import { useMemo } from 'react'
import useTwinTranslation from '../../../utils/hooks/useTwinTranslation'
import { CustomerPayload, ReduxCustomerTypes } from '../../../utils/reducers/customers/customer'
import { parseCustomersToCustomSelectSections } from './functions'
import { CustomSelectSectionsProps, CustomSelectSectionsSearchbar } from '../../../forms/CustomSelect/CustomSelectSections'
import { Modify } from '../../../utils/globals/types'

type CustomSelectSectionCustomersProps = Modify<CustomSelectSectionsProps, CustomSelectSectionCustomersLogicProps & {
    items?: never
}>


const CustomSelectSectionCustomers: React.FC<CustomSelectSectionCustomersProps> = ({customers, ...rest}) => {
    const { customersSectionItems} = useCustomSelectSectionCustomersLogic({ customers })
    return (
        <CustomSelectSectionsSearchbar  {...rest} items={customersSectionItems} />
    )
}

interface CustomSelectSectionCustomersLogicProps {
    customers: CustomerPayload
}

const useCustomSelectSectionCustomersLogic = ({ customers }: CustomSelectSectionCustomersLogicProps) => {
    const { t } = useTwinTranslation()
    const customersTrans = t('customers', 'Clientes')
    const customersB2BTrans = t('customersB2B', 'Clientes B2B')
    const contactTrans = t('contacts', 'Contactos')
    const customersSectionItems = useMemo(() => {
        const types: Record<ReduxCustomerTypes, string> = {
            'customers': customersTrans,
            'customersB2B': customersB2BTrans,
            'contacts': contactTrans
        };
        return parseCustomersToCustomSelectSections(customers, types)
    }, [customers, customersTrans, customersB2BTrans, contactTrans])
    return { customersSectionItems }
}

export default CustomSelectSectionCustomers