import ChooseWorkingCenter from './ChooseWorkingCenter'

interface RightSideHeaderProps {}

const RightSideHeader: React.FC<RightSideHeaderProps> = () => {
    return (
        <div className='ml-auto'>
            <ChooseWorkingCenter />
        </div>
    )
}
export default RightSideHeader