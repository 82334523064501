export const removeAccents = (str: string) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}
export const prepareForSearch = (str: string) => {
    if (str) {
        return removeAccents(str.toLowerCase())
    }
    return str
}
export const stringMatchWithSearch = (searchValue: string, value: string) => {
    const valueParsed = prepareForSearch(value)
    const searchParsed = prepareForSearch(searchValue)
    if (searchValue === '' || (valueParsed && valueParsed.includes(searchParsed))) {
        return true
    }
    return false
}