import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { twinFetchPostJSON } from '../../../../../utils/globals/data'
import TwinTrans from '../../../../../baseComponents/TwinTrans'
import ModuleList from './ModuleList'
import { ModuleInformation } from './types'
import useTwinTranslation from '../../../../../utils/hooks/useTwinTranslation'
import './modules.sass'
import ModuleCart from './ModulesCart'

interface ModulesProps {}

const Modules: React.FC<ModulesProps> = () => {
    const { moduleInformation, ...funcs } = useModulesLogic()
    return (
        <div className='modules_dashboard'>
            <div className='mb-48 text-center'>
                <h1 className='text-39 mb-24'><TwinTrans transKey={'discoverHowToBetterYourProductivity'}>Descubre como mejorar tu productividad</TwinTrans></h1>
                <span className='light18 description-text'><TwinTrans transKey='longTextModuleShop'>Hemos preparado una lista de módulos que creemos que te pueden encantar y mejorar tu productividad al máximo.</TwinTrans><TwinTrans transKey='longTextModuleShop'>Hemos preparado una lista de módulos que creemos que te pueden encantar y mejorar tu productividad al máximo.</TwinTrans></span>
            </div>
            {moduleInformation ? <ModuleCart moduleInformation={moduleInformation} {...funcs} /> : null}
            {moduleInformation ? <ModuleList moduleInformation={moduleInformation} {...funcs} /> : null}
        </div>
    )
}


const useModulesLogic = () => {
    const { id } = useParams()
    const [moduleInformation, setModuleInformation] = useState<null | ModuleInformation>(null)
    const { t } = useTwinTranslation()
    const translation = useMemo(() => t('allF', 'Todas'), [t])

    const getData = useCallback(async () => {
        const modules = await twinFetchPostJSON('/api/company/module/getAllModules', { CompanyId: id })
        const categories = await twinFetchPostJSON('/api/company/module/getAllModuleCategories', {})
        const parsedData: ModuleInformation = {
            own: [],
            changes: { new: [], deleted: []},
            modules: [],
            categories: { 0: translation }
        }
        for (const category of categories) {
            parsedData.categories[category.id] = category.name
        }
        for (const mymodule of modules) {
            if (mymodule.CompanyId) {
                parsedData.own.push(mymodule.id)
            }
            parsedData.modules.push(mymodule)
        }
        setModuleInformation(parsedData)
    }, [id, translation])

    const addModule = useCallback((module_id: string) => {
        setModuleInformation((old) => {
            if (!old) {
                return null
            }
            const copy = JSON.parse(JSON.stringify(old))
            const key = copy.changes.deleted.indexOf(module_id)
            if (key !== -1) {
                copy.changes.deleted.splice(key, 1)
            } else {
                copy.changes.new.push(module_id)
            }
            return {...copy}
        })
    }, [setModuleInformation])

    const deleteModule = useCallback((module_id: string) => {
        setModuleInformation((old) => {
            if (!old) {
                return null
            }
            const copy = JSON.parse(JSON.stringify(old))
            const key = copy.changes.new.indexOf(module_id)
            if (key !== -1) {
                copy.changes.new.splice(key, 1)
            } else {
                copy.changes.deleted.push(module_id)
            }
            return { ...copy }
        })
    }, [setModuleInformation])

    useEffect(() => {
        getData()
    }, [getData])

    return {moduleInformation, addModule, deleteModule}
}

export default Modules