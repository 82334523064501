import { DropzoneRootProps } from 'react-dropzone'
import { ImageInputFile } from '../types'
import { faCloudUpload, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import TwinTrans from '../../../baseComponents/TwinTrans';
import { Fragment } from 'react';
import TwinIcon from '../../../baseComponents/TwinIcon';

interface ImageInputDeciderProps {
    length: number
}

const ImageInputDecider = ({ length }: ImageInputDeciderProps) => {
    if (length) {
        return ImagePreviewWithFile
    }
    return ImageInputWithoutFile
}


interface BaseImageInputProps{
    getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T
    files: ImageInputFile[]
    setFiles: React.Dispatch<React.SetStateAction<ImageInputFile[]>>
    onChange?: (file: any) => void
}

interface ImagePreviewWithFileProps extends BaseImageInputProps{}

const ImagePreviewWithFile: React.FC<ImagePreviewWithFileProps> = ({getRootProps, files, setFiles, onChange}) => {
    return (
        <div className='block'>
            <div className={'flex overflow-hidden boxImage-normal'}>
                <div className='flex items-center justify-center w-full'>
                    <img title='img' className={'block box-border image'} src={files[0].preview} alt=''/>
                </div>
            </div>
            <div className='flex mt-3 items-center boxIcons'>
                <div className='w-2/3 cursor-pointer'>
                    <div {...getRootProps({ className: 'dropzone' })} className='flex items-center ' >
                        <TwinIcon className='iconsMini' icon={faCloudUpload} />
                        <span className='text-sm ml-3 underline'>
                            <TwinTrans transKey={'uploadFile'}>
                                Subir archivo
                            </TwinTrans>
                        </span>
                    </div>
                </div>
                <div className='w-1/3 text-right'>
                    <TwinIcon className='cursor-pointer iconsMini mt-0.5' icon={faTrashCan} onClick={() => { setFiles([]); onChange?.(undefined) }} />
                </div>
            </div>
        </div>
    )
}

interface ImageInputWithoutFileProps extends BaseImageInputProps{}

const ImageInputWithoutFile: React.FC<ImageInputWithoutFileProps> = ({getRootProps, files}) => {
    return (
        <Fragment>
            <div {...getRootProps({ className: 'dropzone' })} className={(!files.length ? 'border border-dashed blockImage cursor-pointer uploadImage-normal ' : '')}>
                <div className='block'>
                    <div className='text-center'>
                        <TwinIcon className='mb-1 iconCloudUpload' icon={faCloudUpload} />
                    </div>
                    <div className='text-center'>
                        <span className='light12'>
                            <TwinTrans transKey={'dragAndDrop'}>
                                Arrastra o sube tu archivo
                            </TwinTrans>
                        </span>
                    </div>
                </div>
            </div>
            <span className='blockImage light12'>
                <TwinTrans transKey={'maxWeight'}>
                    Peso máximo 500kb
                </TwinTrans>
            </span>
        </Fragment>
    )
}


export default ImageInputDecider