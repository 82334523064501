import { getLang } from '.'
import { appStore } from '../reducers'
import { TwinDictionary } from './dictionary'
import { changeErrorMessage, changeRestrictTimeControlAccess } from '../reducers/reduxDispatch'

export interface TwinFetchPostExtraProps{
    authToken?: string,
    type?: 'default' | 'special',
    companyId?: string,
    myExtraHeaders?: TwinDictionary
}
const twinFetchPost = async (url: string, body: any, extraProps?: TwinFetchPostExtraProps): Promise<Response> => {
    const type = !extraProps?.type ? 'default' : extraProps.type
    const extraHeaders: { [key: string]: string } = type === 'default' ? { 'Content-Type': 'application/json' } : {}
    const parsedMyExtraHeaders =  extraProps?.myExtraHeaders|| {}
    const res = await fetch(url, {
        method: 'POST',
        headers: {
            'window-id': appStore.getState().user?.extraData.WindowId || '',
            'fingerprint-auth': appStore.getState().fingerprint,
            'auth-token': extraProps?.authToken || appStore.getState().user?.token || '',
            'company-id': extraProps?.companyId || '',
            'app-lang': getLang(),
            ...extraHeaders,
            ...parsedMyExtraHeaders
        },
        body: type === 'default' ? JSON.stringify(body) : body
    })
    if (res.status === 423) {
        changeRestrictTimeControlAccess(true)
    }
    return res
}

export const twinFetchPostJSON = async (url: string, body: any, extraProps?: TwinFetchPostExtraProps) : Promise<any | undefined> => {
    const response = await twinFetchPost(url, body, extraProps)
    if (response.status === 200) {
        return await response.json()
    } else {
        changeErrorMessage(true)
    }
}
export const twinFetchPostText = async (url: string, body: any, extraProps?: TwinFetchPostExtraProps) : Promise<any | undefined> => {
    const response = await twinFetchPost(url, body, extraProps)
    if (response.status === 200) {
        return await response.text()
    } else {
        changeErrorMessage(true)
    }
}

type FileToUpload = File | string
export const uploadFileToBack = async (file: FileToUpload, filename: string, moveTo: string[], companyId?: string, antiRandom?: boolean) => {
    const myfile = await fetchFileIfIsUrl(file, filename)
    const pathInBack = await uploadFileProcess(myfile || file, filename, moveTo, companyId, antiRandom)
    return pathInBack
}

const fetchFileIfIsUrl = async (file: FileToUpload, filename: string) => {
    if (typeof file === 'string') {
        const fileFromUrl = await fetch(file)
        const result = await fileFromUrl.arrayBuffer()
        return new File([result], filename)
    }
}

const uploadFileProcess = async (file: FileToUpload, filename: string, moveTo: string[], companyId?: string, antiRandom?: boolean) => {
    const formData = new FormData()
    formData.append('file-select', file as any, filename)
    formData.append('moveTo', moveTo.join('/'))
    if (antiRandom) {
        formData.append('antiRandom', 'true')
    }
    const response = await twinFetchPost('/api/processFile', formData, { type: 'special', companyId })
    if (response.status === 200) {
        return await response.text()
    }
    return false
}

export interface ApiError {
    msg: string
    location?: string
    param?: string
    value?: string
}

export default twinFetchPost