import { faCloudUpload } from '@fortawesome/pro-light-svg-icons'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import CustomSelect from '../../../forms/CustomSelect'
import { InputDefault } from '../../../forms/Input'
import useTwinTranslation from '../../../utils/hooks/useTwinTranslation'
import { ButtonPrimary, ButtonSecondary } from '../../Button'
import { ModalSmallCentered, ButtonsModalSmallCentered } from '../../Modal'
import { ModalOpenedTy } from '../../Modal/types'
import SearchBar from '../../SearchBar'
import TwinIcon from '../../TwinIcon'
import TwinTrans from '../../TwinTrans'
import { TFSConfig, TFSOrderBy } from '../types'
import { TwinDictionary } from '../../../utils/globals/dictionary'

type TFSRightHeaderProps = TFSImportFileButtonProps &  TFSCreateFolderButtonProps & {

}

export const TFSRightHeader: React.FC<TFSRightHeaderProps> = ({ addFolderToPath, ...rest }) => {
    return (
        <div className='flex items-center'>
            <TFSImportFileButton {...rest}/>
            <TFSCreateFolderButton addFolderToPath={addFolderToPath}/>
        </div>
    )
}

interface TFSImportFileButtonProps extends TFSImportFileButtonLogicProps { }

const TFSImportFileButton: React.FC<TFSImportFileButtonProps> = ({...rest}) => {
    const {inputRef, makeClickToInput, onUpload} = useTFSImportFileButtonLogic({...rest})
    return (
        <Fragment>
            <ButtonSecondary className='mr-25' onClick={makeClickToInput}><TwinIcon icon={faCloudUpload} className='mr-8' /><TwinTrans transKey='importFile'>Importar fichero</TwinTrans></ButtonSecondary>
            <input type='file' ref={inputRef} onChange={onUpload} style={{ display: 'none' }} />
        </Fragment>
    )
}

interface TFSImportFileButtonLogicProps {
    addFileToPath: (file: File) => void
}

const useTFSImportFileButtonLogic = ({ addFileToPath }: TFSImportFileButtonLogicProps) => {
    const inputRef = useRef<any>(null)

    const makeClickToInput = useCallback(() => {
        inputRef.current.click()
    }, [])

    const onUpload = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const fileUploaded = event.target.files[0];
            addFileToPath(fileUploaded)
        }
    }, [addFileToPath])
 
    return {inputRef, makeClickToInput, onUpload}
}

interface TFSCreateFolderButtonProps extends TFSCreateFolderButtonLogicProps {}

const TFSCreateFolderButton: React.FC<TFSCreateFolderButtonProps> = ({addFolderToPath}) => {
    const {t} = useTwinTranslation()
    const {opened, setOpened, createFolder, value, setValue} = useTFSCreateFolderButtonLogic({addFolderToPath})
    return (
        <Fragment>
            <ButtonPrimary onClick={() => setOpened(true)}><TwinIcon icon={faPlus} className='mr-8' /><TwinTrans transKey='createFolder'>Crear carpeta</TwinTrans></ButtonPrimary>
            {opened && <ModalSmallCentered opened={opened} setOpened={setOpened}>
                <h2><TwinTrans transKey='createNewFolder'>Crear una nueva carpeta</TwinTrans></h2>
                <InputDefault placeholder={t('writeNameOfFolder', 'Escribe el nombre de la carpeta')} value={value} onChange={setValue} />
                <ButtonsModalSmallCentered confirmText={t('createFolder', 'Crear carpeta')} setOpened={setOpened} onAccept={createFolder} />
            </ModalSmallCentered>}
        </Fragment>
    )
}

interface TFSCreateFolderButtonLogicProps {
    addFolderToPath: (value: string) => void
}

const useTFSCreateFolderButtonLogic = ({addFolderToPath}: TFSCreateFolderButtonLogicProps) => {
    const [opened, setOpened] = useState<ModalOpenedTy>(null)
    const [value, setValue] = useState('')

    const createFolder = useCallback(() => {
        addFolderToPath(value)
    }, [value, addFolderToPath])

    useEffect(() => {
        if (opened) {
            setValue('')
        }
    }, [opened])
    

    return {opened, setOpened, value, createFolder, setValue}
}



interface TFSHeaderProps extends TFSHeaderLogicProps { }


export const TFSHeader: React.FC<TFSHeaderProps> = ({ config, ...rest }) => {
    const { changeOrder, changeSearchbar } = useTFSHeaderLogic({config, ...rest})
    const { t } = useTwinTranslation()
    const items: Record<TFSOrderBy, TwinDictionary> = {
        '': { name: t('firstFolders', 'Primero carpetas')},
        'byName': { name: t('byName', 'Por nombre')},
        'byOldNew': {name: t('newsFirst', 'Nuevos primero')},
        'byOldOld': { name: t('oldFirst', 'Antiguos primero')},
    }
    return (
        <div className='tfsheader'>
            <div className='flex items-center'>
                <SearchBar searchValue={config.searchbar} onChange={changeSearchbar} placeholder={t('searchFoldersOrFiles', 'Buscar carpetas o archivos')} className='files_searchbar mr-30 mt-16'></SearchBar>
                <CustomSelect items={items} label={t('orderBy', 'Ordenar por')} className='files_customselect' value={config.orderBy} onChange={changeOrder}></CustomSelect>
            </div>
        </div>
    )
}
interface TFSHeaderLogicProps {
    setConfig: React.Dispatch<React.SetStateAction<TFSConfig>>
    config: TFSConfig
}

const useTFSHeaderLogic = ({ setConfig, config }: TFSHeaderLogicProps) => {
    const changeSearchbar = useCallback((searchbar: string) => {
        setConfig((oldConfig) => {
            return {
                ...oldConfig,
                searchbar
            }
        })
    }, [setConfig])

    const changeOrder = useCallback((orderBy: TFSOrderBy) => {
        setConfig((oldConfig) => {
            return {
                ...oldConfig,
                orderBy
            }
        })
    }, [setConfig])

    return {changeSearchbar, changeOrder}
}