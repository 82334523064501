import { SellShippingStatusModelType } from '@teinor/erp/types/company/order/sell/sellShipping/sellShippingStatus'
import { dictionaryComplexFromJsonArr } from '../../globals/dictionary'

export interface SellShippingStatusAction {
    type: 'CHANGE_SELLSHIPPINGSTATUS'
    payload: SellShippingStatusModelType[]
}
export type SellShippingStatusPayload = { [id: number]: SellShippingStatusModelType } | null

export default function sellshippingstatus(state: SellShippingStatusPayload = null, action: SellShippingStatusAction): SellShippingStatusPayload {
    switch (action.type) {
        case 'CHANGE_SELLSHIPPINGSTATUS':
            const sellshippingstatus = dictionaryComplexFromJsonArr(action.payload || [])
            return state = sellshippingstatus
        default:
            return state
    }
}