import { EmployeeExpenseModelType } from '@teinor/erp/types/company/userInner/employee/employeeExpense'
import { EmployeeExpenseRowTypeModelAdd } from '@teinor/erp/types/company/userInner/employee/employeeExpense/employeeExpenseRow/employeeExpenseRowType'
import { useCallback, useEffect, useState } from 'react'
import ModalDeleteEmployeeExpenses from '../../../../app/erp/screens/Staff/EmployeeParent/Employees/SingleEmployee/Tabs/EmployeeExpenses/ModalDeleteEmployeeExpenses/index'
import { ButtonOrLoader } from '../../../../baseComponents/Button'
import { MediumModalCreateEdit } from '../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../../baseComponents/ModalsLayouts/types'
import { permissionCheck } from '../../../../baseComponents/PermissionChecker/function'
import FormRenderer from '../../../../forms/FormRenderer'
import { FormRender } from '../../../../forms/FormRenderer/types'
import { BeforeSubmitHandler } from '../../../../forms/TwinForm/types'
import { twinFetchPostJSON } from '../../../../utils/globals/data'
import { displayDateByString } from '../../../../utils/globals/date'
import { returnClassOfSpanTemplate } from '../../../../utils/globals/tailwind'
import useEmployeeExpensesType from '../../../../utils/hooks/employeeExpenses/useEmployeeExpensesType'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import { getActiveCurrencies } from '../../../../utils/reducers/getters'
import { changeErrorMessage } from '../../../../utils/reducers/reduxDispatch'
import EmployeeExpenseTableExpenses from './EmployeeExpenseTableExpenses/index'
import EmployeeExpenseNotEditableModal from './EmployeeExpenseNotEditableModal/index'
import { EmployeeExpenseModelTypeExtended } from './types'
import EmployeeExpenseEditTotals from './EmployeeExpenseEditTotals'
import { valueOrDefaultValue } from '../../../../utils/globals/valueOrDefaultValue'
import { calculateEmployeeExpensesTotals } from '@teinor/erp/prices/expense'
import { ModalOpenedTy } from '../../../../baseComponents/Modal/types'

interface EmployeeExpenseEditModalProps extends ModalEditComponentProps {}

const EmployeeExpenseEditModal: React.FC<EmployeeExpenseEditModalProps> = ({ userPermissions, allRowData, ...rest }) => {
    const { t } = useTwinTranslation()
    const { expensesType, beforeSubmitExpensesHandler, expenseInstance, changeWithCalculate, changeFieldExpenseInstance, openNotAcceptModal, setExpenseInstance, setOpenNotAcceptModal } = useEmployeeExpenseEditModalLogic({ allRowData, ...rest })
    const allCurrencies = getActiveCurrencies()
    const { expenseStatus } = useEmployeeExpensesType()

    if (!expensesType || !expenseInstance) {
        return null
    }
    if (expenseInstance?.originalStatus !== 0 || !userPermissions || !permissionCheck(userPermissions, 'update')) {
        return (<EmployeeExpenseNotEditableModal {...{userPermissions, expenseInstance, expensesType, changeFieldExpenseInstance}} {...rest} />)
    }

    const cancelledButton: JSX.Element = <ButtonOrLoader onClick={() => changeFieldExpenseInstance('2', 'status')} textButton={t('deny', 'Denegar')} buttonIsDisabled={false} className='button_red' />
    const acceptButton: JSX.Element = <ButtonOrLoader onClick={() => changeFieldExpenseInstance('1', 'status')} textButton={t('accept', 'Aceptar')} buttonIsDisabled={false} />

    const fields: FormRender<EmployeeExpenseModelType> = [
        {
            cols: 2,
            elements: [
                {
                    name: 'EmployeeId',
                    component: 'InputHidden',
                    value: expenseInstance?.EmployeeId
                },
                {
                    name: 'id',
                    component: 'InputHidden',
                    value: expenseInstance?.id,
                },
                {
                    name: 'EmployeeId',
                    label: t('employee', 'Empleado'),
                    component: 'TextField',
                    value: expenseInstance?.Employee?.fullname_short
                },
                {
                    name: 'status',
                    label: t('status', 'Estado'),
                    component: 'TextField',
                    value: expenseInstance ? expenseStatus[expenseInstance?.status].name : '',
                },
                {
                    name: 'dateStart',
                    label: t('dateStart', 'Desde'),
                    component: 'InputCalendarStateFull',
                },
                {
                    name: 'dateEnd',
                    label: t('dateEnd', 'Hasta'),
                    component: 'InputCalendarStateFull',
                },
                {
                    name: 'CurrencyId',
                    label: t('currency', 'Moneda'),
                    component: 'CustomSelect',
                    items: allCurrencies,
                    onChange: (value) => changeFieldExpenseInstance(value, 'CurrencyId'),
                },
                {
                    name: 'location',
                    label: t('location', 'Ubicación'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true,
                },
                {
                    name: 'km',
                    label: t('km', 'Km'),
                    component: 'InputNumberWithLabelMargin',
                    value: String(expenseInstance?.km),
                    onChange: (value) => changeFieldExpenseInstance(value, 'km'),
                    onBlur: changeWithCalculate,
                },
                {
                    name: 'kmPrice',
                    label: t('kmPrice', 'Precio del Km'),
                    component: 'InputPrice',
                    CurrencyId: expenseInstance?.CurrencyId || '',
                    value: String(expenseInstance?.kmPrice),
                    onChange: (value) => changeFieldExpenseInstance(value, 'kmPrice'),
                    onBlur: changeWithCalculate,
                },
                {
                    name: 'abroad',
                    label: t('abroad', 'Extranjero'),
                    component: 'CheckboxMargin',
                },
                {
                    name: 'overnight_stay',
                    label: t('overnightStay', 'Pernoctación'),
                    component: 'CheckboxMargin',
                },
                {
                    name: 'updatedAt',
                    label: t('modificatedAt', 'Última modificación'),
                    component: 'TextField',
                    value: displayDateByString(expenseInstance?.updatedAt || ''),
                },
                {
                    name: 'remarks',
                    label: t('remarks', 'Observaciones'),
                    component: 'TextArea',
                    onChange: (value) => changeFieldExpenseInstance(value, 'remarks'),
                    className: returnClassOfSpanTemplate(2)
                },
            ]
        },
    ]
    const parsedFields = valueOrDefaultValue(fields, userPermissions, expenseInstance)

    return (
        <MediumModalCreateEdit fields={[]} translations={{
            title: t('editExpensesSheet', 'Editar ficha de gastos'),
            button: t('markAsPaid', 'Marcar como pagado'),
        }} haveButtonPermissions={permissionCheck(userPermissions || false, 'update')} url={'/api/app/employeeExpense/updateEmployeeExpense'} imagePath={['employee', String(expenseInstance?.EmployeeId), 'expenses', String(expenseInstance?.id)]} beforeSubmit={beforeSubmitExpensesHandler} extraButtons={[cancelledButton, acceptButton]} {...rest}>
            <FormRenderer sections={parsedFields} className='mb-20' />
            <EmployeeExpenseTableExpenses expenseInstance={expenseInstance} expensesType={expensesType} setExpenseInstance={setExpenseInstance} />
            <EmployeeExpenseEditTotals changeFieldExpenseInstance={changeFieldExpenseInstance} expenseInstance={expenseInstance} changeWithCalculate={changeWithCalculate}/>
            {openNotAcceptModal ? <ModalDeleteEmployeeExpenses setOpenNotAcceptModal={setOpenNotAcceptModal} onSubmit={rest.onSubmit} allRowData={expenseInstance || []} setOpened={rest.setOpened} /> : null}
        </MediumModalCreateEdit>
    )
}

interface EmployeeExpenseEditModalLogicProps extends ModalEditComponentProps { }

const useEmployeeExpenseEditModalLogic = ({ allRowData }: EmployeeExpenseEditModalLogicProps) => {
    const { t } = useTwinTranslation()
    const errorMessage = t('addValidDates', 'Introduzca una fecha válida')
    const [expensesType, setExpensesType] = useState<EmployeeExpenseRowTypeModelAdd[]>([])
    const [expenseInstance, setExpenseInstance] = useState<EmployeeExpenseModelTypeExtended | null>(null)
    const [openNotAcceptModal, setOpenNotAcceptModal] = useState<ModalOpenedTy>(null)

    const changeWithCalculate = useCallback(() => {
        setExpenseInstance((old) => {
            const expenseInstanceCopy = JSON.parse(JSON.stringify(old))
            calculateEmployeeExpensesTotals(expenseInstanceCopy)
            return expenseInstanceCopy
        })
    }, [setExpenseInstance])

    const changeFieldExpenseInstance = useCallback((value: string, field: keyof EmployeeExpenseModelType) => {
        setExpenseInstance((old: any) => {
            if (!old) {
                return old
            }
            return { ...old, [field]: value }
        })
    }, [setExpenseInstance])

    const beforeSubmitExpensesHandler: BeforeSubmitHandler = useCallback((values) => {
        delete values.EmployeeExpenseRowTypeId
        delete values.name
        if (new Date(values.dateStart) > new Date(values.dateEnd)) {
            changeErrorMessage(errorMessage)
            return false
        }
        if (String(expenseInstance?.status) === '2') {
            setOpenNotAcceptModal(true)
            return false
        }
        if (expenseInstance?.EmployeeExpenseRows) {
            for (let row in expenseInstance?.EmployeeExpenseRows) {
                let file = `file_amount_expense_${row}`
                if (values[file]) {
                    expenseInstance.EmployeeExpenseRows[parseInt(row)].file_amount_expense = values[file]
                }
            }
        }
        values.status = String(expenseInstance?.status) === '0' ? 3 : expenseInstance?.status
        values.EmployeeExpenseRows = expenseInstance?.EmployeeExpenseRows
        values.total_amount = expenseInstance?.total_amount
        values.km_amount = expenseInstance?.km_amount
        values.expenses_amount = expenseInstance?.expenses_amount
        values.total_amount_declarable = expenseInstance?.total_amount_declarable
        values.total_amount_excluded_vat = expenseInstance?.total_amount_excluded_vat
        return true
    }, [expenseInstance, errorMessage, setOpenNotAcceptModal])


    const getExpensesTypes = useCallback(async () => {
        const allExpensesTypes = await twinFetchPostJSON('/api/app/employeeExpense/getAllEmployeeExpensesRowType', {})
        setExpensesType(allExpensesTypes)
    }, [setExpensesType])

    const getExpense = useCallback(async () => {
        const expense = await twinFetchPostJSON('/api/app/employeeExpense/getEmployeeExpenseInstance', { id: allRowData?.id })
        if (expense) {
            expense.originalStatus = expense.status
            setExpenseInstance(expense)
        }
    }, [setExpenseInstance, allRowData])

    useEffect(() => {
        getExpensesTypes()
    }, [getExpensesTypes])

    useEffect(() => {
        getExpense()
    }, [getExpense])

    return { expensesType, beforeSubmitExpensesHandler, expenseInstance, changeWithCalculate, changeFieldExpenseInstance, openNotAcceptModal, setExpenseInstance, setOpenNotAcceptModal }
}

export default EmployeeExpenseEditModal;