import { TFSBreadcrumbTy } from './types'

const basePath = {
    name: '',
    link: '/'
}
export const prepareBreadcrumb = (path: string) => {
    const result: TFSBreadcrumbTy[] = []
    if (path === '/') {
        result.push(basePath)
    } else {
        const pathsSplited = path.split('/')
        let parentPath = ''
        for (const pathSplit of pathsSplited) {
            if (pathSplit === '') {
                result.push(basePath)
            } else {
                parentPath += '/' + pathSplit
                result.push({
                    name: pathSplit,
                    link: parentPath
                })
            }
        }
    }
    return result
}