import { faCubes } from '@fortawesome/pro-light-svg-icons'
import TwinIcon from '../../../../../../baseComponents/TwinIcon'
import { ModuleInformation, ModuleInformationIndividualModule } from '../types'
import { useMemo, useRef, useState } from 'react'
import TwinTrans from '../../../../../../baseComponents/TwinTrans'
import DisplayPriceAdmin from '../../../../components/DisplayPriceAdmin'
import SwitchLight from '../../../../../../forms/SwitchLight'
import { dictionaryComplexFromJsonArr } from '../../../../../../utils/globals/dictionary'
import useOnClickOutside from '../../../../../../utils/hooks/useOnClickOut'
import { ButtonPrimary } from '../../../../../../baseComponents/Button'

interface ModuleCartProps {
    moduleInformation: ModuleInformation
    addModule: (module_id: string) => void
    deleteModule: (module_id: string) => void
}

const ModuleCart: React.FC<ModuleCartProps> = ({moduleInformation, ...rest}) => {
    const {opened, setOpened, ref} = useModuleCartLogic()
    return (
        <div className='module_cart relative' ref={ref}>
            <div className='module_cart_ball relative text-white cursor-pointer' onClick={() => setOpened((old) => !old)}>
                <div className='bg-green-21 small_ball flex items-center justify-center'>{moduleInformation.changes.deleted.length + moduleInformation.changes.new.length}</div>
                <div className='bg-green-43 big_ball flex items-center justify-center '>
                    <TwinIcon icon={faCubes} fontSize={40} />
                </div>
            </div>
            {opened ? <ModuleCartInner moduleInformation={moduleInformation} {...rest} /> : null}
        </div>
    )
}


const useModuleCartLogic = () => {
    const [opened, setOpened] = useState<boolean>(false)
    const ref = useRef<any>(null)

    useOnClickOutside(ref, () => setOpened(false))

    return {opened, setOpened, ref}
}

interface ModuleCartInnerProps extends ModuleCartInnerLogicProps {
    addModule: (module_id: string) => void
    deleteModule: (module_id: string) => void
}

const ModuleCartInner: React.FC<ModuleCartInnerProps> = ({moduleInformation, addModule, deleteModule}) => {
    const { memorizedModules } = useModuleCartInnerLogic({ moduleInformation })
    const renderThis: JSX.Element[] = []
    let sum = 0
    for (const element of moduleInformation.own) {
        if (!moduleInformation.changes.deleted.includes(element)) {
            sum += parseFloat(memorizedModules[element].price)
        }
    }
    for (const element of moduleInformation.changes.new) {
        renderThis.push(<ModuleCartIndividual mymodule={memorizedModules[element]} key={element} changeModuleStatus={deleteModule} />)
        sum += parseFloat(memorizedModules[element].price)
    }
    for (const element of moduleInformation.changes.deleted) {
        renderThis.push(<ModuleCartIndividual mymodule={memorizedModules[element]} key={element} changeModuleStatus={addModule} isDeleted={true} />)
    }
    return (
        <div className='module_cart_inner absolute' >
            <span className='regular26 p-15 mb-2 block'><TwinTrans transKey='manageYourModules'>Gestiona tus módulos</TwinTrans></span>
            <div className='module_cart_inner_listing'>
                {renderThis}
            </div>
            <div className='flex items-center p-15'>
                <span className='medium20'><TwinTrans transKey='TOTALmensualQuote'>TOTAL cuota mensual</TwinTrans></span>
                <span className='medium20 ml-auto'><DisplayPriceAdmin value={sum}> / mes</DisplayPriceAdmin></span>
            </div>
            <div className='p-15'>
                <ButtonPrimary className='w-full h-40 '><TwinTrans transKey='saveChanges'>Guardar cambios</TwinTrans></ButtonPrimary>
            </div>
        </div>
    )
}

interface ModuleCartInnerLogicProps {
    moduleInformation: ModuleInformation
}

const useModuleCartInnerLogic = ({moduleInformation}: ModuleCartInnerLogicProps) => {
    const memorizedModules = useMemo(() => dictionaryComplexFromJsonArr(moduleInformation.modules), [moduleInformation])

    return {memorizedModules}
}

interface ModuleCartIndividualProps {
    mymodule: ModuleInformationIndividualModule
    changeModuleStatus: (module_id: string) => void
    isDeleted?: boolean
}

const ModuleCartIndividual: React.FC<ModuleCartIndividualProps> = ({isDeleted, mymodule, changeModuleStatus }) => {

    return (
        <div className={'module_individual flex items-center p-15 ' + (isDeleted ? 'deleted' : '')}>
            <div className='flex flex-col'>
                <span className='regular16'>{mymodule.name}</span>
                <DisplayPriceAdmin className='light16' value={mymodule.price}>  / <TwinTrans transKey='month'>mes</TwinTrans></DisplayPriceAdmin>
            </div>
            <div className='ml-auto'>
                <SwitchLight active={!isDeleted} onChange={() => changeModuleStatus(mymodule.id)} />
            </div>
        </div>
    )
}


export default ModuleCart