import { stringMatchWithSearch } from '../../../utils/globals/search'
import { CustomSelectBlockedItemsTy } from './types'

export const filterCustomSelectItemsBlocked = (searchValue: string, items: CustomSelectBlockedItemsTy) => {
    const filteredItems: CustomSelectBlockedItemsTy = {}
    for (const key in items) {
        const item = items[key]
        if (stringMatchWithSearch(searchValue, String(item.text))) {
            filteredItems[key]= item
        }
    }  
    return filteredItems
}