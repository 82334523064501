import { ConnectedProps, connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ComponentWithPermissions } from '../../../../../../baseComponents/CreateRoutering/types'
import { TwinTableLink } from '../../../../../../baseComponents/TwinTable'
import BaseScreenTable from '../../../../../../baseComponents/TwinTable/BaseScreenTable'
import { TableComponentProps } from '../../../../../../baseComponents/TwinTable/BaseScreenTable/types'
import { CustomRenderString, EditRow } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import withLoading from '../../../../../../utils/hoc/withLoading'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import { AllReduxPayloads } from '../../../../../../utils/reducers'
import { WorkingCenterTypePayload } from '../../../../../../utils/reducers/company/workingCenterTypes'
import ModalCreateWorkingCenterConnect from './ModalCreateWorkingCenter'
import { WorkingCenterModelTypeExtended } from './types'

interface WorkingCentersProps extends ComponentWithPermissions { }

const WorkingCenters: React.FC<WorkingCentersProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()

    const pageData = {
        title: t('workingCenters', 'Centros de Trabajo'),
        newText: t('newWorkingCenter', 'Nuevo Centro de trabajo'),
    }

    return (
        <BaseScreenTable TableComponent={TableWorkingCentersConnect} CreateModalComponent={ModalCreateWorkingCenterConnect} pageData={pageData} userPermissions={userPermissions} />
    )
}

interface TableWorkingCentersProps extends TableComponentProps { }

const TableWorkingCenters: React.FC<TableWorkingCentersProps & ReduxWorkingCenterTypes> = ({ setTableInstance, workingCenterTypes }) => {
    const { t } = useTwinTranslation()
    const location = useLocation().pathname
    const columns: ColumnTableSchema<WorkingCenterModelTypeExtended> = [
        {
            id: 'name', 
            dataKey: 'name',
            label: t('name', 'Nombre'),
            sortable: true,
            searchKey: 'WorkingCenter.name',
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            }
        },
        {
            id: 'WorkingCenterTypeRelParsed',
            dataKey: 'WorkingCenterTypeRelParsed',
            label: t('workingCenterType', 'Tipo de Centro de trabajo'),
            customRender: (parameterValue) => <CustomRenderString value={workingCenterTypes?.[parseInt(parameterValue)]?.name || ''} />
        },
        {
            id: 'edit',
            dataKey: 'id', 
            label: '',
            width: 50,
            customRender: (parameterValue, allRowData) => <EditRow key={'edit' + parameterValue} {...{ parameterValue, allRowData }} />
        }
    ]
    return (
        <TwinTableLink name='workingCenters' createRowDataLink={[location, ['$id', 'main']]} columns={columns} getDataFrom='/api/app/workingCenter/getAllWorkingCenters'  setTableInstance={setTableInstance} />
    )
}

const workingCenterTypesDispatch = {
    setWorkingCenterTypes: (payload: WorkingCenterTypePayload) => ({ type: 'CHANGE_WORKINGCENTERTYPE', payload }),
}

export type ReduxWorkingCenterTypes = ConnectedProps<typeof workingCenterTypesConnect>
const mapWorkingCenterTypesConnector = (state: AllReduxPayloads) => ({ workingCenterTypes: state.workingCenterTypes })
const workingCenterTypesConnect = connect(mapWorkingCenterTypesConnector, workingCenterTypesDispatch)

const workingCenterTypesConnectLoading = withLoading(TableWorkingCenters, [{ fetchUrl: '/api/app/workingCenter/workingCenterType/getAllWorkingCenterTypes', propName: 'workingCenterTypes', setFunctionName: 'setWorkingCenterTypes' }])

const TableWorkingCentersConnect = workingCenterTypesConnect(workingCenterTypesConnectLoading)

export default WorkingCenters