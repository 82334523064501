import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { useCallback, useMemo } from 'react';
import { CustomSelectProps, CustomSelectBase, CustomSelectHeaderDefaultProps } from '..';
import TwinIcon from '../../../baseComponents/TwinIcon';
import { TwinDictionary } from '../../../utils/globals/dictionary';
import { CustomSelectDropdownIcon, CustomSelectDropDown, useDropDownRowLogic } from '../DropDown';
import { CustomSelectDropDownBaseProps, CustomSelectDropDownRowProps } from '../DropDown/types';
import { DisplayTextFuncTy } from '../types';
import TwinToolTip from '../../../baseComponents/TwinToolTip';
import './customSelectColor.sass'

interface CustomSelectColorProps extends CustomSelectProps {}

export const CustomSelectColor: React.FC<CustomSelectColorProps> = ({ value, label, onChange, items, extraDropDownParams, className, ...rest }) => {
    const { displayTextFunc } = useCustomSelectColorLogic({fieldName: rest.fieldName})
    const itemsDict = JSON.parse(JSON.stringify(items))
    return (
        <div className='w-full' onClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
            <CustomSelectBase {...rest} className={'custom_select_label_up w-full l px-4 ' + (className || '')} CustomSelectHeader={CustomSelectColorHeader} onChange={onChange} label={label || ''} items={itemsDict} value={value} displayTextFunc={displayTextFunc} DropDownComponent={CustomSelectColorDropDown} extraDropDownParams={extraDropDownParams} />
        </div>
    )
}

interface CustomSelectColorLogicProps {
    fieldName?: string
}
const useCustomSelectColorLogic = ({ fieldName = 'name' }: CustomSelectColorLogicProps) => {
    const displayTextFunc: DisplayTextFuncTy = useCallback(((items, value) => {
        if (!Array.isArray(items)) {
            return items[value]?.[fieldName]
        }
        return ''
    }), [fieldName])

    return { displayTextFunc }
}


interface CustomSelectColorHeaderProps extends CustomSelectHeaderDefaultProps { }

const CustomSelectColorHeader: React.FC<CustomSelectColorHeaderProps> = ({ opened, displayText, label, required, icon, extraHeaderParams, items, ...input }) => {
    const myValueItem = items[(input.value || '') as any]
    return (
        <div className={'custom_select_header cursor-pointer '}>
            {extraHeaderParams?.displayToolTip ? <TwinToolTip id={extraHeaderParams.idTooltip} place='top' content={extraHeaderParams.message} />: null}
            <input type='text' className='input_hidden' {...input} required={required} onChange={() => undefined} />
            <div className='custom_select_header_icon' data-tooltip-id={extraHeaderParams?.idTooltip}>
                <div className='custom_select_header_text'>
                    <label className={'cursor-pointer twin_elipsis'}>{label}{required ? ' *' : ''}</label>
                    <div className='flex items-center custom_select_header_container'>
                        {myValueItem?.color ? <div className='rounded-full mr-10 custom_select_color_ball' style={{ backgroundColor: myValueItem.color }}></div> : null}
                        {displayText !== '-' ?
                            <div className='custom_select_item_text' title={displayText}>
                                {displayText !== 'main' ? displayText : <TwinIcon icon={faChevronDown} />}
                            </div>
                            :
                            <TwinIcon className='custom_select_block_icon absolute text-white h-10 w-10' icon={faChevronDown} />
                        }
                    </div>
                </div>
                <CustomSelectDropdownIcon opened={opened} readOnly={input.readOnly} icon={icon} />
            </div>
            <div className='custom_select_border'></div>
        </div>
    )
}

interface CustomSelectColorDropDownProps extends CustomSelectDropDownBaseProps {
    fieldDescription?: string
}

export const CustomSelectColorDropDown: React.FC<CustomSelectColorDropDownProps> = ({ items, children, fieldName = 'name', fieldDescription = 'name', ...rest }) => {
    const itemsSorted = useMemo(() => sortCustomSelectColors(items, fieldName, fieldDescription), [items, fieldDescription, fieldName])
    return (<CustomSelectDropDown items={itemsSorted} {...rest} rowRenderer={({ ...rowRenderProps }) => {
        return (
            <CustomSelectColorDropDownRow currentvalue={rest.value} onChange={rest.onChange} item={itemsSorted[rowRenderProps.index]}  {...rowRenderProps} />
        )
    }}>{children}</CustomSelectDropDown>)
}


interface CustomSelectColorDropDownRowTy {
    text: string | number
    value: string | number
    description: string
    color: string
    order: string | number
}

export const sortCustomSelectColors = (items: TwinDictionary, fieldName = 'name', fieldDescription = 'name') => {
    const itemsArr: CustomSelectColorDropDownRowTy[] = []
    for (const key in items) {
        const item = items[key]
        itemsArr.push({ value: key, text: item[fieldName], order: item.order, color: item.color, description: (item[fieldDescription] || item[fieldName]) })
    }
    itemsArr.sort((a, b) => {
        return a.order > b.order ? 1 : -1
    })
    return itemsArr
}

const CustomSelectColorDropDownRow: React.FC<CustomSelectDropDownRowProps> = ({ className, onChange, ...rest }) => {
    const { color, description } = rest.item
    const { changeValue, text, value } = useDropDownRowLogic({ onChange, ...rest })
    return (
        <div className={'custom_select_value cursor-pointer flex justify-between items-center bg-white regular12 text-gray-51 hover:bg-gray-F7 px-15 ' + (className || '')} title={description} onClick={changeValue} {...rest} {...value}>
            <div className='rounded-full mr-10 custom_select_color_ball' style={{ backgroundColor: color }}></div>
            <span className='w-full custom_select_item_row_text'>{text}</span>
        </div>
    )
}

export default CustomSelectColor