import { DictionaryEmployee } from '../../../../../../utils/reducers/company/employees'
import { getEmployeeParams } from '../../../../../../utils/reducers/getters'
import { DictionaryGroup } from '../../../../../../utils/reducers/groups/groups'
import { TaskAdminCalendarSelectedData } from './types'

const taskAdminCalendarGetDefaultOrderedColor = (currentIndex: number) => {
    let index = 0
    const colors = [
        '#AECB6C',
        '#90BDDA',
        '#F8A7C1',
        '#F88754',
        '#6299E3',
        '#D891EE',
        '#D62839'
    ]

    if (currentIndex === 0) {
        index = 0
    } else {
        index = (currentIndex) % colors.length
    }
    return colors[index]
}

interface TaskAdminCalendarSelectedDataParams {
    name: string
    groups: DictionaryGroup | null
    employees: DictionaryEmployee | null
}

export const taskAdminCalendarGetDefaultSelectedData = ({ name, groups, employees }: TaskAdminCalendarSelectedDataParams): TaskAdminCalendarSelectedData => {
    const mySelectedData: TaskAdminCalendarSelectedData = getEmployeeParams()?.[name] ? JSON.parse(getEmployeeParams()?.[name]) : taskAdminCalendarGetOnlyDefaultData(groups, employees)
    parseTaskAdminCalendarFilterData(mySelectedData, groups)
    return mySelectedData
}
export const parseTaskAdminCalendarFilterData = (myFilterData: TaskAdminCalendarSelectedData, groups: DictionaryGroup | null) => {
    if (myFilterData?.filters?.groups) {
        const myGroups: string[] = []
        for (const element of myFilterData.filters.groups) {
            if (groups?.[parseInt(element)]) {
                myGroups.push(element)
            }
        }
        myFilterData.filters.groups = myGroups
    }
}
export const taskAdminCalendarGetOnlyDefaultData = (groups: DictionaryGroup | null, employees: DictionaryEmployee | null) => {
    const result: TaskAdminCalendarSelectedData = {
        filters: { groups: [], employees: [] },
        colors: { groups: {}, employees: {} }
    }
    let currentIndex = 0
    if (employees) {
        for (const key in employees) {
            const item = employees[key]
            result.colors.employees[item.id] = taskAdminCalendarGetDefaultOrderedColor(currentIndex)
            currentIndex++
        }
    }
    if (groups) {
        for (const key in groups) {
            const item = groups[key]
            result.colors.groups[item.id] = taskAdminCalendarGetDefaultOrderedColor(currentIndex)
            currentIndex++
        }
    }
    return result
}