import { CustomerProjectModelType } from '@teinor/erp/types/company/customer/customerProject'
import { ConnectedProps, connect } from 'react-redux'
import { ComponentWithPermissions } from '../../../../../../baseComponents/CreateRoutering/types'
import TwinTable from '../../../../../../baseComponents/TwinTable'
import BaseScreenTable from '../../../../../../baseComponents/TwinTable/BaseScreenTable'
import { TableComponentBaseScreenInnerProps } from '../../../../../../baseComponents/TwinTable/BaseScreenTableInner/types'
import { CustomRenderString, EditRow } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import ModalEditCustomerProject from '../../../../../../specificComponents/Customers/ModalEditCustomerProject'
import withLoading from '../../../../../../utils/hoc/withLoading'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import { AllReduxPayloads } from '../../../../../../utils/reducers'
import { CustomerPayload } from '../../../../../../utils/reducers/customers/customer'
import ModalCreateCustomerProject from './ModalCreateCustomerProject'
import { CustomerProjectTypePayload } from '../../../../../../utils/reducers/customers/customerProjectTypes'

interface CustomerProjectsProps extends ComponentWithPermissions {
}

const CustomerProjects: React.FC<CustomerProjectsProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()
    const pageData = {
        title: t('projects', 'Proyectos'),
        newText: t('addProject', 'Añadir un proyecto'),
    }

    return (
        <BaseScreenTable TableComponent={TableCustomerProjectsConnect} pageData={pageData} CreateModalComponent={ModalCreateCustomerProject} EditModalComponent={ModalEditCustomerProject} userPermissions={userPermissions} />
    )
}

type TableCustomerProjectsProps = TableComponentBaseScreenInnerProps & ReduxCustomerAndProjectTypes & {
}

const TableCustomerProjects: React.FC<TableCustomerProjectsProps> = ({ setTableInstance, setOpened, customers }) => {
    const { t } = useTwinTranslation()
    const columns: ColumnTableSchema<CustomerProjectModelType> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('id', 'ID'),
            width: 75
        },
        {
            id: 'customer',
            dataKey: 'CustomerId',
            label: t('customer', 'Cliente'),
            customRender: (parameterValue) => <CustomRenderString value={customers?.all?.[parseInt(parameterValue)].name || ''} />,
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentsCustomSelectSearchBar',
                extraComponentData: { items: customers?.all || {}, label: t('customer', 'Cliente') }
            },
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
        },
        {
            id: 'description',
            dataKey: 'description',
            label: t('description', 'Descripción'),
        },
        {
            id: 'CustomerProjectType',
            dataKey: 'CustomerProjectTypeId',
            label: t('projectType', 'Tipo de proyecto'),
            customRender: (parameterValue, allRowData) => <CustomRenderString value={allRowData?.CustomerProjectType?.name} />
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            customRender: (parameterValue, allRowData) => <EditRow key={'edit' + parameterValue} {...{ parameterValue, allRowData }} />
        },
    ]
    return (
        <TwinTable name='CustomerProjects' columns={columns} defaultOrder={{ order: 'desc', defaultOrderField: 'createdAt' }} getDataFrom='/api/app/customer/customerProject/getAllCustomerProjects'
            onRowClick={(_id, rowData) => setOpened({
                type: 'edit',
                allRowData: rowData
            })}
            setTableInstance={setTableInstance} />
    )
}

const customersAndProjectTypesDispatch = {
    setCustomers: (payload: CustomerPayload) => ({ type: 'CHANGE_CUSTOMER', payload }),
    setCustomerProjectTypes: (payload: CustomerProjectTypePayload) => ({ type: 'CHANGE_CUSTOMER_PROJECT_TYPES', payload })
}

export type ReduxCustomerAndProjectTypes = ConnectedProps<typeof customersAndProjectTypesConnect>
const mapCustomersAndProjectTypesConnector = (state: AllReduxPayloads) => ({ customers: state.customers, customerProjectTypes: state.customerProjectTypes })
const customersAndProjectTypesConnect = connect(mapCustomersAndProjectTypesConnector, customersAndProjectTypesDispatch)

const customersAndProjectTypesConnectLoading = withLoading(TableCustomerProjects, [{ fetchUrl: '/api/app/customer/getAllCustomers', propName: 'customers', setFunctionName: 'setCustomers' }, { fetchUrl: '/api/app/customer/customerProject/customerProjectTypes/getAllCustomerProjectTypesFull', propName: 'customerProjectTypes', setFunctionName: 'setCustomerProjectTypes' }])

const TableCustomerProjectsConnect = customersAndProjectTypesConnect(customersAndProjectTypesConnectLoading)


export default CustomerProjects