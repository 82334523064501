import React, { useState } from 'react'
import { ComponentWithPermissions } from '../../CreateRoutering/types'
import { RowData, TableInstanceType } from '../types'
import { TitleSButtonPermissioned } from '../../Layout/TitleAndRightLayout'
import useUpdateTableInstance from '../../../utils/hooks/useUpdateTableInstance'
import { BaseScreenPageData} from '../BaseScreenTable/types'
import {ModalComponentProps, ModalEditComponentProps } from '../../ModalsLayouts/types'
import { TableComponentBaseScreenInnerProps } from './types'
import useEditDeleteModal from '../../../utils/hooks/useEditDeleteModal'
import { ConnectedComponent } from 'react-redux'

interface BaseScreenTableInnerProps extends ComponentWithPermissions {
    extraWhereParams: {[key: string]: any}
    basicData?: {[key: string]: any}
    pageData: BaseScreenPageData
    TableComponent: React.FC<TableComponentBaseScreenInnerProps> | ConnectedComponent<React.FC<TableComponentBaseScreenInnerProps & any>, any>
    CreateModalComponent: React.FC<ModalEditComponentProps> | ConnectedComponent<React.FC<ModalComponentProps & any>, any>
    EditModalComponent?: React.FC<ModalEditComponentProps>
    DeleteModalComponent?: React.FC<ModalEditComponentProps>
}

const BaseScreenTableInner: React.FC<BaseScreenTableInnerProps> = ({ pageData, TableComponent, CreateModalComponent, EditModalComponent, DeleteModalComponent, userPermissions, extraWhereParams, basicData }) => {
    const { openModal, setOpenModal, tableInstance, setTableInstance } = useBaseScreenTableInnerLogic()
    const { updateTableInstance } = useUpdateTableInstance({ tableInstance })
    const myAllRowData: RowData = {...openModal?.allRowData}
    if(basicData){
        myAllRowData.basicData = basicData
    }
    return (
        <TitleSButtonPermissioned title={pageData.title} buttonText={pageData.newText} userPermissions={userPermissions} onClick={() => setOpenModal({type: 'create', allRowData: {}})}>
            <TableComponent setTableInstance={setTableInstance} userPermissions={userPermissions} setOpened={setOpenModal} extraWhereParams={extraWhereParams} tableInstance={tableInstance}/>
            {openModal?.type === 'create' && <CreateModalComponent {...{ setOpened: () => setOpenModal(null) }} onSubmit={updateTableInstance} userPermissions={userPermissions}/>}
            {(openModal?.type === 'edit' && EditModalComponent) && <EditModalComponent {...{ setOpened: () => setOpenModal(null) }} onSubmit={updateTableInstance} userPermissions={userPermissions} allRowData={myAllRowData} />}
            {(openModal?.type === 'delete' && DeleteModalComponent) && <DeleteModalComponent {...{ setOpened: () => setOpenModal(null) }} onSubmit={updateTableInstance} userPermissions={userPermissions} allRowData={myAllRowData} />}
        </TitleSButtonPermissioned>
    )
}

const useBaseScreenTableInnerLogic = () => {
    const { openModal, setOpenModal } = useEditDeleteModal()
    const [tableInstance, setTableInstance] = useState<TableInstanceType>(null)
    return { openModal, setOpenModal, setTableInstance, tableInstance }
}

export default BaseScreenTableInner