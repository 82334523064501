import { BuildCustomSelectClassProps } from './types'

export const buildCustomSelectClass = (props: BuildCustomSelectClassProps) => {
    const customSelectClass: string[] = []
    if (props.labelUp) {
        customSelectClass.push('custom_select_label_up')
    }
    if (props.opened) {
        customSelectClass.push('opened')
    }
    return customSelectClass.join(' ')
}