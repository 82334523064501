import { CostSellModelType } from '@teinor/erp/types/company/config/cost/costSell'
import { dictionaryComplexFromJsonArr } from '../../globals/dictionary'

export interface CostSellsAction {
    type: 'CHANGE_COSTSELL'
    payload: CostSellModelType[]
}
export type CostSellPayload = { [id: number]: CostSellModelType } | null

export default function costsells(state: CostSellPayload = null, action: CostSellsAction): CostSellPayload {
    switch (action.type) {
        case 'CHANGE_COSTSELL':
            const costsells = dictionaryComplexFromJsonArr(action.payload || [])
            return state = costsells
        default:
            return state
    }
}