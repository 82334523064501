import { BigTitleAndRightLayout } from '../../../../../baseComponents/Layout/TitleAndRightLayout'
import useTwinTranslation from '../../../../../utils/hooks/useTwinTranslation'
import { useLocation, useParams } from 'react-router'
import { ComponentTabObject } from '../../../../../utils/hooks/useTabLogic'
import { getBasePath } from '../../../../../utils/globals/link'
import { EditCardTabsHorizontalTabs } from '../../../../../baseComponents/EditCardTabs'
import TimeControlEmployeeHolidays from './TimeControlEmployeeHolidays'
import PunchInUser from './PunchInUser'
import MyMonthTimeControl from './MyMonthTimeControl'

interface UserTimeControlProps  { }

const UserTimeControl: React.FC<UserTimeControlProps> = () => {
    const { t } = useTwinTranslation()
    const location = useLocation()
    const params = useParams<{ id: string, tab?: string }>()
    const tabs: ComponentTabObject = {
        'calendar': { component: PunchInUser , text: t('general', 'General') },
        'holidayDays': { component: TimeControlEmployeeHolidays, text: t('freeDays', 'Días festivos')},
        'myTimeControlMonth': { component: MyMonthTimeControl, text: t('myTimeControlMonth', 'Mi control horario del mes') }
    }
    
    return (
        <BigTitleAndRightLayout title={t('timeControl', 'Control horario')} >
            <EditCardTabsHorizontalTabs basePath={getBasePath(location.pathname, 'timeControl')} tabs={tabs} tab={params.tab}  />
        </BigTitleAndRightLayout>
    )
}


export default UserTimeControl