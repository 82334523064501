import { EmployeePayslipModelType } from '@teinor/erp/types/company/userInner/employee/employeePayslip'
import { useCallback, useEffect, useState } from 'react'
import LoadingSpinner from '../../../../../../../../../../baseComponents/LoaderDecider/LoadingSpinner'
import { ModalLittle } from '../../../../../../../../../../baseComponents/Modal'
import { ModalCreateEditStructureLayout } from '../../../../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../../../../../../../../baseComponents/ModalsLayouts/types'
import FormRenderer from '../../../../../../../../../../forms/FormRenderer'
import { FormRender } from '../../../../../../../../../../forms/FormRenderer/types'
import { twinFetchPostJSON } from '../../../../../../../../../../utils/globals/data'
import { displayDate } from '../../../../../../../../../../utils/globals/date'
import useTwinTranslation from '../../../../../../../../../../utils/hooks/useTwinTranslation'


interface ModalEmployeePayslipReceivedProps extends ModalEmployeePayslipReceivedLogicProps { }

const ModalEmployeePayslipReceived: React.FC<ModalEmployeePayslipReceivedProps> = ({setOpened, allRowData, onSubmit }) => {
    const { t } = useTwinTranslation()
    const { data } = useModalEmployeePayslipReceivedLogic({ allRowData, setOpened, onSubmit })
    if (!data) {
        return (
            <ModalLittle opened={true} setOpened={setOpened} onClickOut={false} className={'flex flex-col'}>
                <LoadingSpinner />
            </ModalLittle>
        )
    }

    const fields: FormRender<EmployeePayslipModelType> = [
        {
            cols: 1,
            elements: [
                {
                    name: 'name',
                    component: 'TextField',
                    label: t('namePayslip', 'Nombre de la nómina'),
                    value: data?.name
                },
                {
                    name: 'dateStart',
                    component: 'TextField',
                    label: t('from', 'Desde'),
                    value: displayDate(new Date(data?.dateStart || ''))
                },
                {
                    name: 'dateEnd',
                    component: 'TextField',
                    label: t('to', 'Hasta'),
                    value: displayDate(new Date(data?.dateEnd || '')),
                },
                {
                    name: 'amount',
                    component: 'DisplayPriceField',
                    label: t('amount', 'Importe'),
                    CurrencyId: data?.CurrencyId,
                    value: data?.amount
                },
                {
                    name: 'updatedAt',
                    component: 'TextField',
                    label: t('approvedDate', 'Fecha de envio'),
                    value: displayDate(new Date(data?.updatedAt))
                },
            ]
        },
    ]

    return (
        <ModalLittle opened={true} setOpened={setOpened} onClickOut={false} className={'flex flex-col'}>
            <ModalCreateEditStructureLayout loading={false} translations={{
                title: t('payslipInfo',  'Información de la nómina recibida'),
                button: ''
            }} haveButtonPermissions={false} >
                <div className='overflow-auto flex flex-auto flex-col'>
                    <FormRenderer sections={fields} className='grid_mincontent' />
                </div>
            </ModalCreateEditStructureLayout>
        </ModalLittle>
    )
}

interface ModalEmployeePayslipReceivedLogicProps extends ModalEditComponentProps {}

const useModalEmployeePayslipReceivedLogic = ({ allRowData }: ModalEmployeePayslipReceivedLogicProps) => {
    const [data, setData] = useState<EmployeePayslipModelType | null>(null)

    const getPayslipData = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/employee/employeePayslip/getEmployeePayslipInstance', { id: allRowData?.SubId })
        if (result) {
            setData(result)
        }
    }, [setData, allRowData?.SubId])

    useEffect(() => {
        getPayslipData()
    }, [getPayslipData])

    return { data }
}

export default ModalEmployeePayslipReceived