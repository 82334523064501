import { faSquareSliders, faTimes, faCheck } from '@fortawesome/pro-light-svg-icons'
import { useState } from 'react'
import { ButtonRed, ButtonPrimary } from '../../Button'
import { ModalOpenedTy } from '../../Modal/types'
import TwinIcon from '../../TwinIcon'
import TwinTrans from '../../TwinTrans'
import { WidgetSetupParams } from '../types'
import { DashboardWidgetAndFamiliesExtraComplete } from '../useDashboardWidgets/types'
import DashboardHeaderColumn from './DashboardHeaderColumn'

type DashboardHeaderProps = WidgetSetupParams & DashboardWidgetAndFamiliesExtraComplete & {
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({ setSetUpOpened, setUpOpened, getUserWidgets, saveUserWidgets, ...rest }) => {
    const {openModal, setOpenModal} = useDashboardHeaderLogic()
    const renderThis: JSX.Element[] = []
    if (!setUpOpened) {
        renderThis.push(<div className='text-green-21 flex items-center cursor-pointer' onClick={() => setSetUpOpened(true)} key={'setUpButtton'}><TwinIcon icon={faSquareSliders} className='h-19 mr-10' /><span><TwinTrans transKey='setUp'>Configurar</TwinTrans></span></div>)
    } else {
        renderThis.push(<div className='text-green-21 flex items-center cursor-pointer mr-10' onClick={() => setOpenModal(true)} key={'setUpAddWidget'}><TwinIcon icon={faSquareSliders} className='h-19 mr-10' /><span><TwinTrans transKey='addWidget'>Añadir widget</TwinTrans></span></div>)
        renderThis.push(<ButtonRed onClick={() => { setSetUpOpened(false); getUserWidgets() } } key={'setUpButttonCancel'} className='mr-10'><TwinIcon icon={faTimes} className='h-19 mr-10' /><span><TwinTrans transKey='cancel'>Cancelar</TwinTrans></span></ButtonRed>)
        renderThis.push(<ButtonPrimary onClick={() => { setSetUpOpened(false); saveUserWidgets() }} key={'setUpButttonApply'}><TwinIcon icon={faCheck} className='h-19 mr-10' /><span><TwinTrans transKey='apply'>Aplicar</TwinTrans></span></ButtonPrimary>)
    }
    return (
        <div className='flex items-center'>
            {renderThis}
            {openModal ? <DashboardHeaderColumn setOpenModal={setOpenModal} {...rest} /> : null}
        </div>
    )
}

const useDashboardHeaderLogic = () => {
    const [openModal, setOpenModal] = useState<ModalOpenedTy>(null)
    return {openModal, setOpenModal}
}

export default DashboardHeader