import { ServiceTypeModelType } from '@teinor/erp/types/company/config/serviceType'
import { dictionaryComplexFromJsonArr } from '../../globals/dictionary'

export interface ServiceTypesAction {
    type: 'CHANGE_SERVICETYPE'
    payload: ServiceTypeModelType[]
}
export type DictionaryServiceType = { [id: number]: ServiceTypeModelType }
export type ServiceTypePayload = DictionaryServiceType | null

export default function serviceTypes(state: ServiceTypePayload = null, action: ServiceTypesAction): DictionaryServiceType | null {
    switch (action.type) {
        case 'CHANGE_SERVICETYPE':
            const dictionaryServiceType: DictionaryServiceType = dictionaryComplexFromJsonArr(action.payload || [])
            return state = dictionaryServiceType
        default:
            return state
    }
}