import { useCallback, useState} from 'react'
import TwinTrans from '../../TwinTrans'
import './verticalSort.sass'
import { closestCenter, DndContext, DragEndEvent, KeyboardSensor, PointerSensor, PointerSensorOptions, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import TwinIcon from '../../TwinIcon';
import { faGripDotsVertical, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { VerticalSortItem } from './types';

interface VerticalSortListProps extends VerticalSortListLogicProps {
    items: VerticalSortItem[]
}

const VerticalSortList: React.FC<VerticalSortListProps> = ({ items, setItems }) => {
    const { sensors, onDragStart, onDragEnd, scrollable } = useVerticalSortListLogic({ setItems })
    const renderThis: JSX.Element[] = []
    const ids = []
    for (const item of items) {
        renderThis.push(<SortableItem key={item.id} id={item.id}  item={item} />)
        ids.push(item.id)
    }
    return (
        <div className='flex-auto h-full overflow-auto'>
            <div className={'vertical_sort_container overflow-hidden' + (!scrollable ? ' h-full' : '')}>
                <div className='vertical_sort_header vertical_sort_row'>
                    <span><TwinTrans transKey='order'>Orden</TwinTrans></span>
                    <span><TwinTrans transKey='name'>Nombre</TwinTrans></span>
                </div>
                <DndContext sensors={sensors} onDragStart={onDragStart} onDragEnd={onDragEnd} collisionDetection={closestCenter} >
                    <SortableContext items={ids}  strategy={verticalListSortingStrategy} >
                        {renderThis}
                    </SortableContext>
                </DndContext>
            </div>
        </div>
    )
}

interface VerticalSortListDeleteProps extends VerticalSortListLogicProps {
    items: VerticalSortItem[]
}

export const VerticalSortListDelete: React.FC<VerticalSortListDeleteProps> = ({ items, setItems }) => {
    const { sensors, onDragStart, onDragEnd, scrollable } = useVerticalSortListLogic({
        setItems,
        pointerSensorOptions: {
            activationConstraint: {
                delay: 200,
                tolerance: 50
            }
        }
    })
    const { handleDelete } = useVerticalSortListDeleteLogic({ setItems })
    const renderThis: JSX.Element[] = []
    const ids = []
    for (const item of items) {
        renderThis.push(<SortableItemDelete key={item.id} id={item.id} item={item} handleDelete={handleDelete} />)
        ids.push(item.id)
    }
    return (
        <div className='flex-auto h-full overflow-auto'>
            <div className={'vertical_sort_container overflow-hidden' + (!scrollable ? ' h-full' : '')}>
                <div className='vertical_sort_header vertical_sort_row'>
                    <span><TwinTrans transKey='order'>Orden</TwinTrans></span>
                    <span><TwinTrans transKey='name'>Nombre</TwinTrans></span>
                </div>
                <DndContext sensors={sensors} onDragStart={onDragStart} onDragEnd={onDragEnd} collisionDetection={closestCenter} >
                    <SortableContext items={ids}  strategy={verticalListSortingStrategy} >
                        {renderThis}
                    </SortableContext>
                </DndContext>
            </div>
        </div>
    )
}

interface VerticalSortListDeleteLogicProps {
    setItems: React.Dispatch<React.SetStateAction<VerticalSortItem[]>>
}

const useVerticalSortListDeleteLogic = ({setItems}: VerticalSortListDeleteLogicProps) => {
    const handleDelete = useCallback((value: VerticalSortItem, e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()
        setItems((old) => {
            const updatedItems: VerticalSortItem[] = []
            
            for (const element of old) {
                if (element.id !== value.id) {
                    updatedItems.push(element)
                }
            }

            for (let i = 0; i < updatedItems.length; i++) {
                updatedItems[i].order = i + 1
            }

            return updatedItems
        })
    }, [setItems])
    return { handleDelete }
}


interface VerticalSortListLogicProps {
    setItems: React.Dispatch<any>
    pointerSensorOptions?: PointerSensorOptions
}

const useVerticalSortListLogic = ({ setItems, pointerSensorOptions }: VerticalSortListLogicProps) => {
    const [scrollable, setScrollable] = useState<boolean>(true)

    const sensors = useSensors(
        useSensor(PointerSensor, pointerSensorOptions),
        useSensor(KeyboardSensor, {
          coordinateGetter: sortableKeyboardCoordinates
        })
    )

      const onDragEnd = useCallback((event: DragEndEvent) => {
          const { active, over } = event

        if(active.id !== over?.id){
            let oldIndex = 0
            let newIndex = 0
            setItems((oldItems: VerticalSortItem[]) => {
                for (const key in oldItems) {
                    const item = oldItems[key]
                    if(active.id === item.id ){
                        oldIndex=parseInt(key)
                    }
                    if(over?.id === item.id ){
                        newIndex=parseInt(key)
                    }
                }
                const items: VerticalSortItem[] = arrayMove(oldItems, oldIndex, newIndex)
                for (const key in items) {
                    items[key].order = parseInt(key) + 1
                }
                return items
            })
            setScrollable(true)
        }
      }, [setItems])
    
    const onDragStart = useCallback(() => {
        const container: any = document.querySelector('.vertical_sort_container')
        const containerParent: any = container?.parentElement
        if(containerParent.scrollHeight > container.offsetHeight){
            setScrollable(false)
        } else{
            setScrollable(true)
        }
    }, [setScrollable])


    return {  onDragStart, onDragEnd, sensors, scrollable}
}

interface SortableItemProps {
    id: string
    item: VerticalSortItem
}

const SortableItem: React.FC<SortableItemProps> = ({id, item}) => {
    const {
        attributes,
        listeners,
        setNodeRef, 
        transform,
        transition
    } = useSortable({id})
    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    }
    return (
        <div className='vertical_sort_list_element vertical_sort_row' ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <span>{item?.order}</span>
            <span>{item?.name}</span>
            <TwinIcon icon={faGripDotsVertical} className='ml-auto move_icon' fontSize={18}/>
        </div>
    )
}

interface SortableItemDeleteProps {
    id: string
    item: VerticalSortItem
    handleDelete: (value: VerticalSortItem, e: React.MouseEvent) => void
}

const SortableItemDelete: React.FC<SortableItemDeleteProps> = ({id, item, handleDelete}) => {
    const {
        attributes,
        listeners,
        setNodeRef, 
        transform,
        transition
    } = useSortable({ id })
    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    }
    return (
        <div className = 'vertical_sort_list_element vertical_sort_row' ref = { setNodeRef } style = { style } {...attributes } {...listeners } >
            <span>{item?.order}</span>
            <span>{item?.name}</span>
            <TwinIcon icon={faGripDotsVertical} className='ml-auto mr-15 move_icon' fontSize={18} />
            <div className='flex justify-center items-center' onClick={(e) => handleDelete(item, e)}>
                <TwinIcon icon={faTrashCan} className='cursor-pointer hover:text-red-BA' size='lg' />
            </div>
        </div>
    )
}

export default VerticalSortList