import { InputHourMinSecStateFull, InputHourMinSecStateFullProps, InputHourMinSecStateLess, InputHourMinSecStateLessProps, RectangularInputHourMinSecStateFull, RectangularInputHourMinSecStateLess } from '..'

export const InputHourMinSecUnlimitedStateLess: React.FC<InputHourMinSecStateLessProps> = ({ ...rest }) => {
    return (
        <InputHourMinSecStateLess {...rest} maxHours={Infinity} />
    )
}
export const InputHourMinSecUnlimitedStateFull: React.FC<InputHourMinSecStateFullProps> = ({ ...rest }) => {
    return (
        <InputHourMinSecStateFull {...rest} maxHours={Infinity} />
    )
}

export const RectangularInputHourMinSecUnlimitedStateLess: React.FC<InputHourMinSecStateLessProps> = ({ ...rest }) => {
    return (
        <RectangularInputHourMinSecStateLess {...rest} maxHours={Infinity} />
    )
}

export const RectangularInputHourMinSecUnlimitedStateFull: React.FC<InputHourMinSecStateFullProps> = ({ ...rest }) => {
    return (
        <RectangularInputHourMinSecStateFull {...rest} maxHours={Infinity} />
    )
}