import { ConnectedProps, connect } from 'react-redux'
import { AllReduxAppPayloads, GlobalContext } from '../../../utils/reducers'
import { BottomNavAppJson, BottomNavAppTy } from '../../../utils/reducers/globals/bottomNavApp'
import ModalTask from './ModalTask'
import ModalWorkingTask from './ModalWorkingTask'
import './modalBottomNavApp.sass'
import { useCallback, useEffect, useMemo } from 'react'
import { listenSocket, unlistenSocket } from '../../../utils/globals/socket'
import { getUserInfo } from '../../../utils/reducers/getters'
import { addBottomNavAppElement, deleteBottomNavAppElement } from '../../../utils/reducers/reduxDispatch'
import { TwinDictionary } from '../../../utils/globals/dictionary'

interface ModalBottomNavAppProps extends ModalBottomNavAppLogicProps{ }

interface ModalBottomWidths {
    opened: number
    closed: number
}
const elementsWidth: Record<BottomNavAppTy, ModalBottomWidths> = {
    workingModal: {
        opened: 320,
        closed: 320
    },
    taskModal: {
        opened: 770,
        closed: 320
    }
}

const ModalBottomNavApp: React.FC<ModalBottomNavAppProps> = ({ bottomNavApp, ...rest }) => {
    const { sortedKeys } = useModalBottomNavAppLogic({ bottomNavApp, ...rest })

    if (!sortedKeys?.length) {
        return null
    }
    const renderThis: JSX.Element[] = []
    const windowWidth = window.innerWidth
    let totalWidthFloating = 0
    
    for (const key of sortedKeys) {
        const element = bottomNavApp[key]
        if(element){
            const type = element.type
            const widths = elementsWidth[type]
            if(element.extraData.opened){
                totalWidthFloating+= widths.opened
            }else{
                totalWidthFloating += widths.closed
            }
            if (windowWidth > totalWidthFloating) {
                renderThis.push(<ModalBottomNavAppElement key={key} bottomNavAppJson={element} />)
            }
        }
    }
    return (
        <div className='modal_bottom_nav_app fixed h-50 bottom-0 z-1'>
            <div className=' flex items-end overflow-x-auto flex-row-reverse'>
                {renderThis}
            </div> 
        </div>
    )
}

interface ModalBottomNavAppLogicProps extends ReduxBottomNavAppConnector {

}
const useModalBottomNavAppLogic = ({bottomNavApp}: ModalBottomNavAppLogicProps) => {
    const user = getUserInfo()

    const sortedKeys = useMemo(() => {
        const sortedElementsIds = []
        const reservedPositions: TwinDictionary = {
            'workingModal': 0
        }
        const reservedPositionElements = new Array(Object.keys(reservedPositions).length)
        for (const key of Object.keys(bottomNavApp).reverse()) {
            if (reservedPositions[key] !== undefined) {
                reservedPositionElements[reservedPositions[key]] = key
            } else {
                sortedElementsIds.push(key)
            }
        }
        const filteredReservedPositionElements = reservedPositionElements.filter((item) => item)
        const sortedElementsIdsParsed: string[] = [...filteredReservedPositionElements, ...sortedElementsIds]
        return sortedElementsIdsParsed
    }, [bottomNavApp])

    const updateModalBottomBySocket = useCallback(async (dataStr?: string) => {
        if (dataStr) {
            const parsedData = JSON.parse(dataStr)
            const typeParse = parsedData['updateProp']
            if (parsedData['WindowId'] !== user?.WindowId && typeParse === 'createTaskWorkLog') {
                addBottomNavAppElement({ type: 'workingModal', key: 'workingModal', extraData: { id: parsedData?.createTaskWorkLog?.id, name: parsedData?.createTaskWorkLog?.name, TaskId: parsedData?.createTaskWorkLog?.TaskId, startDate: parsedData?.createTaskWorkLog?.startDate } })
            } else if (parsedData['WindowId'] !== user?.WindowId && typeParse === 'endTaskWorkLog') {
                await deleteBottomNavAppElement('workingModal')
            }
        }
    }, [user?.WindowId])    

    useEffect(() => {
        listenSocket('workingModal_' + user?.Employee?.id, (changedParams) => { updateModalBottomBySocket(changedParams) })
        return () => {
            unlistenSocket('workingModal_' + user?.Employee?.id)
        }
    }, [user?.Employee?.id, updateModalBottomBySocket])

    return { sortedKeys }
}


const bottomNavAppDispatch = {
    deleteBottomNavAppElement: () => ({ type: 'DELETE_BOTTOMNAVAPP' }),
}
const mapBottomNavAppConnector = (state: AllReduxAppPayloads) => ({ bottomNavApp: state.bottomNavApp })
const bottomNavAppConnector = connect(mapBottomNavAppConnector, bottomNavAppDispatch, null, { context: GlobalContext })
type ReduxBottomNavAppConnector = ConnectedProps<typeof bottomNavAppConnector>


interface ModalBottomNavAppElementProps {
    bottomNavAppJson: BottomNavAppJson
}

type FormRendererSingleElements = Record<BottomNavAppTy, React.FC<any>>
const ModalBottomNavAppElement: React.FC<ModalBottomNavAppElementProps> = ({ bottomNavAppJson }) => {
    const elements: FormRendererSingleElements = {
        workingModal: ModalWorkingTask,
        taskModal: ModalTask
    }
    if (bottomNavAppJson){
        const { type, extraData} = bottomNavAppJson
        const MyComponent = elements[type]
        return <MyComponent {...{ extraData }} />
    }
    return null
}

export default bottomNavAppConnector(ModalBottomNavApp)