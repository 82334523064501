import { CustomerModelType } from '@teinor/erp/types/company/customer'
import { CustomerBankModelType } from '@teinor/erp/types/company/customer/customerBank'
import { ComponentWithPermissions } from '../../../../../../../../../baseComponents/CreateRoutering/types'
import { permissionCheck } from '../../../../../../../../../baseComponents/PermissionChecker/function'
import TwinTable from '../../../../../../../../../baseComponents/TwinTable'
import BaseScreenTableInner from '../../../../../../../../../baseComponents/TwinTable/BaseScreenTableInner'
import { TableComponentBaseScreenInnerProps } from '../../../../../../../../../baseComponents/TwinTable/BaseScreenTableInner/types'
import { CustomRenderBoolean, EditRow, DeleteRow } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import ModalCECustomerBank from './ModalCECustomerBank'

interface CustomerBanksProps extends ComponentWithPermissions {
    basicData: CustomerModelType | null
}

const CustomerBanks: React.FC<CustomerBanksProps> = ({ basicData, userPermissions }) => {
    const { t } = useTwinTranslation()

    const pageData = {
        title: t('banks', 'Bancos'),
        newText: t('addBank', 'Nuevo banco'),
    }

    return (
        <BaseScreenTableInner TableComponent={TableCustomerBanks} CreateModalComponent={ModalCECustomerBank} EditModalComponent={ModalCECustomerBank} pageData={pageData} userPermissions={userPermissions} extraWhereParams={{ CustomerId: basicData?.id }} />
    )
}
interface TableCustomerBanksProps extends TableComponentBaseScreenInnerProps { }

const TableCustomerBanks: React.FC<TableCustomerBanksProps> = ({ setOpened, setTableInstance, userPermissions, extraWhereParams }) => {
    const { t } = useTwinTranslation()

    const columns: ColumnTableSchema<CustomerBankModelType> = [
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            },
            width: 150
        },
        {
            id: 'defaultBank',
            dataKey: 'defaultBank',
            label: t('default', 'Por defecto'),
            width: 150,
            customRender: (parameterValue) => <CustomRenderBoolean value={String(parameterValue) === 'true'} width={75} />
        },
        {
            id: 'iban',
            dataKey: 'iban',
            label: t('iban', 'IBAN'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('iban', 'IBAN'), }
            }
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            customRender: (parameterValue, allRowData) => <EditRow key={'edit' + parameterValue} {...{ parameterValue, allRowData }} />
        },
        {
            id: 'delete',
            dataKey: 'id',
            label: '',
            width: 50,
            customRender: (parameterValue, allRowData) => <DeleteRow key={'delete' + parameterValue} {...{ parameterValue, allRowData }} onClick={(_idk, allRowData) => setOpened({ type: 'delete', allRowData })} />,
            havePermission: permissionCheck(userPermissions, 'delete')
        }
    ]
    return (
            <TwinTable columns={columns} name='customerBanks' getDataFrom='/api/app/customer/customerBank/getAllCustomerBanks'  extraWhereParams={extraWhereParams}  onRowClick={(_id, rowData) => setOpened({
                type: 'edit',
                allRowData: rowData
            })} setTableInstance={setTableInstance} />
    )
}

export default CustomerBanks