import { CustomerModelType } from '@teinor/erp/types/company/customer'
import { ComponentWithPermissions } from '../../../../../../../../../baseComponents/CreateRoutering/types'
import TwinFileSystem from '../../../../../../../../../baseComponents/TwinFileSystem'


interface CustomerB2BFilesProps extends ComponentWithPermissions {
    basicData: CustomerModelType | null
}

const CustomerB2BFiles: React.FC<CustomerB2BFilesProps> = ({ userPermissions, basicData }) => {
    if (basicData?.id) {
        return (<TwinFileSystem subFolder={'/' + basicData.id + '/customerFolder'} type='customer' userPermissions={userPermissions}></TwinFileSystem>)
    }
    return null
}

export default CustomerB2BFiles