import { Calendar, CalendarProps, HeaderProps, ToolbarProps, View, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import TwinIcon from '../TwinIcon'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons'
import useTwinTranslation from '../../utils/hooks/useTwinTranslation'
import { twinCalendarDayAndWeekDayFormat, twinCalendarMonthYearFormat } from '../TwinCalendar/functions'
import YearView from './YearView'
import { ButtonSecondary } from '../Button'
import TwinTrans from '../TwinTrans'
import { Modify } from '../../utils/globals/types'
import { TwinBigCalendarEvents } from './types'
import './twinbigcalendar.sass'

export type TwinBigCalendarProps = Modify<CalendarProps, {
    localizer?: never
    className?: string
    defaultView?: View | 'year'
    events?: TwinBigCalendarEvents
}>


const TwinBigCalendar: React.FC<TwinBigCalendarProps> = ({ className, defaultView = 'month', components, ...rest}) => {
    let startComponents: any = { toolbar: CustomToolBar, timeGutterHeader: CustomTimeGutterHeader, week: { header: DefaultDayWeek } }
    if(components){
        let myComponents: any = components
        for (const key in myComponents) {
            const value = myComponents[key]
            if (typeof value === 'object' && startComponents[key]){
                startComponents[key] = {...startComponents[key], ...value}
            }else {
                startComponents[key] = value
            }
        }
    }
    const localizer = momentLocalizer(moment)
    return (
        <div className={('flex-auto h-1 ' + (className || ''))}>
            <Calendar
                timeslots={1}
                step={60}
                localizer={localizer}
                startAccessor="start"
                endAccessor="end"
                components={startComponents}
                views={{
                    day: true,
                    week: true,
                    month: true,
                    year: YearView
                } as any}
                defaultView={defaultView as any}
                getDrilldownView={(props, view)=>{if(view === 'month'){return 'week'}}}
                eventPropGetter={(event: any) => {
                    const isLessThanHour = ((event.end - event.start) / 1000 / 60 / 60) < 1
                    return ({
                        style: {
                            backgroundColor: event.color || undefined,
                            borderColor: event.borderColor || 'transparent', borderWidth: '1px',
                            color: event.textColor || undefined,
                            flexFlow: isLessThanHour ? 'row' : 'column wrap' 
                        }
                    })
                }}
                {...rest}
            />
        </div>
    )
}

interface DefaultDayWeekProps extends HeaderProps {}

const DefaultDayWeek: React.FC<DefaultDayWeekProps> = ({date}) => {
    const {day, weekDay} = twinCalendarDayAndWeekDayFormat('es-ES', date)

    return (
        <div className=' flex flex-col items-center'>
            <div>{weekDay}</div>
            <div className='week_num_day'>{day}</div>
        </div>
    )
}

interface CustomToolBarProps extends ToolbarProps {

}

const CustomToolBar: React.FC<CustomToolBarProps> = ({ date, onNavigate, onView, view }) => {
    const { t } = useTwinTranslation()
    const parsedDate = view !=='year' as any? twinCalendarMonthYearFormat('es-ES', date) : date.getFullYear()
    return (
        <div className='calendar_toolbar flex items-center justify-between mx-25 mb-10'>
            <div className='flex items-center justify-center'>
                <TwinIcon onClick={() => onNavigate('PREV')} className='cursor-pointer mr-24 h-24 hover:text-green-21' icon={faChevronLeft}/>
                <TwinIcon onClick={() => onNavigate('NEXT')} className='cursor-pointer mr-24 h-24 hover:text-green-21' icon={faChevronRight} />
                <div className='toolbar-label  text-left text-gray-51 capitalize'>{parsedDate}</div>
            </div>
            <div className='flex items-center justify-center right_side_calendar_toolbar'>
                <ButtonSecondary className='mr-50 h-40' onClick={() => onNavigate('TODAY')}><TwinTrans transKey='backToToday'>Volver a hoy</TwinTrans></ButtonSecondary>
                <div className='rbc-toolbar'>
                    <CustomToolBarOption keyTime={'year' as any} text={t('year', 'Año')} viewActive={view} {...{ onView }} />
                    <CustomToolBarOption keyTime='month' text={t('monthC', 'Mes')} viewActive={view} {...{ onView }} />
                    <CustomToolBarOption keyTime='week' text={t('week', 'Semana')} viewActive={view} {...{ onView }} />
                </div>  
            </div>
        </div>
    )
}

type ViewExtTy = View | 'year'
interface CustomToolBarOptionProps {
    keyTime: ViewExtTy
    text: string
    viewActive: ViewExtTy
    onView: (val: any) => void
}

const CustomToolBarOption: React.FC<CustomToolBarOptionProps> = ({ viewActive, keyTime, onView, text}) => {
    return (
        <div className={viewActive === keyTime ? 'active' : ''} onClick={() => onView(keyTime)}>
            {text}
        </div>
    )
}


interface CustomTimeGutterHeaderProps {}

const CustomTimeGutterHeader: React.FC<CustomTimeGutterHeaderProps> = () => {
    return (
        <div className='custom_time_gutter'>
            <TwinTrans transKey='allDay'> Todo el día</TwinTrans>
        </div>
    )
}



export default TwinBigCalendar