import { TwinDictionary } from '../../../../../../../../../../utils/globals/dictionary'
import { WCalParentExtendedTy } from '../../../types'

export const checkIsBiggerThanSmaller = (biggerNumber: number, smallNumber: number) => {
    if (biggerNumber > smallNumber) {
        return true
    }
    return false
}
export const parseEmployeeWCalsFreeWCalSpecials = (basicData: WCalParentExtendedTy | null) => {
    let Employees: number[] = []
    let WCalsFree: number[] = []
    let WCalSpecials: number[] = []
    if (basicData?.WCalsFree) {
        WCalsFree = getIdFromArray(basicData?.WCalsFree)
    }
    if (basicData?.Employees) {
        Employees = getIdFromArray(basicData?.Employees)
    }
    if (basicData?.WCalSpecials) {
        WCalSpecials = getIdFromArray(basicData?.WCalSpecials)
    }
    return { Employees: Employees.join(';'), WCalsFree: WCalsFree.join(';'), WCalSpecials: WCalSpecials.join(';') }
}
const getIdFromArray = (array: TwinDictionary[]) => {
    let result: number[] = []
    for (const element of array) {
        result.push(element.id)
    }
    return result
}