import { faBell } from '@fortawesome/pro-light-svg-icons'
import { faBell as faBellSolid } from '@fortawesome/pro-solid-svg-icons'
import { useState } from 'react'
import { HeaderElementTask } from '..'
import { ModalOpenedTy } from '../../../../../baseComponents/Modal/types'
import { SetTaskDataExtended } from '../../types'
import ModalTaskSubscriber from './ModalTaskSubscriber'

interface HeaderTaskSubscriberProps {
    id: string
    message: string
    is_subscribed: boolean
    setData: SetTaskDataExtended
}

const HeaderTaskSubscriber: React.FC<HeaderTaskSubscriberProps> = ({ id, is_subscribed, setData, message }) => {
    const { opened, setOpened } = useHeaderTaskSubscriberLogic()
    return (
        <HeaderElementTask id={'subscriber_' + id} message={message} classNameIcon={is_subscribed ? 'text-green-43' : ''} icon={is_subscribed ? faBellSolid : faBell} onClick={() => setOpened((old) => old ? null : true)}>
            {opened ? <ModalTaskSubscriber TaskId={parseInt(id)} {...{ setOpened, setData, is_subscribed }} /> : null}
        </HeaderElementTask>
    )
}

const useHeaderTaskSubscriberLogic = () => {
    const [opened, setOpened] = useState<ModalOpenedTy>(null)

    return { opened, setOpened }
}


export default HeaderTaskSubscriber