import { useCallback } from 'react'
import { OnSubmit } from '../../forms/TwinForm/types'
import { twinFetchPostText } from '../globals/data'
import { TwinDictionary } from '../globals/dictionary'

interface FetchTextProps {
    onSubmit: OnSubmit
    url: string
    data: TwinDictionary
}

export const useFetchText = ({ onSubmit, url, data }: FetchTextProps) => {
    const handleFetchText = useCallback(async () => {
        const res = await twinFetchPostText(url, {
            ...data
        })
        onSubmit?.(res, data || {})
    }, [onSubmit, url, data])
    return { handleFetchText }
}