import { faLockKeyhole } from '@fortawesome/pro-light-svg-icons'
import { Fragment, useCallback, useState } from 'react'
import { useParams } from 'react-router'
import CustomError from '../../../../../baseComponents/CustomError'
import TwinTrans from '../../../../../baseComponents/TwinTrans'
import { BeforeSubmitHandler } from '../../../../../forms/TwinForm/types'
import FormRenderer from '../../../../../forms/FormRenderer'
import { FormRender } from '../../../../../forms/FormRenderer/types'
import { returnClassOfSpanTemplate } from '../../../../../utils/globals/tailwind'
import useTwinTranslation from '../../../../../utils/hooks/useTwinTranslation'
import { FooterLink, FormAuthentication } from '../../Login/Components'
import { checkPassword, checkPasswordLength } from './functions'
import useIsLoading from '../../../../../utils/hooks/useIsLoading'


interface RegisterFormProps extends UseRegisterLogicProps { }

export const RegisterForm: React.FC<RegisterFormProps> = ({...props}) => {
    const { token } = useParams<{token: string }>()
    const { t } = useTwinTranslation()
    const { beforeSubmitHandler, handleOnSubmit, errors, loading } = useRegisterLogic(props)
    const fields: FormRender = [
        {
            cols: 3,
            elements: [
                {
                    name: 'name',
                    component: 'Input',
                    placeholder: t('name', 'Nombre'),
                    required: true
                },
                {
                    name: 'surname',
                    placeholder: t('surname', 'Apellidos'),
                    component: 'Input',
                    required: true,
                    className: returnClassOfSpanTemplate(2)
                },
                {
                    name: 'email',
                    placeholder: t('email', 'Email'),
                    component: 'Input',
                    required: true,
                    type: 'email',
                    className: returnClassOfSpanTemplate(3)
                },
                {
                    name: 'password',
                    placeholder: t('password', 'Contraseña'),
                    component: 'InputPassword',
                    required: true,
                    icon: faLockKeyhole,
                    className: returnClassOfSpanTemplate(3)
                },
                {
                    name: 'confirmPassword',
                    placeholder: t('confirm_password', 'Confirmar contraseña'),
                    component: 'InputPassword',
                    required: true,
                    icon: faLockKeyhole,
                    className: returnClassOfSpanTemplate(3)
                },
                {
                    name: 'companyToken',
                    component: 'InputHidden',
                    value: token
                }
            ]
        },
    ]

    return (
        <Fragment>
            <div className='text-center mb-32'>
                <h1 className='mb-22'>
                    <TwinTrans transKey='createAccount'>
                        Crear una cuenta
                    </TwinTrans>
                </h1>
                <span className='regular14'>
                    <TwinTrans transKey='enterDataToRegister'>
                        Introduce tus datos para registrarte
                    </TwinTrans>
                </span>
            </div>
            <CustomError textError={errors} className='mb-10' />
            <FormAuthentication buttonText={t('create', 'Crear')} action='/api/user/createUser' onSubmit={handleOnSubmit} beforeSubmitHandler={beforeSubmitHandler} loading={loading}>
                <FormRenderer sections={fields} allSectionsInnerClassName='gap-x-24' />
            </FormAuthentication>
            <FooterLink textLink={t('haveAccount', 'Ya tengo cuenta')} link={'/'}></FooterLink>
        </Fragment>
    );
}

interface UseRegisterLogicProps {
    changeShow: () => void
}

const useRegisterLogic = ({ changeShow }: UseRegisterLogicProps) => {
    const { t } = useTwinTranslation()
    const {startLoading, loading, endLoading} = useIsLoading()
    const [errors, setErrors] = useState<false | string>(false)

    const handleOnSubmit = useCallback(async (res: Response) => {
        if (res.status === 200) {
            await changeShow()
            endLoading()
        } else {
            const responseJson = await res.json()
            setErrors(responseJson.errors[0].msg)
            endLoading()
        }
    }, [changeShow, endLoading])
    
    const beforeSubmitHandler: BeforeSubmitHandler = useCallback((vals) => {
        if (!checkPassword(vals)) {
            const error = t('notSamePass', 'Las contraseñas no son iguales')
            setErrors(error)
            return false
        } else if (checkPasswordLength(vals)) {
            const error = t('incorrectPasswordLength', 'La contraseña debe de tener al menos 8 carácteres')
            setErrors(error)
            return false
        }
        startLoading()
        setErrors(false)
        return true
    }, [t, startLoading])

    return { beforeSubmitHandler, handleOnSubmit, errors, loading }
}