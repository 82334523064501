import { CostBuyModelType } from '@teinor/erp/types/company/config/cost/costBuy'
import { dictionaryComplexFromJsonArr } from '../../globals/dictionary'

export interface CostBuysAction {
    type: 'CHANGE_COSTBUY'
    payload: CostBuyModelType[]
}
export type CostBuyPayload = { [id: number]: CostBuyModelType } | null

export default function costbuys(state: CostBuyPayload = null, action: CostBuysAction): CostBuyPayload {
    switch (action.type) {
        case 'CHANGE_COSTBUY':
            const costbuys = dictionaryComplexFromJsonArr(action.payload || [])
            return state = costbuys
        default:
            return state
    }
}