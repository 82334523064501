import { useCallback, useRef, useState } from 'react'
import { InputHidden, RectangularInputProps } from '..'
import TwinIcon from '../../../baseComponents/TwinIcon'
import { CustomRenderIcon } from '../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import useOnClickOutside from '../../../utils/hooks/useOnClickOut'
import { dictIcon } from '../../../utils/icons'
import { CustomSelectDropdownIcon } from '../../CustomSelect/DropDown'


export type InputIconProps = RectangularInputProps & InputIconLogicProps & {
}

const InputIcon: React.FC<InputIconProps> = ({ className, label, onChangeIcon, defaultValue, ...inputRest }) => {
    const readOnly = inputRest.readOnly
    const { showPicker, handleShowPicker, handleMyIcon, myIcon, ref, iconName } = useInputIconLogic({ onChangeIcon, defaultValue })
    const borderColor = showPicker ? 'border-green-43' : 'border-gray-97'
    const textColor = showPicker ? 'text-green-43' : ''
    return (
        <div ref={ref} >
            <div className={`flex cursor-pointer relative border-b ${borderColor} pb-8 mt-6`} onClick={readOnly ? undefined : handleShowPicker}>
                <CustomRenderIcon icon={dictIcon[myIcon]} className={textColor} />
                <span className={`ml-10 ${textColor}`}>{label}</span>
                <CustomSelectDropdownIcon opened={showPicker} />
            </div>
            <InputHidden value={iconName} {...inputRest} />
            {showPicker ? <div className='relative'><Icons handleMyIcon={handleMyIcon} /></div> : null}
        </div>
    )
}
interface InputIconLogicProps {
    defaultValue?: string
    onChangeIcon?: (value: string) => void
}

const useInputIconLogic = ({ defaultValue, onChangeIcon }: InputIconLogicProps) => {
    const [showPicker, setShowPicker] = useState<boolean>(false)
    const [myIcon, setMyIcon] = useState<string>(defaultValue || 'faCalendarDays')
    const ref = useRef<any>(null)
    const iconName = myIcon.toString()

    useOnClickOutside(ref, () => setShowPicker(false))

    const handleShowPicker = useCallback(() => {
        setShowPicker((old) => !old)
    }, [setShowPicker])

    const handleMyIcon = useCallback((icon: string) => {
        setMyIcon(icon)
        setShowPicker(false)
        onChangeIcon?.(icon)
    }, [setMyIcon, onChangeIcon])

    return { showPicker, handleShowPicker, handleMyIcon, myIcon, ref, iconName }
}

interface IconsProps {
    handleMyIcon: (icon: string) => void
}

const Icons: React.FC<IconsProps> = ({ handleMyIcon}) => {
    const renderThis: JSX.Element[] = []
    for (const icon of Object.keys(dictIcon)) {
        renderThis.push(<div className='grid justify-center cursor-pointer' onClick={() =>handleMyIcon(icon)}><TwinIcon icon={dictIcon[icon]} className='w-27 h-27'/></div>)
    }

    return (
        <div className='shadow rounded-md absolute left-0 right-0 z-10 bg-white'>
            <div className='grid grid-cols-6 py-20 px-20 gap-15 max-h-188 overflow-y-auto'>{renderThis}</div>
        </div>
    )
}



export default InputIcon