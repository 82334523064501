import { dictionaryComplexFromJsonArr } from '../../globals/dictionary'
import { ShippingZoneGroupModelType } from '@teinor/erp/types/company/config/shipping/shippingZoneGroup'

export interface ShippingZoneGroupsAction {
    type: 'CHANGE_SHIPPINGZONEGROUP'
    payload: ShippingZoneGroupModelType[]
}
export type DictionaryShippingZoneGroup = { [id: number]: ShippingZoneGroupModelType }

export type ShippingZoneGroupPayload = { [id: number]: ShippingZoneGroupModelType } | null

export default function shippingZoneGroups(state: ShippingZoneGroupPayload = null, action: ShippingZoneGroupsAction): DictionaryShippingZoneGroup | null {
    switch (action.type) {
        case 'CHANGE_SHIPPINGZONEGROUP':
            const shippingZoneGroups = dictionaryComplexFromJsonArr(action.payload || [])
            return state = shippingZoneGroups
        default:
            return state
    }
}