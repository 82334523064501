import { faTrashCan } from '@fortawesome/pro-light-svg-icons'
import { HeaderElementTask } from '..'
import { useState } from 'react'
import ModalTaskDelete from './ModalTaskDelete'
import { UpdateTaskTy } from '../../types'

interface HeaderTaskDeleteProps {
    id: string
    message: string
    onDeletedTask: () => Promise<void>
    updateTask: UpdateTaskTy
}

const HeaderTaskDelete: React.FC<HeaderTaskDeleteProps> = ({id, message, ...rest}) => {
    const { opened, setOpened } = useHeaderTaskDeleteLogic()
    return (
        <HeaderElementTask id={'delete_' + id} message={message} icon={faTrashCan} onClick={() => setOpened(true)}>
            {opened ? <ModalTaskDelete opened={true} setOpened={() => setOpened(null)} {...rest} /> : null }
        </HeaderElementTask>
    )
}

const useHeaderTaskDeleteLogic = () => {
    const [opened, setOpened] = useState<true | null>(null)

    return { opened, setOpened }
}

export default HeaderTaskDelete