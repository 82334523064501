import { useLocation, useParams } from 'react-router-dom'
import { ComponentWithPermissions } from '../../../../../../../baseComponents/CreateRoutering/types'
import { getBasePath } from '../../../../../../../utils/globals/link'
import TasksTableDefault from '../../../../../../../specificComponents/Task/TasksTableDefault'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import { GroupPayload } from '../../../../../../../utils/reducers/groups/groups'
import { AllReduxPayloads } from '../../../../../../../utils/reducers'
import { ConnectedProps, connect } from 'react-redux'
import withLoading from '../../../../../../../utils/hoc/withLoading'

type TaskByGroupProps = ComponentWithPermissions & ReduxTaskByGroupConnector & { }

const TaskByGroup: React.FC<TaskByGroupProps> = ({ userPermissions, groups }) => {
    const {t, tVars} = useTwinTranslation()
    const location = useLocation()
    const { GroupId } = useParams()
    const basePath = getBasePath(location.pathname, GroupId || '')
    let nameGroup = ''
    if(GroupId && groups?.[parseInt(GroupId)]?.name){
        nameGroup = groups?.[parseInt(GroupId)].name
    }
    const pageData = {
        title: tVars('tasksOfNameGroup', 'Tareas de {{nameGroup}}', { nameGroup }), 
        newText: t('newTask', 'Nueva tarea')
    }
    return (
        <TasksTableDefault limitPath={GroupId || ''} extraCreateTaskParams={{ GroupId }} name='tableTasksByGroup' listenSocketStr={'getAllTasksGroup_' + GroupId} getDataFrom={'/api/app/task/getAllTasksByGroup'} createRowDataLink={[basePath, ['$id']]}  {...{ userPermissions, basePath, pageData }} extraWhereParams={{ GroupId }} />
    )
}

const taskByGroupDispatch = {
    setGroups: (payload:GroupPayload) => ({ type: 'CHANGE_GROUP', payload }),
}

const mapTaskByGroupConnector = (state: AllReduxPayloads) => ({ groups: state.groups })
const taskByGroupConnector = connect(mapTaskByGroupConnector, taskByGroupDispatch)
type ReduxTaskByGroupConnector = ConnectedProps<typeof taskByGroupConnector>
const taskByGroupConnectLoading = withLoading(TaskByGroup, [{ fetchUrl: '/api/app/group/getAllGroupsListing', propName: 'groups', setFunctionName: 'setGroups' }])

export const TaskByGroupConnect = taskByGroupConnector(taskByGroupConnectLoading)

export default TaskByGroupConnect