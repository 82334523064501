import { faCircleCheck } from '@fortawesome/pro-light-svg-icons'
import { CustomerProjectStatusModelType } from '@teinor/erp/types/company/customer/customerProjectStatus'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { EditCardTabsHorizontalWithoutLinks } from '../../../baseComponents/EditCardTabs'
import { ModalBig } from '../../../baseComponents/Modal'
import { ModalEditComponentProps } from '../../../baseComponents/ModalsLayouts/types'
import TwinIcon from '../../../baseComponents/TwinIcon'
import { RowData } from '../../../baseComponents/TwinTable/types'
import CustomSelectColor from '../../../forms/CustomSelect/CustomSelectColor'
import { InputCalendarStateFull } from '../../../forms/Input/InputCalendar'
import { TextAreaDebounce } from '../../../forms/Input/TextArea'
import { twinFetchPostJSON } from '../../../utils/globals/data'
import { displayDate } from '../../../utils/globals/date'
import { dictionaryComplexFromJsonArr, TwinDictionary } from '../../../utils/globals/dictionary'
import withLoading from '../../../utils/hoc/withLoading'
import { ComponentTabObject } from '../../../utils/hooks/useTabLogic'
import useTwinTranslation from '../../../utils/hooks/useTwinTranslation'
import { AllReduxPayloads } from '../../../utils/reducers'
import { EmployeesPayload } from '../../../utils/reducers/company/employees'
import { getAllCustomerProjectTypes, getEmployees, getGroups } from '../../../utils/reducers/getters'
import { GroupPayload } from '../../../utils/reducers/groups/groups'
import { CustomSelectMultipleImageStateFull } from '../../../forms/CustomSelect/CustomSelectMultipleImage'
import CustomerProjectActivityTab from './CustomerProjectActivityTab'
import CustomerProjectCommentTab from './CustomerProjectCommentTab'
import CustomerProjectTaskTab from './CustomerProjectTaskTab'
import { CustomerProjectModelTypeExtended } from './types'
import { TaskCFieldPayload } from '../../../utils/reducers/company/taskCFields'
import { CustomerProjectTypePayload } from '../../../utils/reducers/customers/customerProjectTypes'
import './modaleditcustomerprojectactivity.sass'


interface ModalEditCustomerProjectProps extends ModalEditComponentProps {}

const ModalEditCustomerProject: React.FC<ModalEditCustomerProjectProps> = ({ allRowData, setOpened }) => {
    const { t } = useTwinTranslation()

    const { customerProjectData, setTabActive, tabActive, getCustomerProject, onChangeCustomerProjectDescription, projectStatuses } = useModalEditCustomerProjectLogic({ allRowData: allRowData || {} })

    const tabs: ComponentTabObject = {
        'activity': { component: CustomerProjectActivityTab, text: t('activity', 'Actividad') },
        'tasks': { component: CustomerProjectTaskTab, text: t('tasks', 'Tareas') },
        'comments': { component: CustomerProjectCommentTab, text: t('comments', 'Comentarios') }
    }    

    if (!customerProjectData) {
        return null
    }
    
    return (
        <ModalBig opened={true} setOpened={setOpened} className='flex flex-col' onClickOut={false}>
            <div className='modal_edit_cproject flex-auto flex flex-col'>
                <h2 className='mt-30'>{customerProjectData?.name}</h2>
                <h2 className='text-16 text-gray-51 font-light mt-10'>{allRowData?.Customer?.name}</h2>
                <CustomerProjectFirstHeader customerProjectData={customerProjectData} getCustomerProject={getCustomerProject} id={allRowData?.id}myCustomerProjectStatusesDict={projectStatuses.myCustomerProjectStatusesDict} />
                <CustomerProjectStatusBar CurrentCProjectStatusId={customerProjectData.CurrentCProjectStatusId} customerProjectStatusesHistoryDict={customerProjectData.customerProjectStatusesHistoryDict}  myCustomerProjectStatusesArr={projectStatuses.myCustomerProjectStatusesArr} />
                <TextAreaDebounce className='mt-34 w-full mr-15 modal_edit_customer_project_description' name='description' value={customerProjectData?.description || ''} onChange={onChangeCustomerProjectDescription} label={t('description', 'Descripción')} />
                <EditCardTabsHorizontalWithoutLinks tabs={tabs} tab={tabActive} onClick={setTabActive} extraComponentData={{ customerProjectData, getCustomerProject }} className='customer_project_tabs mt-34 flex-auto flex flex-col' />
            </div>
        </ModalBig>
    )
}

interface ModalEditCustomerProjectLogicProps {
    allRowData: RowData
}

const useModalEditCustomerProjectLogic = ({ allRowData }: ModalEditCustomerProjectLogicProps) => {
    const [tabActive, setTabActive] = useState('activity')
    const [customerProjectData, setCustomerProjectData] = useState<CustomerProjectModelTypeExtended | null>(null)
    const id = allRowData.id

    const projectStatuses = useMemo(() => {
        const customerProjectTypes = getAllCustomerProjectTypes()
        const CustomerProjectTypeId = customerProjectData?.CustomerProjectTypeId
        if (CustomerProjectTypeId) {
            const myCustomerProjectStatusesDict = customerProjectTypes?.[CustomerProjectTypeId]?.customerProjectStatusDict || {}
            const myCustomerProjectStatusesArr = customerProjectTypes?.[CustomerProjectTypeId].CustomerProjectStatuses || []
            return { myCustomerProjectStatusesDict, myCustomerProjectStatusesArr } 
        }
        return { myCustomerProjectStatusesDict: {}, myCustomerProjectStatusesArr: []}
    }, [customerProjectData?.CustomerProjectTypeId])

    const getCustomerProject = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/customer/customerProject/getCustomerProjectInstanceComplete', { id })
        if (result) {
            setCustomerProjectData(() => {
                const itemsEmployees: string[] = []
                const itemsGroups: string[] = []
                const dictCustomerProjectStatusHistory = dictionaryComplexFromJsonArr(result?.CustomerProjectStatusHistories, 'CustomerProjectStatusId', undefined, true)
                for (const element of result.Employees) {
                    itemsEmployees.push(String(element.id))
                }
                for (const element of result.Groups) {
                    itemsGroups.push(String(element.id))
                }

                return {
                    ...result,
                    dataSelectedEmployees: itemsEmployees,
                    dataSelectedGroups: itemsGroups,
                    customerProjectStatusesHistoryDict: dictCustomerProjectStatusHistory
                }
            })
        }
    }, [setCustomerProjectData, id])

    const onChangeCustomerProjectDescription = useCallback(async (value: string) => {
        setCustomerProjectData((old) => {
            if (!old) {
                return null
            }
            return {...old, description: value}
        })
        await twinFetchPostJSON('/api/app/customer/customerProject/updateCustomerProject', {
            id,
            description: value
        })
    }, [id])

    useEffect(() => {
        getCustomerProject()
    }, [getCustomerProject])

    return { customerProjectData, tabActive, setTabActive, getCustomerProject, onChangeCustomerProjectDescription, projectStatuses }
}

interface CustomerProjectFirstHeaderProps extends CustomerProjectFirstHeaderLogicProps {
    myCustomerProjectStatusesDict: TwinDictionary
    customerProjectData: CustomerProjectModelTypeExtended
}

const CustomerProjectFirstHeader: React.FC<CustomerProjectFirstHeaderProps> = ({ customerProjectData, myCustomerProjectStatusesDict, getCustomerProject, id }) => {
    const { t } = useTwinTranslation()
    const employees = getEmployees()
    const groups = getGroups()
    const { handleOnChangeCustomerProjectLimitDate, handleOnChangeCustomerProjectStatus, handleOnAddEmployeeSelector, handleOnDeleteEmployeeSelector, handleOnAddGroupSelector, handleOnDeleteGroupSelector } = useCustomerProjectFirstHeaderLogic({ getCustomerProject, id })
    return (
        <div className='mt-18 flex items-start justify-between'>
            <div className='mr-4 flex gap-5'>
                <CustomSelectMultipleImageStateFull itemsSelected={customerProjectData?.dataSelectedEmployees} defaultImage='/user-png.png' items={employees || {}} onAdd={handleOnAddEmployeeSelector} onDelete={handleOnDeleteEmployeeSelector} label={t('employeesAssigned', 'Empleados asignados')} type={'Employees'} fieldImage='profile_image' fieldName='fullname_short'/>
                <CustomSelectMultipleImageStateFull itemsSelected={customerProjectData?.dataSelectedGroups} defaultImage='/group.png' items={groups || {}} onAdd={handleOnAddGroupSelector} onDelete={handleOnDeleteGroupSelector} label={t('groupsAssigned', 'Grupos asignados')} type={'Groups'} fieldImage='photo' />
            </div>
            <div className='flex flex-auto gap-20'>
                <InputCalendarStateFull label={t('limitDate', 'Fecha límite')} onlyValids={true} value={customerProjectData?.limit_date} className='w-full' onChange={handleOnChangeCustomerProjectLimitDate}></InputCalendarStateFull>
                <CustomSelectColor items={myCustomerProjectStatusesDict} value={customerProjectData?.CurrentCProjectStatusId} label={t('changeStatus', 'Cambiar de estado')} onChange={handleOnChangeCustomerProjectStatus} />
            </div>
        </div>
    )
}

interface CustomerProjectFirstHeaderLogicProps {
    getCustomerProject: () => Promise<void>
    id: number
}

const useCustomerProjectFirstHeaderLogic = ({ getCustomerProject, id }: CustomerProjectFirstHeaderLogicProps) => {

    const handleOnAddEmployeeSelector = useCallback(async (value: string) => {
        const result = await twinFetchPostJSON('/api/app/customer/customerProject/assignCustomerProjectEmployee', {
            CustomerProjectId: id,
            EmployeeId: value
        })
        if (result) {
            getCustomerProject()
        }
    }, [id, getCustomerProject])

    const handleOnDeleteEmployeeSelector = useCallback(async (value: string) => {
        const result = await twinFetchPostJSON('/api/app/customer/customerProject/removeCustomerProjectEmployee', {
            CustomerProjectId: id,
            EmployeeId: value
        })
        if (result) {
            getCustomerProject()
        }
    }, [id, getCustomerProject])
    const handleOnAddGroupSelector = useCallback(async (value: string) => {
        const result = await twinFetchPostJSON('/api/app/customer/customerProject/assignCustomerProjectGroup', {
            CustomerProjectId: id,
            GroupId: parseInt(value)
        })
        if (result) {
            getCustomerProject()
        }
    }, [id, getCustomerProject])

    const handleOnDeleteGroupSelector = useCallback(async (value: string) => {
        const result = await twinFetchPostJSON('/api/app/customer/customerProject/removeCustomerProjectGroup', {
            CustomerProjectId: id,
            GroupId: parseInt(value)
        })
        if (result) {
            getCustomerProject()
        }
    }, [id, getCustomerProject])

    const handleOnChangeCustomerProjectLimitDate = useCallback(async (value: any) => {
        await twinFetchPostJSON('/api/app/customer/customerProject/updateCustomerProject', {
            id,
            limit_date: value
        })
    }, [id])

    const handleOnChangeCustomerProjectStatus = useCallback(async (CurrentCProjectStatusId: string) => {
        await twinFetchPostJSON('/api/app/customer/customerProject/updateCustomerProjectCurrentStatus', {
            id,
            CurrentCProjectStatusId
        })
        getCustomerProject()
    }, [id, getCustomerProject])

    useEffect(() => {
        getCustomerProject()
    }, [getCustomerProject])

    return { handleOnAddEmployeeSelector, handleOnDeleteEmployeeSelector, handleOnAddGroupSelector, handleOnDeleteGroupSelector, handleOnChangeCustomerProjectLimitDate, handleOnChangeCustomerProjectStatus }
}



interface CustomerProjectStatusBarProps  {
    CurrentCProjectStatusId: number
    customerProjectStatusesHistoryDict: TwinDictionary
    myCustomerProjectStatusesArr: CustomerProjectStatusModelType[]
}

const CustomerProjectStatusBar: React.FC<CustomerProjectStatusBarProps> = ({ CurrentCProjectStatusId, myCustomerProjectStatusesArr, customerProjectStatusesHistoryDict }) => {
    const renderThis: JSX.Element[] = []
    let lastDate: null | Date = null
    let isColored = CurrentCProjectStatusId ? true : false
    for (const element of myCustomerProjectStatusesArr) {
        if (element.listing) {
            const finishedDate = customerProjectStatusesHistoryDict[element.id]?.finishedDate
            let displayDateDate = finishedDate ? new Date(finishedDate) : null
            if (!lastDate && displayDateDate) {
                lastDate = displayDateDate
            }
            if (displayDateDate && lastDate && displayDateDate < lastDate) {
                displayDateDate = null
            } else if(displayDateDate) {
                lastDate = displayDateDate
            }
            const isCurrentStatus = CurrentCProjectStatusId === element.id 
            if (isCurrentStatus) {
                const createdAt = customerProjectStatusesHistoryDict[element.id]?.createdAt
                displayDateDate = new Date(createdAt)
            }
            renderThis.push(
                <CustomerProjectStatusBox name={element.name} displayDate={isColored && displayDateDate ? displayDate(displayDateDate) : ''} color={isColored ? element.color : '#D9D9D9'} hasIcon={!isCurrentStatus} key={element.id}/>
            )
            if (isCurrentStatus) {
                isColored = false
            }
        }
    }
    return (
        <div className='mt-23 flex gap-2 overflow-auto'>
            {renderThis}
        </div>
    )
}

interface CustomerProjectStatusBoxProps {
    displayDate?: string
    name: string
    color?: string
    hasIcon?: boolean
}

const CustomerProjectStatusBox: React.FC<CustomerProjectStatusBoxProps> = ({ displayDate, name, color, hasIcon }) => {
    return (
        <div className='flex flex-col customer_project_status_box p-10 bg-gray-F7 border-t-4' style={{borderColor: color}}>
            <span className='text-gray-51 text-14 twin_elipsis'>{name}</span>
            {displayDate ? <div className='flex justify-between items-center h-24'>
                <span className='text-gray-51 text-12 font-light mr-40'>{displayDate}</span>
                {hasIcon ? <TwinIcon icon={faCircleCheck} className='text-green-43 w-20 h-20'></TwinIcon> : null}
            </div> : null}
        </div>
    )
}

const customerEmployeeGroupDispatch = {
    setGroups: (payload: GroupPayload) => ({ type: 'CHANGE_GROUP', payload }),
    setEmployees: (payload: EmployeesPayload) => ({ type: 'CHANGE_EMPLOYEE', payload }),
    setTaskCFields: (payload: TaskCFieldPayload) => ({ type: 'CHANGE_TASKCFIELD', payload }),
    setCustomerProjectTypes: (payload: CustomerProjectTypePayload) => ({ type: 'CHANGE_CUSTOMER_PROJECT_TYPES', payload }),
}

export type ReduxCustomerEmployeeGroup= ConnectedProps<typeof customerEmployeeGroupConnect>
const mapCustomerEmployeeGroupConnector = (state: AllReduxPayloads) => ({ groups: state.groups, employees: state.employees, taskCFields: state.taskCFields, customerProjectTypes: state.customerProjectTypes })
const customerEmployeeGroupConnect = connect(mapCustomerEmployeeGroupConnector, customerEmployeeGroupDispatch)

const customerEmployeeGroupConnectLoading = withLoading(ModalEditCustomerProject, [{ fetchUrl: '/api/app/group/getAllGroupsListing', propName: 'groups', setFunctionName: 'setGroups' }, { fetchUrl: '/api/app/employee/getAllEmployees', propName: 'employees', setFunctionName: 'setEmployees' }, { fetchUrl: '/api/app/task/customField/getAllTaskCFields', propName: 'taskCFields', setFunctionName: 'setTaskCFields' }, { fetchUrl: '/api/app/customer/customerProject/customerProjectTypes/getAllCustomerProjectTypesFull', propName: 'customerProjectTypes', setFunctionName: 'setCustomerProjectTypes' }])

const ModalEditCustomerProjectConnect = customerEmployeeGroupConnect(customerEmployeeGroupConnectLoading)

export default ModalEditCustomerProjectConnect