import { CustomerGroupModelType } from '@teinor/erp/types/company/customer/customerGroup'
import { customerTypesInversed, CustomerTypesInversedKeys } from '@teinor/erp/types/company/customer/customerTypes'


interface CustomerGroupModelTypeCustom extends CustomerGroupModelType{
    customName: string
}
type ReduxCustomerGroup = 'B2BCustomerGroups' | 'B2CCustomerGroups' | 'ContactGroups'

interface CustomerGroupsParsed {
    B2BCustomerGroups: CustomerGroupModelType[] 
    B2CCustomerGroups: CustomerGroupModelType[]
    ContactGroups: CustomerGroupModelType[]
    dictionary: {[key: string]: CustomerGroupModelTypeCustom}
}

export interface CustomerGroupAction {
    type: 'CHANGE_CUSTOMER_GROUP'
    payload: CustomerGroupModelType[] 
}
export type CustomerGroupPayload = CustomerGroupsParsed | null
export default function company(state: CustomerGroupPayload = null, action: CustomerGroupAction): CustomerGroupsParsed | null {
    switch (action.type) {
        case 'CHANGE_CUSTOMER_GROUP':
            const customerGroupsTypesDict: Record<CustomerTypesInversedKeys, ReduxCustomerGroup> = {
                0: 'B2CCustomerGroups',
                1: 'B2BCustomerGroups',
                2: 'ContactGroups'
            }
            const result: CustomerGroupsParsed = {
                B2BCustomerGroups : [], 
                B2CCustomerGroups: [],
                ContactGroups: [],
                dictionary: {}
            }
            for (const key in action.payload) {
                const customerGroup = action.payload[key]
                result.dictionary[customerGroup.id] = { ...customerGroup, customName: customerGroup.name + ' - ' + customerTypesInversed[customerGroup.customer_type] }
                const element = customerGroupsTypesDict[customerGroup.customer_type] 
                if (element) {
                    const item = result[element]
                    item.push(customerGroup)
                }
            }
            return state = result
        default:
            return state
    }
}