import { CustomerShopModelType } from '@teinor/erp/types/company/customer/customerShop'
import { SetStateAction, useCallback, useEffect, useState } from 'react'
import { UserPermissionsSingle } from '../../../../baseComponents/CreateRoutering/types'
import LoadingSpinner from '../../../../baseComponents/LoaderDecider/LoadingSpinner'
import { ModalCreateEditStructureLayout } from '../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import FormRenderer from '../../../../forms/FormRenderer'
import { FormRender } from '../../../../forms/FormRenderer/types'
import TwinForm from '../../../../forms/TwinForm'
import { OnSubmit } from '../../../../forms/TwinForm/types'
import { getDictionaryStates } from '../../../../utils/globals/countries'
import { twinFetchPostJSON } from '../../../../utils/globals/data'
import { valueOrDefaultValue } from '../../../../utils/globals/valueOrDefaultValue'
import useCountryState from '../../../../utils/hooks/useCountry'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import { getDictionaryCountries } from '../../../../utils/reducers/getters'
import { ModalCreateTaskWithHelperData } from '../types'

interface SectionCEWorkingCenterProps extends SectionCEWorkingCenterLogicProps {
    userPermissions: UserPermissionsSingle | undefined
}

export const SectionCEWorkingCenter: React.FC<SectionCEWorkingCenterProps> = ({ setDataTask, type, dataTask, setType, getCustomerProjectTaskTypeInstance, userPermissions }) => {
    const { t } = useTwinTranslation()
    const { handleOnSubmit, workingCenterInstance, handleOnSubmitCreate } = useSectionCEWorkingCenterLogic({ setDataTask, type, dataTask, setType, getCustomerProjectTaskTypeInstance })
    const { country, setCountry } = useCountryState({ defaultCountry: workingCenterInstance?.country })

    const workingCenterFields: FormRender<CustomerShopModelType> = [
        {
            cols: 1,
            elements: [
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'responsible',
                    label: t('responsible', 'Responsable'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'address',
                    label: t('address', 'Dirección'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'additional_address',
                    label: t('additional_address', 'Dirección adicional'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'postal_code',
                    label: t('postal_code', 'Código Postal'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'country',
                    label: t('country', 'País'),
                    component: 'CustomSelectWithSearchBar',
                    items: getDictionaryCountries(),
                    value: workingCenterInstance?.country,
                    onChange: (value: string) => setCountry(value)
                },
                {
                    name: 'state',
                    label: t('state', 'Provincia'),
                    component: 'CustomSelectWithSearchBar',
                    sortItems: true,
                    items: getDictionaryStates(country),
                    value: workingCenterInstance?.state,
                },
                {
                    name: 'city',
                    label: t('city', 'Ciudad'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'phone',
                    label: t('phone', 'Teléfono'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'mobile',
                    label: t('mobile', 'Móvil'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'email',
                    label: t('email', 'Email'),
                    component: 'InputWithLabelMargin',
                    type: 'email',
                },
                {
                    name: 'CustomerId',
                    component: 'InputHidden',
                    value: dataTask.CustomerId
                },
                {
                    name: 'id',
                    component: 'InputHidden',
                    value: dataTask.CustomerShopId
                },
            ],
        },
    ]
    if (type === 'editWorkingCenter') {
        if (workingCenterInstance && userPermissions) {
            const parsedFields = valueOrDefaultValue(workingCenterFields, userPermissions, workingCenterInstance)
            return (<TwinForm className='pr-30 flex flex-col flex-auto' action='/api/app/customer/customerShop/updateCustomerShop' onSubmit={handleOnSubmit}>
                <ModalCreateEditStructureLayout loading={false} translations={{
                    title: t('editWorkingCenter', 'Editar centro de trabajo'),
                    button: t('edit', 'Editar'),
                }} haveButtonPermissions={true} className='modal_negative_margin'>
                    <div className='overflow-auto flex flex-auto flex-col'>
                        {parsedFields.length ?
                            <FormRenderer sections={parsedFields} />
                            : null}
                    </div>
                </ModalCreateEditStructureLayout>
            </TwinForm>)
        }
        return <LoadingSpinner />
    }
    return (
        <TwinForm className='pr-30 flex flex-col flex-auto' action='/api/app/customer/customerShop/createCustomerShop' onSubmit={handleOnSubmitCreate}>
            <ModalCreateEditStructureLayout loading={false} translations={{
                title: t('createWorkingCenter', 'Crear centro de trabajo'),
                button: t('create', 'Crear'),
            }} haveButtonPermissions={true} className='modal_negative_margin'>
                <div className='overflow-auto flex flex-auto flex-col'>
                    {workingCenterFields.length ?
                        <FormRenderer sections={workingCenterFields} />
                        : null}
                </div>
            </ModalCreateEditStructureLayout>
        </TwinForm>
    )
}

interface SectionCEWorkingCenterLogicProps {
    dataTask: ModalCreateTaskWithHelperData
    setDataTask: React.Dispatch<SetStateAction<ModalCreateTaskWithHelperData>>
    type: string
    setType: React.Dispatch<SetStateAction<string | null>>
    getCustomerProjectTaskTypeInstance: (id: number) => Promise<void>
}

const useSectionCEWorkingCenterLogic = ({ setDataTask, type, dataTask, setType, getCustomerProjectTaskTypeInstance }: SectionCEWorkingCenterLogicProps) => {
    const [workingCenterInstance, setWorkingCenterInstance] = useState<CustomerShopModelType | null>(null)

    const CustomerId = dataTask.CustomerId
    const CustomerProjectId = dataTask.CustomerProjectId

    const handleOnSubmitCreate: OnSubmit = useCallback(async (_res, values) => {
        const result = await twinFetchPostJSON('/api/app/customer/customerShop/getAllCustomerShops', { where: { name: values.name, CustomerId } })
        if (result) {
            setDataTask((old) => {
                return { ...old, CustomerShopId: result[result.length - 1].id }
            })
        }
        setType(null)
        getCustomerProjectTaskTypeInstance(parseInt(CustomerProjectId))
    }, [setDataTask, setType, getCustomerProjectTaskTypeInstance, CustomerProjectId, CustomerId])

    const handleOnSubmit: OnSubmit = useCallback(async () => {
        setType(null)
        getCustomerProjectTaskTypeInstance(parseInt(CustomerProjectId))
    }, [setType, getCustomerProjectTaskTypeInstance, CustomerProjectId])

    const getWorkingCenterInstance = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/customer/customerShop/getCustomerShopInstance', { id: dataTask.CustomerShopId })
        if (result) {
            setWorkingCenterInstance(result)
        }
    }, [setWorkingCenterInstance, dataTask])

    useEffect(() => {
        if (type === 'editWorkingCenter') {
            getWorkingCenterInstance()
        }
    }, [getWorkingCenterInstance, type])

    return { handleOnSubmit, workingCenterInstance, handleOnSubmitCreate }
}

export default SectionCEWorkingCenter