import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { LittleModalCreateEdit } from '../../../../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../../../../../../../../baseComponents/ModalsLayouts/types'
import { FormRender } from '../../../../../../../../../../forms/FormRenderer/types'
import { twinFetchPostJSON } from '../../../../../../../../../../utils/globals/data'
import { TwinDictionary, dictionaryComplexFromJsonArr } from '../../../../../../../../../../utils/globals/dictionary'
import useTwinTranslation from '../../../../../../../../../../utils/hooks/useTwinTranslation'
import { WorkingCenterEmployeeWithDefaultTy } from '../type'


type ModalCreateEmployeeWorkingCenterProps = ModalEditComponentProps & ModalCreateEmployeeWorkingCenterLogicProps & {}

const ModalCreateEmployeeWorkingCenter: React.FC<ModalCreateEmployeeWorkingCenterProps> = ({ userPermissions, allRowData, selEmployees, ...rest }) => {
    const { t } = useTwinTranslation()
    const { employees, id } = useModalCreateEmployeeWorkingCenterLogic({selEmployees})
    const fields: FormRender<WorkingCenterEmployeeWithDefaultTy> = [
        {
            cols: 1,
            elements: [
                {
                    name: 'EmployeeId',
                    label: t('employee', 'Empleado'),
                    component: 'CustomSelectWithSearchBar',
                    items: employees,
                    required: true
                },
                {
                    name: 'is_default',
                    label: t('isDefault', 'Por defecto'),
                    component: 'Checkbox',
                },
                {
                    name: 'WorkingCenterId',
                    component: 'InputHidden',
                    value: id
                },
            ],
        },
    ]


    return (<LittleModalCreateEdit fields={fields} url={'/api/app/workingCenter/workingCenterEmployee/assignEmployeeToWorkingCenter'} translations={{
        title: t('addEmployee', 'Añadir usuario'),
        button: t('add', 'Añadir')
    }} haveButtonPermissions={true} {...rest} />)
}

interface ModalCreateEmployeeWorkingCenterLogicProps {
    selEmployees: number[]
}

const useModalCreateEmployeeWorkingCenterLogic = ({selEmployees}: ModalCreateEmployeeWorkingCenterLogicProps) => {
    const [employees, setEmployees] = useState<TwinDictionary>({})
    const { id } = useParams()

    const getEmployees = useCallback(async () => {
        const data = await twinFetchPostJSON('/api/app/employee/getAllEmployees', { where: { 'id': { '$not': selEmployees } }})
        const dictEmployees = dictionaryComplexFromJsonArr(data)
        setEmployees(dictEmployees)
    }, [setEmployees, selEmployees])

    useEffect(() => {
        getEmployees()
    }, [getEmployees])
    return {employees, id}
}

export default ModalCreateEmployeeWorkingCenter;