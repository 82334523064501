import ModalCreateEdit from '../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalComponentProps } from '../../../../../../../baseComponents/ModalsLayouts/types'
import { FormRender } from '../../../../../../../forms/FormRenderer/types'
import { getDictionaryStates } from '../../../../../../../utils/globals/countries'
import { returnClassOfSpanTemplate } from '../../../../../../../utils/globals/tailwind'
import useCountryState from '../../../../../../../utils/hooks/useCountry'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import { useTypeIdNumber } from '../../../../../../../utils/hooks/useTypeIdNumber'
import { getAllWorkingCentersTypes, getDictionaryCountries } from '../../../../../../../utils/reducers/getters'
import { WorkingCenterModelTypeExtended } from '../types'

interface ModalCreateWorkingCenterProps extends ModalComponentProps { }

const ModalCreateWorkingCenter: React.FC<ModalCreateWorkingCenterProps> = ({ ...rest }) => {
    const { t } = useTwinTranslation()
    const { country, setCountry } = useCountryState()
    const { typesIdNumber } = useTypeIdNumber()
    const fields: FormRender<WorkingCenterModelTypeExtended> = [
        {
            cols: 2,
            elements: [
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'code',
                    label: t('code', 'Código'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'type_id_number',
                    label: t('typeIdNumber', 'Tipo de documento identificación'),
                    component: 'CustomSelect',
                    items: typesIdNumber,
                    value: 0
                },
                {
                    name: 'id_number',
                    label: t('id_number', 'DNI'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'workingCenterTypes',
                    component: 'MultipleCheckboxSelector',
                    subtitles: {
                        subtitleOptions: t('typeWorkingCenter', 'Tipo de Centro de trabajo'),
                        subtitleSelectedOptions: t('typeWorkingCenterSelected', 'Tipo de Centro de trabajo seleccionado')
                    }, 
                    items: getAllWorkingCentersTypes() || {},
                    className: returnClassOfSpanTemplate(2)
                },
            ]
        },
        {
            cols: 2,
            title: t('address', 'Dirección'),
            elements: [
                {
                    name: 'address',
                    label: t('address', 'Dirección'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'additional_address',
                    label: t('number', 'Número'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'postal_code',
                    label: t('postal_code', 'Código Postal'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'city',
                    label: t('city', 'Ciudad'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'country',
                    label: t('country', 'País'),
                    component: 'CustomSelectWithSearchBar',
                    items: getDictionaryCountries(),
                    onChange: (value: string) => setCountry(value),
                    required: true
                },
                {
                    name: 'state',
                    label: t('state', 'Provincia'),
                    component: 'CustomSelectWithSearchBar',
                    sortItems: true,
                    items: getDictionaryStates(country),
                }
            ]
        },
        {
            cols: 2,
            title: t('contactInformation', 'Información de contacto'),
            elements: [
                {
                    name: 'email',
                    label: t('email', 'Email'),
                    component: 'InputWithLabelMargin',
                    type: 'email',
                },
                {
                    name: 'phone',
                    label: t('phone', 'Teléfono'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'mobile',
                    label: t('mobile', 'Móvil'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                }
            ]
        }
    ]
    return (
        <ModalCreateEdit fields={fields} url={'/api/app/workingCenter/createWorkingCenter'} translations={{
            title: t('createWorkingCenter', 'Crear Centro de trabajo'),
            button: t('create', 'Crear')
        }} haveButtonPermissions={true} {...rest} />
    )
}

export default ModalCreateWorkingCenter