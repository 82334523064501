import { useCallback } from 'react'
import { TableInstanceType } from '../../baseComponents/TwinTable/types'

export interface UpdateTableInstanceProps {
    tableInstance: TableInstanceType
}

const useUpdateTableInstance = ({ tableInstance }: UpdateTableInstanceProps) => {
    const updateTableInstance = useCallback(() => {
        tableInstance?.getTableDataFromStart()
    }, [tableInstance])

    return { updateTableInstance }

}


export default useUpdateTableInstance