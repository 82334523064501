import { WorkingCenterModelType } from '@teinor/erp/types/company/workingCenter'
import { WorkingCenterCounterModelType } from '@teinor/erp/types/company/workingCenter/workingCenterCounter'
import { arrayFromArrayDictionaryAttribute } from '../../globals/array'
import { dictionaryComplexFromJsonArr } from '../../globals/dictionary'
import { WorkingCenterBankModelType } from '@teinor/erp/types/company/workingCenter/wokingCenterBank'
import { WorkingCenterPaymentMethodModelType } from '@teinor/erp/types/company/workingCenter/workingCenterPaymentMethod'
import { WorkingCenterChargingMethodModelType } from '@teinor/erp/types/company/workingCenter/workingCenterChargingMethod'


type WorkingCenterModelTypeDictFulls = WorkingCenterModelType & {
    dictCounters: {[id: number]: WorkingCenterCounterModelType}
    dictBanks: {[id: number]: WorkingCenterBankModelType}
    dictPaymentMethods: {[id: number]: WorkingCenterPaymentMethodModelType}
    dictChargingMethods: {[id: number]: WorkingCenterChargingMethodModelType}
    idWarehouses: number[]
} 
export interface WorkingCentersAction {
    type: 'CHANGE_WORKINGCENTER'
    payload: WorkingCenterModelType[] 
}
export type DictionaryWorkingCenter = { [id: number]: WorkingCenterModelTypeDictFulls }
export type WorkingCenterPayload = null | DictionaryWorkingCenter

export default function workingCenter(state: WorkingCenterPayload = null, action: WorkingCentersAction): DictionaryWorkingCenter | null {
    switch (action.type) {
        case 'CHANGE_WORKINGCENTER':
            const dictionaryWorkingCenter: DictionaryWorkingCenter = dictionaryComplexFromJsonArr(action.payload || [])
            for (const key in dictionaryWorkingCenter) {
                const workingCenter = dictionaryWorkingCenter[key]
                if(workingCenter.WorkingCenterCounters && workingCenter.WorkingCenterCounters.length){
                    workingCenter['dictCounters'] = dictionaryComplexFromJsonArr(workingCenter.WorkingCenterCounters)
                }
                if(workingCenter.WorkingCenterBanks && workingCenter.WorkingCenterBanks.length){
                    workingCenter['dictBanks'] = dictionaryComplexFromJsonArr(workingCenter.WorkingCenterBanks)
                }
                if(workingCenter.WorkingCenterPaymentMethods && workingCenter.WorkingCenterPaymentMethods.length){
                    workingCenter['dictPaymentMethods'] = dictionaryComplexFromJsonArr(workingCenter.WorkingCenterPaymentMethods)
                }
                if(workingCenter.WorkingCenterChargingMethods && workingCenter.WorkingCenterChargingMethods.length){
                    workingCenter['dictChargingMethods'] = dictionaryComplexFromJsonArr(workingCenter.WorkingCenterChargingMethods)
                }
                if (workingCenter.Warehouses && workingCenter.Warehouses.length) {
                    workingCenter['idWarehouses'] = arrayFromArrayDictionaryAttribute(workingCenter.Warehouses, 'id')
                }
            }
            return state = {...dictionaryWorkingCenter}
        default:
            return state
    }
}