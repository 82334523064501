import { EmployeeExpenseModelType } from '@teinor/erp/types/company/userInner/employee/employeeExpense'
import { EmployeeExpenseRowTypeModelAdd } from '@teinor/erp/types/company/userInner/employee/employeeExpense/employeeExpenseRow/employeeExpenseRowType'
import { useCallback } from 'react'
import { MediumModalCreateEdit } from '../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../../../baseComponents/ModalsLayouts/types'
import { permissionCheck } from '../../../../../baseComponents/PermissionChecker/function'
import FormRenderer from '../../../../../forms/FormRenderer'
import { FormRender } from '../../../../../forms/FormRenderer/types'
import { BeforeSubmitHandler } from '../../../../../forms/TwinForm/types'
import { displayDateByString } from '../../../../../utils/globals/date'
import { returnClassOfSpanTemplate } from '../../../../../utils/globals/tailwind'
import useEmployeeExpensesType from '../../../../../utils/hooks/employeeExpenses/useEmployeeExpensesType'
import useTwinTranslation from '../../../../../utils/hooks/useTwinTranslation'
import EmployeeExpenseNotEditableTableExpenses from './EmployeeExpenseNotEditableTableExpenses'
import EmployeeExpenseNotEditableTotals from './EmployeeExpenseNotEditableTotals'
import { valueOrDefaultValue } from '../../../../../utils/globals/valueOrDefaultValue'


type EmployeeExpenseNotEditableModalProps = ModalEditComponentProps & EmployeeExpenseNotEditableModalLogicProps & {
    expensesType: EmployeeExpenseRowTypeModelAdd[]
    changeFieldExpenseInstance: (value: string, field: keyof EmployeeExpenseModelType) => void
}

const EmployeeExpenseNotEditableModal: React.FC<EmployeeExpenseNotEditableModalProps> = ({ expenseInstance, changeFieldExpenseInstance, expensesType, userPermissions, ...rest }) => {
    const { t } = useTwinTranslation()
    const { expenseStatus } = useEmployeeExpensesType()
    const { beforeSubmitExpensesHandler } = useEmployeeExpenseNotEditableModalLogic({ expenseInstance })
    
    if (!expenseInstance) {
        return null
    }

    const fields: FormRender<EmployeeExpenseModelType> = [
        {
            cols: 2,
            elements: [
                {
                    name: 'EmployeeId',
                    component: 'InputHidden',
                    value: expenseInstance?.EmployeeId
                },
                {
                    name: 'id',
                    component: 'InputHidden',
                    value: expenseInstance?.id,
                },
                {
                    name: 'EmployeeId',
                    label: t('employee', 'Empleado'),
                    component: 'TextField',
                    value: expenseInstance?.Employee?.fullname_short
                },
                {
                    name: 'status',
                    label: t('status', 'Estado'),
                    component: 'TextField',
                    value: expenseInstance ? expenseStatus[expenseInstance?.status].name : '',
                },
                {
                    name: 'dateStart',
                    label: t('dateStart', 'Desde'),
                    component: 'TextField',
                    value: displayDateByString(expenseInstance?.dateStart || ''),
                },
                {
                    name: 'dateEnd',
                    label: t('dateEnd', 'Hasta'),
                    component: 'TextField',
                    value: displayDateByString(expenseInstance?.dateEnd || ''),
                },
                {
                    name: 'CurrencyId',
                    label: t('currency', 'Moneda'),
                    component: 'TextField',
                },
                {
                    name: 'location',
                    label: t('location', 'Ubicación'),
                    component: 'TextField',
                },
                {
                    name: 'km',
                    label: t('km', 'Km'),
                    component: 'TextField',
                },
                {
                    name: 'kmPrice',
                    label: t('kmPrice', 'Precio del Km'),
                    component: 'DisplayPriceField',
                    CurrencyId: expenseInstance?.CurrencyId || '',
                },
                {
                    name: 'abroad',
                    label: t('abroad', 'Extranjero'),
                    component: 'TextField',
                    value: expenseInstance?.abroad ? t('yes', 'Sí') : t('no', 'No'),
                },
                {
                    name: 'overnight_stay',
                    label: t('overnightStay', 'Pernoctación'),
                    component: 'TextField',
                    value: expenseInstance?.abroad ? t('yes', 'Sí') : t('no', 'No'),
                },
                {
                    name: 'updatedAt',
                    label: t('modificatedAt', 'Última modificación'),
                    component: 'TextField',
                    value: displayDateByString(expenseInstance?.updatedAt || ''),
                },
                {
                    name: 'remarks',
                    label: t('remarks', 'Observaciones'),
                    component: (String(expenseInstance?.status) === '3' || String(expenseInstance?.status) === '2') ? (expenseInstance?.remarks ? 'TextField': 'InputHidden'): 'TextArea',
                    onChange: (value) => changeFieldExpenseInstance(value, 'remarks'),
                    className: returnClassOfSpanTemplate(2)
                },
            ]
        },
    ]
    if (userPermissions) {
        const parsedFields = valueOrDefaultValue(fields, userPermissions, expenseInstance)
        return (<MediumModalCreateEdit fields={[]} translations={{
            title: t('editExpensesSheet', 'Editar ficha de gastos'),
            button: t('markAsPaid', 'Marcar como pagado'),
        }} haveButtonPermissions={permissionCheck(userPermissions || false, 'update')  && String(expenseInstance?.status) === '1'} url={'/api/app/employeeExpense/updateEmployeeExpense'} beforeSubmit={beforeSubmitExpensesHandler} {...rest} >
            <FormRenderer sections={parsedFields} className='mb-20' />
            <EmployeeExpenseNotEditableTableExpenses expenseInstance={expenseInstance} expensesType={expensesType}/>
            <EmployeeExpenseNotEditableTotals expenseInstance={expenseInstance}/>
        </MediumModalCreateEdit>)
    }
    return null
}

interface EmployeeExpenseNotEditableModalLogicProps {
    expenseInstance: EmployeeExpenseModelType
}

const useEmployeeExpenseNotEditableModalLogic = ({ expenseInstance }: EmployeeExpenseNotEditableModalLogicProps) => {

    const beforeSubmitExpensesHandler: BeforeSubmitHandler = useCallback((values) => {
        values.status = 3
        values.remarks = expenseInstance?.remarks
        return true
    }, [expenseInstance, ])

    return { beforeSubmitExpensesHandler }
}

export default EmployeeExpenseNotEditableModal