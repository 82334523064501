import { ErpModules } from '@teinor/erp/modules'
import i18n from '../../i18n'

export const getLang = () => {
    const myLang = i18n.language || window.localStorage.i18nextLng
    return myLang
}

export const haveModuleBuyed = (modules?: ErpModules, modulesBuyed?: ErpModules) => {
    if (!modules) {
        return true
    }
    for (const moduleItem of modules) {
        if (modulesBuyed?.includes(moduleItem)) {
            return true
        }
    }
    return false
}

export const numberToFileSize = (number: number) => {
    const KB = number/1024
    if (KB < 1000) {
        return Math.round(KB) + ' KB'
    }
    const MB = KB / 1024
    if (MB < 1000) {
        return Math.round(MB) + ' MB'
    }
    const GB = MB / 1024
    if (GB < 1000) {
        return Math.round(GB) + ' GB'
    }
}