import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { Fragment, useCallback, useState } from 'react';
import TwinIcon from '../../../../baseComponents/TwinIcon';
import TwinTrans from '../../../../baseComponents/TwinTrans';
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation';
import { HeaderAuth, MyLink, TitleAuth } from '../Login/Components';
import { RegisterForm } from './RegisterForm';

export interface RegisterProps { }

const Register: React.FC<RegisterProps> = () => {
    return (
        <Fragment>
            <RegisterHeader />
            <DeciderRegister />
        </Fragment>
    );
}

interface RegisterHeaderProps { }

export const RegisterHeader: React.FC<RegisterHeaderProps> = () => {
    return (
        <Fragment>
            <HeaderAuth />
            <TitleAuth>
                <TwinTrans transKey='loginPageSubtitle'>
                    Bienvenido a la mejor forma de gestionar tu joyería
                </TwinTrans>
            </TitleAuth>
        </Fragment>
    )
}

interface DeciderRegisterProps { }

export const DeciderRegister: React.FC<DeciderRegisterProps> = () => {
    const { showForm, changeShow } = useDeciderRegisterLogic()
    if (!showForm) {
        return (<AccountCreated />)
    }
    return (<RegisterForm {...{ changeShow }} />)
}

const useDeciderRegisterLogic = () => {
    const [showForm, setShowForm] = useState<boolean>(true)

    const changeShow = useCallback(() => {
        setShowForm((oldShowForm) => !oldShowForm)
    }, [setShowForm])

    return { showForm, changeShow }
}

interface AccountCreatedProps {}

export const AccountCreated: React.FC<AccountCreatedProps> = () => {
    const { t } = useTwinTranslation()
    return (
        <div className='flex flex-col items-center justify-between'>
            <div className='flex flex-col items-center justify-between'>
                <RegisterCheckWithText title={t('accountCreated', 'Tu cuenta se ha creado correctamente')} subtitle={t('checkMailConfirmation', 'Revisa tu correo electrónico y haz clic en el mensaje de confirmación para validar tu registro')}/>
                <span className='not_received_mail medium16'>
                    <TwinTrans transKey={'notReceivedMail'}>¿No has recibido ningún correo electrónico?</TwinTrans>
                </span>
                <span className='my-15 regular16'>
                    <TwinTrans transKey={'checkInSpam'}>Revisa tu bandeja de correo no deseado</TwinTrans>
                </span>
                <MyLink className='my-50' text={t('returnToLogin', 'Ir a la página de Inicio sesión')} link={'/'} />
            </div>
        </div>
    )
}


export const RegisterCheckWithText: React.FC<BaseRegisterCheckProps> = ({...rest}) => {
    return (
        <Fragment>
            <TwinIcon icon={faCheckCircle} style={{ width: '44px', height: '44px' }} className='text-green-43' />
            <BaseRegisterCheck {...rest} />
        </Fragment>
    )
}

export const RegisterErrorWithText: React.FC<BaseRegisterCheckProps> = ({ ...rest }) => {
    return (
        <Fragment>
            <TwinIcon icon={faTimesCircle} style={{ width: '44px', height: '44px' }} className='text-red-BA' />
            <BaseRegisterCheck {...rest} />
        </Fragment>
    )
}

interface BaseRegisterCheckProps {
    title: string
    subtitle: string
}

const BaseRegisterCheck: React.FC<BaseRegisterCheckProps> = ({title, subtitle}) => {
    return (
        <Fragment>
            <div className='my-25 account_created'>
                {title}
            </div>
            <span className='check_mail_confirmation regular16'>
                {subtitle}
            </span>
            <div className='register_line'></div>
        </Fragment>
    )
}


export default Register;