import { useState } from 'react'
import { RowData } from '../../baseComponents/TwinTable/types'

export type UseEditDeleteModalProps = UseEditDeleteModalOnProps | null

interface UseEditDeleteModalOnProps {
    type: 'create' | 'edit' | 'delete'
    allRowData: RowData
}

export type EditDeleteModalProps = {
    openModal: UseEditDeleteModalProps
    setOpenModal: SetEditDeleteModalTy
}
export type SetEditDeleteModalTy = React.Dispatch<React.SetStateAction<UseEditDeleteModalProps>>

const useEditDeleteModal = () => {
    const [openModal, setOpenModal] = useState<UseEditDeleteModalProps>(null)

    return { openModal, setOpenModal }
}
export default useEditDeleteModal