import { useEffect, useRef } from 'react'

function useInterval(callback: ()=>void, delay: number | false) {
    const savedCallback = useRef<any>()

    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    useEffect(() => {
        function tick() {
            savedCallback.current()
        }
        if (delay !== false) {
            let id = setInterval(tick, delay)
            return () => clearInterval(id)
        }
    }, [delay])

}

export default useInterval