import { useCallback, useMemo, useState } from 'react'
import { CustomSelectBase, CustomSelectBaseProps } from '..'
import SearchBar from '../../../baseComponents/SearchBar'
import { ChangeValueFunc, Modify } from '../../../utils/globals/types'
import useTwinTranslation from '../../../utils/hooks/useTwinTranslation'
import { CustomSelectDropDown } from '../DropDown'
import { CustomSelectDropDownBasePropsMod, CustomSelectDropDownRowProps } from '../DropDown/types'
import { DisplayTextFuncTy } from '../types'
import { filterCustomSelectItemsBlocked } from './function'
import { CustomSelectBlockedItemsTy, CustomSelectIsBlockedFnc } from './types'
import './customselectblocked.sass'
import TwinIcon from '../../../baseComponents/TwinIcon'
import { faLock } from '@fortawesome/pro-light-svg-icons'


export type CustomSelectBlockedItemsProps = Modify<CustomSelectBaseProps,  {
    items: CustomSelectBlockedItemsTy
    isBlockedChecker: CustomSelectIsBlockedFnc
    DropDownComponent?: never
    displayTextFunc?: never
}>

const CustomSelectBlockedItems: React.FC<CustomSelectBlockedItemsProps> = ({items, isBlockedChecker,  ...rest}) => {
    const { displayTextFunc } = useDropDownBlockedWithSearchBarLogic(items)
    return (
        <CustomSelectBase {...rest} items={items} extraDropDownParams={{isBlockedChecker}} DropDownComponent={CustomSelectBlockedItemsDropDown} {...{displayTextFunc}} />
    )
}

export const CustomSelectBlockedItemsSearchbar: React.FC<CustomSelectBlockedItemsProps> = ({items, isBlockedChecker,  ...rest}) => {
    const {filteredItems, displayTextFunc} = useDropDownBlockedWithSearchBarLogic(items)
    return (
        <CustomSelectBase {...rest} items={filteredItems} extraDropDownParams={{isBlockedChecker}} DropDownComponent={CustomSelectBlockedItemsSearchbarDropDown} {...{displayTextFunc}} />
    )
}

type CustomSelectBlockedDropDownProps = Modify<CustomSelectDropDownBasePropsMod, {
    items: CustomSelectBlockedItemsTy
}>

const CustomSelectBlockedItemsDropDown: React.FC<CustomSelectBlockedDropDownProps> = ({ items, extraParams, children, ...rest }) => {
    const keys = useMemo(() => Object.keys(items), [items])
    const {cancelChange} = useCustomSelectBlockedItemsLogic()
    return (<CustomSelectDropDown items={keys} {...rest} rowRenderer={({ ...rowRenderProps }) => {
        const value = keys[rowRenderProps.index]
        const item = items[value]
        const isBlocked = extraParams?.isBlockedChecker(item)
        return (
            <CustomSelectDropDownRowBlockedItems currentvalue={rest.value} onChange={isBlocked ? cancelChange : rest.onChange} item={{ value, text: item.text }}  {...rowRenderProps} className={isBlocked ? 'is_blocked' : ''} isBlocked={isBlocked? true : false} />
        )
    }}>{children}</CustomSelectDropDown>)
}

const CustomSelectBlockedItemsSearchbarDropDown: React.FC<CustomSelectBlockedDropDownProps> = ({ items, ...rest}) => {
    const {t} = useTwinTranslation()
    const { searchValue, changeSearchValue, filteredItems } = useDropDownBlockedWithSearchBarLogic(items)

    return (
        <CustomSelectBlockedItemsDropDown {...rest} items={filteredItems}>
            <SearchBar onChange={changeSearchValue} searchValue={searchValue} placeholder={t('search', 'Buscar')} className='custom_select_search_bar'></SearchBar>
        </CustomSelectBlockedItemsDropDown>
    )
}

const useDropDownBlockedWithSearchBarLogic = (items: CustomSelectBlockedItemsTy) => {
    const [searchValue, setSearchValue] = useState<string>('')
    const filteredItems = useMemo(() => filterCustomSelectItemsBlocked(searchValue, items), [searchValue, items])
    const changeSearchValue: ChangeValueFunc = useCallback((myvalue) => {
        setSearchValue(myvalue)
    }, [setSearchValue])

    const displayTextFunc: DisplayTextFuncTy = useCallback((items, value) => {
        if(!Array.isArray(items)){
            return items[value]?.text
        }
        return ''
    }, [])
    return { searchValue, changeSearchValue, filteredItems, displayTextFunc }
}


const useCustomSelectBlockedItemsLogic = () => {
    const cancelChange: CustomSelectDropDownRowProps['onChange'] = useCallback((_value, e) => {
        e.stopPropagation()
        e.preventDefault()
    }, [])

    return {cancelChange}
}
interface CustomSelectDropDownRowBlockedItemsProps extends CustomSelectDropDownRowProps {
    isBlocked: boolean
}
export const CustomSelectDropDownRowBlockedItems: React.FC<CustomSelectDropDownRowBlockedItemsProps> = ({ className, onChange, isBlocked, ...rest }) => {
    const { changeValue, text, value } = useDropDownRowBlockedLogic({ onChange, ...rest })
    return (
        <div className={'custom_select_value cursor-pointer flex items-center bg-white regular12 text-gray-51 hover:bg-gray-F7 px-15 ' + (className || '')} onClick={changeValue} {...rest} {...value}>
            {isBlocked ?<TwinIcon className='mr-8' icon={faLock}></TwinIcon>: null}
            <span>{text}</span>
        </div>
    )
}

const useDropDownRowBlockedLogic = ({ item, onChange }: CustomSelectDropDownRowProps) => {
    const value = item.value
    const text = item.text

    const changeValue = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        onChange(value, e)
    }, [onChange, value])

    return { changeValue, text, value: {selectValue: value} }
}

export default CustomSelectBlockedItems