import { useLocation } from 'react-router-dom'
import { ComponentWithPermissions } from '../../../../../../baseComponents/CreateRoutering/types'
import TasksTableDefault from '../../../../../../specificComponents/Task/TasksTableDefault'
import { getBasePath } from '../../../../../../utils/globals/link'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import { getUserInfo } from '../../../../../../utils/reducers/getters'

interface TasksSummaryProps extends ComponentWithPermissions {}

const TasksSummary: React.FC<TasksSummaryProps> = ({ userPermissions }) => {
    const {t} = useTwinTranslation()
    const location = useLocation()
    const basePath = getBasePath(location.pathname, 'tasks')
    const userInfo = getUserInfo()
    const EmployeeId = userInfo?.Employee?.id
    const pageData = {
        title: t('tasks', 'Tareas') ,
        newText: t('newTask', 'Nueva tarea')
    }
    return (
        <TasksTableDefault name='tableTasks' extraCreateTaskParams={{ EmployeeId }} limitPath='tasks' getDataFrom='/api/app/task/getAllTasks' listenSocketStr='getAllTasks' createRowDataLink={[basePath, ['$id']]}  {...{ userPermissions, basePath, pageData }} />
    )
}

export default TasksSummary