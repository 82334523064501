import { useCallback, useEffect, useState } from 'react'
import { ButtonPrimary, ButtonSecondary } from '../../Button'
import { UserPermissions } from '../../CreateRoutering/types'
import LoaderDecider from '../../LoaderDecider'
import { ModalMedium } from '../../Modal'
import { ModalOpenedTy } from '../../Modal/types'
import PermissionChecker from '../../PermissionChecker'
import VerticalSortList from '../VerticalSortList'
import { TableInstanceType } from '../../TwinTable/types'
import TwinTrans from '../../TwinTrans'
import { twinFetchPostJSON } from '../../../utils/globals/data'
import { TwinDictionary } from '../../../utils/globals/dictionary'
import { VerticalSortItem } from '../VerticalSortList/types'

interface ButtonModalSortListProps extends ButtonModalSortListLogicProps {
    userPermissions: UserPermissions
}

const ButtonModalSortList: React.FC<ButtonModalSortListProps> = ({ userPermissions, ...rest }) => {
    const { setOpened, opened, data, setData, updateOrder } = useButtonModalSortListLogic({ ...rest })
    return (
        <PermissionChecker permission='update' userPermissions={userPermissions}>
            <ButtonSecondary onClick={() => setOpened(true)}>
                <TwinTrans transKey={'updateOrder'}>Actualizar orden</TwinTrans>
            </ButtonSecondary>
            {opened && <ModalMedium opened={true} setOpened={setOpened} className='flex flex-col'>
                <h2 className='mb-35'>
                    <TwinTrans transKey='updatePriorityOrder'>Actualizar el orden de prioridad</TwinTrans>
                </h2>
                <LoaderDecider loading={data === null}>
                    {data !== null && <VerticalSortList items={data as any} setItems={setData}></VerticalSortList>}
                </LoaderDecider>
                <ButtonPrimary className='mt-30 ml-auto' onClick={updateOrder}>
                    <TwinTrans transKey='save'>Guardar</TwinTrans>
                </ButtonPrimary>
            </ModalMedium>}
        </PermissionChecker>
    )
}
export type ItemSortFunction = (data: VerticalSortItem[]) => Promise<boolean>
interface ButtonModalSortListLogicProps  {
    getDataFrom: string
    updateData: ItemSortFunction
    tableInstance: TableInstanceType
    extraWhereParams?: TwinDictionary
}

const useButtonModalSortListLogic = ({ tableInstance, getDataFrom, updateData, extraWhereParams }: ButtonModalSortListLogicProps) => {
    const [opened, setOpened] = useState<ModalOpenedTy>(null)
    const [data, setData] = useState<VerticalSortItem[] | null>(null)
    const extraWhereStr = JSON.stringify(extraWhereParams)
    const getData = useCallback(async () => {
        const extraWhereJS = extraWhereStr ? JSON.parse(extraWhereStr) : undefined
        const mydata = await twinFetchPostJSON(getDataFrom, { order: [['order', 'ASC']], where: extraWhereJS })
        if (mydata) {
            setData(mydata)
        }
    }, [getDataFrom, extraWhereStr])

    const updateOrder = useCallback(async () => {
        if (data && await updateData(data)) {
            tableInstance?.getTableDataFromStart()
            setOpened(null)
        }
    }, [data, tableInstance, updateData])

    useEffect(() => {
        if (opened) {
            getData()
        }
    }, [opened, getData])
    return { opened, setOpened, data, setData, updateOrder }
}

export default ButtonModalSortList