import { faChevronDown, faChevronUp, faClock, faXmark } from '@fortawesome/pro-light-svg-icons'
import TwinIcon from '../../../../baseComponents/TwinIcon'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import { ModalOpenedTy } from '../../../../baseComponents/Modal/types'
import { useCallback, useState } from 'react'
import { displayDateWithFormattedHours } from '../../../../utils/globals/date'
import { TaskNotificationModalTasksTy } from '../types'
import { TaskModelType } from '@teinor/erp/types/company/task'
import { TwinDictionary } from '../../../../utils/globals/dictionary'
import { Link } from 'react-router-dom'
import TwinTrans from '../../../../baseComponents/TwinTrans'
import { getEmployeeParams } from '../../../../utils/reducers/getters'
import { addBottomNavAppElement } from '../../../../utils/reducers/reduxDispatch'

interface TaskNotificationModalPopupProps extends TaskNotificationModalPopupDropDownProps {
    animatedClass: string
    handleOnClosePopUpTask: () => void
}

const TaskNotificationModalPopup: React.FC<TaskNotificationModalPopupProps> = ({ animatedClass, handleOnClosePopUpTask, tasks, setTasks }) => {
    const { t } = useTwinTranslation()
    const { handleDropDown, openDropDown } = useTaskNotificationModalPopup()
    return (
        <div className={'opacity_trigger fixed right-5 top-5 z-9 ' + animatedClass}>
            <div className='relative'>
                <div className='flex items-center text-white py-20 px-20 flex justify-center w-290 rounded-8 bg-green-43'>
                    <span>{t('toDoTasks', 'Tienes tareas por hacer')}</span>
                    <div className='flex gap-10 ml-auto'>
                            <TwinIcon icon={openDropDown ? faChevronUp : faChevronDown} onClick={handleDropDown} style={{ width: '18px', height: '18px' }} className=' cursor-pointer' />
                        <TwinIcon icon={faXmark} onClick={handleOnClosePopUpTask} style={{ width: '18px', height: '18px' }} className=' cursor-pointer' />
                    </div>
                </div>
                {openDropDown ?
                    <div className={'opacity_trigger absolute right-0 text-gray-51 flex flex-col justify-center w-390 z-9 bg-white popup_notification_tasks_body ' + animatedClass}>
                    <TaskNotificationModalPopupDropDown tasks={tasks} setTasks={setTasks} />
                </div> : null}
            </div>
        </div>
    )
}

const useTaskNotificationModalPopup = () => {
    const [openDropDown, setOpenDropDown] = useState<ModalOpenedTy>(null)
    const handleDropDown = useCallback(() => {
        setOpenDropDown((old) => {
            if (old) {
                return null
            }
            return true
        })
    }, [setOpenDropDown])
    return { handleDropDown, openDropDown }
}

interface TaskNotificationModalPopupDropDownProps extends TaskNotificationModalPopupDropDownLogicProps {
    tasks: TaskNotificationModalTasksTy['taskToShow']
}

const TaskNotificationModalPopupDropDown: React.FC<TaskNotificationModalPopupDropDownProps> = ({tasks, setTasks}) => {
    const { onHandleRemoveTaskFromNotification } = useTaskNotificationModalPopupDropDownLogic({ setTasks })
    const parameters = getEmployeeParams()
    const floatingTasks = parameters?.['floatingTasks'] === 'on' ? true : false
    const renderThis: JSX.Element[] = []
    for (const key in tasks) {
        const element = tasks[key]
        renderThis.push(<PopUpNotificationTask floatingTasks={floatingTasks} index={key} task={element} onHandleRemoveTaskFromNotification={onHandleRemoveTaskFromNotification} key={element.id} />)
    }
    return (
        <div className='flex flex-col justify-center'>
            {renderThis}
        </div>
    )
}
interface TaskNotificationModalPopupDropDownLogicProps {
    setTasks: React.Dispatch<React.SetStateAction<TaskNotificationModalTasksTy>>
}

const useTaskNotificationModalPopupDropDownLogic = ({ setTasks }: TaskNotificationModalPopupDropDownLogicProps) => {
    const onHandleRemoveTaskFromNotification = useCallback((index: number) => {
        setTasks((old) => {
            const copy = JSON.parse(JSON.stringify(old))
            const taskToShow: TaskModelType[] = copy.taskToShow
            const hiddenTasks: TwinDictionary = copy.hiddenTasks
            const removed = taskToShow.splice(index, 1)
            hiddenTasks[parseInt(String(removed[0]?.id))] = removed[0]?.start_date || ''
            return { taskToShow, hiddenTasks }
        })
    }, [setTasks])
    return { onHandleRemoveTaskFromNotification }
}

interface PopUpNotificationTaskProps extends PopUpNotificationTaskLogicProps {
    index: string
    onHandleRemoveTaskFromNotification: (id: number) => void
    floatingTasks: boolean
}

const PopUpNotificationTask: React.FC<PopUpNotificationTaskProps> = ({ index, task, onHandleRemoveTaskFromNotification, floatingTasks }) => {
    const { handleOpenFloatingTask } = usePopUpNotificationTaskLogic({task})
    return (
        <div className='flex flex-col py-15 px-20 popup_notification_tasks_body_task'>
            <div className='flex items-center'>
                <div>
                    <span className='text-green-43'>#{task.id}</span>
                    <span className='mx-5'>-</span>
                    <span>{task.name}</span>
                </div>
                <TwinIcon icon={faXmark} onClick={() => onHandleRemoveTaskFromNotification(parseInt(index))} fontSize={18} className='ml-auto cursor-pointer'/>
            </div>
            <div className='flex items-end mt-5'>
                <div className='flex items-center mt-2'>
                    <TwinIcon icon={faClock} fontSize={12} />
                    <span className='ml-10 text-gray-97 mt-2 regular12'>{displayDateWithFormattedHours(new Date(task.start_date || ''))}</span>
                </div>
                {floatingTasks ?
                    <span className='mr-5 cursor-pointer ml-auto text-green-43' onClick={handleOpenFloatingTask}><TwinTrans transKey='openTask'>Abrir tarea</TwinTrans></span>
                    :
                    <Link className='mr-5 cursor-pointer ml-auto text-green-43' to={'/task/tasks/' + task?.id}>
                        <TwinTrans transKey='openTask'>Abrir tarea</TwinTrans>
                    </Link>
                }
            </div>
        </div>
    )
}

interface PopUpNotificationTaskLogicProps {
    task: TaskModelType
}

const usePopUpNotificationTaskLogic = ({task}: PopUpNotificationTaskLogicProps) => {
    const handleOpenFloatingTask = useCallback(() => {
        addBottomNavAppElement({ type: 'taskModal', key: 'taskModal-' + task.id, extraData: { id: task.id, opened: true} })
    }, [task.id])

    return { handleOpenFloatingTask }
}


export default TaskNotificationModalPopup