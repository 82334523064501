import { TwinDictionary } from '../globals/dictionary'
import useTwinTranslation from './useTwinTranslation'

export const useTypeIdNumber = () => {
    const { t } = useTwinTranslation()
    const typesIdNumber: TwinDictionary = {
        0: { name: t('idNumberCompany', 'NIF / IVA') },
        1: { name: t('passport', 'Pasaporte') },
        2: { name: t('officialDocumentOtherCountry', 'Documento Oficial de Otro País') },
        3: { name: t('residenceCertificate', 'Certificado de Residencia') },
        4: { name: t('otherDocument', 'Otro documento') },
        5: { name: t('notRegistered', 'No censado') },
    }
    return { typesIdNumber }
}

export const useTypeNationality = () => {
    const { t } = useTwinTranslation()
    const typesNationality: TwinDictionary = {
        0: { name: t('national', 'Nacional') },
        1: { name: t('foreign', 'Extranjeros') },
        2: { name: t('ue', 'UE') },
    }
    return { typesNationality }
}
export const useTypeRepresentative = () => {
    const { t } = useTwinTranslation()
    const typesRepresentative: TwinDictionary = {
        0: { name: t('justBag', 'Solo Maleta') },
        1: { name: t('laptop', 'Portátil') },
    }
    return { typesRepresentative }
}

export const useTypeLaptop = () => {
    const { t } = useTwinTranslation()
    const typesLaptop: TwinDictionary = {
        0: { name: t('noLaptop', 'Sin portátil') },
        1: { name: t('computer', 'Ordenador') },
        2: { name: t('pocket', 'Pocket') },
    }
    return { typesLaptop }
}

export const useInvoiceType = () => {
    const { t } = useTwinTranslation()
    const invoiceTypes: TwinDictionary = {
        0: { name: t('products', 'Productos.') },
        1: { name: t('services', 'Servicios') },
    }
    return { invoiceTypes }
}
export const useRegimeType = () => {
    const { t } = useTwinTranslation()
    const regimeTypes: TwinDictionary = {
        0: { name: t('general', 'General.') },
        1: { name: t('lease', 'Alquiler') },
    }
    return { regimeTypes }
}