import { TaskLogModelType } from '@teinor/erp/types/company/task/taskLog'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import { TwinDictionary } from '../../../../utils/globals/dictionary'
import { displayDateTime } from '../../../../utils/globals/date'

interface TaskLogsProps {
    taskLogs: TaskLogModelType[]
}

const TaskLogs: React.FC<TaskLogsProps> = ({ taskLogs}) => {
    const renderThis: JSX.Element[] = []
    for (const key in taskLogs) {
        const taskLog = taskLogs[key]
        renderThis.push(<TaskLog key={key} {...{taskLog}} />)
    }
    return (
        <div className='mt-10'>
            {renderThis}
        </div>
    )
}

interface TaskLogProps {
    taskLog: TaskLogModelType
}

const TaskLog: React.FC<TaskLogProps> = ({ taskLog }) => {
    const {tVars} = useTwinTranslation()
    const date = new Date(taskLog?.createdAt)
    const extraDataParsed = JSON.parse(taskLog?.extraData || '{}')
    const options = { 'interpolation': { 'escapeValue': false }, taskId: taskLog.TaskId, createdDate: displayDateTime(date), subTaskId: extraDataParsed?.subTaskId || '', employeeName: extraDataParsed?.employeeName || '-', groupName: extraDataParsed?.groupName || '-'}
    const taskLogTypesDic: TwinDictionary = {
        1: tVars('createdTaskLog', 'ha creado la tarea #{{taskId}} - {{createdDate}}', options),
        2: !extraDataParsed.EmployeeId ? tVars('unassignedUserTaskLog', 'ha desasignado el empleado de la tarea #{{taskId}} - {{createdDate}}', options) : tVars('assignedUserTaskLog', 'ha asignado a {{employeeName}} la tarea #{{taskId}} - {{createdDate}}', options),
        3: !extraDataParsed.GroupId ? tVars('unassignedGroupTaskLog', 'ha desasignado el grupo de la tarea #{{taskId}} - {{createdDate}}', options) : tVars('assignedGroupTaskLog', 'ha asignado a {{groupName}} la tarea #{{taskId}} - {{createdDate}}', options),
        4: tVars('createSubTaskLog', 'ha creado la subtarea #{{subTaskId}} - {{createdDate}}', options),
        5: tVars('finishedTaskLog', 'ha finalizado la tarea #{{taskId}} - {{createdDate}}', options),
        6: tVars('unfinishedTaskLog', 'ha reabierto la tarea #{{taskId}} - {{createdDate}}', options),
    }
    return (
        <div>
            <span className='link12'>{taskLog?.Employee?.name} </span>
            <span className='label12'>{taskLogTypesDic[taskLog.type]}</span>
        </div>
    )
}


export default TaskLogs