import { useCallback, useState } from 'react'
import useTwinTranslation from '../../../../../utils/hooks/useTwinTranslation'
import { MyLink } from '../../Login/Components'

import { RegisterCheckWithText, RegisterErrorWithText, RegisterHeader } from '../../Register'
import NewPasswordForm from './NewPasswordForm'

interface NewPasswordProps {}

const NewPassword: React.FC<NewPasswordProps> = () => {
    const { showMessage, handleChangeShowMessage } = useNewPasswordLogic()
    const Component = NewPassDecider({ showMessage })
    return (
        <div>
            <RegisterHeader />
            <Component handleChangeShowMessage={handleChangeShowMessage} />                      
        </div>
    )
}

const useNewPasswordLogic = () => {
    const [showMessage, setShowMessage] = useState<boolean | null>(null)
    const handleChangeShowMessage = useCallback((active: boolean) => {
        setShowMessage(active)
    }, [setShowMessage])

    return { showMessage, handleChangeShowMessage}
}

type NewPasswordDeciderProps = {
    showMessage: boolean | null
}

const NewPassDecider = ({ showMessage}: NewPasswordDeciderProps) => {
    if (showMessage) {
        return MessageChangedPassword
    } else if(showMessage === false) {
        return MessageErrorPassword
    }
    return NewPasswordForm
}

interface MessageChangedPasswordProps {}

const MessageChangedPassword: React.FC<MessageChangedPasswordProps> = () => {
    const {t} = useTwinTranslation()
    return (
        <div className='flex flex-col items-center justify-between'>
            <RegisterCheckWithText title={t('passwordChanged', 'Tu contraseña se ha modificado correctamente')} subtitle={t('preparedToLoginNewPass', 'Ya puedes iniciar sesión con tu email y tu nueva contraseña.')} />
            <MyLink className='my-10 text-center' text={t('returnToLogin', 'Ir a la página de Inicio sesión')} link={'/'} />
        </div>
    )
}

interface MessageErrorPasswordProps {}

const MessageErrorPassword: React.FC<MessageErrorPasswordProps> = () => {
    const { t } = useTwinTranslation()
    return (
        <div className='flex flex-col items-center justify-between'>
            <RegisterErrorWithText title={t('passwordChangeError', 'Error al cambiar la contraseña')} subtitle={t('passwordNoChangedExtended', 'Tu contraseña no se ha modificado. El enlace es inválido o ha caducado. Si no sabes como solucionarlo, contacta a soporte.')} />
            <MyLink className='my-10 text-center' text={t('returnToLogin', 'Ir a la página de Inicio sesión')} link={'/'} />
        </div>
    )
}

export default NewPassword