import { TwinDictionary } from '../../../../utils/globals/dictionary'
import { InputSearchedProductModelTy, InputSearchedProductsTy, InputSearchProductFieldType } from '../types'

interface RenderProductsInputSearchProductProps {
    products: InputSearchedProductsTy | null
    field: InputSearchProductFieldType
handleSelectProduct: (id: number, name: string) => void}

export const RenderProductsInputSearchProduct: React.FC<RenderProductsInputSearchProductProps> = ({ products, field, handleSelectProduct }) => {
    const renderThis: JSX.Element[]= []
    if (!products) {
        return null
    }   
    for (const key in products) {
        const product = products[key]
        renderThis.push(<InputSearchedProduct key={key} {...{product, field, handleSelectProduct}}/>)
    }
    return (
        <div className='input_searched_products'>
            <div className='results_input_searched_products'>
                {renderThis}
            </div>
        </div>
    )
}


interface InputSearchedProductProps {
    product: InputSearchedProductModelTy
    field: InputSearchProductFieldType
handleSelectProduct: (id: number, name: string) => void}

const InputSearchedProduct: React.FC<InputSearchedProductProps> = ({ product, field, handleSelectProduct}) => {
    const productName = product['ProductLangs.name']
    const reference = product.reference
    const code_bar = product.code_bar || ''
    let fields: TwinDictionary = {
        main: productName,
        secondary: reference,
    }
    if (field === 'reference') {
        fields = {
            main: reference,
            secondary: productName,
        }   
    } else if (field === 'code_bar'){
        fields = {
            main: code_bar,
            secondary: productName,
        }   
    }
    return (
        <div className='input_searched_product' onClick={()=>handleSelectProduct(product.id, productName)}>
            <span className='text-green-00 font-medium'>{fields.main}</span>
            <span className='mx-5 text-green-00'>-</span>
            <span>{fields.secondary}</span>
        </div>
    )
}