import { useParams } from 'react-router-dom'
import ModalDeleteFilled from '../../../../../../../../../../baseComponents/ModalsLayouts/ModalDeleteFilled'
import { ModalEditComponentProps } from '../../../../../../../../../../baseComponents/ModalsLayouts/types'
import { useFetchText } from '../../../../../../../../../../utils/hooks/useFetchText'
import useTwinTranslation from '../../../../../../../../../../utils/hooks/useTwinTranslation'

interface ModalDeleteEmployeeWorkingCenterProps extends ModalEditComponentProps { }

const ModalDeleteEmployeeWorkingCenter: React.FC<ModalDeleteEmployeeWorkingCenterProps> = ({ setOpened, onSubmit, allRowData }) => {
    const { t } = useTwinTranslation()
    const params = useParams<{ id: string }>()
    const id = params.id
    
    const { handleFetchText } = useFetchText({
        onSubmit,
        url: '/api/app/workingCenter/workingCenterEmployee/removeEmployeeToWorkingCenter',
        data: {
            EmployeeId: allRowData?.id,
            WorkingCenterId: id
        }
    })
    return (
        <ModalDeleteFilled opened={true} setOpened={setOpened} onCancel={() => setOpened(null)} onAccept={handleFetchText} translations={{
            title: t('sureDeleteEmployeeFromWorkingCenter', '¿Seguro quieres eliminar este empleado de este Centro de trabajo?'),
            subtitle: t('onceDeletedNotRecoverM', 'Una vez eliminado no podrás recuperarlo')
        }} />
    )
}

export default ModalDeleteEmployeeWorkingCenter