import { ModalMedium } from '../../../../../../../baseComponents/Modal'
import { ModalOpenedSetTy } from '../../../../../../../baseComponents/Modal/types'
import TwinTrans from '../../../../../../../baseComponents/TwinTrans'
import DisplayPriceAdmin from '../../../../../components/DisplayPriceAdmin'
import { ModuleInformationIndividualModule } from '../../types'
import { getEditorStateWithContent } from '../../../../../../../forms/TwinEditor/functions'
import { Editor } from 'draft-js'
import { ButtonPrimary, ButtonRed } from '../../../../../../../baseComponents/Button'
import TwinIcon from '../../../../../../../baseComponents/TwinIcon'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { faTimes } from '@fortawesome/pro-light-svg-icons'

interface IndividualModuleModalProps {
    setOpened: ModalOpenedSetTy
    mymodule: ModuleInformationIndividualModule
    addModule: (module_id: string) => void
    deleteModule: (module_id: string) => void
}

const IndividualModuleModal: React.FC<IndividualModuleModalProps> = ({ setOpened, mymodule, addModule, deleteModule }) => {
    const buttons = {
        forAdd: <ButtonPrimary className='h-48 w-full' onClick={() => {setOpened(null); addModule(mymodule.id)}}><TwinIcon icon={faPlus} className='mr-10' fontSize={20} /><span className='regular20'><TwinTrans transKey='addModule'>Añadir módulo</TwinTrans></span></ButtonPrimary>,
        forDelete: <ButtonRed className='h-48 w-full' onClick={() => {setOpened(null); deleteModule(mymodule.id)}}><TwinIcon icon={faTimes} className='mr-10' fontSize={20} /><span className='regular20'><TwinTrans transKey='removeModule'>Eliminar módulo</TwinTrans></span></ButtonRed>
    }
    const state = getEditorStateWithContent(mymodule.description)
    return (
        <ModalMedium opened={true} setOpened={() => setOpened(null)} className='flex flex-auto flex-col'>
            <div className='flex items-center '>
                <span className='regular26'>{mymodule.name}</span>
                <DisplayPriceAdmin className='regular40 ml-auto' value={mymodule.price}>  / <TwinTrans transKey='month'>mes</TwinTrans></DisplayPriceAdmin>
            </div>
            <div className='mt-50 regular18'>
                <Editor editorState={state} readOnly={true} onChange={() => { }}></Editor>
            </div>
            <div className='mt-auto'>
                {mymodule.type === 'new' || mymodule.type === 'own' ? buttons.forDelete : buttons.forAdd}
            </div>
        </ModalMedium>
    )
}


export default IndividualModuleModal