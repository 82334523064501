import { ConnectedProps, connect } from 'react-redux'
import ModalTabs from '../../../../../../../baseComponents/ModalsLayouts/ModalTabs'
import { ModalEditComponentProps } from '../../../../../../../baseComponents/ModalsLayouts/types'
import withLoading from '../../../../../../../utils/hoc/withLoading'
import { ComponentTabObject } from '../../../../../../../utils/hooks/useTabLogic'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import { AllReduxPayloads } from '../../../../../../../utils/reducers'
import { EmployeeAbsenceTypePayload } from '../../../../../../../utils/reducers/company/employeeAbsenceTypes'
import EmployeeAbsenceListingTab from './tabs/EmployeeAbsenceListingTab'
import MainRequestEmployeeAbsenceConnect from './tabs/MainRequestEmployeeAbsence'

type ModalRequestEmployeeAbsenceProps = ModalEditComponentProps & ReduxEmployeeAbsenceTypes & {}

const ModalRequestEmployeeAbsence: React.FC<ModalRequestEmployeeAbsenceProps> = ({ userPermissions, ...rest }) => {
    const { t } = useTwinTranslation()
    const tabs: ComponentTabObject = {
        'main': { component: MainRequestEmployeeAbsenceConnect, text: t('createEmployeeAbsence', 'Crear ausencia') },
        'absenceList': { component: EmployeeAbsenceListingTab, text: t('listOfAbsences', 'Listado de ausencias'), userPermission: userPermissions }
    }
    return (
        <ModalTabs {...rest} tabs={tabs} tab={'main'} className='notFlexAutoFormRender modaltabs_request_employee_absence' extraComponentData={{ ...rest}}></ModalTabs>
    )
}

const employeeAbsenceTypesDispatch = {
    setEmployeeAbsenceTypes: (payload: EmployeeAbsenceTypePayload) => ({ type: 'CHANGE_EMPLOYEEABSENCETYPE', payload }),
}

export type ReduxEmployeeAbsenceTypes = ConnectedProps<typeof employeeAbsenceTypesConnect>
const mapEmployeeAbsenceTypesConnector = (state: AllReduxPayloads) => ({ employeeAbsenceTypes: state.employeeAbsenceTypes })
const employeeAbsenceTypesConnect = connect(mapEmployeeAbsenceTypesConnector, employeeAbsenceTypesDispatch)

const employeeAbsenceTypesConnectLoading = withLoading(ModalRequestEmployeeAbsence, [{ fetchUrl: '/api/app/workingCalendar/employeeAbsence/getAllEmployeeAbsenceTypes', propName: 'employeeAbsenceTypes', setFunctionName: 'setEmployeeAbsenceTypes' }])

const  ModalRequestEmployeeAbsenceConnect = employeeAbsenceTypesConnect(employeeAbsenceTypesConnectLoading)

export default ModalRequestEmployeeAbsenceConnect