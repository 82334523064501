import { WorkingCenterModelType } from '@teinor/erp/types/company/workingCenter'
import { useCallback, useEffect, useState } from 'react'
import ModalCreateUserWorkingCenter from './ModalCreateEmployeeWorkingCenter'
import ModalDeleteUserWorkingCenter from './ModalDeleteEmployeeWorkingCenter'
import { WorkingCenterEmployeeExtended } from './type'
import { ComponentWithPermissions } from '../../../../../../../../../baseComponents/CreateRoutering/types'
import { TitleSButtonPermissioned } from '../../../../../../../../../baseComponents/Layout/TitleAndRightLayout'
import { permissionCheck } from '../../../../../../../../../baseComponents/PermissionChecker/function'
import VirtualTableListing from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing'
import { CustomRenderBoolean, DeleteRow } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import twinFetchPost, { twinFetchPostJSON } from '../../../../../../../../../utils/globals/data'
import useEditDeleteModal from '../../../../../../../../../utils/hooks/useEditDeleteModal'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import { EmployeeModelType } from '@teinor/erp/types/company/userInner/employee'
import { useParams } from 'react-router-dom'
import { changeErrorMessage } from '../../../../../../../../../utils/reducers/reduxDispatch'

interface WorkingCenterEmployeesProps extends ComponentWithPermissions { 
    basicData: WorkingCenterModelType
}

const WorkingCenterEmployees: React.FC<WorkingCenterEmployeesProps> = ({ userPermissions, basicData }) => {
    const { t } = useTwinTranslation()
    const { data, openModal, setOpenModal, getData, selEmployees, updateDefaultWorkingCenter } = useWorkingCenterEmployeeLogic({WorkingCenterId: basicData.id})
    const params = useParams<{ id: string }>()
    const id = params.id
    const columns: ColumnTableSchema<WorkingCenterEmployeeExtended> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('id', 'ID'),
            width: 75
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
        },
        {
            id: 'surname',
            dataKey: 'surname',
            label: t('surname', 'Apellido'),
        },
        {
            id: 'email',
            dataKey: 'email',
            label: t('email', 'Email'),            
        },
        {
            id: 'defaultWorkingCenterId',
            dataKey: 'defaultWorkingCenterId',
            label: t('byDefault', 'Por defecto'),
            customRender: (parameterValue, allRowData) => <CustomRenderBoolean className='cursor-pointer' value={parseInt(String(parameterValue)) === parseInt(String(id))} width={75} onClick={() =>updateDefaultWorkingCenter(allRowData.rowIndex, parseInt(String(parameterValue)) !== parseInt(String(id)))} />
        },
        {
            id: 'delete',
            dataKey: 'id',
            label: '',
            width: 50,
            customRender: (parameterValue, allRowData) => <DeleteRow key={'delete' + parameterValue} {...{ parameterValue, allRowData }} onClick={(_idk, allRowData) => setOpenModal({ type: 'delete', allRowData })} />,
            havePermission: permissionCheck(userPermissions, 'delete')
        }
    ]
    return (
        <TitleSButtonPermissioned title={ t('users', 'Usuarios')} buttonText={t('addUser', 'Añadir usuario')} onClick={() => setOpenModal({ type: 'create', allRowData: {} })} userPermissions={userPermissions} permission='update'>
            <div className='flex-auto'>
                <VirtualTableListing tableData={data} columns={columns} name='workingCenterEmployees' />
            </div>
            {openModal?.type === 'create' && <ModalCreateUserWorkingCenter {...{ setOpened: () => setOpenModal(null) }} onSubmit={getData} selEmployees={selEmployees|| []} />}
            {openModal?.type === 'delete' && <ModalDeleteUserWorkingCenter {...{ setOpened: () => setOpenModal(null) }} allRowData={openModal.allRowData} onSubmit={getData} />}
        </TitleSButtonPermissioned>
    )
}

interface WorkingCenterEmployeeLogicProps {
    WorkingCenterId: number
}

const useWorkingCenterEmployeeLogic = ({ WorkingCenterId }: WorkingCenterEmployeeLogicProps) => {
    const [data, setData] = useState<EmployeeModelType[]>([])
    const [selEmployees, setSelEmployees] = useState<number[] |null>(null)
    const { openModal, setOpenModal } = useEditDeleteModal()
    const {t} = useTwinTranslation()
    const cantChangeDefaultWCNoHaveMore = t('cantChangeDefaultWCNoHaveMore', 'No puedes cambiar el Centro de trabajo por defecto, debido a que no tiene más otros asignados')

    const getData = useCallback(async () => {
        let employees = await twinFetchPostJSON('/api/app/workingCenter/workingCenterEmployee/getAllEmployeesByWorkingCenter', { WorkingCenterId })
        setData(employees)
        const parsedUsers: number[]= []
        for (const user of employees) {
            parsedUsers.push(user.id)
        }
        setSelEmployees(parsedUsers)
    }, [WorkingCenterId, setData, setSelEmployees])

    const updateDefaultWorkingCenter = useCallback(async (pos: number, value: boolean) => {
        let response = await twinFetchPost('/api/app/workingCenter/workingCenterEmployee/updateEmployeeWorkingCenterDefault', { WorkingCenterId, is_default: value, EmployeeId: data[pos].id
        })
        if (response.status === 200) {
            const result = await response.json()
            if (result) {
                setData((old) => {
                    const copyOld = JSON.parse(JSON.stringify(old))
                    if (value) {
                        copyOld[pos].defaultWorkingCenterId = WorkingCenterId
                    } else {
                        copyOld[pos].defaultWorkingCenterId = undefined
                    }
                    return copyOld
                })
            }
        } else {
            const responseJSON = await response.json()
            if (responseJSON.errors[0].msg === 'No have other Working Center') {
                changeErrorMessage(cantChangeDefaultWCNoHaveMore)
            }
        }
       
    }, [WorkingCenterId, setData, data, cantChangeDefaultWCNoHaveMore])

    useEffect(() => {
        getData()
    }, [getData])
    return { data, getData, openModal, setOpenModal, selEmployees, updateDefaultWorkingCenter }
}

export default WorkingCenterEmployees