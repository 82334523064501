import { CustomerProjectModelType } from '@teinor/erp/types/company/customer/customerProject'
import { SetStateAction } from 'react'
import PermissionChecker from '../../../../baseComponents/PermissionChecker'
import CustomSelect from '../../../../forms/CustomSelect'
import { TwinDictionary, addDictionaryExtraLine } from '../../../../utils/globals/dictionary'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import { getUserPermissions } from '../../../../utils/reducers/getters'
import CustomSelectTaskContactInfo from '../../Components/CustomSelectTaskContactInfo'
import { ModalCreateTaskWithHelperData } from '../types'
import { ConnectedProps, connect } from 'react-redux'
import TwinTrans from '../../../../baseComponents/TwinTrans'
import withLoading from '../../../../utils/hoc/withLoading'
import { AllReduxPayloads } from '../../../../utils/reducers'
import { CustomerPayload } from '../../../../utils/reducers/customers/customer'
import SearcherCustomerProjectSimple from '../../../Customers/SearcherCustomerProject/SearcherCustomerProjectSimple'

interface TaskHelperContactPersonSelectorParentProps extends TaskHelperContactPersonSelectorProps { }

export const TaskHelperContactPersonSelectorParent: React.FC<TaskHelperContactPersonSelectorParentProps> = ({ extraProps, dataTask, setDataTask, handleOpenModalBig }) => {
    if (extraProps.CustomerContactPersonId) {
        return <TaskHelperContactPersonSelectorEdit extraProps={extraProps} dataTask={dataTask} setDataTask={setDataTask} handleOpenModalBig={handleOpenModalBig} />
    }
    return <TaskHelperContactPersonSelectorCreate extraProps={extraProps} dataTask={dataTask} setDataTask={setDataTask} handleOpenModalBig={handleOpenModalBig} />
}


interface TaskHelperContactPersonSelectorProps {
    extraProps: TwinDictionary
    dataTask: ModalCreateTaskWithHelperData
    setDataTask: React.Dispatch<SetStateAction<ModalCreateTaskWithHelperData>>
    handleOpenModalBig: (modalType: string) => void
}

const TaskHelperContactPersonSelectorEdit: React.FC<TaskHelperContactPersonSelectorProps> = ({ extraProps, dataTask, setDataTask, handleOpenModalBig }) => {
    const { t } = useTwinTranslation()
    const myCustomerContactPersons = addDictionaryExtraLine(JSON.parse(JSON.stringify(extraProps.customerContactPersons || {})))
    return (
        <PermissionChecker userPermissions={getUserPermissions('customer.profile.contactPerson')} permission={'update'} >
            <CustomSelectTaskContactPerson items={myCustomerContactPersons} value={dataTask?.CustomerContactPersonId} setDataTask={setDataTask} CustomerProject={extraProps.customerProjectId} />
            <div className='ml-auto text-12 cursor-pointer' onClick={() => handleOpenModalBig('editContactPerson')}>{t('editContactPerson', 'Editar persona de contacto')}</div>
        </PermissionChecker>
    )
}

const TaskHelperContactPersonSelectorCreate: React.FC<TaskHelperContactPersonSelectorProps> = ({ extraProps, dataTask, setDataTask, handleOpenModalBig }) => {
    const { t } = useTwinTranslation()
    const myCustomerContactPersons = addDictionaryExtraLine(JSON.parse(JSON.stringify(extraProps.customerContactPersons || {})))
    return (
        <PermissionChecker userPermissions={getUserPermissions('customer.profile.contactPerson')} permission={'create'} >
            <CustomSelectTaskContactPerson items={myCustomerContactPersons} value={dataTask?.CustomerContactPersonId} setDataTask={setDataTask} CustomerProject={extraProps.customerProjectId} />
            {extraProps.customerProjectId ?
                <div className='ml-auto text-12 cursor-pointer' onClick={() => handleOpenModalBig('newContactPerson')}>{t('addNewContactPerson', 'Añadir nueva persona de contacto')}</div>
                : null}
        </PermissionChecker>
    )
}

interface CustomSelectTaskContactPersonProps {
    items: TwinDictionary
    setDataTask: React.Dispatch<SetStateAction<ModalCreateTaskWithHelperData>>
    CustomerProject?: CustomerProjectModelType
    value?: number
}

const CustomSelectTaskContactPerson: React.FC<CustomSelectTaskContactPersonProps> = ({ items, setDataTask, CustomerProject, value }) => {
    const { t } = useTwinTranslation()
    const selectedInfo = value ? items?.[value] : undefined

    if (!CustomerProject) {
        return <CustomSelect readOnly={true} value={value} label={t('customerContactPersonSelectProject', 'Contacto del cliente - Selecciona proyecto')} items={{}} />
    }
    return <CustomSelectTaskContactInfo id={'taskHelperContactPerson'} label={t('customerContactPerson', 'Contacto del cliente')} items={items} value={value} selectedContact={selectedInfo} onChange={(value) => setDataTask((old) => { return { ...old, CustomerContactPersonId: value } })} name='CustomerContactPersonId' />
}


interface TaskHelperCustomerShopSelectorParentProps extends TaskHelperCustomerShopSelectorProps { }

export const TaskHelperCustomerShopSelectorParent: React.FC<TaskHelperCustomerShopSelectorParentProps> = ({ extraProps, dataTask, setDataTask, handleOpenModalBig }) => {
    if (extraProps.CustomerShopId) {
        return <TaskHelperCustomerShopSelectorEdit extraProps={extraProps} dataTask={dataTask} setDataTask={setDataTask} handleOpenModalBig={handleOpenModalBig} />
    }
    return <TaskHelperCustomerShopSelectorCreate extraProps={extraProps} dataTask={dataTask} setDataTask={setDataTask} handleOpenModalBig={handleOpenModalBig} />
}


interface TaskHelperCustomerShopSelectorProps {
    extraProps: TwinDictionary
    dataTask: ModalCreateTaskWithHelperData
    setDataTask: React.Dispatch<SetStateAction<ModalCreateTaskWithHelperData>>
    handleOpenModalBig: (modalType: string) => void
}

const TaskHelperCustomerShopSelectorCreate: React.FC<TaskHelperCustomerShopSelectorProps> = ({ extraProps, dataTask, setDataTask, handleOpenModalBig }) => {
    const { t } = useTwinTranslation()
    const myCustomerShops = addDictionaryExtraLine(JSON.parse(JSON.stringify(extraProps.customerShops || {})))
    return (
        <PermissionChecker userPermissions={getUserPermissions('customer.profile.shops')} permission={'create'} >
            <CustomSelectTaskCustomerShop items={myCustomerShops} value={dataTask?.CustomerShopId} setDataTask={setDataTask} CustomerProject={extraProps.customerProjectId} />
            {extraProps.customerProjectId ?
                <div className='ml-auto text-12 cursor-pointer' onClick={() => handleOpenModalBig('newWorkingCenter')}>{t('addNewWorkingCenter', 'Añadir centro de trabajo')}</div>
                : null}
        </PermissionChecker>
    )
}

const TaskHelperCustomerShopSelectorEdit: React.FC<TaskHelperCustomerShopSelectorProps> = ({ extraProps, dataTask, setDataTask, handleOpenModalBig }) => {
    const { t } = useTwinTranslation()
    const myCustomerShops = addDictionaryExtraLine(JSON.parse(JSON.stringify(extraProps.customerShops || {})))
    return (
        <PermissionChecker userPermissions={getUserPermissions('customer.profile.shops')} permission={'update'} >
            <CustomSelectTaskCustomerShop items={myCustomerShops} value={dataTask?.CustomerShopId} setDataTask={setDataTask} CustomerProject={extraProps.customerProjectId} />
            <div className='ml-auto text-12 cursor-pointer' onClick={() => handleOpenModalBig('editWorkingCenter')}>{t('editWorkingCenter', 'Editar centro de trabajo')}</div>
        </PermissionChecker>
    )
}

interface CustomSelectTaskCustomerShopProps {
    items: TwinDictionary
    setDataTask: React.Dispatch<SetStateAction<ModalCreateTaskWithHelperData>>
    CustomerProject?: CustomerProjectModelType
    value?: number
}

const CustomSelectTaskCustomerShop: React.FC<CustomSelectTaskCustomerShopProps> = ({ items, setDataTask, CustomerProject, value }) => {
    const { t } = useTwinTranslation()
    const selectedInfo = value ? items?.[value] : undefined

    if (!CustomerProject) {
        return <CustomSelect readOnly={true} value={value} label={t('customerWorkingCenterSelectProject', 'Centro del cliente - Selecciona proyecto')} items={{}} />
    }
    return <CustomSelectTaskContactInfo id={'taskHelperWorkingCenter'} label={t('customerWorkingCenter', 'Centro del cliente')} items={items} value={value} selectedContact={selectedInfo} onChange={(value) => setDataTask((old) => { return { ...old, CustomerShopId: value } })} name='CustomerShopId'/>
}


interface TaskHelperCustomerProjectSelectorParentProps {
    extraProps: TwinDictionary
    handleAddCustomerProject: (CustomerProjectId: number | null) => Promise<boolean>
    handleOpenModalBig: (modalType: string) => void
}

export const TaskHelperCustomerProjectSelectorParent: React.FC<TaskHelperCustomerProjectSelectorParentProps> = ({ extraProps, handleAddCustomerProject, handleOpenModalBig }) => {
    return (
        <PermissionChecker userPermissions={getUserPermissions('customer.profile.main')} permission={'read'} >
            <CustomerSearcherTaskConnect handleAddCustomerProject={handleAddCustomerProject} CustomerId={extraProps.customerId} CustomerProjectId={extraProps.customerProjectId} />
            {extraProps.customerProjectId ?
                <div className='ml-auto text-12 cursor-pointer' onClick={() => handleOpenModalBig('customerTask')}><TwinTrans transKey='customerTask'>Ver tareas del cliente</TwinTrans></div>
                : null}
        </PermissionChecker>)
}

interface CustomerSearcherTaskProps extends ReduxCustomer {
    handleAddCustomerProject: (CustomerProjectId: number | null) => Promise<boolean>
    CustomerId: number
    CustomerProjectId: number
}

export const CustomerSearcherTask: React.FC<CustomerSearcherTaskProps> = ({ customers, handleAddCustomerProject, CustomerId, CustomerProjectId }) => {
    const { t } = useTwinTranslation()
    return (
        <SearcherCustomerProjectSimple name='CustomerProjectId' customers={customers} handleAddCustomerProject={handleAddCustomerProject} label={t('project', 'Proyecto')} CustomerId={CustomerId} CustomerProjectId={CustomerProjectId} />
    )
}

const customersDispatch = {
    setCustomers: (payload: CustomerPayload) => ({ type: 'CHANGE_CUSTOMER', payload }),
}

type ReduxCustomer = ConnectedProps<typeof customersConnect>
const mapCustomersConnector = (state: AllReduxPayloads) => ({ customers: state.customers })
const customersConnect = connect(mapCustomersConnector, customersDispatch)

const customersConnectLoading = withLoading(CustomerSearcherTask, [{ fetchUrl: '/api/app/customer/getAllCustomers', propName: 'customers', setFunctionName: 'setCustomers' }])

const CustomerSearcherTaskConnect = customersConnect(customersConnectLoading)