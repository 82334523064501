export const customerProjectTypeColors =  [
    '#6299E3',
    '#54AF7F',
    '#D84157',
    '#F4C867',
    '#F2A53B',
    '#9DC6E5',
    '#5DB0F8',
    '#3A8ADD',
    '#1D4BA7',
    '#AFAFAF',
    '#5D27E2'
]

export function hexToRgbA(hex: string, opacity: string = '1') {
    let c: any;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ','+opacity+')';
    }
}