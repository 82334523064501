
import { EmployeeAbsenceModelType } from '@teinor/erp/types/company/userInner/employee/employeeAbsence'
import { ConnectedProps, connect } from 'react-redux'
import { useParams } from 'react-router'
import { ComponentWithPermissions } from '../../../../../../../../../baseComponents/CreateRoutering/types'
import { DisplayStatusWithoutIcon } from '../../../../../../../../../baseComponents/Displays/DisplayStatus'
import BaseScreenTableInner from '../../../../../../../../../baseComponents/TwinTable/BaseScreenTableInner'
import { TableComponentBaseScreenInnerProps } from '../../../../../../../../../baseComponents/TwinTable/BaseScreenTableInner/types'
import { CustomRenderString, CustomRenderDate } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import withLoading from '../../../../../../../../../utils/hoc/withLoading'
import useStatusRequestTypes from '../../../../../../../../../utils/hooks/useStatusRequestTypes'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import { AllReduxPayloads } from '../../../../../../../../../utils/reducers'
import { EmployeeAbsenceTypePayload } from '../../../../../../../../../utils/reducers/company/employeeAbsenceTypes'
import ModalCreateEmployeeAbsence from './ModalCreateEmployeeAbsence'
import ModalEditEmployeeAbsence from './ModalEditEmployeeAbsence'
import TwinTable from '../../../../../../../../../baseComponents/TwinTable'


interface EmployeeAbsencesProps extends ComponentWithPermissions {
}

const EmployeeAbsences: React.FC<EmployeeAbsencesProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()
    const params = useParams<{ id: string }>()
    const id = params.id
    const pageData = {
        title: t('employeeAbsences', 'Ausencias'),
        newText: t('addEmployeeAbsences', 'Añadir una ausencia'),
    }

    return (
        <BaseScreenTableInner TableComponent={TableEmployeeAbsencesConnect} extraWhereParams={{ 'EmployeeId': id }} pageData={pageData} CreateModalComponent={ModalCreateEmployeeAbsence} EditModalComponent={ModalEditEmployeeAbsence} userPermissions={userPermissions} />
    )
}

type TableEmployeeAbsencesProps = TableComponentBaseScreenInnerProps & ReduxEmployeeAbsenceTypes & {
}

const TableEmployeeAbsences: React.FC<TableEmployeeAbsencesProps> = ({ setTableInstance, userPermissions, extraWhereParams, setOpened, employeeAbsenceTypes}) => {
    const { t } = useTwinTranslation()
    const columns: ColumnTableSchema<EmployeeAbsenceModelType> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('id', 'ID'),
            width: 75
        },
        {
            id: 'EmployeeAbsenceType',
            dataKey: 'EmployeeAbsenceTypeId',
            label: t('absenceType', 'Tipo de ausencia'),
            customRender: (parameterValue, allRowData) => <CustomRenderString value={allRowData?.EmployeeAbsenceType?.fullname_short} />,

            searchableComponent: {
                component: 'SearchableComponentsCustomSelectSearchBar',
                extraComponentData: { items: employeeAbsenceTypes||{}, label: t('absenceType', 'Tipo de ausencia') }
            },
        },
        {
            id: 'description',
            dataKey: 'description',
            label: t('description', 'Descripción'),
        },
        {
            id: 'dateStart',
            dataKey: 'dateStart',
            label: t('dateStartS', 'Fecha inicio'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />
        },
        {
            id: 'dateEnd',
            dataKey: 'dateEnd',
            label: t('dateEndS', 'Fecha fin'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />
        },
        {
            id: 'approved',
            dataKey: 'approved',
            label: t('status', 'Estado'),
            customRender: (parameterValue) => <RenderEmployeeAbsenceStatus value={parameterValue} />,
            width: 220
        },
    ]
    return (
        <TwinTable name='employeeAbsences' columns={columns} defaultOrder={{ order: 'desc', defaultOrderField: 'dateStart' }} getDataFrom='/api/app/workingCalendar/employeeAbsence/getAllEmployeeAbsences'
            onRowClick={(_id, rowData) => setOpened({
                type: 'edit',
                allRowData: rowData
            })}
            setTableInstance={setTableInstance} extraWhereParams={extraWhereParams} />
    )
}

interface RenderEmployeeAbsenceStatusProps {
    value: string
}

const RenderEmployeeAbsenceStatus: React.FC<RenderEmployeeAbsenceStatusProps> = ({ value }) => {
    const status = useStatusRequestTypes()
    const parsedValue = parseInt(value)
    return <DisplayStatusWithoutIcon colorText={status[parsedValue].colorText} text={status[parsedValue].name} />
}

const employeeAbsenceTypesDispatch = {
    setEmployeeAbsenceTypes: (payload: EmployeeAbsenceTypePayload) => ({ type: 'CHANGE_EMPLOYEEABSENCETYPE', payload }),
}

export type ReduxEmployeeAbsenceTypes = ConnectedProps<typeof employeeAbsenceTypesConnect>
const mapEmployeeAbsenceTypesConnector = (state: AllReduxPayloads) => ({ employeeAbsenceTypes: state.employeeAbsenceTypes })
const employeeAbsenceTypesConnect = connect(mapEmployeeAbsenceTypesConnector, employeeAbsenceTypesDispatch)

const employeeAbsenceTypesConnectLoading = withLoading(TableEmployeeAbsences, [{ fetchUrl: '/api/app/workingCalendar/employeeAbsence/getAllEmployeeAbsenceTypes', propName: 'employeeAbsenceTypes', setFunctionName: 'setEmployeeAbsenceTypes' }])

const TableEmployeeAbsencesConnect = employeeAbsenceTypesConnect(employeeAbsenceTypesConnectLoading)

export default EmployeeAbsences