import { CustomerProjectTypeModelType } from '@teinor/erp/types/company/customer/customerProjectType'
import { ComponentWithPermissions } from '../../../../../../baseComponents/CreateRoutering/types'
import { TwinTableLink } from '../../../../../../baseComponents/TwinTable'
import BaseScreenTable from '../../../../../../baseComponents/TwinTable/BaseScreenTable'
import { TableComponentProps } from '../../../../../../baseComponents/TwinTable/BaseScreenTable/types'
import { CustomRenderColor, CustomRenderString, EditRowNotLink } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import ModalCECustomerProjectType from './ModalCECustomerProjectType'
import { ConnectedProps, connect } from 'react-redux'
import withLoading from '../../../../../../utils/hoc/withLoading'
import { AllReduxPayloads } from '../../../../../../utils/reducers'
import { TaskTypePayload } from '../../../../../../utils/reducers/company/tastTypes'
import { useParams } from 'react-router-dom'
import DoubleTable from '../../../../../../baseComponents/DoubleTable'
import { useAddDoubleTableGreenRowRender } from '../../../../../../baseComponents/DoubleTable/functions'
import CustomerProjectStatus from './CustomerProjectStatus'

interface CustomerProjectTypesProps extends ComponentWithPermissions {}

const CustomerProjectTypes: React.FC<CustomerProjectTypesProps> = ({ userPermissions }) => {
    const { CustomerProjectTypeId } = useParams()
    return (<DoubleTable userPermissions={userPermissions} FirstChild={CustomerProjectTypesInner} SecondChild={CustomerProjectStatus} displaySecond={CustomerProjectTypeId !== 'main'} />)
}
const CustomerProjectTypesInner: React.FC<CustomerProjectTypesProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()

    const pageData = {
        title: t('customerProjectTypes', 'Tipos de proyecto'),
        newText: t('newCustomerProjectType', 'Nuevo tipo de proyecto'),
    }

    return (
        <BaseScreenTable TableComponent={TableCustomerProjectTypesConnect} CreateModalComponent={ModalCECustomerProjectType} EditModalComponent={ModalCECustomerProjectType} pageData={pageData} userPermissions={userPermissions} />
    )
}

interface TableCustomerProjectTypesProps extends TableComponentProps{}

const TableCustomerProjectTypes: React.FC<TableCustomerProjectTypesProps & ReduxTaskTypes> = ({ setTableInstance, setOpened, taskTypes }) => {
    const { t } = useTwinTranslation()
    const { CustomerProjectTypeId } = useParams()
    const { rowRenderer } = useAddDoubleTableGreenRowRender(CustomerProjectTypeId || '')
    const columns: ColumnTableSchema<CustomerProjectTypeModelType> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('id', 'ID'),
            sortable: true,
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            }
        },
        {
            id: 'color',
            dataKey: 'color',
            label: t('color', 'Color'),
            customRender: (parameterValue) => <CustomRenderColor value={parameterValue} />
        },
        {
            id: 'taskTypeId',
            dataKey: 'TaskTypeId',
            label: t('tastType', 'Tipo de tarea'),
            customRender: (parameterValue) => <CustomRenderString value={taskTypes?.[parameterValue as any]?.name || ''}/>
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            customRender: (parameterValue, allRowData) => <EditRowNotLink key={'edit' + parameterValue} {...{ parameterValue, allRowData }} onClick={(_idk, allRowData) => setOpened({ type: 'edit', allRowData })} />
        },
    ]

    return (
        <TwinTableLink createRowDataLink={['/customer/projectTypes', ['$id']]} name='CustomerProjectTypes' columns={columns} getDataFrom='/api/app/customer/customerProject/customerProjectTypes/getAllCustomerProjectTypes' setTableInstance={setTableInstance} rowRenderer={rowRenderer} hideLoadFiltersButton={true}>
        </TwinTableLink>
    )
}

const taskTypesDispatch = {
    setTaskTypes: (payload: TaskTypePayload) => ({ type: 'CHANGE_TASKTYPE', payload }),
}

export type ReduxTaskTypes = ConnectedProps<typeof taskTypesConnect>
const mapTaskTypesConnector = (state: AllReduxPayloads) => ({ taskTypes: state.taskTypes })
const taskTypesConnect = connect(mapTaskTypesConnector, taskTypesDispatch)
const taskTypesConnectLoading = withLoading(TableCustomerProjectTypes, [{ fetchUrl: '/api/app/task/taskType/getAllTaskTypes', propName: 'taskTypes', setFunctionName: 'setTaskTypes' }])
const TableCustomerProjectTypesConnect = taskTypesConnect(taskTypesConnectLoading)



export default CustomerProjectTypes