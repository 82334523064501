import { faAdd, faBell, faBuildings, faClock, faPowerOff } from '@fortawesome/pro-light-svg-icons'
import { Fragment, useEffect, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { Link } from 'react-router-dom'
import useTwinTranslation from '../../../../../utils/hooks/useTwinTranslation'
import { AllReduxAppPayloads, GlobalContext } from '../../../../../utils/reducers'
import { LastNotificationPayload } from '../../../../../utils/reducers/company/lastNotification'
import { getUserInfo, getUserPermissions } from '../../../../../utils/reducers/getters'
import { UserPayload } from '../../../../../utils/reducers/globals/user'
import { signOutUser } from '../../../../../utils/reducers/reduxDispatch'
import { checkIfAnimated } from '../../../../Modal/functions'
import { ModalOpenedTy } from '../../../../Modal/types'
import { PopUpMessage } from '../../../../ModalsLayouts/ModalPopupMessage'
import PermissionChecker, { ModuleChecker } from '../../../../PermissionChecker'
import TwinIcon from '../../../../TwinIcon'
import ModalSelectorCompanyManager from './ModalSelectorCompanyManager'
import './user_alerts.sass'
import useEditDeleteModal from '../../../../../utils/hooks/useEditDeleteModal'
import ModalCreateTaskWithHelper from '../../../../../specificComponents/Task/ModalCreateTaskWithHelper'

interface UserIconsProps { }

const UserIcons: React.FC<UserIconsProps> = () => {
    return (
        <div className='flex flex-col justify-end items-center mt-auto user_alerts w-full gap-y-24'>
            <User />
            <CreateTask />
            <SelectorCompanyConnected />
            <TimeControl />
            <NotificationsConnect />
            <SignOut />
        </div>
    )
}

interface CreateTaskProps { }

const CreateTask: React.FC<CreateTaskProps> = () => {
    const { openModal, setOpenModal } = useCreateTaskLogic()
    const userPermissions = getUserPermissions('task.main')
    return (
        <PermissionChecker userPermissions={userPermissions} permission={'create'}>
            <ModuleChecker modules={['tasks']}>
                <div>
                    <TwinIcon icon={faAdd} className='user_alerts_icons ' onClick={() => setOpenModal({type: 'edit', allRowData: {}})} />
                    {openModal?.type === 'edit' ? <ModalCreateTaskWithHelper onSubmit={() => { }} setOpened={() => setOpenModal(null)} allRowData={openModal?.allRowData} userPermissions={userPermissions || undefined} /> : null}
                </div>
            </ModuleChecker>
        </PermissionChecker>

    )
}

const useCreateTaskLogic = () => {
    const { openModal, setOpenModal } = useEditDeleteModal()

    return { openModal, setOpenModal }
}

interface UserProps { }

const User: React.FC<UserProps> = () => {
    const { user } = useUserLogic()
    const profileImage = user?.Employee?.profile_image || '/user-png.png'
    return (
        <Link to={'/employeeProfile/main'}>
            <div className='logo_user cursor-pointer'>
                <img src={profileImage} className='rounded-full h-full w-full' alt='logo user' />
            </div>
        </Link>

    )
}

const useUserLogic = () => {
    const user = getUserInfo()
    return { user }
}


interface SelectorCompanyProps extends ReduxSelectorCompanyConnector { }

const SelectorCompany: React.FC<SelectorCompanyProps> = ({ user }) => {
    const { opened, setOpened } = useSelectorCompanyLogic()
    if (!user?.extraData.isManager) {
        return null
    }
    return (
        <div>
            <TwinIcon icon={faBuildings} className='user_alerts_icons ' onClick={() => setOpened(true)} />
            {opened ? <ModalSelectorCompanyManager {...{ opened, setOpened }} /> : null}
        </div>
    )
}

const useSelectorCompanyLogic = () => {
    const [opened, setOpened] = useState<ModalOpenedTy>(null)
    return { opened, setOpened }
}

const selectorCompanyDispatch = {
    setUser: (payload: UserPayload) => ({ type: 'CHANGE_USER', payload }),
}
const mapSelectorCompanyConnector = (state: AllReduxAppPayloads) => ({ user: state.user })
const selectorCompanyConnector = connect(mapSelectorCompanyConnector, selectorCompanyDispatch, null, { context: GlobalContext })
type ReduxSelectorCompanyConnector = ConnectedProps<typeof selectorCompanyConnector>
export const SelectorCompanyConnected = selectorCompanyConnector(SelectorCompany)

interface TimeControlProps { }

const TimeControl: React.FC<TimeControlProps> = () => {
    return (
        <ModuleChecker modules={['working_calendar', 'working_calendar_medium', 'working_calendar_full']}>
            <Link to={'/timeControl/calendar'}>
                <div className='flex flex-col items-center'>
                    <TwinIcon icon={faClock} className='user_alerts_icons' />
                </div>
            </Link>
        </ModuleChecker>
    )
}

interface NotificationsProps extends NotificationsLogicProps { }

const Notifications: React.FC<NotificationsProps> = ({ lastNotification, ...rest }) => {
    const {t} = useTwinTranslation()
    const { displayModal } = useNotificationsLogic({ lastNotification, ...rest })
    return (
        <Fragment>
            <Link to={'/notifications/all'}>
                <div className='flex flex-col items-center notifications'>
                    {lastNotification ?
                        <div className='notifications_item mb-6 rounded-full items-center' />
                        : null}
                    <TwinIcon icon={faBell} className='user_alerts_icons' />
                </div>
            </Link>
            {displayModal ? <PopUpMessage type={'success'} message={t('haveNewNotification', 'Tienes una nueva notificación')} animatedClass={checkIfAnimated(displayModal)} /> : null}
        </Fragment>
    )
}

interface NotificationsLogicProps extends ReduxLastNotification {}

const useNotificationsLogic = ({lastNotification}: NotificationsLogicProps) => {
    const [displayModal, setDisplayModal] = useState(false)

    useEffect(() => {
        if (lastNotification?.id) {
            setDisplayModal(true)
            setTimeout(() => {
                setDisplayModal(false)
            }, 3000)
        }
    }, [lastNotification?.id])

    return {displayModal}
}


const lastNotificationDispatch = {
    setLastNotification: (payload: LastNotificationPayload) => ({ type: 'CHANGE_LAST_NOTIFICATION', payload }),
}

export type ReduxLastNotification = ConnectedProps<typeof lastNotificationConnect>
const mapLastNotificationConnector = (state: AllReduxAppPayloads) => ({ lastNotification: state.lastNotification })

const lastNotificationConnect = connect(mapLastNotificationConnector, lastNotificationDispatch, null, { context: GlobalContext })

const NotificationsConnect = lastNotificationConnect(Notifications)


interface SignOutProps { }

export const SignOut: React.FC<SignOutProps> = () => {
    return (
        <Link to={'/'} onClick={signOutUser} className='sign_out_btn'>
            <TwinIcon icon={faPowerOff} className='user_alerts_icons' />
        </Link>
    )
}


export default UserIcons