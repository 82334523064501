export interface AddAntiUnsaveAction {
    type: 'ADD_UNSAVECHANGE'
    payload: [key: string, value: any]
}

export interface DeleteAntiUnsaveAction {
    type: 'DELETE_UNSAVECHANGE'
    payload: string
}

export interface ClearAntiUnsaveAction {
    type: 'CLEAR_UNSAVECHANGE'
}
export interface ShowAntiUnsaveAction {
    type: 'SHOW_UNSAVECHANGE'
    payload: () => void
}
export interface HideAntiUnsaveAction {
    type: 'HIDE_UNSAVECHANGE'
}

export type AntiUnsaveActions = AddAntiUnsaveAction | DeleteAntiUnsaveAction | ClearAntiUnsaveAction | ShowAntiUnsaveAction | HideAntiUnsaveAction

export type AntiUnsavePayload = { 
    '__show'?: () => void
    [name: string]: any
}
export default function antiUnsave(state: AntiUnsavePayload = {}, action: AntiUnsaveActions): AntiUnsavePayload {
    switch (action.type) {
        case 'ADD_UNSAVECHANGE':
            return { ...state, [action.payload[0]]: action.payload[1] }
        case 'DELETE_UNSAVECHANGE': 
            const copy = JSON.parse(JSON.stringify(state))
            delete copy[action.payload]
            return { ...copy }
        case 'CLEAR_UNSAVECHANGE': 
            state = {}
            return { ...state }
        case 'SHOW_UNSAVECHANGE': 
            return { ...state, '__show': action.payload }
        case 'HIDE_UNSAVECHANGE': 
            delete state['__show']
            return { ...state}
        default:
            return state
    }
}