import { useState, useMemo, useCallback } from 'react'
import { returnClassOfSpanTemplate, returnClassOfSpanRowsTemplate } from '../../../../utils/globals/tailwind'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import { WhiteBox } from '../../../AppLayout/WhiteBox'
import { ModalFlexible } from '../../../Modal'
import { ModalOpenedSetTy } from '../../../Modal/types'
import TwinIcon from '../../../TwinIcon'
import { DashboardWidgetAndFamilies, WidgetFamiliesKeys, WidgetFamily, WidgetElementRender } from '../../useDashboardWidgets/types'
import { createWidgetThreeByFamilies } from './functions'
import { WidgetFamiliesDictionaryExtended, WidgetFamilyExtended } from './types'

type DashboardHeaderColumnProps = DashboardHeaderColumnLogicProps & {}

const DashboardHeaderColumn: React.FC<DashboardHeaderColumnProps> = ({ setOpenModal, ...logic}) => {
    const { category, setCategory, returnModal, widgetThree, addWidget } = useDashboardHeaderColumnLogic({ setOpenModal, ...logic })
    const {t} = useTwinTranslation()
    let renderThis: JSX.Element | null = null
    let text = t('categories', 'Categorías')
    if (category) {
        const myCategory = widgetThree[category]
        text = myCategory.title
        renderThis = <ModalCategoryInner myCategory={myCategory} addWidget={addWidget}/>
    } else {
        renderThis = <ModalCategory widgetThree={widgetThree} setCategory={setCategory} />
    }
    return (
        <ModalFlexible opened={true} setOpened={returnModal} type={category ? 'modal_medium' : 'modal_little'} className='modal_families_dashboard flex flex-col'>
            <h2 className='mb-25'>{text}</h2>
            <div className='modal_negative_margin flex-auto h-1 '>
                {renderThis}
            </div>
        </ModalFlexible>
    )
}

interface DashboardHeaderColumnLogicProps extends DashboardWidgetAndFamilies {
    setOpenModal: ModalOpenedSetTy
}

const useDashboardHeaderColumnLogic = ({ avaliableDashboardWidgets, userDashboardWidgets, dashboardFamilies, setOpenModal, setUserDashboardWidgets }: DashboardHeaderColumnLogicProps) => {
    const [category, setCategory] = useState<null | WidgetFamiliesKeys>(null)
    const widgetThree: WidgetFamiliesDictionaryExtended = useMemo(() => createWidgetThreeByFamilies(avaliableDashboardWidgets, userDashboardWidgets, dashboardFamilies), [avaliableDashboardWidgets, userDashboardWidgets, dashboardFamilies])
    const returnModal = useCallback(() => {
        if (category) {
            setCategory(null)
        } else {
            setOpenModal(null)
        }
    }, [category, setCategory, setOpenModal])

    const addWidget = useCallback((name: string) => {
        const widget = avaliableDashboardWidgets[name]
        setUserDashboardWidgets((old) => {
            const copy = JSON.parse(JSON.stringify(old))
            copy.push({
                cols: widget.cols,
                rows: widget.rows,
                name
            })
            return [...copy]
        })
        setOpenModal(null)
    }, [setOpenModal, avaliableDashboardWidgets, setUserDashboardWidgets])

    return { category, setCategory, returnModal, widgetThree, addWidget }
}

interface ModalCategoryInnerProps {
    myCategory: WidgetFamilyExtended
    addWidget: (name: string) => void
}

const ModalCategoryInner: React.FC<ModalCategoryInnerProps> = ({myCategory, addWidget}) => {
    const renderThis: JSX.Element[] = []
    for (const key in myCategory.childrens) {
        const widget = myCategory.childrens[parseInt(key)]
        renderThis.push(<DashboardHeaderWidget onClick={() => addWidget(widget.name)} widget={widget} key={widget.name} />)
    }
    return (
        <div className='grid grid-cols-2 gap-16 overflow-auto h-full dashboard_grid'>
            {renderThis}
        </div>
    )
}

interface ModalCategoryProps {
    widgetThree: WidgetFamiliesDictionaryExtended
    setCategory: React.Dispatch<React.SetStateAction<WidgetFamiliesKeys | null>>
}

const ModalCategory: React.FC<ModalCategoryProps> = ({ widgetThree, setCategory }) => {
    const renderThis: JSX.Element[] = []
    for (const key in widgetThree) {
        const myKey = key as WidgetFamiliesKeys
        const element = widgetThree[myKey]
        if (element.childrens) {
            renderThis.push(<DashboardHeaderCategory key={key} family={element} onClick={() => setCategory(myKey)} />)
        }
    }
    return (
        <div className='grid grid-cols-2 gap-16 overflow-auto h-full auto-rows-min'>
            {renderThis}
        </div>
    )
}


interface DashboardHeaderCategoryProps {
    onClick: () => void
    family: WidgetFamily
}

const DashboardHeaderCategory: React.FC<DashboardHeaderCategoryProps> = ({onClick, family}) => {
    return (
        <div className='dashboard_header_category bg-white flex flex-col items-center justify-center cursor-pointer' onClick={onClick}>
            <div><TwinIcon icon={family.icon} className='text-green-00' /></div>
            <div>{family.title}</div>
        </div>
    )
}

interface DashboardHeaderWidgetProps {
    onClick: () => void
    widget: WidgetElementRender
}

const DashboardHeaderWidget: React.FC<DashboardHeaderWidgetProps> = ({ onClick, widget }) => {
    const cols: any = widget.cols > 2 ?  2 : widget.cols
    const rows: any = widget.rows > 2 ?  2 : widget.rows
    return (
        <WhiteBox className={'bg-white cursor-pointer p-24 relative flex flex-col ' + (returnClassOfSpanTemplate(cols)) + ' ' + (returnClassOfSpanRowsTemplate(rows))} onClick={onClick}>
            <div className='absolute right-0 left-0 top-0 bottom-0 z-10' onClick={(e) => { e.stopPropagation(); e.preventDefault(); onClick() }}></div>
            <widget.component {...{ ...widget.extraProps }} {...{ cols, rows }} />
        </WhiteBox>
    )
}

export default DashboardHeaderColumn