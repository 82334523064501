import { addClassName, addOrRemoveClassList, removeClassName } from '../../utils/globals/components'
import { inputErrorClass } from '../../utils/globals/tailwind'

export const returnClassByLabelUp = (up: boolean) => {
    if (up) {
        return 'input_label_up'
    }
    return ''
}

export const shouldBeLabelUp = (inputRef: any, value?: any) => {
    if (value) {
        return true
    }else if (inputRef && inputRef.current) {
        if (inputRef.current.value) {
            return true
        }
    }
    return false
}
export const changeClassListForLabelUp = (currentTarget: EventTarget & HTMLInputElement) => {
    const className = returnClassByLabelUp(true)
    const shouldAdd = shouldBeLabelUp(null, currentTarget.value)
    addOrRemoveClassList(currentTarget, className, shouldAdd)
}

export const handleErrorsInput = (currentTarget: EventTarget & HTMLInputElement, parentCurrentTarget: HTMLElement | null) => {
    const isValid = currentTarget.checkValidity()
    removeClassListError(currentTarget, parentCurrentTarget, isValid)
    addErrorWhenBlur(currentTarget, parentCurrentTarget, isValid)
}

const removeClassListError = (currentTarget: EventTarget & HTMLInputElement, parentCurrentTarget: HTMLElement | null, isValid: boolean) => {
    const has_previous_errors = currentTarget.classList.contains(inputErrorClass) || parentCurrentTarget?.classList.contains(inputErrorClass)
    if (isValid && has_previous_errors) {
        removeClassName(inputErrorClass, currentTarget)
        removeClassName(inputErrorClass, parentCurrentTarget)
    }
}

const addErrorWhenBlur = (currentTarget: EventTarget & HTMLInputElement, parentCurrentTarget: HTMLElement | null, isValid: boolean) => {
    if (!isValid) {
        addClassName(inputErrorClass, currentTarget)
        addClassName(inputErrorClass, parentCurrentTarget)
    }
}