import { useCallback } from 'react'
import { ModalOpenedSetTy } from '../../baseComponents/Modal/types'
import { BeforeSubmitHandler, OnSubmit } from '../../forms/TwinForm/types'

export interface ModalFormValidationProps {
    setOpened: ModalOpenedSetTy
    onSubmit: OnSubmit
    endLoading?: () => void 
    beforeSubmit?: BeforeSubmitHandler
    startLoading: () => void
}

const useModalFormValidation = ({setOpened, onSubmit, endLoading, beforeSubmit, startLoading}: ModalFormValidationProps) => {
    const handleOnSubmit: OnSubmit = useCallback((res, valuesSend) => {
        endLoading?.()
        setOpened(null)
        onSubmit?.(res, valuesSend)
    }, [setOpened, onSubmit, endLoading])

    const handleOnPreSubmit: BeforeSubmitHandler = useCallback((values, form) => {
        startLoading()
        if (beforeSubmit && !beforeSubmit(values, form)) {
            endLoading?.()
            return false
        } 
        return true
    }, [startLoading, beforeSubmit, endLoading])

    return { handleOnSubmit, handleOnPreSubmit }

}


export default useModalFormValidation