import { Fragment, useCallback, useEffect, useState } from 'react'
import { twinFetchPostJSON } from '../../../../utils/globals/data'
import { CompanyBox, LinkCompanyBox } from './CompanyBox'
import { filterTypeCompany } from './functions'
import { CompaniesInterface, CompaniesRequired, CompanyInterfaceRequired } from './types'
import './selectCompany.sass'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import TwinTrans from '../../../../baseComponents/TwinTrans'

interface SelectCompanyProps {}

const SelectCompany: React.FC<SelectCompanyProps> = () => {
    const { companies } = useCompaniesLogic()
    return (
        <div className='select_company_container' >
            <div className='select_company' >
                <div className='mb-69'>
                    <h1 className='text-39 text-center  mb-48'><TwinTrans transKey={'chooseCompanyOrCreateOne'}>Elige qué empresa quieres gestionar o crea una nueva</TwinTrans></h1>
                </div>
                <Companies companies={companies}/>
            </div>
        </div>
    )
}

interface CompaniesProps extends CompanyInterfaceRequired {}

const Companies: React.FC<CompaniesProps> = ({companies}) => {
    if (companies) {
        return (
            <div className='h-full overflow-y-auto container_companies grid gap-y-50'>
                <RenderCompanies {...{ companies }} />
            </div>
        )
    }
    return null
}

const useCompaniesLogic = () => {
    const [companies, setCompanies] = useState<CompaniesInterface | null>(null)

    const getCompanies = useCallback(async () => {
        const companies = await twinFetchPostJSON('/api/company/getMyCompanies', {})
        const companiesFiltered: CompaniesInterface = { owned: [], invited: [] }
        for (const key in companies) {
            filterTypeCompany(companies[key], companiesFiltered)
        }
        setCompanies({ owned: companiesFiltered.owned, invited: companiesFiltered.invited })
    }, [])

    useEffect(() => {
        getCompanies()
    }, [getCompanies])

    return { companies, getCompanies }
}

interface RenderCompaniesProps extends CompanyInterfaceRequired {}

const RenderCompanies: React.FC<RenderCompaniesProps> = ({companies}) => {
    const renderThis: JSX.Element[] = []
    const {t} = useTwinTranslation()
    if (companies && companies.owned.length) {
        renderThis.push(<RenderCompaniesDisplay companies={companies.owned} title={t('ownedCompanies', 'Empresas propias')}/>)
    }
    if (companies && companies.invited.length) {
        renderThis.push(<RenderCompaniesDisplay companies={companies.invited} title={t('invitedCompanies', 'Empresas Invitadas')}/>)
    }
    return (
        <Fragment>
            {renderThis}
        </Fragment>
    )
}

interface RenderCompaniesDisplayProps extends CompaniesRequired {
    title: string
}

const RenderCompaniesDisplay: React.FC<RenderCompaniesDisplayProps> = ({title, companies}) => {
    return (
        <div>
            <h2 className='mb-20'>{title}</h2>
            <RenderCompaniesBoxes companies={companies} />
        </div>
    )
}

interface RenderCompaniesBoxesProps extends CompaniesRequired{
}

const RenderCompaniesBoxes: React.FC<RenderCompaniesBoxesProps> = ({ companies }) => {
    const renderCompaniesBoxes: JSX.Element[] = []
    for (const key in companies) {
        const company = companies[key]
        if (company.type === 3) {
            renderCompaniesBoxes.push(<CompanyBox key={key} {...company} />)
        } else {
            renderCompaniesBoxes.push(<LinkCompanyBox key={key} {...company} />)
        }
    }
    return (
        <div className='grid grid-cols-3 select_company_grid'>
            {renderCompaniesBoxes}
        </div>
    )
}

export default SelectCompany