import { useCallback } from 'react'
import ButtonModalSortList, { ItemSortFunction } from '../../../../../../../baseComponents/Sorts/ButtonModalSortList'
import { BaseButtonModalSortListProps } from '../../../../../../../baseComponents/Sorts/ButtonModalSortList/types'
import { twinFetchPostText } from '../../../../../../../utils/globals/data'

interface TaskCustomFieldSortButtonProps extends BaseButtonModalSortListProps {
}

const TaskCustomFieldSortButton: React.FC<TaskCustomFieldSortButtonProps> = ({ ...rest }) => {
    const { updateOrder } = useTaskCustomFieldSortButtonLogic()
    return (
        <ButtonModalSortList updateData={updateOrder} getDataFrom='/api/app/task/customField/getAllTaskCFields' {...rest} />
    )
}

const useTaskCustomFieldSortButtonLogic = () => {
    const updateOrder: ItemSortFunction = useCallback(async (data) => {
        const resp = await twinFetchPostText('/api/app/task/customField/updateTaskCFieldOrder', { taskCFields: data })
        if (resp) {
            return true
        }
        return false
    }, [])

    return { updateOrder }
}

export default TaskCustomFieldSortButton