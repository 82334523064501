import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import CustomSelect, { CustomSelectWithSearchBar } from '../../../../../forms/CustomSelect';
import { InputWithLabel } from '../../../../../forms/Input';
import { TwinDictionary } from '../../../../../utils/globals/dictionary';
import { Modify } from '../../../../../utils/globals/types';
import { ButtonPrimary, ButtonSecondary } from '../../../../Button';
import TwinTrans from '../../../../TwinTrans';
import { AllSearchableComponentsComponent, AllSearchableComponentsSingleElement, SearchableComponentProps } from '../types';

interface ModalFilterProps extends ModalFilterLogicProps {
    children: React.ReactNode
    style?: React.CSSProperties
}

const ModalFilter: React.FC<ModalFilterProps> = ({ setOpened, changeSingleField, dataKey, children, style }) => {
    const { resetValue } = useModalFilterLogic({ changeSingleField, dataKey, setOpened} )
    return (
        <div className='table_search_modal' style={style}>
            {children}
            <div className='flex justify-between mt-15'>
                <ButtonSecondary className='table_search_modal_button mr-12' onClick={resetValue}>
                    <TwinTrans transKey={'reset'}>Reiniciar</TwinTrans>
                </ButtonSecondary>
                <ButtonPrimary className='table_search_modal_button' onClick={() => setOpened(false)}>
                    <TwinTrans transKey={'accept'}>Aceptar</TwinTrans>
                </ButtonPrimary>
            </div>
        </div>
    )
}
interface ModalFilterLogicProps {
    setOpened: React.Dispatch<React.SetStateAction<boolean>>
    changeSingleField?: (fieldName: string, value: string) => void
    dataKey: string
}
const useModalFilterLogic = ({ changeSingleField, dataKey, setOpened }: ModalFilterLogicProps  ) => {
    const resetValue = useCallback(() => {
        setOpened(false)
        changeSingleField?.(dataKey, '')
    }, [setOpened, changeSingleField, dataKey])
    return {resetValue}
}

interface SearchComponentProps extends SearchableComponentProps {
    label?: string
}

const SearchComponent: React.FC<SearchComponentProps> = ({ changeSingleField, dataKey, value, ...rest }) => {
    const { handleSearchValue } = useSearchComponentLogic({ changeSingleField, dataKey, value })
    return (
        <InputWithLabel name={dataKey} onChange={(value) => handleSearchValue(value)} defaultValue={value} label={rest.extraComponentData.label} />
    )
}

const useSearchComponentLogic = ({ changeSingleField, dataKey, value }: SearchComponentProps) => {
    const [searchValue, setSearchValue] = useState<string>(value)
    const [debounceValue] = useDebounce(searchValue, 250)

    const handleSearchValue = useCallback((changedValue: string) => {
        setSearchValue(changedValue)
    }, [setSearchValue])

    useEffect(() => {
        changeSingleField?.(dataKey, debounceValue)
    }, [changeSingleField, debounceValue, dataKey])


    return { debounceValue, setSearchValue, handleSearchValue }
}

export type ModalFilterCustomExtraComponentDataSelectProps = {
    extraComponentData: {
        items: TwinDictionary
        label: string
        value?: string | number
        onChange?: (id: any) => void
        fieldName?: string
    }
}

type ModalFilterCustomSelectProps = Modify<ModalFilterCustomExtraComponentDataSelectProps, SearchableComponentProps & {
    value: string
}>

const ModalFilterCustomSelect: React.FC<ModalFilterCustomSelectProps> = ({ changeSingleField, dataKey, value, extraComponentData }) => {
    if (!extraComponentData?.items) {
        return null
    }
    return (<CustomSelect onChange={(value) => changeSingleField?.(dataKey, value)} value={value} {...extraComponentData}  />)
}

interface ModalFilterCustomSelectSearchBarProps extends ModalFilterCustomSelectProps {}

const ModalFilterCustomSelectSearchBar: React.FC<ModalFilterCustomSelectSearchBarProps> = ({ changeSingleField, dataKey, value, extraComponentData }) => {
    if (!extraComponentData?.items) {
        return null
    }
    return (<CustomSelectWithSearchBar onChange={(value) => changeSingleField?.(dataKey, value)} value={value} {...extraComponentData}  />)
}

const elements: AllSearchableComponentsSingleElement = {
    SearchableComponentBasic: SearchComponent,
    SearchableComponentsCustomSelect: ModalFilterCustomSelect,
    SearchableComponentsCustomSelectSearchBar: ModalFilterCustomSelectSearchBar,
    SearchableComponentsCustomSelectSingle: ModalFilterCustomSelect
}

interface SingleElementModalFilterTableRendererProps extends SearchableComponentProps {
    component: AllSearchableComponentsComponent
}

export const SingleElementModalFilterTableRenderer = ({component, ...rest}: SingleElementModalFilterTableRendererProps) => {
    const MyComponent = elements[component]
    return <MyComponent {...rest} />
}

export default ModalFilter;