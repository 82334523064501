import { EmployeeModelType } from '@teinor/erp/types/company/userInner/employee'
import { ComponentWithPermissions } from '../../../../../../../../../baseComponents/CreateRoutering/types'
import TwinFileSystem from '../../../../../../../../../baseComponents/TwinFileSystem'

interface EmployeeFilesProps extends ComponentWithPermissions {
    basicData: EmployeeModelType | null
}

const EmployeeFiles: React.FC<EmployeeFilesProps> = ({ userPermissions, basicData }) => {
    if (basicData?.id) {
        return (<TwinFileSystem subFolder={'/' + basicData.id+'/employeeFolder'} type='employee' userPermissions={userPermissions}></TwinFileSystem>)
    }
    return null
}

export default EmployeeFiles