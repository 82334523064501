import { ConnectedProps, connect } from 'react-redux'
import { ComponentWithPermissions } from '../../../../../../baseComponents/CreateRoutering/types'
import { DisplayStatusWithoutIcon } from '../../../../../../baseComponents/Displays/DisplayStatus'
import { permissionCheck } from '../../../../../../baseComponents/PermissionChecker/function'
import TwinTable from '../../../../../../baseComponents/TwinTable'
import BaseScreenTable from '../../../../../../baseComponents/TwinTable/BaseScreenTable'
import { TableComponentProps } from '../../../../../../baseComponents/TwinTable/BaseScreenTable/types'
import { CustomRenderDate, CustomRenderString, EditRow } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import { WCalHolidayUsedDayModelTypeExtended } from '../../../../../../specificComponents/WCal/WCalHolidayUsedDay/types'
import { TwinDictionary } from '../../../../../../utils/globals/dictionary'
import withLoading from '../../../../../../utils/hoc/withLoading'
import useStatusRequestTypes from '../../../../../../utils/hooks/useStatusRequestTypes'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import { AllReduxPayloads } from '../../../../../../utils/reducers'
import { EmployeesPayload } from '../../../../../../utils/reducers/company/employees'
import ModalEditWCHolidayRequests from './ModalEditWCHolidayRequests'
import ModalCreateWCHolidayRequest from './ModalCreateWCHolidayRequest'
import { ButtonSecondary } from '../../../../../../baseComponents/Button'
import TwinTrans from '../../../../../../baseComponents/TwinTrans'
import { useState } from 'react'
import WCHolidayRequestRangeView from './WCHolidayRequestRangeView'


interface WorkingCalendarHolidayRequestsProps extends ComponentWithPermissions { }

const WorkingCalendarHolidayRequests: React.FC<WorkingCalendarHolidayRequestsProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()
    const { setView, view } = useWorkingCalendarHolidayRequestLogic()
    if (view === 'bars_view') {
        return <WCHolidayRequestRangeView setView={setView} userPermissions={userPermissions} />
    }
    const pageData = {
        title: t('holidayListing', 'Listado de vacaciones'),
        newText: t('newHolidayRequest', 'Nueva solicitud de vacaciones'),
    }
    return (
        <BaseScreenTable TableComponent={TableWorkingCalendarHolidayBagConnect} pageData={pageData} userPermissions={userPermissions} EditModalComponent={ModalEditWCHolidayRequests} CreateModalComponent={ModalCreateWCHolidayRequest} RightHeader={<ButtonSecondary className='mr-5' onClick={() => setView('bars_view')}><TwinTrans transKey='changeView'>Cambiar vista</TwinTrans></ButtonSecondary>}/>
    )
}


const useWorkingCalendarHolidayRequestLogic = () => {
    const [view, setView] = useState<'default' | 'bars_view'>('default')
    return { view, setView }
}

type TableWorkingCalendarHolidayRequestsProps = TableComponentProps & ReduxEmployees & {}


const TableWorkingCalendarHolidayRequests: React.FC<TableWorkingCalendarHolidayRequestsProps> = ({ userPermissions, setTableInstance, setOpened, employees }) => {
    const { t } = useTwinTranslation()
    const statusRequestTypes = useStatusRequestTypes()

    const columns: ColumnTableSchema<WCalHolidayUsedDayModelTypeExtended> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('id', 'ID'),
            width: 75
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('holidayBag', 'Bolsa de vacaciones'),
            searchKey: 'WCalHolidayBag.name',
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            },
            customRender: (parameterValue, allRowData) => <CustomRenderString value={allRowData?.['WCalHolidayBag']?.name} />
        },
        {
            id: 'EmployeeIdRequest',
            dataKey: 'WCalHolidayBag.EmployeeId' as any,
            label: t('employee', 'Empleado'),
            searchKey: 'WCalHolidayBag.Employee.fullname_short',
            searchSingleKey: 'WCalHolidayBag.EmployeeId',
            searchableComponent: {
                component: 'SearchableComponentsCustomSelectSearchBar',
                extraComponentData: { items: employees as TwinDictionary, label: t('employee', 'Empleado'), fieldName: 'fullname_short' },
            },
            customRender: (parameterValue, allRowData) => <CustomRenderString value={allRowData?.['WCalHolidayBag']?.Employee?.fullname_short || ''} />
        },
        {
            id: 'EmployeeId',
            dataKey: 'EmployeeId',
            label: t('managedBy', 'Gestionado por'),
            customRender: (parameterValue, allRowData) => <CustomRenderString value={allRowData?.Employee?.fullname_short || ''} />
        },
        {
            id: 'from',
            dataKey: 'from',
            label: t('from', 'Desde'),
            sortable: true,
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />
        },
        {
            id: 'to',
            dataKey: 'to',
            label: t('to', 'Hasta'),
            sortable: true,
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />
        },
        {
            id: 'approved',
            dataKey: 'approved',
            label: t('status', 'Estado'),
            customRender: (parameterValue) => <RenderUsedDayStatus value={parameterValue} />,
            searchableComponent: {
                component: 'SearchableComponentsCustomSelectSearchBar',
                extraComponentData: { items: statusRequestTypes, label: t('status', 'Estado') }
            },
        },
        {
            id: 'approvedDate',
            dataKey: 'approvedDate',
            label: t('approvedDate', 'Fecha de aprobación'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            customRender: (parameterValue, allRowData) => <EditRow key={'edit' + parameterValue} {...{ parameterValue, allRowData }} />,
            havePermission: permissionCheck(userPermissions, 'update')
        }
    ]
    return (
        <TwinTable name='workingCalendarHolidayUsedDays' columns={columns} getDataFrom='/api/app/workingCalendar/workingCalendarHolidayBag/getAllWorkingCalendarHolidayUsedDays' onRowClick={(_id, rowData) => setOpened({
            type: 'edit',
            allRowData: rowData
        })} setTableInstance={setTableInstance} />
    )
}

interface RenderBuyOrderStatusProps {
    value: string
}

const RenderUsedDayStatus: React.FC<RenderBuyOrderStatusProps> = ({ value }) => {
    const status = useStatusRequestTypes()
    const parsedValue = parseInt(value)
    return <DisplayStatusWithoutIcon colorText={status[parsedValue].colorText} text={status[parsedValue].name} />
}

const employeesDispatch = {
    setEmployees: (payload: EmployeesPayload) =>
        ({ type: 'CHANGE_EMPLOYEE', payload })
}

export type ReduxEmployees = ConnectedProps<typeof employeesConnect>
const mapEmployeesConnector = (state: AllReduxPayloads) => ({ employees: state.employees })
const employeesConnect = connect(mapEmployeesConnector, employeesDispatch)

const employeesConnectLoading = withLoading(TableWorkingCalendarHolidayRequests, [{ fetchUrl: '/api/app/employee/getAllEmployees', propName: 'employees', setFunctionName: 'setEmployees' }])

const TableWorkingCalendarHolidayBagConnect = employeesConnect(employeesConnectLoading)

export default WorkingCalendarHolidayRequests