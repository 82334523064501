import { EmployeeAbsenceModelType, EmployeeAbsenceTypeKeysInversed } from '@teinor/erp/types/company/userInner/employee/employeeAbsence'
import { useCallback, useEffect, useState } from 'react'
import { ButtonOrLoader } from '../../../../../../../baseComponents/Button'
import LoadingSpinner from '../../../../../../../baseComponents/LoaderDecider/LoadingSpinner'
import { ModalLittle } from '../../../../../../../baseComponents/Modal'
import ModalDeleteFilled from '../../../../../../../baseComponents/ModalsLayouts/ModalDeleteFilled'
import { ModalEditComponentProps } from '../../../../../../../baseComponents/ModalsLayouts/types'
import PermissionChecker from '../../../../../../../baseComponents/PermissionChecker'
import TwinTrans from '../../../../../../../baseComponents/TwinTrans'
import FormRenderer from '../../../../../../../forms/FormRenderer'
import { FormRender } from '../../../../../../../forms/FormRenderer/types'
import CustomRenderSchedules from '../../../../../../../specificComponents/WCal/schedule/CustomRenderSchedules'
import { twinFetchPostJSON } from '../../../../../../../utils/globals/data'
import { displayDate } from '../../../../../../../utils/globals/date'
import { TwinDictionary } from '../../../../../../../utils/globals/dictionary'
import useEditDeleteModal from '../../../../../../../utils/hooks/useEditDeleteModal'
import useStatusRequestTypes from '../../../../../../../utils/hooks/useStatusRequestTypes'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import { changeErrorMessage, changeSuccessMessage } from '../../../../../../../utils/reducers/reduxDispatch'

interface ModalManageEmployeeAbsenceRequestProps extends ModalManageEmployeeAbsenceRequestLogicProps {
}

const ModalManageEmployeeAbsenceRequest: React.FC<ModalManageEmployeeAbsenceRequestProps> = ({ userPermissions, setOpened, allRowData, onSubmit }) => {
    const { manageEmployeeAbsence, data, openModal, setOpenModal } = useModalManageEmployeeAbsenceRequestLogic({ allRowData, setOpened, onSubmit })
    const { t } = useTwinTranslation()
    const status = useStatusRequestTypes()

    const statusButtons: TwinDictionary = {
        'approved': <ButtonOrLoader onClick={() => manageEmployeeAbsence('approved')} textButton={t('approve', 'Aprobar')} buttonIsDisabled={false} />,
        'denied': <ButtonOrLoader onClick={() => manageEmployeeAbsence('denied')} textButton={t('deny', 'Denegar')} buttonIsDisabled={false} className='button_red' />,
        'cancel': <ButtonOrLoader onClick={() => setOpenModal({ type: 'edit', allRowData: allRowData ? allRowData : [] })} textButton={t('cancelAbsence', 'Cancelar ausencia')} buttonIsDisabled={false} className='button_red' />
    }

    const renderThis: JSX.Element[] = []
    if (data?.approved === 0) {
        renderThis.push(statusButtons['approved'])
        renderThis.push(statusButtons['denied'])
    } else if (data?.approved === 1) {
        renderThis.push(statusButtons['cancel'])
    }

    if(!data){
        return (
            <ModalLittle opened={true} setOpened={setOpened} onClickOut={false} className={'flex flex-col'}>
                <LoadingSpinner />
            </ModalLittle>
        )
    }
    const fields: FormRender<EmployeeAbsenceModelType> = [
        {
            cols: 1,
            elements: [
                {
                    name: 'EmployeeAbsenceTypeId',
                    component: 'TextField',
                    label: t('absenceType', 'Type'),
                    value: data?.EmployeeAbsenceType?.name,
                },
                {
                    name: 'EmployeeId',
                    component: 'TextField',
                    value: data?.Employee?.fullname_short,
                    label: t('employee', 'Empleado')
                },
                {
                    name: 'dateStart',
                    component: 'TextField',
                    label: t('dateStart', 'Fecha inicio'),
                    value: displayDate(new Date(data?.dateStart || ''))
                },
                {
                    name: 'dateEnd',
                    component: 'TextField',
                    label: t('dateEnd', 'Fecha fin'),
                    value: displayDate(new Date(data?.dateEnd || '')),
                },
                {
                    name: 'description',
                    component: 'TextField',
                    label: t('description', 'Descripción'),
                    value: data?.description
                },
                {
                    name: 'EmployeeAbsenceTypeId',
                    component: 'TextField',
                    label: t('type', 'Tipo'),
                    value: data?.EmployeeAbsenceType?.name
                },
                {
                    name: 'schedule',
                    component: 'Custom',
                    render: () => <CustomRenderSchedules schedule={data?.schedule} />
                },
                {
                    name: 'proof_file_url',
                    component: 'FilesInput',
                    label: t('absenceProof', 'Justificante ausencia'),
                    defaultValue: data?.proof_file_url,
                    readOnly: true
                },
                {
                    name: 'approved',
                    component: 'TextField',
                    label: t('status', 'Estado'),
                    value: status[parseInt(String(data?.approved))]?.name
                },
                {
                    name: 'id',
                    component: 'InputHidden',
                    value: data?.id
                },
            ]
        },
    ]
    return (
        <ModalLittle opened={true} setOpened={setOpened} onClickOut={false} className={'flex flex-col'}>
            <div className='mb-25'>
                <h2><TwinTrans transKey='manageAbsences'>Gestionar ausencias</TwinTrans></h2>
            </div>
            <div className={'flex flex-auto h-1'}>
                <div className='overflow-auto flex flex-auto flex-col'>
                    {fields.length ?
                        <FormRenderer sections={fields} className='grid_mincontent'>
                        </FormRenderer>
                        : null}
                </div>
            </div>
            <div className='mt-30 ml-auto flex gap-15 edit_holiday_requests_footer'>
                <PermissionChecker userPermissions={userPermissions!} permission={'update'}>
                    {renderThis}
                </PermissionChecker>
            </div>
            {openModal?.type === 'edit' && <ModalCancelEmployeeAbsenceRequest {...{ setOpened: () => setOpenModal(null) }} allRowData={openModal.allRowData} onSubmit={(res, send) => { onSubmit(res, send); setOpened(null) }} />}
        </ModalLittle>
    )
}

interface ModalManageEmployeeAbsenceRequestLogicProps extends ModalEditComponentProps { }

const useModalManageEmployeeAbsenceRequestLogic = ({ allRowData, setOpened, onSubmit }: ModalManageEmployeeAbsenceRequestLogicProps) => {
    const { t } = useTwinTranslation()
    const { openModal, setOpenModal } = useEditDeleteModal()
    const message = t('successfullyCompleted', 'Realizado correctamente')
    const [data, setData] = useState<EmployeeAbsenceModelType | null>(null)
    const manageEmployeeAbsence = useCallback(async (status: EmployeeAbsenceTypeKeysInversed) => {
        const result = await twinFetchPostJSON('/api/app/workingCalendar/employeeAbsence/manageAbsenceRequest', {
            id: allRowData?.id,
            approved: status === 'approved' ? 1 : 2
        })
        if (result) {
            onSubmit(result, {})
            changeSuccessMessage(message)
            setOpened(null)
        } else {
            changeErrorMessage(true)
        }
    }, [allRowData?.id, setOpened, onSubmit, message])

    const getAbsencesData = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/workingCalendar/employeeAbsence/getEmployeeAbsenceInstance', { id: allRowData?.id })
        if (result) {
            setData(result)
        }
    }, [setData, allRowData?.id])

    useEffect(() => {
        getAbsencesData()
    }, [getAbsencesData])

    return { manageEmployeeAbsence, data, getAbsencesData, openModal, setOpenModal }
}

interface ModalCancelEmployeeAbsenceRequestProps extends ModalCancelEmployeeAbsenceRequestLogicProps { }

const ModalCancelEmployeeAbsenceRequest: React.FC<ModalCancelEmployeeAbsenceRequestProps> = ({ onSubmit, setOpened, allRowData }) => {
    const { cancelEmployeeAbsence } = useModalCancelEmployeeAbsenceRequestLogic({ onSubmit, setOpened, allRowData })
    const { t } = useTwinTranslation()

    return (
        <ModalDeleteFilled opened={true} setOpened={setOpened} onCancel={() => setOpened(null)} onAccept={cancelEmployeeAbsence} translations={{
            title: t('sureCancelEmployeeAbsence', '¿Seguro que quieres cancelar esta ausencia?'),
            subtitle: t('onceCanceledNotRecoverM', 'Una vez cancelado no podrás recuperarlo')
        }} />
    )
}

interface ModalCancelEmployeeAbsenceRequestLogicProps extends ModalEditComponentProps { }

const useModalCancelEmployeeAbsenceRequestLogic = ({ onSubmit, setOpened, allRowData }: ModalCancelEmployeeAbsenceRequestLogicProps) => {
    const { t } = useTwinTranslation()

    const message = t('successfullyCompleted', 'Realizado correctamente')

    const cancelEmployeeAbsence = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/workingCalendar/employeeAbsence/cancelAbsenceRequest', {
            id: allRowData?.id
        })
        if (result) {
            onSubmit(result, {})
            changeSuccessMessage(message)
            setOpened(null)
        } else {
            changeErrorMessage(true)
        }
    }, [allRowData?.id, setOpened, onSubmit, message])

    return { cancelEmployeeAbsence }
}

export default ModalManageEmployeeAbsenceRequest