import { EmployeeExpenseStatusKeys } from '@teinor/erp/types/company/userInner/employee/employeeExpense'
import useTwinTranslation from '../useTwinTranslation'


interface ExpenseStatusTy {
    name: string
    colorText: string
}
const useEmployeeExpensesType = () => {
    const {t} = useTwinTranslation()
    const expenseStatus: Record<EmployeeExpenseStatusKeys, ExpenseStatusTy > = {
        0: { name: t('notProcessed', 'Sin procesar'), colorText: 'text-gray-2E' },
        1: { name: t('pendingPaymentShort', 'Pago Pend.'), colorText: 'text-yellow-B9' },
        2: { name: t('deniedSingle', 'Denegado'), colorText: 'text-red-BA' },
        3: { name: t('paid', 'Pagado'), colorText: 'text-green-21' },
    }
    return {expenseStatus}
}

export default useEmployeeExpensesType
