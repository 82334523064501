import { useCallback } from 'react'
import { ModalOpenedSetTy } from '../../../../../../baseComponents/Modal/types'
import { ModalSmallFilledOutside } from '../../../../../../baseComponents/ModalsLayouts/ModalSmallFilled'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import { SetTaskDataExtended } from '../../../types'
import { twinFetchPostJSON } from '../../../../../../utils/globals/data'

interface ModalTaskSubscriberProps extends ModalTaskSubscriberLogicProps {
    is_subscribed: boolean
}

const ModalTaskSubscriber: React.FC<ModalTaskSubscriberProps> = ({ is_subscribed, setOpened, setData, TaskId }) => {
    const { t } = useTwinTranslation()
    const { handleUpdateSubscribe } = useModalTaskSubscriberLogic({ setOpened, setData, TaskId })
    const title = is_subscribed ? t('unfollowTask', 'Dejar de seguir la tarea') : t('subscribeTask', 'Suscribirse a la Tarea')
    return (
        <ModalSmallFilledOutside translations={{
            title,
            subtitle: is_subscribed ? t('unfollowTaskExplanation', 'Estás suscrito a esta tarea. Si dejas de seguir esta tarea, ya no recibirás notificaciones sobre los cambios en la tarea.') : t('subscriberTaskExplanation', 'Si te suscribes a esta tarea, recibirás notificaciones sobre los cambios realizados.'),
            buttonAccept: t('accept', 'Aceptar')
        }} onAccept={() => { handleUpdateSubscribe(!is_subscribed) }} opened={true} setOpened={() => setOpened(null)} />
    )
}

interface ModalTaskSubscriberLogicProps {
    setOpened: ModalOpenedSetTy
    setData: SetTaskDataExtended
    TaskId: number
}

const useModalTaskSubscriberLogic = ({ TaskId, setData }: ModalTaskSubscriberLogicProps) => {

    const subscribeTask = useCallback(async() => {
        const taskSubscriber = await twinFetchPostJSON('/api/app/task/subscriber/createTaskSubscriber', { TaskId })
        if (taskSubscriber) {
            setData((old) => {
                if (!old) {
                    return null
                }
                const copyOld = JSON.parse(JSON.stringify(old))
                copyOld.is_subscribed = true
                return { ...copyOld }
            })
        } 
    }, [setData, TaskId])

    const unsubscribeTask = useCallback(async() => {
        const taskSubscriber = await twinFetchPostJSON('/api/app/task/subscriber/deleteTaskSubscriber', { TaskId })
        if (taskSubscriber) {
            setData((old) => {
                if (!old) {
                    return null
                }
                const copyOld = JSON.parse(JSON.stringify(old))
                copyOld.is_subscribed = false
                return {...copyOld}
            })
        } 
    }, [setData, TaskId])

    const handleUpdateSubscribe = useCallback(async (subscribe: boolean) => {
        if (subscribe) {
            await subscribeTask()
        } else {
            await unsubscribeTask()
        }
    }, [subscribeTask, unsubscribeTask])


    return { handleUpdateSubscribe }
}


export default ModalTaskSubscriber