import { useState, useCallback, useEffect } from 'react'
import { useLocation, useParams } from 'react-router'
import { ComponentWithPermissions } from '../../../../../../../baseComponents/CreateRoutering/types'
import EditCardTabs from '../../../../../../../baseComponents/EditCardTabs'
import { twinFetchPostJSON } from '../../../../../../../utils/globals/data'
import { getBasePath } from '../../../../../../../utils/globals/link'
import { ComponentTabObject } from '../../../../../../../utils/hooks/useTabLogic'
import PermissionTab from './PermissionTab'

type PermissionGroupProps =ComponentWithPermissions & {}

const PermissionGroup: React.FC<PermissionGroupProps> = ({ userPermissions }) => {
    const location = useLocation()
    const params = useParams<{ id: string, tab?: string }>()
    
    const { basicData, getData } = usePermissionGroupLogic()

    if (!params.id) {
        return null
    }
    return (<EditCardTabs basePath={getBasePath(location.pathname, 'groups')} basicData={basicData} tabs={basicData} tab={params.id} userPermissions={userPermissions} getData={getData} />)
}

const usePermissionGroupLogic = () => {
    const [basicData, setBasicData] = useState<ComponentTabObject>({})
    const getData = useCallback(async () => {
        const data = await twinFetchPostJSON('/api/app/group/getAllGroups', {})
        let tabsCustom: ComponentTabObject = {}
        if (data) {
            for (const key in data) {
                tabsCustom[String(data[key].id)] = { component: PermissionTab, text: data[key].name, permission: 'group.permissions'}
            }
        }
        setBasicData(tabsCustom)
    }, [setBasicData])
    
    useEffect(() => {
        getData()
    }, [getData])

    return {basicData, getData}
}

export default PermissionGroup