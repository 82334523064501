import { TwinDictionary } from '../globals/dictionary'
import useTwinTranslation from './useTwinTranslation'

const useStatusRequestTypes = () => {
    const { t } = useTwinTranslation()
    const requestStatusTypesDic: TwinDictionary = {
        0: { name: t('pendingApprovedShort', 'Por aprobar'), colorText: 'text-yellow-B9' },
        1: { name: t('approved', 'Aprobadas'), colorText: 'text-green-21' },
        2: { name: t('denied', 'Denegadas'), colorText: 'text-red-BA' },
        3: { name: t('canceled', 'Canceladas'), colorText: 'text-red-BA' }
    }
    return requestStatusTypesDic
}

export default useStatusRequestTypes