import { faClock } from '@fortawesome/pro-light-svg-icons'
import { HeaderElementTask } from '..'
import { SetEditDeleteModalTy } from '../../../../../utils/hooks/useEditDeleteModal'


interface HeaderTaskInvertedTimeProps {
    id: string
    message: string
    setOpenModalInvertedTime: SetEditDeleteModalTy 
}

const HeaderTaskInvertedTime: React.FC<HeaderTaskInvertedTimeProps> = ({ id, message, setOpenModalInvertedTime }) => {
    return (
        <HeaderElementTask id={'invertedTime_' + id} message={message} icon={faClock} onClick={() => setOpenModalInvertedTime({type: 'create', allRowData: {}})} />
    )
}


export default HeaderTaskInvertedTime