import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { WhatCanYouDoKeys } from '@teinor/erp/permissions'
import { ButtonPrimary } from '../../Button'
import { ComponentWithPermissions } from '../../CreateRoutering/types'
import PermissionChecker from '../../PermissionChecker'
import TwinIcon from '../../TwinIcon'

interface BaseLayoutProps {
    title: string
    RightHeader?: JSX.Element
    className?: string
    children: React.ReactNode
}

export const BigTitleAndRightLayout: React.FC<BaseLayoutProps> = ({title, RightHeader, className,  children}) => {
    return (
        <div className={'px-40 py-45 flex flex-col flex-auto big-layout ' + (className ||'')}>
            <div className='flex justify-between mb-30 big_layout_title'>
                <h1>{title}</h1>
                {RightHeader}
            </div>
            {children}
        </div>
    )
}

export const SmallTitleAndRightLayout: React.FC<BaseLayoutProps> = ({title, RightHeader, children}) => {
    return (
        <div className='flex flex-col flex-auto'>
            <div className='flex justify-between items-center mb-40'>
                <span className='regular18'>{title}</span>
                {RightHeader}
            </div>
            {children}
        </div>
    )
}

export const MiddleTitleAndRightLayout: React.FC<BaseLayoutProps> = ({title, RightHeader, children}) => {
    return (
        <div className='flex flex-col flex-auto'>
            <div className='flex justify-between items-center mb-40'>
                <h2>{title}</h2>
                {RightHeader}
            </div>
            {children}
        </div>
    )
}

interface TitleSButtonPermissionedProps extends ComponentWithPermissions {
    title: string
    buttonText?: string
    onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
    permission?: WhatCanYouDoKeys
    children: React.ReactNode
}

export const TitleSButtonPermissioned: React.FC<TitleSButtonPermissionedProps> = ({userPermissions, onClick, title, buttonText, children, permission = 'create'}) => {
    return (
        <SmallTitleAndRightLayout title={title} RightHeader={
            <PermissionChecker userPermissions={userPermissions} permission={permission}>
                {buttonText && <ButtonPrimary onClick={onClick}>
                    <TwinIcon icon={faPlus} className='mr-8' />
                    {buttonText}
                </ButtonPrimary>}
            </PermissionChecker>
        }>
            {children}
        </SmallTitleAndRightLayout>
    )
}

interface TitleMButtonPermissionedProps extends TitleSButtonPermissionedProps {}

export const TitleMButtonPermissioned: React.FC<TitleMButtonPermissionedProps> = ({userPermissions, onClick, title, buttonText, children, permission = 'create'}) => {
    return (
        <SmallTitleAndRightLayout title={title} RightHeader={
            <PermissionChecker userPermissions={userPermissions} permission={permission}>
                {buttonText && <ButtonPrimary onClick={onClick}>
                    <TwinIcon icon={faPlus} className='mr-8' />
                    {buttonText}
                </ButtonPrimary>}
            </PermissionChecker>
        }>
            {children}
        </SmallTitleAndRightLayout>
    )
}