import { CompaniesInterface } from './types'

export const filterTypeCompany = (company: any, companiesFiltered: CompaniesInterface) => {
    const type = company.Users[0].UserCompany.type
    company.type = type
    if (type === 1 || type ===  2) {
        handleAdminCompanyType(company, companiesFiltered)
    } else {
        handleInvitedCompanyType(company, companiesFiltered)
    }
}

const handleAdminCompanyType = (company: any, companiesFiltered: CompaniesInterface) => {
    companiesFiltered.owned.push(company)
}

const handleInvitedCompanyType = (company: any, companiesFiltered: CompaniesInterface) => {
    companiesFiltered.invited.push(company)
}