import { EmployeeAbsenceModelType } from '@teinor/erp/types/company/userInner/employee/employeeAbsence'
import { useCallback, useEffect, useState } from 'react'
import LoadingSpinner from '../../../../../../../../../../baseComponents/LoaderDecider/LoadingSpinner'
import { ModalLittle } from '../../../../../../../../../../baseComponents/Modal'
import { ModalCreateEditStructureLayout } from '../../../../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../../../../../../../../baseComponents/ModalsLayouts/types'
import FormRenderer from '../../../../../../../../../../forms/FormRenderer'
import { FormRender } from '../../../../../../../../../../forms/FormRenderer/types'
import CustomRenderSchedules from '../../../../../../../../../../specificComponents/WCal/schedule/CustomRenderSchedules'
import { twinFetchPostJSON } from '../../../../../../../../../../utils/globals/data'
import { displayDate } from '../../../../../../../../../../utils/globals/date'
import { valueOrDefaultValue } from '../../../../../../../../../../utils/globals/valueOrDefaultValue'
import useStatusRequestTypes from '../../../../../../../../../../utils/hooks/useStatusRequestTypes'
import useTwinTranslation from '../../../../../../../../../../utils/hooks/useTwinTranslation'
import { getUserPermissions } from '../../../../../../../../../../utils/reducers/getters'
import { EmployeeAbsenceModelTypeExtended } from '../ModalCreateEmployeeAbsence/types'

interface ModalEditEmployeeAbsenceProps extends ModalEditEmployeeAbsenceLogicProps {
}

const ModalEditEmployeeAbsence: React.FC<ModalEditEmployeeAbsenceProps> = ({ setOpened, allRowData, onSubmit }) => {
    const { t } = useTwinTranslation()
    const status = useStatusRequestTypes()
    const { data } = useModalEditEmployeeAbsenceLogic({ allRowData, setOpened, onSubmit })
    if (!data) {
        return (
            <ModalLittle opened={true} setOpened={setOpened} onClickOut={false} className={'flex flex-col'}>
                <LoadingSpinner />
            </ModalLittle>
        )
    }
    const fields: FormRender<EmployeeAbsenceModelType> = [
        {
            cols: 1,
            elements: [
                {
                    name: 'EmployeeId',
                    component: 'TextField',
                    value: data?.Employee?.name,
                    label: t('employee', 'Empleado')
                },
                {
                    name: 'dateStart',
                    component: 'TextField',
                    label: t('dateStart', 'Desde'),
                    value: displayDate(new Date(data?.dateStart || ''))
                },
                {
                    name: 'dateEnd',
                    component: 'TextField',
                    label: t('dateEnd', 'Hasta'),
                    value: displayDate(new Date(data?.dateEnd || '')),
                },
                {
                    name: 'description',
                    component: 'TextField',
                    label: t('description', 'Descripción'),
                    value: data?.description
                },
                {
                    name: 'EmployeeAbsenceTypeId',
                    component: 'TextField',
                    label: t('type', 'Tipo'),
                    value: data?.EmployeeAbsenceType?.name
                },
                {
                    name: 'schedule',
                    component: 'Custom',
                    render: () => <CustomRenderSchedules schedule={data?.schedule} />
                },
                {
                    name: 'proof_file_url',
                    component: 'FilesInput',
                    label: t('absenceProof', 'Justificante ausencia'),
                    defaultValue: data?.proof_file_url,
                    readOnly: true
                },
                {
                    name: 'approved',
                    component: 'TextField',
                    label: t('status', 'Estado'),
                    value: status[parseInt(String(data?.approved))]?.name
                },
                {
                    name: 'id',
                    component: 'InputHidden',
                    value: data?.id
                },
            ]
        },
    ]
    const userPermission = getUserPermissions('WCal.absences.control')
    if (userPermission) {
        const parsedFields = valueOrDefaultValue(fields, userPermission, allRowData)
        return (
            <ModalLittle opened={true} setOpened={setOpened} onClickOut={false} className={'flex flex-col'}>
                <ModalCreateEditStructureLayout loading={false} translations={{
                    title: t('notificationInfo', 'Información de la notificación'),
                    button: ''
                }} haveButtonPermissions={false} >
                    <div className='overflow-auto flex flex-auto flex-col'>
                        {fields.length ?
                            <FormRenderer sections={parsedFields} className='grid_mincontent' />
                            : null}
                    </div>
                </ModalCreateEditStructureLayout>
            </ModalLittle>
        )
    }
    return null
}

interface ModalEditEmployeeAbsenceLogicProps extends ModalEditComponentProps {
}

const useModalEditEmployeeAbsenceLogic = ({ allRowData }: ModalEditEmployeeAbsenceLogicProps) => {
    const [data, setData] = useState<EmployeeAbsenceModelTypeExtended | null>(null)
    
    const getAbsenceData = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/workingCalendar/employeeAbsence/getEmployeeAbsenceInstance', { id: allRowData?.id })
        if (result) {
            setData(result)
        }
    }, [setData, allRowData?.id])

    useEffect(() => {
        getAbsenceData()
    }, [getAbsenceData])
    return { data }
}

export default ModalEditEmployeeAbsence