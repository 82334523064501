import { faKey, faLockKeyhole, faUserLarge } from '@fortawesome/pro-light-svg-icons';
import { Fragment, useCallback, useEffect, useState } from 'react';
import CustomError from '../../../../baseComponents/CustomError';
import { useTabLogic } from '../../../../utils/hooks/useTabLogic';
import { BeforeSubmitHandler, OnSubmit } from '../../../../forms/TwinForm/types';
import FormRenderer from '../../../../forms/FormRenderer';
import { FormRender } from '../../../../forms/FormRenderer/types';
import { ApiError } from '../../../../utils/globals/data';
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation';
import { FooterOfLoginForm, FormAuthentication, LoginHeader, LoginSubHeader } from './Components';
import { classNameError, deciderByActive, handleLoginSubmit } from './functions';
import { UserModelType } from '@teinor/erp/types/environment/user';
import useIsLoading from '../../../../utils/hooks/useIsLoading';
import '../../components/AuthComposition/auth.sass'

export interface LoginProps {}

const Login: React.FC<LoginProps> = () => {
    const {active, ComponentFromTab, onChangeTabs} = useLoginLogic()
    
    return (
        <Fragment>
            <LoginHeader active={active} onChangeTabs={onChangeTabs} />
            <LoginSubHeader />
            <LoginBaseForm active={active}>
                <ComponentFromTab />
            </LoginBaseForm>
            {/* <FooterLink textLink={t('createAccount', 'Crear una cuenta')} link={'/register'} /> */}
        </Fragment>
    );
}

const useLoginLogic = () => {
    const { active, ComponentFromTab, onChangeTabs } = useTabLogic([
        {
            component: LoginERP
        },
        {
            component: LoginAdmin
        }
    ])

    return {active, ComponentFromTab, onChangeTabs}
}


interface LoginBaseFormProps extends LoginBaseFormLogicProps {
    children?: React.ReactNode
}

const LoginBaseForm: React.FC<LoginBaseFormProps> = ({ children, ...props }) => {
    const {t} = useTwinTranslation()
    const { errors, onSubmit, loading, handleOnPreSubmit } = useLoginBaseFormLogic(props)
    let error: string | false = false
    if (errors) {
        if (errors.msg === 'New device detected') {
            error = t('newDeviceDetected', 'Nuevo dispositivo detectado. Por favor actívalo para poder continuar trabajando.')
        } else {
            error = t('loginIncorrect', 'Datos de acceso incorrectos')
        }
    }
    return (
        <div className={classNameError(errors) + ' login_base_form'}>
            <CustomError textError={error} className='my-10'  />
            <FormAuthentication buttonText={t('enter', 'Entrar')} action='/api/tryLogin' onSubmit={onSubmit} beforeSubmitHandler={handleOnPreSubmit} loading={loading}>
                {children}
                <FooterOfLoginForm />
            </FormAuthentication>
        </div>
    )
}
interface LoginBaseFormLogicProps {
    active: number
}
const useLoginBaseFormLogic = ({active}: LoginBaseFormLogicProps) => {
    const [errors, setErrors] = useState<false | ApiError>(false)
    const { startLoading, loading, endLoading } = useIsLoading()

    const onSubmit: OnSubmit = useCallback(async (res, values) => {
        await handleLoginSubmit(res, deciderByActive(active), setErrors, values.remember)
        endLoading()
    }, [active, endLoading])

    const handleOnPreSubmit: BeforeSubmitHandler = useCallback((values, form) => {
        startLoading()
        return true
    }, [startLoading])

    useEffect(() => {
        setErrors(false)
    }, [active])

    return {errors, onSubmit, loading, handleOnPreSubmit}
}


interface LoginERPProps { }

export const LoginERP: React.FC<LoginERPProps> = () => {
    const { t } = useTwinTranslation()
    const fields: FormRender<UserModelType>  = [
        {
            cols: 1,
            elements: [
                {
                    name: 'token',
                    placeholder: t('code', 'Código'),
                    component: 'InputWithLeftIcon',
                    required: true,
                    icon: faKey
                },
                {
                    name: 'email',
                    placeholder: t('email', 'Email'),
                    component: 'InputWithLeftIcon',
                    type: 'email',
                    required: true,
                    icon: faUserLarge
                },
                {
                    name: 'password',
                    placeholder: t('password', 'Contraseña'),
                    component: 'InputPassword',
                    required: true,
                    icon: faLockKeyhole
                }
            ]
        }
    ]
    return <FormRenderer sections={fields} />
}

interface LoginAdminProps { }

export const LoginAdmin: React.FC<LoginAdminProps> = () => {
    const { t } = useTwinTranslation()
    const fieldsAdmin: FormRender<UserModelType> = [
        {
            cols: 1,
            elements: [
                {
                    name: 'email',
                    placeholder: t('email', 'Email'),
                    component: 'InputWithLeftIcon',
                    type: 'email',
                    required: true,
                    icon: faUserLarge
                },
                {
                    name: 'password',
                    placeholder: t('password', 'Contraseña'),
                    component: 'InputPassword',
                    required: true,
                    icon: faLockKeyhole
                }
            ]
        }
    ]
    return <FormRenderer sections={fieldsAdmin} />
}

export default Login;