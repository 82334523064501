import { useCallback, useMemo, useState } from 'react'
import { SearchBarCheckBox } from '../../../baseComponents/SearchBar'
import TwinIcon, {TwinIconProp} from '../../../baseComponents/TwinIcon'
import Checkbox from '../../../forms/Checkbox'
import { filterCustomSelectItems } from '../../../forms/CustomSelect/DropDown/functions'
import { TwinDictionary } from '../../../utils/globals/dictionary'
import { ChangeValueFunc } from '../../../utils/globals/types'
import useTwinTranslation from '../../../utils/hooks/useTwinTranslation'
import { MultipleCbxSelectorOption, MultipleCbxSelectorValues } from '../types'

interface SingleColumnSelectorProps {
    options: MultipleCbxSelectorOption
    onChange: (value: string) => void
    values: MultipleCbxSelectorValues
    onChangeAll: (value: boolean) => void
    keyName?: string
    subtitle?: string
}

export const SingleColumnSelector: React.FC<SingleColumnSelectorProps> = ({ options, onChange, values, onChangeAll, subtitle, keyName= 'name' }) => {
    const { t } = useTwinTranslation()
    const { searchValue, changeSearchValue, filteredItems } = useSelectorLogic(options)
    const renderOptions: JSX.Element[] = []
    for (const value in filteredItems) {
        const option = filteredItems[value]?.[keyName]
        renderOptions.push(<div key={value} className='option_selector' div-value={value}><Checkbox label={option} onChange={() => onChange(value)} checked={values.includes(value)} /></div>)
    }
    const checked = values.length === Object.keys(options).length && values.length > 0
    return (
        <div className='flex-col selector_column'>
            <span className='subtitle block medium14 my-20 '>{subtitle}</span>
            <div className='selector'>
                <SearchBarCheckBox onChangeCheckbox={onChangeAll}className='selector_searchbar' placeholder={t('search', 'Buscar')} onChange={changeSearchValue} {...{ searchValue, checked }} />
                <div className='options_container'>
                    {renderOptions}
                </div>
            </div>
        </div>
    )
}

export const useSelectorLogic = (items: TwinDictionary) => {
    const [searchValue, setSearchValue] = useState<string>('')
    const filteredItems = useMemo(() => filterCustomSelectItems(searchValue, items), [searchValue, items])
    const changeSearchValue: ChangeValueFunc = useCallback((myvalue) => {
        setSearchValue(myvalue)
    }, [setSearchValue])

    return { searchValue, changeSearchValue, filteredItems }
}

interface MiddleIconsButtonsProps {
    onClick: () => void
    children?: React.ReactNode
}

export const MiddleIconsButton: React.FC<MiddleIconsButtonsProps> = ({ onClick, children }) => {
    return (
        <div className='middle_icons_button' onClick={onClick}>
            {children}
        </div>
    )
}
interface MiddleIconsDoubleIconProps extends MiddleIconsButtonsProps {
    icon: TwinIconProp
}
export const MiddleIconsDoubleIcon: React.FC<MiddleIconsDoubleIconProps> = ({ icon, onClick }) => {
    return (
        <MiddleIconsButton onClick={onClick}>
            <TwinIcon icon={icon} className=' mr-6' />
            <TwinIcon icon={icon} className=' mr-6' />
        </MiddleIconsButton>
    )
}