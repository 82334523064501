import { TaskBagOfHoursModelType } from '@teinor/erp/types/company/task/taskBagOfHours'
import { useCallback, useState } from 'react'
import { LittleModalCreateEdit } from '../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../../../../../baseComponents/ModalsLayouts/types'
import { FormRender } from '../../../../../../../forms/FormRenderer/types'
import { twinFetchPostJSON } from '../../../../../../../utils/globals/data'
import { TwinDictionary, dictionaryComplexFromJsonArr } from '../../../../../../../utils/globals/dictionary'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import { getCustomers } from '../../../../../../../utils/reducers/getters'
import CustomSelectSectionCustomers from '../../../../../../../specificComponents/Customers/CustomSelectSectionCustomers'

interface ModalCreateTaskBagOfHoursProps extends ModalEditComponentProps { }

interface TaskBagOfHoursModelTypeExtended extends TaskBagOfHoursModelType {
    CustomerId: number
}
const ModalCreateTaskBagOfHours: React.FC<ModalCreateTaskBagOfHoursProps> = ({ allRowData, userPermissions, ...rest }) => {
    const { t } = useTwinTranslation()
    const { customerProjects, getCustomerProjects, timeLimitRemaining, changeRemaining, changeTimeLimit } = useModalCreateTaskBagOfHoursLogic()
    const customers = getCustomers()
    const fields: FormRender<TaskBagOfHoursModelTypeExtended> = [
        {
            cols: 1,
            elements: [
                {
                    name: 'CustomerId',
                    component: 'Custom',
                    render: ({ extraProps }) => <CustomSelectSectionCustomers onChange={getCustomerProjects} customers={extraProps.customers} label={t('customer', 'Cliente')} required={true} />,
                    extraProps: { customers }
                },
                {
                    name: 'CustomerProjectId',
                    label: t('project', 'Proyecto'),
                    component: 'CustomSelect',
                    required: true,
                    items: customerProjects || {},
                },
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true,
                },
                {
                    name: 'timeLimit',
                    label: t('timeLimit', 'Tiempo límite'),
                    component: 'InputHourMinSecUnlimitedStateLess',
                    required: true,
                    value: String(timeLimitRemaining.limit),
                    onFinalChange: changeTimeLimit,
                },
                {
                    name: 'remainingTime',
                    label: t('remainingTime', 'Tiempo restante'),
                    component: 'InputHourMinSecUnlimitedStateLess',
                    value: String(timeLimitRemaining.remaining),
                    onFinalChange: changeRemaining
                },
                {
                    name: 'expireDate',
                    label: t('expireDate', 'Fecha de vencimiento'),
                    component: 'InputCalendarStateFull',
                    onlyValids: true,
                },
                {
                    name: 'id',
                    component: 'InputHidden'
                }
            ]
        }
    ]

    return (
        <LittleModalCreateEdit fields={fields} url={'/api/app/task/bagOfHours/createTaskBagOfHours'} translations={{
            title: t('createTaskBagOfHours', 'Crear bolsa de horas'),
            button: t('create', 'Crear')
        }} haveButtonPermissions={true} {...rest} />
    )
}

const useModalCreateTaskBagOfHoursLogic = () => {
    const [customerProjects, setCustomerProjects] = useState<TwinDictionary | null>(null)
    const [timeLimitRemaining, setTimeLimitRemaining] = useState({limit: 0, remaining: 0})

    const changeRemaining = useCallback((value: number) => {
        setTimeLimitRemaining((old) => {
            const copyOld = JSON.parse(JSON.stringify(old))
            copyOld.remaining = value
            if (value > copyOld.limit) {
                copyOld.limit = value
            }
            return { ...copyOld }
        })
    }, [setTimeLimitRemaining])
    const changeTimeLimit = useCallback((value: number) => {
        setTimeLimitRemaining((old) => {
            const copyOld = JSON.parse(JSON.stringify(old))
            if (copyOld.remaining === copyOld.limit || value < copyOld.remaining) {
                copyOld.remaining = value
            }
            copyOld.limit = value
            return { ...copyOld }
        })
    }, [setTimeLimitRemaining])

    const getCustomerProjects = useCallback(async (CustomerId: number) => {
        const myData = await twinFetchPostJSON('/api/app/customer/customerProject/getAllCustomerProjects', { where: { CustomerId } })
        if (myData) {
            setCustomerProjects(dictionaryComplexFromJsonArr(myData))
        }
    }, [setCustomerProjects])


    return { customerProjects, getCustomerProjects, timeLimitRemaining, changeRemaining, changeTimeLimit }
}


export default ModalCreateTaskBagOfHours