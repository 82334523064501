import InputHour, { InputHourProps, RectangularInputHour } from '..'

const InputHourUnlimited: React.FC<InputHourProps> = ({ ...rest}) => {
    return (
        <InputHour {...rest} maxHours={Infinity} />
    )
}

export const RectangularInputHourUnlimited: React.FC<InputHourProps> = ({ ...rest }) => {
    return (
        <RectangularInputHour {...rest} maxHours={Infinity} />
    )
}

export default InputHourUnlimited