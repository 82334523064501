import { Routes } from '../../../CreateRoutering/types'
import { SignOut } from '../IconMenuBar/UserIcons'
import { LeftMenuPrintItemsConnect } from '../LeftMenuComponents'

interface IconAdminMenuBarProps {
    routes: Routes
}

const IconAdminMenuBar: React.FC<IconAdminMenuBarProps> = ({ routes }) => {
    return (
        <div className='w-70 flex flex-col items-center bg-gray-33 text-white py-15'>
            <img className='menu_bar_logo' src='/logo-white.png' alt='' />
            <LeftMenuPrintItemsConnect routes={routes} />
            <UserAdminIcons />
        </div>
    )
}

interface UserAdminIconsProps { }

const UserAdminIcons: React.FC<UserAdminIconsProps> = () => {
    return (
        <div className='flex flex-col justify-end items-center mt-auto user_alerts w-full gap-y-24'>
            <SignOut />
        </div>
    )
}


export default IconAdminMenuBar