import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import {Provider} from 'react-redux';
import store, { GlobalContext } from './utils/reducers';
import {appStore} from './utils/reducers';
const container = document.getElementById('root')
const root = ReactDOM.createRoot(container!);
root.render(
  <React.StrictMode>
    <Provider store={appStore} context={GlobalContext}>
      <Provider store={store}>
        <App />
      </Provider>
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
