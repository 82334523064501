import { Fragment, useCallback } from 'react'
import { ButtonOrLoader } from '../../../../../baseComponents/Button'
import TwinTrans from '../../../../../baseComponents/TwinTrans'
import TwinForm from '../../../../../forms/TwinForm'
import { InputWithLabel } from '../../../../../forms/Input'
import useIsLoading from '../../../../../utils/hooks/useIsLoading'
import useTwinTranslation from '../../../../../utils/hooks/useTwinTranslation'
import { MyLink } from '../../Login/Components'

interface ForgotPasswordFormProps {
    handleChangeShowMessage: (active: true | null) => void
}

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({ handleChangeShowMessage }) => {
    const { t } = useTwinTranslation()
    const { startLoading, endLoading, loading } = useIsLoading()
    const { handleOnSubmit } = useForgotPasswordFormLogic({ handleChangeShowMessage })

    return (
        <Fragment>
            <div className='my-50 text-center'>
                <h1 className='mb-40'>
                    <TwinTrans transKey='recoverYourPassword'>
                        Recupera tu contraseña
                    </TwinTrans>
                </h1>
                <span className='regular14'>
                    <TwinTrans transKey='introduceEmailToRecoverPass'>
                        Introduce tu email para recuperar contraseña
                    </TwinTrans>
                </span>
            </div>
            <TwinForm method='POST' action='/api/recoverPassword' onSubmit={(res) => { handleOnSubmit(res); endLoading() }} beforeSubmitHandler={startLoading} onError={endLoading} >
                <InputWithLabel name='email' label={t('email', 'Email')} type='email' />
                <ButtonOrLoader className='recover_pass_button' textButton={t('recoverPassword', 'Recuperar contraseña')} buttonIsDisabled={loading}/>
            </TwinForm>
            <div className='mt-40 text-center'>
                <MyLink text={t('returnToLoginPage', 'Volver a la página de Inicio sesión')} link={'/'} />
            </div>
        </Fragment>
    )
}

interface ForgotPasswordFormLogicProps {
    handleChangeShowMessage: (active: true | null) => void
}
const useForgotPasswordFormLogic = ({  handleChangeShowMessage }: ForgotPasswordFormLogicProps) => {
    const handleOnSubmit = useCallback(async (res: Response) => {
        if (res.status === 200) {
            return handleChangeShowMessage(true)
        }
        return handleChangeShowMessage(null)
    }, [handleChangeShowMessage])

    return { handleOnSubmit }
}

export default ForgotPasswordForm