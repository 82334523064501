import React, { useCallback, useEffect, useState } from 'react'
import { twinFetchPostJSON, twinFetchPostText, uploadFileToBack } from '../../utils/globals/data'
import useTwinTranslation from '../../utils/hooks/useTwinTranslation'
import { ComponentWithPermissions } from '../CreateRoutering/types'
import LoadingSpinner from '../LoaderDecider/LoadingSpinner'
import { SmallTitleAndRightLayout } from '../Layout/TitleAndRightLayout'
import { appendFileToPath, appendFolderToPath, clearLastBar, deleteFileFromFolder, deleteFirstOrMakeItNull, deleteFolderInPath, getContentInsideFolder, getLastElementOfPath, modifyKeyToFile, organizeByFolders, renameFolderInPath } from './functions'
import { TFSHeader, TFSRightHeader } from './Headers'
import { TFSConfig, TFSVarsForPetition, TwinFileFolder, TwinFileFolderParsed } from './types'
import './twinfilesystem.sass'
import TFSBreadcrumb from './TFSBreadcrumb'
import TFSInside from './TFSInside'
import { prepareForSearch } from '../../utils/globals/search'
import PermissionChecker from '../PermissionChecker'
import ModalDeleteFilled from '../ModalsLayouts/ModalDeleteFilled'

type TwinFileSystemProps = TwinFileSystemLogicProps & ComponentWithPermissions & {
}

const TwinFileSystem: React.FC<TwinFileSystemProps> = ({ userPermissions, ...rest }) => {
  const { t } = useTwinTranslation()
  const { files, configParams, changePath, setFiles } = useTwinFileSystemLogic(rest)
  const {loading, addFileToPath, deleteFile, downloadFile, confirmReplace, cancelReplace, alreadyCreated} = useTFSFileLogic({path: configParams.config.path, setFiles, currentFiles: files, ...rest})
  const {addFolderToPath, editCurrentFolder, deleteCurrentFolder} = useTFSFolderLogic({path: configParams.config.path, setFiles, changePath, ...rest})
  if (files === null) {
    return <LoadingSpinner />
  }
  const nameFile = alreadyCreated?.[0].name
  return (
    <SmallTitleAndRightLayout title={t('files', 'Ficheros')} RightHeader={
      <PermissionChecker userPermissions={userPermissions} permission='create'><TFSRightHeader addFileToPath={addFileToPath} addFolderToPath={addFolderToPath} /></PermissionChecker>
    }>
      <div className='tfsheader'>
        <TFSHeader {...configParams} />
        <TFSBreadcrumb path={configParams.config.path} changePath={changePath} deleteCurrentFolder={deleteCurrentFolder} userPermissions={userPermissions} editCurrentFolder={editCurrentFolder}></TFSBreadcrumb>
      </div>
      <TFSInside changePath={changePath} content={files} loadingFiles={loading} deleteFile={deleteFile} downloadFile={downloadFile} userPermissions={userPermissions} />
      {((alreadyCreated?.length || 0) > 0) && <ModalDeleteFilled setOpened={cancelReplace} opened={true} onAccept={confirmReplace} translations={{
        title: t('theFile', 'El archivo') + ' ' + nameFile + ' ' + t('exists', 'ya existe'),
        subtitle: t('fileExistsTextForReplace', 'Si pulsas sobre eliminar el fichero actual que acabas de subir remplazará al que ya existía y el anterior se eliminará.')
      }}>
      </ModalDeleteFilled>}
    </SmallTitleAndRightLayout>
  )
}

const baseConfig: TFSConfig = {
  searchbar: '',
  orderBy: '',
  path: '/'
}
interface TwinFileSystemLogicProps extends TFSVarsForPetition {}

const useTwinFileSystemLogic = ({ type, subFolder }: TwinFileSystemLogicProps) => {
  const [files, setFiles] = useState<TwinFileFolder | null>(null)
  const [config, setConfig] = useState({ ...baseConfig })
  const currentFiles = getContentInsideFolder(files, config)
  const getFiles = useCallback(async () => {
    const data = await twinFetchPostJSON('/api/app/fileSystem/getFilesByFolder', {
      type,
      subFolder
    })
    setFiles(organizeByFolders(data, type + subFolder))
  }, [type, subFolder])

  const changePath = useCallback((path: string) => {
    setConfig({
      ...baseConfig,
      path
    })
  }, [setConfig])
  

  useEffect(() => {
    getFiles()
  }, [getFiles])

  return { files: currentFiles, configParams: { config, setConfig }, changePath, setFiles}
}

interface TFSFileLogicProps extends TFSVarsForPetition {
  path: string
  setFiles: React.Dispatch<React.SetStateAction<TwinFileFolder | null>>
  currentFiles: TwinFileFolderParsed
}

const useTFSFileLogic = ({type, subFolder, path, setFiles, currentFiles}: TFSFileLogicProps) => {
  const [loading, setLoading] = useState<string[]>([])
  const [alreadyCreated, setAlreadyCreated] = useState<null | File[]>(null)

  const currentFullPath = clearLastBar(type + subFolder + path)

  const clearLoading = useCallback((fileLoaded: string) => {
    setLoading((oldLoading) => {
      const loaded = JSON.parse(JSON.stringify(oldLoading))
      const indexOf = loaded.indexOf(fileLoaded)
      loaded.splice(indexOf, 1)
      return loaded
    })
  }, [setLoading])

  const submitFile = useCallback(async (fileUploaded: File, isReplace = false) => {
    const name = fileUploaded.name
    if (!isReplace) {
      setFiles((oldFiles) => appendFileToPath(oldFiles, path, name))
    }
    setLoading((oldLoading) => [...oldLoading, name])
    const result = await uploadFileToBack(fileUploaded, name, [currentFullPath], undefined, true)
    if (result !== false) {
      setFiles((oldFiles) => modifyKeyToFile(oldFiles, path, name, result) )
    } else if(!isReplace) {
      setFiles((oldFiles) => deleteFileFromFolder(oldFiles, path, name) )
    }
    clearLoading(name)
  }, [currentFullPath, setLoading, clearLoading, path, setFiles])

  const addFilesToPath = useCallback((files: File[]) => {
    const confirmFiles = []
    for (const file of files) {
      if (currentFiles[prepareForSearch(file.name)]) {
        confirmFiles.push(file)
      } else {
        submitFile(file)
      }
    }
    setAlreadyCreated(confirmFiles)
  }, [currentFiles, submitFile])

  const addFileToPath = useCallback((file: File) => {
    if (currentFiles[prepareForSearch(file.name)]) {
      setAlreadyCreated([file])
    } else {
      submitFile(file)
    }
  }, [currentFiles, submitFile])

  const deleteFile = useCallback(async (filepath: string) => {
    const deleted = await twinFetchPostText('/api/app/fileSystem/deleteFile', {
      type,
      subFolder,
      filepath
    })
    if (deleted && deleted === 'true') {
      const last = getLastElementOfPath(filepath)
      setFiles((oldFiles) => deleteFileFromFolder(oldFiles, path, last.last || '') )
    }
  }, [path, type, subFolder, setFiles])

  const downloadFile = useCallback(async (filepath: string, e: React.MouseEvent) => {
    const current = e.currentTarget
    const link = await twinFetchPostText('/api/app/fileSystem/getLinkOfFile', {
      type,
      subFolder,
      filepath
    })
    if (link && link !== 'false') {
      const a = current.querySelector('a')
      if (a) {
        a.href = link
        a.click()
      }
    }
  }, [type, subFolder])

    const confirmReplace = useCallback(() => {
    if (alreadyCreated) {
      const fileForSubmit = alreadyCreated[0]
      setAlreadyCreated(deleteFirstOrMakeItNull)
      submitFile(fileForSubmit, true)
    }
  }, [alreadyCreated, submitFile])

  const cancelReplace = useCallback(() => {
    if (alreadyCreated) {
      setAlreadyCreated(deleteFirstOrMakeItNull)
    }
  }, [alreadyCreated])

  return {addFileToPath, addFilesToPath, deleteFile, downloadFile, confirmReplace, cancelReplace, loading, alreadyCreated, setAlreadyCreated}
}

interface TFSFolderLogicProps extends TFSVarsForPetition {
  path: string
  setFiles: React.Dispatch<React.SetStateAction<TwinFileFolder | null>>
  changePath: (path: string) => void
}

const useTFSFolderLogic = ({type, subFolder, path, setFiles, changePath}: TFSFolderLogicProps) => {
  const addFolderToPath = useCallback(async (folderName: string) => {
    let isReplaceParent = false
    setFiles((oldFiles) => {
      const { myfiles, isReplace } = appendFolderToPath(oldFiles, path, folderName)
      isReplaceParent = isReplace
      return myfiles
    })
    if (!isReplaceParent) {
      const createdFolder = await twinFetchPostText('/api/app/fileSystem/createFolder', {
          type,
          subFolder,
          folderName: path.endsWith('/') ? path + folderName : path + '/' + folderName
      })
      if (!createdFolder || createdFolder === 'false') {
        setFiles((oldFiles) => deleteFolderInPath(oldFiles, path, folderName) )
      }
    }
  }, [path, type, subFolder, setFiles])

  const deleteCurrentFolder = useCallback(async () => {
    const last = getLastElementOfPath(path)
    const folderName = last.last || '/'
    const deletedFolder = await twinFetchPostText('/api/app/fileSystem/deleteFolder', {
      type,
      subFolder,
      folderName: path
    })
    if (deletedFolder && deletedFolder === 'true') {
      if (folderName === '/') {
        setFiles(() => {
          return { '/': { folders: {}, files: []} }
        })
      } else {
        setFiles((oldFiles) => deleteFolderInPath(oldFiles, path, folderName, 1))
        changePath(last.otherPath)
      }
    }
  }, [path, type, subFolder, changePath, setFiles])

  const editCurrentFolder = useCallback(async (newFolderName: string) => {
    const last = getLastElementOfPath(path)
    const folderName = last.last || '/'
    const newFolderPath = last.otherPath + '/' + newFolderName
    const editedFolder = await twinFetchPostText('/api/app/fileSystem/editFolder', {
      type,
      subFolder,
      oldFolderName: path,
      newFolderPath
    })
    if (editedFolder && editedFolder === 'true' && folderName !== '/') {
        setFiles((oldFiles) => renameFolderInPath(oldFiles, path, folderName, newFolderName, path, newFolderPath))
        changePath(newFolderPath)
    }
  }, [path, type, subFolder, changePath, setFiles])

  return {addFolderToPath, deleteCurrentFolder, editCurrentFolder}
}


export default TwinFileSystem