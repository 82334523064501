import { useCallback } from 'react'
import { defaultRowRenderer, TableRowRenderer } from 'react-virtualized/dist/es/Table'

const bgClassName = 'bg-green-DB'
export const addDoubleTableGreenMatch = (className: string, match: boolean) => {
    if (!match) {
        return className
    }
    return className + ' ' + bgClassName
}
export const useAddDoubleTableGreenRowRender = (matchParam: string) => {
    const rowRenderer: TableRowRenderer = useCallback((props) => {
        return defaultRowRenderer({ ...props, className: addDoubleTableGreenMatch(props.className, String(props.rowData.id) === matchParam)})
    }, [matchParam])
    return {rowRenderer}
}