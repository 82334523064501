import { ModalCreateEditMedium } from '../../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit';
import { ModalEditComponentProps } from '../../../../../../../../baseComponents/ModalsLayouts/types';
import { valueOrDefaultValue } from '../../../../../../../../utils/globals/valueOrDefaultValue';
import useTwinTranslation from '../../../../../../../../utils/hooks/useTwinTranslation';
import { FormRender } from '../../../../../../../../forms/FormRenderer/types';
import { VerticalSortListDelete } from '../../../../../../../../baseComponents/Sorts/VerticalSortList';
import { RowData } from '../../../../../../../../baseComponents/TwinTable/types';
import { VerticalSortItem } from '../../../../../../../../baseComponents/Sorts/VerticalSortList/types';
import { useCallback, useMemo, useState } from 'react';
import { TaskHelperModelAdd, TaskHelperModelType } from '@teinor/erp/types/company/task/taskHelper';
import { TwinDictionary } from '../../../../../../../../utils/globals/dictionary';
import { getUserInfo } from '../../../../../../../../utils/reducers/getters';
import { changeErrorMessage } from '../../../../../../../../utils/reducers/reduxDispatch';

interface ModalCETaskHelperCreatorProps extends ModalEditComponentProps { }

export const ModalCETaskHelperCreator: React.FC<ModalCETaskHelperCreatorProps> = ({ setOpened, onSubmit, userPermissions, allRowData }) => {
    const { onChangeTaskHelperField, filteredItems, items, setItems, jsonTaskFields, beforeSubmitTaskHelper } = useModalCETaskHelperCreatorLogic({allRowData})
    const { t } = useTwinTranslation()
    const user = getUserInfo()
    const fields: FormRender<TaskHelperModelType> = [
        {
            cols: 1,
            elements: [
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'fields',
                    component: 'CustomSelectWithNotInFilteredItems',
                    items: jsonTaskFields,
                    label: t('field', 'Campo'),
                    excludeItems: filteredItems,
                    onChange: onChangeTaskHelperField
                },
                {
                    name: 'EmployeeId',
                    component: 'InputHidden',
                    value: user?.Employee?.id
                },
                {
                    name: 'id',
                    component: 'InputHidden'
                },
            ]
        },
    ]
    if (allRowData && userPermissions) {
        const parsedFields = valueOrDefaultValue(fields, userPermissions, allRowData)
        return (
            <ModalCreateEditMedium setOpened={setOpened} onSubmit={onSubmit} fields={parsedFields} translations={{
                title: t('editTaskHelper', 'Editar ayudante de tareas'),
                button: t('save', 'Guardar')
            }} haveButtonPermissions={true} url={'/api/app/task/helper/updateTaskHelper'} beforeSubmit={beforeSubmitTaskHelper}>
                <VerticalSortListDelete items={items} setItems={setItems} />
            </ModalCreateEditMedium>
        )
    }
    return (
        <ModalCreateEditMedium setOpened={setOpened} onSubmit={onSubmit} fields={fields} translations={{
            title: t('createTaskHelper', 'Crear ayudante de tareas'),
            button: t('create', 'Crear')
        }} haveButtonPermissions={true} url={'/api/app/task/helper/createTaskHelper'} beforeSubmit={beforeSubmitTaskHelper}>
            <VerticalSortListDelete items={items} setItems={setItems} />
        </ModalCreateEditMedium>
    )
}

interface ModalCETaskHelperCreatorLogicProps {
    allRowData?: RowData
}

const useModalCETaskHelperCreatorLogic = ({ allRowData }: ModalCETaskHelperCreatorLogicProps) => {
    const { t } = useTwinTranslation()
    const errorMessage = t('notSelectedFields', 'Campos no seleccionados')
    const jsonTaskFields: TwinDictionary = useMemo(() => {
        return {
            name: { name: t('title','Título'), id: 'name' },
            description: { name: t('description', 'descripción'), id: 'description' },
            start_date: { name: t('dateStart', 'Fecha inicio'), id: 'start_date' },
            end_date: { name: t('dateEnd', 'Fecha fin'), id: 'end_date' },
            TaskTypeId: { name: t('taskType', 'Tipo de tarea'), id: 'TaskTypeId' },
            GroupId: { name: t('group', 'Grupo'), id: 'GroupId' },
            EmployeeId: { name: t('employee', 'Empleado'), id: 'EmployeeId' },
            CustomerProjectId: { name: t('project', 'Proyecto'), id: 'CustomerProjectId'},
            CustomerContactPersonId: { name: t('contactPerson', 'Persona de contacto'), id: 'CustomerContactPersonId'},
            CustomerShopId: { name: t('workingCenter', 'Centro de trabajo'), id: 'CustomerShopId'},
        }
    }, [t])
    const [items, setItems] = useState<VerticalSortItem[]>(() => {
        const arr: VerticalSortItem[] = []
        let counter = 1
        if (allRowData?.fields) {
            for (const element of allRowData?.fields) {
                arr.push({
                    id: jsonTaskFields?.[element]?.id,
                    order: counter,
                    name: jsonTaskFields?.[element]?.name
                })
                counter++
           }
            return arr
        }
        return []
    })

    const beforeSubmitTaskHelper = useCallback((values: TaskHelperModelAdd) => {
        const arr = []
        for (const element of items) {
            arr.push(element.id)
        }
        values.fields = arr
        if (values.fields.length === 0) {
            changeErrorMessage(errorMessage)
            return false
        }
        return true
    }, [items, errorMessage])

    const filteredItems = useMemo(() => {
        const arr = []
        for (const element of items) {
            arr.push(element.id)
        }
        return arr
    }, [items])

    const onChangeTaskHelperField = useCallback((value: string) => {
        setItems((oldItems) => {
            const copy = JSON.parse(JSON.stringify(oldItems))
            copy.push({
                id: jsonTaskFields[value].id,
                order: copy.length + 1,
                name: jsonTaskFields[value].name
            })
            return copy
        })
    }, [setItems, jsonTaskFields])

    return { onChangeTaskHelperField, items, setItems, filteredItems, jsonTaskFields, beforeSubmitTaskHelper }
}

export default ModalCETaskHelperCreator