import { faLock, faUserLarge } from '@fortawesome/pro-light-svg-icons'
import { useCallback, useState } from 'react'
import { ButtonPrimary } from '../../../../../baseComponents/Button'
import CustomError from '../../../../../baseComponents/CustomError'
import { ModalLittleUnclosable } from '../../../../../baseComponents/Modal'
import TwinForm from '../../../../../forms/TwinForm'
import { OnSubmit } from '../../../../../forms/TwinForm/types'
import FormRenderer from '../../../../../forms/FormRenderer'
import { FormRender } from '../../../../../forms/FormRenderer/types'
import { ApiError } from '../../../../../utils/globals/data'
import useTwinTranslation from '../../../../../utils/hooks/useTwinTranslation'
import { ErpLoaderLoaded } from '../types'
import  jscookie from 'js-cookie'
import { setUserByLogin, signOutUser } from '../../../../../utils/reducers/reduxDispatch'
import TwinTrans from '../../../../../baseComponents/TwinTrans'
import { appStore } from '../../../../../utils/reducers'
import { Link } from 'react-router-dom'
import { EmployeeModelType } from '@teinor/erp/types/company/userInner/employee'

interface ErpLoaderEmployeeModalProps extends ErpLoaderEmployeeModalLogicProps {
    loaded: ErpLoaderLoaded
}

const ErpLoaderEmployeeModal: React.FC<ErpLoaderEmployeeModalProps> = ({setLoaded, loaded}) => {
    const { onSubmit, errors } = useErpLoaderEmployeeModalLogic({ setLoaded })
    const {t} = useTwinTranslation()
    const sections: FormRender<EmployeeModelType>= [
        {
            cols: 1,
            className: 'mt-20',
            elements: [
                {
                    name: 'code',
                    placeholder: t('code', 'Código'),
                    component: 'InputWithLeftIcon',
                    required: true,
                    icon: faUserLarge
                },
                {
                    name: 'password',
                    placeholder: t('password', 'Contraseña'),
                    component: 'InputWithLeftIcon',
                    type: 'password',
                    icon: faLock
                }
            ]
        }
    ]
    return (
        <ModalLittleUnclosable opened={loaded === 'employeeLeft' ? true : null}>
            <TwinForm action='/api/app/employee/setEmployeeToToken' onSubmit={onSubmit}>
                <div className='text-center'>
                    <h2><TwinTrans transKey='welcomeBack'>Bienvenido de nuevo</TwinTrans>, {appStore.getState().user?.extraData.name} </h2>
                    <span className='mt-20 block'><TwinTrans transKey='needIdEmployeeForContinue'>Necesitamos tu id de empleado para continuar</TwinTrans></span>
                </div>
                <CustomError textError={errors && errors.msg} className='my-20'  />
                <FormRenderer sections={sections} />
                <ButtonPrimary className='w-full mt-30' type='submit'>Log in</ButtonPrimary>
            </TwinForm>
            <Link to='/' onClick={signOutUser} className='mt-30 block text-center text-green-21'><TwinTrans transKey='notYou'>¿No eres tu?</TwinTrans></Link>
        </ModalLittleUnclosable>
    )
}

interface ErpLoaderEmployeeModalLogicProps {
    setLoaded: React.Dispatch<React.SetStateAction<ErpLoaderLoaded>>
}

const useErpLoaderEmployeeModalLogic = ({ setLoaded }: ErpLoaderEmployeeModalLogicProps) => {
    const [errors, setErrors] = useState<false | ApiError>(false)
    const onSubmit: OnSubmit = useCallback(async (res) => {
        if (res.status === 200) {
            const token = await res.text()
            const cookie = JSON.parse(jscookie.get('twin_jwt_user') || '')
            const remember = cookie.remember ?? false
            await setUserByLogin({ token, type: 'erp' } , remember)
            setLoaded(true)
        } else {
            const errors = await res.json()
            setErrors(errors.errors[0])
        }
    }, [setLoaded])
    return {onSubmit, errors}
}

export default ErpLoaderEmployeeModal