import { EmployeeModelType } from '@teinor/erp/types/company/userInner/employee'
import TwinFileSystem from '../../../../../../../baseComponents/TwinFileSystem'

interface MyEmployeeFilesProps{
    basicData: EmployeeModelType | null
}

const MyEmployeeFiles: React.FC<MyEmployeeFilesProps> = ({ basicData }) => {
    if (basicData?.id) {
        return (<TwinFileSystem subFolder={'/' + basicData.id + '/employeeFolder'} type='employee' userPermissions={{ n: 1, permission: 'read' }} />)
    }
    return null
}

export default MyEmployeeFiles