import { dictionaryComplexFromJsonArr } from '../../globals/dictionary'
import { ShippingCompanyModelType } from '@teinor/erp/types/company/config/shipping/shippingCompany'

export interface ShippingCompaniesAction {
    type: 'CHANGE_SHIPPINGCOMPANY'
    payload: ShippingCompanyModelType[]
}
export type DictionaryShippingCompany = { [id: number]: ShippingCompanyModelType }

export type ShippingCompanyPayload = { [id: number]: ShippingCompanyModelType } | null

export default function shippingCompanies(state: ShippingCompanyPayload = null, action: ShippingCompaniesAction): DictionaryShippingCompany | null  {
    switch (action.type) {
        case 'CHANGE_SHIPPINGCOMPANY':
            const shippingCompanies = dictionaryComplexFromJsonArr(action.payload || [])
            return state = shippingCompanies
        default:
            return state
    }
}