import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons'
import { useCallback, useEffect, useState } from 'react'
import { twinFetchPostJSON } from '../../../../utils/globals/data'
import { TwinDictionary } from '../../../../utils/globals/dictionary'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import TwinBigCalendar from '../../../TwinBigCalendar'
import { TwinBigCalendarEvents } from '../../../TwinBigCalendar/types'
import TwinIcon from '../../../TwinIcon'
import TwinTrans from '../../../TwinTrans'
import { timeControlParseAllEmployeeHolidaysToEvents, timeControlParseAllFreeDaysToEvents } from './functions'

interface HolidayCalendarProps { }

const HolidayCalendar: React.FC<HolidayCalendarProps> = () => {
    const { data, handleOnNavigate, getData, setYear, year } = useHolidayCalendarLogic()

    return (
        <div className='flex flex-auto flex-col tce_employee_holidays_calendar'>
            <div className='flex items-center mb-15'>
                <h3 className='mr-auto'><TwinTrans transKey='holidayAndFreeDays'>Días festivos y vacaciones</TwinTrans></h3>
                <CustomToolBar getData={getData} setYear={setYear} year={year} />
            </div>
            <TwinBigCalendar date={new Date(`${year}-01-01`)} events={data || []} defaultView='year' onNavigate={handleOnNavigate} className='tce_employee_holidays_calendar_wrapper' />
        </div>
    )
}

const useHolidayCalendarLogic = () => {
    const { t } = useTwinTranslation()
    const pendingText = t('pending', 'Pendiente')
    const holidaysText = t('holidays', 'Vacaciones')
    const [data, setData] = useState<TwinBigCalendarEvents | null>(null)
    const [year, setYear] = useState<number>(new Date().getFullYear())

    const parseResultToData = useCallback((result: TwinDictionary) => {
        const freeDaysEvents = timeControlParseAllFreeDaysToEvents(result.freeDays)
        const holidaysEvents = timeControlParseAllEmployeeHolidaysToEvents(result.holidays, pendingText, holidaysText)
        const data: TwinBigCalendarEvents = [...freeDaysEvents, ...holidaysEvents]
        return data
    }, [pendingText, holidaysText])

    const getData = useCallback(async () => {
        const dateStart = new Date(year, 0, 1).toDateString()
        const dateEnd = new Date(year, 11, 31).toDateString()
        setData(null)
        const result = await twinFetchPostJSON('/api/app/workingCalendar/workingCalendarHolidayBag/getFullFreeDaysCalendarWithHolidays', { dateStart, dateEnd })
        const parsedData = parseResultToData(result)
        setData(parsedData)
    }, [setData, parseResultToData, year])

    const handleOnNavigate = useCallback((date: Date, view: string) => {
        if (view === 'year') {
            setYear(date.getFullYear())
        }
    }, [setYear])

    useEffect(() => {
        getData()
    }, [getData])

    return { data, handleOnNavigate, getData, year, setYear }
}

interface CustomToolBarProps extends CustomToolBarLogicProps {
    year: number
    getData: () => void
}

const CustomToolBar: React.FC<CustomToolBarProps> = ({ year, getData, ...rest }) => {
    const { handleNextYear, handlePrevYear } = useCustomToolBarLogic({ ...rest })
    return (
        <div className='flex items-center custom_calendar_toolbar'>
            <div className='mr-24'>
                <TwinIcon className='cursor-pointer p-3 h-24  hover:text-green-21' icon={faChevronLeft} onClick={handlePrevYear} />
            </div>
            <div className='mr-24'>
                <TwinIcon className='cursor-pointer p-3 h-24 hover:text-green-21' icon={faChevronRight} onClick={handleNextYear} />
            </div>
            <div className='toolbar-label text-left text-gray-51 capitalize'>
                {year}
            </div>
        </div>
    )
}

interface CustomToolBarLogicProps {
    setYear: React.Dispatch<React.SetStateAction<number>>
}

const useCustomToolBarLogic = ({ setYear }: CustomToolBarLogicProps) => {

    const handlePrevYear = useCallback(() => {
        setYear((old) => old - 1)
    }, [setYear])
    const handleNextYear = useCallback(() => {
        setYear((old) => old + 1)
    }, [setYear])

    return { handleNextYear, handlePrevYear }
}

export default HolidayCalendar
