import { WarehouseModelType } from '@teinor/erp/types/company/warehouse'

export interface WarehousesAction {
    type: 'CHANGE_WAREHOUSE'
    payload: WarehouseModelType[]
}
export type WarehousePayload = WarehouseModelType[] | null

export default function warehouse(state: WarehousePayload = null, action: WarehousesAction): WarehouseModelType[] | null {
    switch (action.type) {
        case 'CHANGE_WAREHOUSE':
            return state = action.payload
        default:
            return state
    }
}