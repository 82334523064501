import { faCheck, faPaperclipVertical } from '@fortawesome/pro-light-svg-icons'
import { EmployeeAbsenceModelType } from '@teinor/erp/types/company/userInner/employee/employeeAbsence'
import { useCallback, useEffect, useState } from 'react'
import { DisplayStatusWithoutIcon } from '../../../../../../../../../baseComponents/Displays/DisplayStatus'
import { ModalEditComponentProps } from '../../../../../../../../../baseComponents/ModalsLayouts/types'
import TwinIcon from '../../../../../../../../../baseComponents/TwinIcon'
import { VirtualTableListingStateLess } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing'
import { CustomRenderDate, CustomRenderString, DeleteRow } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import TwinTrans from '../../../../../../../../../baseComponents/TwinTrans'
import { FilesInputSendOnUpload } from '../../../../../../../../../forms/FilesInput'
import { twinFetchPostJSON } from '../../../../../../../../../utils/globals/data'
import useEditDeleteModal from '../../../../../../../../../utils/hooks/useEditDeleteModal'
import useStatusRequestTypes from '../../../../../../../../../utils/hooks/useStatusRequestTypes'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import ModalCancelRequestEmployeeAbsence from './ModalCancelRequestEmployeeAbsence'
import { getEmployeeAbsenceTypes } from '../../../../../../../../../utils/reducers/getters'

interface EmployeeAbsenceListingTabProps extends ModalEditComponentProps {
}

const EmployeeAbsenceListingTab: React.FC<EmployeeAbsenceListingTabProps> = () => {
    const { t } = useTwinTranslation()
    const employeeAbsenceTypes = getEmployeeAbsenceTypes()
    const { absenceData, getAbsenceData, openModal, setOpenModal} = useEmployeeAbsenceListingTabLogic()
    const columns: ColumnTableSchema<EmployeeAbsenceModelType> = [
        {
            id: 'dateStart',
            dataKey: 'dateStart',
            label: t('from', 'Desde'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />,
        },
        {
            id: 'dateEnd',
            dataKey: 'dateEnd',
            label: t('to', 'Hasta'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />,
        },
        {
            id: 'approved',
            dataKey: 'approved',
            label: t('status', 'Estado'),
            customRender: (parameterValue) => <RenderUsedDayStatus value={parameterValue} />,
            minWidth: 150
        },
        {
            id: 'EmployeeAbsenceTypeId',
            dataKey: 'EmployeeAbsenceTypeId',
            label: t('type', 'Tipo'),
            customRender: (parameterValue) => <CustomRenderString value={employeeAbsenceTypes?.[parameterValue as any]?.name || ''} />,
            minWidth: 150
        },
        {
            id: 'asd',
            dataKey: 'proof_file_url',
            label: '',
            customRender: (parameterValue, allRowData) => {
                if (allRowData.approved === 2 || allRowData.approved === 3) {
                    return <div></div>
                }
                if (allRowData.proof_file_url) {
                    return <TwinIcon icon={faCheck} className='text-green-43' title={t('proofUploaded', 'Justificante subido')}  />
                }
                return (<FilesInputSendOnUpload extraUpdateFields={{ id: allRowData.id }} icon={faPaperclipVertical} name={'proof_file_url'} url={'/api/app/employee/employeeAbsence/attachProofUrlFile'} path={['employeeAbsence', allRowData.id]} helperText={t('attachProofFile', 'Adjuntar justificante')} onChange={getAbsenceData}/>)
            },
            width: 50
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            customRender: (parameterValue, allRowData) => {
                if ((allRowData.approved === 0 || allRowData.approved === 1)) {
                    return (<DeleteRow key={'delete' + parameterValue} {...{ parameterValue, allRowData }} onClick={(_idk, allRowData) => setOpenModal({ type: 'edit', allRowData })} />)
                }
                return (<div></div>)
            },
            width: 50
        }
    ]
    return (
        <div className='mb-20 mt-25 flex flex-col flex-auto'>
            <div className='justify-between items-center mb-20'>
                <h2>
                    <TwinTrans transKey='listOfAbsences'>Listado de ausencias</TwinTrans>
                </h2>
            </div>
            <div className='flex-auto h-1 overflow-auto'>
                <VirtualTableListingStateLess tableData={absenceData} rowHeight={48} headerHeight={48} name='HolidayRequestDetail' columns={columns} setSelectedColumnsAndFilters={() => { }} />
            </div>
            {openModal?.type === 'edit' && <ModalCancelRequestEmployeeAbsence {...{ setOpened: () => setOpenModal(null) }} allRowData={openModal.allRowData} onSubmit={getAbsenceData} />}
        </div>
)
}

const useEmployeeAbsenceListingTabLogic = () => {
    const [absenceData, setAbsenceData] = useState<EmployeeAbsenceModelType[] | null>(null)
    const { openModal, setOpenModal } = useEditDeleteModal()

    const getAbsenceData = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/employee/employeeAbsence/getAllEmployeeAbsencesByEmployee', {})
        if (result) {
            setAbsenceData(result)
        }
    }, [setAbsenceData])

    useEffect(() => {
        getAbsenceData()
    }, [getAbsenceData])

    return { absenceData, getAbsenceData, openModal, setOpenModal }
}


interface RenderBuyOrderStatusProps {
    value: string
}

const RenderUsedDayStatus: React.FC<RenderBuyOrderStatusProps> = ({ value }) => {
    const status = useStatusRequestTypes()
    const parsedValue = parseInt(value)
    return <DisplayStatusWithoutIcon colorText={status[parsedValue].colorText} text={status[parsedValue].name} />
}

export default EmployeeAbsenceListingTab