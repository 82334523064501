import { getStates, getCountries } from 'countries-and-states'
import { Country } from '../reducers/globals/countries'
import { TwinDictionary } from './dictionary'
import { getLang } from './index'
import { getDictionaryCountries } from '../reducers/getters'

const myLang = getLang()

export const twinCountries = async (): Promise<{ [id: string]: string }[]> => {
    return await getCountries(myLang !== 'es' ? 'en' : 'es')
}
export const twinStates = async (isoCode: string): Promise<{ [id: string]: string | number }[]> => {
    if (isoCode) {
        return await getStates(isoCode, myLang !== 'es' ? 'en' : 'es')
    }
    return []
}

export const getDictionaryStates = (country: string | null) => {
    if (country) {
        const countries = getDictionaryCountries()
        const parsedCountries = dictionaryComplexStatesFromCountryJson(countries, country)
        return parsedCountries
    }
    return {}
}

export const dictionaryStatesFromCountryJson = (items: { [key: string]: { [key: string]: any } }, country: string, keyText: string = 'name') => {
    let parsedJSON: TwinDictionary = {}
    for (const key in items[country].states) {
        const item = items[country].states[key]
        parsedJSON[item.id] = item[keyText]
    }
    return parsedJSON
}
export const dictionaryComplexStatesFromCountryJson = (items: { [key: string]: { [key: string]: any } }, country: string) => {
    let parsedJSON: TwinDictionary = {}
    for (const key in items[country].states) {
        const item = items[country].states[key]
        parsedJSON[item.id] = item
    }
    return parsedJSON
}

export const getStateByCountryAndId = (country: Country, stateId: number) => {
    for (const item of country.states) {
        if (item.id === stateId) {
            return item
        }
    }
    return false
}

export const getCountryNameById = (countryId: string) => {
    const countries = getDictionaryCountries()
    return countries[countryId]
}