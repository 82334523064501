import { roundNumberByParams } from '@teinor/erp/prices'
import { displayFormatedNumber } from '../../../utils/globals/numbers'
import useTwinTranslation from '../../../utils/hooks/useTwinTranslation'
import { getCurrencySymbol } from '../../../utils/globals/price'
import { DisplayPriceTy } from './types'

interface DisplayPriceProps extends DisplayPriceLogicProps {
    className?: string
}

const DisplayPrice: React.FC<DisplayPriceProps> = ({ className, ...params }) => {
    const { priceParsed, CurrencySymbol, vatIncluded } = useDisplayPriceLogic(params)
    return (
        <div className={className}>{priceParsed} {CurrencySymbol} {vatIncluded}</div>
    )
}

interface DisplayPriceLogicProps extends DisplayPriceTy {
}

const useDisplayPriceLogic = ({ CurrencyId, value, with_vat, calculateParams }: DisplayPriceLogicProps) => {
    const { t } = useTwinTranslation()
    let myValue = roundNumberByParams(value, calculateParams.rounding, { roundingPriceType: calculateParams.roundingPriceType })
    const valueParsed = isNaN(myValue) ? 0 : myValue
    const finalValue = valueParsed.toFixed(calculateParams.decimals)
    const priceParsed = displayFormatedNumber(finalValue)
    const CurrencySymbol = getCurrencySymbol(CurrencyId)
    const vatIncluded = with_vat ? t('withVat', 'Iva incl.') : ''
    return { priceParsed, CurrencySymbol, vatIncluded }
}

export default DisplayPrice