
import { useCallback, useRef, useState } from 'react'
import { ButtonOrLoader } from '../../../../../../../baseComponents/Button'
import { ModalMedium } from '../../../../../../../baseComponents/Modal'
import { ModalEditComponentProps } from '../../../../../../../baseComponents/ModalsLayouts/types'
import TwinTrans from '../../../../../../../baseComponents/TwinTrans'
import FormRenderer from '../../../../../../../forms/FormRenderer'
import { FormRender } from '../../../../../../../forms/FormRenderer/types'
import twinFetchPost from '../../../../../../../utils/globals/data'
import { stringToDateString } from '../../../../../../../utils/globals/date'
import { returnClassOfSpanTemplate } from '../../../../../../../utils/globals/tailwind'
import { valueOrDefaultValue } from '../../../../../../../utils/globals/valueOrDefaultValue'
import useIsLoading from '../../../../../../../utils/hooks/useIsLoading'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import { getEmployees } from '../../../../../../../utils/reducers/getters'
import { changeErrorMessage } from '../../../../../../../utils/reducers/reduxDispatch'
import useDictMonths from '../../../../../../../utils/hooks/useDictMonths'


type ModalConfigColumnsProps = ModalTCEmployeePdfMultipleLogicProps & {}

interface CreatePdfProps {
    dateStart: string,
    dateEnd: string,
    EmployeeIds: string
}

const ModalTCEmployeePdfMultiple: React.FC<ModalConfigColumnsProps> = ({ setOpened, ...rest }) => {
    const { t } = useTwinTranslation()
    const employees = getEmployees()
    const { ref, handleBeforeSubmit, loading, changeFieldValue, values } = useModalTCEmployeePdfMultipleLogic({ ...rest, setOpened })
    
    const fields: FormRender<CreatePdfProps> = [
        {
            cols: 2,
            elements: [
                {
                    name: 'dateStart',
                    label: t('dateStart', 'Fecha de inicio'),
                    component: 'InputCalendarStateFull',
                    onChange: (value) => changeFieldValue(stringToDateString(value) || value, 'dateStart'),
                    required: true
                },
                {
                    name: 'dateEnd',
                    label: t('dateEnd', 'Fecha fin'),
                    component: 'InputCalendarStateFull',
                    onChange: (value) => changeFieldValue(stringToDateString(value) || value, 'dateEnd'),
                    required: true
                },
                {
                    name: 'EmployeeIds',
                    component: 'MultipleCheckboxSelector',
                    subtitles: { subtitleOptions: t('employees', 'Empleados'), subtitleSelectedOptions: t('selectedEmployees', 'Empleados seleccionados') },
                    items: employees || {},
                    className: returnClassOfSpanTemplate(2),
                    onChange: (value) => changeFieldValue(value, 'EmployeeIds'),
                    keyName: 'fullname_short',
                },
            ]
        },
    ]

    const parsedFields = valueOrDefaultValue(fields, { n: 3, permission: 'update' }, { dateStart: new Date(values.dateStart).toISOString(), dateEnd: new Date(values.dateEnd).toISOString(), EmployeeIds: ''})
    return (
        <ModalMedium opened={true} setOpened={setOpened} onClickOut={false} className='flex flex-col wrap'>
            <div className='flex flex-auto flex-col' ref={ref}>
                <h2 className='my-30'><TwinTrans transKey={'downloadPdfMultiple'}>Descargar PDF múltiple</TwinTrans></h2>
                {fields.length ?
                    <FormRenderer sections={parsedFields} className='grid_mincontent'>
                    </FormRenderer>
                    : null}
                <a className='hidden downloadfile' download={true} href='/' target='_blank' onClick={(e) => e.stopPropagation()}>a</a>
                <ButtonOrLoader buttonIsDisabled={loading} onClick={() => handleBeforeSubmit()} textButton={t('download', 'Descargar')}/>
            </div>
        </ModalMedium>
    )
}
interface ModalTCEmployeePdfMultipleLogicProps extends ModalEditComponentProps {
}
const useModalTCEmployeePdfMultipleLogic = ({ allRowData, setOpened }: ModalTCEmployeePdfMultipleLogicProps) => {
    const { t } = useTwinTranslation()
    const errorDateMessage = t('addValidDates', 'Introduzca una fecha válida')
    const errorAddEmployeeMessage = t('addSomeEmployee', 'Introduzca algún empleado')
    const startFileNameMultiple = t('employeeTimeControl', 'Control-empleados')
    const ref = useRef<any>(null)
    const { startLoading, endLoading, loading } = useIsLoading()
    const [values, setValues] = useState<CreatePdfProps>({ dateStart: allRowData?.dateStart, dateEnd: allRowData?.dateEnd, EmployeeIds: '' })
    const { dictMonths } = useDictMonths()

    const handleBeforeSubmit = useCallback(async () => {
        startLoading?.()
        const current = ref.current

        if (new Date(values.dateEnd) < new Date(values.dateStart)) {
            endLoading?.()
            changeErrorMessage(errorDateMessage)
            return
        } else if (!values.EmployeeIds) {
            endLoading?.()
            changeErrorMessage(errorAddEmployeeMessage)
            return
        }

        const result = await twinFetchPost('/api/app/employee/employeeTimetable/getEmployeeTimeControlPdf', { EmployeeIds: values.EmployeeIds, dateStart: values.dateStart, dateEnd: values.dateEnd })
        if (result) {
            const pdf = await result.blob()
            const fileURL = URL.createObjectURL(pdf);
            const a = current.querySelector('a')
            const dateStart = new Date(values.dateStart)
            const dateEnd = new Date(values.dateEnd)
            const dateStartName = dictMonths[dateStart.getMonth() + 1] + dateStart.getFullYear()
            const dateEndName = dictMonths[dateEnd.getMonth() + 1] + dateEnd.getFullYear()
            const datesName = dateStartName === dateEndName ? dateStartName : dateStartName + '-' + dateEndName
            const fileName = startFileNameMultiple + '-' + datesName
            if (a) {
                a.href = fileURL
                a.download = fileName
                a.click()
            }
        }
        endLoading?.()
        setOpened(null)
    }, [values, endLoading, startLoading, setOpened, errorAddEmployeeMessage, errorDateMessage, startFileNameMultiple, dictMonths])

    const changeFieldValue = useCallback((value: string, field: keyof CreatePdfProps) => {
        setValues((old: any) => {
            if (!old) {
                return old
            }
            return { ...old, [field]: value }
        })
    }, [setValues])

    return { handleBeforeSubmit, ref, loading, changeFieldValue, values }
}


export default ModalTCEmployeePdfMultiple
