import { DateLocalizer, Navigate, ViewProps } from "react-big-calendar";
import TwinCalendarYearView from '../../TwinCalendar/TwinCalendarYearView';
import { TwinDictionary } from '../../../utils/globals/dictionary';
import { useCallback, useMemo, useState } from 'react';
import ModalEventYearView from './ModalEventYearView';
import { DateCallback } from 'react-calendar';
import { ModalEventsYearView } from './ModalEventYearView/types';
import { TwinBigCalendarEvents } from '../types';
import "react-calendar/dist/Calendar.css";

interface ViewPropsExtended extends ViewProps {
    events: TwinBigCalendarEvents
}

export default function YearView({
    date,
    localizer,
    events,
}: ViewPropsExtended) {
    const currRange = YearView.range(date as any, { localizer })
    const renderThis: JSX.Element[] = []
    const { modalYearView, setModalYearView, handleClickDay, eventsJSON } = useYearViewLogic({ events })

    for (const index in currRange) {
        const month = currRange[index]
        renderThis.push(
            <div key={index} className='calendar_month_item'>
                <TwinCalendarYearView
                    locale='es-ES'
                    activeStartDate={month}
                    tileContent={(props) => {
                        const key = props.date.toDateString()
                        if (eventsJSON[key]?.length) {
                            return <div className='year_calendar_dot' style={{ background: `${eventsJSON[key][0].color}` }}></div>
                        }
                        return null
                    }}
                    tileClassName={({ date, view }) => {
                        const key = date.toDateString()
                        if (
                            view === "month" &&
                            eventsJSON[key]
                        ) {
                            return "event-day"
                        }
                        return null
                    }}
                    onClickDay={handleClickDay}
                />
            </div>
        )
    }

    return (
        <div className='grid-cols-4 grid custom_year overflow-y-auto relative'>
            {renderThis}
            {modalYearView ? <ModalEventYearView modalYearView={modalYearView} setOpened={() => setModalYearView(null)} /> : null}
        </div>
    )
}

interface YearViewLogicProps {
    events: TwinBigCalendarEvents
}

const useYearViewLogic = ({ events }: YearViewLogicProps) => {
    const [modalYearView, setModalYearView] = useState<ModalEventsYearView | null>(null)
    const eventsJSON = useMemo(() => {
        let eventsJSONMemo: TwinDictionary = {}
        for (const element of events) {
            if (element.start && element.end) {
                const currentDate = new Date(element.start)
                while (element.end >= currentDate) {
                    const key = currentDate.toDateString()
                    if (!eventsJSONMemo[key]) {
                        eventsJSONMemo[key] = []
                    }
                    eventsJSONMemo[key].push({ ...element, rightSide: undefined })
                    currentDate.setDate(currentDate.getDate() + 1)
                }
            } else if(element.start) {
                const key = element.start.toDateString()
                if (!eventsJSONMemo[key]) {
                    eventsJSONMemo[key] = []
                }
                eventsJSONMemo[key].push({ ...element, rightSide: undefined })
            }
        }
        return eventsJSONMemo
    }, [events])
    const handleClickDay: DateCallback = useCallback((day, event) => {
        const key = day.toDateString()
        const eventsJSONParsed = eventsJSON[key]
        const current: any = event.currentTarget
        let data: ModalEventsYearView = {
            bottom: 'auto' as any,
            className: '',
            events: eventsJSONParsed,
            left: current.offsetLeft + 6,
            top: current.offsetTop + current.offsetHeight + 11
        }
        let maxLeft = current?.offsetParent?.offsetWidth || 0
        let maxBottom = current?.offsetParent.offsetHeight || 0
        if (data.left < 115) {
            data.left = 0
            data.className = 'no_arrow'
        } else if ((data.left + current.offsetWidth + 90) > maxLeft) {
            data.left = maxLeft - 210
            data.className = 'no_arrow'
        } else {
            data.left = data.left + (current.offsetWidth / 2) - 106
            data.className = 'center'
        }

        if (current.offsetTop >= (maxBottom + 100)) {
            data.top = 'auto' as any
            data.bottom = maxBottom - current.offsetTop
            if (data.className === 'center') {
                data.className = 'center_inversed'
            }
        }

        if (eventsJSONParsed) {
            setModalYearView(data)
        }
    }, [eventsJSON])
    return { modalYearView, setModalYearView, handleClickDay, eventsJSON }
}


YearView.range = (date: Date, { localizer }: { localizer: DateLocalizer }) => {
    const start = localizer.startOf(date, "year")
    const end = localizer.endOf(date, "year")

    const range = []
    let current = start

    while (localizer.lte(current, end, "year")) {
        range.push(current)
        current = localizer.add(current, 1, "month")
    }

    return range
}

YearView.navigate = (
    date: Date,
    action: any,
    { localizer }: { localizer: DateLocalizer }
) => {
    if (action instanceof Date) return action

    switch (action) {
        case Navigate.NEXT:
            return localizer.add(date, 1, "year")
        case Navigate.PREVIOUS:
            return localizer.add(date, -1, "year")
        default:
            return date;
    }
}

YearView.title = (date: Date, { localizer }: { localizer: DateLocalizer }) => {
    return localizer.format(date, "YYYY")
}