import { faCalendar, faXmark } from '@fortawesome/pro-light-svg-icons'
import { useCallback, useEffect, useRef, useState } from 'react'
import TwinCalendar from '../../../baseComponents/TwinCalendar'
import Input, { InputWithLabelMarginIcon } from '../../../forms/Input'
import { ModalInputHour } from '../../../forms/Input/InputHour'
import { displayDateWithFormattedHours, displayFormatedHour, displayJustHoursFormatted } from '../../../utils/globals/date'
import { HourMinTy } from '../../../utils/globals/types'
import useOnClickOutside from '../../../utils/hooks/useOnClickOut'
import './InputCalendarWithStartEndHours.sass'

export type InputCalendarWithStartEndHoursStateLessProps = TwinCalendarWithHoursModalProps & InputCalendarWithStartEndHoursStateLessLogicProps & {
    name?: string
    label: string
    onClickIcon?: () => void
}

export const InputCalendarWithStartEndHoursStateLess: React.FC<InputCalendarWithStartEndHoursStateLessProps> = ({ readOnly, valueStart, valueEnd, label, name, onClickIcon, ...rest }) => {
    const { openCalendar, handleOpen, ref } = useInputCalendarWithStartEndHoursStateLessLogic({ readOnly })
    let dateFormatted = ''
    if (valueStart) {
        dateFormatted = displayDateWithFormattedHours(valueStart)
        if (valueEnd) {
            dateFormatted += ' - ' + displayJustHoursFormatted(valueEnd)
        }
    }
    return (
        <div className={'input_calendar input_calendar_start_end relative'} ref={ref}>
            <InputWithLabelMarginIcon {...rest} {...{ readOnly, onClickIcon }} value={dateFormatted} label={label} icon={valueStart && onClickIcon ? faXmark : faCalendar} onFocus={handleOpen} onChange={() => { }} className='w-full ' />
            {name ? <input type='hidden' name={name} value={valueStart?.toUTCString?.() || ''} /> : null}
            {openCalendar ? <TwinCalendarWithHoursModal {...rest} valueStart={valueStart} valueEnd={valueEnd} /> : null}
        </div>
    )
}

interface InputCalendarWithStartEndHoursStateLessLogicProps {
    readOnly?: boolean
}

const useInputCalendarWithStartEndHoursStateLessLogic = ({ readOnly }: InputCalendarWithStartEndHoursStateLessLogicProps) => {
    const [openCalendar, setOpenCalendar] = useState<boolean>(false)

    const ref = useRef<any>(null)

    useOnClickOutside(ref, () => setOpenCalendar(false))

    const handleOpen = useCallback(() => {
        if (!readOnly) {
            setOpenCalendar(true)
        }
    }, [readOnly])

    return { openCalendar, handleOpen, ref }
}

interface TwinCalendarWithHoursModalProps extends TwinCalendarWithHoursModalLogicProps {
    defaultActiveStartDate?: Date
    minDate?: Date
}

const TwinCalendarWithHoursModal: React.FC<TwinCalendarWithHoursModalProps> = ({ defaultActiveStartDate, minDate, ...rest }) => {
    const { onChangeStartMins, openHourModal, ref, setOpenHourModal, onDateChange, onChangeEndMins, tmpHours } = useTwinCalendarWithHoursModal({ ...rest })
    const hourMinStart: HourMinTy = { mins: tmpHours.dateStart?.getMinutes?.() || 0, hours: tmpHours.dateStart?.getHours?.() || 0 }
    const hourMinEnd: HourMinTy = { mins: tmpHours.dateEnd?.getMinutes?.() || 0, hours: tmpHours.dateEnd?.getHours?.() || 0 }
    const displayMinsStart = displayFormatedHour(hourMinStart)
    const displayMinsEnd = displayFormatedHour(hourMinEnd)
    return (
        <div className='flex flex-col absolute z-10 items-center input_cal_with_hours bg-white'>
            <TwinCalendar className='' onChange={onDateChange} value={tmpHours.dateStart} defaultActiveStartDate={defaultActiveStartDate} minDate={minDate} />
            <div ref={ref} className='flex justify-center pt-12 pb-12 input_cal_with_hours_picker'>
                <Input className='w-45 text-right' readOnly={true} value={displayMinsStart} onClick={() => setOpenHourModal('start')} />
                <span className='mx-10'>-</span>
                <Input className='w-45' readOnly={true} value={displayMinsEnd} onClick={() => setOpenHourModal('end')} />
                {openHourModal === 'start' ? <ModalInputHour onFinalChangeModal={onChangeStartMins} value={hourMinStart} className='modal_input_hour_calendar' maxHours={23} /> : null}
                {openHourModal === 'end' ? <ModalInputHour onFinalChangeModal={onChangeEndMins} value={hourMinEnd} className='modal_input_hour_calendar' maxHours={23} /> : null}
            </div>
        </div>
    )
}

interface TwinCalendarWithHoursModalLogicProps {
    onChange: (value: { valueStart: Date | undefined, valueEnd: Date | undefined }) => void
    valueStart?: Date
    valueEnd?: Date
}

const useTwinCalendarWithHoursModal = ({ valueStart, valueEnd, onChange }: TwinCalendarWithHoursModalLogicProps) => {
    const ref = useRef<any>(null)
    const [haveToTrigger, setHaveToTrigger] = useState<boolean>(false)
    const [tmpHours, setTmpHours] = useState({
        dateStart: valueStart,
        dateEnd: valueEnd
    })
    const [openHourModal, setOpenHourModal] = useState<'start' | 'end' | null>(null)

    const onDateChange = useCallback((date: Date) => {
        const newDateStart = new Date(date)
        const newDateEnd = new Date(date)
        newDateStart.setHours(valueStart?.getHours() || 9)
        newDateStart.setMinutes(valueStart?.getMinutes() || 0)
        newDateStart.setSeconds(0)
        newDateStart.setMilliseconds(0)
        newDateEnd.setHours(valueEnd?.getHours() || 10)
        newDateEnd.setMinutes(valueEnd?.getMinutes() || 0)
        newDateEnd.setSeconds(0)
        newDateEnd.setMilliseconds(0)

        onChange({ valueEnd: newDateEnd, valueStart: newDateStart })
    }, [onChange, valueEnd, valueStart])

    const onChangeStartMins = useCallback((hour: HourMinTy) => {
        setHaveToTrigger(true)
        setTmpHours((old) => {
            const d = old.dateStart ? new Date(old.dateStart) : new Date()
            d.setHours(hour.hours)
            d.setMinutes(hour.mins)
            d.setSeconds(0)
            d.setMilliseconds(0)
            const dEnd = new Date(d)
            dEnd.setHours(hour.hours + 1)
            dEnd.setMinutes(hour.mins)
            return { dateStart: d, dateEnd: dEnd }
        })
    }, [])

    const onChangeEndMins = useCallback((hour: HourMinTy) => {
        setHaveToTrigger(true)
        setTmpHours((old) => {
            const d = old.dateEnd ? new Date(old.dateEnd) : new Date()
            d.setHours(hour.hours)
            d.setMinutes(hour.mins)
            d.setSeconds(0)
            d.setMilliseconds(0)
            return { dateStart: old.dateStart, dateEnd: d }
        })
    }, [])

    useEffect(() => {
        if (haveToTrigger !== false) {
            let id = setInterval(() => {
                onChange({valueStart: tmpHours.dateStart, valueEnd: tmpHours.dateEnd});
                setHaveToTrigger(false)
            }, 500)
            return () => clearInterval(id)
        }
    }, [haveToTrigger, onChange, tmpHours.dateStart, tmpHours.dateEnd])


    useOnClickOutside(ref, () => {
        setOpenHourModal(null)
    })

    useEffect(() => {
        setTmpHours({dateStart: valueStart, dateEnd: valueEnd})
    }, [valueStart, valueEnd])


    return { onChangeStartMins, openHourModal, ref, setOpenHourModal, onDateChange, onChangeEndMins, tmpHours }
}