import { faArrowRotateBack, faLongArrowDown, faLongArrowUp } from '@fortawesome/pro-light-svg-icons'
import { faLongArrowDown as faLongArrowDownSolid, faLongArrowUp as faLongArrowUpSolid } from '@fortawesome/pro-solid-svg-icons'
import { useCallback } from 'react'
import TwinForm from '../../../../forms/TwinForm'
import { SelectedColumnsAndFilters } from '../../../../utils/hooks/useConfigColumns'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import TwinIcon from '../../../TwinIcon'
import TwinTrans from '../../../TwinTrans'
import { ModalCreateEditStructureLayout } from '../../ModalCreateEdit'
import HeaderModalConfigColumns from '../HeaderModalConfigColumns'
import { ModalConfigColumnsBaseProps } from '../types'
import { getNextOrder } from '../../../TwinTable/functions'


interface ModalConfigColumnsOrderProps extends ModalConfigColumnsOrderLogicProps {
}

const ModalConfigColumnsOrder: React.FC<ModalConfigColumnsOrderProps> = ({ tmpColumns, columns, onSave, ...logic }) => {
    const { changeChecked, resetSelected } = useModalConfigColumnsOrderLogic({ tmpColumns, columns, onSave, ...logic })
    const { t } = useTwinTranslation()

    const firstColumn: JSX.Element[] = []
    for (const key in columns) {
        const item = columns[key]
        if ((item.label || item.simpleLabel) && item.havePermission !== false && item.haveModule !== false && item.sortable) {
            const isDesc = tmpColumns.order[item.id] === 'desc'
            const isAsc = tmpColumns.order[item.id] === 'asc' 
            const classNameUp = isDesc? 'text-green-43' :  'text-gray-B2'
            const classNameDown = isAsc? 'text-green-43' : 'text-gray-B2'
            const iconUp = isDesc ? faLongArrowUpSolid : faLongArrowUp
            const iconDown = isAsc ? faLongArrowDownSolid : faLongArrowDown
            firstColumn.push(
                <div className='mt-15 flex flex-cols' key={key}>
                    <span>{item.simpleLabel || item.label as any}</span>
                    <div onClick={() => changeChecked(item.id)}>
                        <TwinIcon icon={iconDown} className={classNameDown + ' ml-5 mr-3 cursor-pointer'} size='sm'/>
                        <TwinIcon icon={iconUp} className={classNameUp + ' cursor-pointer'} size='sm'/>
                    </div>
                </div>)
        }
    }

    return (
        <TwinForm beforeSubmitHandler={onSave} className='flex flex-col flex-auto'>
            <ModalCreateEditStructureLayout loading={false} translations={{
                title: <HeaderModalConfigColumns {...{ tmpColumns, columns, setTmpColumns: logic.setTmpColumns, name: logic.name }} />,
                button: t('save', 'Guardar')
            }} haveButtonPermissions={true} className='modal_negative_margin'>
                <div className='overflow-auto h-full w-full'>
                    <div className='flex flex-wrap w-full h-fit '>
                        <div className='w-fit'>
                            <div className='flex items-center mb-20'>
                                <h4 className='mr-18 '><TwinTrans transKey='configureElementOrder'>Configurar orden de los elementos</TwinTrans></h4>
                                <div className='flex items-center text-green-21 cursor-pointer' onClick={resetSelected}>
                                    <TwinIcon icon={faArrowRotateBack} fontSize={14} className='mr-8' />
                                    <span className='mt-2 lh-14'><TwinTrans transKey='byDefault'>Por defecto</TwinTrans></span>
                                </div>
                            </div>
                            {firstColumn}
                        </div>
                    </div>
                </div>
            </ModalCreateEditStructureLayout>
        </TwinForm>
    )
}

interface ModalConfigColumnsOrderLogicProps extends ModalConfigColumnsBaseProps { }

const useModalConfigColumnsOrderLogic = ({ setTmpColumns }: ModalConfigColumnsOrderLogicProps) => {
    const changeChecked = useCallback((itemKey: string) => {
        setTmpColumns((oldTmp) => {
            let tmpCopy: SelectedColumnsAndFilters = JSON.parse(JSON.stringify(oldTmp))
            getNextOrder(itemKey, tmpCopy.order, 'desc')
            return { ...tmpCopy }
        })
    }, [setTmpColumns])

    const resetSelected = useCallback(() => {
        setTmpColumns((oldTmp) => {
            let tmpCopy: SelectedColumnsAndFilters = JSON.parse(JSON.stringify(oldTmp))
            tmpCopy.order = {}
            return { ...tmpCopy }
        })
    }, [setTmpColumns])

    return { changeChecked, resetSelected }
}


export default ModalConfigColumnsOrder