import { useRef, useCallback } from 'react';
import { ModalOpenedSetTy } from '../../../../../../baseComponents/Modal/types';
import TwinTrans from '../../../../../../baseComponents/TwinTrans';
import { InputCalendarStateFull } from '../../../../../../forms/Input/InputCalendar';
import useOnClickOutside from '../../../../../../utils/hooks/useOnClickOut';
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation';
import { stringToDateString } from '../../../../../../utils/globals/date';
import { UpdateTaskTy } from '../../../types';

interface ModalTaskNotShowUntilProps extends ModalTaskNotShowUntilLogicProps {
    remember_date?: string
}

export const ModalTaskNotShowUntil: React.FC<ModalTaskNotShowUntilProps> = ({ remember_date, ...rest }) => {
    const { ref, handleUpdateTask } = useModalTaskNotShowUntilLogic({...rest})
    const {t} = useTwinTranslation()
    return (
        <div ref={ref} className='not_show_until_modal absolute w-240 bg-white rounded-xl px-30 py-20 z-10'>
            <div className=''>
                <div className='mb-20 regular16'>
                    <TwinTrans transKey='notShowUntil'>No mostrar hasta</TwinTrans>
                </div>
                <InputCalendarStateFull label={t('date', 'fecha')} name='remember_date' value={remember_date} onChange={handleUpdateTask} onlyValids={true} />
            </div>
        </div> 
    )
}

interface ModalTaskNotShowUntilLogicProps {
    setOpened: ModalOpenedSetTy
    updateTask: UpdateTaskTy
}

const useModalTaskNotShowUntilLogic = ({ setOpened, updateTask }: ModalTaskNotShowUntilLogicProps) => {
    const ref = useRef<any>(null)
    useOnClickOutside(ref, () => setTimeout(() => setOpened(null), 150))

    const handleUpdateTask = useCallback((value: string) => {
        updateTask('remember_date', value !== '' ? stringToDateString(value) : '' )
    }, [updateTask])

    return { ref, handleUpdateTask }
}