import { Fragment, useCallback, useState } from 'react'
import { ModuleInformation, ModuleInformationIndividualModule } from '../types'
import SearchBar from '../../../../../../baseComponents/SearchBar'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import TwinTrans from '../../../../../../baseComponents/TwinTrans'
import SwitchLight from '../../../../../../forms/SwitchLight'
import CustomSelect from '../../../../../../forms/CustomSelect'
import { ModuleDashboardFilters } from './types'
import { parseModuleListByFilters } from './functions'
import { WhiteBox } from '../../../../../../baseComponents/AppLayout/WhiteBox'
import { ModalOpenedTy } from '../../../../../../baseComponents/Modal/types'
import IndividualModuleModal from './IndividualModuleModal'
import DisplayPriceAdmin from '../../../../components/DisplayPriceAdmin'
import TwinIcon, { TwinIconProp } from '../../../../../../baseComponents/TwinIcon'
import { faCheckCircle, faCircleArrowDown, faTimesCircle } from '@fortawesome/pro-light-svg-icons'

interface ModuleListProps {
    moduleInformation: ModuleInformation
    addModule: (module_id: string) => void
    deleteModule: (module_id: string) => void
}

const ModuleList: React.FC<ModuleListProps> = ({moduleInformation, ...funcs}) => {
    const { filters, changeSearchbar, changeOnlyActive, changeCategory } = useModuleListLogic()
    const parsedModules = parseModuleListByFilters(moduleInformation, filters)
    const renderThis: JSX.Element[] = []
    for (const element of parsedModules) {
        renderThis.push(<IndividualModule mymodule={element} key={element.id} {...funcs} />)
    }
    const {t} = useTwinTranslation()
    return (
        <div className='module_listing_parent'>
            <SearchBar onChange={changeSearchbar} searchValue={filters.searchBar} placeholder={t('searchByName', 'Buscar por nombre')} />
            <div className='module_listing'>
                <div className='module_listing_header flex items-end'>
                    <div className='module_listing_left mb-5'>
                        <span>{moduleInformation.modules.length} <TwinTrans transKey='modules'>módulos</TwinTrans></span>
                    </div> 
                    <div className='module_listing_right ml-auto flex items-end'>
                        <SwitchLight active={filters.onlyActive} onChange={changeOnlyActive} label={t('activeModules', 'Módulos activados')} className='mr-50 mb-5'/>
                        <CustomSelect label={t('filterByCategory', 'Filtrar por categoría')} items={moduleInformation.categories} className='w-150' onChange={changeCategory}/>
                    </div>
                </div>
                <div className='module_listing_inner grid grid-cols-4'>
                    {renderThis}
                </div>
            </div>
        </div>
    )
}

const useModuleListLogic = () => {
    const [filters, setFilters] = useState < ModuleDashboardFilters>({
        CategoryId: 0,
        onlyActive: false,
        searchBar: ''
    })

    const changeSearchbar = useCallback((searchBar: string) => {
        setFilters((old) => {
            return JSON.parse(JSON.stringify({
                ...old,
                searchBar
            }))
        })
    }, [setFilters])

    const changeOnlyActive = useCallback((onlyActive: boolean) => {
        setFilters((old) => {
            return JSON.parse(JSON.stringify({
                ...old,
                onlyActive
            }))
        })
    }, [setFilters])
    
    const changeCategory = useCallback((CategoryId: string) => {
        setFilters((old) => {
            return JSON.parse(JSON.stringify({
                ...old,
                CategoryId: parseInt(CategoryId)
            }))
        })
    }, [setFilters])

    return { filters, changeSearchbar, changeOnlyActive, changeCategory }
}

interface IndividualModuleProps {
    mymodule: ModuleInformationIndividualModule
    addModule: (module_id: string) => void
    deleteModule: (module_id: string) => void
}

const IndividualModule: React.FC<IndividualModuleProps> = ({ mymodule, ...funcs }) => {
    const {opened, setOpened} = useIndividualModuleLogic()
    const shortDescription = mymodule?.shortDescription && mymodule.shortDescription.length > 85 ? (mymodule.shortDescription.substring(0, 85) + '...') : mymodule.shortDescription
    return (
        <Fragment>
            <WhiteBox className='individual_module flex flex-col relative' onClick={() => setOpened(true)}>
                <ModuleTypeBall type={mymodule.type} />
                <span className='regular20'>{mymodule?.name}</span>
                <span className='light16 my-14'>{shortDescription}</span>
                <DisplayPriceAdmin className='regular20 mt-auto' value={mymodule.price}> / <TwinTrans transKey='month'>mes</TwinTrans></DisplayPriceAdmin>
            </WhiteBox>
            {opened ? <IndividualModuleModal setOpened={setOpened} mymodule={mymodule} {...funcs} /> : null}
        </Fragment>
    )
}


const useIndividualModuleLogic = () => {
    const [opened, setOpened] = useState<ModalOpenedTy>(null)
    return {opened, setOpened}
}

interface ModuleTypeBallProps {
    type?: ModuleInformationIndividualModule['type']
}

const ModuleTypeBall: React.FC<ModuleTypeBallProps> = ({type}) => {
    if (!type) {
        return null
    }
    const dict: Record<'new' | 'own' | 'deleted', { className: string, icon: TwinIconProp}> = {
        deleted: {
            className: 'bg-red-BA',
            icon: faTimesCircle
        },
        own: {
            className: 'bg-green-21',
            icon: faCheckCircle
        },
        new: {
            className: 'bg-green-00',
            icon: faCircleArrowDown
        }
    }
    const props = dict[type]
    return (
        <div className={'module_ball flex items-center justify-center absolute ' + props.className}>
            <TwinIcon icon={props.icon} fontSize={26} className='text-white'/>
        </div>
    )
}

export default ModuleList