import { CustomerProjectStatusModelAdd, CustomerProjectStatusModelType } from '@teinor/erp/types/company/customer/customerProjectStatus'
import { useCallback, useEffect, useState } from 'react'
import { ButtonOrLoader } from '../../../../../../../../../baseComponents/Button'
import { MediumModalCreateEdit } from '../../../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../../../../../../../baseComponents/ModalsLayouts/types'
import VerticalSortList from '../../../../../../../../../baseComponents/Sorts/VerticalSortList'
import TwinTrans from '../../../../../../../../../baseComponents/TwinTrans'
import FormRenderer from '../../../../../../../../../forms/FormRenderer'
import { FormRender } from '../../../../../../../../../forms/FormRenderer/types'
import { twinFetchPostJSON } from '../../../../../../../../../utils/globals/data'
import { TwinDictionary, dictionaryComplexFromJsonArr } from '../../../../../../../../../utils/globals/dictionary'
import useIsLoading from '../../../../../../../../../utils/hooks/useIsLoading'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import { CustomerProjectStatusModelTypeExtended, CustomerProjectTypeStatuses, CustomerProjectStatusVerticalSortItem } from './types'
import { customerProjectTypeColors } from '../../../../../../../../../utils/globals/colors'
import useCustomerTypesRandomColor from '../../../../../../../../../utils/hooks/customer/useCustomerTypesRandomColor'
import { ModalOpenedTy } from '../../../../../../../../../baseComponents/Modal/types'
import { MultipleCbxSelectorValues } from '../../../../../../../../../forms/MultipleCheckboxSelector/types'
import { parseIntArray } from '../../../../../../../../../utils/globals/array'


type ModalCECustomerProjectFlowProps = ModalEditComponentProps & {
    customerProjectTypeId: number
}

const ModalCECustomerProjectFlow: React.FC<ModalCECustomerProjectFlowProps> = ({ userPermissions, customerProjectTypeId, ...rest }) => {
    const { t } = useTwinTranslation()
    const { projectStatuses, createStatusOpened, setCreateStatusOpened, getCustomerProjectTypeStatuses, handleOnChangeProjectStatus, handleBeforeSubmitProjectStatus, changeSelectedProjectStatus } = useModalCECustomerProjectFlowLogic({ id: customerProjectTypeId })

    if (!projectStatuses) {
        return null
    }
    
    const allProjectsStatusDict = dictionaryComplexFromJsonArr(projectStatuses.allProjectsStatus)
    const fields: FormRender<CustomerProjectStatusModelTypeExtended> = [
        {
            cols: 1,
            title: t('statuses', 'Estados'),
            elements: [
                {
                    name: 'customerProjectStatus',
                    component: 'MultipleCheckboxSelectorStateLess',
                    items: allProjectsStatusDict || {},
                    selectedValues: projectStatuses.selectedProjects,
                    subtitles: { subtitleOptions: t('hidden', 'Escondidos'), subtitleSelectedOptions: t('visibles', 'Visibles') },
                    onChange: changeSelectedProjectStatus
                }
            ]
        },
    ]
    return (
        <MediumModalCreateEdit fields={fields} url={'/api/app/customer/customerProject/customerProjectStatus/updateCustomerProjectStatusOrderAndListing'} translations={{
            title: t('manageStatuses', 'Manejar estados'),
            button: t('save', 'Guardar')
        }} haveButtonPermissions={true} className='notFlexAutoFormRender' {...rest} beforeSubmit={handleBeforeSubmitProjectStatus}>
            <div className='mt-30'>
                <span className='ml-auto text-16 text-green-21 cursor-pointer w-full' onClick={() => setCreateStatusOpened((old) => old ? null : true)}><TwinTrans transKey='createNewStatus'>Crear nuevo estado</TwinTrans></span>
                {createStatusOpened ? <ModalCreateCustomerProjectFlow getCustomerProjectTypeStatuses={getCustomerProjectTypeStatuses} CustomerProjectTypeId={customerProjectTypeId} /> : null}
            </div>
            <div className='mt-30'>
                <VerticalSortList items={projectStatuses.allProjectsStatus} setItems={(setItems) => handleOnChangeProjectStatus(setItems(projectStatuses.allProjectsStatus))}></VerticalSortList>
            </div>
        </MediumModalCreateEdit>
    )
}

interface ModalCECustomerProjectFlowLogicProps {
    id: number
}

const useModalCECustomerProjectFlowLogic = ({ id }: ModalCECustomerProjectFlowLogicProps) => {
    const [createStatusOpened, setCreateStatusOpened] = useState<ModalOpenedTy>(null)
    const [projectStatuses, setProjectStatuses] = useState<CustomerProjectTypeStatuses | null>(null)
    
    const getCustomerProjectTypeStatuses = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/customer/customerProject/customerProjectStatus/getAllCustomerProjectStatus', {
            where: {
                CustomerProjectTypeId: id,
            },
            order: [['order', 'ASC']]
         })
        if (result) {
            const selectedProjects: number[] = []
            const allProjectsStatus: CustomerProjectStatusVerticalSortItem[] = []
            for (const element of result) {
                if (element.listing) {
                    selectedProjects.push(element.id)
                }
                allProjectsStatus.push({
                    id: element.id,
                    name: element.name,
                    order: element.order,
                    color: element.color, 
                    listing: element.listing
                })
            }
            setProjectStatuses({ selectedProjects, allProjectsStatus })
        }
    }, [id])

    const handleOnChangeProjectStatus = useCallback((items: CustomerProjectStatusVerticalSortItem[]) => {
        setProjectStatuses((old) => {
            if (!old) {
                return old
            }
            const copy = JSON.parse(JSON.stringify(old))
            return {
                ...copy,
                allProjectsStatus: items
            }
        })
    }, [setProjectStatuses])

    const handleBeforeSubmitProjectStatus = useCallback((vals: TwinDictionary) => {
        if (projectStatuses) {
            const mySelectedIds = vals['customerProjectStatus'].split(';')
            for (const key in projectStatuses.allProjectsStatus) {
                const item = projectStatuses.allProjectsStatus[key]
                if (mySelectedIds.includes(String(item.id))) {
                    item.listing = true
                } else {
                    item.listing = false
                }
            }
            vals['customerProjectStatus'] = projectStatuses.allProjectsStatus
        }
        return true
    }, [projectStatuses])

    const changeSelectedProjectStatus = useCallback((items: MultipleCbxSelectorValues) => {
        setProjectStatuses((old) => {
            if (!old) {
                return null
            }
            return { allProjectsStatus: old.allProjectsStatus, selectedProjects: parseIntArray(items || []) }
        })
    }, [setProjectStatuses])

    useEffect(() => {
        getCustomerProjectTypeStatuses()
    }, [getCustomerProjectTypeStatuses])

    return { projectStatuses, setProjectStatuses, setCreateStatusOpened, createStatusOpened, getCustomerProjectTypeStatuses, handleOnChangeProjectStatus, handleBeforeSubmitProjectStatus, changeSelectedProjectStatus }
}

interface ModalCreateCustomerProjectFlowProps extends ModalCreateCustomerProjectFlowLogicProps {}

const ModalCreateCustomerProjectFlow: React.FC<ModalCreateCustomerProjectFlowProps> = ({...logic}) => {
    const { t } = useTwinTranslation()
    const { data, changeField, handleCreateProjectStatusFlow, loading } = useModalCreateCustomerProjectFlowLogic({ ...logic })

    const fields: FormRender<CustomerProjectStatusModelTypeExtended> = [
        {
            cols: 2,
            elements: [
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true,
                    value: data.name,
                    onChange: (val: any) => changeField(val, 'name')
                },
                {
                    name: 'color',
                    label: t('color', 'Color'),
                    component: 'InputColor',
                    colors: customerProjectTypeColors,
                    value: data.color,
                    onChange: (val: any) => changeField(val, 'color')
                },
                {
                    name: 'listing',
                    label: t('listing', 'Listar'),
                    component: 'CheckboxMargin',
                    onChange: (val: any) => changeField(val, 'listing'),
                    checked: data.listing
                },
                {
                    name: 'id',
                    component: 'Custom',
                    render: ({ extraProps }) => <ButtonOrLoader onClick={extraProps.handleCreateProjectStatusFlow} buttonIsDisabled={extraProps.loading} textButton={t('create', 'Crear')} />,
                    extraProps: {
                        loading,
                        data,
                        handleCreateProjectStatusFlow
                    }
                }
            ]
        },
    ]
    return (
        <div className='cp_status_flow_create_status mt-10'>
            <FormRenderer sections={fields} />
        </div>
    )
}

interface ModalCreateCustomerProjectFlowLogicProps {
    getCustomerProjectTypeStatuses: () => Promise<void>
    CustomerProjectTypeId: number
}

const useModalCreateCustomerProjectFlowLogic = ({ getCustomerProjectTypeStatuses, CustomerProjectTypeId }: ModalCreateCustomerProjectFlowLogicProps) => {
    const { getDefaultRandomColorCustomerTypes} = useCustomerTypesRandomColor()
    const [data, setData] = useState<CustomerProjectStatusModelAdd>(
        {
            name: '',
            color: getDefaultRandomColorCustomerTypes(),
            listing: true,
            order: 0,
            CustomerProjectTypeId
        }
    )
    const { endLoading, loading, startLoading } = useIsLoading()

    const changeField = useCallback((value: any, field: keyof CustomerProjectStatusModelType) => {
        setData((old) => {
            if (!old) {
                return old
            }
            const copy = JSON.parse(JSON.stringify(old))
            return { ...copy, [field]: value }
        })
    }, [setData])

    const handleCreateProjectStatusFlow = useCallback(async (e: any) => {
        e.stopPropagation()
        e.preventDefault()
        startLoading()
        await twinFetchPostJSON('/api/app/customer/customerProject/customerProjectStatus/createCustomerProjectStatus', { ...data })
        await getCustomerProjectTypeStatuses()
        endLoading()
        setData({
            name: '',
            color: getDefaultRandomColorCustomerTypes(),
            listing: true,
            order: 0,
            CustomerProjectTypeId
        })
    }, [data, endLoading, startLoading, getCustomerProjectTypeStatuses, CustomerProjectTypeId, getDefaultRandomColorCustomerTypes])

    return {data, changeField, handleCreateProjectStatusFlow, loading }
}


export default ModalCECustomerProjectFlow