export interface TextFieldProps {
    className?: string
    label: string
    value?: string
}

export const TextField: React.FC<TextFieldProps> = ({className, label, value}) => {
    return (
        <div className={'flex flex-col ' + (className || '')}>
            <span className='light12 text-gray-51 mb-4'>{label}</span>
            <span className='regular14 overflow-hidden' >{value}</span>
        </div>
    )
}