import { useCallback, useState } from 'react'

const useIsLoading = () => {
    const [loading, setLoading] = useState<boolean>(false)

    const endLoading = useCallback(() => {
        setLoading(false)
    }, [setLoading])

    const startLoading = useCallback(() => {
        setLoading(true)
        return true
    }, [setLoading])

    return { loading, endLoading, startLoading }
}


export default useIsLoading