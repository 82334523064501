import { faArrowUpFromBracket } from '@fortawesome/pro-light-svg-icons'
import { HeaderElementTask } from '..'
import { UpdateTaskTy } from '../../types'

interface HeaderTaskUnfinishProps {
    id: string
    finished: boolean
    message: string
    updateTask: UpdateTaskTy  
}

const HeaderTaskUnfinish: React.FC<HeaderTaskUnfinishProps> = ({id, finished, message, updateTask}) => {
    if(!finished){
        return null
    } 
    return (
        <HeaderElementTask {...{message}} id={'unfinishTask_' + id} icon={faArrowUpFromBracket} onClick={()=>updateTask('finished', false)} />
    )
}

export default HeaderTaskUnfinish