import { DndContext, closestCenter, useSensors, useSensor, PointerSensor, KeyboardSensor, TouchSensor, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy, sortableKeyboardCoordinates, arrayMove, useSortable } from '@dnd-kit/sortable';
import { faArrows, faTrashAlt, faArrowsMaximize } from '@fortawesome/pro-light-svg-icons';
import { useState, useCallback, useRef } from 'react';
import { TwinDictionary } from '../../utils/globals/dictionary';
import { returnClassOfGridTemplate, returnClassOfSpanTemplate, returnClassOfSpanRowsTemplate } from '../../utils/globals/tailwind';
import useTwinTranslation from '../../utils/hooks/useTwinTranslation';
import { WhiteBox } from '../AppLayout/WhiteBox';
import { BigTitleAndRightLayout } from '../Layout/TitleAndRightLayout';
import TwinIcon from '../TwinIcon';
import { TwinAutoSizer } from '../TwinTable/Adapters';
import DashboardHeader from './DashboardHeader';
import { WidgetSetupParams } from './types';
import useDashboardWidgets, { UseDashboardWidgetsProps } from './useDashboardWidgets';
import { DashboardWidgetAndFamilies } from './useDashboardWidgets/types';
import { CSS } from '@dnd-kit/utilities';
import './dashboard.sass'


interface DashboardProps extends DashboardLogicProps {}

const Dashboard: React.FC<DashboardProps> = ({...logic}) => {
    const { t } = useTwinTranslation()
    const { saveUserWidgets, getUserWidgets, ...rest } = useDashboardLogic({...logic})
    return (
        <BigTitleAndRightLayout title={t('dashboard', 'Dashboard')} RightHeader={<DashboardHeader {...{ saveUserWidgets, getUserWidgets, ...rest }} />} >
            <div className='flex-auto'>
                <TwinAutoSizer>
                    {({ width, height }) => (
                        <DashboardInner {...{ width, height, ...rest }} />
                    )}
                </TwinAutoSizer>
            </div>
        </BigTitleAndRightLayout>
    )
}
interface DashboardLogicProps extends UseDashboardWidgetsProps {

}
const useDashboardLogic = ({ ...logic }: DashboardLogicProps) => {
    const [setUpOpened, setSetUpOpened] = useState(false)
    const dashboardWidgetLogic = useDashboardWidgets({...logic})

    return { setUpOpened, setSetUpOpened, ...dashboardWidgetLogic }
}


interface ElementDashboard {
    component: React.FC<any>
    cols: number
    rows: number
    name: string
    extraProps?: TwinDictionary
}

type DashboardInnerProps = WidgetSetupParams & DashboardInnerLogicProps & DashboardWidgetAndFamilies & {
    width: number
    height: number
}

const colsWidth = 325
const rowsHeight = 125

const DashboardInner: React.FC<DashboardInnerProps> = ({ width, height, setSetUpOpened, setUpOpened, userDashboardWidgets, avaliableDashboardWidgets, ...logic }) => {
    const gridCols = Math.floor(width / colsWidth) || 1
    const { sensors, handleDragOver, handleChangePropsElement, deleteElement } = useDashboardInnerLogic({ ...logic })
    const renderThis = []
    const ids: any[] = []
    for (const key in userDashboardWidgets) {
        const element = userDashboardWidgets[key]
        ids.push(element.name)
        const widgetRender = avaliableDashboardWidgets[element.name]
        if (widgetRender) {
            renderThis.push(<DashboardRendererItem {...{ setSetUpOpened, setUpOpened, element: { ...element, component: widgetRender.component, extraProps: widgetRender.extraProps }, key: element.name, id: element.name, maxCols: gridCols, changeElement: (props) => handleChangePropsElement(props, parseInt(key)), deleteElement: () => deleteElement(parseInt(key)) }} />)
        }
    }
    return (
        <div style={{ width, height }} className={'grid overflow-auto dashboard-layout ' + returnClassOfGridTemplate(gridCols as any)}>
            <DndContext sensors={sensors} onDragOver={handleDragOver} collisionDetection={closestCenter}>
                <SortableContext items={ids} strategy={rectSortingStrategy}>
                    {renderThis}
                </SortableContext>
            </DndContext>
        </div>
    )
}

type DashboardInnerLogicProps = {
    setUserDashboardWidgets: DashboardWidgetAndFamilies['setUserDashboardWidgets']
}

const useDashboardInnerLogic = ({ setUserDashboardWidgets }: DashboardInnerLogicProps) => {

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                delay: 150,
                tolerance: 5
            }
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 75,
                tolerance: 5,
            },
        })
    )

    const handleDragOver = useCallback((event: DragEndEvent) => {
        const { active, over } = event
        if (active.id !== over?.id) {
            let oldIndex = active?.data?.current?.sortable.index
            let newIndex = over?.data?.current?.sortable.index
            setUserDashboardWidgets((oldItems: any) => {
                const items: ElementDashboard[] = arrayMove(oldItems, oldIndex, newIndex)
                return [...items]
            })
        }
    }, [setUserDashboardWidgets])

    const handleChangePropsElement = useCallback((changedProps: TwinDictionary, key: number) => {
        setUserDashboardWidgets((oldItems: any) => {
            const copy = JSON.parse(JSON.stringify(oldItems))
            copy[key] = { ...copy[key], ...changedProps }
            return copy
        })
    }, [setUserDashboardWidgets])

    const deleteElement = useCallback((key: number) => {
        setUserDashboardWidgets((oldItems: any) => {
            const copy = JSON.parse(JSON.stringify(oldItems))
            copy.splice(key, 1)
            return copy
        })
    }, [setUserDashboardWidgets])


    return { sensors, handleDragOver, handleChangePropsElement, deleteElement }
}

type DashboardRendererItemProps = WidgetSetupParams & DashboardRendererItemLogicProps & {
    deleteElement: () => void
}

const DashboardRendererItem: React.FC<DashboardRendererItemProps> = ({ element, setUpOpened, deleteElement, ...logic }) => {
    const { ref, isResizing, style, startDragging, attributes, listeners, setNodeRef, rows, cols } = useDashboardRendererItemLogic({ element, ...logic })

    return (
        <div className={returnClassOfSpanTemplate(cols as any) + ' relative dashboard_component ' + returnClassOfSpanRowsTemplate(rows as any) + (isResizing ? ' active' : '')} ref={setNodeRef} style={style}>
            <WhiteBox subRef={ref} className='w-full h-full p-24 flex flex-col'>
                <element.component {...element.extraProps} {...{ rows, cols }} />
                {setUpOpened ? <div className='p-5 absolute right-1 top-1 cursor-move' {...{ ...attributes, ...listeners }}><TwinIcon icon={faArrows} /></div> : null}
                {setUpOpened ? <div className='p-5 absolute right-8 top-1 cursor-pointer' onClick={deleteElement} ><TwinIcon icon={faTrashAlt} /></div> : null}
                {setUpOpened ? <div className='p-5 absolute right-1 bottom-1 cursor-nw-resize' onMouseDown={startDragging}><TwinIcon icon={faArrowsMaximize} /></div> : null}
                {isResizing ? <div className='ghost top-0 left-0 absolute'></div> : null}
            </WhiteBox>
        </div>
    )
}

interface DashboardRendererItemLogicProps {
    maxCols: number
    id: string
    element: ElementDashboard
    changeElement: (changedProps: TwinDictionary) => void
}

const useDashboardRendererItemLogic = ({ element, maxCols, id, changeElement }: DashboardRendererItemLogicProps) => {
    const ref = useRef<HTMLDivElement | null>(null)
    const [isResizing, setIsResizing] = useState(false)
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })
    if (transform) {
        transform.scaleX = 1
        transform.scaleY = 1
    }
    const style = { transform: CSS.Transform.toString(transform), transition }

    const current = ref?.current?.parentElement
    const moveMouse: any = useCallback((e: any) => {
        const offsetsInner = current?.getBoundingClientRect()
        if (current && offsetsInner) {
            const ghost: any = current.querySelector('.ghost')
            const horizontal = e.clientX - offsetsInner.left + 50
            const vertical = e.clientY - offsetsInner.top + 50
            if (ghost) {
                ghost.style.right = ((current.offsetWidth - (horizontal))) + 50 + 'px'
                ghost.style.bottom = ((current.offsetHeight - (vertical))) + 50 + 'px'
            }
            const newCols = Math.floor(horizontal / colsWidth)
            const newRows = Math.floor(vertical / rowsHeight)
            changeElement({
                cols: newCols > maxCols ? maxCols : newCols < 1 ? 1 : newCols,
                rows: newRows < 1 ? 1 : newRows
            })
        }
    }, [maxCols, current, changeElement])

    const stopDragging: any = useCallback(() => {
        document.removeEventListener("mouseup", stopDragging);
        document.removeEventListener("mousemove", moveMouse);
        document.querySelector('body')?.classList.remove('cursor-nw-resize')
        setIsResizing(false)
    }, [moveMouse])

    const startDragging: React.MouseEventHandler<HTMLDivElement> = useCallback((e) => {
        setIsResizing(true)
        document.addEventListener("mouseup", stopDragging);
        document.addEventListener("mousemove", moveMouse);
        document.querySelector('body')?.classList.add('cursor-nw-resize')
    }, [stopDragging, moveMouse])


    return { ref, isResizing, style, startDragging, attributes, listeners, setNodeRef, rows: element.rows, cols: element.cols > maxCols ? maxCols : element.cols }

}


export default Dashboard