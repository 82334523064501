import React, { useCallback, useEffect, useState } from 'react'
import './left_menu.sass'
import { Routes } from '../../CreateRoutering/types'
import IconMenuBar from './IconMenuBar'
import OpenedMenu from './OpenedMenu'
import { ModalOpenedTy } from '../../Modal/types'

interface LeftMenuProps {
    routes: Routes
}

const LeftMenu: React.FC<LeftMenuProps> = ({ routes }) => {
    const { opened, setOpened, resizeLeftMenu } = useLeftMenuLogic()
    const props = { routes, opened, setOpened }

    
    return (
        <div className='flex'>
            <IconMenuBar {...props} />
            <OpenedMenu {...props} onClick={resizeLeftMenu} />
        </div>
    )
}

const useLeftMenuLogic = () => {
    const [opened, setOpened] = useState<ModalOpenedTy>(true)

    const resizeLeftMenu = useCallback(() => {
        setOpened(window.innerWidth > 940 ? true : null)
    }, [])

    useEffect(() => {
        resizeLeftMenu()
        window.addEventListener('resize', resizeLeftMenu)
        return () => {
            window.removeEventListener('resize', resizeLeftMenu)
        }
    }, [resizeLeftMenu])

    return { opened, setOpened, resizeLeftMenu }
}


export default LeftMenu