import { CurrentPagePayload } from '../../../utils/reducers/globals/currentPage'
import { RouteChild, Routes } from '../../CreateRoutering/types'

export const getParentMenuItems = (menuItems: Routes, currentPage: CurrentPagePayload) => {
    if (!currentPage) {
        return
    }
    for (const key in menuItems) {
        const item = menuItems[key]
        if (currentPage.routes[0] === item.link) {
            return checkIfHaveChilds(menuItems, key, item, currentPage)
        }
    }
}
export const checkIfHaveChilds = (menuItems: Routes, position: string, item: RouteChild, currentPage: CurrentPagePayload) => {
    if (item.childs) {
        return getChildMenuItem(item, currentPage)
    } else {
        return menuItems[parseInt(position)].text
    }
}
export const getChildMenuItem = (item: RouteChild, currentPage: CurrentPagePayload) => {
    if (!currentPage) {
        return
    }
    for (const key in item.childs) {
        if (currentPage.routes[0] === item.link) {
            return item.childs[parseInt(key)].text
        }
    }
}