import { faChevronRight} from '@fortawesome/pro-light-svg-icons'
import { useMemo } from 'react'
import useTwinTranslation from '../../../utils/hooks/useTwinTranslation'
import { ComponentWithPermissions } from '../../CreateRoutering/types'
import TwinIcon from '../../TwinIcon'
import { TFSChangePathTy } from '../types'
import { prepareBreadcrumb } from './functions'
import { TFSEditNameButton, TFSBreadcrumbDecider, TFSDeleteFolderButton } from './Subcomponents'

type TFSBreadcrumbProps = TFSBreadcrumbLogicProps  & ComponentWithPermissions & {
    changePath: TFSChangePathTy
    deleteCurrentFolder: () => void
    editCurrentFolder: (folderName: string) => void
}

const TFSBreadcrumb: React.FC<TFSBreadcrumbProps> = ({ path, changePath, deleteCurrentFolder, editCurrentFolder,  userPermissions }) => {
    const {t} = useTwinTranslation()
    const { breadcrumbsPrepared } = useTFSBreadcrumbLogic({ path })
    const renderThis: JSX.Element[] = []
    let folderName = ''
    for (const key in breadcrumbsPrepared) {
        const isLast = parseInt(key) >= breadcrumbsPrepared.length - 1
        folderName = breadcrumbsPrepared[key].name || t('base', 'Base')
        const Component = TFSBreadcrumbDecider({isLast})
        renderThis.push(<Component item={{ ...breadcrumbsPrepared[key], name: folderName}} key={key} changePath={changePath} />)
        if (!isLast) {
            renderThis.push(<TwinIcon icon={faChevronRight} key={key+'icon'} className='mx-12' />)
        }
    }
    return (
        <div className='mt-32 bg-gray-F7 flex items-center tfs_breadcrumb'>
            <div className='tfs_breadcrumb_inner'>
                {renderThis}
            </div>
            <div className='ml-auto flex items-center'>
                <TFSEditNameButton userPermissions={userPermissions} breadcumbLength={breadcrumbsPrepared.length} folderName={folderName} editCurrentFolder={editCurrentFolder}></TFSEditNameButton>
                <TFSDeleteFolderButton userPermissions={userPermissions} deleteCurrentFolder={deleteCurrentFolder} folderName={folderName}></TFSDeleteFolderButton>
            </div>
        </div>
    )
}

interface TFSBreadcrumbLogicProps {
    path: string
}

const useTFSBreadcrumbLogic = ({ path }: TFSBreadcrumbLogicProps) => {
    const breadcrumbsPrepared = useMemo(() => prepareBreadcrumb(path), [path])

    return {breadcrumbsPrepared}
}





export default TFSBreadcrumb