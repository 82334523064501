import { ConfigPaymentTypeModelType } from '@teinor/erp/types/company/accounting/config/configPaymentType'
import { dictionaryComplexFromJsonArr } from '../../globals/dictionary'

export interface ConfigPaymentTypesAction {
    type: 'CHANGE_CONFIGPAYMENTTYPE'
    payload: ConfigPaymentTypeModelType[]
}
export type ConfigPaymentTypePayload = { [id: number]: ConfigPaymentTypeModelType } | null

export default function configPaymentTypes(state: ConfigPaymentTypePayload = null, action: ConfigPaymentTypesAction): ConfigPaymentTypePayload {
    switch (action.type) {
        case 'CHANGE_CONFIGPAYMENTTYPE':
            const configPaymentTypes = dictionaryComplexFromJsonArr(action.payload || [])
            return state = {...configPaymentTypes}
        default:
            return state
    }
}