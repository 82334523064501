import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationsEs from './locales/es/translations.json'
import translationsEn from './locales/en/translations.json'

const resources = {
    es: {
        translation: translationsEs
    },
    en: {
        translation: translationsEn
    }
}
i18n
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'es',
        supportedLngs: ['es', 'en'],
        lng: 'es',
        load: 'currentOnly',
        ns: ['translation'],
        initImmediate: false,
    });

export default i18n