import { faCircleInfo, faSpinnerThird } from '@fortawesome/pro-light-svg-icons'
import React from 'react'
import { Modify } from '../../utils/globals/types'
import TwinIcon from '../TwinIcon'
import './button.sass'
import TwinTrans from '../TwinTrans'


interface ButtonInfoLeftBaseProps {
    buttonIsDisabled: boolean
    infoMessage: string
    children?: React.ReactNode
}

const ButtonInfoLeftBase: React.FC<ButtonInfoLeftBaseProps> = ({buttonIsDisabled, infoMessage, children}) => {
    return (
        <div className='flex items-center'>
            {buttonIsDisabled &&
                <div className='flex items-center relative button_info_icon '>
                    <div className='button_primary_info_left'>{infoMessage}</div>
                    <TwinIcon icon={faCircleInfo} fontSize={18} className='mr-10 cursor-pointer text-red-BA' />
                </div>
            }
            {children}
        </div>
    )
}

interface ButtonPrimaryInfoLeftProps extends ButtonInfoLeftBaseProps {
    onClick: () => Promise<void>
    textButton: string
    className?: string
}


export const ButtonPrimaryInfoLeft: React.FC<ButtonPrimaryInfoLeftProps> = ({ textButton, className, onClick, ...rest }) => {
    return (
        <ButtonInfoLeftBase {...rest}>
            <ButtonPrimary onClick={onClick} disabled={rest.buttonIsDisabled} className={className || ''}>{textButton}</ButtonPrimary>
        </ButtonInfoLeftBase>
    )
}

interface ButtonPrimaryOrLoaderInfoLeftProps extends ButtonInfoLeftBaseProps {
    onClick: () => void
    textButton: string
    loading: boolean
    className?: string
}

export const ButtonPrimaryOrLoaderInfoLeft: React.FC<ButtonPrimaryOrLoaderInfoLeftProps> = ({ textButton, className, onClick,  loading, ...rest }) => {
   
    return (
        <ButtonInfoLeftBase {...rest}>
            <ButtonDisabledOrLoader buttonIsDisabled={rest.buttonIsDisabled} textButton={textButton} loading={loading} onClick={onClick} className={className || ''} />
        </ButtonInfoLeftBase>            
    )
}
interface ButtonRedInfoLeftProps extends ButtonInfoLeftBaseProps {
    onClick: () => Promise<void>
    textButton: string
    className?: string
}

export const  ButtonRedInfoLeft: React.FC< ButtonRedInfoLeftProps> = ({ textButton, className, onClick, ...rest}) => {
    return (
        <ButtonInfoLeftBase {...rest}>
            < ButtonRed onClick={onClick} disabled={rest.buttonIsDisabled} className={className || ''}>{textButton}</ButtonRed>
        </ButtonInfoLeftBase>            
    )
}
interface ButtonOrLoaderProps {
    buttonIsDisabled: boolean
    textButton: string
    className?: string
    onClick?:()=>void
}

export const ButtonOrLoader: React.FC<ButtonOrLoaderProps> = ({ buttonIsDisabled, textButton, className, onClick  }) => {
    if (buttonIsDisabled) {
        return <div className={className}><TwinIcon icon={faSpinnerThird} spin={true} size='2x' className={'block ml-auto text-green-43'} /></div>
    }
    return <ButtonPrimary type='submit' className={'ml-auto regular14 ' + (className || '')} {...{onClick}}>{textButton}</ButtonPrimary>
}
interface ButtonDisabledOrLoaderProps {
    buttonIsDisabled?: boolean
    loading: boolean
    textButton: string
    className?: string
    onClick?: (e: React.MouseEvent<HTMLElement>) => void
}

export const ButtonDisabledOrLoader: React.FC<ButtonDisabledOrLoaderProps> = ({ loading, buttonIsDisabled, textButton, className, onClick}) => {
    const text = loading ? <TwinTrans transKey='processingDotsv2' >Procesando...</TwinTrans> : textButton
    return <ButtonPrimary type='submit' className={'ml-auto ' + (className || '')} disabled={loading || buttonIsDisabled} onClick={onClick}>{text}</ButtonPrimary>
}

type ButtonProps  = Modify<React.HTMLProps<HTMLButtonElement>, {
    type?: 'button' | 'reset' | 'submit'
}>

interface ButtonPrimaryProps extends ButtonProps {}

export const ButtonPrimary: React.FC<ButtonPrimaryProps> = ({ className, ...rest }) => {
    return (
        <Button className={'button_primary ' + (className || '')} {...rest} />
    )
}
interface ButtonSecondaryProps extends ButtonProps {}

export const ButtonSecondary: React.FC<ButtonSecondaryProps> = ({ className, ...rest }) => {
    return (
        <Button className={'button_secondary  ' + (className || '')} {...rest} />
    )
}

interface ButtonRedProps extends ButtonProps {}

export const ButtonRed: React.FC<ButtonRedProps> = ({ className, ...rest }) => {
    return (
        <Button className={' button_red ' + (className || '')} {...rest} />
    )
}

interface ButtonRedSecondaryProps extends ButtonProps {}

export const ButtonRedSecondary: React.FC<ButtonRedSecondaryProps> = ({className, ...rest}) => {
    return (
        <Button className={'button_red_secondary ' + (className || '')} {...rest} />
    )
}

const Button: React.FC<ButtonProps> = ({ className, children, ...rest }) => {
    return (
        <button className={'button ' + (className || '')} {...rest}>
            {children}
        </button>
    );
}

export default Button