import { Fragment, useEffect } from 'react';
import { Route, Routes as RouteParent } from 'react-router-dom';
import { reverseArray } from '../../utils/globals/array';
import { changeCurrentPage } from '../../utils/reducers/reduxDispatch';
import { joinAndRemoveDuplicatedBars } from './functions';
import { RouteChild, Routes } from './types';
import ModalDemo from './ModalDemo';
import { ErpSingleModule } from '@teinor/erp/modules';


interface CreateRouteringProps {
    routes: Routes
}

const CreateRoutering: React.FC<CreateRouteringProps> = ({ routes }) => {
    const myRoutes = GenerateRoutering(reverseArray(routes), '')
    return (
        <RouteParent>
            {myRoutes}
        </RouteParent>
    );
}

const GenerateRoutering = (routes: Routes, parentsLink: string, treeLink: string[] = []) => {
    const result : JSX.Element[] = []
    for (const route of routes) {
        const link = joinAndRemoveDuplicatedBars(parentsLink, route.link)
        if (!route.treeLink) {
            route.treeLink = treeLink
        }
        result.push(...GenerateRouteringChild({ ...route, link, treeLink: [...route.treeLink, route.link] }))
    }
    return result
}

const GenerateRouteringChild = (route: RouteChild) => {
    let result: JSX.Element[] = []
    if (route.childs) {
        result = [...result, ...GenerateRoutering(route.childs, route.link, route.treeLink)]
    }
    let extraParams : {[key: string]: any }= {}
    if ('permissions' in route) {
        extraParams.userPermissions = route.componentPermissions
    }
    if (route.component) {
        // @ts-ignore
        result.push(<Route path={route.link} key={route.link} element={<ChangeCurrentPage currentPage={route.treeLink} moduleInDemo={route.moduleInDemo}><route.component {...extraParams} /></ChangeCurrentPage>} />)
    }
    return result
}


interface ChangeCurrentPageProps {
    currentPage: string[]
    children?: React.ReactNode
    moduleInDemo?: ErpSingleModule
}

const ChangeCurrentPage: React.FC<ChangeCurrentPageProps> = ({ currentPage, children, moduleInDemo }) => {
    useEffect(() => {
        changeCurrentPage(currentPage)
    }, [currentPage])
    return (<Fragment>{moduleInDemo ? <ModalDemo moduleInDemo={moduleInDemo} /> : null}{children}</Fragment>)
}


export default CreateRoutering;