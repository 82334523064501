import { faChevronRight, faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import Calendar, { CalendarProps, OnChangeDateCallback } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { getLang } from '../../utils/globals';
import TwinIcon from '../TwinIcon';
import { twinCalendarMonthYearFormat } from './functions';
import './twincalendar.sass'
interface TwinCalendarProps extends CalendarProps {
    onChange: OnChangeDateCallback
}

const TwinCalendar: React.FC<TwinCalendarProps> = ({...rest }) => {
    const lang = getLang()
    return (
        <Calendar 
            locale={lang || 'es'}
            {...rest}
            closeCalendar={true}
            next2Label={null} 
            prev2Label={null}
            nextLabel={<TwinIcon icon={faChevronRight}/>}
            prevLabel={<TwinIcon icon={faChevronLeft}/>}
            formatMonthYear={twinCalendarMonthYearFormat}
        />
    )
}

export default TwinCalendar