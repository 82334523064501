import { TaskBagOfHoursModelType } from '@teinor/erp/types/company/task/taskBagOfHours'
import { useParams } from 'react-router'
import { ComponentWithPermissions } from '../../../../../../../../../baseComponents/CreateRoutering/types'
import TwinTable from '../../../../../../../../../baseComponents/TwinTable'
import BaseScreenTableInner from '../../../../../../../../../baseComponents/TwinTable/BaseScreenTableInner'
import { TableComponentBaseScreenInnerProps } from '../../../../../../../../../baseComponents/TwinTable/BaseScreenTableInner/types'
import { CustomRenderBoolean, CustomRenderDate, CustomRenderDisplayHoursMinsSecsBySecs, CustomRenderString, EditRow } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import ModalCreateCustomerB2BBagOfHours from './ModalCreateCustomerB2BBagOfHours'
import ModalEditTaskBagOfHours from '../../../../../../../../../specificComponents/ModalEditTaskBagOfHours'

interface CustomerB2BBagsOfHoursProps extends ComponentWithPermissions {
}

const CustomerB2BBagsOfHours: React.FC<CustomerB2BBagsOfHoursProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()
    const params = useParams<{ id: string }>()
    const id = params.id
    const pageData = {
        title: t('bagsOfHours', 'Bolsas de horas'),
        newText: t('addBagOfHours', 'Añadir bolsa de horas'),
    }

    return (
        <BaseScreenTableInner TableComponent={TableCustomerB2BBagsOfHours} extraWhereParams={{ 'CustomerId': id }} pageData={pageData} CreateModalComponent={ModalCreateCustomerB2BBagOfHours} EditModalComponent={ModalEditTaskBagOfHours} userPermissions={userPermissions} />
    )
}

type TableCustomerB2BBagsOfHoursProps = TableComponentBaseScreenInnerProps & {
}

const TableCustomerB2BBagsOfHours: React.FC<TableCustomerB2BBagsOfHoursProps> = ({ setTableInstance, extraWhereParams, setOpened }) => {
    const { t } = useTwinTranslation()
    const columns: ColumnTableSchema<TaskBagOfHoursModelType> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('id', 'ID'),
            width: 75
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            },
            searchKey: 'TaskBagOfHours.name'
        },
        {
            id: 'CustomerProject',
            dataKey: 'CustomerProjectId',
            label: t('project', 'Proyecto'),
            customRender: (parameterValue, allRowData) => <CustomRenderString value={allRowData?.CustomerProject?.name} />
        },
        {
            id: 'timeLimit',
            dataKey: 'timeLimit',
            label: t('timeLimit', 'Tiempo límite'),
            customRender: (parameterValue) => <CustomRenderDisplayHoursMinsSecsBySecs value={parseInt(parameterValue)} />
        },
        {
            id: 'remainingTime',
            dataKey: 'remainingTime',
            label: t('remainingTime', 'Tiempo restante'),
            customRender: (parameterValue) => <CustomRenderDisplayHoursMinsSecsBySecs value={parseInt(parameterValue)} />
        },
        {
            id: 'expireDate',
            dataKey: 'expireDate',
            label: t('expireDate', 'Fecha de vencimiento'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />
        },
        {
            id: 'active',
            dataKey: 'active',
            label: t('active', 'Activo'),
            customRender: (parameterValue) => <CustomRenderBoolean value={String(parameterValue) === 'true'} width={45} />,
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            customRender: (parameterValue, allRowData) => <EditRow key={'edit' + parameterValue} {...{ parameterValue, allRowData }} />
        },
    ]
    return (
        <TwinTable name='CustomerB2BBagOfHours' columns={columns} defaultOrder={{ order: 'desc', defaultOrderField: 'createdAt' }} getDataFrom='/api/app/task/bagOfHours/getAllTaskBagsOfHours'
            onRowClick={(_id, rowData) => setOpened({
                type: 'edit',
                allRowData: rowData
            })}
            setTableInstance={setTableInstance} extraWhereParams={extraWhereParams} />
    )
}

export default CustomerB2BBagsOfHours