import { CustomerModelType } from '@teinor/erp/types/company/customer'
import { customerTypes } from '@teinor/erp/types/company/customer/customerTypes'
import { useLocation } from 'react-router'
import { ComponentWithPermissions } from '../../../../../../baseComponents/CreateRoutering/types'
import { permissionCheck } from '../../../../../../baseComponents/PermissionChecker/function'
import { TwinTableLink } from '../../../../../../baseComponents/TwinTable'
import BaseScreenTable from '../../../../../../baseComponents/TwinTable/BaseScreenTable'
import { TableComponentProps } from '../../../../../../baseComponents/TwinTable/BaseScreenTable/types'
import { EditRow, DeleteRow } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import ModalCreateCustomer from './ModalCreateCustomer'

interface CustomersProps extends ComponentWithPermissions {}

const Customers: React.FC<CustomersProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()

    const pageData = {
        title: t('customers', 'Clientes'),
        newText: t('newCustomer', 'Nuevo cliente'),
    }

    return (
        <BaseScreenTable TableComponent={TableCustomers} CreateModalComponent={ModalCreateCustomer} pageData={pageData} userPermissions={userPermissions} />
    )
}

interface TableCustomersProps extends TableComponentProps {}

const TableCustomers: React.FC<TableCustomersProps> = ({ userPermissions, setTableInstance, setOpened }) => {
    const { t } = useTwinTranslation()
    const location = useLocation().pathname
    const columns: ColumnTableSchema<CustomerModelType> = [
        {
            id: 'code',
            dataKey: 'code',
            label: t('code', 'Código'),
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('customerCode', 'Código de cliente') }
            }
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            }
        },
        {
            id: 'surname',
            dataKey: 'surname',
            label: t('surname', 'Apellidos'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('surname', 'Apellidos') }
            }
        },
        {
            id: 'email',
            dataKey: 'email',
            label: t('email', 'Email'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            }
        },
        {
            id: 'phone',
            dataKey: 'phone',
            label: t('phone', 'Teléfono'),
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('phone', 'Teléfono') }
            }
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            customRender: (parameterValue, allRowData) => <EditRow key={'edit' + parameterValue} {...{ parameterValue, allRowData }} />
        },
        {
            id: 'delete',
            dataKey: 'id',
            label: '',
            width: 50,
            customRender: (parameterValue, allRowData) => <DeleteRow key={'delete' + parameterValue} {...{ parameterValue, allRowData }} onClick={(_idk, allRowData) => setOpened({ type: 'delete', allRowData })} />,
            havePermission: permissionCheck(userPermissions, 'delete')
        }
    ]
    return (
        <TwinTableLink  createRowDataLink={[location, ['$id', 'main']]} name='customers' columns={columns} getDataFrom='/api/app/customer/getAllCustomers' extraWhereParams={{ 'customer_type': customerTypes['B2C'] }}  setTableInstance={setTableInstance} />
    )
}

export default Customers