import { WorkingCenterTypeModelType } from '@teinor/erp/types/company/workingCenter/workingCenterType'
import { dictionaryComplexFromJsonArr } from '../../globals/dictionary'

export interface WorkingCenterTypesAction {
    type: 'CHANGE_WORKINGCENTERTYPE'
    payload: WorkingCenterTypeModelType[] 
}
export type DictionaryWorkingCenter = {[id: number]: WorkingCenterTypeModelType}
export type WorkingCenterTypePayload = null | DictionaryWorkingCenter

export default function workingCenterType(state: WorkingCenterTypePayload = null, action: WorkingCenterTypesAction): DictionaryWorkingCenter | null {
    switch (action.type) {
        case 'CHANGE_WORKINGCENTERTYPE':
            const dictionaryWorkingCenterType = dictionaryComplexFromJsonArr(action.payload || [])
            return state = dictionaryWorkingCenterType
        default:
            return state
    }
}