import { UserLogModelType } from '@teinor/erp/types/environment/userLog'
import { ComponentWithPermissions } from '../../../../../../baseComponents/CreateRoutering/types'
import TwinTable from '../../../../../../baseComponents/TwinTable'
import { BaseScreenTableSimple } from '../../../../../../baseComponents/TwinTable/BaseScreenTable'
import { TableComponentProps } from '../../../../../../baseComponents/TwinTable/BaseScreenTable/types'
import { CustomRenderDateHours, CustomRenderString } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'

interface UsersLogsProps extends ComponentWithPermissions { }

const UsersLogs: React.FC<UsersLogsProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()

    const pageData = {
        title: t('usersLogs', 'Registro de usuarios'),
        newText: ''
    }

    return (
        <BaseScreenTableSimple TableComponent={TableUsersLogs} pageData={pageData} userPermissions={userPermissions} />
    )
}

interface TableUsersLogsProps extends TableComponentProps { }

const TableUsersLogs: React.FC<TableUsersLogsProps> = ({ setTableInstance }) => {
    const { t } = useTwinTranslation()
 
    const columns: ColumnTableSchema<UserLogModelType> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('id', 'ID'),
            sortable: true,
            width: 100
        },
        {
            id: 'ip',
            dataKey: 'ip',
            label: t('ip', 'IP'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('ip', 'IP') }
            },
        },
        {
            id: 'user',
            dataKey: 'UserId',
            label: t('user', 'Usuario'),
            searchKey: 'User.name',
            customRender: (parameterValue, allRowData) => <CustomRenderString value={allRowData?.User?.name + ' ' + (allRowData?.User?.surname || '')} />,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('user', 'Usuario') }
            }
        },
        {
            id: 'email',
            dataKey: 'User',
            label: t('email', 'Email'),
            searchKey: 'User.email',
            customRender: (parameterValue, allRowData) => <CustomRenderString value={allRowData?.User?.email} />,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('email', 'Email') }
            }
        },
        {
            id: 'createdAt',
            dataKey: 'createdAt',
            label: t('createdDate', 'Fecha de creación'),
            customRender: (parameterValue) => <CustomRenderDateHours value={parameterValue} />,
            sortable: true
        },
    ]
    return (
        <TwinTable name='usersLogs' columns={columns} getDataFrom='/api/app/config/getAllUserLogsByCompany' setTableInstance={setTableInstance}/>
    )
}

export default UsersLogs