import { TwinDictionary } from '../../../utils/globals/dictionary'
import { prepareForSearch, stringMatchWithSearch } from '../../../utils/globals/search'
import { ItemCustomSelectDropDownRow } from './types'

export const filterCustomSelectItems = (searchValue: string, items: TwinDictionary, fieldName = 'name') => {
    const filteredItems: TwinDictionary = {}
    for (const key in items) {
        const item = items[key]?.[fieldName]
        if (stringMatchWithSearch(searchValue, item)) {
            filteredItems[key] = items[key]
        }
    }
    return filteredItems
}

export const sortItems = (items: TwinDictionary, fieldName = 'name') => {
    const itemsArr: ItemCustomSelectDropDownRow[] = []
    for (const key in items) {
        itemsArr.push({ value: key, text: items[key]?.[fieldName] })
    }
    itemsArr.sort((a, b) => {
        if (typeof a.text === 'string' && typeof b.text === 'string') {
            return prepareForSearch(a.text) > prepareForSearch(b.text) ? 1 : -1
        }
        return a.text > b.text ? 1 : -1
    })
    return itemsArr
}

export const sortItemsNumbers = (items: TwinDictionary, fieldName = 'name') => {
    const itemsArr: ItemCustomSelectDropDownRow[] = []
    for (const key in items) {
        itemsArr.push({ value: key, text: parseFloat(items[key]?.[fieldName]) })
    }
    itemsArr.sort((a, b) => {
        if (typeof a.text === 'string' && typeof b.text === 'string') {
            return prepareForSearch(a.text) > prepareForSearch(b.text) ? 1 : -1
        }
        return a.text > b.text ? 1 : -1
    })
    return itemsArr
}