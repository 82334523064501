interface Feature {
    name: string
    values: { [FeatureValueId: string] : string}
}
export type Features = { [FeatureId: string]: Feature }

export interface FeaturesAction {
    type: 'CHANGE_FEATURES'
    payload: Features
}

export type FeaturesPayload = Features | null

export default function features(state: FeaturesPayload = null, action: FeaturesAction): Features | null {
    switch (action.type) {
        case 'CHANGE_FEATURES':
            return state = action.payload
        default:
            return state
    }
}