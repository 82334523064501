import { CurrentPagePayload } from '../../../../utils/reducers/globals/currentPage'
import { Routes } from '../../../CreateRoutering/types'

export const getChildrenMenuItems = (menuItems: Routes, currentPage: CurrentPagePayload) => {
    for (const key in menuItems) {
        const item = menuItems[key]
        if (currentPage?.routes[0] === item.link) {
            return parseInt(key)
        }
    }
    return 0
}