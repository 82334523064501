import { memo, useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ScrollEventData } from 'react-virtualized'
import { defaultRowRenderer, TableRowRenderer } from 'react-virtualized/dist/es/Table'
import { memoChecker } from '../../utils/globals/components'
import { twinFetchPostJSON } from '../../utils/globals/data'
import { TwinDictionary } from '../../utils/globals/dictionary'
import { createLink, CreateLinkFunctionType, CreateLinkProps } from '../../utils/globals/link'
import useConfigColumns, { SelectedColumnsAndFilters } from '../../utils/hooks/useConfigColumns'
import useTwinTranslation from '../../utils/hooks/useTwinTranslation'
import { ButtonConfigColumnsWithModal } from '../ModalsLayouts/ModalConfigColumnsTabs'
import { buildOrder, buildWhereBySearchbars, getNextOrder, saveTableData } from './functions'
import { DefaultOrder, ParseDataFunc, SetTableInstance, TableConfiguration } from './types'
import { VirtualTableListingStateLess } from './VirtualTableListing'
import { SearchBarTable } from './VirtualTableListing/Subcomponents'
import { ColumnConfiguration } from './VirtualTableListing/Subcomponents/types'
import { OnRowClick } from './VirtualTableListing/types'

type TableProps = TableLogicProps & {
    children?: React.ReactNode
    name: string
    hideLoadFiltersButton?: boolean
    hideChangeColumnsButton?: boolean
}

const DEFAULT_ROWHEIGHT_PX = 48
const DEFAULT_LIMIT_ROWS = 30

const TwinTable: React.FC<TableProps> = memo(({ name, columns, getDataFrom, defaultOrder, extraWhereParams, rowRenderer, setTableInstance, onRowClick, children, parseData, maxOffset, hideLoadFiltersButton, hideChangeColumnsButton }) => {
    const { selectedColumnsAndFilters, setSelectedColumnsAndFilters, memorizedColumns } = useConfigColumns({ columns, name })
    const { tableData, tableConfiguration, changeGeneralSearchbar, headerChanges, changeOffsetVirtualTable } = useTableLogic({ columns, getDataFrom, defaultOrder, extraWhereParams, setTableInstance, parseData, maxOffset, filters: selectedColumnsAndFilters.filters, order: selectedColumnsAndFilters.order })
    const { t } = useTwinTranslation()
    return (
        <div className={'flex flex-col flex-auto h-1'}>
            <div className='table_header flex justify-between items-center mb-38'>
                <div className='table_searcher_header'>
                    <SearchBarTable changeGeneralSearchbar={changeGeneralSearchbar} placeholder={t('searchDots', 'Buscar...')} />
                </div>
                <div className='right_side grid_header_table items-center'>
                    {children}
                    {!hideChangeColumnsButton ?
                        <div>
                            <ButtonConfigColumnsWithModal name={name} columns={columns} setSelectedColumnsAndFilters={setSelectedColumnsAndFilters} selectedColumnsAndFilters={selectedColumnsAndFilters} hideLoadFiltersButton={hideLoadFiltersButton} />
                        </div>
                        : null
                    }
                </div>
            </div>
            <div className='flex-auto'>
                <VirtualTableListingStateLess {...{ columns: memorizedColumns, tableConfiguration, headerChanges, rowRenderer, onRowClick }} setSelectedColumnsAndFilters={setSelectedColumnsAndFilters} tableData={tableData} onScroll={changeOffsetVirtualTable} rowHeight={DEFAULT_ROWHEIGHT_PX} headerHeight={48} name={name} selectedFilters={selectedColumnsAndFilters.filters} selectedOrder={selectedColumnsAndFilters.order} selectedFilterId={selectedColumnsAndFilters.id} />
            </div>
        </div>
    )
}, (oldProps, nextProps) => memoChecker(oldProps, nextProps, ['columns', 'defaultOrder', 'extraWhereParams', 'getDataFrom', 'setTableInstance', 'rowRenderer', 'children']))

export const TwinSimpleTable: React.FC<TableProps> = memo(({ columns, getDataFrom, defaultOrder, extraWhereParams, rowRenderer, setTableInstance, onRowClick, children, parseData, maxOffset }) => {
    const { tableData, tableConfiguration, headerChanges, changeOffsetVirtualTable } = useTableLogic({ columns, getDataFrom, defaultOrder, extraWhereParams, setTableInstance, parseData, maxOffset })
    return (
        <div className={'flex flex-col flex-auto h-1'}>
            <div className='flex-auto'>
                <VirtualTableListingStateLess {...{ columns: columns, tableConfiguration, headerChanges, rowRenderer, onRowClick }} setSelectedColumnsAndFilters={() => {}} tableData={tableData} onScroll={changeOffsetVirtualTable} rowHeight={DEFAULT_ROWHEIGHT_PX} headerHeight={48} name={''} />
            </div>
        </div>
    )
}, (oldProps, nextProps) => memoChecker(oldProps, nextProps, ['columns', 'defaultOrder', 'extraWhereParams', 'getDataFrom', 'setTableInstance', 'rowRenderer', 'children']))

interface TableLogicProps {
    columns: ColumnConfiguration[]
    getDataFrom: string
    defaultOrder?: DefaultOrder
    extraWhereParams?: TwinDictionary
    rowRenderer?: TableRowRenderer
    onRowClick?: OnRowClick
    setTableInstance?: SetTableInstance
    parseData?: ParseDataFunc
    maxOffset?: number
    filters?: SelectedColumnsAndFilters['filters']
    order?: SelectedColumnsAndFilters['order']
}

export const useTableLogic = ({setTableInstance, defaultOrder, getDataFrom, columns, extraWhereParams, parseData, maxOffset, filters, order}: TableLogicProps) => {
    const [tableConfiguration, setTableConfiguration] = useState<TableConfiguration>({
        generalSearchbar: '',
        order: {},
        offset: 0,
        singleFieldsSearch: {},
        petitionNumber: 0
    })
    const [tableData, setTableData] = useState<{ [fieldName: string]: any }[] | null>(null)
    
    const changeGeneralSearchbar = useCallback((value: string) => {
        setTableConfiguration((oldTableConfiguration) => {
            const copyOld = JSON.parse(JSON.stringify(oldTableConfiguration))
            return {
                ...copyOld,
                offset: 0,
                generalSearchbar: value
            }
        })
    }, [setTableConfiguration])

    const changeOrder = useCallback((fieldName: string) => {
        setTableConfiguration((oldTableConfiguration) => {
            const copyOld = JSON.parse(JSON.stringify(oldTableConfiguration))
            const newOrder = getNextOrder(fieldName, copyOld.order, defaultOrder?.order || 'desc')
            return {
                ...copyOld,
                offset: 0, 
                order: {...newOrder}
            }
        })
    }, [setTableConfiguration, defaultOrder?.order])
    
    const changeOffset = useCallback((offset: number) => {
        setTableConfiguration((oldTableConfiguration) => {
            const copyOld = JSON.parse(JSON.stringify(oldTableConfiguration))
            return {
                ...copyOld,
                offset
            }
        })
    }, [setTableConfiguration])
    
    const changeSingleField = useCallback((fieldName: string, value: string) => {
        setTableConfiguration((oldTableConfiguration) => {
            const copyOld = JSON.parse(JSON.stringify(oldTableConfiguration))
            return {
                ...copyOld,
                offset: 0, 
                singleFieldsSearch: {
                    ...copyOld.singleFieldsSearch,
                    [fieldName]: value
                }
            }
        })
    }, [setTableConfiguration])
    
    const changeOffsetVirtualTable = useCallback((params: ScrollEventData) => {
        const nitems = params.clientHeight / DEFAULT_ROWHEIGHT_PX
        const sub = DEFAULT_ROWHEIGHT_PX * nitems + params.scrollTop
        const actual = sub / DEFAULT_ROWHEIGHT_PX
        if (tableData) {
            const should_update = tableData.length - 10
            if ((maxOffset === undefined || maxOffset > tableConfiguration.offset ) && should_update <= actual && tableData.length === tableConfiguration.offset + DEFAULT_LIMIT_ROWS) {
                changeOffset(tableConfiguration.offset + DEFAULT_LIMIT_ROWS)
            }
        }
    }, [tableConfiguration.offset, tableData, changeOffset, maxOffset])

    const myColumnsJustForEffect = JSON.stringify(columns)
    const myExtraParamsJustForEffect = JSON.stringify(extraWhereParams)
    const myTableConfigurationJustForEffect = JSON.stringify(tableConfiguration)
    const myFiltersJustForEffect = JSON.stringify(filters || {})
    const myOrderJustForEffect = JSON.stringify(order || {})

    const updateDataTable = useCallback(async () => {
        const myColumns = JSON.parse(myColumnsJustForEffect)
        const myExtraWhereParams = JSON.parse(myExtraParamsJustForEffect || '{}')
        const myTableConfiguration = JSON.parse(myTableConfigurationJustForEffect || '{}')
        const myFiltersParsed = JSON.parse(myFiltersJustForEffect || '{}')
        const myOrderParsed = JSON.parse(myOrderJustForEffect || '{}')
        const whereBySearchBars = buildWhereBySearchbars(
            { generalSearchbar: myTableConfiguration.generalSearchbar, singleFieldsSearch: myTableConfiguration.singleFieldsSearch, filtersFieldsSearch: myFiltersParsed },
            myColumns
        )
    
        const orderBuiled = buildOrder({ ...myOrderParsed, ...myTableConfiguration.order }, columns)

        let apiTableData = await twinFetchPostJSON(getDataFrom, {
            limit: DEFAULT_LIMIT_ROWS,
            offset: myTableConfiguration.offset,
            where: {
                ...whereBySearchBars,
                ...myExtraWhereParams
            },
            order: orderBuiled.length ? orderBuiled : [[defaultOrder?.defaultOrderField || 'id', defaultOrder?.order || 'desc' ]] ,
        })

        if (parseData) {
            apiTableData = parseData(apiTableData)
        }

        saveTableData(apiTableData, myTableConfiguration.offset, setTableData)

    }, [myTableConfigurationJustForEffect, getDataFrom, myColumnsJustForEffect, myExtraParamsJustForEffect, setTableData, defaultOrder?.defaultOrderField, defaultOrder?.order, parseData, myFiltersJustForEffect, myOrderJustForEffect, columns])
    
    const getTableDataFromStart = useCallback(() => {
        setTableConfiguration((oldTableConfiguration) => {
            return JSON.parse(JSON.stringify({
                ...oldTableConfiguration,
                petitionNumber: oldTableConfiguration.petitionNumber + 1,
                offset: 0
            }))
        })
    }, [setTableConfiguration])

    useEffect(() => {
        const tableBody = document.querySelector('.ReactVirtualized__Table__Grid');
        if (tableBody && tableConfiguration.offset === 0) {
            tableBody.scrollTop = 0
        }
    }, [tableConfiguration.offset])

    useEffect(() => {
        updateDataTable()
    }, [updateDataTable])

    useEffect(() => {
        setTableInstance?.({
            getTableDataFromStart
        })
    }, [setTableInstance, getTableDataFromStart])

    return { tableData, tableConfiguration, changeGeneralSearchbar, changeOffsetVirtualTable, headerChanges: {changeOrder, changeSingleField} }
}

export interface TwinTableLinkProps extends TableProps {
    createRowDataLink: CreateLinkProps
}

export const TwinTableLink: React.FC<TwinTableLinkProps> = ({ createRowDataLink, children, ...rest }) => {
    const createRowDataLinkStr = JSON.stringify(createRowDataLink)
    const createLinkCallback: CreateLinkFunctionType = useCallback((allRowData) => {
        return createLink(JSON.parse(createRowDataLinkStr), allRowData)
    }, [createRowDataLinkStr])
    return (
        <TwinTableLinkBase createRowDataLinkFunction={createLinkCallback} {...rest}>
            {children}
        </TwinTableLinkBase>
    )
 }

 export interface TwinTableLinkBaseProps extends TableProps {
    createRowDataLinkFunction: CreateLinkFunctionType
}
export const TwinTableLinkBase: React.FC<TwinTableLinkBaseProps> = ({ createRowDataLinkFunction, children, ...rest }) => {
    const rowRenderer = rest.rowRenderer
    const memorizedRowRenderer: TableRowRenderer = useCallback((props) => {
        if (Object.keys(props.rowData).length) {
            return <Link to={createRowDataLinkFunction(props.rowData)} key={props.key}>{rowRenderer ? rowRenderer(props) : defaultRowRenderer(props)}</Link>
        }
        return defaultRowRenderer(props)
    }, [createRowDataLinkFunction, rowRenderer])
    return (<TwinTable {...rest} rowRenderer={memorizedRowRenderer} >{children}</TwinTable>)
}

export const TwinTableLinkSimpleTable: React.FC<TwinTableLinkProps> = ({ createRowDataLink, children, ...rest }) => {
    const createRowDataLinkStr = JSON.stringify(createRowDataLink)
    const createLinkCallback: CreateLinkFunctionType = useCallback((allRowData) => {
        return createLink(JSON.parse(createRowDataLinkStr), allRowData)
    }, [createRowDataLinkStr])
    return (
        <TwinTableLinkSimpleTableBase createRowDataLinkFunction={createLinkCallback} {...rest}>
            {children}
        </TwinTableLinkSimpleTableBase>
    )
 }

export const TwinTableLinkSimpleTableBase: React.FC<TwinTableLinkBaseProps> = ({ createRowDataLinkFunction, children, ...rest }) => {
    const rowRenderer = rest.rowRenderer
    const memorizedRowRenderer: TableRowRenderer = useCallback((props) => {
        if (Object.keys(props.rowData).length) {
            return <Link to={createRowDataLinkFunction(props.rowData)} key={props.key}>{rowRenderer ? rowRenderer(props) : defaultRowRenderer(props)}</Link>
        }
        return defaultRowRenderer(props)
    }, [createRowDataLinkFunction, rowRenderer])
    return (<TwinSimpleTable {...rest} rowRenderer={memorizedRowRenderer} >{children}</TwinSimpleTable>)
}

export default TwinTable