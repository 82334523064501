import { ModalEditComponentProps } from '../../../../../../../../../../baseComponents/ModalsLayouts/types'
import ModalEditCustomerProject from '../../../../../../../../../../specificComponents/Customers/ModalEditCustomerProject'

interface ModalUserNotificationsProjectsProps extends ModalEditComponentProps {
}

const ModalUserNotificationsProjects: React.FC<ModalUserNotificationsProjectsProps> = ({ userPermissions, setOpened, allRowData, onSubmit }) => {
    if (allRowData) {
        allRowData.id = allRowData?.SubId
    }
    return (
        <ModalEditCustomerProject {...{allRowData}} userPermissions={userPermissions} setOpened={setOpened} onSubmit={()=>setOpened(null)}  />
    )
}

export default ModalUserNotificationsProjects