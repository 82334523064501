import { faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faPlus } from '@fortawesome/pro-light-svg-icons'
import { WCalHolidayUsedDayModelType } from '@teinor/erp/types/company/WCalParent/WCal/WCalHolidayBag/WCalHolidayUsedDay'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ButtonPrimary, ButtonSecondary } from '../../../../../../../baseComponents/Button'
import { ComponentWithPermissions } from '../../../../../../../baseComponents/CreateRoutering/types'
import TitleAndWhiteBoxLayout from '../../../../../../../baseComponents/Layout/TitleAndWhiteBoxLayout'
import LoadingSpinner from '../../../../../../../baseComponents/LoaderDecider/LoadingSpinner'
import PermissionChecker from '../../../../../../../baseComponents/PermissionChecker'
import RangeView from '../../../../../../../baseComponents/TwinBigCalendar/RangeView'
import { RangeViewCalendarEvents } from '../../../../../../../baseComponents/TwinBigCalendar/RangeView/types'
import TwinCalendar from '../../../../../../../baseComponents/TwinCalendar'
import TwinIcon from '../../../../../../../baseComponents/TwinIcon'
import TwinTrans from '../../../../../../../baseComponents/TwinTrans'
import { twinFetchPostJSON } from '../../../../../../../utils/globals/data'
import { displayDate } from '../../../../../../../utils/globals/date'
import useDictMonths from '../../../../../../../utils/hooks/useDictMonths'
import useEditDeleteModal from '../../../../../../../utils/hooks/useEditDeleteModal'
import useOnClickOutside from '../../../../../../../utils/hooks/useOnClickOut'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import ModalCreateWCHolidayRequest from '../ModalCreateWCHolidayRequest'
import { getEmployees } from '../../../../../../../utils/reducers/getters'
import { dictionaryFromJsonArr } from '../../../../../../../utils/globals/dictionary'

interface WCHolidayRequestRangeViewProps extends ComponentWithPermissions {
    setView: React.Dispatch<React.SetStateAction<'default' | 'bars_view'>>
}

const WCHolidayRequestRangeView: React.FC<WCHolidayRequestRangeViewProps> = ({ setView, userPermissions }) => {
    const {t} = useTwinTranslation()
    const { date, setDate, setOpenModal, openModal, getData, futureDate, data, employees } = useWCHolidayRequestRangeViewLogic()
    return (
        <TitleAndWhiteBoxLayout title={t('holidayListing', 'Listado de vacaciones')} RightHeader={
            <div className='flex items-center'>
                <ButtonSecondary className='mr-5' onClick={() => setView('default')}><TwinTrans transKey='changeView'>Cambiar vista</TwinTrans></ButtonSecondary>
                <PermissionChecker userPermissions={userPermissions} permission='create'>
                    <ButtonPrimary onClick={() => setOpenModal({ type: 'create', allRowData: {} })}>
                        <TwinIcon icon={faPlus} className='mr-8' />
                        <TwinTrans transKey='newHolidayRequest'>Nueva solicitud de vacaciones</TwinTrans>
                    </ButtonPrimary>
                </PermissionChecker>
            </div>
        }>
            <WCControlFreeDaysDateSelector date={date} setDate={setDate} />
            {data ? <RangeView dateStart={date} dateEnd={futureDate} events={data} leftColumnData={employees} skipRowsWithoutData={true} /> : <LoadingSpinner />}
            {openModal?.type === 'create' && <ModalCreateWCHolidayRequest {...{ setOpened: () => setOpenModal(null) }} onSubmit={getData} />}
        </TitleAndWhiteBoxLayout >

    )
}

const useWCHolidayRequestRangeViewLogic = () => {
    const { openModal, setOpenModal } = useEditDeleteModal()
    const [data, setData] = useState<RangeViewCalendarEvents | null>(null)
    const [date, setDate] = useState(() => {
        const myDate = new Date()
        return new Date(myDate.getFullYear(), myDate.getMonth(), 1)
    })

    const employees = useMemo(() => dictionaryFromJsonArr(getEmployees() || {}), [])
    const futureDate = useMemo(() => {
        const myFutureDate = new Date(date.getFullYear(), date.getMonth() + 2, 0)
        return myFutureDate
    }, [date])
    

    const {t} = useTwinTranslation()
    const pendingText = t('pending', 'Pendiente')
    const holidaysText = t('holidays', 'Vacaciones')

    const getData = useCallback(async () => {
        const result: WCalHolidayUsedDayModelType[] = await twinFetchPostJSON('/api/app/workingCalendar/workingCalendarHolidayBag/getAllEmployeeHolidaysByDates', {
            dateStart: date.toDateString(),
            dateEnd: futureDate.toDateString()
        })
        if (result) {
            const myData: RangeViewCalendarEvents = {}
            for (const element of result) {
                const EmployeeId = element.WCalHolidayBag?.EmployeeId
                if (EmployeeId) {
                    if (!myData[EmployeeId]) {
                        myData[EmployeeId] = {}
                    }
                    const startDate = new Date(element.from)
                    const endDate = new Date(element.to)
                    const subtitleExtra = element.approved === 0 ? `\n(${pendingText})` : ''
                    let props = {
                        id: element.id,
                        allDay: true,
                        start: startDate,
                        end: endDate,
                        color: element.approved === 0 ? '#B97607' : '#43BAA5',
                        title: holidaysText + ' ' + element.WCalHolidayBag?.Employee?.name,
                        subtitle: (displayDate(startDate) !== displayDate(endDate) ? displayDate(startDate) + ' - ' + displayDate(endDate) : displayDate(startDate)) + subtitleExtra,
                    }
                    const currentDate = new Date(element.from)
                    while (endDate >= currentDate) {
                        const key = currentDate.toDateString()
                        myData[EmployeeId][key] = { ...props, rightSide: undefined }
                        currentDate.setDate(currentDate.getDate() + 1)
                    }
                }
            }
            setData(myData)
        }
        
    }, [date, pendingText, holidaysText, futureDate])

    useEffect(() => {
        getData()
    }, [getData])
    
    return { date, setDate, openModal, setOpenModal, getData, futureDate, data, employees }
}

interface WCControlFreeDaysDateSelectorProps extends WCControlFreeDaysDateSelectorLogicProps {
    date: Date
}

const WCControlFreeDaysDateSelector: React.FC<WCControlFreeDaysDateSelectorProps> = ({ date, ...rest }) => {
    const { nextMonth, onSelectMonth, prevMonth, showModalCalendar, ref, handleShowModalCalendar } = useWCControlFreeDaysDateSelectorLogic({ ...rest })
    const { dictMonths } = useDictMonths()
    const futureDate = new Date(date)
    futureDate.setMonth(futureDate.getMonth() + 1)

    return (
        <div className='flex items-center relative calendar_toolbar'>
            <div className='cursor-pointer mr-24 hover:text-green-21' onClick={prevMonth}><TwinIcon className='h-24' icon={faChevronLeft} /></div>
            <div className='cursor-pointer mr-24 hover:text-green-21' onClick={nextMonth}><TwinIcon className='h-24' icon={faChevronRight} /></div>
            <div className='flex items-center relative ' ref={ref}>
                <div className='toolbar-label  text-left text-gray-51 capitalize cursor-pointer' onClick={handleShowModalCalendar}>
                    {dictMonths[date.getMonth() + 1]} {date.getFullYear()} - {dictMonths[futureDate.getMonth() + 1]} {futureDate.getFullYear()}
                </div>
                <div className='cursor-pointer ml-24 hover:text-green-21' >
                    <TwinIcon className='h-24 mt-5' icon={showModalCalendar ? faChevronUp : faChevronDown} onClick={handleShowModalCalendar} />
                </div>
                {showModalCalendar ? <TwinCalendar defaultActiveStartDate={date} defaultView='year' className='absolute z-10 time_selector_month_calendar top-full' onChange={() => null} onClickMonth={onSelectMonth} /> : null}
            </div>
        </div>
    )
}

interface WCControlFreeDaysDateSelectorLogicProps {
    setDate: React.Dispatch<React.SetStateAction<Date>>
}

const useWCControlFreeDaysDateSelectorLogic = ({ setDate }: WCControlFreeDaysDateSelectorLogicProps) => {
    const [showModalCalendar, setShowModalCalendar] = useState<boolean>(false)

    const ref = useRef<any>(null)

    useOnClickOutside(ref, () => setShowModalCalendar(false))

    const handleShowModalCalendar = useCallback(() => {
        setShowModalCalendar((old) => !old)
    }, [setShowModalCalendar])

    const prevMonth = useCallback(() => {
        setDate((old) => {
            const myDate = new Date(old)
            myDate.setMonth(myDate.getMonth() - 2)
            return myDate
        })
    }, [setDate])

    const nextMonth = useCallback(() => {
        setDate((old) => {
            const myDate = new Date(old)
            myDate.setMonth(myDate.getMonth() + 2)
            return myDate
        })
    }, [setDate])


    const onSelectMonth = useCallback((myDate: Date) => {
        setDate(new Date(myDate.getFullYear(), myDate.getMonth(), 1))
        handleShowModalCalendar()
    }, [handleShowModalCalendar, setDate])

    return {prevMonth, nextMonth, onSelectMonth, showModalCalendar, ref, handleShowModalCalendar}
}

export default WCHolidayRequestRangeView