import { ShippingServiceModelType } from '@teinor/erp/types/company/config/shipping/shippingCompany/shippingService'
import { TwinDictionary } from '../../globals/dictionary'
import { ShippingCompanyModelType } from '@teinor/erp/types/company/config/shipping/shippingCompany'
import { Modify } from '@teinor/erp'

export interface ShippingServicesAction {
    type: 'CHANGE_SHIPPINGSERVICE'
    payload: ShippingServiceModelTypeExtended[]
}

type ShippingServiceModelTypeExtended = Modify< ShippingServiceModelType, {
    companyName: string
    parsedName: string
    ShippingCompany: ShippingCompanyModelType
}>
export type DictionaryShippingService = { [id: number]: ShippingServiceModelTypeExtended }

export type ShippingServicePayload = DictionaryShippingService | null

export default function shippingServices(state: ShippingServicePayload = null, action: ShippingServicesAction): DictionaryShippingService | null {
    switch (action.type) {
        case 'CHANGE_SHIPPINGSERVICE':
            const result : TwinDictionary = {}
            for (const shippingService of action.payload) {
                result[shippingService.id] = { ...shippingService, companyName: shippingService.ShippingCompany.name, parsedName:  shippingService.ShippingCompany.name + ' - ' + shippingService.name}
            }
            return result
        default:
            return state
    }
}