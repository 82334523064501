import { EmployeePayslipModelType } from '@teinor/erp/types/company/userInner/employee/employeePayslip'
import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { LittleModalCreateEdit } from '../../../../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../../../../../../../../baseComponents/ModalsLayouts/types'
import { FormRender } from '../../../../../../../../../../forms/FormRenderer/types'
import useTwinTranslation from '../../../../../../../../../../utils/hooks/useTwinTranslation'
import { getActiveCurrencies, getISOCurrencyMain } from '../../../../../../../../../../utils/reducers/getters'
import { changeErrorMessage } from '../../../../../../../../../../utils/reducers/reduxDispatch'

type ModalCreateEmployeePayslipProps = ModalEditComponentProps & {}

const ModalCreateEmployeePayslip: React.FC<ModalCreateEmployeePayslipProps> = ({ userPermissions, ...rest }) => {
    const { t } = useTwinTranslation()
    const { handleBeforeSubmit, currencyId, setCurrencyId } = useModalCreateEmployeePayslipLogic()
    const params = useParams<{ id: string }>()
    const EmployeeId = params.id
    const allCurrencies = getActiveCurrencies()
    
    const fields: FormRender<EmployeePayslipModelType> = [
        {
            cols: 1,
            elements: [
                {
                    name: 'EmployeeId',
                    component: 'InputHidden',
                    value: EmployeeId,
                },
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    defaultValue: '',
                    required: true
                },
                {
                    name: 'CurrencyId',
                    label: t('currency', 'Moneda'),
                    component: 'CustomSelect',
                    items: allCurrencies,
                    value: currencyId,
                    onChange: setCurrencyId,
                    required: true
                },
                {
                    name: 'amount',
                    label: t('amount', 'Importe'),
                    component: 'InputPriceStateFull',
                    CurrencyId: currencyId,
                    required: true
                },
                {
                    name: 'dateStart',
                    label: t('dateStart', 'Desde'),
                    required: true,
                    component: 'InputCalendarStateFull'
                },
                {
                    name: 'dateEnd',
                    label: t('dateEnd', 'Hasta'),
                    required: true,
                    component: 'InputCalendarStateFull'
                },
               
            ]
        },{
            cols: 1,
            elements: [
                {
                    name: 'file_payslip',
                    label: t('uploadPayslip', 'Subir nómina'),
                    component: 'FilesInput',
                },
            ]
        },
    ]

    return (
        <LittleModalCreateEdit fields={fields} imagePath={['tmp']} url={'/api/app/employee/employeePayslip/createEmployeePayslip'} translations={{
            title: t('addPayslip', 'Añadir nómina'),
            button: t('add', 'Añadir')
        }} haveButtonPermissions={true} beforeSubmit={(values) => handleBeforeSubmit(values)} {...rest}>
        </LittleModalCreateEdit>
    )
}

const useModalCreateEmployeePayslipLogic = () => {
    const [currencyId, setCurrencyId] = useState<string>(getISOCurrencyMain())

    const { t } = useTwinTranslation()
    const errorMessageFile = t('addFile', 'Introduzca un fichero')
    const errorMessageDate = t('addValidDates', 'Introduzca una fecha válida')

    const handleBeforeSubmit = useCallback((values: any) => {
        if (!values.file_payslip) {
            changeErrorMessage(errorMessageFile)
            return false
        }
        if (new Date(values.dateStart) > new Date(values.dateEnd)) {
            changeErrorMessage(errorMessageDate)
            return false
        }
        return true
    }, [errorMessageFile, errorMessageDate])

    return { handleBeforeSubmit, setCurrencyId, currencyId }
}


export default ModalCreateEmployeePayslip;