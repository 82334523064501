import { Fragment, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { RegisterCheckWithText, RegisterErrorWithText, RegisterHeader } from '../Register'
import LoadingSpinner from '../../../../baseComponents/LoaderDecider/LoadingSpinner'
import { twinFetchPostText } from '../../../../utils/globals/data'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import { MyLink } from '../Login/Components'

interface ActivateFingerprintProps extends ActivateFingerprintLogicProps { }

const ActivateFingerprint: React.FC<ActivateFingerprintProps> = () => {
    const { activatedSucc } = useActivateFingerprintLogic()
    const Component = decideComponent(activatedSucc)
    return (
        <Fragment>
            <RegisterHeader />
            <Component />
        </Fragment>
    )
}

interface ActivateFingerprintLogicProps { }

const useActivateFingerprintLogic = () => {
    const [activatedSucc, setActivatedSucc] = useState<boolean | null>(null)
    const { fingerprint_token } = useParams()

    const activateToken = useCallback(async () => {
        const result = await twinFetchPostText('/api/activateDevice', {
            fingerprint_token
        })
        if (result && result === 'true') {
            setActivatedSucc(true)
        } else {
            setActivatedSucc(false)
        }
    }, [fingerprint_token])

    useEffect(() => {
        activateToken()
    }, [activateToken])

    return { activatedSucc }
}

const decideComponent = (activatedSucc: boolean | null) => {
    if (activatedSucc) {
        return SuccessActivated
    } else if (activatedSucc === false) {
        return ErrorActivating
    }
    return LoadingActivating
}

const SuccessActivated: React.FC = () => {
    const { t } = useTwinTranslation()
    return (
        <div className='flex flex-col items-center justify-between'>
            <RegisterCheckWithText title={t('locationActivated', 'Localización activada')} subtitle={t('locationActivatedExtended', 'Localización activada correctamente. Puedes volver a la pantalla de inicio de sesión para empezar a trabajar.')} />
            <MyLink className='my-10 text-center' text={t('returnToLogin', 'Ir a la página de Inicio sesión')} link={'/'} />
        </div>
    )
}

const ErrorActivating: React.FC = () => {
    const { t } = useTwinTranslation()
    return (
        <div className='flex flex-col items-center justify-between'>
            <RegisterErrorWithText title={t('locationNotActivated', 'Localización no activada')} subtitle={t('locationNoActivatedExtended', 'Localización no activada correctamente. El enlace es inválido o ha caducado. Si no sabes como solucionarlo, contacta a soporte.')} />
            <MyLink className='my-10 text-center' text={t('returnToLogin', 'Ir a la página de Inicio sesión')} link={'/'} />
        </div>
    )
}

const LoadingActivating: React.FC = () => {
    return (
        <div className='flex flex-col items-center justify-between'>
            <LoadingSpinner />
        </div>
    )
}


export default ActivateFingerprint