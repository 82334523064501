

import { EmployeePayslipModelType } from '@teinor/erp/types/company/userInner/employee/employeePayslip'
import { useParams } from 'react-router'
import { ComponentWithPermissions } from '../../../../../../../../../baseComponents/CreateRoutering/types'
import { permissionCheck } from '../../../../../../../../../baseComponents/PermissionChecker/function'
import TwinTable from '../../../../../../../../../baseComponents/TwinTable'
import BaseScreenTableInner from '../../../../../../../../../baseComponents/TwinTable/BaseScreenTableInner'
import { TableComponentBaseScreenInnerProps } from '../../../../../../../../../baseComponents/TwinTable/BaseScreenTableInner/types'
import { CustomRenderDate, CustomRenderDisplayPriceWOParams, DeleteRow } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import ModalCreateEmployeePayslip from './ModalCreateEmployeePayslip'
import ModalDeleteEmployeePayslip from './ModalDeleteEmployeePayslip'


interface EmployeePayslipsProps extends ComponentWithPermissions {}

const EmployeePayslips: React.FC<EmployeePayslipsProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()
    const params = useParams<{ id: string }>()
    const id = params.id
    const pageData = {
        title: t('payslips', 'Nóminas'),
        newText: t('addEmployeePayslip', 'Añadir nómina'),
    }

    return (
        <BaseScreenTableInner TableComponent={TableEmployeePayslips} pageData={pageData} CreateModalComponent={ModalCreateEmployeePayslip} DeleteModalComponent={ModalDeleteEmployeePayslip} userPermissions={userPermissions} extraWhereParams={{ 'EmployeeId': id }} />
    )
}

interface TableEmployeePayslipsProps extends TableComponentBaseScreenInnerProps {}

const TableEmployeePayslips: React.FC<TableEmployeePayslipsProps> = ({ setTableInstance, userPermissions, extraWhereParams, setOpened }) => {
    const { t } = useTwinTranslation()
    const columns: ColumnTableSchema<EmployeePayslipModelType> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('id', 'ID'),
            sortable: true,
            width: 100
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            }
        },
        {
            id: 'dateStart',
            dataKey: 'dateStart',
            label: t('dateStart', 'Desde'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />,
            sortable: true
        },
        {
            id: 'dateEnd',
            dataKey: 'dateEnd',
            label: t('dateEnd', 'Hasta'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />
        },
        {
            id: 'amount',
            dataKey: 'amount',
            label: t('amount', 'Importe'),
            customRender: (parameterValue, allRowData) => <CustomRenderDisplayPriceWOParams value={parameterValue} CurrencyId={allRowData?.CurrencyId} />
        },
        {
            id: 'delete',
            dataKey: 'id',
            label: '',
            width: 50,
            customRender: (parameterValue, allRowData) => <DeleteRow key={'delete' + parameterValue} {...{ parameterValue, allRowData }} onClick={(_idk, allRowData) => setOpened({ type: 'delete', allRowData })} />,
            havePermission: permissionCheck(userPermissions, 'delete')
        }
    ]
    return (
        <TwinTable name='employeePayslips' columns={columns} getDataFrom='/api/app/employee/employeePayslip/getAllEmployeePayslips'
            onRowClick={(_id, rowData) => setOpened({
                type: 'edit',
                allRowData: rowData
            })} setTableInstance={setTableInstance} extraWhereParams={extraWhereParams} defaultOrder={{ order: 'desc', defaultOrderField: 'dateStart' }}/>
    )
}


export default EmployeePayslips