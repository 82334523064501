import { useParams } from 'react-router-dom'
import useTwinTranslation from '../../../../../utils/hooks/useTwinTranslation'
import { ComponentTabObject } from '../../../../../utils/hooks/useTabLogic'
import EditCardTabs from '../../../../../baseComponents/EditCardTabs'
import AllNotifications from './tabs/AllNotifications'

interface NotificationsProps {}

const Notifications: React.FC<NotificationsProps> = () => {
    const params = useParams<{ tab?: string }>()
    const { t } = useTwinTranslation() 
    const tabs: ComponentTabObject = {
        'all': { component: AllNotifications, text: t('all', 'Todas') },
    }

    return (<EditCardTabs className='hide_left_bar' basePath={'/notifications'} tabs={tabs} tab={params.tab} userPermissions={{ n: 4, permission: 'delete' }} basicData={[]} getData={async () => {}} />)
}

export default Notifications