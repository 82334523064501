import { faTrashCan } from '@fortawesome/pro-light-svg-icons'
import TwinIcon from '../../../../../../baseComponents/TwinIcon'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import TypeUserCompany from '../../../../components/TypeUserCompany'
import { UserBoxProps } from './types'

const UserBox: React.FC<UserBoxProps> = ({  user, type, myType, className, setOpenModal}) => {
    const { t } = useTwinTranslation()
    return (
        <div className={'user_company_box  text-gray-51 bg-white rounded-lg flex justify-between' + (className || '')}>
            <div className='flex'>
                <img src={user.img ?? '/user-png.png'} alt={user.name} className='rounded-full user_company_box_img' />
                <div className='flex flex-col'>
                    <div className='regular18 user_company_name'>{user.name ?? t('user', 'Usuario')}</div>
                    <div className='regular18 user_company_surname'>{user.surname ?? t('notCreated', 'no creado')}</div>
                    <div className='regular14 user_company_box_email my-5'>{user.email}</div>
                    <TypeUserCompany  type={type} />
                </div>
            </div>
            <DeleteUserIcon {...{
                type, myType, setOpenModal: () => setOpenModal({
                        type: 'delete',
                        allRowData: { UserId: user.id, type },
                    }
            )}} />
        </div>
    )
}
interface DeleteUserIconProps {
    myType: number
    type: number
    setOpenModal: () => void
}

const DeleteUserIcon: React.FC<DeleteUserIconProps> = ({ type, myType,  setOpenModal }) => {
    if (type <= myType) {
        return null
    }
    return (
        <TwinIcon icon={faTrashCan} onClick={()=>setOpenModal()} className='hover:text-red-BA cursor-pointer mt-2 delete_user_icon' size='lg' />
    )
}

export default UserBox