import { TwinDictionary } from '../../../utils/globals/dictionary'

export const sortCustomerProjectStatuses = (items: TwinDictionary) => {
    const myArr: any[] = []
    for (const key in items) {
        const item = items[key]
        myArr.push({ text: item.name, value: item.id, order: item.order })
    }
    const sorted = myArr.sort((a, b) => a.order - b.order)
    return sorted
}