import { CustomerProjectModelType } from '@teinor/erp/types/company/customer/customerProject'
import { useCallback, useMemo, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { MediumModalCreateEdit } from '../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../../../../../baseComponents/ModalsLayouts/types'
import { DropDownSortFunct } from '../../../../../../../forms/CustomSelect/DropDown'
import { FormRender } from '../../../../../../../forms/FormRenderer/types'
import { sortCustomerProjectStatuses } from '../../../../../../../specificComponents/Customers/SortCustomerProjectStatuses'
import withLoading from '../../../../../../../utils/hoc/withLoading'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import { AllReduxPayloads } from '../../../../../../../utils/reducers'
import { EmployeesPayload } from '../../../../../../../utils/reducers/company/employees'
import { getAllCustomerProjectTypes, getCustomers } from '../../../../../../../utils/reducers/getters'
import { GroupPayload } from '../../../../../../../utils/reducers/groups/groups'
import { BeforeSubmitHandler } from '../../../../../../../forms/TwinForm/types'
import CustomSelectSectionCustomers from '../../../../../../../specificComponents/Customers/CustomSelectSectionCustomers'


type ModalCreateCustomerProjectProps = ModalEditComponentProps & ReduxCustomerProject & {}

const ModalCreateCustomerProject: React.FC<ModalCreateCustomerProjectProps> = ({ onSubmit, setOpened, employees, groups }) => {
    const { t } = useTwinTranslation()
    const { customerProjectsTypes, myProjectStatuses, setMyProjectStatusId, myProjectStatusId, customers, handleBeforeSubmit } = useModalCreateCustomerProjectLogic()
    
    const fields: FormRender<CustomerProjectModelType> = [
        {
            cols: 2,
            elements: [
                {
                    name: 'name',
                    component: 'InputWithLabelMargin',
                    label: t('name', 'Nombre'),
                    required: true
                },
                {
                    name: 'description',
                    component: 'TextArea',
                    label: t('description', 'Descripción'),
                },
                {
                    name: 'CustomerId',
                    component: 'Custom',
                    render: ({ extraProps }) => <CustomSelectSectionCustomers customers={extraProps.customers} label={t('customer', 'Cliente')} required={true} />,
                    extraProps: { customers }
                },
                {
                    name: 'CustomerProjectTypeId',
                    component: 'CustomSelect',
                    required: true,
                    label: t('projectType', 'Tipo de proyecto'),
                    items: customerProjectsTypes || {},
                    value: myProjectStatusId,
                    onChange: (value) => setMyProjectStatusId(value)
                },
                {
                    name: 'CurrentCProjectStatusId',
                    component: 'CustomSelect',
                    label: t('projectStatus', 'Tipo de estado'),
                    items: myProjectStatuses || [],
                    DropDownComponent: DropDownSortFunct,
                    extraDropDownParams: {
                        sortFunction: (items: any) => sortCustomerProjectStatuses(items)
                    }
                },
                {
                    name: 'limit_date',
                    label: t('limitDate', 'Fecha límite'),
                    component: 'InputCalendarStateFull',
                    onlyValids: true,
                }
            ]
        },
        {
            cols: 1,
            title: t('employees', 'Empleados'),
            elements: [
                {
                    name: 'Employees',
                    component: 'MultipleCheckboxSelector',
                    subtitles: { subtitleOptions: t('notSelected', 'Sin seleccionar'), subtitleSelectedOptions: t('selected', 'Seleccionados') },
                    items: employees || {},
                }
            ]
        },
        {
            cols: 1,
            title: t('groups', 'Grupos'),
            elements: [
                {
                    name: 'Groups',
                    component: 'MultipleCheckboxSelector',
                    subtitles: { subtitleOptions: t('notSelected', 'Sin seleccionar'), subtitleSelectedOptions: t('selected', 'Seleccionados') },
                    items: groups || {},
                }
            ]
        }
    ]
    return (
        <MediumModalCreateEdit url='/api/app/customer/customerProject/createCustomerProject' fields={fields} setOpened={setOpened} onSubmit={onSubmit} translations={{
            title: t('createProject', 'Crear proyecto'),
            button: t('create', 'Crear')
        }} haveButtonPermissions={true} beforeSubmit={handleBeforeSubmit}>
        </MediumModalCreateEdit>
    )
}

const useModalCreateCustomerProjectLogic = () => {
    const customerProjectsTypes = getAllCustomerProjectTypes()
    const customers = getCustomers()
    const [myProjectStatusId, setMyProjectStatusId] = useState<string | undefined>(undefined)
    const myProjectStatuses = useMemo(() => {
        return myProjectStatusId ? customerProjectsTypes?.[parseInt(myProjectStatusId)].customerProjectStatusDict : {}
    }, [myProjectStatusId, customerProjectsTypes])

    const handleBeforeSubmit: BeforeSubmitHandler = useCallback((values: any) => {
        if (values.CurrentCProjectStatusId && !myProjectStatuses?.[values.CurrentCProjectStatusId]) {
            values.CurrentCProjectStatusId = null
        }
        return true
    }, [myProjectStatuses])
    
    return { customerProjectsTypes, myProjectStatuses, setMyProjectStatusId, myProjectStatusId, customers, handleBeforeSubmit }
}


const customerProjectDispatch = {
    setGroups: (payload: GroupPayload) => ({ type: 'CHANGE_GROUP', payload }),
    setEmployees: (payload: EmployeesPayload) => ({ type: 'CHANGE_EMPLOYEE', payload }),
}

export type ReduxCustomerProject = ConnectedProps<typeof customerProjectConnect>
const mapCustomerProjectTypesConnector = (state: AllReduxPayloads) => ({ groups: state.groups, employees: state.employees })
const customerProjectConnect = connect(mapCustomerProjectTypesConnector, customerProjectDispatch)

const customerProjectConnectLoading = withLoading(ModalCreateCustomerProject, [{ fetchUrl: '/api/app/group/getAllGroupsListing', propName: 'groups', setFunctionName: 'setGroups' }, { fetchUrl: '/api/app/employee/getAllEmployees', propName: 'employees', setFunctionName: 'setEmployees' }])

const ModalCreateCustomerProjectsConnect = customerProjectConnect(customerProjectConnectLoading)

export default ModalCreateCustomerProjectsConnect