import { CustomerContactPersonModelType } from '@teinor/erp/types/company/customer/customerContactPerson'
import { SetStateAction, useState, useCallback, useEffect } from 'react'
import { ModalCreateEditStructureLayout } from '../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import FormRenderer from '../../../../forms/FormRenderer'
import { FormRender } from '../../../../forms/FormRenderer/types'
import TwinForm from '../../../../forms/TwinForm'
import { twinFetchPostJSON } from '../../../../utils/globals/data'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import { UserPermissionsSingle } from '../../../../baseComponents/CreateRoutering/types'
import { valueOrDefaultValue } from '../../../../utils/globals/valueOrDefaultValue'
import { OnSubmit } from '../../../../forms/TwinForm/types'
import LoadingSpinner from '../../../../baseComponents/LoaderDecider/LoadingSpinner'
import { ModalCreateTaskWithHelperData } from '../types'


interface SectionCEContactPersonProps extends SectionCEContactPersonLogicProps {
    userPermissions: UserPermissionsSingle | undefined
}

export const SectionCEContactPerson: React.FC<SectionCEContactPersonProps> = ({ setDataTask, type, dataTask, setType, getCustomerProjectTaskTypeInstance, userPermissions }) => {
    const { t } = useTwinTranslation()
    const { handleOnSubmit, contactPersonInstance, handleOnSubmitCreate } = useSectionCEContactPersonLogic({ setDataTask, type, dataTask, setType, getCustomerProjectTaskTypeInstance })

    const contactPersonFields: FormRender<CustomerContactPersonModelType> = [
        {
            cols: 1,
            elements: [
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true,
                },
                {
                    name: 'position',
                    label: t('position', 'Cargo'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'CustomerId',
                    component: 'InputHidden',
                    value: dataTask.CustomerId
                },
                {
                    name: 'phone',
                    label: t('phone', 'Teléfono'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'mobile',
                    label: t('mobile', 'Móvil'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'email',
                    label: t('email', 'Email'),
                    component: 'InputWithLabelMargin',
                    type: 'email',
                },
                {
                    name: 'id',
                    component: 'InputHidden',
                    value: dataTask?.CustomerContactPersonId
                }
            ]
        }
    ]

    if (type === 'editContactPerson') {
        if (contactPersonInstance && userPermissions) {
            const parsedFields = valueOrDefaultValue(contactPersonFields, userPermissions, contactPersonInstance)
            return (<TwinForm className='pr-30 flex flex-col flex-auto' action='/api/app/customer/customerContactPerson/updateCustomerContactPerson' onSubmit={handleOnSubmit}>
                <ModalCreateEditStructureLayout loading={false} translations={{
                    title: t('editcontactPerson', 'Editar persona de contacto'),
                    button: t('edit', 'Editar')
                }} haveButtonPermissions={true} className='modal_negative_margin'>
                    <div className='overflow-auto flex flex-auto flex-col'>
                        {parsedFields.length ?
                            <FormRenderer sections={parsedFields} />
                            : null}
                    </div>
                </ModalCreateEditStructureLayout>
            </TwinForm>)
        }
        return <LoadingSpinner />
    } 
    return (<TwinForm className='pr-30 flex flex-col flex-auto' action='/api/app/customer/customerContactPerson/createCustomerContactPerson' onSubmit={handleOnSubmitCreate}>
        <ModalCreateEditStructureLayout loading={false} translations={{
            title: t('createContactPerson', 'Crear persona de contacto'),
            button: t('create', 'Crear')
        }} haveButtonPermissions={true} className='modal_negative_margin'>
            <div className='overflow-auto flex flex-auto flex-col'>
                {contactPersonFields.length ?
                    <FormRenderer sections={contactPersonFields} />
                    : null}
            </div>
        </ModalCreateEditStructureLayout>
    </TwinForm>)
}

interface SectionCEContactPersonLogicProps {
    dataTask: ModalCreateTaskWithHelperData
    setDataTask: React.Dispatch<SetStateAction<ModalCreateTaskWithHelperData>>
    type: string
    setType: React.Dispatch<SetStateAction<string | null>>
    getCustomerProjectTaskTypeInstance: (id: number) => Promise<void>
}

const useSectionCEContactPersonLogic = ({ setDataTask, type, dataTask, setType, getCustomerProjectTaskTypeInstance }: SectionCEContactPersonLogicProps) => {
    const [contactPersonInstance, setContactPersonInstance] = useState<CustomerContactPersonModelType | null>(null)

    const CustomerId = dataTask.CustomerId
    const CustomerProjectId = dataTask.CustomerProjectId

    const handleOnSubmitCreate: OnSubmit = useCallback(async (_res, values) => {
        const result = await twinFetchPostJSON('/api/app/customer/customerContactPerson/getAllCustomerContactPersons', {where: {name: values.name, CustomerId}})
        if (result) {
            setDataTask((old) => {
                return { ...old, CustomerContactPersonId: result[result.length - 1].id }
            })
        }
        setType(null)
        getCustomerProjectTaskTypeInstance(parseInt(CustomerProjectId))
    }, [setDataTask, setType, CustomerProjectId, getCustomerProjectTaskTypeInstance, CustomerId])

    const handleOnSubmit: OnSubmit = useCallback(async () => {
        setType(null)
        getCustomerProjectTaskTypeInstance(parseInt(CustomerProjectId))
    }, [setType, CustomerProjectId, getCustomerProjectTaskTypeInstance])

    const getContactPersonInstance = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/customer/customerContactPerson/getCustomerContactPersonForDetail', { id: dataTask.CustomerContactPersonId })
        if (result) {
            setContactPersonInstance(result)
        }
    }, [setContactPersonInstance, dataTask])

    useEffect(() => {
        if (type === 'editContactPerson') {
            getContactPersonInstance()
        }
    }, [getContactPersonInstance, type])

    return { handleOnSubmit, contactPersonInstance, handleOnSubmitCreate }
}


export default SectionCEContactPerson