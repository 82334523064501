import { EmployeeExpenseModelType } from '@teinor/erp/types/company/userInner/employee/employeeExpense'
import { EmployeeExpenseRowTypeModelAdd } from '@teinor/erp/types/company/userInner/employee/employeeExpense/employeeExpenseRow/employeeExpenseRowType'
import TableWithoutVirtualListing from '../../../../../../baseComponents/TwinTable/TableWithoutVirtualListing'
import { CustomRenderDisplayPriceWOParams, CustomRenderDownloadFile, CustomRenderString } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import { dictionaryComplexFromJsonArr } from '../../../../../../utils/globals/dictionary'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import { EmployeeExpenseRowModelTypeExtended } from '../../types'

interface EmployeeExpenseNotEditableTableExpensesProps {
    expenseInstance: EmployeeExpenseModelType | null
    expensesType: EmployeeExpenseRowTypeModelAdd[]
}

const EmployeeExpenseNotEditableTableExpenses: React.FC<EmployeeExpenseNotEditableTableExpensesProps> = ({ expenseInstance, expensesType }) => {
    const { t } = useTwinTranslation()
    const allExpenseTypes = dictionaryComplexFromJsonArr(expensesType)

    if (!expenseInstance) {
        return null
    }
    const columns: ColumnTableSchema<EmployeeExpenseRowModelTypeExtended> = [
        {
            id: 'EmployeeExpenseRowTypeId',
            dataKey: 'EmployeeExpenseRowTypeId',
            label: t('expenseType', 'Tipo de gasto'),
            customRender: (parameterValue) => <CustomRenderString value={allExpenseTypes?.[parameterValue]?.name} />
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
        },
        {
            id: 'amount',
            dataKey: 'amount',
            label: t('amount', 'Importe'),
            customRender: (parameterValue) => <CustomRenderDisplayPriceWOParams value={parameterValue} CurrencyId={expenseInstance?.CurrencyId || ''} /> 
        },
        {
            id: 'file_amount_expense_parse',
            dataKey: 'file_amount_expense_parse',
            label: t('file', 'Fichero'),
            width: 100,
            customRender: (parameterValue) => <CustomRenderDownloadFile url={parameterValue} />
        },
    ]
    
    return (
        <div className='mt-20 flex flex-col'>
            <TableWithoutVirtualListing data={expenseInstance?.EmployeeExpenseRows || []} columns={columns} disableScroll={true} />
        </div>
    )
}
export default EmployeeExpenseNotEditableTableExpenses