import { displayFormatedNumber } from '../../../../utils/globals/numbers'

interface DisplayPriceAdminProps {
    value: number
    className?: string
    children?: React.ReactNode
}

const DisplayPriceAdmin: React.FC<DisplayPriceAdminProps> = ({value, className, children}) => {
    let price = parseFloat(String(value|| 0))
    const finalValue = price?.toFixed(2)
    const priceParsed = displayFormatedNumber(finalValue, 2)
    return (<span className={className}>{priceParsed} €{children}</span>)
}

export default DisplayPriceAdmin