import ModalCEWorkingCalendarSpecial from './ModalCEWorkingCalendarSpecial'
import { useMemo } from 'react'
import { WCalSpecialModelType } from '@teinor/erp/types/company/WCalParent/WCal/WCalSpecial'
import { useLocation } from 'react-router'
import { ComponentWithPermissions } from '../../../../../../baseComponents/CreateRoutering/types'
import { TwinTableLink } from '../../../../../../baseComponents/TwinTable'
import BaseScreenTable from '../../../../../../baseComponents/TwinTable/BaseScreenTable'
import { TableComponentProps } from '../../../../../../baseComponents/TwinTable/BaseScreenTable/types'
import { EditRowNotLink } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'

interface WorkingCalendarSpecialProps extends ComponentWithPermissions { }

const WorkingCalendarSpecial: React.FC<WorkingCalendarSpecialProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()

    const pageData = {
        title: t('workingCalendarSpecial', 'Calendarios de días especiales'),
        newText: t('newWorkingCalendarSpecial', 'Nuevo Calendario de Días especiales'),
    }

    return (
        <BaseScreenTable TableComponent={TableWorkingCalendarSpecial} CreateModalComponent={ModalCEWorkingCalendarSpecial} EditModalComponent={ModalCEWorkingCalendarSpecial} pageData={pageData} userPermissions={userPermissions} />
    )
}

type TableWorkingCalendarSpecialProps = TableComponentProps & {}

const TableWorkingCalendarSpecial: React.FC<TableWorkingCalendarSpecialProps> = ({ setTableInstance, setOpened }) => {
    const { t } = useTwinTranslation()
    const location = useLocation().pathname
    const year = useMemo(() => String(new Date().getFullYear()), [])
    const columns: ColumnTableSchema<WCalSpecialModelType> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('id', 'ID'),
            sortable: true,
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            sortable: true,
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            customRender: (parameterValue, allRowData) => <EditRowNotLink key={'edit' + parameterValue} {...{ parameterValue, allRowData }} onClick={(_idk, allRowData) => setOpened({ type: 'edit', allRowData })} />
        },
    ]
    return (
        <TwinTableLink createRowDataLink={[location, ['$id', year]]} name='WorkingCalendarSpecial' columns={columns} getDataFrom='/api/app/workingCalendar/workingCalendarSpecial/getAllWorkingCalendarSpecials' onRowClick={(_id, rowData) => setOpened({
            type: 'edit',
            allRowData: rowData
        })} setTableInstance={setTableInstance} />
    )
}

export default WorkingCalendarSpecial