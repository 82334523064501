import { faEnvelopeCircleCheck } from '@fortawesome/pro-light-svg-icons'
import { EmployeeNotificationLogTypeKeys, EmployeeNotificationModelType } from '@teinor/erp/types/company/userInner/employee/employeeNotification'
import { useCallback, useEffect, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { ButtonSecondary } from '../../../../../../../baseComponents/Button'
import { DisplayStatusWithoutIcon } from '../../../../../../../baseComponents/Displays/DisplayStatus'
import TwinIcon from '../../../../../../../baseComponents/TwinIcon'
import { TwinSimpleTable } from '../../../../../../../baseComponents/TwinTable'
import { TableComponentProps } from '../../../../../../../baseComponents/TwinTable/BaseScreenTable/types'
import { TableInstanceType } from '../../../../../../../baseComponents/TwinTable/types'
import { CustomRenderDate, CustomRenderString } from '../../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import TwinTrans from '../../../../../../../baseComponents/TwinTrans'
import { twinFetchPostJSON } from '../../../../../../../utils/globals/data'
import { TwinDictionary } from '../../../../../../../utils/globals/dictionary'
import useEditDeleteModal from '../../../../../../../utils/hooks/useEditDeleteModal'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import { AllReduxAppPayloads, GlobalContext } from '../../../../../../../utils/reducers'
import { LastNotificationPayload } from '../../../../../../../utils/reducers/company/lastNotification'
import { changeLastNotification } from '../../../../../../../utils/reducers/reduxDispatch'
import { parseExtraDataDatesNotification } from './functions'
import ModalAllNotificationsEmployee from './ModalAllNotificationsEmployee'

type AllNotificationsProps = TableAllNotificationsProps & {}

const AllNotifications: React.FC<AllNotificationsProps> = ({ userPermissions }) => {
    const { openModal, setOpenModal, setTableInstance, tableInstance, refreshTableData, checkAllNotifications } = useAllNotificationsLogic()


    return (
        <div className={'flex flex-col flex-auto h-1 all_notifications_employee'}>
            <span className='regular18 mb-40'><TwinTrans transKey='allNotifications'>Todas las notificaciones</TwinTrans></span>
            <div className='table_header flex justify-between items-center mb-22'>
                <div className='right_side grid_header_table items-center ml-auto'>
                    <ButtonSecondary onClick={checkAllNotifications}>
                        <TwinIcon icon={faEnvelopeCircleCheck} className='mr-8' size='lg' />
                        <TwinTrans transKey={'checkAllReaded'} >Marcar todas como leídas</TwinTrans>
                    </ButtonSecondary>
                </div>
            </div>
            <div className='flex-auto flex flex-col'>
                <TableAllNotificationsConnect setTableInstance={setTableInstance} userPermissions={userPermissions} setOpened={setOpenModal} tableInstance={tableInstance} />
                {openModal?.type === 'edit' ? <ModalAllNotificationsEmployee onSubmit={refreshTableData} setOpened={() => setOpenModal(null)} allRowData={openModal?.allRowData} userPermissions={userPermissions}/> : null}
            </div>
        </div>
    )
}

const useAllNotificationsLogic = () => {
    const { openModal, setOpenModal } = useEditDeleteModal()
    const [tableInstance, setTableInstance] = useState<TableInstanceType>(null)
    const refreshTableData = useCallback(() => {
        tableInstance?.getTableDataFromStart()
    }, [tableInstance])

    const checkAllNotifications = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/employee/employeeNotification/markAsReadFromNow', {})
        if (result) {
            tableInstance?.getTableDataFromStart()
            changeLastNotification(null)
        }
    }, [tableInstance])


    return { openModal, setOpenModal, tableInstance, setTableInstance, refreshTableData, checkAllNotifications }
}


type TableAllNotificationsProps = TableComponentProps & TableAllNotificationsLogicProps & {}

const TableAllNotifications: React.FC<TableAllNotificationsProps> = ({ setOpened, setTableInstance, lastNotification, tableInstance, ...rest}) => {
    const { t } = useTwinTranslation()
    useTableAllNotificationsLogic({lastNotification, tableInstance, ...rest})
    const dictLogTypes: Record<EmployeeNotificationLogTypeKeys, string> = {
        0: t('holiday', 'Vacaciones'),
        1: t('holiday', 'Vacaciones'),
        2: t('holiday', 'Vacaciones'),
        3: t('holiday', 'Vacaciones'),
        4: t('absence', 'Ausencias'),
        5: t('absence', 'Ausencias'),
        6: t('absence', 'Ausencias'),
        7: t('absence', 'Ausencias'),
        8: t('absence', 'Ausencias'),
        9: t('absence', 'Ausencias'),
        10: t('payslips', 'Nóminas'),
        11: t('expenses', 'Gastos'),
        12: t('expenses', 'Gastos'),
        13: t('expenses', 'Gastos'),
        14: t('expenses', 'Gastos'),
        15: t('expenses', 'Gastos'),
        16: t('projects', 'Proyectos'),
        17: t('projects', 'Proyectos'),
        18: t('task', 'Tarea'),
        19: t('task', 'Tarea'),
        20: t('task', 'Tarea'),
        21: t('task', 'Tarea'),
        22: t('task', 'Tarea'),
        23: t('task', 'Tarea')
    }
    const columns: ColumnTableSchema<EmployeeNotificationModelType> = [
        {
            id: 'id',
            dataKey: 'id',
            label: '',
            width: 40,
            customRender: (parameterValue, allRowData) => {return allRowData?.readed ? <div></div> : <div className={'w-3 h-3 rounded-xl bg-green-43'}></div>}
        },
        {
            id: 'createdAt',
            dataKey: 'createdAt',
            label: t('date', 'Fecha'),
            sortable: true,
            width: 100,
            customRender: (parameterValue, allRowData) => <CustomRenderDate className={allRowData?.readed ? '' : 'font-bold' } value={parameterValue} />
        },
        {
            id: 'title',
            dataKey: 'logType',
            label: t('title', 'Título'),
            width: 250,
            customRender: (parameterValue, allRowData) => <RenderLogTypeTitle value={parameterValue} isRead={allRowData?.readed} />
        },
        {
            id: 'message',
            dataKey: 'extraData',
            label: t('message', 'Mensaje'),
            minWidth: 500,
            customRender: (parameterValue, allRowData) => <RenderLogTypeMessage value={parameterValue} logType={allRowData?.logType} employeeTrigger={allRowData?.EmployeeTrigger?.fullname_short} isRead={allRowData?.readed} />
        },
        {
            id: 'logType',
            dataKey: 'logType',
            minWidth: 150,
            label: t('category', 'Categoría'),
            generalSearchable: false,
            customRender: (parameterValue, allRowData) => <DisplayStatusWithoutIcon colorText={'text-green-21'} text={dictLogTypes[parseInt(parameterValue) as EmployeeNotificationLogTypeKeys ]} />
        },
    ]
    return (
        <TwinSimpleTable name='AllNotifications' columns={columns} defaultOrder={{ defaultOrderField: 'createdAt', order: 'desc' }} getDataFrom='/api/app/employee/employeeNotification/getAllEmployeeNotifications' setTableInstance={setTableInstance} onRowClick={(_id, rowData) => setOpened({
            type: 'edit',
            allRowData: rowData
        })}/>
    )
}

interface TableAllNotificationsLogicProps extends ReduxLastNotification {
    lastNotification: LastNotificationPayload
    tableInstance: TableInstanceType
}

const useTableAllNotificationsLogic = ({ lastNotification, tableInstance}: TableAllNotificationsLogicProps) => {
    useEffect(() => {
        if (lastNotification?.id) {
            tableInstance?.getTableDataFromStart()
        }
    }, [lastNotification?.id, tableInstance])
}

interface RenderBuyOrderStatusProps {
    value: string
    isRead: boolean
}

const RenderLogTypeTitle: React.FC<RenderBuyOrderStatusProps> = ({ value, isRead }) => {
    const { t } = useTwinTranslation()
    const requestStatusTypesDic: TwinDictionary = {
        0:  t('holidayRequest', 'Vacaciones solicitadas'),
        1:  t('holidayApproved', 'Vacaciones aprobadas'),
        2:  t('holidayDenied', 'Vacaciones denegadas'),
        3:  t('holidayCancel', 'Vacaciones canceladas'),
        4:  t('absenceRequest', 'Ausencia solicitada'),
        5:  t('absenceApproved', 'Ausencia aprobada'),
        6:  t('absenceDenied', 'Ausencia denegada'),
        7:  t('absenceCancel', 'Ausencia cancelada'),
        8:  t('absenceProofFile', 'Ausencia justificada'),
        9: t('absenceCancel', 'Ausencia cancelada'),
        10: t('payslipReceived', 'Nómina recibida'),
        11: t('expenseRequest', 'Solicitud de gastos'),
        12: t('expenseDenied', 'Gastos denegados'),
        13: t('expenseAccepted', 'Gastos aceptados'),
        14: t('expensePaid', 'Gastos pagados'),
        15: t('expenseReceived', 'Gasto recibido'),
        16: t('commentAddedProject', 'Comentario añadido'),
        17: t('newStatusProject', 'Estado modificado'),
        18: t('changedEmployeeTask', 'Cambio de empleado en la tarea'),
        19: t('changedGroupTask', 'Cambio de grupo en la tarea'),
        20: t('finishedTask', 'Tarea finalizada'),
        21: t('reopenTask', 'Reabrir tarea'),
        22: t('changesTask', 'Cambios en la tarea'),
        23: t('addTaskComment', 'Añadido comentario en la tarea'),
    }

    const parsedValue = parseInt(value)
    return <CustomRenderString className={isRead ? '' : 'font-bold'} value={requestStatusTypesDic[parsedValue]} />
}

interface RenderLogTypeMessageProps {
    value: string
    isRead: boolean
    logType: number
    employeeTrigger: string
}

export const RenderLogTypeMessage: React.FC<RenderLogTypeMessageProps> = ({ value, isRead, logType, employeeTrigger }) => {
    const { tVars } = useTwinTranslation()
    const extraDataParsed = JSON.parse(value)
    const options = { employeeTrigger, 'interpolation': { 'escapeValue': false }, ...extraDataParsed, ...parseExtraDataDatesNotification(extraDataParsed) }
    const requestStatusTypesDic: TwinDictionary = {
        0: tVars('holidayRequestMessage', '{{employeeTrigger}} ha solicitado vacaciones del {{fromDate}} al {{toDate}}', options),
        1: tVars('holidayApprovedMessage', '{{employeeTrigger}} te ha aprobado las vacaciones del {{fromDate}} al {{toDate}}', options),
        2: tVars('holidayDeniedMessage', '{{employeeTrigger}} te ha denegado las vacaciones del {{fromDate}} al {{toDate}}', options),
        3: tVars('holidayCancelMessage', '{{employeeTrigger}} te ha cancelado las vacaciones del {{fromDate}} al {{toDate}}', options),
        4: tVars('absenceRequestMessage', '{{employeeTrigger}} ha solicitado una ausencia del {{fromDate}} al {{toDate}}', options),
        5: tVars('absenceApprovedMessage', '{{employeeTrigger}} te ha aprobado una ausencia del {{fromDate}} al {{toDate}}', options),
        6: tVars('absenceDeniedMessage', '{{employeeTrigger}} te ha denegado una ausencia del {{fromDate}} al {{toDate}}', options),
        7: tVars('absenceCancelMessage', '{{employeeTrigger}} te ha cancelado una ausencia del {{fromDate}} al {{toDate}}', options),
        8: tVars('absenceProofFileMessage', '{{employeeTrigger}} ha adjuntado un fichero en una ausencia', options),
        9: tVars('absenceSelfCancelMessage', '{{employeeTrigger}} ha cancelado su ausencia del {{fromDate}} al {{toDate}}', options),
        10: tVars('payslipReceivedMessage', 'Has recibido la nómina del {{fromDate}} al {{toDate}}', options),
        11: tVars('expenseRequestMessage', '{{employeeTrigger}} ha enviado una ficha de gastos del {{fromDate}} al {{toDate}}', options),
        12: tVars('expenseDeniedMessage', 'Han denegado la ficha de gastos del {{fromDate}} al {{toDate}}', options),
        13: tVars('expenseAcceptedMessage', 'Han aceptado la ficha de gastos del {{fromDate}} al {{toDate}}', options),
        14: tVars('expensePaidMessage', 'Han pagado la ficha de gastos del {{fromDate}} al {{toDate}}', options),
        15: tVars('expenseReceivedMessage', 'Has recibido una ficha de gastos del {{fromDate}} al {{toDate}}', options),
        16: tVars('commentAddedMessage', '{{employeeTrigger}} ha dejado un comentario en el proyecto {{projectName}}', options),
        17: tVars('newStatusProjectMessage', '{{employeeTrigger}} ha realizado una modificación en el estado del proyecto {{projectName}}', options),
        18: options.employeeName ? tVars('changedEmployeeTaskMessage', '{{employeeTrigger}} ha asignado a {{employeeName}} la tarea #{{taskId}}', options) : tVars('changedNoEmployeeTaskMessage', '{{employeeTrigger}} ha quitado el empleado asignado a la tarea #{{taskId}}', options) ,
        19: options.groupName ? tVars('changedGroupTaskMessage', '{{employeeTrigger}} ha asignado a {{groupName}} la tarea #{{taskId}}', options) : tVars('changedNoGroupTaskMessage', '{{employeeTrigger}} ha quitado el grupo asignado a la tarea #{{taskId}}', options),
        20: tVars('finishedTaskMessage', '{{employeeTrigger}} ha finalizado la tarea #{{taskId}}', options),
        21: tVars('unfinishedTaskMessage', '{{employeeTrigger}} ha reabierto la tarea #{{taskId}}', options),
        22: tVars('changedTaskCFieldMessage', '{{employeeTrigger}} ha cambiado a {{customField} {{customFieldValue}} la tarea #{{taskId}}', options),
        23: tVars('addTaskCommentMessage', '{{employeeTrigger}} ha añadido un comentario en la tarea #{{taskId}}', options),
    }

    return <CustomRenderString className={isRead ? '' : 'font-bold'} value={requestStatusTypesDic[logType]} />
}

const lastNotificationDispatch = {
    setLastNotification: (payload: LastNotificationPayload) => ({ type: 'CHANGE_LAST_NOTIFICATION', payload })
}

export type ReduxLastNotification = ConnectedProps<typeof lastNotificationConnect>
const mapLastNotificationConnector = (state: AllReduxAppPayloads) => ({ lastNotification: state.lastNotification })
const lastNotificationConnect = connect(mapLastNotificationConnector, lastNotificationDispatch, null, { context: GlobalContext })

const TableAllNotificationsConnect = lastNotificationConnect(TableAllNotifications)

export default AllNotifications