import { useCallback } from 'react'
import { Modify } from '../../../../../utils/globals/types'
import { ModalFilterCustomExtraComponentDataSelectProps } from '../../../../TwinTable/VirtualTableListing/Subcomponents/ModalFilter'
import { AllSearchableComponentsSingleElement, AllSearchableComponentsComponent } from '../../../../TwinTable/VirtualTableListing/Subcomponents/types'
import { SingleSelectedFilterTy } from '../../../../../utils/hooks/useConfigColumns'
import MultipleCheckboxSelector from '../../../../../forms/MultipleCheckboxSelector'
import useTwinTranslation from '../../../../../utils/hooks/useTwinTranslation'
import { MultipleCbxSelectorValues } from '../../../../../forms/MultipleCheckboxSelector/types'
import {SwitchLightInversed} from '../../../../../forms/SwitchLight'
import { ListAdderStateFull } from '../../../../../forms/ListAdder'
import CustomSelect from '../../../../../forms/CustomSelect'

interface SearchComponentBaseProps {
    id: string
    dataKey: any
    value: SingleSelectedFilterTy
    changeSingleField: (id: string, value: SingleSelectedFilterTy) => void
    extraComponentData?: any
    isLast?: boolean
}

interface SearchComponentProps extends SearchableComponentBasicLogicProps {
    label: string
}

const SearchableComponentBasic: React.FC<SearchComponentProps> = ({ label, ...rest }) => {
    const { onChange, changeExcluded } = useSearchableComponentBasicLogic({ ...rest })
    const {t} = useTwinTranslation()
    return (
        <div className='py-15'>
            <div className='mb-20 flex justify-end'>
                <SwitchLightInversed active={rest.value.excluded || false} label={t('excludeSelected', 'Excluir seleccionadas')} onChange={changeExcluded}/>
            </div>
            <ListAdderStateFull items={rest.value.value || []} label={t('add', 'Añadir') + ' ' + label} onChange={onChange } className='list_adder_filters' />
        </div>
    )
}

interface SearchableComponentBasicLogicProps extends SearchComponentBaseProps {}

const useSearchableComponentBasicLogic = ({ id, value, changeSingleField }: SearchableComponentBasicLogicProps) => {
    const changeExcluded = useCallback(() => {
        const copy: SingleSelectedFilterTy = JSON.parse(JSON.stringify(value))
        if (copy['excluded']) {
            delete copy['excluded']
        } else {
            copy['excluded'] = true
        }
        changeSingleField(id, copy)
    }, [id, value, changeSingleField])

    const onChange = useCallback((values: string[]) => {
        const copy: SingleSelectedFilterTy = JSON.parse(JSON.stringify(value))
        if (!values.length) {
            delete copy.value
        } else {
            copy.value = values
        }
        changeSingleField(id, copy)
    }, [id, value, changeSingleField])

    return { changeExcluded, onChange }
}


interface SearchableComponentCustomSelectProps extends SearchableComponentCustomSelectLogicProps {}

const SearchableComponentCustomSelect: React.FC<SearchableComponentCustomSelectProps> = ({value, extraComponentData, ...logic}) => {
    const { onChange, changeExcluded } = useSearchableComponentCustomSelectLogic({ value, extraComponentData, ...logic })
    const {t} = useTwinTranslation()
    return (
        <div className='py-15'>
            <div className='flex justify-end'>
                <SwitchLightInversed active={value.excluded || false} label={t('excludeSelected', 'Excluir seleccionadas')} onChange={changeExcluded} />
            </div>
            <MultipleCheckboxSelector defaultValue={value.value} items={extraComponentData.items} subtitles={{subtitleOptions: t('avaliableOptions', 'Opciones disponibles'), subtitleSelectedOptions: t('addedOptions', 'Opciones añadidas') }} onChange={onChange}/>
        </div>
    )
}

type SearchableComponentCustomSelectLogicProps = Modify<SearchComponentBaseProps, ModalFilterCustomExtraComponentDataSelectProps & {

}>

const useSearchableComponentCustomSelectLogic = ({changeSingleField, value, id}: SearchableComponentCustomSelectLogicProps) => {
    const changeExcluded = useCallback(() => {
        const copy: SingleSelectedFilterTy = JSON.parse(JSON.stringify(value))
        if (copy['excluded']) {
            delete copy['excluded']
        } else {
            copy['excluded'] = true
        }
        changeSingleField(id, copy)
    }, [id, value, changeSingleField])

    const onChange  = useCallback((values: MultipleCbxSelectorValues) => {
        const copy: SingleSelectedFilterTy = JSON.parse(JSON.stringify(value))
        const myValues: any = []
        for (const value of values) {
            if (isNaN(value)) {
                myValues.push(value)
            } else {
                myValues.push(parseFloat(value))
            }
        }
        if (!myValues.length) {
            delete copy.value
        } else {
            copy.value = myValues
        }
        changeSingleField(id, copy)
    }, [id, value, changeSingleField])

    return { onChange, changeExcluded }
}

interface SearchableComponentCustomSelectSingleProps extends SearchableComponentCustomSelectSingleLogicProps { }

const SearchableComponentCustomSelectSingle: React.FC<SearchableComponentCustomSelectSingleProps> = ({ value, extraComponentData, isLast, ...logic }) => {
    const { onChange } = useSearchableComponentCustomSelectSingleLogic({ value, extraComponentData, isLast, ...logic })
    return (
        <div className={'pt-5  ' + (isLast ? 'h-150': 'h-100')} >
            <CustomSelect className='short_customselect' value={value.value} items={extraComponentData.items} label={extraComponentData.label} onChange={onChange} maxItems={3}/>
        </div>
    )
}

type SearchableComponentCustomSelectSingleLogicProps = Modify<SearchComponentBaseProps, ModalFilterCustomExtraComponentDataSelectProps & {

}>

const useSearchableComponentCustomSelectSingleLogic = ({changeSingleField, value, id}: SearchableComponentCustomSelectSingleLogicProps) => {

    const onChange  = useCallback((myValue: string | number) => {
        const copy: SingleSelectedFilterTy = JSON.parse(JSON.stringify(value))
        if (!myValue) {
            delete copy.value
        } else {
            copy.value = myValue
        }
        changeSingleField(id, copy)
    }, [id, value, changeSingleField])

    return { onChange }
}


const elements: AllSearchableComponentsSingleElement = {
    SearchableComponentBasic: SearchableComponentBasic,
    SearchableComponentsCustomSelect: SearchableComponentCustomSelect,
    SearchableComponentsCustomSelectSearchBar: SearchableComponentCustomSelect,
    SearchableComponentsCustomSelectSingle: SearchableComponentCustomSelectSingle
}

interface SingleElementModalFilterTableRendererProps extends SearchComponentBaseProps {
    component: AllSearchableComponentsComponent
}

const ModalConfigColumnsFiltersComponentsRenderer = ({ component, ...rest }: SingleElementModalFilterTableRendererProps) => {
    const MyComponent = elements[component]
    return <MyComponent {...rest} />
}

export default ModalConfigColumnsFiltersComponentsRenderer
