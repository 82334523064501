import { ErpModules } from '@teinor/erp/modules'
import { PermissionThreeKeys } from '@teinor/erp/permissions'
import React, { useCallback, useEffect, useState } from 'react'
import { ConnectedComponent } from 'react-redux'
import { UserPermissions } from '../../baseComponents/CreateRoutering/types'
import NullComponent from '../../baseComponents/NullComponent'
import { haveModuleBuyed } from '../globals'
import { TwinDictionary } from '../globals/dictionary'
import { getModulesBuyed } from '../reducers/getters'

export interface ComponentTab{
    component: React.FC<any>
}
export type OnChangeTabsType = (position: number) => void

export const useTabLogic = (components: ComponentTab[], defaultActive?: number) => {
    const [active, setActive] = useState(defaultActive || 0)
    const onChangeTabs: OnChangeTabsType = useCallback((position: number) => {
        setActive(position)
    }, [])
    return { active, onChangeTabs, ComponentFromTab: components[active].component}
}

export interface ComponentTabObjectElement{
    component: React.FC<any> | ConnectedComponent<React.FC<any>, any>
    text: string | JSX.Element
    permission?: PermissionThreeKeys
    userPermission?: UserPermissions
    modules?: ErpModules
    extraProps?: TwinDictionary
}
export type ComponentTabObject = {
    [value: string]:ComponentTabObjectElement
}
export type OnChangeTabsObjectType = (position: keyof ComponentTabObject) => void

export interface UseTabLogicReturn<K> {
    active: keyof K
    ComponentFromTab: React.FC<any> | ConnectedComponent<React.FC<any>, any>
    setActive: React.Dispatch<React.SetStateAction<string | keyof K>>
}

export const useTabObjectLogic = <K extends ComponentTabObject>(components: K, defaultActive?: keyof K) : UseTabLogicReturn<K> => {
    const [active, setActive] = useState(getActive(components, defaultActive || ''))

    useEffect(() => {
        if (defaultActive) {
            setActive((oldActive) => {
                if (oldActive !== defaultActive) {
                    return defaultActive
                }
                return oldActive
            })
        }
    }, [defaultActive])
    const myActive = getActive(components, active)
    return { active: myActive, ComponentFromTab: components?.[myActive]?.component ?? NullComponent, setActive}
}

export const getActive = <K extends ComponentTabObject>(components: K, active: keyof K) => {
    if (components) {
        if (components[active] && 'component' in components[active]) {
            return active
        } else if (components[''] && 'component' in components['']) {
            return ''
        }
    }
    return Object.keys(components)[0]
}
export const filterTabsByPermissions = (tabs: ComponentTabObject, userPermissions: UserPermissions) => {
    let tabsFiltered: ComponentTabObject = {}
    const modulesBuyed = getModulesBuyed()
    for (const key in tabs) {
        const tab = tabs[key]
        if (haveModuleBuyed(tab.modules, modulesBuyed)) {
            if (!tab.permission) {
                tabsFiltered[key] = { ...tab, userPermission: { n: 4, permission: 'delete' } }
                continue
            }
            if (userPermissions && tab.permission in userPermissions) {
                // @ts-ignore
                const userPermission = userPermissions[tab.permission]
                tabsFiltered[key] = { ...tab, userPermission }
            }
        }
    }
    return tabsFiltered
}