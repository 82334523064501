import { TwinDictionary } from '../../utils/globals/dictionary'
import { includesByArrayType } from '../../utils/globals/array'
import { MultipleCbxSelectorOptions, MultipleCbxSelectorValues, PreselectedMultipleCbxSelector } from './types'

export const getNotChangingValuesOnPreSelected = (oldSelectedValues: MultipleCbxSelectorValues, preSelected: PreselectedMultipleCbxSelector) => {
    const result: MultipleCbxSelectorValues = []
    for (const element of oldSelectedValues) {
        if (!includesByArrayType(preSelected.selected, element)) {
            result.push(element)
        }
    }
    return result
}

export const separateOptionsBySelected = (items: TwinDictionary, mySelectedValues: MultipleCbxSelectorValues) => {
    const options: MultipleCbxSelectorOptions = {
        unSelected: {},
        selected: {}
    }
    for (const value in items) {
        if (includesByArrayType(mySelectedValues, value)) {
            options.selected[value] = items[value]
        } else {
            options.unSelected[value] = items[value]
        }
    }
    return options

}