import { useCallback } from 'react'
import { customerProjectTypeColors } from '../../globals/colors'

const useCustomerTypesRandomColor = () => {
    const getDefaultRandomColorCustomerTypes = useCallback(() => {
        const index = Math.floor(Math.random() * customerProjectTypeColors.length)
        return customerProjectTypeColors[index]
    }, [])
    return { getDefaultRandomColorCustomerTypes }
}

export default useCustomerTypesRandomColor

