import { EmployeeNotificationModelType } from '@teinor/erp/types/company/userInner/employee/employeeNotification'

export interface LastNotificationAction {
    type: 'CHANGE_LAST_NOTIFICATION'
    payload: EmployeeNotificationModelType | null
}
export type LastNotificationPayload = EmployeeNotificationModelType | null

export default function lastNotification(state: LastNotificationPayload = null, action: LastNotificationAction): EmployeeNotificationModelType | null {
    switch (action.type) {
        case 'CHANGE_LAST_NOTIFICATION':
            return state = action.payload
        default:
            return state
    }
}