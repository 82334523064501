import { ModalEditComponentProps } from '../../../../../../../../../../baseComponents/ModalsLayouts/types'
import { ButtonPrimary } from '../../../../../../../../../../baseComponents/Button'
import { useCallback } from 'react'
import { twinFetchPostJSON } from '../../../../../../../../../../utils/globals/data'
import { ModalLittle } from '../../../../../../../../../../baseComponents/Modal'
import { useLocation, useNavigate } from 'react-router'
import TwinTrans from '../../../../../../../../../../baseComponents/TwinTrans'
import { getBasePath } from '../../../../../../../../../../utils/globals/link'
import { customerTypes, CustomerTypesKeys } from '@teinor/erp/types/company/customer/customerTypes'


interface ModalContactChangeTypeProps extends ModalContactChangeTypeLogicProps { }

const ModalContactChangeType: React.FC<ModalContactChangeTypeProps> = ({ ...rest}) => {
    const {handleOnSubmitContactType} = useModalContactChangeTypeLogic({ ...rest })
    return (
        <ModalLittle opened={true} setOpened={rest.setOpened}>
            <div className=''>
                <h2 className='pb-20'><TwinTrans transKey='transformToClient'>Transformar a cliente</TwinTrans></h2>
                <span><TwinTrans transKey='sureTransformContactToClient'>¿Seguro que quiere transformar este contacto a Cliente?</TwinTrans></span>
            </div>
            <div className='h-full pt-20'>
                <div className='flex gap-5 ml-auto'>
                    <ButtonPrimary onClick={() => handleOnSubmitContactType('B2C')}><TwinTrans transKey='changeToB2C'>Cambiar B2C</TwinTrans></ButtonPrimary>
                    <ButtonPrimary onClick={() => handleOnSubmitContactType('B2B')}><TwinTrans transKey='changeToB2B'>Cambiar B2B</TwinTrans></ButtonPrimary>
                </div>
            </div>
        </ModalLittle>
    )
}

interface ModalContactChangeTypeLogicProps extends ModalEditComponentProps {}

const useModalContactChangeTypeLogic = ({ allRowData }: ModalContactChangeTypeLogicProps) => {
    const location = useLocation()
    const basePath = getBasePath(location.pathname, 'customer')
    const navigate = useNavigate()
    
    const handleOnSubmitContactType = useCallback(async (type: CustomerTypesKeys) => {
        const urls = {
            B2C:`${basePath}/customers/${allRowData?.id}/main`,
            B2B: `${basePath}/customersB2B/${allRowData?.id}/main`,
            contact: ''
        }        
        const result = await twinFetchPostJSON('/api/app/customer/updateCustomer', {
            id: allRowData?.id,
            customer_type: customerTypes[type]
        })
        if (result) {
            navigate(urls[type])
        }
    }, [allRowData?.id, navigate, basePath])

    return { handleOnSubmitContactType }
}


export default ModalContactChangeType