import { ColumnConfiguration } from '../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types';
import { getSelectorTypeByColumn } from '../../../baseComponents/TwinTable/functions';
import { BuildedOrder, OrderFields, SingleFieldsSearch, Where } from '../../../baseComponents/TwinTable/types';
import { stringToDate } from '../../../utils/globals/date';
import { TwinDictionary, dictionaryComplexFromJsonArr } from '../../../utils/globals/dictionary';
import { SelectedColumnsTy, SelectedFiltersTy } from '../../../utils/hooks/useConfigColumns';
import { TableWhereSearchBarTy } from './types';

export const parseTaskTableHeaderWhereParams = (headerTableWhereParams: TableWhereSearchBarTy) => {
    const result: TwinDictionary = JSON.parse(JSON.stringify(headerTableWhereParams))
    result.start_date = stringToDate(headerTableWhereParams['start_date'])
    result.end_date = stringToDate(headerTableWhereParams['end_date'])
    return result
}

export const getWhereTaskFromSingleFields = (singleFieldsSearch: SingleFieldsSearch, columns: ColumnConfiguration[]) => {
    let where: Where = {}
    const dictColumns: { [id: string]: ColumnConfiguration } = dictionaryComplexFromJsonArr(columns, 'dataKey')
    for (const searchFieldKey in singleFieldsSearch) {
        const column = dictColumns[searchFieldKey]
        const typeSearch = getSelectorTypeByColumn(column)
        const searchFieldValue = singleFieldsSearch[searchFieldKey]
        const parsedSearchFieldKey = column.searchKey || searchFieldKey
        if (searchFieldValue) {
            if (typeSearch === 'selector') {
                where[parsedSearchFieldKey] = searchFieldValue
            } else {
                where[parsedSearchFieldKey] = { $iLike: '%' + searchFieldValue + '%' }
            }
        }
    }
    return where
}

export const getTaskWhereFromFilters = (selectedFilters: SelectedFiltersTy, columns: ColumnConfiguration[]) => {
    let where: Where = {}
    const dictColumns: { [id: string]: ColumnConfiguration } = dictionaryComplexFromJsonArr(columns)
    for (const searchFieldKey in selectedFilters) {
        const singleFilter = selectedFilters[searchFieldKey]
        const column = dictColumns[searchFieldKey]
        const typeSearch = getSelectorTypeByColumn(column)
        const searchFieldValue = singleFilter.value
        const parsedSearchFieldKey = column.searchKey || column.dataKey
        const excluded = singleFilter.excluded || false
        if (searchFieldValue) {
            if (typeSearch === 'singleSelector') {
                where[parsedSearchFieldKey] = searchFieldValue
            }else if (typeSearch === 'selector') {
                if (excluded) {
                    where[parsedSearchFieldKey] = { $notIn: searchFieldValue }
                } else {
                    where[parsedSearchFieldKey] = { $in: searchFieldValue }
                }
            } else {
                if (Array.isArray(searchFieldValue)) {
                    let myOrAnd = []
                    for (const element of searchFieldValue) {
                        myOrAnd.push(getValueOfSingleFilterForWriteFilter(element, excluded))
                    }
                    if (excluded) {
                        where[parsedSearchFieldKey] = { $and: myOrAnd }
                    } else {
                        where[parsedSearchFieldKey] = { $or: myOrAnd }
                    }
                } else {
                    where[parsedSearchFieldKey] = getValueOfSingleFilterForWriteFilter(searchFieldValue, excluded)
                }
            }
        }
    }
    return where
}

const getValueOfSingleFilterForWriteFilter = (value: string, excluded: boolean) => {
    let operator = '%'
    if (value.includes('*')) {
        operator = ''
        value = value.replaceAll('*', '%')
    }
    if (excluded) {
        return { $notILike: operator + value + operator }
    }
    return { $iLike: operator + value + operator }
}

export const buildOrderTaskTable = (orderFields: OrderFields, columns: ColumnConfiguration[]) => {
    const buildedOrder: BuildedOrder = []
    const dictColumns: { [id: string]: ColumnConfiguration } = dictionaryComplexFromJsonArr(columns)
    for (const orderFieldKey in orderFields) {
        const myOrderFieldKey = dictColumns?.[orderFieldKey]?.sortKey || orderFieldKey
        const orderFieldValue = orderFields[orderFieldKey]
        buildedOrder.push([myOrderFieldKey, orderFieldValue])
    }
    return buildedOrder
}

export const getTaskCustomFieldsIds = (columns: SelectedColumnsTy) => {
    const result: string[] =[]
    for (const key in columns) {
        if(String(key).includes('customField_')){
            const id = key.split('_')
            if(id?.[1]){
                result.push(id[1])
            }
        }
    }
    return result
}
