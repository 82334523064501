import { CustomerGroupModelType } from '@teinor/erp/types/company/customer/customerGroup'
import { MediumModalCreateEdit } from '../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalComponentProps } from '../../../../../../../baseComponents/ModalsLayouts/types'
import { FormRender } from '../../../../../../../forms/FormRenderer/types'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import useCustomerTypesDict from '../../../../../../../utils/hooks/customer/useCustomerTypesDict'

interface ModalCreateGroupCustomerProps extends ModalComponentProps { }

const ModalCreateGroupCustomer: React.FC<ModalCreateGroupCustomerProps> = ({ ...rest }) => {
    const { t } = useTwinTranslation()
    const {dictCustomerType} = useCustomerTypesDict()
    
    const fields: FormRender<CustomerGroupModelType> = [
        {
            cols: 2,
            elements: [
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'customer_type',
                    label: t('customerType', 'Tipo de cliente'),
                    items: dictCustomerType,
                    component: 'CustomSelect',
                    required: true
                }
            ]
        }
    ]
    return (
        <MediumModalCreateEdit fields={fields} url={'/api/app/customer/customerGroup/createCustomerGroup'} translations={{
            title: t('createGroup', 'Crear grupo'),
            button: t('create', 'Crear')
        }} haveButtonPermissions={true} {...rest} />
    )
}

export default ModalCreateGroupCustomer