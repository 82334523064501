import { LangModelType } from '@teinor/erp/types/company/config/lang'
import { ComponentWithPermissions } from '../../../../../baseComponents/CreateRoutering/types'
import useTwinTranslation from '../../../../../utils/hooks/useTwinTranslation'
import ModalCreateLang from './ModalCreateLang'
import TwinTable from '../../../../../baseComponents/TwinTable'
import { CustomRenderLanguageName } from '../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import BaseScreenTable from '../../../../../baseComponents/TwinTable/BaseScreenTable'
import { ColumnTableSchema } from '../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import { TableComponentProps } from '../../../../../baseComponents/TwinTable/BaseScreenTable/types'

interface ConfigLangsProps extends ComponentWithPermissions {}

const ConfigLangs: React.FC<ConfigLangsProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()

    const pageData = {
        title: t('langs', 'Idiomas'),
        newText: t('newLanguage', 'Nuevo idioma'),
    }

    return (
        <BaseScreenTable TableComponent={TableLangs} CreateModalComponent={ModalCreateLang} pageData={pageData} userPermissions={userPermissions} />
    )
}

interface TableLangsProps extends TableComponentProps {}

const TableLangs: React.FC<TableLangsProps> = ({ setTableInstance }) => {
    const { t } = useTwinTranslation()

    const columns: ColumnTableSchema<LangModelType> = [
        {
            id: 'iso',
            dataKey: 'id',
            width: 200,
            label: t('isoCode', 'Código ISO'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('isoCode', 'Código ISO') }
            }
        },
        {
            id: 'lang',
            dataKey: 'id',
            label: t('language', 'Idioma'),
            customRender: (parameterValue) => <CustomRenderLanguageName languageId={parameterValue} />,
            generalSearchable: false
        }
    ]
    return (
        <TwinTable  name='langs' columns={columns} getDataFrom='/api/app/config/lang/getAllLangs'  setTableInstance={setTableInstance}/>
    )
}

export default ConfigLangs