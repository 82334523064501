import { CustomerGroupModelType } from '@teinor/erp/types/company/customer/customerGroup'
import { ComponentWithPermissions } from '../../../../../../baseComponents/CreateRoutering/types'
import { permissionCheck } from '../../../../../../baseComponents/PermissionChecker/function'
import TwinTable from '../../../../../../baseComponents/TwinTable'
import BaseScreenTable from '../../../../../../baseComponents/TwinTable/BaseScreenTable'
import { TableComponentProps } from '../../../../../../baseComponents/TwinTable/BaseScreenTable/types'
import { CustomRenderString, DeleteRow } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import ModalCreateGroupCustomer from './ModalCreateGroupCustomer'
import useCustomerTypesDict from '../../../../../../utils/hooks/customer/useCustomerTypesDict'


interface GroupsCustomersProps extends ComponentWithPermissions { }

const GroupsCustomers: React.FC<GroupsCustomersProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()

    const pageData = {
        title: t('groupsCustomers', 'Grupos de clientes'),
        newText: t('newGroup', 'Nuevo grupo'),
    }

    return (
        <BaseScreenTable TableComponent={TableGroupsCustomers} CreateModalComponent={ModalCreateGroupCustomer} pageData={pageData} userPermissions={userPermissions} />
    )
}

interface TableGroupsCustomersProps extends TableComponentProps { }

const TableGroupsCustomers: React.FC<TableGroupsCustomersProps> = ({ userPermissions, setTableInstance, setOpened }) => {
    const { t } = useTwinTranslation()
    const {dictCustomerType} = useCustomerTypesDict()
    const columns: ColumnTableSchema<CustomerGroupModelType> = [
        {
            id: 'name',
            dataKey: 'name',
            width: 300,
            label: t('name', 'Nombre'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            }
        },
        {
            id: 'customer_type',
            dataKey: 'customer_type',
            label: t('customerType', 'Tipo de cliente'),
            sortable: true,
            customRender: (parameterValue) => <CustomRenderString value={dictCustomerType[parameterValue].name}/>,
            searchableComponent: {
                component: 'SearchableComponentsCustomSelectSearchBar',
                extraComponentData: { items: dictCustomerType, label: t('customerType', 'Tipo de cliente') }
            },
            generalSearchable: false
        },
        {
            id: 'delete',
            dataKey: 'id',
            label: '',
            width: 50,
            customRender: (parameterValue, allRowData) => <DeleteRow key={'delete' + parameterValue} {...{ parameterValue, allRowData }} onClick={(_idk, allRowData) => setOpened({ type: 'delete', allRowData })} />,
            havePermission: permissionCheck(userPermissions, 'delete')
        }
    ]
    return (
        <TwinTable  columns={columns} name='customerGroups' getDataFrom='/api/app/customer/customerGroup/getAllCustomersGroup'  setTableInstance={setTableInstance} />
    )
}

export default GroupsCustomers