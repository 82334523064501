import { WorkingCenterTypeModelType } from '@teinor/erp/types/company/workingCenter/workingCenterType'

export const parseSelectedWorkingCenterType = (selectedWorkingCenterTypes?: WorkingCenterTypeModelType[]) => {
    const result: number[] = []
    if (selectedWorkingCenterTypes) {
        for (const type of selectedWorkingCenterTypes) {
            result.push(type.id)
        }
    }
    return result
}