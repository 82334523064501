import { WorkingCenterModelType } from '@teinor/erp/types/company/workingCenter'
import { connect, ConnectedProps } from 'react-redux'
import { ComponentWithPermissions } from '../../../../../../../../../baseComponents/CreateRoutering/types'
import SingleFormLayout from '../../../../../../../../../baseComponents/Layout/SingleFormLayout'
import { FormRender } from '../../../../../../../../../forms/FormRenderer/types'
import { getDictionaryStates } from '../../../../../../../../../utils/globals/countries'
import { isValidDay } from '../../../../../../../../../utils/globals/numbers'
import { returnClassOfSpanTemplate } from '../../../../../../../../../utils/globals/tailwind'
import { valueOrDefaultValue } from '../../../../../../../../../utils/globals/valueOrDefaultValue'
import withLoading from '../../../../../../../../../utils/hoc/withLoading'
import useCountryState from '../../../../../../../../../utils/hooks/useCountry'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import { useTypeIdNumber } from '../../../../../../../../../utils/hooks/useTypeIdNumber'
import { AllReduxPayloads } from '../../../../../../../../../utils/reducers'
import { BillingGroupPayload } from '../../../../../../../../../utils/reducers/company/billingGroups'
import { WorkingCenterTypePayload } from '../../../../../../../../../utils/reducers/company/workingCenterTypes'
import { getDictionaryCountries } from '../../../../../../../../../utils/reducers/getters'
import { WorkingCenterModelTypeExtended } from '../../../types'
import { parseSelectedWorkingCenterType } from './function'

interface MainWorkingCenterProps extends ComponentWithPermissions {
    basicData: WorkingCenterModelType | null
    getData: () => Promise<void>
}

const MainWorkingCenter: React.FC<MainWorkingCenterProps & ReduxWorkingCenterTypes> = ({ basicData, userPermissions, getData, workingCenterTypes}) => {
    const { t } = useTwinTranslation()
    const { typesIdNumber } = useTypeIdNumber()
    const { country, setCountry } = useCountryState({ defaultCountry: basicData?.country })
    const selectedWorkingCenterTypes = parseSelectedWorkingCenterType(basicData?.WorkingCenterTypes || [])
    const fields: FormRender<WorkingCenterModelTypeExtended> = [
        {
            cols: 2,
            title: t('generalInformation', 'Información general'),
            elements: [

                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'code',
                    label: t('code', 'Código'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'type_id_number',
                    label: t('typeIdNumber', 'Tipo de documento identificación'),
                    component: 'CustomSelect',
                    items: typesIdNumber,
                    value: basicData?.type_id_number
                },
                {
                    name: 'id_number',
                    label: t('id_number', 'DNI'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'surcharge',
                    label: t('surcharge', 'Sobrecargo'),
                    component: 'CustomSelect',
                    items: {
                        false: { name: t('withoutSurcharge', 'Sin sobrecargo')},
                        true: { name: t('withSurcharge', 'Con sobrecargo')}
                    }
                },
                {
                    name: 'validate_deposits',
                    label: t('validateDeposits', 'Validar depósitos'),
                    component: 'CheckboxMargin',
                },
                {
                    name: 'stock_valid',
                    label: t('stocksValid', 'Validar Stock'),
                    component: 'CheckboxMargin',
                },
                {
                    name: 'workingCenterTypes',
                    component: 'MultipleCheckboxSelector',
                    subtitles: { subtitleOptions: t('typeWorkingCenter', 'Tipo de Centro de trabajo'), subtitleSelectedOptions: t('typeWorkingCenterSelected', 'Tipo de Centro de trabajo seleccionado')}, 
                    items: workingCenterTypes || {},
                    defaultValue: selectedWorkingCenterTypes,
                    className: returnClassOfSpanTemplate(2)
                },
            ]
        },
        {
            cols: 2,
            title: t('contactInformation', 'Datos de contacto'),
            elements: [
                {
                    name: 'phone',
                    label: t('phone', 'Teléfono'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'mobile',
                    label: t('mobile', 'Móvil'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'email',
                    label: t('email', 'Email'),
                    component: 'InputWithLabelMargin',
                    type: 'email',
                },
                {
                    name: 'id',
                    component: 'InputHidden'
                }
            ]
        },
        {
            cols: 2,
            title: t('address', 'Dirección'),
            elements: [
                {
                    name: 'address',
                    label: t('address', 'Dirección'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'additional_address',
                    label: t('additional_address', 'Dirección adicional'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'postal_code',
                    label: t('postal_code', 'Código Postal'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'city',
                    label: t('city', 'Ciudad'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'country',
                    label: t('country', 'País'),
                    component: 'CustomSelectWithSearchBar',
                    items: getDictionaryCountries(),
                    value: country,
                    onChange: (value: string) => setCountry(value),
                    required: true
                },
                {
                    name: 'state',
                    label: t('state', 'Provincia'),
                    component: 'CustomSelectWithSearchBar',
                    sortItems: true,
                    items: getDictionaryStates(country),
                    value: basicData?.state,
                },
            ]
        },
        {
            cols: 1,
            title: t('payDays', 'Días de pago'),
            elements: [
                {
                    name: 'payDays',
                    component: 'ListAdderStateFull',
                    label: t('payDays', 'Días de pago'),
                    items: basicData?.['payDays'] || [],
                    checkValid: isValidDay
                }
            ]
        }
    ]
    const parsedFields = valueOrDefaultValue(fields, userPermissions, basicData)

    return (
        <SingleFormLayout action='/api/app/workingCenter/updateWorkingCenter' onSubmit={getData} sections={parsedFields} userPermissions={userPermissions} />
    )
}

const workingCenterTypesDispatch = {
    setWorkingCenterTypes: (payload: WorkingCenterTypePayload) => ({ type: 'CHANGE_WORKINGCENTERTYPE', payload }),
    setBillingGroups: (payload: BillingGroupPayload) => ({ type: 'CHANGE_BILLINGGROUP', payload }),
}

export type ReduxWorkingCenterTypes = ConnectedProps<typeof workingCenterTypesConnect>
const mapWorkingCenterTypesConnector = (state: AllReduxPayloads) => ({ workingCenterTypes: state.workingCenterTypes, billingGroups: state.billingGroups })
const workingCenterTypesConnect = connect(mapWorkingCenterTypesConnector, workingCenterTypesDispatch)

const workingCenterTypesConnectLoading = withLoading(MainWorkingCenter, [{ fetchUrl: '/api/app/workingCenter/workingCenterType/getAllWorkingCenterTypes', propName: 'workingCenterTypes', setFunctionName: 'setWorkingCenterTypes' }, { fetchUrl: '/api/app/config/billingGroup/getAllBillingGroups', propName: 'billingGroups', setFunctionName: 'setBillingGroups' }])

const MainWorkingCenterConnect = workingCenterTypesConnect(workingCenterTypesConnectLoading)

export default MainWorkingCenterConnect