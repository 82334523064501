export interface ChangeCurrentPageAction {
    type: 'CHANGE_CURRENTPAGE'
    payload: CurrentPagePayload
}
export interface SetCurrentPageVariableAction {
    type: 'SET_CURRENTPAGE_VARIABLES'
    payload: string[]
}

export type CurrentPageActions = ChangeCurrentPageAction | SetCurrentPageVariableAction
interface CurrentPage {
    routes: string[]
    variables?: string[]
}
export type CurrentPagePayload = CurrentPage  | null
export default function currentPage(state: CurrentPagePayload = null, action: CurrentPageActions): CurrentPagePayload {
    switch (action.type) {
        case 'CHANGE_CURRENTPAGE':
            return action.payload
        case 'SET_CURRENTPAGE_VARIABLES':
            if (!state) {
                return state
            }
            return state = {...state, variables: [...action.payload]}
        default:
            return state
    }
}