import { WhiteBox } from '../../../baseComponents/AppLayout/WhiteBox'
import LoadingSpinner from '../../../baseComponents/LoaderDecider/LoadingSpinner'
import TwinBigCalendar, { TwinBigCalendarProps } from '../../../baseComponents/TwinBigCalendar'
import './taskCalendar.sass'

interface TaskCalendarProps extends TwinBigCalendarProps {}

const TaskCalendar: React.FC<TaskCalendarProps> = ({className, ...calendar}) => {
    return (
        <WhiteBox className='p-30 flex-auto flex-col flex'>
            {calendar.events ? <TwinBigCalendar defaultView='week' {...calendar} className={'task_calendar ' + (className || '')}  tooltipAccessor={() => ''}/> : <LoadingSpinner />}
        </WhiteBox>
    )
}

export default TaskCalendar