import { faHouse } from '@fortawesome/pro-light-svg-icons'
import ManageCompany from './screens/ManageCompany'
import SelectCompany from './screens/SelectCompany'
import {AppLayoutAdmin} from '../../baseComponents/AppLayout'
import CreateRoutering from '../../baseComponents/CreateRoutering'
import { Routes } from '../../baseComponents/CreateRoutering/types'
import useTwinTranslation from '../../utils/hooks/useTwinTranslation'
import Modules from './screens/ManageCompany/Modules'

export const AdminRoutesPaths = (): Routes => {
    const { t } = useTwinTranslation()
    return [
        {
            link: '/',
            text: t('home', 'Inicio'),
            icon: faHouse,
            component: SelectCompany,
            childs: [
                {
                    link: '/*',
                    text: t('home', 'Inicio'),
                    icon: faHouse,
                    component: SelectCompany,
                },
                {
                    link: '/company/:id',
                    text: t('company', 'Empresa'),
                    component: ManageCompany,
                    childs: [
                        {
                            link: '/modules',
                            text: t('modules', 'Módulos'),
                            component: Modules,
                        }
                    ]
                }
            ]
        }
    ]
}

export interface AdminRoutesProps { };

const AdminRoutes: React.FC<AdminRoutesProps> = () => {
    return <AppLayoutAdmin><CreateRoutering routes={AdminRoutesPaths()}></CreateRoutering></AppLayoutAdmin>
}

export default AdminRoutes