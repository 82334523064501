import { useCallback, useEffect, useRef, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { InputProps, InputWithLabelMargin } from '..'
import { twinFetchPostJSON } from '../../../utils/globals/data'
import { Modify } from '../../../utils/globals/types'
import useOnClickOutside from '../../../utils/hooks/useOnClickOut'
import { RenderProductsInputSearchProduct } from './Subcomponents'
import { InputSearchedProductsTy, InputSearchProductFieldType, InputSearchProductInputChangeSearchFunc } from './types'
import './inputSearch.sass'
import { TwinDictionary } from '../../../utils/globals/dictionary'
import { RowData } from '../../../baseComponents/TwinTable/types'
import CustomSelect from '../../CustomSelect'

export type InputSearchProductProps = Modify<InputProps, InputSearchProductNoValueProps & {
    defaultValue?: string
    allRowData?: RowData
    productName?: string
}>


const InputSearchProduct: React.FC<InputSearchProductProps> = ({ defaultValue, productName, name, readOnly, field, label, required}) => {
    if(readOnly && defaultValue){
       return <CustomSelect value={defaultValue} name='ProductId' items={{[defaultValue]: productName}} readOnly={true} {...{label}} required={required}/>
    }
    return <InputSearchProductNoValue {...{name, field, label}} required={required}/>
}

interface InputSearchProductNoValueProps  extends InputSearchProductNoValueLogicProps {
    name: string
    field: InputSearchProductFieldType
    label: string
    required: boolean
}

const InputSearchProductNoValue: React.FC<InputSearchProductNoValueProps> = ({name, field, label, required}) => {
    const { searchValue, changeSearchValue, listProducts, onFocus, ref, product, handleSelectProduct} = useInputSearchProductNoValueLogic()

    return (
        <div className='relative' ref={ref}>
            <InputWithLabelMargin className='input_searchbar_product' value={product?.name}  onChange={changeSearchValue} {...{ searchValue, onFocus, label, required }}  />
            <RenderProductsInputSearchProduct products={listProducts} {...{ field, handleSelectProduct}} />
            {product ?<input value={product.id} type='hidden' name={name} required={required} /> : null}
        </div>
    )
}


interface InputSearchProductNoValueLogicProps {}

const useInputSearchProductNoValueLogic = () => {
    const [listProducts, setListProducts] = useState<InputSearchedProductsTy | null>(null)
    const [searchValue, setSearchValue] = useState<string>('')
    const [product, setProduct] = useState<TwinDictionary| null>(null)
    const [debounceValue] = useDebounce(searchValue, 200)
    const ref = useRef<any>(null)
    
    const changeSearchValue: InputSearchProductInputChangeSearchFunc = useCallback((myvalue) => {
        setSearchValue(myvalue)
    }, [setSearchValue])

    const onFocus = useCallback(() => {
        setProduct(null)
        setListProducts({})
    }, [setProduct, setListProducts])

    const onBlur = useCallback(() => {
        setSearchValue('')
        setListProducts(null)
    }, [])

    const handleSelectProduct = useCallback((id: number, name: string) => {
        setProduct({'name': name, 'id': id})
        onBlur()
    }, [setProduct, onBlur])
    
    useOnClickOutside(ref, onBlur)

    const getAllProducts = useCallback(async (mySearchValue: string) => {       
        const result = await twinFetchPostJSON('/api/app/product/getAllProducts', {
            where: {
                '$or': {
                    'reference' : { $iLike: '%' + mySearchValue  + '%'},
                    'name' : { $iLike: '%' + mySearchValue  + '%'},
                    'code_bar' : { $iLike: '%' + mySearchValue  + '%'},
                }
            }, 
            limit: 6
        })
        setListProducts(result)
    }, [])
        
    useEffect(() => {
        if (debounceValue !== '') {
            getAllProducts(debounceValue)
        } else if (debounceValue === '') {
            setListProducts((old) => {
                if (old) {
                    return []
                }
                return null
            })
        }
    }, [debounceValue, getAllProducts])

    return { searchValue, changeSearchValue, listProducts, onFocus, ref, handleSelectProduct, product}
}

export default InputSearchProduct