import { WCalHolidayUsedDayModelType, WCalHolidayUsedDayTypeKeysInversed } from '@teinor/erp/types/company/WCalParent/WCal/WCalHolidayBag/WCalHolidayUsedDay'
import { useCallback, useEffect, useState } from 'react'
import { ButtonOrLoader } from '../../../../../../../baseComponents/Button'
import { DisplayStatusWithoutIcon } from '../../../../../../../baseComponents/Displays/DisplayStatus'
import { ModalMedium } from '../../../../../../../baseComponents/Modal'
import ModalDeleteFilled from '../../../../../../../baseComponents/ModalsLayouts/ModalDeleteFilled'
import { ModalEditComponentProps } from '../../../../../../../baseComponents/ModalsLayouts/types'
import PermissionChecker from '../../../../../../../baseComponents/PermissionChecker'
import { VirtualTableListingStateLess } from '../../../../../../../baseComponents/TwinTable/VirtualTableListing'
import { CustomRenderDate, CustomRenderString } from '../../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import TwinTrans from '../../../../../../../baseComponents/TwinTrans'
import FormRenderer from '../../../../../../../forms/FormRenderer'
import { FormRender } from '../../../../../../../forms/FormRenderer/types'
import { WCalHolidayUsedDayModelTypeExtended } from '../../../../../../../specificComponents/WCal/WCalHolidayUsedDay/types'
import { twinFetchPostJSON } from '../../../../../../../utils/globals/data'
import { displayDate } from '../../../../../../../utils/globals/date'
import { TwinDictionary } from '../../../../../../../utils/globals/dictionary'
import useEditDeleteModal from '../../../../../../../utils/hooks/useEditDeleteModal'
import useStatusRequestTypes from '../../../../../../../utils/hooks/useStatusRequestTypes'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import { changeErrorMessage, changeSuccessMessage } from '../../../../../../../utils/reducers/reduxDispatch'

interface ModalEditWCHolidayRequestsProps extends  ModalEditWCHolidayRequestsLogicProps {
}

const ModalEditWCHolidayRequests: React.FC<ModalEditWCHolidayRequestsProps> = ({ userPermissions, setOpened, allRowData, onSubmit }) => {
    const { manageWorkingCalendarHolidayUsedDay, data, openModal, setOpenModal } = useModalEditWCHolidayRequestsLogic({ allRowData, setOpened, onSubmit })
    const { t } = useTwinTranslation()
    const status = useStatusRequestTypes()

    const statusButtons: TwinDictionary = {
        'approved': <ButtonOrLoader onClick={() => manageWorkingCalendarHolidayUsedDay('approved')} textButton={t('approve', 'Aprobar')} buttonIsDisabled={false} />,
        'denied': <ButtonOrLoader onClick={() => manageWorkingCalendarHolidayUsedDay('denied')} textButton={t('deny', 'Denegar')} buttonIsDisabled={false} className='button_red' />,
        'cancel': <ButtonOrLoader onClick={() => setOpenModal({ type: 'edit', allRowData: allRowData ? allRowData : []})} textButton={t('cancelHolidays', 'Cancelar vacaciones')} buttonIsDisabled={false} className='button_red'/>
    }

    const renderThis: JSX.Element[] = []
    if (data?.approved === 0) {
        renderThis.push(statusButtons['approved'])
        renderThis.push(statusButtons['denied'])
    } else if (data?.approved === 1) {
        renderThis.push(statusButtons['cancel'])
    }

    const fields: FormRender<WCalHolidayUsedDayModelTypeExtended> = [
        {
            cols: 1,
            elements: [
                {
                    name: 'name',
                    component: 'TextField',
                    label: t('name', 'Nombre'),
                    value: data?.['WCalHolidayBag']?.name,
                },
                {
                    name: 'EmployeeId',
                    component: 'TextField',
                    value: data?.WCalHolidayBag?.Employee?.fullname_short,
                    label: t('employee', 'Empleado')
                },
                {
                    name: 'from',
                    component: 'TextField',
                    label: t('from', 'Desde'),
                    value: displayDate(new Date(data?.from || ''))
                },
                {
                    name: 'to',
                    component: 'TextField',
                    label: t('to', 'Hasta'),
                    value: displayDate(new Date(data?.to || '')),
                },
                {
                    name: 'approved',
                    component: 'TextField',
                    label: t('status', 'Estado'),
                    value: status[parseInt(String(data?.approved))]?.name
                },
                {
                    name: 'approvedDate',
                    component: 'TextField',
                    label: t('approvedDate', 'Fecha de aprobación'),
                    value: data?.approvedDate ? displayDate(new Date(data?.approvedDate)) : ''
                },
                {
                    name: 'id',
                    component: 'InputHidden',
                    value: data?.id
                },
            ]
        },
    ]
    return (
        <ModalMedium opened={true} setOpened={setOpened} onClickOut={false} className={'flex flex-col'}>
            <div className='mb-25'>
            <h2><TwinTrans transKey='manageHolidays'>Gestionar vacaciones</TwinTrans></h2>
            </div>
            <div className={'flex flex-auto h-1'}>
                <div className='overflow-auto flex flex-auto flex-col notFlexAutoFormRender'>
                    {fields.length ?
                        <FormRenderer sections={fields} className='grid_mincontent'>
                        </FormRenderer>
                        : null}
                    <MatchHolidays data={data || undefined} />
                </div>
            </div>
            <div className='mt-30 ml-auto flex gap-15 edit_holiday_requests_footer'>
                <PermissionChecker userPermissions={userPermissions!} permission={'update'}>
                    {renderThis}
                </PermissionChecker>
            </div>
            {openModal?.type === 'edit' && <ModalCancelEmployeeHolidaysRequest {...{ setOpened: () => setOpenModal(null) }} allRowData={openModal.allRowData} onSubmit={(res, send) => { onSubmit(res, send); setOpened(null) }} />}
        </ModalMedium>
    )
}

interface ModalEditWCHolidayRequestsLogicProps extends ModalEditComponentProps {}

const useModalEditWCHolidayRequestsLogic = ({ allRowData, setOpened, onSubmit }: ModalEditWCHolidayRequestsLogicProps) => {
    const { t } = useTwinTranslation()
    const { openModal, setOpenModal } = useEditDeleteModal()
    const message = t('successfullyCompleted', 'Realizado correctamente')
    const [data, setData] = useState<WCalHolidayUsedDayModelTypeExtended | null>(null)
    const manageWorkingCalendarHolidayUsedDay = useCallback(async (status: WCalHolidayUsedDayTypeKeysInversed) => {
        const result = await twinFetchPostJSON('/api/app/workingCalendar/workingCalendarHolidayBag/manageHolidayRequest', {
            id: allRowData?.id,
            approved: status === 'approved' ? 1 : 2
        })
        if (result) {
            onSubmit(result, {})
            changeSuccessMessage(message)
            setOpened(null)
        } else {
            changeErrorMessage(true)
        }
    }, [allRowData?.id, setOpened, onSubmit, message])
    
    const getUsedDaysData = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/workingCalendar/workingCalendarHolidayBag/getWCalHolidayBagUsedDaysInstanceForManage', { id: allRowData?.id })
        if (result) {
            setData(result)
        }
    }, [setData, allRowData?.id])

    useEffect(() => {
        getUsedDaysData()
    }, [getUsedDaysData])

    return { manageWorkingCalendarHolidayUsedDay, data, openModal, setOpenModal, getUsedDaysData }
}

interface MatchHolidaysProps {
    data?: WCalHolidayUsedDayModelTypeExtended
}

const MatchHolidays: React.FC<MatchHolidaysProps> = ({ data }) => {
    const {t} = useTwinTranslation()
    const columns: ColumnTableSchema<WCalHolidayUsedDayModelType> = [
        {
            id: 'from',
            dataKey: 'from',
            label: t('from', 'Desde'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />,
        },
        {
            id: 'to',
            dataKey: 'to',
            label: t('to', 'Hasta'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />,
        },
        {
            id: 'EmployeeId',
            dataKey: 'EmployeeId',
            label: t('employee', 'Empleado'),
            customRender: (parameterValue, allRowData) => <CustomRenderString value={allRowData.WCalHolidayBag.Employee.fullname_short} />,
        },
        {
            id: 'approved',
            dataKey: 'approved',
            label: t('status', 'Estado'),
            customRender: (parameterValue) => <RenderUsedDayStatus value={parameterValue} />,
            width: 220
        },
    ]
    return (
        <div className='h-200'>
            <h2 className='text-18 text-gray-51 mb-10'>
                <TwinTrans transKey='matchHolidays'>Vacaciones que coinciden</TwinTrans>
            </h2>
            <VirtualTableListingStateLess tableData={data?.matchHolidays as any || []} rowHeight={48} headerHeight={48} name='HolidayMatchDetail' columns={columns} setSelectedColumnsAndFilters={() => { }} />
        </div>
    )
}

interface RenderBuyOrderStatusProps {
    value: string
}

const RenderUsedDayStatus: React.FC<RenderBuyOrderStatusProps> = ({ value }) => {
    const status = useStatusRequestTypes()
    const parsedValue = parseInt(value)
    return <DisplayStatusWithoutIcon colorText={status[parsedValue].colorText} text={status[parsedValue].name} />
}

interface ModalCancelEmployeeHolidaysRequestProps extends ModalCancelEmployeeHolidaysRequestLogicProps { }

const ModalCancelEmployeeHolidaysRequest: React.FC<ModalCancelEmployeeHolidaysRequestProps> = ({ onSubmit, setOpened, allRowData }) => {
    const { cancelEmployeeHolidays } = useModalCancelEmployeeHolidaysRequestLogic({ onSubmit, setOpened, allRowData })
    const { t } = useTwinTranslation()

    return (
        <ModalDeleteFilled opened={true} setOpened={setOpened} onCancel={() => setOpened(null)} onAccept={cancelEmployeeHolidays} translations={{
            title: t('sureCancelWCHolidayRequest', '¿Seguro que quieres cancelar estas vacaciones?'),
            subtitle: t('onceCanceledHolidaysNotRecoverM', 'Una vez canceladas no podrás recuperarlas')
        }} />
    )
}

interface ModalCancelEmployeeHolidaysRequestLogicProps extends ModalEditComponentProps { }

const useModalCancelEmployeeHolidaysRequestLogic = ({ onSubmit, setOpened, allRowData }: ModalCancelEmployeeHolidaysRequestLogicProps) => {
    const { t } = useTwinTranslation()

    const message = t('successfullyCompleted', 'Realizado correctamente')

    const cancelEmployeeHolidays = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/workingCalendar/workingCalendarHolidayBag/cancelHolidayRequest', {
            id: allRowData?.id
        })
        if (result) {
            onSubmit(result, {})
            changeSuccessMessage(message)
            setOpened(null)
        } else {
            changeErrorMessage(true)
        }
    }, [allRowData?.id, setOpened, onSubmit, message])

    return { cancelEmployeeHolidays }
}


export default ModalEditWCHolidayRequests