import { SetTaskDataExtended, TaskExtendedTy } from '../types'
import { useCallback } from 'react'
import { twinFetchPostJSON } from '../../../../utils/globals/data'
import { TaskCFieldPayload } from '../../../../utils/reducers/company/taskCFields'
import CustomSelectColor from '../../../../forms/CustomSelect/CustomSelectColor'


interface TaskCFieldsProps extends TaskCFieldsLogicProps {
    taskCFields: TaskCFieldPayload
}

const TaskCFields: React.FC<TaskCFieldsProps> = ({data, taskCFields, setData}) => {
    const { updateTaskCField } = useTaskCFieldsLogic({ setData, data })
    const renderThis: JSX.Element[] = []
    if (taskCFields && Object.keys(taskCFields)?.length) {
        for (const key in taskCFields) {
            const customField = taskCFields[key]
            const items = customField.dictTaskCFieldValues
            const selectedValueId = data.dictTaskCFieldValues[customField.id]
            const selected = items[selectedValueId]
            renderThis.push(<CustomSelectColor key={key}  label={customField.name} items={items} value={selected?.id} onChange={(value) => updateTaskCField(value, items[value].TaskCFieldId)} />)
        }
    }
    return (
        <div className='grid grid-cols-2 gap-x-60 gap-y-19 mb-20'>
            {renderThis}
        </div>
    )
}

interface TaskCFieldsLogicProps {
    data: TaskExtendedTy
    setData: SetTaskDataExtended
}

const useTaskCFieldsLogic = ({ data, setData }: TaskCFieldsLogicProps) => {
    
    const updateTaskCField = useCallback(async (TaskCFieldValueId: number, TaskCFieldId: number) => {
        const result = await twinFetchPostJSON('/api/app/task/assignCustomFieldValueToTask', { TaskId: data?.id, TaskCFieldValueId, TaskCFieldId }, { myExtraHeaders: { 'type-update': 'singleTask' }})
        if (result) {
            setData((old) => {
                if (!old) {
                    return null
                }
                const copyOld = JSON.parse(JSON.stringify(old))
                copyOld.dictTaskCFieldValues[TaskCFieldId] = TaskCFieldValueId
                return { ...copyOld }
            })
        }
    }, [data, setData])
    

    return { updateTaskCField }
}


export default TaskCFields