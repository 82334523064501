import './switchlight.sass'
interface SwitchLightProps {
    active: boolean
    label?: string
    onChange: (value: boolean) => void
    className?: string
}

const SwitchLight: React.FC<SwitchLightProps> = ({ active, label, onChange, className }) => {
    return (
        <div className={'switch_light_parent flex items-center cursor-pointer ' + (className ||  '')} onClick={() => onChange(!active)}>
            <div className={'switch_light flex items-center ' + (active ? 'active' : '')}>
                <div className='ball'></div>
            </div>
            {label ? <span>{label}</span> : null}
        </div>
    )
}

export const SwitchLightInversed: React.FC<SwitchLightProps> = ({ active, label, onChange, className }) => {
    return (
        <div className={'switch_light_parent flex items-center cursor-pointer ' + (className ||  '')} onClick={() => onChange(!active)}>
            {label ? <span className='mr-22'>{label}</span> : null}
            <div className={'switch_light flex items-center ' + (active ? 'active' : '')}>
                <div className='ball'></div>
            </div>
        </div>
    )
}

export default SwitchLight