import { SupplierModelType } from '@teinor/erp/types/company/supplier'
import { SupplierPaymentMethodModelType } from '@teinor/erp/types/company/supplier/supplierPaymentMethod'
import { dictionaryComplexFromJsonArr } from '../../globals/dictionary'
import { SupplierBankModelType } from '@teinor/erp/types/company/supplier/supplierBank'
import { SupplierServiceModelType } from '@teinor/erp/types/company/supplier/supplierService'



type SupplierModelDictExtended = SupplierModelType & {
    dictPaymentMethods: {[id: number]: SupplierPaymentMethodModelType}
    dictSuppliersBanks: {[id: number]: SupplierBankModelType}
    dictSuppliersServices: {[id: number]: SupplierServiceModelType}
} 
export interface SuppliersAction {
    type: 'CHANGE_SUPPLIER'
    payload: SupplierModelType[]
}
export type DictionarySupplier = {[id: number]: SupplierModelDictExtended}
export type SuppliersResult = { suppliers: DictionarySupplier, creditors: DictionarySupplier, all: DictionarySupplier }
export type SupplierPayload = null | SuppliersResult
export default function supplier(state: SupplierPayload = null, action: SuppliersAction): SuppliersResult | null {
    switch (action.type) {
        case 'CHANGE_SUPPLIER':
            const result : SuppliersResult = { suppliers: {}, creditors:  {}, all: {}}
            for (const supplier of action.payload) {
                const id = supplier.id
                const type = supplier.type
                const item: SupplierModelDictExtended = { ...supplier, dictPaymentMethods: {}, dictSuppliersBanks: {}, dictSuppliersServices: {}}
                if (supplier.SupplierPaymentMethods && supplier.SupplierPaymentMethods.length) {
                    item.dictPaymentMethods = dictionaryComplexFromJsonArr(supplier.SupplierPaymentMethods)
                }
                if (supplier.SupplierBanks && supplier.SupplierBanks.length) {
                    item.dictSuppliersBanks = dictionaryComplexFromJsonArr(supplier.SupplierBanks)
                }
                if (supplier.SupplierServices && supplier.SupplierServices.length) {
                    item.dictSuppliersServices = dictionaryComplexFromJsonArr(supplier.SupplierServices)
                }
                if(type === 0){
                    result.suppliers[id] = {...item}
                }else if(type === 1){
                    result.creditors[id] = {...item}
                }
                result.all[id] = {...item}
            }
        return state = result
        default:
            return state
    }
}