import { memo, useMemo } from 'react'
import TwinBigCalendar, { TwinBigCalendarProps } from '../../../baseComponents/TwinBigCalendar'
import { EventsWorkingCalendar } from '../../../baseComponents/WorkingCalendar'
import TwinIcon from '../../../baseComponents/TwinIcon'
import { Link } from 'react-router-dom'
import { memoChecker } from '../../../utils/globals/components'
import { useLocation, useParams } from 'react-router'
import { getBasePath } from '../../../utils/globals/link'
import { TwinBigCalendarEvents } from '../../../baseComponents/TwinBigCalendar/types'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons'
import { ButtonPrimary } from '../../../baseComponents/Button'
import TwinTrans from '../../../baseComponents/TwinTrans'
import './whiteboxcalendarevents.sass'

interface WhiteBoxCalendarEventsProps {
    className?: string
    calendarProps?: TwinBigCalendarProps
    events: TwinBigCalendarEvents | null | 'LAST_YEAR_EVENTS'
    handlePreviousYearEvents: () => Promise<void>
}

const WhiteBoxCalendarEvents: React.FC<WhiteBoxCalendarEventsProps> = memo(({ className, events, calendarProps, handlePreviousYearEvents }) => {
    let myEvents = events !== 'LAST_YEAR_EVENTS' ? events : []
    const { yearParsed, path, year } = useWhiteBoxCalendarEventsParentLogic()
    return (
        <div className='pt-30 mt-40 white_box flex flex-auto h-1 white_box_calendar_events'>
            <div className='flex flex-auto flex-col white_box_calendar_events_wrapper'>
                <div className='flex items-center calendar_toolbar extended_margin'>
                    <Link className='cursor-pointer mr-24 hover:text-green-21' to={path + '/' + (yearParsed - 1)}><TwinIcon className='h-24' icon={faChevronLeft} /></Link>
                    <Link className='cursor-pointer mr-24 hover:text-green-21' to={path + '/' + (yearParsed + 1)}><TwinIcon className='h-24' icon={faChevronRight} /></Link>
                    <div className='toolbar-label  text-left text-gray-51 capitalize'>{year}</div>
                </div>
                <TwinBigCalendar {...calendarProps} events={myEvents || []} date={new Date((year + '/01/01'))} className='whitebox_calendar_events_big_calendar' />
            </div>
            <EventsWorkingCalendar events={myEvents} >
                {events === 'LAST_YEAR_EVENTS' ? <ButtonPrimary onClick={handlePreviousYearEvents}><TwinTrans transKey='copyEvents'>Copiar eventos del año pasado</TwinTrans></ButtonPrimary> : undefined}
            </EventsWorkingCalendar>
        </div>
    )
}, (oldProps, nextProps) => memoChecker(oldProps, nextProps, ['className', 'events', 'calendarProps']))

const useWhiteBoxCalendarEventsParentLogic = () => {
    const { id, year } = useParams()
    const location = useLocation()
    const yearParsed = parseInt(year || '0')
    const path = useMemo(() => getBasePath(location.pathname, (id || '0')), [id, location.pathname])
    return { yearParsed, path, year }
}

export default WhiteBoxCalendarEvents