import { EmployeeExpenseModelType } from '@teinor/erp/types/company/userInner/employee/employeeExpense'
import { EmployeeExpenseRowTypeModelAdd } from '@teinor/erp/types/company/userInner/employee/employeeExpense/employeeExpenseRow/employeeExpenseRowType'
import { useCallback, useEffect, useState } from 'react'
import { MediumModalCreateEdit } from '../../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../../../../../../baseComponents/ModalsLayouts/types'
import FormRenderer from '../../../../../../../../forms/FormRenderer'
import { FormRender } from '../../../../../../../../forms/FormRenderer/types'
import { BeforeSubmitHandler } from '../../../../../../../../forms/TwinForm/types'
import EmployeeExpenseTableExpenses from '../../../../../../../../specificComponents/Employee/EmployeeExpenses/EmployeeExpenseEditModal/EmployeeExpenseTableExpenses'
import { EmployeeExpenseModelAddExtended } from '../../../../../../../../specificComponents/Employee/EmployeeExpenses/EmployeeExpenseEditModal/types'
import { twinFetchPostJSON } from '../../../../../../../../utils/globals/data'
import { returnClassOfSpanTemplate } from '../../../../../../../../utils/globals/tailwind'
import useTwinTranslation from '../../../../../../../../utils/hooks/useTwinTranslation'
import { getActiveCurrencies, getConfigParam, getISOCurrencyMain, getUserInfo } from '../../../../../../../../utils/reducers/getters'
import { changeErrorMessage } from '../../../../../../../../utils/reducers/reduxDispatch'
import { calculateEmployeeExpensesTotals } from '@teinor/erp/prices/expense'

type ModalCreateEmployeeExpensesProps = ModalEditComponentProps & {}

const ModalCreateEmployeeExpenses: React.FC<ModalCreateEmployeeExpensesProps> = ({ userPermissions, ...rest }) => {
    const { t } = useTwinTranslation()
    const { expensesType, beforeSubmitExpensesHandler, expenseInstance, changeWithCalculate, changeFieldExpenseInstance, setExpenseInstance } = useModalCreateEmployeeExpensesLogic()
    const user = getUserInfo()
    const allCurrencies = getActiveCurrencies()

    const fields: FormRender<EmployeeExpenseModelType> = [
        {
            cols: 2,
            elements: [
                {
                    name: 'EmployeeId',
                    component: 'InputHidden',
                    value: user?.Employee?.id,
                },
                {
                    name: 'kmPrice',
                    component: 'InputHidden',
                    value: expenseInstance?.kmPrice,
                },
                {
                    name: 'dateStart',
                    label: t('dateStart', 'Desde'),
                    component: 'InputCalendarStateFull',
                    value: expenseInstance?.dateStart,
                    required: true
                },
                {
                    name: 'dateEnd',
                    label: t('dateEnd', 'Hasta'),
                    component: 'InputCalendarStateFull',
                    value: expenseInstance?.dateEnd,
                    required: true
                },
                {
                    name: 'CurrencyId',
                    label: t('currency', 'Moneda'),
                    component: 'CustomSelect',
                    items: allCurrencies,
                    value: expenseInstance?.CurrencyId || '',
                    onChange: (value) => changeFieldExpenseInstance(value, 'CurrencyId'),
                },
                {
                    name: 'location',
                    label: t('location', 'Ubicación'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true,
                    defaultValue: expenseInstance?.location,
                },
                {
                    name: 'km',
                    label: t('km', 'Km'),
                    component: 'InputNumberWithLabelMargin',
                    value: String(expenseInstance?.km),
                    onChange: (value) => changeFieldExpenseInstance(value, 'km'),
                    onBlur: changeWithCalculate,
                },
                {
                    name: 'kmPrice',
                    label: t('kmPrice', 'Precio del Km'),
                    component: 'DisplayPriceField',
                    value: parseFloat(String(expenseInstance?.kmPrice)),
                    CurrencyId: expenseInstance?.CurrencyId || '',
                },
                {
                    name: 'abroad',
                    label: t('abroad', 'Extranjero'),
                    component: 'CheckboxMargin',
                    defaultChecked: expenseInstance?.abroad,
                },
                {
                    name: 'overnight_stay',
                    label: t('overnightStay', 'Pernoctación'),
                    component: 'CheckboxMargin',
                    defaultChecked: expenseInstance?.overnight_stay,
                },
                {
                    name: 'remarks',
                    label: t('remarks', 'Observaciones'),
                    component: 'TextArea',
                    value: expenseInstance?.remarks,
                    onChange: (value) => changeFieldExpenseInstance(value, 'remarks'),
                    className: 'mb-20 ' + returnClassOfSpanTemplate(2)
                },
            ]
        },
    ]

    const fieldsTotalExpenses: FormRender<EmployeeExpenseModelType> = [
        {
            cols: 2,
            title: t('totalExpenses', 'Gastos totales'),
            elements: [
                {
                    name: 'km_amount',
                    label: t('totalKmAmount', 'Importe de Km totales'),
                    component: 'DisplayPriceField',
                    value: expenseInstance?.km_amount || 0,
                    CurrencyId: expenseInstance?.CurrencyId || '',
                },
                {
                    name: 'expenses_amount',
                    label: t('addicionalTotalExpenses', 'Gastos adicionales totales'),
                    component: 'DisplayPriceField',
                    CurrencyId: expenseInstance?.CurrencyId || '',
                    value: expenseInstance?.expenses_amount,
                },
                {
                    name: 'total_amount',
                    label: t('total', 'Total'),
                    component: 'DisplayPriceField',
                    CurrencyId: expenseInstance?.CurrencyId || '',
                    value: expenseInstance?.total_amount,
                },
            ]
        },
    ]

    return (
        <MediumModalCreateEdit fields={fields} url={'/api/app/employee/employeeExpense/createEmployeeExpense'} translations={{
        title: t('addExpenseSheet', 'Nueva ficha de gastos'),
        button: t('add', 'Añadir')
        }} haveButtonPermissions={true} imagePath={['tmp']} {...rest} beforeSubmit={beforeSubmitExpensesHandler}>
            <EmployeeExpenseTableExpenses expenseInstance={expenseInstance} expensesType={expensesType} setExpenseInstance={setExpenseInstance}/>
            <FormRenderer sections={fieldsTotalExpenses} className='mt-20'/>
        </MediumModalCreateEdit>
    )
}

const useModalCreateEmployeeExpensesLogic = () => {
    const { t } = useTwinTranslation()
    const errorMessage = t('addValidDates', 'Introduzca una fecha válida')
    const [expensesType, setExpensesType] = useState<EmployeeExpenseRowTypeModelAdd[]>([])
    const [expenseInstance, setExpenseInstance] = useState<EmployeeExpenseModelAddExtended>({
        EmployeeExpenseRows: [],
        km: 0,
        total_amount: 0,
        km_amount: 0,
        expenses_amount: 0,
        kmPrice: getConfigParam('kmPrice') || 0,
        CurrencyId: getISOCurrencyMain(),
    })

    const changeWithCalculate = useCallback(() => {
        setExpenseInstance((old) => {
            const expenseInstanceCopy = JSON.parse(JSON.stringify(old))
            calculateEmployeeExpensesTotals(expenseInstanceCopy)
            return expenseInstanceCopy
        })
    }, [setExpenseInstance])

    const changeFieldExpenseInstance = useCallback((value: string, field: keyof EmployeeExpenseModelType) => {
        setExpenseInstance((old: any) => {
            if (!old) {
                return old
            }
            return { ...old, [field]: value }
        })
    }, [setExpenseInstance])

    const beforeSubmitExpensesHandler: BeforeSubmitHandler = useCallback((values) => {
        delete values.EmployeeExpenseRowTypeId
        delete values.name
        if (new Date(values.dateStart) > new Date(values.dateEnd)) {
            changeErrorMessage(errorMessage)
            return false
        }
  
        if (expenseInstance?.EmployeeExpenseRows) {
            for (let row in expenseInstance?.EmployeeExpenseRows) {
                let file = `file_amount_expense_${row}`
                if (values[file]) {
                    expenseInstance.EmployeeExpenseRows[parseInt(row)].file_amount_expense = values[file]
                }
            }
        }

        values.EmployeeExpenseRows = expenseInstance?.EmployeeExpenseRows
        values.total_amount = expenseInstance?.total_amount
        values.km_amount = expenseInstance?.km_amount
        values.expenses_amount = expenseInstance?.expenses_amount
        values.total_amount_declarable = expenseInstance?.total_amount_declarable
        values.total_amount_excluded_vat = expenseInstance?.total_amount_excluded_vat
        return true
    }, [expenseInstance, errorMessage])


    const getExpensesTypes = useCallback(async () => {
        const allExpensesTypes = await twinFetchPostJSON('/api/app/employeeExpense/getAllEmployeeExpensesRowType', {})
        setExpensesType(allExpensesTypes)
    }, [setExpensesType])

    useEffect(() => {
        getExpensesTypes()
    }, [getExpensesTypes])

    return { expensesType, beforeSubmitExpensesHandler, expenseInstance, changeWithCalculate, changeFieldExpenseInstance, setExpenseInstance }
}

export default ModalCreateEmployeeExpenses;