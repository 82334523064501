import ModalDeleteFilled from '../../../../../../../../../../baseComponents/ModalsLayouts/ModalDeleteFilled'
import { ModalEditComponentProps } from '../../../../../../../../../../baseComponents/ModalsLayouts/types'
import { useFetchText } from '../../../../../../../../../../utils/hooks/useFetchText'
import useTwinTranslation from '../../../../../../../../../../utils/hooks/useTwinTranslation'

interface ModalDeleteEmployeePayslipProps extends ModalEditComponentProps { }

const ModalDeleteEmployeePayslip: React.FC<ModalDeleteEmployeePayslipProps> = ({ setOpened, onSubmit, allRowData }) => {
    const { t } = useTwinTranslation()

    const { handleFetchText } = useFetchText({
        onSubmit,
        url: '/api/app/employee/employeePayslip/deleteEmployeePayslip',
        data: {
                id: allRowData?.id,
        }
    })
    return (
        <ModalDeleteFilled opened={true} setOpened={setOpened} onCancel={() => setOpened(null)} onAccept={handleFetchText} translations={{
            title: t('sureDeleteEmployeePayslip', '¿Seguro quieres eliminar la nómina de este empleado?'),
            subtitle: t('onceDeletedNotRecoverM', 'Una vez eliminado no podrás recuperarlo')
        }} />
    )
}

export default ModalDeleteEmployeePayslip