import AuthComposition from './components/AuthComposition'
import Login from './screens/Login'
import Register from './screens/Register'
import CreateRoutering from '../../baseComponents/CreateRoutering'
import { Routes } from '../../baseComponents/CreateRoutering/types'
import ForgotPassword from './screens/ForgotPassword'
import ActivateAccount from './screens/Register/ActivateAccount'
import NewPassword from './screens/ForgotPassword/NewPassword'
import ActivateFingerprint from './screens/ActivateFingerprint'

const LoginRoutesPaths = () : Routes => {
    return [
        {
            link: '/',
            text: 'Login',
            component: Login,
            exactPath: false,
            childs: [
                {
                    link: '/*',
                    text: 'Login',
                    component: Login
                },
                {
                    link: '/forgot-password/:token',
                    text: 'Forgot Password',
                    component: NewPassword
                },
                {
                    link: '/forgot-password',
                    text: 'Forgot Password',
                    component: ForgotPassword
                },
                {
                    link: '/register',
                    text: 'Register',
                    component: Register
                },
                {
                    link: '/register/:token',
                    text: 'Register',
                    component: Register
                },
                {
                    link: '/activate/:token',
                    text: 'Activate',
                    component: ActivateAccount
                },
                {
                    link: '/activateDevice/:fingerprint_token',
                    text: 'Activate',
                    component: ActivateFingerprint
                }
            ]
        },
    ]
}

export interface LoginRoutesProps {};

const LoginRoutes: React.FC<LoginRoutesProps> = () => {
    return <AuthComposition><CreateRoutering routes={LoginRoutesPaths()}></CreateRoutering></AuthComposition>
}

export default LoginRoutes