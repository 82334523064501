import { useRef } from 'react'
import { ModalOpenedSetTy } from '../../../Modal/types'
import useOnClickOutside from '../../../../utils/hooks/useOnClickOut'
import { ModalEventsYearView } from './types'
import './modaleventyearview.sass'
import { EventWorkingCalendar } from '../../../WorkingCalendar'

interface ModalEventYearViewProps extends ModalEventyearViewLogicProps {
    modalYearView: ModalEventsYearView
    onCancel?(): void
}

const ModalEventYearView: React.FC<ModalEventYearViewProps> = ({ setOpened, modalYearView, onClickOut = true }) => {
    const renderThisInner: JSX.Element[] = []
    const { top, left, bottom, className } = modalYearView
    const { ref } = useModalEventyearViewLogic({ setOpened, onClickOut })
    if (modalYearView?.events) {
        for (const element of modalYearView?.events) {
            renderThisInner.push(
                <EventWorkingCalendar key={element.id} event={element} />
            )
        }
    }
    return (
        <div className={'modal_eventyear absolute pl-16 pt-20 pb-20 ' + (className || '')} style={{ top, left, bottom }} ref={ref}>
            <div className='modal_eventyearinner flex flex-col gap-10'>
                {renderThisInner}
            </div>
        </div>
    )
}

interface ModalEventyearViewLogicProps {
    setOpened: ModalOpenedSetTy
    onClickOut?: boolean
}

const useModalEventyearViewLogic = ({ setOpened, onClickOut }: ModalEventyearViewLogicProps) => {
    const ref = useRef<any>(null)
    useOnClickOutside(ref, () => onClickOut ? setOpened(null) : {})

    return { ref }
}


export default ModalEventYearView