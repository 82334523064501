import { CustomSelectSectionItems } from '../../../forms/CustomSelect/CustomSelectSections/types'
import { CustomerPayload, ReduxCustomerTypes } from '../../../utils/reducers/customers/customer'

export const parseCustomersToCustomSelectSections = (customers: CustomerPayload, typeCustomers: Record<ReduxCustomerTypes, string>) => {
    const result: CustomSelectSectionItems = []
    if (customers) {
        for (const type in typeCustomers) {
            const parsedType = type as ReduxCustomerTypes
            const nameSection = typeCustomers[parsedType]
            if (Object.keys(customers[parsedType]).length) {
                const myChilds = customers[parsedType]
                result.push({ name: nameSection, childs: myChilds })
            }
        }
    }
    return result
}