import { Modify } from '@teinor/erp'
import {GroupPermissionsComputed} from '@teinor/erp/permissions'
import { EmployeeModelType } from '@teinor/erp/types/company/userInner/employee'
import { TwinEmployeeParametersDict } from '@teinor/erp/types/company/userInner/employeeParameter'
import { WorkingCenterModelType } from '@teinor/erp/types/company/workingCenter'
import { TwinDictionary } from '../../globals/dictionary'

export type UserLoginType = 'erp' | 'admin'

export type UserCookie = {
    token: string
    type: UserLoginType
    remember?: boolean
}

export interface UserInfo extends UserCookie{
    extraData: UserModel
}
type WorkingCenterModelTypeExtended = WorkingCenterModelType & {
    is_default: boolean
    BillingGroupId: number
}
type WorkingCenters = {[key: string]: WorkingCenterModelTypeExtended}
export interface UserModel {
    id: number
    email: string
    name: string
    surname: string
    image: string | null
    is_admin: boolean
    active: boolean
    type: number
    isManager: boolean
    groupPermissions: GroupPermissionsComputed
    WindowId: string
    WorkingCenters?: WorkingCenters
    WorkingCenterId?: number
    Employee?: EmployeeModelType
}

type UserModelForPayload = Modify<UserInfo, {
    extraData: UserModel & {workingCenters?: WorkingCenterModelTypeExtended[]}
}>
export interface UserAction {
    type: 'CHANGE_USER'
    payload: UserModelForPayload | null
}
export interface UserActionWorkingCenter {
    type: 'CHANGE_USERWORKINGCENTER'
    payload: number
}
export interface EmployeeParamsActionWorkingCenter {
    type: 'CHANGE_EMPLOYEE_PARAMS'
    payload: TwinEmployeeParametersDict
}
export type UserPayload = UserInfo | null
type Actions = UserAction | UserActionWorkingCenter | EmployeeParamsActionWorkingCenter
export default function user(state: UserPayload = null, action: Actions) : UserPayload {
    switch (action.type) {
        case 'CHANGE_USER':
            let WorkingCenterId = undefined
            const WorkingCenters: TwinDictionary = {}
            if (action.payload?.extraData?.Employee?.WorkingCenters && action.payload?.extraData?.Employee.defaultWorkingCenterId) {
                WorkingCenterId = action.payload?.extraData?.Employee.defaultWorkingCenterId
                for (const element of action.payload?.extraData?.Employee?.WorkingCenters) {
                    WorkingCenters[element.id] = element
                }
            }
            return state = action.payload?.extraData ? { ...action.payload, extraData: { ...action.payload?.extraData, WorkingCenterId: WorkingCenterId, WorkingCenters: WorkingCenters } } : null
        case 'CHANGE_USERWORKINGCENTER':
            if (state) {
                const copy = JSON.parse(JSON.stringify(state))
                copy.extraData.WorkingCenterId = action.payload
                return { ...copy }
            }
            return state
        case 'CHANGE_EMPLOYEE_PARAMS':
            if (state && state.extraData.Employee) {
                const copy = JSON.parse(JSON.stringify(state))
                copy.extraData.Employee.EmployeeParameters = { ...copy.extraData.Employee.EmployeeParameters, ...action.payload }
                return { ...copy }
            }
            return state
        default:
            return state
    }
}