export type GridTemplateColumnsType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | null

export const gridTemplateColumnsTranslator = {
    1: 'grid-cols-1',
    2: 'grid-cols-2',
    3: 'grid-cols-3',
    4: 'grid-cols-4',
    5: 'grid-cols-5',
    6: 'grid-cols-6',
    7: 'grid-cols-7',
    8: 'grid-cols-8',
    9: 'grid-cols-9',
    10: 'grid-cols-10',
    11: 'grid-cols-11',
    12: 'grid-cols-12',
    13: 'grid-cols-none'
}
export type SpanColumnTemplateType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | null

export const spanColumnTemplateTranslator = {
    1: 'col-span-1',
    2: 'col-span-2',
    3: 'col-span-3',
    4: 'col-span-4',
    5: 'col-span-5',
    6: 'col-span-6',
    7: 'col-span-7',
    8: 'col-span-8',
    9: 'col-span-9',
    10: 'col-span-10',
    11: 'col-span-11',
    12: 'col-span-12'
}

export const spanColumnRowTemplateTranslator = {
    1: 'row-span-1',
    2: 'row-span-2',
    3: 'row-span-3',
    4: 'row-span-4',
    5: 'row-span-5',
    6: 'row-span-6',
    7: 'row-span-7',
    8: 'row-span-8',
    9: 'row-span-9',
    10: 'row-span-10',
    11: 'row-span-11',
    12: 'row-span-12'
}

export const returnClassOfGridTemplate = (cols: GridTemplateColumnsType) => {
    if (cols === null) {
        return ''
    }
    return gridTemplateColumnsTranslator[cols]
}

export const returnClassOfSpanTemplate = (cols: SpanColumnTemplateType) => {
    if (cols === null) {
        return ''
    }
    return spanColumnTemplateTranslator[cols]
}

export const returnClassOfSpanRowsTemplate = (cols: SpanColumnTemplateType) => {
    if (cols === null) {
        return ''
    }
    return spanColumnRowTemplateTranslator[cols]
}

export const inputErrorClass = 'input_error'

export const borderRedErrorClass = 'border-red-BA'

export const inputFocusClass = 'input_focus'