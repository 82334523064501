import { Link } from 'react-router-dom'
import { filterTabsByPermissions, useTabObjectLogic } from '../../utils/hooks/useTabLogic'
import { TabsInfo } from './types'
import './editcardtabs.sass'
import { calculateTopOfElement } from '../../utils/globals/components'
import { UserPermissions } from '../CreateRoutering/types'
import { useMemo } from 'react'
import LoaderDecider from '../LoaderDecider'
import { joinAndRemoveDuplicatedBars } from '../CreateRoutering/functions'
import { WhiteBox } from '../AppLayout/WhiteBox'

interface EditCardTabsProps extends TabsInfo{
    basicData: { [key: string]: any } | null
    basePath: string
    userPermissions: UserPermissions
    getData: () => Promise<void>
    className?: string
}

const EditCardTabs: React.FC<EditCardTabsProps> = ({ tabs, tab, basicData, basePath, userPermissions, getData, className }) => {
    const tabsFiltered = useMemo(() => filterTabsByPermissions(tabs, userPermissions), [tabs, userPermissions])
    const { active, ComponentFromTab } = useTabObjectLogic(tabsFiltered, tab)
    if (!tabsFiltered || !Object.keys(tabsFiltered).length) {
        return null
    }
    return (
        <div className={'flex edit_card_tabs flex-auto overflow-auto ' + (className || '')}>
            <EditCardTabsLeftBarLink tabs={tabsFiltered} tab={active} basePath={basePath}/>
            <EditCardTabsTab>
                <LoaderDecider loading={basicData ? false : true}>
                    <ComponentFromTab basicData={basicData} userPermissions={tabsFiltered[active].userPermission} getData={getData} />
                </LoaderDecider>
            </EditCardTabsTab>
        </div>
    )
}


interface EditCardTabsLeftBarProps extends TabsInfo {
    children?: React.ReactNode
}
const ROW_HEIGHT = 31
const GAP_BTW = 15
interface EditCardTabsLeftBarLinkProps extends EditCardTabsLeftBarProps {
    basePath: string
}

export const EditCardTabsLeftBarLink: React.FC<EditCardTabsLeftBarLinkProps> = ({ basePath, tabs, tab}) => {
    const renderThis: JSX.Element[] = []
    for (const key in tabs) {
        renderThis.push(<EditCardTabsLeftItem key={key} text={tabs[key].text} isActive={tab === key} to={key} basePath={basePath} />)
    }
    return (
        <EditCardTabsLeftBar tabs={tabs} tab={tab} >{renderThis}</EditCardTabsLeftBar>
    )
}
interface EditCardTabsLeftBarDivProps extends EditCardTabsLeftBarProps {
    onClick: (tabId: string) => void
    children?: React.ReactNode
}
export const EditCardTabsLeftBarDiv: React.FC<EditCardTabsLeftBarDivProps> = ({ onClick, tabs, tab, children}) => {
    const renderThis: JSX.Element[] = []
    for (const key in tabs) {
        renderThis.push(<EditCardTabsLeftDivItem key={key} text={tabs[key].text} isActive={tab === key} onClick={() => onClick(key)} />)
    }
    return (
        <EditCardTabsLeftBar tabs={tabs} tab={tab} >{renderThis} {children}</EditCardTabsLeftBar>
    )
}

const EditCardTabsLeftBar: React.FC<EditCardTabsLeftBarProps> = ({tabs, tab, children}) => {
    return (
        <div className='editcardtabs_leftbar flex'>
            <div className='ectlb_bar'>
                <div className='ectlb_bar_bar' style={{top: calculateTopOfElement(ROW_HEIGHT, GAP_BTW, Object.keys(tabs).indexOf(String(tab)) ), height: ROW_HEIGHT}}></div>
            </div>
            <div className='ectlb_tabs flex-auto'>
                {children}
            </div>
        </div>
    )
}

interface EditCardTabsTabProps {
    children: React.ReactNode
}

const EditCardTabsTab: React.FC<EditCardTabsTabProps> = ({children}) => {
    return (
        <WhiteBox className='editcardtabs_tab'>
            {children}
        </WhiteBox>
    )
}

interface EditCardTabsLeftItemProps {
    text: string | JSX.Element
    to: string
    isActive?: boolean
    basePath: string
}

const EditCardTabsLeftItem: React.FC<EditCardTabsLeftItemProps> = ({basePath, text, to,  isActive}) => {
    return (
        <Link to={joinAndRemoveDuplicatedBars(basePath,'/' + to)} className={'editcardtabs_tabitem regular18 ' + (isActive ? 'active' : '')}>
            {text}
        </Link>
    )
}
interface EditCardTabsLeftDivItemProps {
    text: string | JSX.Element
    isActive?: boolean
    onClick: () => void
}
const EditCardTabsLeftDivItem: React.FC<EditCardTabsLeftDivItemProps> = ({text, isActive, onClick}) => {
    return (
        <div className={'editcardtabs_tabitem regular18 cursor-pointer ' + (isActive ? 'active' : '')} onClick={onClick}>
            {text}
        </div>
    )
}
interface EditCardTabsHorizontalWithoutLinksProps extends EditCardTabsLeftBarProps {
    onClick: (tabId: string) => void
    children?: React.ReactNode
    extraComponentData?: { [key: string]: any }
    className?: string
}
export const EditCardTabsHorizontalWithoutLinks: React.FC<EditCardTabsHorizontalWithoutLinksProps> = ({ onClick, tabs, tab, extraComponentData, className }) => {
    const { active, ComponentFromTab } = useTabObjectLogic(tabs, tab)
    const renderThis: JSX.Element[] = []
    for (const key in tabs) {
        renderThis.push(<EditCardTabsHorizontalWithoutLinksItem key={key} text={tabs[key].text} isActive={active === key} onClick={() => onClick(key)} />)
    }
    return (
        <div className={'flex-auto flex flex-col h-1 horizontal_tabs ' + (className || '')}>
            <div className='horizontal_tabs_subtitle text-18'>
                {renderThis}
            </div>
            <ComponentFromTab {...extraComponentData} />
        </div>
    )
}
interface EditCardTabsHorizontalWithoutLinksItemProps {
    text: string | JSX.Element
    isActive?: boolean
    onClick: () => void
}
const EditCardTabsHorizontalWithoutLinksItem: React.FC<EditCardTabsHorizontalWithoutLinksItemProps> = ({text, isActive, onClick}) => {
    return (
        <div className={'flex justify-center px-10 pb-20 cursor-pointer horizontal_tab_item_without_link ' + (isActive ? 'active' : '')} onClick={onClick}>
            {text}
        </div>
    )
}

interface EditCardTabsHorizontalTabsProps extends TabsInfo {
    basePath: string
}

export const EditCardTabsHorizontalTabs: React.FC<EditCardTabsHorizontalTabsProps> = ({ basePath, tabs, tab }) => {
    const { ComponentFromTab } = useTabObjectLogic(tabs, tab)
    const renderThis: JSX.Element[] = []
    for (const key in tabs) {
        renderThis.push(<EditCardTabsHorizontalItem key={key} text={tabs[key].text} isActive={tab === key} to={key} basePath={basePath} />)
    }
    return (
        <div className='flex-auto flex flex-col h-1'>
            <div className='horizontal_tabs_subtitle light18'>
                {renderThis}
            </div>
            <ComponentFromTab />
        </div>
    )
}
interface EditCardTabsHorizontalItemProps {
    text: string | JSX.Element
    to: string
    isActive?: boolean
    basePath: string
}
const EditCardTabsHorizontalItem: React.FC<EditCardTabsHorizontalItemProps> = ({ basePath, text, to, isActive }) => {
    return (
        <Link to={joinAndRemoveDuplicatedBars(basePath, '/' + to)} className={'mr-20 px-10 pb-20 cursor-pointer horizontal_tab_item ' + (isActive ? 'active' : '')}>
            {text}
        </Link>
    )
}

export default EditCardTabs