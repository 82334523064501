import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import twinFetchPost from '../../globals/data';
import { listenSocket } from '../../globals/socket';

interface FetchProps {
    fetchUrl: string
    propName: string
    setFunctionName: string
    params?: { [key: string]: any }
}
export interface ILoadingProps {
    loading: boolean
    children?: React.ReactNode
}

const Loading: React.FC<ILoadingProps> = ({ loading, children }) => {
    return (
        <Fragment>
            {loading ?
                <div>Loading</div>
                : children
            }
        </Fragment>
    );
}

const withLoading = <T extends {}>(WrappedComponent: React.FC<T>, fetchProps: FetchProps[]): React.FC<T>=> {
    const LoadingDataHOC = (props: any) => {
        const [loading, setLoading] = useState(true)
        const isCalledRef = useRef(false);
        const loadFetchProps = useCallback(async () => {
            if (loading) {
                let promises: any[] = []
                if (fetchProps) {
                    for (const prop of fetchProps) {
                        if (props[prop.propName] === null || props[prop.propName] === false) {
                            promises.push(makePetitionPromise(prop, props))
                        }
                    }
                }
                await Promise.all(promises)
                setLoading(false)
            }
        }, [props, loading])

        useEffect(() => {
            if (!isCalledRef.current) {
                isCalledRef.current = true;
                loadFetchProps()
            }
        }, [loadFetchProps])
        return <Loading loading={loading}><WrappedComponent {...props} /></Loading>
    }
    return LoadingDataHOC
}

const makePetitionPromise = async (prop: any, props: any) => {
    const result = await twinFetchPost(prop.fetchUrl, { ...prop.params })
    listenSocket(prop.propName, () => makePetitionPromise(prop, props))
    if (result.status === 200) {
        const myresult = await result.json()
        return dispatchPromise(props[prop.setFunctionName], myresult || [])
    } else {
        return dispatchPromise(props[prop.setFunctionName], [])
    }
}

const dispatchPromise = async (funct: any, result: any) => {
    await funct(result)
}
export default withLoading