import  { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { nanoid } from 'nanoid';
import './image.sass'
import { ImageInputFile } from './types';
import ImageInputDecider from './ImageInputDecider';

export interface ImageInputProps{
    defaultValue?: string
    name?: string
    onChange?: (file: ImageInputFile[]) => void
    label?: string
    emptyAfterChange?: boolean
    className?: string
}

export const ImageInput: React.FC<ImageInputProps> = ({ defaultValue, name, onChange, label, emptyAfterChange=false, className }) => {
    const [files, setFiles] = useState<ImageInputFile[]>(defaultValue ? [{name: defaultValue, preview: defaultValue}] : []);
    const [id] = useState(nanoid())
    const { getRootProps, getInputProps } = useDropzone({
        useFsAccessApi: false,
        accept: {
            'image/*': ['.png', '.gif', '.jpeg', '.jpg']
        },
        onDrop: acceptedFiles => {
            const myAcceptedFiles = acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }))
            setFiles([...myAcceptedFiles]);
            if (onChange) {
                onChange([...myAcceptedFiles])
                if (emptyAfterChange) {
                    setFiles([])
                }
            }
        }
    });

    useEffect(() => () => {
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    const Component = ImageInputDecider({length: files.length})
    return (
        <section className={'flex items-start justify-between ' + (className||'')}>
            <aside className='block'>
                {label && <label htmlFor={id} className='input_field_label text-gray-3 cursor-pointer imageLabel block'>{label}</label>}
                <input id={id} name={files.length ? name : ''} title='img' {...getInputProps()} className='border-primary' />
                {!files.length &&
                    <input id={id} name={name} value='' type='hidden' />
                }
                <Component {...{onChange, getRootProps, files, setFiles}}></Component>
            </aside>
        </section>
    );
}


export default ImageInput;
