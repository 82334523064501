import { SueStatusTypesInversedKeys, sueStatusTypesInversed } from '@teinor/erp/types/company/sue'
import { useState, useCallback, useEffect } from 'react'
import { twinFetchPostJSON } from '../../../../utils/globals/data'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import TwinTrans from '../../../TwinTrans'
import { DSBSueCircleData, DSBSueCircleStatusDictionary } from './types'
import Chart from 'react-apexcharts'



interface SueStatusCircleProps {
    title: string
    status: SueStatusTypesInversedKeys | 'expired'
    data: DSBSueCircleData | null
}


const SueStatusCircle: React.FC<SueStatusCircleProps> = ({ status, title, data }) => {
    const { t } = useTwinTranslation()
    const labelStatus: Record<(SueStatusTypesInversedKeys | 'left' | 'expired'), DSBSueCircleStatusDictionary> = {
        closed: {
            color: '#21B35C',
            label: t('sueClosedWidget', 'Cerradas')
        },
        informed: {
            label: t('sueWithoutProcessingWidgets', 'Sin procesar'),
            color: '#BCB0B0'
        },
        processing: {
            label: t('sueInProcessWidget', 'En proceso'),
            color: '#549CF8'
        },
        left: {
            label: t('sueLeftWidget', 'Restantes'),
            color: '#C3D3DE'
        },
        expired: {
            label: t('sueExpiredWidget', 'Atrasadas'),
            color: '#F85754'
        }
    }

    let series: number[] = []
    const arrayForChart: DSBSueCircleStatusDictionary[] = [{ ...labelStatus[status] }, { ...labelStatus['left'] }]
    const labels = data?.totalSues === 0 ? [t('noSuesAvailable', 'No hay denuncias')] : [arrayForChart[0].label, arrayForChart[1].label]
    if (data && data.totalSues === 0) {
        series = [1]
    } else if (data) {
        series = [data.totalStatus, data.leftSues]
    }
    return (
        <div className='flex flex-auto flex-col'>
            <h3>{title}</h3>
            <div className='align-center justify-center flex flex-auto '>
                {data !== null && data ? <Chart options={{
                    chart: {
                        id: 'sue-informed-chart',
                        events: {
                            mounted: (chart) => {
                                chart.windowResizeHandler()
                            }
                        }
                    },
                    fill: {
                        colors: [arrayForChart[0].color, arrayForChart[1].color],
                    },
                    labels,
                    dataLabels: {
                        enabled: false
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                size: "85%",
                                labels: {
                                    show: true,
                                    total: {
                                        label: arrayForChart[0].label,
                                        show: true,
                                        showAlways: true,
                                        formatter: () => {
                                            return data.totalStatus + ' / ' + (data.totalStatus + data.leftSues)
                                        }
                                    },
                                    name: {
                                        show: true,
                                        offsetY: -10,
                                    },
                                }
                            }
                        }
                    },
                    colors: [arrayForChart[0].color, arrayForChart[1].color],
                    legend: {
                        width: 150,
                        offsetY: 30,
                        offsetX: 0,
                        formatter: function (val, opts) {
                            if (data.totalSues === 0) {
                                return val
                            }
                            return [val, opts.w.globals.series[opts.seriesIndex]].join(' ')
                        }
                    },
                    responsive: [
                        {
                            breakpoint: 480,
                            options: {
                                chart: {
                                    width: "100%"
                                },
                                legend: {
                                    show: false
                                }
                            }
                        }
                    ],
                }}
                    series={series}
                    type='donut'
                    width={350}
                    className='flex items-center justify-center'
                />
                    :
                    <div>
                        <TwinTrans transKey={'sueStatusNoInfo'}>No hay información de este estado</TwinTrans>
                    </div>}
            </div>
        </div>
    )
}

interface DSBSueCircleProps extends DSBSueCircleLogicProps {
    title: string
}

const DSBSueCircle: React.FC<DSBSueCircleProps> = ({ status, title }) => {
    const { data } = useDSBSueCircleLogic({ status })
    return (<SueStatusCircle {...{ data, status, title }} />)
}


interface DSBSueCircleLogicProps {
    status: SueStatusTypesInversedKeys
}

const useDSBSueCircleLogic = ({ status }: DSBSueCircleLogicProps) => {
    const [data, setData] = useState<DSBSueCircleData | null>(null)

    const getData = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/sue/getAllSuesAndStatusCount', {
            where: {
                status: sueStatusTypesInversed[status]
            }
        })
        if (result) {
            setData({
                leftSues: result.totalSues - result.totalStatus,
                totalStatus: result.totalStatus,
                totalSues: result.totalSues
            })
        }
    }, [status])

    useEffect(() => {
        getData()
    }, [getData])
    return { data }
}

interface DSBSueCircleExpiredProps {
    title: string
}


export const DSBSueCircleExpired: React.FC<DSBSueCircleExpiredProps> = ({ title }) => {
    const { data } = useDSBSueCircleExpiredLogic()
    return (<SueStatusCircle {...{ data, status: 'expired', title }} />)
}


const useDSBSueCircleExpiredLogic = () => {
    const [data, setData] = useState<DSBSueCircleData | null>(null)

    const getData = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/sue/getAllSuesExpiredCount', {})
        if (result) {
            setData({
                leftSues: result.totalSues - result.totalStatus,
                totalStatus: result.totalStatus,
                totalSues: result.totalSues
            })
        }
    }, [])

    useEffect(() => {
        getData()
    }, [getData])
    return { data }
}


export default DSBSueCircle