import { Fragment } from 'react'
import { RenderBalanceHours, RenderDateAndWeekDay, RenderEntryOutWork, RenderHoursToWork, RenderIconBalanceHours, RenderWorkedHours, RenderWorkingTime } from './Subcomponents'
import { timeControlEmployeeMonthRowRender } from './functions'
import { EmployeeJourneyTyExtended } from '../../../app/erp/screens/Staff/WorkingCalendarParent/TimeControlEmployeeMonth/types'
import { TableComponentProps } from '../../../baseComponents/TwinTable/BaseScreenTable/types'
import { VirtualTableListingStateLess } from '../../../baseComponents/TwinTable/VirtualTableListing'
import { ColumnTableSchema } from '../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../utils/hooks/useTwinTranslation'

type TableControlMonthWorkProps = TableComponentProps & {
    data: EmployeeJourneyTyExtended[] | null
}

const TableControlMonthWork: React.FC<TableControlMonthWorkProps> = ({ data }) => {
    const { t } = useTwinTranslation()

    const columns: ColumnTableSchema<EmployeeJourneyTyExtended> = [
        {
            id: 'day',
            dataKey: 'date',
            label: t('day', 'Día'),
            customRender: (parameterValue) => <RenderDateAndWeekDay date={parameterValue} />
        },
        {
            id: 'workingTime',
            dataKey: 'journey',
            label: t('workingTime', 'Horario'),
            customRender: (parameterValue, allRowData) => <RenderWorkingTime {...{ allRowData }} />,
            minWidth: 300,
        },
        {
            id: 'workingTime',
            dataKey: 'journey',
            label: t('entry', 'Entrada'),
            customRender: (parameterValue, allRowData) => <RenderEntryOutWork entry={true} {...{ allRowData }} />

        },
        {
            id: 'workingTime',
            dataKey: 'journey',
            label: t('exit', 'Salida'),
            customRender: (parameterValue, allRowData) => <RenderEntryOutWork entry={false} {...{ allRowData }} />

        },
        {
            id: 'workedHours',
            dataKey: 'timetables',
            label: t('workedHours', 'Horas trabajadas'),
            customRender: (parameterValue, allRowData) => <RenderWorkedHours {...{ allRowData }} />,

        },
        {
            id: 'hoursToWork',
            dataKey: 'journey',
            label: t('hoursToDo', 'Horas a hacer'),
            customRender: (parameterValue, allRowData) => <RenderHoursToWork {...{ allRowData }} />
        },
        {
            id: 'balance',
            dataKey: 'timetables',
            label: t('balance', 'Balance'),
            headerClassName: 'flex justify-center items-center',
            className: 'flex justify-center items-center',
            customRender: (parameterValue, allRowData) => <RenderBalanceHours {...{ allRowData }} />
        },
        {
            id: 'icons',
            dataKey: 'timetables',
            label: '',
            customRender: (parameterValue, allRowData) => <RenderIconBalanceHours {...{ allRowData }} />,
            minWidth: 250,
        },

    ]
    return (
        <Fragment>
            <div className={'flex flex-col flex-auto h-1'}>
                <div className='flex-auto'>
                    <VirtualTableListingStateLess {...{ columns: columns }} setSelectedColumnsAndFilters={() => { }} tableData={data || []} headerHeight={48} name='monthWorkDays' rowRenderer={timeControlEmployeeMonthRowRender} />
                </div>
            </div>
        </Fragment>
    )
}

export default TableControlMonthWork