import { ConnectedProps, connect } from 'react-redux'
import { AllReduxAppPayloads, GlobalContext } from '../../../utils/reducers'
import { getParentMenuItems } from './functions'
import './header.sass'
import { Routes } from '../../CreateRoutering/types'

interface HeaderProps {
    children?: React.ReactNode
}

const Header: React.FC<HeaderProps> = ({children}) => {
    return (
        <div className='flex items-center header'>
            {children}
        </div>
    )
}


interface HeaderAdminProps extends ReduxHeaderAdminConnector {
    routes: Routes
}

const HeaderAdmin: React.FC<HeaderAdminProps> = ({routes, currentPage }) => {
    const headerTitle = getParentMenuItems(routes, currentPage)
    return (
        <Header>
            <h1 className='ml-42'>{headerTitle}</h1>
        </Header>
    )
}

const mapHeaderAdminConnector = (state: AllReduxAppPayloads) => ({ currentPage: state.currentPage })
const headerAdminConnector = connect(mapHeaderAdminConnector, null, null, { context: GlobalContext })
type ReduxHeaderAdminConnector = ConnectedProps<typeof headerAdminConnector>
export const HeaderAdminConnect = headerAdminConnector(HeaderAdmin)

export default Header