import { useParams } from 'react-router-dom'
import { ModalEditComponentProps } from '../../../baseComponents/ModalsLayouts/types'
import useTwinTranslation from '../../../utils/hooks/useTwinTranslation'
import { CustomerProjectModelType } from '@teinor/erp/types/company/customer/customerProject'
import { FormRender } from '../../../forms/FormRenderer/types'
import { sortCustomerProjectStatuses } from '../SortCustomerProjectStatuses'
import { DropDownSortFunct } from '../../../forms/CustomSelect/DropDown'
import { MediumModalCreateEdit } from '../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { getAllCustomerProjectTypes } from '../../../utils/reducers/getters'
import { useCallback, useMemo, useState } from 'react'
import { GroupPayload } from '../../../utils/reducers/groups/groups'
import { EmployeesPayload } from '../../../utils/reducers/company/employees'
import { connect, ConnectedProps } from 'react-redux'
import { AllReduxPayloads } from '../../../utils/reducers'
import withLoading from '../../../utils/hoc/withLoading'
import { BeforeSubmitHandler } from '../../../forms/TwinForm/types'

type ModalCreateCustomerProjectProps = ModalEditComponentProps & ReduxCustomerEmployeeGroup & {}

const ModalCreateCustomerProject: React.FC<ModalCreateCustomerProjectProps> = ({ allRowData, setOpened, onSubmit, employees, groups }) => {
    const { t } = useTwinTranslation()
    const { id } = useParams()
    const { customerProjectsTypes, myProjectStatuses, setMyProjectStatusId, handleBeforeSubmit } = useModalCreateCustomerProjectLogic({ id: allRowData?.CustomerProjectTypeId })
    const fields: FormRender<CustomerProjectModelType> = [
        {
            cols: 2,
            elements: [
                {
                    name: 'name',
                    component: 'InputWithLabelMargin',
                    label: t('name', 'Nombre'),
                    required: true
                },
                {
                    name: 'description',
                    component: 'TextArea',
                    label: t('description', 'Descripción'),
                },
                {
                    name: 'CustomerProjectTypeId',
                    component: 'CustomSelect',
                    required: true,
                    label: t('projectType', 'Tipo de proyecto'),
                    items: customerProjectsTypes || {},
                    onChange: (value) => setMyProjectStatusId(value)
                },
                {
                    name: 'CurrentCProjectStatusId',
                    component: 'CustomSelect',
                    label: t('projectStatus', 'Tipo de estado'),
                    items: myProjectStatuses || [],
                    DropDownComponent: DropDownSortFunct,
                    extraDropDownParams: {
                        sortFunction: (items: any) => sortCustomerProjectStatuses(items)
                    }
                },
                {
                    name: 'limit_date',
                    label: t('limitDate', 'Fecha límite'),
                    component: 'InputCalendarStateFull',
                    onlyValids: true,
                },
                {
                    name: 'CustomerId',
                    component: 'InputHidden',
                    value: id
                },
                {
                    name: 'id',
                    component: 'InputHidden',
                    value: allRowData?.id
                },
            ]
        },
        {
            cols: 1,
            title: t('employees', 'Empleados'),
            elements: [
                {
                    name: 'Employees',
                    component: 'MultipleCheckboxSelector',
                    subtitles: { subtitleOptions: t('notSelected', 'Sin seleccionar'), subtitleSelectedOptions: t('selected', 'Seleccionados') },
                    items: employees || {},
                }
            ]
        },
        {
            cols: 1,
            title: t('groups', 'Grupos'),
            elements: [
                {
                    name: 'Groups',
                    component: 'MultipleCheckboxSelector',
                    subtitles: { subtitleOptions: t('notSelected', 'Sin seleccionar'), subtitleSelectedOptions: t('selected', 'Seleccionados') },
                    items: groups || {},
                }
            ]
        }
    ]
    return (
        <MediumModalCreateEdit url='/api/app/customer/customerProject/createCustomerProject' fields={fields} setOpened={setOpened} onSubmit={onSubmit} translations={{
            title: t('createProject', 'Crear proyecto'),
            button: t('create', 'Crear')
        }} haveButtonPermissions={true} beforeSubmit={handleBeforeSubmit}>
        </MediumModalCreateEdit>
    )
}

interface ModalCreateCustomerProjectLogicProps {
    id: number
}


const useModalCreateCustomerProjectLogic = ({ id }: ModalCreateCustomerProjectLogicProps) => {
    const customerProjectsTypes = getAllCustomerProjectTypes()
    const idProjectType = id !== undefined ? String(id) : ''
    const [myProjectStatusId, setMyProjectStatusId] = useState<string>(idProjectType)
    const myProjectStatuses = useMemo(() => {
        return myProjectStatusId ? customerProjectsTypes?.[parseInt(myProjectStatusId)].customerProjectStatusDict : {}
    }, [myProjectStatusId, customerProjectsTypes])

    const handleBeforeSubmit: BeforeSubmitHandler = useCallback((values: any) => {
        if (values.CurrentCProjectStatusId && !myProjectStatuses?.[values.CurrentCProjectStatusId]) {
            values.CurrentCProjectStatusId = null
        }
        return true
    }, [myProjectStatuses])

    return { customerProjectsTypes, myProjectStatuses, setMyProjectStatusId, handleBeforeSubmit }
}

const customerEmployeeGroupDispatch = {
    setGroups: (payload: GroupPayload) => ({ type: 'CHANGE_GROUP', payload }),
    setEmployees: (payload: EmployeesPayload) => ({ type: 'CHANGE_EMPLOYEE', payload })
}

export type ReduxCustomerEmployeeGroup = ConnectedProps<typeof customerEmployeeGroupConnect>
const mapCustomerEmployeeGroupConnector = (state: AllReduxPayloads) => ({ groups: state.groups, employees: state.employees })
const customerEmployeeGroupConnect = connect(mapCustomerEmployeeGroupConnector, customerEmployeeGroupDispatch)

const customerEmployeeGroupConnectLoading = withLoading(ModalCreateCustomerProject, [{ fetchUrl: '/api/app/group/getAllGroupsListing', propName: 'groups', setFunctionName: 'setGroups' }, { fetchUrl: '/api/app/employee/getAllEmployees', propName: 'employees', setFunctionName: 'setEmployees' }])

const ModalCreateCustomerProjectConnect = customerEmployeeGroupConnect(customerEmployeeGroupConnectLoading)


export default ModalCreateCustomerProjectConnect