import { Modify } from '@teinor/erp';
import { Fragment, memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { ScrollEventData, TableHeaderRowProps, TableRowRenderer } from 'react-virtualized';
import { defaultHeaderRowRenderer } from 'react-virtualized/dist/es/Table';
import { memoChecker } from '../../../utils/globals/components';
import { twinFetchPostJSON } from '../../../utils/globals/data';
import useConfigColumns, { SelectedColumnsAndFilters, SelectedColumnsTy, SelectedFiltersTy, SelectedOrderTy } from '../../../utils/hooks/useConfigColumns';
import { changeEmployeeParams } from '../../../utils/reducers/reduxDispatch';
import NullComponent from '../../NullComponent';
import { TwinAutoSizer, TwinColumn, TwinTable } from '../Adapters';
import { HeaderChangesTy, TableConfiguration } from '../types';
import { calculateColumnWidth, cleanVTColumnsForMemo, getVirtualizedListingColumnWidth } from './functions';
import { CellRender, HeaderRender } from './Subcomponents';
import { ColumnConfiguration} from './Subcomponents/types';
import { ChangeColumnConfigTy, OnRowClick } from './types';

type VirtualTableListingPropsStateFulll = Modify<VirtualTableListingProps, {
    setSelectedColumnsAndFilters?: never
}>

const VirtualTableListing: React.FC<VirtualTableListingPropsStateFulll> = ({ ...rest }) => {
    const { setSelectedColumnsAndFilters, memorizedColumns } = useConfigColumns({ columns: rest.columns, name: rest.name })
    return (
        <VirtualTableListingStateLess {...rest} setSelectedColumnsAndFilters={setSelectedColumnsAndFilters} columns={memorizedColumns} />
    )
}

export interface VirtualTableListingProps extends VirtualTableListingAutosizedProps { }

export const VirtualTableListingStateLess: React.FC<VirtualTableListingProps> = memo(({ ...rest }) => {
    return (
        <TwinAutoSizer>
            {({ width, height }) => (
                <VirtualTableListingAutosized {...rest} width={width} height={height}/>
            )}
        </TwinAutoSizer>
    )
}, (oldProps, nextProps) => memoChecker(oldProps, nextProps, ['selectedFilters', 'selectedOrder', 'columns', 'rowRenderer', 'headerChanges', 'headerHeight', 'onRowClick', 'tableData', 'tableConfiguration', 'headerChanges', 'onScroll', 'rowHeight', 'acceptHeightChanges'], { 'columns': ['label'] }))

interface VirtualTableListingAutosizedProps extends VirtualTableAutosizedLogicProps{
    tableData: { [fieldName: string]: any }[] | null
    tableConfiguration?: TableConfiguration
    headerChanges?: HeaderChangesTy
    headerHeight?: number
    onRowClick?: OnRowClick
    onScroll?: (scrollEvent: ScrollEventData) => void
    rowRenderer?: TableRowRenderer 
    disableMarginAuto?: boolean
    selectedFilters?: SelectedFiltersTy
    selectedOrder?: SelectedOrderTy
    addOneEmptyRow?: boolean
    updateEmployeeParameter?: boolean
}

interface VirtualTableListingStyleProps {
    width: number
    height: number
}

const VirtualTableListingAutosized: React.FC<VirtualTableListingAutosizedProps & VirtualTableListingStyleProps> = ({ tableData = [], rowHeight = 48, headerHeight = 50, onRowClick, columns, tableConfiguration, headerChanges, onScroll, rowRenderer, height, width, defaultMinWidthColumns = 100, disableMarginAuto, selectedFilters, selectedOrder, addOneEmptyRow, selectedFilterId, updateEmployeeParameter, ...logic }) => {
    const { columnWidth, loaded, newWidth, ref, tableNode, changeColumnConfig } = useVirtualTableAutosizedLogic({ columns, width, height, rowHeight, defaultMinWidthColumns, selectedFilterId, ...logic })

    if (loaded === false) {
        return null
    }
    let someColumnHaveWidthAuto = false
    const scrollable = newWidth !== width
    const tableIsEmpty = tableData?.length === 0
    const nRowsEmpty = addOneEmptyRow ? 1 : 0
    return (
        <TwinTable
            ref={ref}
            height={height}
            width={width}
            rowHeight={rowHeight!}
            gridStyle={{
                direction: 'inherit',
            }}
            rowStyle={({ index }) => {
                return {
                    display: 'flex',
                    width: index > -1 ? newWidth : width
                }
            }}
            containerStyle={{
                maxWidth: newWidth,
                width: newWidth
            }}
            headerRowRenderer={(props) => <HeaderMemo {...props} tableConfiguration={tableConfiguration} tableNode={tableNode} columnsForMemo={columns} selectedFilters={selectedFilters} selectedOrder={selectedOrder}/>}
            headerHeight={headerHeight!}
            onRowClick={(e) => onRowClick?.(e.rowData.id || e.index, e.rowData, e)}
            rowCount={tableIsEmpty ? nRowsEmpty : (tableData?.length ?? nRowsEmpty)}
            rowGetter={({ index }) => tableData?.[index] || {}}
            onScroll={onScroll}
            rowRenderer={rowRenderer}
            rowClassName={({ index }) => onRowClick && index !== -1 ? 'cursor-pointer' : ''}
            className={'twin_table ' + (tableData === null ? '  isNull' : '') + (scrollable ? ' scrollable' : '') + (tableIsEmpty ? ' empty_table' : '')}
        >
            {columns.map(({ dataKey, parseData, customRender, className, haveModule = true, havePermission = true, headerClassName, id, justForFilter, ...rest }, index) => {
                if (haveModule && havePermission && !justForFilter) {
                    let extraClassName = ' '
                    if (!rest.width) {
                        someColumnHaveWidthAuto = true
                    }
                    const isLast = index === columns.length-1
                    if (!disableMarginAuto && isLast && !someColumnHaveWidthAuto) {
                        extraClassName = ' ml-auto'
                    }
                    const myColumnWidth = getVirtualizedListingColumnWidth(rest, columnWidth, scrollable, defaultMinWidthColumns)
                    return (
                        <TwinColumn
                            headerClassName={(headerClassName || '') + extraClassName}
                            className={(className || '') + extraClassName}
                            key={dataKey}
                            width={myColumnWidth}
                            minWidth={myColumnWidth}
                            headerRenderer={() => <HeaderRender key={'header' + id} isSelectedFilterOn={selectedFilters?.[id]?.value !== undefined} columnSelectedOrder={selectedOrder?.[id]} id={id} dataKey={dataKey} headerHeight={headerHeight} {...{ tableConfiguration, changeColumnConfig, headerChanges }}  {...rest} defaultMinWidthColumns={defaultMinWidthColumns} updateEmployeeParameter={updateEmployeeParameter} />}
                            cellRenderer={(props) =>tableIsEmpty ? <NullComponent/> : <CellRender parseData={parseData} customRender={customRender} {...props} key={props.rowIndex + props.columnIndex} isNullTable={tableData === null} rowHeight={rowHeight} /> }
                            dataKey={String(dataKey)}
                        />
                    )
                }
                return null;
            })}
        </TwinTable>
    )
}

interface VirtualTableAutosizedLogicProps {
    columns: ColumnConfiguration[]
    setSelectedColumnsAndFilters: React.Dispatch<React.SetStateAction<SelectedColumnsAndFilters>>
    rowHeight?: number
    acceptHeightChanges?: boolean
    defaultMinWidthColumns?: number
    name: string
    selectedFilterId?: number
}

const useVirtualTableAutosizedLogic = ({ width, columns, setSelectedColumnsAndFilters, rowHeight, defaultMinWidthColumns, name, acceptHeightChanges = false, selectedFilterId }: VirtualTableAutosizedLogicProps & VirtualTableListingStyleProps) => {
    const [loaded, setLoaded] = useState(true)
    const { columnWidth, newWidth } = useMemo(() => calculateColumnWidth(width, columns, defaultMinWidthColumns), [width, columns, defaultMinWidthColumns])
    const ref = useRef<any>(null)
    const tableNode = ref.current?.Grid._scrollingContainer

    useEffect(() => {
        if (acceptHeightChanges && !loaded) {
            setLoaded(true)
        }
    }, [loaded, acceptHeightChanges])

    useEffect(() => {
        if (acceptHeightChanges) {
            setLoaded(false)
        }
    }, [rowHeight, acceptHeightChanges])

    useEffect(() => {
        const listener = (event: any) => {
            const target = event.target
            const header = target?.parentElement?.querySelector('.ReactVirtualized__Table__headerRow')
            if (target.scrollLeft !== header.scrollLeft) {
                header.scrollLeft = target.scrollLeft
            }
        };
        if (tableNode) {
            tableNode.addEventListener("scroll", listener);
        }
        return () => {
            if (tableNode) {
                tableNode.removeEventListener("scroll", listener);
            }
        };
    }, [tableNode])

    const updateFilterOnTable = useCallback(async (mycolumns: SelectedColumnsTy, id: number) => {
        delete mycolumns.id
        delete mycolumns.name
        const myValue = JSON.stringify(mycolumns)
        await twinFetchPostJSON('/api/app/config/filter/updateFilter', { id, value: myValue })
    }, [])

    const updateEmployeeParams = useCallback(async (mycolumns: SelectedColumnsTy) => {
        const myName = 'table_' + name
        const myValue = JSON.stringify(mycolumns)
        await twinFetchPostJSON('/api/app/config/employee/updateEmployeeParameter', { parameterKey: myName, value: myValue })
        changeEmployeeParams({ [myName]: myValue })
    }, [name])
    
    const changeColumnConfig: ChangeColumnConfigTy = useCallback(async (id, userConfig, updateDb = true) => {
        await flushSync(async () => {
            let mySelectedColumns = {}
            await setSelectedColumnsAndFilters((old) => {
                const copyOld: SelectedColumnsAndFilters = JSON.parse(JSON.stringify(old))
                copyOld.selectedColumns[id] = { ...userConfig }
                mySelectedColumns = copyOld
                return copyOld
            })
            if (updateDb) {
                if (selectedFilterId) {
                    await updateFilterOnTable(mySelectedColumns, selectedFilterId)
                } else {
                    await updateEmployeeParams(mySelectedColumns)
                }
            }
        })
    }, [setSelectedColumnsAndFilters, updateEmployeeParams, selectedFilterId, updateFilterOnTable])

    return { columnWidth, loaded, newWidth, ref, tableNode, changeColumnConfig }
}

interface HeaderMemoProps extends TableHeaderRowProps {
    tableNode: null | HTMLElement
    columnsForMemo: ColumnConfiguration[]
    tableConfiguration?: TableConfiguration
    selectedFilters?: SelectedFiltersTy
    selectedOrder?: SelectedOrderTy
}

const HeaderMemo: React.FC<HeaderMemoProps> = memo(({ tableNode, ...props }) => {
    useLayoutEffect(() => {
        if (tableNode) {
            const header = tableNode.parentElement?.querySelector('.ReactVirtualized__Table__headerRow') as any
            if (header) {
                const maxScrollLeftHeader = header.scrollWidth - header.clientWidth;
                const maxScrollLeftTable = tableNode.scrollWidth - tableNode.clientWidth;
                if (maxScrollLeftHeader !== maxScrollLeftTable) {
                    const diff = maxScrollLeftTable - maxScrollLeftHeader
                    const padding = header?.style?.paddingRight.substring(0, header?.style?.paddingRight?.indexOf('px')) || 0
                    header.style.paddingRight = parseFloat(String(padding)) + diff + 'px'
                }
            }
        }
    }, [tableNode])

    return <Fragment>{defaultHeaderRowRenderer({ ...props })}</Fragment>
}, (oldProps, nextProps) => memoChecker(oldProps, nextProps, ['selectedFilters', 'selectedOrder', 'columns', 'style', 'columnsForMemo', 'tableConfiguration'], { 'columns': ['props', '_owner'], 'columnsForMemo': ['label'] }, {'label': cleanVTColumnsForMemo}))

export default VirtualTableListing
