import { faFilter, faLongArrowDown as faLongArrowDownLight, faLongArrowUp as faLongArrowUpLight } from '@fortawesome/pro-light-svg-icons'
import { faLongArrowDown as faLongArrowDownSolid, faLongArrowUp as faLongArrowUpSolid } from '@fortawesome/pro-solid-svg-icons'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { TableCellProps } from 'react-virtualized'
import { useDebounce } from 'use-debounce'
import { getMinPxFromElement } from '../../../../utils/globals/components'
import useOnClickOutside from '../../../../utils/hooks/useOnClickOut'
import LoadingSpinner from '../../../LoaderDecider/LoadingSpinner'
import SearchBar from '../../../SearchBar'
import TwinIcon, { TwinIconProp } from '../../../TwinIcon'
import '../../table.sass'
import { HeaderChangesTy, Order, TableConfiguration } from '../../types'
import { ChangeColumnConfigTy } from '../types'
import ModalFilter, { SingleElementModalFilterTableRenderer } from './ModalFilter'
import { checkIfOrderExists } from './functions'
import { AllSearchableComponents, CustomRender, HeaderColumnProps, SearchableComponentProps } from './types'

type HeaderRenderProps = HeaderRenderLogicProps & HeaderColumnProps & {
    tableConfiguration?: TableConfiguration
    headerChanges?: HeaderChangesTy
    headerHeight?: number
    isSelectedFilterOn?: boolean
    columnSelectedOrder?: Order
}
export const HeaderRender: React.FC<HeaderRenderProps> = ({ label, dataKey, sortKey, headerHeight, sortable, searchableComponent, tableConfiguration, width, headerChanges, isSelectedFilterOn, columnSelectedOrder, updateEmployeeParameter = true, ...rest }) => {
    const { setRef } = useHeaderRenderLogic({ ...rest })
    let value = tableConfiguration?.singleFieldsSearch[dataKey] || ''
    return (
        <div className='flex justify-start items-center medium14 bg-gray-F7' key={dataKey}>
            <div className='flex items-center w-full'
                style={{ height: headerHeight }}>
                {!label || typeof label === 'string'? label : label()}
                <HeaderSortable {...{ sortable, dataKey: String(dataKey), tableConfiguration, changeOrder: headerChanges?.changeOrder, columnSelectedOrder }} />
                <HeaderSearchable {...{ searchableComponent, dataKey: String(dataKey), value }} changeSingleField={headerChanges?.changeSingleField} isSelectedFilterOn={isSelectedFilterOn} />
                {!width && updateEmployeeParameter ? <div className='table_header_separator cursor-col-resize' ref={setRef}></div> : null}
            </div>
        </div>
    )
}

interface HeaderRenderLogicProps  {
    changeColumnConfig: ChangeColumnConfigTy
    defaultMinWidthColumns: number
    minWidth?: HeaderColumnProps['minWidth']
    id: HeaderColumnProps['id']
    updateEmployeeParameter?: boolean
}

const useHeaderRenderLogic = ({ minWidth, defaultMinWidthColumns, changeColumnConfig, id, updateEmployeeParameter }: HeaderRenderLogicProps) => {
    const [ref, setRef] = useState<any | null>(null)
    useEffect(() => {
        let movement = { starterPx: 0, starterWidth: 0 }
        const mouseUp = (event: any) => {
            document.querySelector('body')?.classList.remove('cursor-col-resize')
            document.removeEventListener('mousemove', mouseMove)
            let myWidth = movement.starterWidth + (event.clientX - movement.starterPx)
            const myMinWidth = minWidth || defaultMinWidthColumns
            if (myWidth < myMinWidth) {
                myWidth = myMinWidth
            }
            changeColumnConfig(id, { userWidth: myWidth }, updateEmployeeParameter)
            document.removeEventListener('mouseup', mouseUp)
        }
        const mouseMove = (event: any) => {
            const headerElement = ref?.parentElement?.parentElement?.parentElement
            if (headerElement) {
                let myWidth = movement.starterWidth + (event.clientX - movement.starterPx)
                const myMinWidth = minWidth || defaultMinWidthColumns
                if (myWidth < myMinWidth) {
                    myWidth = myMinWidth
                }
                changeColumnConfig(id, { userWidth: myWidth }, false)
            }
        }
        const mouseDown = (event: any) => {
            const target = event.target
            const headerElement = target?.parentElement?.parentElement?.parentElement
            if (headerElement && headerElement.classList.contains('ReactVirtualized__Table__headerColumn')) {
                movement = { starterPx: event.clientX, starterWidth: getMinPxFromElement(headerElement) }
            }
            document.querySelector('body')?.classList.add('cursor-col-resize')
            document.addEventListener('mousemove', mouseMove)
            document.addEventListener('mouseup', mouseUp)
        };
        if (ref) {
            ref.addEventListener("mousedown", mouseDown);
        }
        return () => {
            if (ref) {
                ref.removeEventListener("mousedown", mouseDown);
                document.removeEventListener('mousemove', mouseMove)
                document.removeEventListener('mouseup', mouseUp)
            }
        };
    }, [ref, id, minWidth, changeColumnConfig, defaultMinWidthColumns, updateEmployeeParameter])
    
    return { ref, setRef }
}

interface HeaderSearchableProps extends SearchableComponentProps {
    searchableComponent?: AllSearchableComponents
    changeSingleField?: (fieldName: string, value: string) => void
    isSelectedFilterOn?: boolean
}

const HeaderSearchable: React.FC<HeaderSearchableProps> = ({ searchableComponent, changeSingleField, isSelectedFilterOn, ...rest }) => {
    const { opened, setOpened, ref, getOffsetWidth } = useHeaderSearchableLogic()
    if (isSelectedFilterOn || !searchableComponent) {
        return null
    }
    return (
        <div ref={ref}>
            <TwinIcon className={'ml-12 cursor-pointer ' + (opened || rest.value ? 'text-green-43' : 'text-gray-B2')} icon={faFilter} onClick={() => setOpened((oldOpened) => !oldOpened)}/>
            {(opened && searchableComponent) && <ModalFilter {...{ opened, setOpened }} style={getOffsetWidth()} changeSingleField={changeSingleField} dataKey={rest.dataKey}><SingleElementModalFilterTableRenderer {...{ component: searchableComponent.component, opened, setOpened, ...rest }} changeSingleField={changeSingleField}extraComponentData={searchableComponent.extraComponentData} /></ModalFilter>}
        </div>
    )
}
const useHeaderSearchableLogic = () => {
    const [opened, setOpened] = useState<boolean>(false)
    const ref = useRef<any>(null)
    const current = ref.current
    const getOffsetWidth = useCallback(() => {
        if (current) {
            const offsets = current.parentElement.getBoundingClientRect()
            return {top: offsets.top + 40+'px', left: offsets.left + 'px'}
        }
        return {top: undefined, left: undefined}
    }, [current])
    useOnClickOutside(ref, () => setOpened(false))
    return { opened, setOpened, ref, getOffsetWidth }
}

interface HeaderSortableProps {
    dataKey: string
    tableConfiguration?: TableConfiguration
    changeOrder?: (fieldName: string) => void
    sortable?: boolean
    columnSelectedOrder?: Order
}

const HeaderSortable: React.FC<HeaderSortableProps> = ({ dataKey, tableConfiguration, changeOrder, columnSelectedOrder, sortable = false }) => {
    if (!sortable) {
        return null
    }
    return (
        <div className='flex justify-center items-center' onClick={() => changeOrder?.(dataKey)}>
            <HeaderSortIcon order='asc' icon={faLongArrowDownLight} solidIcon={faLongArrowDownSolid} {...{ dataKey, tableConfiguration, columnSelectedOrder }}/>
            <HeaderSortIcon order='desc' icon={faLongArrowUpLight} solidIcon={faLongArrowUpSolid} {...{ dataKey, tableConfiguration, columnSelectedOrder }} />
        </div>
    )
}
interface HeaderSortIconProps {
    icon: TwinIconProp
    solidIcon: TwinIconProp
    dataKey: string
    sortKey?: string
    tableConfiguration?: TableConfiguration
    order: 'asc' | 'desc'
    columnSelectedOrder?: Order
}

const HeaderSortIcon: React.FC<HeaderSortIconProps> = ({ icon, solidIcon, tableConfiguration, dataKey, sortKey, order, columnSelectedOrder }) => {
    let className = (order === 'asc' ? 'ml-12' : 'ml-2') + ' cursor-pointer'
    let myIcon = icon
    if ((tableConfiguration && checkIfOrderExists(tableConfiguration, sortKey || dataKey, order)) || (!tableConfiguration?.order?.[sortKey || dataKey] && columnSelectedOrder === order)) {
        className += ' text-green-43'
        myIcon = solidIcon
    } else {
        className += ' text-gray-B2'
    }
    return (
        <TwinIcon className={className} icon={myIcon} size='sm' />
    )
}
    
interface CellRenderProps extends CellRenderLogicProps {
    rowHeight?: number
    isNullTable?: boolean
}

export const CellRender: React.FC<CellRenderProps> = ({ columnIndex, rowHeight = 48, isNullTable, ...rest }) => {
    const { result } = useCellRenderLogic({ ...rest, columnIndex })
    if (isNullTable) {
        return <CellRenderNullTable columnIndex={columnIndex} key={columnIndex}></CellRenderNullTable>
    }
    return (
        <div key={columnIndex} className='flex items-center justify-start' style={{ height: rowHeight }}>
            {result}
        </div>
    );
}
interface CellRenderLogicProps extends TableCellProps  {
    parseData?: (value: any) => void
    customRender?: CustomRender
}

const useCellRenderLogic = ({ cellData, parseData, rowData, customRender, rowIndex }: CellRenderLogicProps) => {
    let result = cellData
    if (parseData) {
        result  = parseData?.(cellData)
    }
    if (customRender) {
        result = customRender(result, { ...rowData, rowIndex })
    }
    return {result}
}

interface CellRenderNullTableProps {
    columnIndex: number
}

const CellRenderNullTable: React.FC<CellRenderNullTableProps> = ({ columnIndex }) => {
    if (columnIndex === 0) {
        return (
            <div key={columnIndex} className='cursor-pointer  flex items-center justify-start tablecellnull'>
                <LoadingSpinner/>
            </div>
        )
    }
    return null
}

interface SearchBarTableProps {
    changeGeneralSearchbar: (value: string) => void
    placeholder: string
    className?: string
}

export const SearchBarTable: React.FC<SearchBarTableProps> = ({ changeGeneralSearchbar, placeholder, className }) => {
    const { searchValue, handleSearchValue } = useSearchBarTableLogic({ changeGeneralSearchbar })
    return (
        <SearchBar className={'search_bar_table ' + (className||'')} placeholder={placeholder} searchValue={searchValue} onChange={(value) => handleSearchValue(value)} />
    )
}
interface SearchBarTableLogicProps {
    changeGeneralSearchbar: (value: string) => void
}

const useSearchBarTableLogic = ({ changeGeneralSearchbar }: SearchBarTableLogicProps) => {
    const [searchValue, setSearchValue] = useState<string>('')
    const [debounceValue] = useDebounce(searchValue, 250)

    const handleSearchValue = useCallback((changedValue: string) => {
        setSearchValue(changedValue)
    }, [setSearchValue])

    useEffect(() => {
        changeGeneralSearchbar(debounceValue)
    }, [debounceValue, changeGeneralSearchbar])

    return { debounceValue, searchValue, handleSearchValue }
}