import { EmployeeExpenseModelType } from '@teinor/erp/types/company/userInner/employee/employeeExpense'
import { EmployeeExpenseRowTypeModelAdd } from '@teinor/erp/types/company/userInner/employee/employeeExpense/employeeExpenseRow/employeeExpenseRowType'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ButtonOrLoader } from '../../../../../../../../../../baseComponents/Button'
import { MediumModalCreateEdit } from '../../../../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../../../../../../../../baseComponents/ModalsLayouts/types'
import { FormRender } from '../../../../../../../../../../forms/FormRenderer/types'
import { BeforeSubmitHandler } from '../../../../../../../../../../forms/TwinForm/types'
import EmployeeExpenseEditTotals from '../../../../../../../../../../specificComponents/Employee/EmployeeExpenses/EmployeeExpenseEditModal/EmployeeExpenseEditTotals'
import EmployeeExpenseTableExpenses from '../../../../../../../../../../specificComponents/Employee/EmployeeExpenses/EmployeeExpenseEditModal/EmployeeExpenseTableExpenses'
import { EmployeeExpenseModelAddExtended } from '../../../../../../../../../../specificComponents/Employee/EmployeeExpenses/EmployeeExpenseEditModal/types'
import { twinFetchPostJSON } from '../../../../../../../../../../utils/globals/data'
import { returnClassOfSpanTemplate } from '../../../../../../../../../../utils/globals/tailwind'
import { valueOrDefaultValue } from '../../../../../../../../../../utils/globals/valueOrDefaultValue'
import useTwinTranslation from '../../../../../../../../../../utils/hooks/useTwinTranslation'
import { getActiveCurrencies, getConfigParam, getISOCurrencyMain } from '../../../../../../../../../../utils/reducers/getters'
import { changeErrorMessage } from '../../../../../../../../../../utils/reducers/reduxDispatch'
import { calculateEmployeeExpensesTotals } from '@teinor/erp/prices/expense'


type ModalCreateEmployeeExpensesProps = ModalEditComponentProps & {}

const ModalCreateEmployeeExpenses: React.FC<ModalCreateEmployeeExpensesProps> = ({ userPermissions, ...rest }) => {
    const { t } = useTwinTranslation()
    const { expensesType, beforeSubmitExpensesHandler, expenseInstance, changeWithCalculate, changeFieldExpenseInstance, setExpenseInstance } = useModalCreateEmployeeExpensesLogic()
    const allCurrencies = getActiveCurrencies()
    const params = useParams<{ id: string }>()
    const EmployeeId = params.id

    const saveButton: JSX.Element = <ButtonOrLoader onClick={() => changeFieldExpenseInstance('1', 'status')} textButton={t('save', 'Guardar')} buttonIsDisabled={false} />

    const fields: FormRender<EmployeeExpenseModelType> = [
        {
            cols: 2,
            elements: [
                {
                    name: 'EmployeeId',
                    component: 'InputHidden',
                    value: EmployeeId
                },
                {
                    name: 'dateStart',
                    label: t('dateStart', 'Desde'),
                    component: 'InputCalendarStateFull',
                    required: true
                },
                {
                    name: 'dateEnd',
                    label: t('dateEnd', 'Hasta'),
                    component: 'InputCalendarStateFull',
                    required: true
                },
                {
                    name: 'CurrencyId',
                    label: t('currency', 'Moneda'),
                    component: 'CustomSelect',
                    items: allCurrencies,
                    onChange: (value) => changeFieldExpenseInstance(value, 'CurrencyId'),
                },
                {
                    name: 'location',
                    label: t('location', 'Ubicación'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true,
                },
                {
                    name: 'km',
                    label: t('km', 'Km'),
                    component: 'InputNumberWithLabelMargin',
                    value: String(expenseInstance?.km),
                    onChange: (value) => changeFieldExpenseInstance(value, 'km'),
                    onBlur: changeWithCalculate,
                },
                {
                    name: 'kmPrice',
                    label: t('kmPrice', 'Precio del Km'),
                    component: 'InputPrice',
                    value: String(expenseInstance?.kmPrice),
                    onChange: (value) => changeFieldExpenseInstance(value, 'kmPrice'),
                    onBlur: changeWithCalculate,
                    CurrencyId: expenseInstance?.CurrencyId || '',
                },
                {
                    name: 'abroad',
                    label: t('abroad', 'Extranjero'),
                    component: 'CheckboxMargin',
                },
                {
                    name: 'overnight_stay',
                    label: t('overnightStay', 'Pernoctación'),
                    component: 'CheckboxMargin',
                },
                {
                    name: 'remarks',
                    label: t('remarks', 'Observaciones'),
                    component: 'TextArea',
                    onChange: (value) => changeFieldExpenseInstance(value, 'remarks'),
                    className: 'mb-20 ' + returnClassOfSpanTemplate(2)
                },
            ]
        },
    ]
    if (userPermissions) {
        const parsedFields = valueOrDefaultValue(fields, userPermissions, expenseInstance)
    
        return (
            <MediumModalCreateEdit fields={parsedFields} url={'/api/app/employeeExpense/createEmployeeExpense'} translations={{
                title: t('addExpenseSheet', 'Nueva ficha de gastos'),
                button: t('markAsPaid', 'Marcar como pagado')
            }} haveButtonPermissions={true} imagePath={['tmp']} {...rest} beforeSubmit={beforeSubmitExpensesHandler} extraButtons={[saveButton]}>
                <EmployeeExpenseTableExpenses expenseInstance={expenseInstance} expensesType={expensesType} setExpenseInstance={setExpenseInstance}/>
                <EmployeeExpenseEditTotals changeFieldExpenseInstance={changeFieldExpenseInstance} expenseInstance={expenseInstance} changeWithCalculate={changeWithCalculate}/>
            </MediumModalCreateEdit>
        )
    }
    return null
}

const useModalCreateEmployeeExpensesLogic = () => {
    const { t } = useTwinTranslation()
    const errorMessage = t('addValidDates', 'Introduzca una fecha válida')
    const [expensesType, setExpensesType] = useState<EmployeeExpenseRowTypeModelAdd[]>([])
    const [expenseInstance, setExpenseInstance] = useState<EmployeeExpenseModelAddExtended>({
        EmployeeExpenseRows: [],
        km: 0,
        total_amount: 0,
        km_amount: 0,
        expenses_amount: 0,
        kmPrice: getConfigParam('kmPrice') || 0,
        CurrencyId: getISOCurrencyMain(),
    })

    const changeWithCalculate = useCallback(() => {
        setExpenseInstance((old) => {
            const expenseInstanceCopy = JSON.parse(JSON.stringify(old))
            calculateEmployeeExpensesTotals(expenseInstanceCopy)
            return expenseInstanceCopy
        })
    }, [setExpenseInstance])

    const changeFieldExpenseInstance = useCallback((value: string, field: keyof EmployeeExpenseModelType) => {
        setExpenseInstance((old: any) => {
            if (!old) {
                return old
            }
            return { ...old, [field]: value }
        })
    }, [setExpenseInstance])

    const beforeSubmitExpensesHandler: BeforeSubmitHandler = useCallback((values) => {
        delete values.EmployeeExpenseRowTypeId
        delete values.name
        if (new Date(values.dateStart) > new Date(values.dateEnd)) {
            changeErrorMessage(errorMessage)
            return false
        }

        if (expenseInstance?.EmployeeExpenseRows) {
            for (let row in expenseInstance?.EmployeeExpenseRows) {
                let file = `file_amount_expense_${row}`
                if (values[file]) {
                    expenseInstance.EmployeeExpenseRows[parseInt(row)].file_amount_expense = values[file]
                }
            }
        }

        values.EmployeeExpenseRows = expenseInstance?.EmployeeExpenseRows
        values.total_amount = expenseInstance?.total_amount
        values.km_amount = expenseInstance?.km_amount
        values.expenses_amount = expenseInstance?.expenses_amount
        values.total_amount_declarable = expenseInstance?.total_amount_declarable
        values.total_amount_excluded_vat = expenseInstance?.total_amount_excluded_vat
        values.status = String(expenseInstance?.status) === '1' ? 1 : 3
        
        return true
    }, [expenseInstance, errorMessage])


    const getExpensesTypes = useCallback(async () => {
        const allExpensesTypes = await twinFetchPostJSON('/api/app/employeeExpense/getAllEmployeeExpensesRowType', {})
        setExpensesType(allExpensesTypes)
    }, [setExpensesType])

    useEffect(() => {
        getExpensesTypes()
    }, [getExpensesTypes])

    return { expensesType, beforeSubmitExpensesHandler, expenseInstance, changeWithCalculate, changeFieldExpenseInstance, setExpenseInstance }
}

export default ModalCreateEmployeeExpenses;