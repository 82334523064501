import { Link } from 'react-router-dom'
import TokenDisplay from '../../../components/TokenDisplay'
import  TypeUserCompany from '../../../components/TypeUserCompany'
import { CompanyWhenAll } from '../types'

interface CompanyBoxProps extends CompanyWhenAll  {
}

export const CompanyBox: React.FC<CompanyBoxProps> = ({name, address, token, type}) => {
    return (
        <div className='bg-white rounded-xl company_box shadow-md'>
            <TokenDisplay token={token} />
            <div className='regular18 mt-25 w-1/2 name_company_box'>
                {name}
            </div>
            <div className='regular14 my-15 address_company_box'>
                {address}
            </div>
            <TypeUserCompany type={type} className='pt-25' />
        </div>
    )
}
interface LinkCompanyBoxProps extends CompanyBoxProps {}

export const LinkCompanyBox: React.FC<LinkCompanyBoxProps> = (props) => {
    return (
        <Link to={'/company/' + props.id}>
            <CompanyBox {...props}></CompanyBox>
        </Link>
    )
}