import { ErpModules } from '@teinor/erp/modules'

export interface UserFromCompany {
    id: number
    name: string
    surname: string
    email: string
    UserCompany: UserCompany
    img?: string
}

interface UserCompany {
    type: number
    CompanyId: number
    UserId: number
}

export interface UserCompanyInvite {
    id: number
    email: string
}

export interface CompanyGroup {
    id: number
    name: string
    isDefault: boolean
}

export interface Company {
    additional_address: string
    address: string
    billing_email: string
    city: string
    country: string
    createdAt: string
    email: string
    fiscal_name: string
    id: number
    id_number: string
    mobile: string
    name: string
    phone: string
    postal_code: string
    state: string
    token: string
    urlName: string
    image?: string
    Users: UserFromCompany[]
    UserInvites: UserCompanyInvite[]
    Groups: CompanyGroup[]
    modulesBuyed: ErpModules
    modulesDemo: ErpModules
    MyUserType: number
}

export interface CompanyAction {
    type: 'CHANGE_COMPANY'
    payload: CompanyPayload
}
export type CompanyPayload = Company | null
export default function company(state: CompanyPayload = null, action: CompanyAction): CompanyPayload {
    switch (action.type) {
        case 'CHANGE_COMPANY':
            return state = action.payload
        default:
            return state
    }
}
