


import { CustomRenderDisplayHoursByMins, CustomRenderString } from '../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { VirtualTableListingStateLess } from '../../../../baseComponents/TwinTable/VirtualTableListing'
import { ColumnTableSchema } from '../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import { TableComponentProps } from '../../../../baseComponents/TwinTable/BaseScreenTable/types'
import useDictMonths from '../../../../utils/hooks/useDictMonths'
import { displayFormatedHourFromMins } from '../../../../utils/globals/date'
import { TimeControlEmployeeJourneyByYearProps } from '../types'

type TableControlYearWorkProps = TableComponentProps & {
    data: TimeControlEmployeeJourneyByYearProps[] | null
    year: number
}
const TableControlYearWork: React.FC<TableControlYearWorkProps> = ({ data, year }) => {
    const { t } = useTwinTranslation()
    const { dictMonths } = useDictMonths()

    const columns: ColumnTableSchema<TimeControlEmployeeJourneyByYearProps> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('monthHeader', 'Mes'),
            customRender: (parameterValue) => <CustomRenderString value={dictMonths[parameterValue]} />
        },
        {
            id: 'totalMinsWorked',
            dataKey: 'totalMinsWorked',
            label: t('workedHours', 'Horas trabajadas'),
            customRender: (parameterValue) => <CustomRenderDisplayHoursByMins value={parameterValue}  />,

        },
        {
            id: 'totalMinsToDo',
            dataKey: 'totalMinsToDo',
            label: t('hoursToDo', 'Horas a hacer'),
            customRender: (parameterValue) => <CustomRenderDisplayHoursByMins value={parameterValue} />,
        },
        {
            id: 'balanceMinsToDo',
            dataKey: 'balanceMinsToDo',
            label: t('balance', 'Balance'),
            headerClassName: 'flex justify-center items-center',
            className: 'flex justify-center items-center',
            customRender: (parameterValue, allRowData) => <RenderTotalMonthBalanceHours balance={parseFloat(parameterValue)} year={year} month={allRowData.id} />,
        },
    ]
    return (
        <div className={'flex flex-col flex-auto h-1'}>
            <div className='flex-auto'>
                <VirtualTableListingStateLess columns={columns}  setSelectedColumnsAndFilters={() => { }} tableData={data || []} rowHeight={48} headerHeight={48} name='yearWorkDays' />
            </div>
        </div>
    )
}

interface RenderTotalMonthBalanceHoursProps {
    year: number
    month: number
    balance: number
}

const RenderTotalMonthBalanceHours: React.FC<RenderTotalMonthBalanceHoursProps> = ({ year, month, balance}) => {
    const now = new Date()
    const thisStartMonth = new Date(now.getFullYear(), now.getMonth(), 1)
    const startMonthDate = new Date(year, month - 1, 1)
    if (startMonthDate > thisStartMonth) {
        return <div></div>
    } 
    let balanceClassName = 'rounded-xl balance_total ' + (balance === 0 ? 'balance_correct' : balance > 0 ? 'balance_more_hours' : 'balance_less_hours')
    return (
        <div className={balanceClassName}>
            {displayFormatedHourFromMins(balance)}
        </div>
    )
}

export default TableControlYearWork