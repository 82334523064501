import { GroupVatModelType } from '@teinor/erp/types/company/vat/groupVat'
import { dictionaryComplexFromJsonArr } from '../../globals/dictionary'
export interface GroupVatsAction {
    type: 'CHANGE_GROUPVAT'
    payload: GroupVatPayload
}
export type GroupVatPayload = GroupVatModelType[]
export type GroupVatJson = { [isoCode: string]: GroupVatModelType } | null
export default function groupVats(state: GroupVatJson =  null, action: GroupVatsAction): GroupVatJson {
    switch (action.type) {
        case 'CHANGE_GROUPVAT':
            return state = dictionaryComplexFromJsonArr(action.payload)
        default:
            return state
    }
}