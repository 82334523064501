export type ChangeRestrictTimeControlAccessPayloadAction = null | true

export interface ChangeRestrictTimeControlAccessAction {
    type: 'CHANGE_RESTRICT_TC_ACCESS'
    payload: ChangeRestrictTimeControlAccessPayloadAction
}

export type ChangeRestrictTimeControlAccessPayload = null | true
export default function restrictTimeControlAccess(state: ChangeRestrictTimeControlAccessPayload = null, action: ChangeRestrictTimeControlAccessAction): ChangeRestrictTimeControlAccessPayload {
    switch (action.type) {
        case 'CHANGE_RESTRICT_TC_ACCESS':
            return state = action.payload
        default:
            return state
    }
}