import { dictTimeZones } from '@teinor/erp/types/globals/timeZone'
import { Fragment, useCallback } from 'react'
import { ComponentWithPermissions } from '../../../../../../../../../baseComponents/CreateRoutering/types'
import SingleFormLayout from '../../../../../../../../../baseComponents/Layout/SingleFormLayout'
import { BeforeSubmitHandler } from '../../../../../../../../../forms/TwinForm/types'
import { FormRender } from '../../../../../../../../../forms/FormRenderer/types'
import { returnClassOfSpanTemplate } from '../../../../../../../../../utils/globals/tailwind'
import { valueOrDefaultValue } from '../../../../../../../../../utils/globals/valueOrDefaultValue'
import useEditDeleteModal from '../../../../../../../../../utils/hooks/useEditDeleteModal'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import ModalCreateNewVersionOrUpdate from '../../subcomponents/ModalCreateNewVersionOrUpdate'
import { WCalParentExtendedTy } from '../../types'


type MainWorkingCalendarProps = ComponentWithPermissions & {
    basicData: WCalParentExtendedTy | null
    getData: () => Promise<void>
}
const parseSelected = (selectedWCals?: any[]) => {
    const result: number[] = []
    if (selectedWCals) {
        for (const type of selectedWCals) {
            result.push(type.id)
        }
    }
    return result
}


const MainWorkingCalendar: React.FC<MainWorkingCalendarProps> = ({ userPermissions, basicData, getData}) => {
    const {t} = useTwinTranslation()
    const { openModal, openModalSubmit, setOpenModal } = useMainWorkingCalendarLogic()
    const selectedWCalsFree = parseSelected(basicData?.WCalsFree || [])
    const selectedWCalSpecials = parseSelected(basicData?.WCalSpecials || [])
    const selectedEmployees = parseSelected(basicData?.Employees || [])
    const fields: FormRender<WCalParentExtendedTy> = [
        {
            cols: 2,
            title: t('mainInformation', 'Información principal'),
            elements: [
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'dayMins',
                    label: t('averageHoursDay', 'Media de horas al día'),
                    component: 'InputHour',
                    value: String(basicData?.dayMins || 0) , 
                    required: true
                },
                {
                    name: 'marginStartMin',
                    label: t('marginStartMin', 'Margen mínimo para empezar'),
                    component: 'InputHour',
                    value: String(basicData?.marginStartMin || 0),
                },
                {
                    name: 'marginEndMin',
                    label: t('marginEndMin', 'Margen mínimo para acabar'),
                    component: 'InputHour',
                    value: String(basicData?.marginEndMin || 0),
                },
                {
                    name: 'timeZone',
                    label: t('timeZone', 'Zona Horaria'),
                    component: 'CustomSelectWithSearchBar',
                    items: dictTimeZones,
                },
                {
                    name: 'WCalParentId',
                    component: 'InputHidden'
                },
                {
                    name: 'WCalId',
                    component: 'InputHidden',
                },
                {
                    name: 'Employees',
                    component: 'MultipleCheckboxSelector',
                    subtitles: { subtitleOptions: t('employees', 'Empleados'), subtitleSelectedOptions: t('employeesAssociated', 'Empleados asociados') },
                    items: basicData?.EmployeesAll || {},
                    defaultValue: selectedEmployees,
                    className: returnClassOfSpanTemplate(2),
                    keyName: 'fullname_short'
                },
                {
                    name: 'WCalsFree',
                    component: 'MultipleCheckboxSelector',
                    subtitles: { subtitleOptions: t('holidayCalendars', 'Calendarios festivos'), subtitleSelectedOptions: t('holidayCalendarsAssociated', 'Calendarios festivos asociados') },
                    items: basicData?.WCalsFreeAll || {},
                    defaultValue: selectedWCalsFree,
                    className: returnClassOfSpanTemplate(2)
                },
                {
                    name: 'WCalSpecials',
                    component: 'MultipleCheckboxSelector',
                    subtitles: { subtitleOptions: t('specialDays', 'Días especiales'), subtitleSelectedOptions: t('specialDaysAssociated', 'Días especiales asociados') },
                    items: basicData?.WCalSpecialsAll || {},
                    defaultValue: selectedWCalSpecials,
                    className: returnClassOfSpanTemplate(2)
                },
            ]
        },

    ]
    const parsedFields = valueOrDefaultValue(fields, userPermissions, basicData)
    return (
        <Fragment>
            <SingleFormLayout action='/api/app/workingCalendar/updateWorkingCalendar' onSubmit={getData} sections={parsedFields} userPermissions={userPermissions} beforeSubmitHandler={openModalSubmit}/>
            {openModal?.type === 'edit' ? <ModalCreateNewVersionOrUpdate urlTabString='main' {...{ openModal, setOpenModal, getData }} /> : null}
        </Fragment>
    )
}

const useMainWorkingCalendarLogic = () => {
    const { openModal, setOpenModal } = useEditDeleteModal()

    const openModalSubmit: BeforeSubmitHandler = useCallback((vals) => {
        setOpenModal({ type: 'edit', allRowData:vals })
        return false
    }, [setOpenModal])

    return { openModalSubmit, openModal, setOpenModal }
}


export default MainWorkingCalendar