export type ChangePopupPayloadAction = string | true

export interface ChangePopupErrorMessageAction {
    type: 'CHANGE_ERROR_MSG'
    payload: ChangePopupPayloadAction
}
export interface ChangePopupSuccessMessageAction {
    type: 'CHANGE_SUCCESS_MSG'
    payload: ChangePopupPayloadAction
}
export interface ChangePopupChangeMessageAction {
    type: 'RESET_POPUP_MSG'
}

export type PopupMessageActions = ChangePopupErrorMessageAction | ChangePopupSuccessMessageAction | ChangePopupChangeMessageAction

export type PopupMessagePayload = { type: 'success' | 'error', value: string | true } | null 

export default function popupmessage(state: PopupMessagePayload = null, action: PopupMessageActions): PopupMessagePayload {
    switch (action.type) {
        case 'RESET_POPUP_MSG': 
            return state = null
        case 'CHANGE_ERROR_MSG':
            return state = { type: 'error', value: action.payload }
        case 'CHANGE_SUCCESS_MSG':
            return state = { type: 'success', value: action.payload }
        default:
            return state
    }
}