import { dictionaryComplexFromJsonArr } from '../../globals/dictionary'
import { TaskHelperModelType } from '@teinor/erp/types/company/task/taskHelper'

export interface TaskTypesAction {
    type: 'CHANGE_TASKHELPERS'
    payload: TaskHelperModelType[]
}

export type DictionaryTaskHelpers = { [id: number]: TaskHelperModelType }
export type TaskHelpersPayload = null | DictionaryTaskHelpers

export default function taskHelpers(state: TaskHelpersPayload = null, action: TaskTypesAction): DictionaryTaskHelpers | null {
    switch (action.type) {
        case 'CHANGE_TASKHELPERS':
            const dictionaryTaskHelpers = dictionaryComplexFromJsonArr(action.payload || [])
            return state = dictionaryTaskHelpers
        default:
            return state
    }
}