import { CustomerProjectCommentModelTypeExt } from '../types';

export const parseCustomerProjectCommentFileName = (comment: CustomerProjectCommentModelTypeExt) => {
    if (comment.file_url) {
        const fileNameSplit = comment?.file_url?.split('/') || []
        const fileNameToParse = fileNameSplit?.[fileNameSplit.length - 1].split('_')
        let fileName = fileNameToParse[0]
        const fileExtensionSplited = fileNameToParse[1].split('.')
        const fileExtension = fileExtensionSplited[fileExtensionSplited.length - 1]
        fileName += '.' + fileExtension
        return fileName
    }
    return ''
}