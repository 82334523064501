import { Fragment } from 'react'
import TwinForm from '../../../forms/TwinForm'
import { BeforeSubmitHandler, OnSubmit } from '../../../forms/TwinForm/types'
import FormRenderer from '../../../forms/FormRenderer'
import { FormRender } from '../../../forms/FormRenderer/types'
import useIsLoading from '../../../utils/hooks/useIsLoading'
import useModalFormValidation from '../../../utils/hooks/useModalFormValidation'
import { ButtonOrLoader } from '../../Button'
import LoaderDecider from '../../LoaderDecider'
import { ModalBig, ModalLittle, ModalMedium, ModalSmallMedium } from '../../Modal'
import { ModalComponentProps } from '../types'
import { TwinDictionary } from '../../../utils/globals/dictionary'

interface BaseCreateEditProps{
    fields: FormRender
    translations: {
        title: JSX.Element | string
        button: string
    }
    haveButtonPermissions: boolean
    antiUnsaveTrigger?: boolean
    beforeSubmit?: BeforeSubmitHandler
    children?: React.ReactNode
}

type ModalCreateEditBaseProps =  ModalComponentProps & BaseCreateEditProps & {
    url: string
    imagePath?: string[]
    onChangeError?: OnSubmit 
    className?: string
    triggerErrors?: boolean
    extraProps?: TwinDictionary
    extraButtons?: JSX.Element[]
}

export const ModalCreateEditBase: React.FC<ModalCreateEditBaseProps> = ({ translations, url, fields, haveButtonPermissions, antiUnsaveTrigger, children, beforeSubmit, onChangeError, imagePath, className, triggerErrors, extraProps, extraButtons, ...rest }) => {
    const { startLoading, endLoading, loading } = useIsLoading()
    const { handleOnSubmit, handleOnPreSubmit } = useModalFormValidation({ ...rest, endLoading, startLoading, beforeSubmit })
    return (
        <TwinForm action={url} onSubmit={handleOnSubmit} beforeSubmitHandler={handleOnPreSubmit} onError={(e) => { onChangeError?.(e, {}); endLoading() }} className={'flex-auto flex flex-col h-1 ' + (className || '')} imagepath={imagePath} {...{triggerErrors, extraProps}}>
            <ModalCreateEditStructure {...{ translations, loading, fields, haveButtonPermissions, url, antiUnsaveTrigger, extraButtons }}>
                {children}
            </ModalCreateEditStructure>
        </TwinForm>
    )
}
interface ModalCreateEditStructureProps extends BaseCreateEditProps{
    loading: boolean
    children?: React.ReactNode
    extraButtons?: JSX.Element[]
}

export const ModalCreateEditStructure: React.FC<ModalCreateEditStructureProps> = ({extraButtons, translations, fields, haveButtonPermissions, loading, antiUnsaveTrigger,  children}) => {
    return (
        <ModalCreateEditStructureLayout translations={translations} loading={loading} haveButtonPermissions={haveButtonPermissions} className='modal_negative_margin flex flex-col' extraButtons={extraButtons}>
            <div className='overflow-auto flex flex-auto flex-col'>
                {fields.length ?
                    <FormRenderer sections={fields} className='grid_mincontent' antiUnsaveTrigger={antiUnsaveTrigger}>
                    </FormRenderer>
                : null}
                {children}
            </div>
        </ModalCreateEditStructureLayout>
    )
}
interface ModalCreateEditStructureLayoutProps {
    loading: boolean
    children?: React.ReactNode
    extraButtons?: JSX.Element[]
    translations: {
        title: JSX.Element | string
        button: string
    }
    haveButtonPermissions: boolean
    className?: string
}

export const ModalCreateEditStructureLayout: React.FC<ModalCreateEditStructureLayoutProps> = ({ className, extraButtons, translations, haveButtonPermissions, loading, children }) => {
    let element : JSX.Element | string = translations.title
    if (typeof translations.title === 'string') {
        element = <h2>{translations.title}</h2>
    }
    return (
        <Fragment>
            <div className='mb-25 form_title'>
                {element}
            </div>
            <div className={'flex flex-auto h-1 ' + (className || '')}>
                {children}
            </div>
            {haveButtonPermissions &&
                <div className='mt-30 ml-auto flex gap-15'>
                    {extraButtons}
                    <ButtonOrLoader buttonIsDisabled={loading} textButton={translations.button} />
                </div>
            }
        </Fragment>
    )
}

interface ModalCreateEditProps extends ModalCreateEditBaseProps {
    className?: string
}

const ModalCreateEdit: React.FC<ModalCreateEditProps> = ({ children, className, ...rest }) => {
    return (
        <ModalBig opened={true} setOpened={rest.setOpened} onClickOut={false} className={'flex flex-col ' + (className || '')}>
            <ModalCreateEditBase {...rest}>
                {children}
            </ModalCreateEditBase>
        </ModalBig>
    )
}

interface ModalCreateEditMediumProps extends ModalCreateEditBaseProps {
    className?: string
}

export const ModalCreateEditMedium: React.FC<ModalCreateEditMediumProps> = ({ children, className, ...rest }) => {
    return (
        <ModalMedium opened={true} setOpened={rest.setOpened} onClickOut={false} className={'flex flex-col '+ (className || '')}>
            <ModalCreateEditBase {...rest}>
                {children}
            </ModalCreateEditBase>
        </ModalMedium>
    )
}

interface ModalCreateEditLoadingProps extends ModalCreateEditBaseProps {
    loading: boolean
}

export const ModalCreateEditLoading: React.FC<ModalCreateEditLoadingProps> = ({ loading, children, ...rest }) => {
    return (
        <ModalBig opened={true} setOpened={rest.setOpened} onClickOut={false} className='flex flex-col'>
            <LoaderDecider loading={loading}>
                <ModalCreateEditBase {...rest}>
                    {children}
                </ModalCreateEditBase>
            </LoaderDecider>
        </ModalBig>
    )
}
interface ModalCreateEditMediumLoadingProps extends ModalCreateEditBaseProps {
    loading: boolean
}

export const ModalCreateEditMediumLoading: React.FC<ModalCreateEditMediumLoadingProps> = ({ loading, children, ...rest }) => {
    return (
        <ModalMedium opened={true} setOpened={rest.setOpened} onClickOut={false} className='flex flex-col'>
            <LoaderDecider loading={loading}>
                <ModalCreateEditBase {...rest}>
                    {children}
                </ModalCreateEditBase>
            </LoaderDecider>
        </ModalMedium>
    )
}

interface MediumModalCreateEditProps extends ModalCreateEditBaseProps {
    className?: string
    extraButtons?: JSX.Element[]
}

export const MediumModalCreateEdit: React.FC<MediumModalCreateEditProps> = ({ children, className, ...rest }) => {
    return (
        <ModalMedium opened={true} setOpened={rest.setOpened} onClickOut={false} className={'flex flex-col ' + (className || '') }>
            <ModalCreateEditBase {...rest}>
                {children}
            </ModalCreateEditBase>
        </ModalMedium>
    )
}
interface MediumSmallModalCreateEditProps extends ModalCreateEditBaseProps {
    className?: string
}

export const MediumSmallModalCreateEdit: React.FC<MediumSmallModalCreateEditProps> = ({ children, className, ...rest }) => {
    return (
        <ModalSmallMedium opened={true} setOpened={rest.setOpened} onClickOut={false} className={'flex flex-col ' + (className || '') }>
            <ModalCreateEditBase {...rest }>
                { children }
            </ModalCreateEditBase >
        </ModalSmallMedium >
    )
}


interface ModalCreateEditLittleLoadingProps extends ModalCreateEditBaseProps {
    loading: boolean
}

export const ModalCreateEditLittleLoading: React.FC<ModalCreateEditLittleLoadingProps> = ({ loading, children, ...rest }) => {
    return (
        <ModalLittle opened={true} setOpened={rest.setOpened} onClickOut={false} className='flex flex-col'>
            <LoaderDecider loading={loading}>
                <ModalCreateEditBase {...rest}>
                    {children}
                </ModalCreateEditBase>
            </LoaderDecider>
        </ModalLittle>
    )
}

interface LittleModalCreateEditProps extends ModalCreateEditBaseProps {}

export const LittleModalCreateEdit: React.FC<LittleModalCreateEditProps> = ({ children, className, ...rest }) => {
    return (
        <ModalLittle opened={true} setOpened={rest.setOpened} onClickOut={false} className={'flex flex-col ' + (className || '')}>
            <ModalCreateEditBase {...rest}>
                {children}
            </ModalCreateEditBase>
        </ModalLittle>
    )
}

interface LittleModalCreateEditChildrenFirstProps extends LittleModalCreateEditProps {
    className?: string
 }

export const LittleModalCreateEditChildrenFirst: React.FC<LittleModalCreateEditChildrenFirstProps> = ({ className, translations, children, beforeSubmit, haveButtonPermissions, antiUnsaveTrigger, fields, url,  ...rest }) => {
    const { startLoading, endLoading, loading } = useIsLoading()
    const { handleOnSubmit, handleOnPreSubmit } = useModalFormValidation({ ...rest, endLoading, startLoading, beforeSubmit })
    return (
        <ModalLittle opened={true} setOpened={rest.setOpened} onClickOut={false} className={'flex flex-col ' + (className || '')}>
            <TwinForm action={url} onSubmit={handleOnSubmit} beforeSubmitHandler={handleOnPreSubmit} onError={endLoading} className='flex-auto flex flex-col h-1'>
                <ModalCreateEditStructureLayout translations={translations} loading={loading} haveButtonPermissions={haveButtonPermissions} className='modal_negative_margin flex flex-col'>
                    <div className='overflow-auto flex flex-auto flex-col'>
                        {children}
                        {fields.length ?
                            <FormRenderer sections={fields} className='grid_mincontent' antiUnsaveTrigger={antiUnsaveTrigger}>
                            </FormRenderer>
                            : null}
                    </div>
                </ModalCreateEditStructureLayout>
            </TwinForm>
        </ModalLittle>
    )
}

export default ModalCreateEdit