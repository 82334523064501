import { faChevronRight } from '@fortawesome/pro-light-svg-icons'
import { useMemo } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Link } from 'react-router-dom'
import { AllReduxAppPayloads, GlobalContext } from '../../../../utils/reducers'
import { Routes } from '../../../CreateRoutering/types'
import TwinIcon from '../../../TwinIcon'
import { getBreadcrumbFromCurrentPage, makeRouteObject } from './functions'
import { RouteBreadcrumb } from './types'
import './breadcrumb.sass'

interface BreadcrumbProps extends BreadcrumbLogicProps {

}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ ...rest }) => {
    const { currentPageJoinedMemo } = useBreadcrumbLogic(rest)

    const renderThis: JSX.Element[] = []
    for (const key in currentPageJoinedMemo) {
        const element = currentPageJoinedMemo[key]
        const isLast = parseInt(key) >= currentPageJoinedMemo.length - 1
        const Component = BreadcrumbElementDecider({route: element, isLast})
        renderThis.push(<Component route={element} key={element.link+element.text} isLast={isLast}/>)
        if (!isLast) {
            renderThis.push(<TwinIcon icon={faChevronRight} key={element.link + element.text+'separator'} className='breadcrumb_separator'></TwinIcon>)
        }
    }
    return (
        <div className='breadcrumb flex items-center'>
            {renderThis}
        </div>
    )
}

interface BreadcrumbLogicProps extends ReduxBreadcrumbConnector {
    routes: Routes
}

const useBreadcrumbLogic = ({ routes, currentPage }: BreadcrumbLogicProps) => {
    const routesMemo = useMemo(() => makeRouteObject(routes), [routes])
    const currentPageJoinedMemo = useMemo(() => getBreadcrumbFromCurrentPage(currentPage, routesMemo), [currentPage, routesMemo])
    return {currentPageJoinedMemo}
}


interface BreadcrumbElementProps {
    route: RouteBreadcrumb
    isLast?: boolean
}

const BreadcrumbElementDecider = ({ route, isLast }: BreadcrumbElementProps) => {
    if (route.component && !isLast) {
        return BreadcrumbElementLink
    }
    return BreadcrumbElementDiv
}

const BreadcrumbElementLink: React.FC<BreadcrumbElementProps> = ({ route }) => {
    return (
        <Link className='breadcrumb_element' to={route.link}>
            {route.text}
        </Link>
    )
}

const BreadcrumbElementDiv: React.FC<BreadcrumbElementProps> = ({ route }) => {
    return (
        <span className='breadcrumb_element'>
            {route.text}
        </span>
    )
}



const mapBreadcrumbConnector = (state: AllReduxAppPayloads) => ({ currentPage: state.currentPage })
const BreadcrumbConnector = connect(mapBreadcrumbConnector, null, null, { context: GlobalContext })
type ReduxBreadcrumbConnector = ConnectedProps<typeof BreadcrumbConnector>

export default BreadcrumbConnector(Breadcrumb)