import { useCallback, useLayoutEffect, useState } from 'react'
import { ComponentWithPermissions } from '../CreateRoutering/types'
import { animationKeys, AnimationStatus } from './types'
import './doubleTable.sass'

type DoubleTableProps = ComponentWithPermissions & DoubleTableLogicProps & {
    FirstChild: React.FC<any>
    SecondChild: React.FC<any>
}

const DoubleTable: React.FC<DoubleTableProps> = ({ FirstChild, SecondChild, displaySecond, userPermissions }) => {
    const {animationReady} = useDoubleTableLogic({displaySecond})
    
    return (
        <div className={'px-40 py-45 grid flex-auto doubleTable ' + (animationReady > 0 ? 'active' : '' )}>
            <FirstChild {...{ userPermissions }} />
            {animationReady > 0 && <SecondChild {...{ userPermissions, animationReady }} />}
        </div>
    )
}

interface DoubleTableLogicProps {
    displaySecond: boolean
}

const useDoubleTableLogic = ({displaySecond}: DoubleTableLogicProps) => {
    const [animationReady, setAnimationReady] = useState<AnimationStatus>(animationKeys['none'])
    const processAnimation = useCallback(() => {
        setTimeout(() => {
            setAnimationReady(animationKeys['loaded'])
        }, 300)
    }, [])

    useLayoutEffect(() => {
        setAnimationReady((oldAnimationReady) => {
            if(displaySecond && oldAnimationReady === animationKeys['loading']){
                processAnimation()
            }
            if(displaySecond && oldAnimationReady === animationKeys['none']){
                return 1
            }else if(!displaySecond && oldAnimationReady === animationKeys['loaded']){
                return 0
            }
            return oldAnimationReady
        })
    }, [animationReady, displaySecond, processAnimation])

    return {animationReady}
}

export default DoubleTable