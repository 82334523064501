export interface CountriesAction {
    type: 'CHANGE_COUNTRY'
    payload: CountryJson
}
export interface State {
    id: number;
    name: string;
    adminName1: string;
    countryCode: string;
    countryName: string;
    [key: string]: string | number;
}
export interface Country {
    name: string
    code: string
    states: State[]
    myStates: { [id: string]: string | number }
}
export type CountryJson = { [isoCode: string]: Country }
export default function countries(state: CountryJson = {}, action: CountriesAction): CountryJson {
    switch (action.type) {
        case 'CHANGE_COUNTRY':
            return state = action.payload
        default:
            return state
    }
}