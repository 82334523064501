import { useLocation, useParams } from 'react-router'
import MainWorkingCalendar from './Tabs/MainWorkingCalendar'
import { useCallback, useEffect, useState } from 'react'
import { WCalParentExtendedTy } from './types'
import ScheduleWorkingCalendar from './Tabs/ScheduleWorkingCalendar'
import { ComponentWithPermissions } from '../../../../../../../baseComponents/CreateRoutering/types'
import EditCardTabs from '../../../../../../../baseComponents/EditCardTabs'
import { twinFetchPostJSON } from '../../../../../../../utils/globals/data'
import { dictionaryComplexFromJsonArr } from '../../../../../../../utils/globals/dictionary'
import { getBasePath } from '../../../../../../../utils/globals/link'
import { ComponentTabObject } from '../../../../../../../utils/hooks/useTabLogic'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'

type SingleWorkingCalendarProps = ComponentWithPermissions & {}

const SingleWorkingCalendar: React.FC<SingleWorkingCalendarProps> = ({ userPermissions }) => {
    const location = useLocation()
    const params = useParams<{ id: string, tab?: string }>()
    const { basicData, getData} = useSingleWorkingCalendarLogic()
    const { t } = useTwinTranslation()
    if (!params.id) {
        return null
    }
    const tabs: ComponentTabObject = {
        'main': { component: MainWorkingCalendar, text: t('general', 'General'), permission: 'WCal.main' },
        'schedule': { component: ScheduleWorkingCalendar, text: t('schedule', 'Horario'), permission: 'WCal.schedule' },
    }
    return (<EditCardTabs basePath={getBasePath(location.pathname, params.id)} basicData={basicData} tabs={tabs} tab={params.tab} userPermissions={userPermissions} getData={getData} />)
}



const useSingleWorkingCalendarLogic = () => {
    const [basicData, setBasicData] = useState<WCalParentExtendedTy | null>(null)
    const params = useParams<{ id: string, tab?: string }>()
    const getData = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/workingCalendar/getWorkingCalendarForUpdate', { id: params.id })
        if(result){
            setBasicData({ ...result.WCal.WCalParent, ...result.WCal, WCalId: result.WCal.id, WCalsFreeAll: dictionaryComplexFromJsonArr(result.WCalsFree), WCalSpecialsAll: dictionaryComplexFromJsonArr(result.WCalSpecials), EmployeesAll: dictionaryComplexFromJsonArr(result.Employees) })
        }
    }, [setBasicData, params?.id])

    useEffect(() => {
        getData()
    }, [getData])

    return { basicData, getData  }
}


export default SingleWorkingCalendar