import { faPlus } from '@fortawesome/pro-light-svg-icons'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { WhiteBox } from '../../../baseComponents/AppLayout/WhiteBox'
import { ButtonPrimary } from '../../../baseComponents/Button'
import { joinAndRemoveDuplicatedBars } from '../../../baseComponents/CreateRoutering/functions'
import { ComponentWithPermissions } from '../../../baseComponents/CreateRoutering/types'
import { ButtonConfigColumnsWithModal } from '../../../baseComponents/ModalsLayouts/ModalConfigColumnsTabs'
import PermissionChecker from '../../../baseComponents/PermissionChecker'
import SearchBar from '../../../baseComponents/SearchBar'
import TwinIcon from '../../../baseComponents/TwinIcon'
import { TableInstanceType } from '../../../baseComponents/TwinTable/types'
import CustomSelect from '../../../forms/CustomSelect'
import { InputCalendarStateFull } from '../../../forms/Input/InputCalendar'
import { twinFetchPostJSON } from '../../../utils/globals/data'
import { TwinDictionary } from '../../../utils/globals/dictionary'
import { CreateLinkProps, getBasePath } from '../../../utils/globals/link'
import useTaskHeaderSelectorsDict from '../../../utils/hooks/tasks/useTaskHeaderSelectorsDict'
import useConfigColumns, { SelectedColumnsAndFilters } from '../../../utils/hooks/useConfigColumns'
import useTwinTranslation from '../../../utils/hooks/useTwinTranslation'
import { getConfigParam, getUserInfo } from '../../../utils/reducers/getters'
import { addBottomNavAppElement } from '../../../utils/reducers/reduxDispatch'
import TaskTable from './TaskTable'
import { TableWhereSearchBarTy, TaskScreenTableBaseTy, TaskScreenTableInfoTy } from './types'
import { useDebounce } from 'use-debounce'


interface TaskScreenTableWhiteBoxProps extends TaskScreenTableProps {}

export const TaskScreenTableWhiteBox: React.FC<TaskScreenTableWhiteBoxProps> = ({ extraWhereParams, ...rest}) => {
    return (
        <WhiteBox className='flex flex-auto py-40 px-50 task_table_wb'>
            <TaskScreenTable {...rest} extraWhereParams={{ ...extraWhereParams }}  />
        </WhiteBox>
    )
}

type TaskScreenTableProps = ComponentWithPermissions & TaskScreenTableLogicProps & TaskScreenTableBaseTy & {
    pageData: TaskScreenTableInfoTy
    name: string
    createRowDataLink: CreateLinkProps
    floatingTasks: boolean
    extraCreateTaskParams?: TwinDictionary
    extraHeaders?: { [key: string]: React.FC<any> }
}

const TaskScreenTable: React.FC<TaskScreenTableProps> = ({ name, columns, getDataFrom, createRowDataLink, listenSocketStr, pageData, extraWhereParams, floatingTasks, userPermissions, ...rest }) => {
    const {t} = useTwinTranslation()

    const { selectedColumnsAndFilters, setSelectedColumnsAndFilters, memorizedColumns } = useConfigColumns({ columns, name })
    const { changeHeaderTableWhereParams, createTask, headerTableWhereParams, setTableInstance, searchValue, handleSearchValue } = useTaskScreenTableLogic({ floatingTasks, setSelectedColumnsAndFilters, ...rest})
    
    const { dictAssignation, dictFinished, dictSubTasks, dictVisibles } = useTaskHeaderSelectorsDict()

    const finishedFilterValue = selectedColumnsAndFilters?.filters['finished_filter']?.value
    const assignedFilterValue = selectedColumnsAndFilters?.filters['assigned']?.value
    const visiblesFilterValue = selectedColumnsAndFilters?.filters['visibles']?.value
    const subtaskFilterValue = selectedColumnsAndFilters?.filters['subtask']?.value
    return (
        <div className='task_screen_table flex flex-col flex-auto w-1'>
            <div className='flex justify-between items-center'>
                <div className='regular18 task_table_title'>{pageData.title}</div>
                <div className='flex items-center'>
                    <ButtonConfigColumnsWithModal name={name} columns={columns} setSelectedColumnsAndFilters={setSelectedColumnsAndFilters} selectedColumnsAndFilters={selectedColumnsAndFilters} />
                    <PermissionChecker userPermissions={userPermissions} permission='create'>
                        <ButtonPrimary className='ml-15' onClick={createTask}>
                            <TwinIcon icon={faPlus} className='mr-8' />
                            {pageData?.newText}
                        </ButtonPrimary>
                    </PermissionChecker>
                </div>
            </div>
            <div className='flex justify-end items-end mb-20 mt-15'>
                <SearchBar className='search_bar_table mr-30' placeholder={t('searchKeywordDots', 'Busca por palabra clave...')} searchValue={searchValue} onChange={handleSearchValue} />
                <InputCalendarStateFull className='mr-30' label={t('from', 'Desde')} onlyValids={true} value={headerTableWhereParams['start_date']} onChange={(value) =>changeHeaderTableWhereParams('start_date', value)} />
                <InputCalendarStateFull className='mr-auto' label={t('to', 'Hasta')} onlyValids={true} value={headerTableWhereParams['end_date']} onChange={(value) => changeHeaderTableWhereParams('end_date', value)} />
                <CustomSelect className='w-120 ml-30' label={t('status', 'Estado')} items={dictFinished} value={finishedFilterValue || headerTableWhereParams['finished']} onChange={(value) => changeHeaderTableWhereParams('finished', value)} readOnly={finishedFilterValue !== undefined ? true : false} />                
                {name !== 'tableTasksByUser' ? <CustomSelect className='w-120 ml-30' label={t('assignation', 'Asignación')} items={dictAssignation} value={assignedFilterValue || headerTableWhereParams['assigned']} onChange={(value) => changeHeaderTableWhereParams('assigned', value)} readOnly={assignedFilterValue !== undefined ? true : false} /> : null}                
                <CustomSelect className='w-120 ml-30' label={t('visibles', 'Visibles')} items={dictVisibles} value={visiblesFilterValue || headerTableWhereParams['visibles']} onChange={(value) => changeHeaderTableWhereParams('visibles', value)} readOnly={visiblesFilterValue !== undefined ? true : false} />
                <CustomSelect className='w-120 ml-30' label={t('subTasks', 'Subtareas')} items={dictSubTasks} value={subtaskFilterValue || headerTableWhereParams['subtask']} onChange={(value) => changeHeaderTableWhereParams('subtask', value)} readOnly={subtaskFilterValue !== undefined ? true : false} /> 
            </div>
            <TaskTable generalSearchbar={headerTableWhereParams.generalSearchBar} {...{ name, getDataFrom, listenSocketStr, columns, createRowDataLink, floatingTasks, selectedColumnsAndFilters, setSelectedColumnsAndFilters, memorizedColumns, extraWhereParams, headerTableWhereParams, setTableInstance }} />
        </div>
    )
}


interface TaskScreenTableLogicProps{
    limitPath: string
    floatingTasks: boolean
    extraCreateTaskParams?: TwinDictionary
    setSelectedColumnsAndFilters?: React.Dispatch<React.SetStateAction<SelectedColumnsAndFilters>>
}
const useTaskScreenTableLogic = ({ limitPath, floatingTasks, extraCreateTaskParams, setSelectedColumnsAndFilters }: TaskScreenTableLogicProps) => {
    const [tableInstance, setTableInstance] = useState<TableInstanceType>(null)
    const [headerTableWhereParams, setHeaderTableWhereParams] = useState<TableWhereSearchBarTy>({ generalSearchBar: '', start_date: '', end_date: '', finished: 1, visibles: 0, assigned: 1, subtask: 1 })
    const navigate = useNavigate()
    const location = useLocation()
    const [searchValue, setSearchValue] = useState<string>('')
    const [debounceValue] = useDebounce(searchValue, 150)

    const handleSearchValue = useCallback((changedValue: string) => {
        setSearchValue(changedValue)
    }, [setSearchValue])

    

    const basePath = getBasePath(location.pathname, limitPath)

    const changeHeaderTableWhereParams = useCallback((field: keyof TableWhereSearchBarTy, value: string | number) => {
        let mustUpdate = true
        if (field === 'end_date' && value !== '') {
            const dateEnd = moment(value).endOf('day').toDate()
            value = dateEnd.toUTCString()
        }
        setHeaderTableWhereParams((old)=>{
            if(old[field] !== undefined && old[field] === value){
                mustUpdate = false
                return old
            }
            const copyOld =JSON.parse(JSON.stringify(old))
            return {...copyOld, [field]: value}
        })
        if(mustUpdate){
            tableInstance?.getTableDataFromStart()
        }
    }, [setHeaderTableWhereParams, tableInstance])
    
    const user = getUserInfo()
    const createTask = useCallback(async () => {
        let WorkingCenterId = undefined
        const workingCenterByDefault = getConfigParam('assignTaskYourWorkingCenterByDefault')
        if (workingCenterByDefault && workingCenterByDefault === 'on') {
            WorkingCenterId = user?.WorkingCenterId
        }
        const createdTask = await twinFetchPostJSON('/api/app/task/createTask', { WorkingCenterId, ...extraCreateTaskParams })
        if(createdTask){
            if(floatingTasks){
                addBottomNavAppElement({ type: 'taskModal', key: 'taskModal-' + createdTask.id, extraData: { id: createdTask.id, opened: true } })
            }else {
                navigate(joinAndRemoveDuplicatedBars(basePath, '/'+ createdTask.id))
            }
        }
    }, [navigate, basePath, user?.WorkingCenterId, floatingTasks, extraCreateTaskParams])

    const onChangeFilter = useCallback((json: TwinDictionary) => {
        if (setSelectedColumnsAndFilters) {
            setSelectedColumnsAndFilters({ filters: json?.value?.filters || {}, selectedColumns: json?.value?.selectedColumns || {}, id: json.id, name: json.name, order: json?.value?.order || {} })
        }
    }, [setSelectedColumnsAndFilters])

    useEffect(() => {
        changeHeaderTableWhereParams('generalSearchBar', debounceValue)
    }, [debounceValue, changeHeaderTableWhereParams])
   
    return { changeHeaderTableWhereParams, createTask, headerTableWhereParams, setTableInstance, onChangeFilter, searchValue, handleSearchValue }
}


export default TaskScreenTable