import { Trans } from 'react-i18next'


interface TwinTransProps {
    transKey: string
    children: React.ReactNode;
}

const TwinTrans: React.FC<TwinTransProps> = ({transKey, children}) => {
    return (
        <Trans i18nKey={transKey}>{children}</Trans>
    )
}

export default TwinTrans