import { GroupModelType } from '@teinor/erp/types/company/userInner/group'
import { dictionaryComplexFromJsonArr } from '../../globals/dictionary'

export interface GroupAction {
    type: 'CHANGE_GROUP'
    payload: GroupModelType[]
}
export type DictionaryGroup = { [id: number]: GroupModelType }

export type GroupPayload =  null | DictionaryGroup


export default function groups(state: GroupPayload = null, action: GroupAction): DictionaryGroup | null {
    switch (action.type) {
        case 'CHANGE_GROUP':
            const dictionaryGroup = dictionaryComplexFromJsonArr(action.payload || [])
            return state = dictionaryGroup
        default:
            return state
    }
}