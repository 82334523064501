import { faArrowDownBigSmall } from '@fortawesome/pro-light-svg-icons'
import { useRef, useState } from 'react'
import { HeaderElementTask } from '..'
import { ModalOpenedTy } from '../../../../../baseComponents/Modal/types'
import useOnClickOutside from '../../../../../utils/hooks/useOnClickOut'
import { UpdateTaskTy } from '../../types'
import ModalTaskTransformSubTask from './ModalTaskTransformSubtask'

interface HeaderTaskTransformSubtaskProps {
    id: string
    message: string
    updateTask: UpdateTaskTy
}

const HeaderTaskTransformSubtask: React.FC<HeaderTaskTransformSubtaskProps> = ({ id, updateTask, message }) => {
    const { ref, opened, setOpened } = useHeaderTaskTransformSubtaskLogic()
    return (
        <HeaderElementTask subRef={ref} id={'transformSubTask_' + id} message={message} icon={faArrowDownBigSmall} onClick={() => setOpened((old) => old ? null : true)}>
            {opened ?
                <ModalTaskTransformSubTask {...{ setOpened, updateTask }} />
                : null
            }
        </HeaderElementTask>
    )
}


const useHeaderTaskTransformSubtaskLogic = () => {
    const [opened, setOpened] = useState<ModalOpenedTy>(null)
    const ref = useRef<any>(null)

    useOnClickOutside(ref, () => setOpened(null))

    return { ref, opened, setOpened }
}

export default HeaderTaskTransformSubtask