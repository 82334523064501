import { METaskBagOfHoursModelTypeExtended, MEBOHTaskParsed } from './types'

export const parsedDataBagOfHoursDetail = (bagOfHours: METaskBagOfHoursModelTypeExtended) => {
    const data: METaskBagOfHoursModelTypeExtended = {
        ...bagOfHours,
        Tasks: []
    }
    for (const task of bagOfHours.Tasks) {
        const taskParsed: MEBOHTaskParsed = {
            ...task,
            workedSeconds: 0,
            dictionaryWorkLogs: {}
        }
        if (task.TaskWorkLogs) {
            for (const taskWorkLog of task.TaskWorkLogs) {
                const employee = taskWorkLog.Employee
                const task = taskParsed.dictionaryWorkLogs
                if (employee) {
                    if (!task[employee.id]) {
                        task[employee.id] = {
                            employeeName: employee.fullname_short,
                            employeeImage: employee.profile_image,
                            logs: [],
                            totalWorkedSecs: 0
                        }
                    }
                    task[employee.id].logs.push(taskWorkLog)
                    task[employee.id].totalWorkedSecs = task[employee.id].totalWorkedSecs + taskWorkLog.workedSecs
                }
                taskParsed.workedSeconds += taskWorkLog.workedSecs
            }
        }
        data.Tasks.push(taskParsed)
    }

    return data
}