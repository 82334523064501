import { useCallback } from 'react'
import ButtonModalSortList, { ItemSortFunction } from '../../../../../../../../baseComponents/Sorts/ButtonModalSortList'
import { BaseButtonModalSortListProps } from '../../../../../../../../baseComponents/Sorts/ButtonModalSortList/types'
import { twinFetchPostText } from '../../../../../../../../utils/globals/data'
import { useParams } from 'react-router-dom'

interface TaskCustomFieldValueSortButtonProps extends BaseButtonModalSortListProps {
}

const TaskCustomFieldValueSortButton: React.FC<TaskCustomFieldValueSortButtonProps> = ({ ...rest }) => {
    const { updateOrder } = useTaskCustomFieldValueSortButtonLogic()
    const { TaskCFieldId } = useParams()
    return (
        <ButtonModalSortList updateData={updateOrder} getDataFrom='/api/app/task/customField/value/getAllTaskCFieldValues' extraWhereParams={{TaskCFieldId}} {...rest} />
    )
}

const useTaskCustomFieldValueSortButtonLogic = () => {
    const updateOrder: ItemSortFunction = useCallback(async (data) => {
        const resp = await twinFetchPostText('/api/app/task/customField/value/updateTaskCFieldValueOrder', { taskCFieldValues: data })
        if (resp) {
            return true
        }
        return false
    }, [])

    return { updateOrder }
}

export default TaskCustomFieldValueSortButton