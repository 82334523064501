import { faX } from '@fortawesome/pro-light-svg-icons'
import { Modify } from '@teinor/erp'
import { WCalFreeModelType } from '@teinor/erp/types/company/WCalParent/WCal/WCalFree'
import { useState, useMemo, useCallback, useEffect } from 'react'
import { useParams } from 'react-router'
import { ButtonPrimary } from '../../../../../../../baseComponents/Button'
import { ComponentWithPermissions } from '../../../../../../../baseComponents/CreateRoutering/types'
import { BigTitleAndRightLayout } from '../../../../../../../baseComponents/Layout/TitleAndRightLayout'
import PermissionChecker from '../../../../../../../baseComponents/PermissionChecker'
import { TwinBigCalendarEvents } from '../../../../../../../baseComponents/TwinBigCalendar/types'
import TwinIcon from '../../../../../../../baseComponents/TwinIcon'
import TwinTrans from '../../../../../../../baseComponents/TwinTrans'
import { EventsWorkingCalendarProps } from '../../../../../../../baseComponents/WorkingCalendar'
import WhiteBoxCalendarEvents from '../../../../../../../specificComponents/WCal/WhiteBoxCalendarEvents'
import { twinFetchPostJSON } from '../../../../../../../utils/globals/data'
import useEditDeleteModal from '../../../../../../../utils/hooks/useEditDeleteModal'
import useMonthDictionary from '../../../../../../../utils/hooks/useMonthDictionary'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import { appStore } from '../../../../../../../utils/reducers'
import { setVariablesToCurrentPage } from '../../../../../../../utils/reducers/reduxDispatch'
import ModalCreateWCFreeDays from './ModalCreateWCFreeDays'
import ModalDeleteWCFreeDays from './ModalDeleteWCFreeDays'

interface WorkingCalendarFreeDaysProps extends WorkingCalendarFreeDaysLogicProps { }

const WorkingCalendarFreeDays: React.FC<WorkingCalendarFreeDaysProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()
    const { openModal, setOpenModal, getData, events } = useWorkingCalendarFreeDaysLogic({ userPermissions })
    return (
        <BigTitleAndRightLayout
            title={t('freeDays', 'Días festivos')}
            RightHeader={
                <PermissionChecker userPermissions={userPermissions} permission='create'>
                    <ButtonPrimary onClick={() => setOpenModal({ type: 'create', allRowData: {} })}><TwinTrans transKey={'addFreeDay'}>Añadir Día Festivo</TwinTrans></ButtonPrimary>
                </PermissionChecker>
            }>
            <WCFreeDaysParentCalendar events={events} getData={getData} />
            {openModal?.type === 'create' ? <ModalCreateWCFreeDays setOpened={() => setOpenModal(null)}  getData={getData} /> : null}
            {openModal?.type === 'delete' ? <ModalDeleteWCFreeDays setOpened={() => setOpenModal(null)} onSubmit={getData} id={openModal.allRowData.id} /> : null}
        </BigTitleAndRightLayout>
    )
}
interface WorkingCalendarFreeDaysLogicProps extends ComponentWithPermissions { }

export const useWorkingCalendarFreeDaysLogic = ({ userPermissions }: WorkingCalendarFreeDaysLogicProps) => {
    const { id, year } = useParams()
    const [events, setEvents] = useState<EventsWorkingCalendarFree>(null)
    const { t } = useTwinTranslation()
    const freeDay = t('freeDay', 'Día festivo')
    const months = useMonthDictionary()
    const userPermissionsStr = JSON.stringify(userPermissions)    
    const { openModal, setOpenModal } = useEditDeleteModal()
    const montshJSON = JSON.stringify(months)
    const myYear = useMemo(() => parseInt(year || '0'), [year])
    const parseDateISOToString = useCallback((dateISO: Date) => {
        const myMonths = JSON.parse(montshJSON)
        const dateString = dateISO.getDate() + '  ' + myMonths[dateISO.getMonth()]
        return dateString
    }, [montshJSON])

    const parseDataToEvents = useCallback((data: WCalFreeModelType) => {
        const userPermissionsParsed = JSON.parse(userPermissionsStr)
        const wCalFreeDays = data?.WCalFreeDays
        const eventsParsed: TwinBigCalendarEvents = []
        if (wCalFreeDays) {
            for (const element of wCalFreeDays) {
                const date = new Date(element.day)
                const dayParsed = parseDateISOToString(date)
                eventsParsed.push({
                    id: element.id,
                    title: element.name || freeDay,
                    allDay: false,
                    start: date,
                    subtitle: dayParsed,
                    color: element.color,
                    rightSide: (
                        <PermissionChecker userPermissions={userPermissionsParsed} permission='delete'>
                            <TwinIcon icon={faX} className='cursor-pointer ml-auto' onClick={() => setOpenModal({ type: 'delete', allRowData: { id: element.id } })}></TwinIcon>
                        </PermissionChecker>
                    )
                })
            }
            return eventsParsed
        }
        return null
    }, [parseDateISOToString, setOpenModal, userPermissionsStr, freeDay])

    const getData = useCallback(async () => {
        const currentPageVariables = appStore.getState()?.currentPage?.variables
        setEvents(null)
        const result = await twinFetchPostJSON('/api/app/workingCalendar/workingCalendarFree/getWCalFreeEventsForUpdate', {
            id,
            year: myYear
        })
        if (result) {
            let parsedEvents: EventsWorkingCalendarFree = null
            if (result?.msg === 'LAST_YEAR_EVENTS') {
                parsedEvents = 'LAST_YEAR_EVENTS'
            } else {
                parsedEvents = parseDataToEvents(result)
            }
            await setEvents(parsedEvents)
            if (!currentPageVariables?.length) {
                setVariablesToCurrentPage([result.name])
            }
        }
    }, [parseDataToEvents, id, myYear, setEvents])

    useEffect(() => {
        getData()
    }, [getData])


    return { openModal, setOpenModal, getData, events }
}

type EventsWorkingCalendarFree = TwinBigCalendarEvents | null | 'LAST_YEAR_EVENTS'
type WCFreeDaysParentCalendarProps = Modify<EventsWorkingCalendarProps, WCFreeDaysParentCalendarLogicProps & {
    events: EventsWorkingCalendarFree
}>

const WCFreeDaysParentCalendar: React.FC<WCFreeDaysParentCalendarProps> = ({events, ...logic}) => {
    const { handlePreviousYearEvents } = WCFreeDaysParentCalendarLogic({...logic})
    return (
        <WhiteBoxCalendarEvents events={events} calendarProps={{ defaultView: 'year' }} handlePreviousYearEvents={handlePreviousYearEvents} />
    )
}

interface WCFreeDaysParentCalendarLogicProps {
    getData: () => Promise<void>
}

const WCFreeDaysParentCalendarLogic = ({getData}: WCFreeDaysParentCalendarLogicProps) => {
    const { id, year } = useParams()
    const handlePreviousYearEvents = useCallback(async () => {
        const myYear = parseInt(year || '0')
        let result = await twinFetchPostJSON('/api/app/workingCalendar/workingCalendarFree/copyWCalFreeDaysFromLastYear', {
            id,
            year: myYear
        })
        if (result) {
            getData()
        }
    }, [getData, year, id])
    return { handlePreviousYearEvents }
}

export default WorkingCalendarFreeDays
