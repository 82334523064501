import { faSearch } from '@fortawesome/pro-light-svg-icons'
import { InputWithRightIcon } from '../../forms/Input'
import { ChangeValueFunc } from '../../utils/globals/types'
import Checkbox from '../../forms/Checkbox'

interface SearchBarProps {
    onChange: ChangeValueFunc
    searchValue: string
    className?: string
    placeholder?: string
    onFocus?: () => void
}

const SearchBar: React.FC<SearchBarProps> = ({ searchValue, className, ...rest }) => {
    return (
        <div className={' light14 searchbar ' + (className || '')} onClick={(e) => e.stopPropagation()}>
            <InputWithRightIcon className='placeholder-gray-97 w-full ' iconStyles={{ height: '16px', width: '16px', color: '#636363' }} icon={faSearch} value={searchValue} {...rest} />
        </div>
    )
}

interface SearchBarCheckBoxProps extends SearchBarProps {
    checked: boolean
    onChangeCheckbox?: (value: boolean) => void
    label?: string
}

export const SearchBarCheckBox: React.FC<SearchBarCheckBoxProps> = ({ searchValue, className, label, onChangeCheckbox, checked, ...rest }) => {
    return (
        <div className={' light14 searchbar flex border-b-green-21 ' + (className || '')} onClick={(e) => e.stopPropagation()}>
            <Checkbox className='ml-21' label={label || ''} onChange={onChangeCheckbox} {...{ checked }} />
            <InputWithRightIcon className='placeholder-gray-97 w-full ' iconStyles={{ height: '16px', width: '16px', color: '#636363' }} icon={faSearch} value={searchValue} {...rest} />
        </div>
    )
}


export default SearchBar