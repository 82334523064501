import { TwinParameters } from '@teinor/erp/types/company/config/parameter'
import { useCallback, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { ComponentWithPermissions } from '../../../../../baseComponents/CreateRoutering/types'
import EditCardTabs from '../../../../../baseComponents/EditCardTabs'
import { twinFetchPostJSON } from '../../../../../utils/globals/data'
import { getBasePath } from '../../../../../utils/globals/link'
import { ComponentTabObject } from '../../../../../utils/hooks/useTabLogic'
import useTwinTranslation from '../../../../../utils/hooks/useTwinTranslation'
import MainParameters from './Tabs/MainParameters'

type ConfigParametersProps = ComponentWithPermissions & {}

const ConfigParameters: React.FC<ConfigParametersProps> = ({ userPermissions }) => {
    const location = useLocation()
    const { t } = useTwinTranslation()
    const params = useParams()
    const { basicData, getData } = useConfigParametersLogic()
    const tabs: ComponentTabObject = {
        'main': { component: MainParameters, text: t('general', 'General'), permission: 'config.parameter' },
    }
    return (<EditCardTabs basePath={getBasePath(location.pathname, 'parameters')} basicData={basicData} tabs={tabs} tab={params.tab} userPermissions={userPermissions} getData={getData} />)
}

const useConfigParametersLogic = () => {
    const [basicData, setBasicData] = useState<TwinParameters | null>(null)

    const getData = useCallback(async () => {
        const myData = await twinFetchPostJSON('/api/app/config/parameter/getAllParameters', {})
        if (myData) {
            const result: any = {}
            for (const element of myData) {
                result[element.id] = element.value
            }
            setBasicData(result)
        }
    }, [setBasicData])

    useEffect(() => {
        getData()
    }, [getData])

    return { basicData, getData }
}

export default ConfigParameters