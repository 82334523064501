import { CustomerShopModelType } from '@teinor/erp/types/company/customer/customerShop'
import { useParams } from 'react-router'
import { MediumModalCreateEdit } from '../../../../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../../../../../../../../baseComponents/ModalsLayouts/types'
import { permissionCheck } from '../../../../../../../../../../baseComponents/PermissionChecker/function'
import { FormRender } from '../../../../../../../../../../forms/FormRenderer/types'
import { valueOrDefaultValue } from '../../../../../../../../../../utils/globals/valueOrDefaultValue'
import useTwinTranslation from '../../../../../../../../../../utils/hooks/useTwinTranslation'
import { getDictionaryCountries } from '../../../../../../../../../../utils/reducers/getters'
import { getDictionaryStates } from '../../../../../../../../../../utils/globals/countries'
import useCountryState from '../../../../../../../../../../utils/hooks/useCountry'

interface ModalCEContactShopProps extends ModalEditComponentProps { }

const ModalCEContactShop: React.FC<ModalCEContactShopProps> = ({ userPermissions, allRowData, ...rest }) => {
    const { id } = useParams()
    const { t } = useTwinTranslation()
    const { country, setCountry } = useCountryState({ defaultCountry: allRowData?.country })
    const fields: FormRender<CustomerShopModelType> = [
        {
            cols: 2,
            elements: [
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'responsible',
                    label: t('responsible', 'Responsable'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'id',
                    component: 'InputHidden'
                },
                {
                    name: 'CustomerId',
                    component: 'InputHidden',
                    value: id
                },
                {
                    name: 'address',
                    label: t('address', 'Dirección'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'additional_address',
                    label: t('additional_address', 'Dirección adicional'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'postal_code',
                    label: t('postal_code', 'Código Postal'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'country',
                    label: t('country', 'País'),
                    component: 'CustomSelectWithSearchBar',
                    items: getDictionaryCountries(),
                    value: allRowData?.country,
                    onChange: (value: string) => setCountry(value)
                },
                {
                    name: 'state',
                    label: t('state', 'Provincia'),
                    component: 'CustomSelectWithSearchBar',
                    sortItems: true,
                    items: getDictionaryStates(country), 
                    value: allRowData?.state
                },
                {
                    name: 'city',
                    label: t('city', 'Ciudad'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'phone',
                    label: t('phone', 'Teléfono'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'mobile',
                    label: t('mobile', 'Móvil'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'email',
                    label: t('email', 'Email'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'type_id_number',
                    component: 'InputHidden',
                    value: id
                },
                {
                    name: 'id_number',
                    component: 'InputHidden',
                    value: id
                },
            ],
        },
    ]
    if (allRowData && userPermissions) {
        fields[0].elements.push({
            name: 'isMain',
            label: t('isMainWC', 'Centro principal'),
            component: 'CheckboxMargin',
        })
        const parsedFields = valueOrDefaultValue(fields, userPermissions, allRowData) 

        return (<MediumModalCreateEdit fields={parsedFields} url={'/api/app/customer/customerShop/updateCustomerShop'} translations={{ title: t('editWorkingCenter', 'Editar centro de trabajo'), button: t('save', 'Guardar') }} haveButtonPermissions={permissionCheck(userPermissions, 'update')} {...rest} >
        </MediumModalCreateEdit>)
    }

    return (<MediumModalCreateEdit fields={fields} url={'/api/app/customer/customerShop/createCustomerShop'} translations={{
        title: t('createWorkingCenter', 'Crear centro de trabajo'),
        button: t('create', 'Crear')
    }} haveButtonPermissions={true} {...rest} />)
}

export default ModalCEContactShop