import { memo, useEffect, useLayoutEffect, useRef } from 'react'
import { memoChecker } from '../../../utils/globals/components'
import { TwinDictionary } from '../../../utils/globals/dictionary'
import { TwinAutoSizer } from '../Adapters'
import { ColumnConfiguration } from '../VirtualTableListing/Subcomponents/types'
import { calculateColumnWidth, cleanVTColumnsForMemo, getVirtualizedListingColumnWidth } from '../VirtualTableListing/functions'
import { TableWithoutVirtualListingRowRenderer, TableWithoutVirtualListingRowProps } from './types'


export interface TableWithoutVirtualListingPropsBase {
    data: TwinDictionary[] | null
    columns: ColumnConfiguration[]
    rowRenderer?: TableWithoutVirtualListingRowRenderer
}

interface TableWithoutVirtualListingProps extends TableWithoutVirtualListingPropsBase { 
    disableScroll?: boolean
}

const TableWithoutVirtualListing: React.FC<TableWithoutVirtualListingProps> = ({ columns, data, rowRenderer, disableScroll  = false}) => {

    return (
        <div className='table_without_virtual flex-auto overflow-x-auto overflow-y-hidden h-full'>
            <TwinAutoSizer disableHeight={disableScroll}>
                {({ width, height }) => (
                    <TableWithoutVirtualListingAutosized width={width} height={height} columns={columns} data={data} rowRenderer={rowRenderer} />
                )}
            </TwinAutoSizer>
        </div>
    )
}

interface TableWithoutVirtualListingAutosizedProps extends TableWithoutVirtualListingPropsBase {
    width: number
    height: number
    defaultMinWidthColumns?: number
}

const TableWithoutVirtualListingAutosized: React.FC<TableWithoutVirtualListingAutosizedProps> = ({ width, height, data, columns, defaultMinWidthColumns = 100, rowRenderer }) => {
    const widthCalcul = calculateColumnWidth(width, columns, defaultMinWidthColumns)
    const scrollable = widthCalcul.newWidth !== width
    const dictWidthColumns: TwinDictionary = {}
    const { ref, tableNode } = useTableWithoutVirtualListingAutosizedLogic()

    const rowStyle = {
        width: widthCalcul.newWidth + 'px',
        maxWidth: widthCalcul.newWidth + 'px',
    }

    const containerStyle = {
        width: width + 'px',
        height: (height - 50) + 'px',
    }

    for (const column of columns) {
        const width = getVirtualizedListingColumnWidth(column, widthCalcul.columnWidth, scrollable, defaultMinWidthColumns)
        dictWidthColumns[column.id] = {
            flex: `0 1 ${width}px`,
            minWidth: column.width ? column.width : (width < defaultMinWidthColumns) ? defaultMinWidthColumns : width + 'px',
        }
    }

    const renderRows: JSX.Element[] = []
    if (data) {
        for (const rowIndex in data) {
            const props: TableWithoutVirtualListingRowProps = {
                style: rowStyle,
                columns,
                rowData: data[rowIndex],
                dictWidthColumns,
                rowIndex: parseInt(rowIndex)
            }
            if (rowRenderer) {
                renderRows.push(rowRenderer(props))
            } else {
                renderRows.push(<TableWithoutDefaultRowRenderer {...props} />)
            }
        }
    }

    return (
        <div className='table_without_virtual_inner'>
            <RowHeader tableNode={tableNode} style={{ width: width + 'px' }} columns={columns} dictWidthColumns={dictWidthColumns} />
            <div ref={ref} className='table_grid_rows overflow-y-auto overflow-x-auto' style={containerStyle}>
                <div className='data_rows h-full' style={rowStyle}>{renderRows}</div>
            </div>
        </div>
    )
}

const useTableWithoutVirtualListingAutosizedLogic = () => {
    const ref = useRef<any>(null)
    const tableNode = ref.current

    useEffect(() => {
        const listener = (event: any) => {
            const target = event.target
            const header = target?.parentElement?.querySelector('.row_header')
            if (target.scrollLeft !== header.scrollLeft) {
                header.scrollLeft = target.scrollLeft
            }
        };
        if (tableNode) {
            tableNode.addEventListener("scroll", listener);
        }
        return () => {
            if (tableNode) {
                tableNode.removeEventListener("scroll", listener);
            }
        };
    }, [tableNode])

    return { ref, tableNode }
}


interface RowHeaderProps {
    style?: React.CSSProperties
    dictWidthColumns: TwinDictionary
    columns: ColumnConfiguration[]
    tableNode: null | HTMLElement
}

const RowHeader: React.FC<RowHeaderProps> = memo(({ style, dictWidthColumns, columns, tableNode }) => {
    const rowColumns: JSX.Element[] = []

    useLayoutEffect(() => {
        if (tableNode) {
            const header = tableNode.parentElement?.querySelector('.row_header') as any
            if (header) {
                const maxScrollLeftHeader = header.scrollWidth - header.clientWidth;
                const maxScrollLeftTable = tableNode.scrollWidth - tableNode.clientWidth;
                if (maxScrollLeftHeader !== maxScrollLeftTable) {
                    const diff = maxScrollLeftTable - maxScrollLeftHeader
                    const padding = header?.style?.paddingRight.substring(0, header?.style?.paddingRight?.indexOf('px')) || 0
                    header.style.paddingRight = parseFloat(String(padding)) + diff + 'px'
                }
            }
        }
    }, [tableNode])

    for (const column of columns) {
        if (column.havePermission !== false && column.haveModule !== false) {
            rowColumns.push(<ColumnHeader key={'header' + column.id} style={dictWidthColumns[column.id]} column={column} />)
        }
    }

    return (<div className={`row_header flex px-8 border-b border-gray-F7 bg-gray-F7 overflow-hidden`} style={style}>{rowColumns}</div>)
}, (oldProps, nextProps) => memoChecker(oldProps, nextProps, ['columns', 'style',], { 'columns': ['props', '_owner', 'label'] }, { 'label': cleanVTColumnsForMemo }))

interface ColumnHeaderProps {
    style?: React.CSSProperties
    column: ColumnConfiguration
}

const ColumnHeader: React.FC<ColumnHeaderProps> = ({ style, column }) => {
    return (<div className={'header_column flex px-8 py-12 font-medium items-center h-50 ' + (column.headerClassName || '')} style={style}>{!column.label || typeof column.label === 'string' ? column.label : column.label()}</div>)
}


export const TableWithoutDefaultRowRenderer: React.FC<TableWithoutVirtualListingRowProps> = ({ style, className, rowData, dictWidthColumns, rowIndex, columns }) => {
    const rowColumns: JSX.Element[] = []
    for (const column of columns) {
        if (column.havePermission !== false && column.haveModule !== false) {
            rowColumns.push(<Column key={rowIndex + column.id} style={dictWidthColumns[column.id]} rowData={rowData} rowIndex={rowIndex} column={column} />)
        }
    }
    return (<div className={'table_row flex px-8 border-b border-gray-F7 overflow-hidden cursor-pointer ' + (className || '')} style={style}>{rowColumns}</div>)
}

interface ColumnProps {
    style?: React.CSSProperties
    className?: string
    rowData: TwinDictionary
    rowIndex: number
    column: ColumnConfiguration
}

const Column: React.FC<ColumnProps> = ({ style, className, rowData, rowIndex, column }) => {
    const result = rowData[column.dataKey]
    let columnResult = result

    if (column.parseData) {
        columnResult = column.parseData?.(result)
    }
    if (column.customRender) {
        columnResult = column.customRender(result, { ...rowData, rowIndex })
    }

    return (<div className={'table_column flex px-8 py-12 overflow-hidden' + (className || '')} style={style}>{columnResult}</div>)
}

export default TableWithoutVirtualListing