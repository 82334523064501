import { useLocation, useParams } from 'react-router-dom'
import { ComponentWithPermissions } from '../../../../../../../baseComponents/CreateRoutering/types'
import { getBasePath } from '../../../../../../../utils/globals/link'
import TasksTableDefault from '../../../../../../../specificComponents/Task/TasksTableDefault'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import { AllReduxPayloads } from '../../../../../../../utils/reducers'
import { ConnectedProps, connect } from 'react-redux'
import withLoading from '../../../../../../../utils/hoc/withLoading'
import { EmployeesPayload } from '../../../../../../../utils/reducers/company/employees'

type TaskByEmployeeProps = ComponentWithPermissions & ReduxTaskByEmployeeConnector & { }

const TaskByEmployee: React.FC<TaskByEmployeeProps> = ({ userPermissions, employees }) => {
    const {t, tVars} = useTwinTranslation()
    const location = useLocation()
    const { EmployeeId } = useParams()
    const basePath = getBasePath(location.pathname, EmployeeId || '')
    let nameEmployee = ''
    if (EmployeeId && employees?.[parseInt(EmployeeId)]?.fullname_short) {
        nameEmployee = employees?.[parseInt(EmployeeId)].fullname_short
    }
    const pageData = {
        title: tVars('tasksOfNameEmployee', 'Tareas de {{nameEmployee}}', { nameEmployee }), 
        newText: t('newTask', 'Nueva tarea')
    }
    return (
        <TasksTableDefault name='tableTasksByUser' extraCreateTaskParams={{ EmployeeId }} limitPath={EmployeeId|| ''} getDataFrom='/api/app/task/getAllTasksByEmployee' listenSocketStr={'getAllTasksEmployee_'+EmployeeId} createRowDataLink={[basePath, ['$id']]}  {...{ userPermissions, basePath, pageData }}  extraWhereParams={{EmployeeId}} />
    )
}

const taskByEmployeeDispatch = {
    setEmployees: (payload:EmployeesPayload) => ({ type: 'CHANGE_EMPLOYEE', payload }),
}

const mapTaskByEmployeeConnector = (state: AllReduxPayloads) => ({ employees: state.employees })
const taskByEmployeeConnector = connect(mapTaskByEmployeeConnector, taskByEmployeeDispatch)
type ReduxTaskByEmployeeConnector = ConnectedProps<typeof taskByEmployeeConnector>
const taskByEmployeeConnectLoading = withLoading(TaskByEmployee, [{ fetchUrl: '/api/app/employee/getAllEmployees', propName: 'employees', setFunctionName: 'setEmployees' }])

export const TaskByEmployeeConnect = taskByEmployeeConnector(taskByEmployeeConnectLoading)

export default TaskByEmployeeConnect