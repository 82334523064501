import { TwinDictionary } from '../../globals/dictionary'
import useTwinTranslation from '../useTwinTranslation'

const useTaskHeaderSelectorsDict = () => {
    const { t } = useTwinTranslation()
    const dictFinished: TwinDictionary = {
        0: { name: t('all', 'Todas'), value: 0 },
        1: { name: t('notFinished', 'Sin finalizar'), value: 1 },
        2: { name: t('finisheds', 'Finalizadas'), value: 2 },
    }
    const dictVisibles: TwinDictionary = {
        0: { name: t('justVisibles', 'Solo visibles'), value: 0 },
        1: { name: t('justHidden', 'Solo escondidas'), value: 1 },
        2: { name: t('all', 'Todas'), value: 2 },
    }
    const dictAssignation: TwinDictionary = {
        0: { name: t('notAssigned', 'Sin asignar'), value: 0 },
        1: { name: t('all', 'Todas'), value: 1 },
        2: { name: t('myTasksAndNotAssigned', 'Mis tareas y las que están sin asignar'), value: 2 },
    }
    const dictSubTasks: TwinDictionary = {
        0: { name: t('justParentTasks', 'Solo tareas padre'), value: 0 },
        1: { name: t('all', 'Todas'), value: 1 },
    }
    return { dictFinished, dictVisibles, dictAssignation, dictSubTasks}
}

export default useTaskHeaderSelectorsDict