import { faEdit, faTrashCan } from '@fortawesome/pro-light-svg-icons'
import { useEffect, useState } from 'react'
import { InputDefault } from '../../../../forms/Input'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import { ComponentWithPermissions } from '../../../CreateRoutering/types'
import { ButtonsModalSmallCentered, ModalSmallCentered } from '../../../Modal'
import { ModalOpenedTy } from '../../../Modal/types'
import ModalDeleteFilled from '../../../ModalsLayouts/ModalDeleteFilled'
import PermissionChecker from '../../../PermissionChecker'
import TwinIcon from '../../../TwinIcon'
import TwinTrans from '../../../TwinTrans'
import { TFSChangePathTy } from '../../types'
import { TFSBreadcrumbTy } from '../types'

interface BaseBreadcrumb {
    item: TFSBreadcrumbTy
    changePath?: TFSChangePathTy
}

interface BreadcrumbDeciderProps{
    isLast: boolean
}

export const TFSBreadcrumbDecider = ({ isLast}: BreadcrumbDeciderProps) => {
    if (isLast) {
        return BreadcrumbLast
    }
    return BreadcrumbDiv
}

interface BreadcrumbDivProps extends BaseBreadcrumb {
    className?: string
}

const BreadcrumbDiv: React.FC<BreadcrumbDivProps> = ({ item, className, changePath }) => {
    return (<span className={'cursor-pointer light16 text-gray-51 ' + (className || '')} onClick={() => changePath?.(item.link)}>{item.name}</span>)
}

interface BreadcrumbLastProps extends BaseBreadcrumb{}

const BreadcrumbLast: React.FC<BreadcrumbLastProps> = ({...rest}) => {
    return (<BreadcrumbDiv className='cursor-default font-normal' {...rest}></BreadcrumbDiv>)
}


type TFSEditNameButtonProps = ComponentWithPermissions & TFSEditNameButtonLogicProps &{
    breadcumbLength: number
    editCurrentFolder: (folderName: string) => void
}

export const TFSEditNameButton: React.FC<TFSEditNameButtonProps> = ({ breadcumbLength, userPermissions, folderName, editCurrentFolder }) => {
    const { opened, setOpened, value, setValue } = useTFSEditNameButtonLogic({folderName})
    const {t} = useTwinTranslation()
    if (breadcumbLength <= 1) {
        return null
    }
    return (
        <PermissionChecker userPermissions={userPermissions} permission={'update'}>
            <div className='text-green-21 flex items-center cursor-pointer' onClick={() => setOpened(true)}>
                <TwinIcon icon={faEdit} style={{ height: '19px', width: '19px' }}></TwinIcon>
                <span className='ml-10 mt-2'><TwinTrans transKey='editName'>Editar nombre</TwinTrans></span>
            </div>
            {opened && <ModalSmallCentered opened={opened} setOpened={setOpened}>
                <h2><TwinTrans transKey='editFolderName'>Editar nombre de la carpeta</TwinTrans> {folderName}</h2>
                <InputDefault placeholder={t('writeNameOfFolder', 'Escribe el nombre de la carpeta')} value={value} onChange={setValue} />
                <ButtonsModalSmallCentered confirmText={t('modify', 'Modificar')} setOpened={setOpened} onAccept={() => editCurrentFolder(value)}></ButtonsModalSmallCentered>
            </ModalSmallCentered>}
        </PermissionChecker>
    )
}

interface TFSEditNameButtonLogicProps {
    folderName: string
}

const useTFSEditNameButtonLogic = ({folderName}: TFSEditNameButtonLogicProps) => {
    const [value, setValue] = useState('')
    const [opened, setOpened] = useState<ModalOpenedTy>(null)

    useEffect(() => {
        if (opened) {
            setValue(folderName)
        }
    }, [folderName, opened])

    return { opened, setOpened, value, setValue }
    
}

type TFSDeleteFolderButtonProps = ComponentWithPermissions & TFSDeleteFolderButtonLogicProps &{
    folderName: string
    deleteCurrentFolder: () => void
}

export const TFSDeleteFolderButton: React.FC<TFSDeleteFolderButtonProps> = ({ userPermissions, deleteCurrentFolder, folderName }) => {
    const { opened, setOpened } = useTFSDeleteFolderButtonLogic()
    const {t} = useTwinTranslation()
    return (
        <PermissionChecker userPermissions={userPermissions} permission={'delete'}>
            <div className='verticalSeparator'></div>
            <div className='text-red-BA flex items-center cursor-pointer' onClick={() => setOpened(true)}>
                <TwinIcon icon={faTrashCan} style={{ height: '19px', width: '19px' }}></TwinIcon>
                <span className='ml-10 mt-2'><TwinTrans transKey='deleteFolder'>Eliminar carpeta</TwinTrans></span>
            </div>
            {opened && <ModalDeleteFilled opened={opened} setOpened={setOpened} onAccept={deleteCurrentFolder} translations={{
                title: t('sureDeleteFolderv2', '¿Seguro que quieres eliminar la carpeta') + '?',
                subtitle: t('ifYouDeleteFolderAllFilesDeleted', 'Si eliminas la carpeta, todos los ficheros también serán eliminados')
            }}>
            </ModalDeleteFilled>}
        </PermissionChecker>
    )
}

interface TFSDeleteFolderButtonLogicProps {}

const useTFSDeleteFolderButtonLogic = () => {
    const [opened, setOpened] = useState<ModalOpenedTy>(null)
    return {opened, setOpened}
}