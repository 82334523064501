import { sueStatusTypesInversed } from '@teinor/erp/types/company/sue'
import { useCallback, useEffect, useState } from 'react'
import ModalTabs from '../../../../../../baseComponents/ModalsLayouts/ModalTabs'
import { ModalEditComponentProps } from '../../../../../../baseComponents/ModalsLayouts/types'
import { OnSubmit } from '../../../../../../forms/TwinForm/types'
import { twinFetchPostJSON } from '../../../../../../utils/globals/data'
import { ComponentTabObject } from '../../../../../../utils/hooks/useTabLogic'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import MainEditSue from './Tabs/MainEditSue'
import { SueTypeExtendedModalEdit } from './Tabs/MainEditSue/types'
import SueActivityTab from './Tabs/SueActivityTab'

interface ModalEditSueProps extends ModalEditComponentProps {}

const ModalEditSue: React.FC<ModalEditSueProps> = ({ ...rest }) => {
    const { t } = useTwinTranslation()
    const { sueData, getSueData} = useModalEditSueLogic({id: rest.allRowData?.id, onSubmit: rest.onSubmit})
    const tabs: ComponentTabObject = {
        'main': { component: MainEditSue , text: t('data', 'Datos') },
        'activity': { component: SueActivityTab, text: t('activity', 'Actividad') }
    }
    return (
        <ModalTabs {...rest} tabs={tabs} tab={'main'} className='notFlexAutoFormRender' extraComponentData={{ ...rest, sueData, getSueData }}></ModalTabs>
    )
}


interface ModalEditSueLogicProps {
    id: string
    onSubmit: OnSubmit
}

const useModalEditSueLogic = ({ id, onSubmit }: ModalEditSueLogicProps) => {
    const [sueData, setSueData] = useState<SueTypeExtendedModalEdit | null>(null)

    const getSueData = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/sue/getSueComplete', { id })
        if (result) {
            if (!result.allReaded) {
                await onSubmit(result, {})
            }
            const sueMonths = 3
            const initialDate = new Date(result.createdAt)
            const currentDate = new Date()
            const monthsDifference = (currentDate.getFullYear() - initialDate.getFullYear()) * 12 + (currentDate.getMonth() - initialDate.getMonth())
            if (monthsDifference >= sueMonths && result.status !== sueStatusTypesInversed['closed']) {
                result.expired = true
            }
            setSueData(result)
        }
    }, [setSueData, id, onSubmit])

    useEffect(() => {
        getSueData()
    }, [getSueData])

    return { sueData, getSueData }
}


export default ModalEditSue