import { getCompany } from '../../../../utils/reducers/getters';
import { CurrentPagePayload } from '../../../../utils/reducers/globals/currentPage';
import { joinAndRemoveDuplicatedBars } from '../../../CreateRoutering/functions';
import { Routes } from '../../../CreateRoutering/types';
import { RouteBreadcrumb, RouteObject } from './types';


export const makeRouteObject = (routes: Routes, parentLink = '/') => {
    let myRoutes: RouteObject = {}
    for (const route of routes) {
        const myLink = joinAndRemoveDuplicatedBars(parentLink, route.link)
        myRoutes[myLink] = {
            text: route.text,
            link: myLink,
            component: route.component
        }
        if (route.childs) {
            const childRoutes = makeRouteObject(route.childs, myLink)
            myRoutes = {...myRoutes, ...childRoutes}
        }
    }
    return myRoutes
}

export const getBreadcrumbFromCurrentPage = (currentPage: CurrentPagePayload, routeObject: RouteObject) => {
    const result: RouteBreadcrumb[] = []
    const company = getCompany()
    if (currentPage) {
        const variablesLength = currentPage.variables?.length ?? 0
        const routesLength = currentPage.routes.length
        for (const key in currentPage.routes) {
            const keyParsed = parseInt(key)
            const lastLink = result[keyParsed - 1]?.link ?? '/'
            const currentLink = joinAndRemoveDuplicatedBars(lastLink, currentPage.routes[key])
            const routeObjectRound = routeObject[currentLink]
            if (routeObjectRound) {
                let text = routeObjectRound.text
                if (currentPage.variables && (keyParsed + variablesLength) >= routesLength) {
                    const position = Math.abs(routesLength - (keyParsed + variablesLength))
                    text = currentPage.variables[position]
                }
                result.push({text, link: currentLink, component: routeObjectRound.component})
            }
        }
    }
    if (company) {
        result.unshift({ text: company?.name, link: '/' })
    }
    return result
}