import { WidgetDictionary, UserWidget } from '../../useDashboardWidgets/types'
import { WidgetFamiliesDictionaryExtended } from './types'

export const createWidgetThreeByFamilies = (avaliableDashboardWidgets: WidgetDictionary, userDashboardWidgets: UserWidget[], dashboardFamilies: WidgetFamiliesDictionaryExtended) => {
    const userDashboardWidgetsKeys = userDashboardWidgets.map((item) => item.name)
    let myDashboardFamilies = JSON.parse(JSON.stringify(dashboardFamilies))
    for (const key in avaliableDashboardWidgets) {
        if (!userDashboardWidgetsKeys.includes(key)) {
            const element = avaliableDashboardWidgets[key]
            if (!myDashboardFamilies[element.family].childrens) {
                myDashboardFamilies[element.family].childrens = []
            }
            myDashboardFamilies[element.family].childrens?.push({ ...element, name: key })
        }
    }
    return myDashboardFamilies
}
