export interface FingerPrintAction {
    type: 'CHANGE_FINGERPRINT'
    payload: FingerprintPayload
}

export type FingerprintPayload = string

export default function fingerprint(state: FingerprintPayload = '', action: FingerPrintAction): string {
    switch (action.type) {
        case 'CHANGE_FINGERPRINT':
            return state = action.payload
        default:
            return state
    }
}