import {PriceZoneGroupModelType} from '@teinor/erp/types/company/priceZone/priceZoneGroup'
import {PriceZoneModelType} from '@teinor/erp/types/company/priceZone'
import { Modify } from '../../globals/types'

type PriceZoneGroup = Modify<PriceZoneGroupModelType, {
    PriceZones: PriceZoneModelType[]
}>

export type PriceZoneGroups = { [PriceZoneGroupId: string]: PriceZoneGroup }

export interface PriceZoneGroupsAction {
    type: 'CHANGE_PRICEZONEGROUPS'
    payload: PriceZoneGroupModelType[]
}

export type PriceZoneGroupsPayload = PriceZoneGroups | null

export default function pricezonegroups(state: PriceZoneGroupsPayload = null, action: PriceZoneGroupsAction): PriceZoneGroups | null {
    switch (action.type) {
        case 'CHANGE_PRICEZONEGROUPS':
            const parsed: PriceZoneGroups = {}
            for (const element of action.payload) {
                parsed[element.id] = {
                    ...element,
                    PriceZones: element.PriceZones
                }
            }
            return state = parsed
        default:
            return state
    }
}