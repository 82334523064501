import { CSSProperties, useCallback, useState } from 'react'
import Input, { InputProps } from '..'
import { IconDefinition, faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons'
import TwinIcon from '../../../baseComponents/TwinIcon'

export interface InputPasswordProps extends InputProps {
    icon: IconDefinition,
    iconStyles?: CSSProperties
}

const DEFAULT_ICON_STYLES = { height: '19px', width: '19px' }

const InputPassword: React.FC<InputPasswordProps> = ({ className, icon, iconStyles = DEFAULT_ICON_STYLES, ...rest }) => {
    const { togglePasswordIcon, showPassword } = useInputPasswordLogic()
    return (
        < div className={'input_field relative ' + (className || '')} >
            <div className='input_with_icon flex items-center'>
                <TwinIcon {...{ icon }} style={iconStyles} />
                <Input {...rest}  className='flex-auto' type={showPassword ? 'text' : 'password'} />
                <div onClick={togglePasswordIcon} className='cursor-pointer'>
                    <TwinIcon style={iconStyles} className='ml-auto' icon={showPassword ? faEye : faEyeSlash} />
                </div>
            </div>
            < div className='input_border'></div >
        </div>
    )
}

const useInputPasswordLogic = () => {
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const togglePasswordIcon = useCallback(() => {
        setShowPassword((old) => !old)
    }, [setShowPassword])
    return { togglePasswordIcon, showPassword }
}

export default InputPassword