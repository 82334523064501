import { stringMatchWithSearch } from '../../../../../../utils/globals/search'
import { ModuleInformation, ModuleInformationIndividualModule } from '../types'
import { ModuleDashboardFilters } from './types'

export const parseModuleListByFilters = (moduleInformation: ModuleInformation, filters: ModuleDashboardFilters) => {
    const parsedModules: ModuleInformation['modules'] = []
    for (const element of moduleInformation.modules) {
        const categoryFilter = filters.CategoryId === 0 || filters.CategoryId === element.ModuleCategoryId
        const is_deleted = moduleInformation.changes.deleted.includes(element.id)
        const is_own = moduleInformation.own.includes(element.id)
        const is_new = moduleInformation.changes.new.includes(element.id)
        const onlyFilterActive = !filters.onlyActive || (is_own && !is_deleted) || is_new
        const searchBarFilter = stringMatchWithSearch(filters.searchBar, element.name)
        if (categoryFilter && onlyFilterActive && searchBarFilter) {
            let type: ModuleInformationIndividualModule['type'] = undefined
            if (is_deleted) {
                type = 'deleted'
            } else if (is_new) {
                type = 'new'
            } else if (is_own) {
                type = 'own'
            }
            parsedModules.push({...element, type})
        }
    }
    return parsedModules
}