import { CustomerModelType } from '@teinor/erp/types/company/customer'
import { CustomerContactPersonModelType } from '@teinor/erp/types/company/customer/customerContactPerson'
import { ComponentWithPermissions } from '../../../../../../../../../baseComponents/CreateRoutering/types'
import { permissionCheck } from '../../../../../../../../../baseComponents/PermissionChecker/function'
import TwinTable from '../../../../../../../../../baseComponents/TwinTable'
import BaseScreenTableInner from '../../../../../../../../../baseComponents/TwinTable/BaseScreenTableInner'
import { TableComponentBaseScreenInnerProps } from '../../../../../../../../../baseComponents/TwinTable/BaseScreenTableInner/types'
import { EditRow, DeleteRow } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import ModalCreateContactContactPerson from './ModalCreateContactContactPerson'
import ModalEditContactContactPerson from './ModalEditContactContactPerson'

interface ContactContactsPersonsProps extends ComponentWithPermissions {
    basicData: CustomerModelType | null
}

const ContactContactsPersons: React.FC<ContactContactsPersonsProps> = ({ basicData, userPermissions }) => {
    const { t } = useTwinTranslation()

    const pageData = {
        title: t('contactsPerson', 'Personas de contacto'),
        newText: t('addContactPerson', 'Nueva persona de contacto'),
    }

    return (
        <BaseScreenTableInner TableComponent={TableContactContactsPersons} CreateModalComponent={ModalCreateContactContactPerson} EditModalComponent={ModalEditContactContactPerson} pageData={pageData} userPermissions={userPermissions} extraWhereParams={{ CustomerId: basicData?.id }} />
    )
}
interface TableContactContactsPersonsProps extends TableComponentBaseScreenInnerProps { }

const TableContactContactsPersons: React.FC<TableContactContactsPersonsProps> = ({ setOpened, setTableInstance, userPermissions, extraWhereParams }) => {
    const { t } = useTwinTranslation()

    const columns: ColumnTableSchema<CustomerContactPersonModelType> = [
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            }
        },
        {
            id: 'position',
            dataKey: 'position',
            label: t('position', 'Cargo'),
            sortable: true,
        },
        {
            id: 'mobile',
            dataKey: 'mobile',
            label: t('mobile', 'Móvil'),
        },
        {
            id: 'phone',
            dataKey: 'phone',
            label: t('phone', 'Teléfono'),
        },
        {
            id: 'email',
            dataKey: 'email',
            label: t('email', 'Email'),
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            customRender: (parameterValue, allRowData) => <EditRow key={'edit' + parameterValue} {...{ parameterValue, allRowData }} />
        },
        {
            id: 'delete',
            dataKey: 'id',
            label: '',
            width: 50,
            customRender: (parameterValue, allRowData) => <DeleteRow key={'delete' + parameterValue} {...{ parameterValue, allRowData }} onClick={(_idk, allRowData) => setOpened({ type: 'delete', allRowData })} />,
            havePermission: permissionCheck(userPermissions, 'delete')
        }
    ]
    return (
        <TwinTable columns={columns} name='customersContactPersons' getDataFrom='/api/app/customer/customerContactPerson/getAllCustomerContactPersons'
             onRowClick={(_id, rowData) => setOpened({
                type: 'edit',
                allRowData: rowData
            })} setTableInstance={setTableInstance} extraWhereParams={extraWhereParams} />
    )
}

export default ContactContactsPersons