import { TaskWorkLogModelTypeExt } from '../../types';

export const sumAllSecsWorkLogs = (taskWorkLogs: TaskWorkLogModelTypeExt[]) => {
    let result = 0
    if(taskWorkLogs.length){
        for (const workLog of taskWorkLogs) {
            if(!workLog.deleted){
                result += parseInt(String(workLog.workedSecs))
            }
        }
    }
    return result
}

export const addOriginalWorkedSecsTaskWorkLog = (taskWorkLogs: TaskWorkLogModelTypeExt[]) => {
    const taskWorkLogsParsed = []
    if (taskWorkLogs.length) {
        for (const element of taskWorkLogs) {
            taskWorkLogsParsed.push({
                ...element,
                originalWorkedSecs: element.workedSecs || 0
            })
        }
    }
    return taskWorkLogsParsed
}