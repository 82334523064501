import { TaskModelType } from '@teinor/erp/types/company/task'
import { ColumnTableSchema } from '../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import { CustomRenderTaskCField, CustomRenderTaskCustomerAndProject, CustomRenderTaskIcons, CustomRenderTaskName, CustomRenderTaskNameAndDate, CustomRenderTaskRoundImage, CustomRenderTasksFinished, CustomRenderWorkingCenter } from '../../specificComponents/Task/TaskScreenTable/Subcomponents'
import useTwinTranslation from './useTwinTranslation'
import { CustomRenderDateHours } from '../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { faUser, faUserGroup } from '@fortawesome/pro-light-svg-icons'
import TwinIcon from '../../baseComponents/TwinIcon'
import { getEmployees, getGroups, getTaskCFields } from '../reducers/getters'
import { addDictionaryExtraLine } from '../globals/dictionary'
import { useMemo } from 'react'
import useTaskHeaderSelectorsDict from './tasks/useTaskHeaderSelectorsDict'
export interface TaskModelTypeExt extends TaskModelType {
    customField: string
}

export interface TaskModelColumnsExt extends TaskModelTypeExt {
    visibles?: number
    assigned?: number
    subtask?: number
}

interface TaskColumnsProps {
    id: string
}

const useTaskColumns = ({ id }: TaskColumnsProps) => {
    const {t} = useTwinTranslation()
    const extraColumns: ColumnTableSchema<TaskModelTypeExt> = []
    const employees = useMemo(() => addDictionaryExtraLine(JSON.parse(JSON.stringify(getEmployees() || {}))), [])
    const groups = useMemo(() => addDictionaryExtraLine(JSON.parse(JSON.stringify(getGroups() || {}))), [])
    const taskCFields = getTaskCFields()
    const isMain = id === 'main' || id === ''

    const {dictAssignation, dictFinished, dictSubTasks, dictVisibles } = useTaskHeaderSelectorsDict()

    const baseColumns: ColumnTableSchema<TaskModelColumnsExt> = [
        {
            id: 'finished',
            dataKey: 'finished',
            label: '',
            simpleLabel: t('finished', 'Finalizada'),
            customRender: (parameterValue, allRowData) => <CustomRenderTasksFinished value={String(parameterValue) === 'true'} allRowData={allRowData} width={45} />,
            width: 50
        },
        {
            id: 'id',
            dataKey: 'id',
            label: t('nº', 'Nº'),
            customRender: (parameterValue) => <div className='text-green-43'>#{parameterValue}</div>,
            sortable: true,
            width: 75
        },
        {
            id: 'nameAndDate',
            dataKey: 'name',
            label: t('title', 'Título'),
            searchKey: 'Task.name',
            sortKey: 'name',
            simpleLabel: t('titleAndDate', 'Título y fecha'),
            customRender: (_parameterValue, allRowData) => <CustomRenderTaskNameAndDate allRowData={allRowData} />,
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('title', 'Título') }
            },
            minWidth: 120
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('title', 'Título'),
            sortable: true,
            searchKey: 'Task.name',
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('title', 'Título') }
            },
            minWidth: 120,
            hideColumnByDefault: true,
            customRender: (_parameterValue, allRowData) => <CustomRenderTaskName id={String(allRowData?.id) } name={allRowData?.name} />,
        },
        {
            id: 'createdAt',
            dataKey: 'createdAt',
            label: t('createdDate', 'Fecha de creación'),
            sortable: true,
            customRender: (parameterValue) => <CustomRenderDateHours value={parameterValue} />,
            hideColumnByDefault: true
        },
        {
            id: 'subtasks',
            dataKey: 'ChildrenTasks',
            label: '',
            simpleLabel: t('subtasksAndNonVisibles', 'Subtareas y tareas no visibles'),
            customRender: (_parameterValue, allRowData) => <CustomRenderTaskIcons {...{ allRowData }} />,
            width: 50,
        },
        {
            id: 'finished_filter',
            dataKey: 'finished',
            simpleLabel: t('finished', 'Finalizada'),
            label: t('finished', 'Finalizada'),
            justForFilter: true,
            searchableComponent: {
                component: 'SearchableComponentsCustomSelectSingle',
                extraComponentData: { label: t('finished', 'Finalizada'), items: dictFinished }
            }
        },
        {
            id: 'visibles',
            dataKey: 'visibles',
            simpleLabel: t('visibles', 'Visibles'),
            label: t('visibles', 'Visibles'),
            justForFilter: true,
            searchableComponent: {
                component: 'SearchableComponentsCustomSelectSingle',
                extraComponentData: { label: t('visibles', 'Visibles'), items: dictVisibles }
            }
        },
        {
            id: 'assigned',
            dataKey: 'assigned',
            simpleLabel: t('assignment', 'Asignación'),
            label: t('assignment', 'Asignación'),
            justForFilter: true,
            searchableComponent: {
                component: 'SearchableComponentsCustomSelectSingle',
                extraComponentData: { label: t('assignment', 'Asignación'), items: dictAssignation }
            }
        },
        {
            id: 'subtask',
            dataKey: 'subtask',
            simpleLabel: t('subtasks', 'Subtareas'),
            label: t('subtasks', 'Subtareas'),
            justForFilter: true,
            searchableComponent: {
                component: 'SearchableComponentsCustomSelectSingle',
                extraComponentData: { label: t('subtasks', 'Subtareas'), items: dictSubTasks}
            }
        }
    ]
    const columnWC: ColumnTableSchema<TaskModelTypeExt> = [{
        id: 'WorkingCenterId',
        dataKey: 'WorkingCenterId',
        label: isMain ? t('workingCenter', 'Centro de trabajo') : t('workingCenterShort', 'CT'),
        simpleLabel: t('workingCenter', 'Centro de trabajo'),
        customRender: (_parameterValue, allRowData) => <CustomRenderWorkingCenter  {...{ allRowData, id }} />,
        width: isMain ? 175 : 50,
    }]
    const columnCustomer: ColumnTableSchema<TaskModelTypeExt> = [
        {
            id: 'customer',
            dataKey: 'CustomerShopId',
            label: t('customer', 'Cliente'),
            customRender: (_parameterValue, allRowData) => <CustomRenderTaskCustomerAndProject allRowData={allRowData} id={id} />,
            minWidth: isMain ? 175 : 50,
        }
    ]

    const baseColumnsEnd: ColumnTableSchema<TaskModelTypeExt> = [
        {
            id: 'EmployeeId',
            dataKey: 'EmployeeId',
            simpleLabel: t('employee', 'Empleado'),
            label: ()=><TwinIcon icon={faUser} className='w-16 h-16 mx-auto' />,
            customRender: (_parameterValue, allRowData) => <CustomRenderTaskRoundImage id={allRowData?.id} value={allRowData?.EmployeeId} field='EmployeeId' items={employees || {}} icon={faUser} img={allRowData?.Employee?.profile_image} name={allRowData?.Employee?.fullname_short} />,
            width: 50

        },
        {
            id: 'GroupId',
            dataKey: 'GroupId',
            simpleLabel: t('group', 'Grupo'),
            label: () => <TwinIcon icon={faUserGroup} className='w-16 h-16 mx-auto' />,
            customRender: (_parameterValue, allRowData) => <CustomRenderTaskRoundImage id={allRowData?.id} value={allRowData?.GroupId} field='GroupId' items={groups || {}} icon={faUserGroup} img={allRowData?.Group?.photo} name={allRowData?.Group?.name} />,
            width: 50
        },
    ]

    if (taskCFields) {
        for (const customFieldId in taskCFields) {
            const customField = taskCFields[customFieldId]
            extraColumns.push(
                {
                    id: 'customField_' + customField.id,
                    dataKey: 'customField_' + customFieldId as any,
                    simpleLabel: customField.name,
                    label: isMain ? customField.name : customField.name.charAt(0).toUpperCase(),
                    customRender: (_parameterValue, allRowData) => <CustomRenderTaskCField key={allRowData?.id} id={id} allRowData={allRowData} dictTaskCFieldValues={customField.dictTaskCFieldValues} customFieldId={customFieldId} />,
                    searchableComponent: {
                        component: 'SearchableComponentsCustomSelectSearchBar',
                        extraComponentData: { items: customField.dictTaskCFieldValues, label: customField.name }
                    },
                    searchKey: 'customField_' + customField.id,
                    generalSearchable: false,
                    width: isMain ? 175 : 75,
                    sortable: true,
                },
            )
        }
    }
    const columns: ColumnTableSchema<TaskModelColumnsExt> = [
        ...baseColumns,
        ...columnCustomer,
        ...columnWC,
        ...extraColumns,
        ...baseColumnsEnd
    ]
    return { columns, baseColumns, baseColumnsEnd, extraColumns, columnWC, columnCustomer }
}

export default useTaskColumns