import { faColumns3 } from '@fortawesome/pro-regular-svg-icons'
import { Modify } from '@teinor/erp'
import { useCallback, useState } from 'react'
import { twinFetchPostJSON } from '../../../utils/globals/data'
import { getOnlyDefaultColumns, getTableSelectedColumns, SelectedColumnsAndFilters } from '../../../utils/hooks/useConfigColumns'
import useTwinTranslation from '../../../utils/hooks/useTwinTranslation'
import { changeEmployeeParams } from '../../../utils/reducers/reduxDispatch'
import { ButtonSecondary } from '../../Button'
import { ModalOpenedTy } from '../../Modal/types'
import TwinIcon from '../../TwinIcon'
import TwinTrans from '../../TwinTrans'
import { ModalComponentPropsWithoutSubmit } from '../types'
import { ConfigColumnsColumn, ModalConfigColumnsBaseProps } from './types'
import ModalTabs from '../ModalTabs'
import { ComponentTabObject } from '../../../utils/hooks/useTabLogic'
import ModalConfigColumns from './ModalConfigColumns'
import ModalConfigColumnsFilters from './ModalConfigColumnsFilters'
import { TwinDictionary } from '../../../utils/globals/dictionary'
import { ButtonsLoadFiltersStateFull } from '../../Button/ButtonLoadFilter'
import ModalConfigColumnsOrder from './ModalConfigColumnsOrder'
import './modalConfigColumnsTabs.sass'

interface ModalConfigColumnsTabsProps extends ModalConfigColumnsTabsLogicProps {
    haveFiltersColumn?: boolean
}

const ModalConfigColumnsTabs: React.FC<ModalConfigColumnsTabsProps> = ({setOpened, haveFiltersColumn = true, ...rest }) => {
    const { t } = useTwinTranslation()
    const logic = useModalConfigColumnsTabsLogic({setOpened, ...rest})
    const tabs: ComponentTabObject = {
        'main': { component: ModalConfigColumns, text: t('configColumns', 'Configurar columnas') },
    }
    if (haveFiltersColumn) {
        tabs['filters'] = { component: ModalConfigColumnsFilters, text: t('configFilters', 'Configurar filtros') }
        tabs['order'] = { component: ModalConfigColumnsOrder, text: t('configOrder', 'Configurar orden') }
    }
    return (
        <ModalTabs tabs={tabs} setOpened={setOpened} onSubmit={() => setOpened(null)} extraComponentData={logic} className='modal_config_columns_tabs'></ModalTabs>
    )
}

interface ModalConfigColumnsTabsLogicProps extends ModalComponentPropsWithoutSubmit {
    setSelectedColumnsAndFilters: React.Dispatch<React.SetStateAction<SelectedColumnsAndFilters>>
    selectedColumnsAndFilters: SelectedColumnsAndFilters
    columns: ConfigColumnsColumn<any>[]
    name: string
}

const useModalConfigColumnsTabsLogic = ({ selectedColumnsAndFilters, columns, setSelectedColumnsAndFilters, setOpened, name }: ModalConfigColumnsTabsLogicProps): ModalConfigColumnsBaseProps => {
    const [tmpColumns, setTmpColumns] = useState(selectedColumnsAndFilters)
    
    const updateFilterOnTable = useCallback(async (columns: SelectedColumnsAndFilters, id: number) => {
        delete columns.id
        delete columns.name
        const myValue = JSON.stringify(columns)
        await twinFetchPostJSON('/api/app/config/filter/updateFilter', { id, value: myValue })
    }, [])

    const updateEmployeeParams = useCallback(async (columns: SelectedColumnsAndFilters) => {
        delete columns.id
        delete columns.name
        const myName = 'table_' + name
        const myValue = JSON.stringify(columns)
        await twinFetchPostJSON('/api/app/config/employee/updateEmployeeParameter', { parameterKey: myName, value: myValue })
        changeEmployeeParams({ [myName]: myValue })
    }, [name])

    const updateParamsDecider = useCallback(async (columns: SelectedColumnsAndFilters) => {
        if (tmpColumns.id && String(tmpColumns.id) !== '0') {
            await updateFilterOnTable(columns, tmpColumns.id)
        } else {
            await updateEmployeeParams(columns)
        }
    }, [tmpColumns.id, updateFilterOnTable, updateEmployeeParams])

    const onSave = useCallback(() => {
        if (!Object.keys(tmpColumns.selectedColumns).length) {
            const defaultCols = getOnlyDefaultColumns(columns)
            const myColumns = { ...defaultCols, filters: tmpColumns.filters, id: tmpColumns.id, name: tmpColumns.name }
            setSelectedColumnsAndFilters(myColumns)
            updateParamsDecider(myColumns)
        } else {
            setSelectedColumnsAndFilters({ ...tmpColumns })
            updateParamsDecider({ ...tmpColumns })
        }
        setOpened(null)
        return false
    }, [tmpColumns, setSelectedColumnsAndFilters, setOpened, columns, updateParamsDecider])

    return {tmpColumns, setTmpColumns, onSave, columns, selectedColumnsAndFilters, name}
}


type ButtonConfigColumnsProps = Modify<ModalConfigColumnsTabsProps, {
    setOpened?: never
    hideLoadFiltersButton?: boolean
}>

export const ButtonConfigColumnsWithModal: React.FC<ButtonConfigColumnsProps> = ({ selectedColumnsAndFilters, setSelectedColumnsAndFilters, hideLoadFiltersButton, ...rest }) => {
    const [opened, setOpened] = useState<ModalOpenedTy>(null)
    const {t} = useTwinTranslation()
    const filtersLength = Object.keys(selectedColumnsAndFilters?.filters || {}).length
    const isCustomFilter = selectedColumnsAndFilters.id && String(selectedColumnsAndFilters.id) !== '0'
    const name = isCustomFilter && selectedColumnsAndFilters.name ? selectedColumnsAndFilters.name : t('byDefault', 'Por defecto')
    const defaultValues = getTableSelectedColumns(rest.name, rest.columns)
    const { onChangeFilter } = useButtonConfigColumnsWithModalLogic({ setSelectedColumnsAndFilters })
    return (
        <div className='button_config_columns_modal flex items-center'>
            {filtersLength > 0 || isCustomFilter ? <span className='medium16 mr-15'><TwinTrans transKey='configuration'>Configuración</TwinTrans>: {name} ({filtersLength})</span> : null}
            {hideLoadFiltersButton ? null : <ButtonsLoadFiltersStateFull allowCreate={false} selectedFilters={selectedColumnsAndFilters} onChangeFilter={(json) => onChangeFilter(json)} tableName={rest.name} defaultValues={defaultValues} className='load_button_filter'/>}
            <ButtonSecondary onClick={() => setOpened(true)} className='h-34'>
                <TwinIcon icon={faColumns3} />
                <span className='ml-10 load_columns_button_text'><TwinTrans transKey='configColumns'>Configurar columnas</TwinTrans></span>
            </ButtonSecondary>
            {opened && <ModalConfigColumnsTabs {...rest} setOpened={setOpened} selectedColumnsAndFilters={selectedColumnsAndFilters} setSelectedColumnsAndFilters={setSelectedColumnsAndFilters} />}
        </div>
    )
}

interface ButtonConfigColumnsWithModalLogicProps {
    setSelectedColumnsAndFilters: React.Dispatch<React.SetStateAction<SelectedColumnsAndFilters>>
}

const useButtonConfigColumnsWithModalLogic = ({setSelectedColumnsAndFilters}: ButtonConfigColumnsWithModalLogicProps) => {
    const onChangeFilter = useCallback((json: TwinDictionary) => {
        setSelectedColumnsAndFilters({ filters: json?.value?.filters || {}, selectedColumns: json?.value?.selectedColumns || {}, id: json.id, name: json.name, order: json?.value?.order || {}})
    }, [setSelectedColumnsAndFilters])

    return { onChangeFilter }
}

export default ModalConfigColumnsTabs