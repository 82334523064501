import { includesByArrayType } from './array'

export const addDictionaryExtraLine = (items: TwinDictionary) => {
    items[''] = {
        name: '---',
        fullname_short: '---',
        id: ''
    }
    return items
}

export type TwinDictionary = { [key: string | number]: any }
export const dictionaryFromJsonArr = (items: TwinDictionary[] | TwinDictionary, keyValue: string = 'id', keyText: string = 'name') => {
    let parsedJSON: TwinDictionary = {}
    for (const key in items) {
        const item = items[key]
        parsedJSON[item[keyValue]] = item[keyText]
    }
    return parsedJSON
}
export const dictionaryTwoLevelFromJsonArr = (items: TwinDictionary[], fields: string[], keyValue: string = 'id') => {
    let parsedJSON: TwinDictionary = {}
    for (const field of fields) {
        for (const key in items) {
            const item = items[key]
            if (parsedJSON[field]) {
                parsedJSON[field] = { ...parsedJSON[field], [item[keyValue]] : item[field] }
            } else {
                parsedJSON[field] = { [item[keyValue]] : item[field] }
            }
        }
    }
    return parsedJSON
}
export const dictionaryComplexFromJsonArr = <K extends TwinDictionary>(items: TwinDictionary | TwinDictionary[], keyValue: keyof K = 'id', parseKeyFunc?: (param: string) => string, skipUniques?: boolean, parseParams?: (item: K) => K): TwinDictionary => {
    let parsedJSON: { [key: string]: K } = {}
    for (const key in items) {
        const item = items[key]
        const keyInitial = item[keyValue]
        const keyOfDict = parseKeyFunc ? parseKeyFunc(keyInitial) : keyInitial
        if (skipUniques && parsedJSON[keyOfDict]) {
            continue
        }
        if(!parseParams){
            parsedJSON[keyOfDict] = {...item}
        }else{
            parsedJSON[keyOfDict] = parseParams({...item})
        }
    }
    return parsedJSON
}

export const dictionaryFromArr = (items: any[]) => {
    let parsedJSON: TwinDictionary = {}
    for (const element of items) {
        parsedJSON[element] = element
    }
    return parsedJSON
}

export const dictionaryFromJson = (items: { [key: string]: { [key: string]: any } }, keyText: string = 'name') => {
    let parsedJSON: TwinDictionary = {}
    for (const key in items) {
        const item = items[key]
        parsedJSON[key] = item[keyText]
    }
    return parsedJSON
}

export const dictionaryComplexFromArr = (items: any[]) => {
    let parsedJSON: TwinDictionary = {}
    for (const element of items) {
        parsedJSON[element] = { name: element }
    }
    return parsedJSON
}

export const dictionaryComplexFromSimpleDict = (dict: { [key: string]: string }) => {
    let result: TwinDictionary = {}
    for (const key in dict) {
        const name = dict[key]
        result[key] = { name }
    }
    return result
}

export const elementsOfDictionaryByValues = (items: TwinDictionary, values: string[]) => {
    const result: TwinDictionary = {}
    if(values && values.length){
        for (const key in items) {
            if (values.includes(key)) {
                result[key] = items[key]
            }
        }
    }
    return result
}
export const filterDictionaryNotInItems = (items: TwinDictionary, notInItems: string[]) => {
    const result :TwinDictionary = {}
    for (const key of Object.keys(items)) {
        if (!notInItems.includes(String(key))) {
            result[key] = items[key]
       }
    }
    return result
}

export const filterDictionaryInItems = (items: TwinDictionary, inItems: string[] | number[]) => {
    const result :TwinDictionary = {}
    for (const key of Object.keys(items)) {
        if (includesByArrayType(inItems, key)) {
            result[key] = items[key]
        }
    }
    return result
}

export const parseDictionaryNameToText = (item: TwinDictionary, newParam = 'text', oldParam = 'name') => {
    const newItem = JSON.parse(JSON.stringify(item))
    newItem[newParam] = newItem[oldParam]
    return {...newItem}
}

export const indexKeyDictionaryFromJsonArray = (items: TwinDictionary[], indexParam = 'id') => {
    const dictionary: TwinDictionary = {}
    for (const key in items) {
        const item = items[key]
        dictionary[item[indexParam]] = key
    }
    return dictionary
}

export const dictionaryTwoLevelNameCodeFromJsonArr = (items: TwinDictionary[], field: string, keyValue: string = 'id', keyCode: string = 'code', keyName: string = 'name') => {
    let result: TwinDictionary = {}
    for (const key in items) {
        const item = items[key]
        const parsed: TwinDictionary =  {}
        for (const index in item[field]) {
            const option = item[field][index]
            parsed[option[keyValue]] = option[keyCode] + ' - ' + option[keyName]
        }
        result[item.id] ={...item, [field]: parsed}
    }
    return result
}
export const dictionaryFromJsonArrCodeName = (items: TwinDictionary[] | TwinDictionary, keyValue: string = 'id', keyCode:string ='code', keyName: string = 'name') => {
    let parsedJSON: TwinDictionary = {}
    for (const key in items) {
        const item = items[key]
        parsedJSON[item[keyValue]] = item[keyCode] + ' - ' + item[keyName]
    }
    return parsedJSON
}

export const dictionaryComplexFromJsonArrCodeName = (items: TwinDictionary[] | TwinDictionary, keyValue: string = 'id', keyCode: string = 'code', keyName: string = 'name', newField: string = 'name') => {
    let parsedJSON: TwinDictionary = {}
    for (const key in items) {
        const item = items[key]
        parsedJSON[item[keyValue]] = { ...item, [newField]: item[keyCode] + ' - ' + item[keyName] }
    }
    return parsedJSON
}

export const dictionaryComplexFromJsonArrCodeNameClearCode = (items: TwinDictionary[] | TwinDictionary, keyValue: string = 'id', keyCode: string = 'code', keyName: string = 'name', newField: string = 'name') => {
    let parsedJSON: TwinDictionary = {}
    for (const key in items) {
        const item = items[key]
        const prevText = item[keyCode] ? (item[keyCode] + ' - ') : ''
        parsedJSON[item[keyValue]] = { ...item, [newField]: prevText + item[keyName] }
    }
    return parsedJSON
}

export const getDifferencesTwoDicts = (firstItem: TwinDictionary, secondItemReturn: TwinDictionary, diffChecker: string[]) => {
    let parsedJSON: TwinDictionary = {}
    for (const keyDiff of diffChecker) {
        const item1In = firstItem[keyDiff]
        const item2In = secondItemReturn[keyDiff]
        if (item1In !== item2In) {
            parsedJSON[keyDiff] = item2In
        }
    }
    return parsedJSON
}

export const createDictionaryByTwoDicts = (keyDictionary: TwinDictionary, textDictionary: TwinDictionary) => {
    let parsedJSON: TwinDictionary = {}
    for (const key in keyDictionary) {
        if (key in textDictionary) {
            parsedJSON[key] = textDictionary[key]
        }
    }
    return parsedJSON
}