import { BrandModelType } from '@teinor/erp/types/company/product/brand'
import { dictionaryFromJsonArr } from '../../globals/dictionary'
export interface BrandsAction {
    type: 'CHANGE_BRANDS'
    payload: BrandsPayload
}
export type BrandsPayload = BrandModelType[]
export type BrandJson = { [isoCode: string]: BrandModelType } | null
export default function brands(state: BrandJson = null, action: BrandsAction): BrandJson {
    switch (action.type) {
        case 'CHANGE_BRANDS':
            return state = dictionaryFromJsonArr(action.payload)
        default:
            return state
    }
}