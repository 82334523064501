import { TwinDictionary } from './dictionary'

export const addOrRemoveClassList = (currentTarget: EventTarget & HTMLElement, className: string, add: boolean) => {
    if (add) {
        addClassName(className, currentTarget)
    } else {
        removeClassName(className, currentTarget,)
    }
}

export const addClassName = (className: string, currentTarget?: EventTarget & HTMLElement | null) => {
    currentTarget?.classList.add(className)
}

export const removeClassName = (className: string, currentTarget?: EventTarget & HTMLElement | null) => {
    currentTarget?.classList.remove(className)
}

export const calculateTopOfElement = (row_height: number, gap_btw_rows: number, active?: number | false) => {
    return active ? active * (row_height + gap_btw_rows ) : 0 
}
type CleanerFunctions = { [key: string]: (object: any) => any }
type SkipFields = { [key: string]: string[]}

const cleanField = (element: TwinDictionary, skipFields?: string[], cleanerFunctions?: CleanerFunctions) => {
    if (!skipFields) {
        return element
    }
    let result: TwinDictionary = {}
    for (const key in element) {
        const cleanFunction = cleanerFunctions?.[String(key)]
        if (!skipFields.includes(String(key))) {
            result[key] = element[key]
        } else if(cleanFunction) {
            result[key] = cleanFunction(element[key])
        }
    }
    return result
}

const cleanFieldArray = (elements: TwinDictionary[], skipFields?: string[], cleanerFunctions?: CleanerFunctions) => {
    if (!skipFields) {
        return elements
    }
    let result: TwinDictionary[] = []
    for (const element of elements) {
        result.push(cleanField(element, skipFields, cleanerFunctions))
    }
    return result
}
export const memoChecker = <K>(oldProps: K, nextProps: K, checkParams: (keyof K)[], skipFields?: SkipFields, cleanerFunctions?: CleanerFunctions) => {
    for (const param of checkParams) {
        let old: any = oldProps[param]
        let next: any = nextProps[param]
        if (param !== 'children') {
            const haveSkipFields = skipFields?.[param as string]
            if (haveSkipFields) {
                if (Array.isArray(old)) {
                    old = cleanFieldArray(old, haveSkipFields, cleanerFunctions)
                } else if (typeof old === 'object') {
                    old = cleanField(old, haveSkipFields, cleanerFunctions)
                }
                if (Array.isArray(next)) {
                    next = cleanFieldArray(next, haveSkipFields, cleanerFunctions)
                } else if (typeof next === 'object') {
                    next = cleanField(next, haveSkipFields, cleanerFunctions)
                }
            }
            if (typeof old === 'object' || Array.isArray(old)) {
                old = JSON.stringify(old) 
            }
            if (typeof next === 'object' || Array.isArray(next)) {
                next = JSON.stringify(next)
            }
        }
        if (old !== next) {
            return false
        }
    }
    return true
}

export const getMinPxFromElement = (element: HTMLElement) => {
    const minW = element.style.minWidth
    const px = parseFloat(minW.substring(0, minW.length - 2))
    return px || 0
}