import { TwinEmployeeParametersDict } from '@teinor/erp/types/company/userInner/employeeParameter'
import { useCallback, useEffect, useState } from 'react'
import { ComponentWithPermissions } from '../../../../../../../baseComponents/CreateRoutering/types'
import SingleFormLayout from '../../../../../../../baseComponents/Layout/SingleFormLayout'
import { OnSubmit } from '../../../../../../../forms/TwinForm/types'
import { FormRender } from '../../../../../../../forms/FormRenderer/types'
import { valueOrDefaultValue } from '../../../../../../../utils/globals/valueOrDefaultValue'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import { changeEmployeeParams } from '../../../../../../../utils/reducers/reduxDispatch'
import { TwinDictionary } from '../../../../../../../utils/globals/dictionary'
import { twinFetchPostJSON } from '../../../../../../../utils/globals/data'
import LoadingSpinner from '../../../../../../../baseComponents/LoaderDecider/LoadingSpinner'

type EmployeeParametersProps = EmployeeParametersLogicParamsProps & ComponentWithPermissions & {
}

const EmployeeParameters: React.FC<EmployeeParametersProps> = ({ getData, userPermissions }) => {
    const { onSubmit, employeeParameters } = useEmployeeParameters({ getData })
    const { t } = useTwinTranslation()
    const onOff = {
        'on': { name: t('on', 'Activado') },
        'off': { name: t('disabled', 'Desactivado') },
    }
    if(!employeeParameters){
        return <LoadingSpinner />
    }
    const fields: FormRender<TwinEmployeeParametersDict> = [
        {
            cols: 3,
            title: t('parameters', 'Parámetros'),
            elements: [
                {
                    name: 'dark_mode',
                    label: t('darkMode', 'Modo oscuro'),
                    component: 'CustomSelect',
                    items: onOff
                },
                {
                    name: 'mailHolidayNotification',
                    label: t('mailHolidayNotification', 'Notificaciones de solicitud de vacaciones'),
                    component: 'CustomSelect',
                    items: onOff
                },
                {
                    name: 'floatingTasks',
                    label: t('floatingTasks', 'Tareas en ventanas flotantes'),
                    component: 'CustomSelect',
                    items: onOff
                },
                {
                    name: 'autoSubscribeWhenTaskAssigned',
                    label: t('autoSubscribeWhenTaskAssigned', 'Suscribirse cuando te asignan una tarea'),
                    component: 'CustomSelect',
                    items: onOff
                },
                {
                    name: 'autoUnsubscribeWhenTaskUnassigned',
                    label: t('autoUnsubscribeWhenTaskUnassigned', 'Darse de baja cuando te desasignan una tarea'),
                    component: 'CustomSelect',
                    items: onOff
                },
                {
                    name: 'receiveNotificationFromTaskGroups',
                    label: t('receiveNotificationFromTaskGroups', 'Recibir notificaciones de las tareas de mi grupo'),
                    component: 'CustomSelect',
                    items: onOff
                },
            ]
        },
    ]
    const parsedFields = valueOrDefaultValue(fields, userPermissions, employeeParameters)
    return (
        <SingleFormLayout action='/api/app/config/employee/updateEmployeeParameters' onSubmit={onSubmit} sections={parsedFields} userPermissions={userPermissions} />
    )
}

interface EmployeeParametersLogicParamsProps {
    getData: () => Promise<void>
}

const useEmployeeParameters = ({ getData }: EmployeeParametersLogicParamsProps) => {
    const [employeeParameters, setEmployeeParameters] = useState<TwinDictionary | null>(null)

    const getEmployeeParameters = useCallback(async() => {
        const myData = await twinFetchPostJSON('/api/user/getUserInstance', {})
        if(myData){
            setEmployeeParameters(myData?.Employee?.EmployeeParameters)        
        }
    }, [setEmployeeParameters])


    const onSubmit: OnSubmit = useCallback((_res, values) => {
        getData()
        changeEmployeeParams(values)
    }, [getData])

    useEffect(() => {
        getEmployeeParameters()
    }, [getEmployeeParameters])

    return { onSubmit, employeeParameters }
}


export default EmployeeParameters