export interface AddBottomNavAppAction {
    type: 'ADD_BOTTOMNAVAPP'
    payload: BottomNavAppJson
}
export interface ChangeBottomNavAppAction {
    type: 'CHANGE_BOTTOMNAVAPP'
    payload: BottomNavAppJson
}
export interface DeleteBottomNavAppAction {
    type: 'DELETE_BOTTOMNAVAPP'
    payload: string
}
export interface ClearBottomNavAppAction {
    type: 'CLEAR_BOTTOMNAVAPP'
}
export interface GetBottomNavAppAction {
    type: 'GET_BOTTOMNAVAPP'
}

export type BottomNavAppPayload = { [key: string]: BottomNavAppJson }
export type BottomNavAppTy = 'workingModal' | 'taskModal'
export type BottomNavAppJson = { type: BottomNavAppTy, key: string, extraData: any }

export type BottomNavAppActions = AddBottomNavAppAction | ChangeBottomNavAppAction | DeleteBottomNavAppAction | ClearBottomNavAppAction | GetBottomNavAppAction

export default function bottomNavApps(state: BottomNavAppPayload = {}, action: BottomNavAppActions): BottomNavAppPayload {
    switch (action.type) {
        case 'ADD_BOTTOMNAVAPP':
            const alreadyExists = state[action.payload.key]
            if(alreadyExists){
                const copy = JSON.parse(JSON.stringify(state))
                delete copy[action.payload.key]
                return { ...copy, [action.payload.key]: action.payload }
            }
            return {...state, [action.payload.key]: action.payload }
        case 'CHANGE_BOTTOMNAVAPP':
            const copyState = JSON.parse(JSON.stringify(state))
            copyState[action.payload.key] = {
                ...copyState[action.payload.key], ...action.payload }
            return { ...copyState }
        case 'GET_BOTTOMNAVAPP':
            return state
        case 'DELETE_BOTTOMNAVAPP':
            const copy = JSON.parse(JSON.stringify(state))
            delete copy[action.payload]
            return { ...copy }
        case 'CLEAR_BOTTOMNAVAPP':
            state = {}
            return { ...state }
        default:
            return state
    }
}