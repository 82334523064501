import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { ButtonDisabledOrLoader } from '../../../../../baseComponents/Button'
import { SwitchButton, TabItems } from '../../../../../baseComponents/Button/SwitchButton'
import TwinTrans from '../../../../../baseComponents/TwinTrans'
import Checkbox from '../../../../../forms/Checkbox'
import TwinForm from '../../../../../forms/TwinForm'
import { OnSubmit, BeforeSubmitHandler } from '../../../../../forms/TwinForm/types'
import useTwinTranslation from '../../../../../utils/hooks/useTwinTranslation'

interface LoginHeaderProps {
    active: number,
    onChangeTabs: (position: number) => void
}

export const LoginHeader: React.FC<LoginHeaderProps> = ({ active, onChangeTabs }) => {
    const items: TabItems[] = [
        {
            element: <TwinTrans transKey={'erp'}>ERP</TwinTrans>
        },
        {
            element: <TwinTrans transKey={'admin'}>Admin</TwinTrans>
        }
    ]
    return (
        <Fragment>
            <HeaderAuth>
                <SwitchButton active={active} onClick={onChangeTabs} items={items} />
            </HeaderAuth>
            <TitleAuth>
                <TwinTrans transKey='loginPageSubtitleNew'>
                    Bienvenido a la mejor forma de gestionar tu empresa
                </TwinTrans>
            </TitleAuth>
        </Fragment>
    )
}

interface LoginSubHeaderProps { }

export const LoginSubHeader: React.FC<LoginSubHeaderProps> = () => {
    return (
         <div className='mt-40 text-center'>
            <h1 className='mb-22'>
                <TwinTrans transKey='login'>
                    Login
                </TwinTrans>
            </h1>
            <span className='regular14'>
                <TwinTrans transKey='enterDataToLogin'>
                    Introduce tus datos para entrar en el panel
                </TwinTrans>
            </span>
        </div>
    )
}

interface FooterOfLoginFormProps { }

export const FooterOfLoginForm: React.FC<FooterOfLoginFormProps> = () => {
    const { t } = useTwinTranslation()
    return (
        <div className='flex justify-between items-center mt-22 forgot_password_login_footer_form'>
            <Checkbox name='remember' label={t('rememberMe', 'Recordarme')} className='regular14 color_checkbox_grey' />
            <MyLink link={'/forgot-password'} text={t('forgotPassword', '¿Has olvidado tu contraseña?')} />
        </div>
    )
}

interface FooterLinkProps {
    textLink: string
    link: string
    children?: React.ReactNode
}

export const FooterLink: React.FC<FooterLinkProps> = ({ textLink, link, children }) => {
    return (
        <div className='auth_footer'>
            <MyLink text={textLink} link={link} className='text-16' />
            {children}
        </div>
    )
}

interface TitleAuthProps {
    children?: React.ReactNode
}

export const TitleAuth: React.FC<TitleAuthProps> = ({ children }) => {
    return (
        <div className='auth_title'>
            {children}
        </div>
    )
}

interface HeaderAuthProps {
    children?: React.ReactNode
}

export const HeaderAuth: React.FC<HeaderAuthProps> = ({ children }) => {
    return (
        <div className='flex justify-between items-center'>
            <img src='/teinor_logo.png' alt='teinor_logo' className='auth_logo' />
            {children}
        </div>
    )
}

interface MyLinkProps {
    text: string
    link: string
    className?: string
}

export const MyLink: React.FC<MyLinkProps> = ({ link, text, className }) => {
    return (
        <Link to={link} className={'text-green-21 ' + (className || '')}>{text}</Link>
    )
}

interface FormAuthenticationProps {
    action: string
    buttonText: string
    onSubmit?: OnSubmit
    beforeSubmitHandler?: BeforeSubmitHandler
    children?: React.ReactNode
    loading: boolean
}
export const FormAuthentication: React.FC<FormAuthenticationProps> = ({ buttonText, children, loading, ...rest }) => {
    return (
        <TwinForm method='POST'  {...rest} triggerErrors={false}>
            {children}
            <ButtonDisabledOrLoader textButton={buttonText} loading={loading}  className='auth_button' />
        </TwinForm>
    )
}