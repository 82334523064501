import { useCallback } from 'react'
import TwinForm, { FormProps } from '../../../forms/TwinForm'
import { BeforeSubmitHandler, OnSubmit } from '../../../forms/TwinForm/types'
import FormRenderer from '../../../forms/FormRenderer'
import { FormRender } from '../../../forms/FormRenderer/types'
import useIsLoading from '../../../utils/hooks/useIsLoading'
import useTwinTranslation from '../../../utils/hooks/useTwinTranslation'
import { ButtonOrLoader } from '../../Button'
import { ComponentWithPermissions } from '../../CreateRoutering/types'
import PermissionChecker from '../../PermissionChecker'

type SingleFormLayoutProps = ComponentWithPermissions & FormProps & SingleFormLayoutLogicProps & {
    sections: FormRender<any>
    extraButtons?: JSX.Element[]
}

const SingleFormLayout: React.FC<SingleFormLayoutProps> = ({onSubmit, beforeSubmitHandler, sections, userPermissions,  extraButtons, ...rest}) => {
    const { myBeforeHandler, myOnSubmit, endLoading, loading } = useSingleFormLayoutLogic({ onSubmit, beforeSubmitHandler })
    const {t} = useTwinTranslation()
    return (
        <TwinForm onSubmit={myOnSubmit} beforeSubmitHandler={myBeforeHandler} onError={endLoading} {...rest} className='flex-auto flex flex-col'>
            <FormRenderer sections={sections} antiUnsaveTrigger={true} className='flex-auto auto-rows-min' />
            <PermissionChecker userPermissions={userPermissions} permission='update'>
                <div className='flex justify-end items-center mt-40'>
                    <ButtonOrLoader textButton={t('save', 'Guardar')} buttonIsDisabled={loading} />
                    {extraButtons}
                </div>
            </PermissionChecker>
        </TwinForm>
    )
}

interface SingleFormLayoutLogicProps {
    onSubmit: OnSubmit
    beforeSubmitHandler?: BeforeSubmitHandler 
}

const useSingleFormLayoutLogic = ({onSubmit, beforeSubmitHandler}: SingleFormLayoutLogicProps) => {
    const { startLoading, endLoading, loading } = useIsLoading()

    const myOnSubmit: OnSubmit = useCallback((response, vals) => {
        endLoading()
        onSubmit(response, vals)
    }, [endLoading, onSubmit])

    const myBeforeHandler: BeforeSubmitHandler = useCallback((vals, form) => {
        if (beforeSubmitHandler) {
            startLoading()
            if(!beforeSubmitHandler(vals, form)){
                endLoading()
                return false
            } 
            return true
        }
        return startLoading()
    }, [startLoading, endLoading, beforeSubmitHandler])

    return {startLoading, endLoading, loading, myOnSubmit, myBeforeHandler}
}


export default SingleFormLayout