import { faPaperclip } from '@fortawesome/pro-light-svg-icons'
import { HeaderElementTask } from '..'
import { useDropzone } from 'react-dropzone'
import TwinIcon from '../../../../../baseComponents/TwinIcon'
import { SetTaskDataExtended } from '../../types'
import { useCallback } from 'react'
import { twinFetchPostJSON, uploadFileToBack } from '../../../../../utils/globals/data'

interface HeaderTaskUploadFileProps extends HeaderTaskUploadFileLogicProps {
    message: string
}

const HeaderTaskUploadFile: React.FC<HeaderTaskUploadFileProps> = ({ id, message, setData}) => {
    const { getRootProps, getInputProps} = useHeaderTaskUploadFileLogic({id, setData})
    return (
        <HeaderElementTask id={'uploadFile_' + id} message={message}>
            <div {...getRootProps({ className: 'dropzone' })} className='cursor-pointer'>
                <TwinIcon data-tooltip-id='uploadFile' icon={faPaperclip} className='h-20' />
                <input name='file_url' title='img' {...getInputProps()} />
            </div>
        </HeaderElementTask>
    )
}
interface HeaderTaskUploadFileLogicProps {
    id: string
    setData: SetTaskDataExtended
}

const useHeaderTaskUploadFileLogic = ({ id, setData }: HeaderTaskUploadFileLogicProps) => {
    const createCommentWithAttached = useCallback(async (myAcceptedFiles: any[]) => {
        const pushedFiles: any[] = []
        for (const file of myAcceptedFiles) {
            const name = file.name
            const file_url = await uploadFileToBack(file, name, ['tasks', id || ''])
            const result = await twinFetchPostJSON('/api/app/task/comment/createTaskComment', { TaskId: id, file_url }, { myExtraHeaders: { 'type-update': 'singleTask' } })
            if (result) {
                pushedFiles.push(result)
            }
        }
        setData((old) => {
            if (!old) {
                return null
            }
            const copyOld = JSON.parse(JSON.stringify(old))
            copyOld.TaskComments = [...copyOld.TaskComments, ...pushedFiles]
            return { ...copyOld }
        })
    }, [id, setData])

    const { getRootProps, getInputProps } = useDropzone({
        useFsAccessApi: false,
        accept: {
            'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
            'application/zip': ['.zip'],
            'application/pdf': ['.pdf']
        },
        maxSize: 2000000,
        onDrop: acceptedFiles => {
            const myAcceptedFiles = acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }))
            createCommentWithAttached(myAcceptedFiles)
        }
    })
    return { getRootProps, getInputProps }
}


export default HeaderTaskUploadFile