import { ButtonsModalSmallCentered, ModalSmallCenteredProps, ModalSmallCentered } from '../../Modal'
import ReactDOM from 'react-dom'

interface ModalSmallFilledProps extends ModalSmallCenteredProps {
    translations: {
        title: string
        subtitle: string
        buttonAccept: string
    }
    onAccept: () => void
}

const ModalSmallFilled: React.FC<ModalSmallFilledProps> = ({ translations, onAccept, ...rest }) => {
    return (
        <ModalSmallCentered {...rest}>
            <h2>{translations.title}</h2>
            <span>{translations.subtitle}</span>
            <ButtonsModalSmallCentered setOpened={rest.setOpened} onCancel={() => rest.setOpened(null)} confirmText={translations.buttonAccept} onAccept={onAccept}/>
        </ModalSmallCentered>
    )
}

interface ModalSmallFilledOutsideProps extends ModalSmallFilledProps {}

export const ModalSmallFilledOutside: React.FC<ModalSmallFilledOutsideProps> = ({ ...rest}) => {
    return (
        ReactDOM.createPortal(<ModalSmallFilled  {...rest}  />, document.body)
    )
}


export default ModalSmallFilled