import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { useState } from 'react'
import { ButtonPrimary } from '../../Button'
import { ComponentWithPermissions } from '../../CreateRoutering/types'
import PermissionChecker from '../../PermissionChecker'
import { TableInstanceType } from '../types'
import TwinIcon from '../../TwinIcon'
import { BaseScreenLogicProps, BaseScreenPageData, BaseScreenPageDataBoth, TableComponentProps } from './types'
import { BigTitleAndRightLayout } from '../../Layout/TitleAndRightLayout'
import useUpdateTableInstance from '../../../utils/hooks/useUpdateTableInstance'
import useEditDeleteModal from '../../../utils/hooks/useEditDeleteModal'
import { Modify } from '@teinor/erp'
import { ConnectedComponent } from 'react-redux'
import { WhiteBox } from '../../AppLayout/WhiteBox'
import { ModalEditComponentProps, ModalComponentProps } from '../../ModalsLayouts/types'

interface BaseScreenTableSimpleProps extends ComponentWithPermissions{
    pageData: BaseScreenPageData
    RightHeader?: JSX.Element
    TableComponent: React.FC<TableComponentProps> | ConnectedComponent<React.FC<any>, any>
    CreateModalComponent?: React.FC<ModalComponentProps & ComponentWithPermissions> | ConnectedComponent<ModalComponentProps & any, any>
    EditModalComponent?: React.FC<ModalEditComponentProps>
    DeleteModalComponent?: React.FC<ModalEditComponentProps>
    logicProps: BaseScreenLogicProps
    updateTableInstance: () => void
    children?: React.ReactNode
}

export const BaseScreenTableSimpleStateless: React.FC<BaseScreenTableSimpleProps> = ({ logicProps, updateTableInstance, pageData, TableComponent, RightHeader, userPermissions, EditModalComponent, DeleteModalComponent, CreateModalComponent, children }) => {
    const { openModal, setOpenModal, tableInstance, setTableInstance } = logicProps
    return (
        <BigTitleAndRightLayout title={pageData.title} RightHeader={RightHeader} >
            <WhiteBox className='flex flex-col flex-auto px-40 py-50'>
                <TableComponent 
                    setTableInstance={setTableInstance} 
                    userPermissions={userPermissions} 
                    setOpened={setOpenModal} 
                    tableInstance={tableInstance} 
                />
                {children}
                {openModal?.type === 'create' && CreateModalComponent && <CreateModalComponent {...{ setOpened: () => setOpenModal(null) }} onSubmit={updateTableInstance} userPermissions={userPermissions} />}
                {(openModal?.type === 'edit' && EditModalComponent) && <EditModalComponent {...{ setOpened: () => setOpenModal(null) }} onSubmit={updateTableInstance} userPermissions={userPermissions} allRowData={openModal.allRowData} />}
                {(openModal?.type === 'delete' && DeleteModalComponent) && <DeleteModalComponent {...{ setOpened: () => setOpenModal(null) }} onSubmit={updateTableInstance} userPermissions={userPermissions} allRowData={openModal.allRowData} 
                />}
            </WhiteBox>
        </BigTitleAndRightLayout>
    )
}

type BaseScreenTableSimpleStateProps = Modify<BaseScreenTableSimpleProps, {
    logicProps?: never
    updateTableInstance?: never
}>

export const BaseScreenTableSimple: React.FC<BaseScreenTableSimpleStateProps> = ({ ...all }) => {
    const { openModal, setOpenModal, tableInstance, setTableInstance } = useBaseScreenTableLogic()
    const { updateTableInstance } = useUpdateTableInstance({tableInstance})
    return (
        <BaseScreenTableSimpleStateless {...all} logicProps={{ openModal, setOpenModal, tableInstance, setTableInstance }} updateTableInstance={updateTableInstance} />
    )
}

type BaseScreenTableProps = Modify<BaseScreenTableSimpleProps, {
    pageData: BaseScreenPageDataBoth
    CreateModalComponent: React.FC<ModalComponentProps> | ConnectedComponent<ModalComponentProps & any , any>
    logicProps?: never
    updateTableInstance?: never
}>

const BaseScreenTable: React.FC<BaseScreenTableProps> = ({ pageData, userPermissions, ...rest}) => {
    const { openModal, setOpenModal, tableInstance, setTableInstance } = useBaseScreenTableLogic()
    const { updateTableInstance } = useUpdateTableInstance({tableInstance})
    return (
        <BaseScreenTableSimpleStateless {...rest} {...{ pageData, userPermissions, updateTableInstance }} logicProps={{ openModal, setOpenModal, tableInstance, setTableInstance }} RightHeader={
            <div className='flex items-center'>
                {rest.RightHeader}
                <PermissionChecker userPermissions={userPermissions} permission='create'>
                    {pageData.newText && <ButtonPrimary onClick={() => setOpenModal({ type: 'create', allRowData: {} })}>
                        <TwinIcon icon={faPlus} className='mr-8' />
                        {pageData.newText}
                    </ButtonPrimary>}
                </PermissionChecker>
            </div>
        }>
        </BaseScreenTableSimpleStateless>
    )
}

export const useBaseScreenTableLogic = (): BaseScreenLogicProps => {
    const { openModal, setOpenModal } = useEditDeleteModal()
    const [tableInstance, setTableInstance] = useState<TableInstanceType>(null)
    return { openModal, setOpenModal, setTableInstance, tableInstance }
}

export default BaseScreenTable