import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { ScrollEventData, TableRowRenderer } from 'react-virtualized'
import { defaultRowRenderer } from 'react-virtualized/dist/es/Table'
import { VirtualTableListingStateLess } from '../../../../baseComponents/TwinTable/VirtualTableListing'
import { OnRowClick } from '../../../../baseComponents/TwinTable/VirtualTableListing/types'
import { getNextOrder, saveTableData } from '../../../../baseComponents/TwinTable/functions'
import { DefaultOrder, ParseDataFunc, TableConfiguration } from '../../../../baseComponents/TwinTable/types'
import { memoChecker } from '../../../../utils/globals/components'
import { twinFetchPostJSON } from '../../../../utils/globals/data'
import { CreateLinkFunctionType, CreateLinkProps, createLink } from '../../../../utils/globals/link'
import { listenSocket, unlistenSocket } from '../../../../utils/globals/socket'
import { SelectedColumnsAndFilters } from '../../../../utils/hooks/useConfigColumns'
import { addBottomNavAppElement } from '../../../../utils/reducers/reduxDispatch'
import { buildOrderTaskTable, getTaskCustomFieldsIds, getTaskWhereFromFilters, getWhereTaskFromSingleFields, parseTaskTableHeaderWhereParams } from '../functions'
import { TableWhereSearchBarTy, TaskScreenTableBaseTy } from '../types'

const DEFAULT_ROWHEIGHT_PX = 48
const DEFAULT_LIMIT_ROWS = 40

interface TaskTableProps extends TaskTableLogicProps {
    name: string
    createRowDataLink: CreateLinkProps
    setSelectedColumnsAndFilters: React.Dispatch<React.SetStateAction<SelectedColumnsAndFilters>>
    memorizedColumns: any
    selectedColumnsAndFilters: SelectedColumnsAndFilters
    floatingTasks: boolean
    simpleDivContainer?: boolean
}

const TaskTable: React.FC<TaskTableProps> = memo(({ name, columns, getDataFrom, defaultOrder, extraWhereParams, floatingTasks, listenSocketStr, headerTableWhereParams, rowRenderer, setTableInstance, onRowClick, parseData, maxOffset, createRowDataLink, generalSearchbar, selectedColumnsAndFilters, setSelectedColumnsAndFilters, memorizedColumns, simpleDivContainer = false }) => {
    const createRowDataLinkStr = JSON.stringify(createRowDataLink)
    const createLinkCallback: CreateLinkFunctionType = useCallback((allRowData) => {
        return createLink(JSON.parse(createRowDataLinkStr), allRowData)
    }, [createRowDataLinkStr])
    const { tableData, tableConfiguration, headerChanges, changeOffsetVirtualTable, openModalTask, tableRef } = useTaskTableLogic({ columns, getDataFrom, defaultOrder, extraWhereParams, setTableInstance, parseData, maxOffset, selectedColumnsAndFilters, generalSearchbar, headerTableWhereParams, listenSocketStr })

    const memorizedRowRenderer: TableRowRenderer = useCallback((props) => {
        props.key = props.rowData?.id
        if (Object.keys(props.rowData).length) {
            if (simpleDivContainer) {
                return <div className='cursor-pointer' key={props.key}>{defaultRowRenderer(props)}</div>
            }else if (floatingTasks) {
                return <div className='cursor-pointer' key={props.key} onClick={() => openModalTask(props.rowData?.id)}>{defaultRowRenderer(props)}</div>
            }
            return <Link to={createLinkCallback(props.rowData)} key={props.key} className='twin_elipsis'>{rowRenderer ? rowRenderer(props) : defaultRowRenderer(props)}</Link>
        }
        return defaultRowRenderer(props)
    }, [createLinkCallback, rowRenderer, floatingTasks, openModalTask, simpleDivContainer])
    return (
        <div className={'flex flex-col flex-auto h-1'} ref={tableRef}>
            <div className='flex-auto'>
                <VirtualTableListingStateLess {...{ columns: memorizedColumns, tableConfiguration, headerChanges, rowRenderer: memorizedRowRenderer, onRowClick }} setSelectedColumnsAndFilters={setSelectedColumnsAndFilters} tableData={tableData} onScroll={changeOffsetVirtualTable} rowHeight={DEFAULT_ROWHEIGHT_PX} headerHeight={48} name={name} selectedFilters={selectedColumnsAndFilters.filters} selectedOrder={selectedColumnsAndFilters.order}/>
            </div>
        </div>
    )
}, (oldProps, nextProps) => memoChecker(oldProps, nextProps, ['columns', 'defaultOrder', 'extraWhereParams', 'getDataFrom', 'setTableInstance', 'rowRenderer', 'listenSocketStr', 'floatingTasks', 'headerTableWhereParams', 'selectedColumnsAndFilters', 'memorizedColumns']))
interface TaskTableLogicProps extends TaskScreenTableBaseTy {
    headerTableWhereParams: TableWhereSearchBarTy
    generalSearchbar: string
    defaultOrder?: DefaultOrder
    onRowClick?: OnRowClick
    parseData?: ParseDataFunc
    maxOffset?: number
    selectedColumnsAndFilters?: SelectedColumnsAndFilters
}

const useTaskTableLogic = ({ listenSocketStr, setTableInstance, defaultOrder, getDataFrom, extraWhereParams, parseData, maxOffset, columns, generalSearchbar, headerTableWhereParams, selectedColumnsAndFilters }: TaskTableLogicProps) => {
    const tableRef = useRef<any>(null)
    const [tableConfiguration, setTableConfiguration] = useState<TableConfiguration>({
        generalSearchbar,
        order: {},
        offset: 0,
        singleFieldsSearch: {},
        petitionNumber: 0,
    })
    const [tableData, setTableData] = useState<{ [fieldName: string]: any }[] | null>(null)

    const openModalTask = useCallback(async (id: number) => {
        addBottomNavAppElement({ type: 'taskModal', key: 'taskModal-' + id, extraData: { id, opened: true } })
    }, [])

    const changeOrder = useCallback((fieldName: string) => {
        setTableConfiguration((oldTableConfiguration) => {
            const copyOld = JSON.parse(JSON.stringify(oldTableConfiguration))
            const newOrder = getNextOrder(fieldName, copyOld.order, defaultOrder?.order || 'desc')
            return {
                ...copyOld,
                offset: 0,
                order: { ...newOrder }
            }
        })
    }, [setTableConfiguration, defaultOrder?.order])

    const changeOffset = useCallback((offset: number) => {
        setTableConfiguration((oldTableConfiguration) => {
            const copyOld = JSON.parse(JSON.stringify(oldTableConfiguration))
            return {
                ...copyOld,
                offset,
            }
        })
    }, [setTableConfiguration])

    const changeSingleField = useCallback((fieldName: string, value: string) => {
        setTableConfiguration((oldTableConfiguration) => {
            const copyOld = JSON.parse(JSON.stringify(oldTableConfiguration))
            return {
                ...copyOld,
                offset: 0,
                limit: DEFAULT_LIMIT_ROWS,
                singleFieldsSearch: {
                    ...copyOld.singleFieldsSearch,
                    [fieldName]: value
                }
            }
        })
    }, [setTableConfiguration])

    const changeOffsetVirtualTable = useCallback((params: ScrollEventData) => {
        const nitems = params.clientHeight / DEFAULT_ROWHEIGHT_PX
        const sub = DEFAULT_ROWHEIGHT_PX * nitems + params.scrollTop
        const actual = sub / DEFAULT_ROWHEIGHT_PX
        if (tableData) {
            const should_update = tableData.length - 15
            if ((maxOffset === undefined || maxOffset > tableConfiguration.offset) && should_update <= actual && tableData.length === tableConfiguration.offset + DEFAULT_LIMIT_ROWS) {
                changeOffset(tableConfiguration.offset + DEFAULT_LIMIT_ROWS)
            }
        }
    }, [tableConfiguration.offset, tableData, changeOffset, maxOffset])

    const myExtraParamsJustForEffect = JSON.stringify(extraWhereParams)
    const myHeaderTableWhereParamsJustForEffect = JSON.stringify(headerTableWhereParams)
    const myTableConfigurationJustForEffect = JSON.stringify(tableConfiguration)
    const myColumnsJustForEffect = JSON.stringify(columns)
    const mySelectedColumnsAndFiltersJustForEffect = JSON.stringify(selectedColumnsAndFilters)

    const updateDataTable = useCallback(async (params: any) => {
        const { myTableConfiguration, myExtraWhereParams, myHeaderTableWhereParams, selectedColumnsAndFilters } = params
        let orderBuilded = buildOrderTaskTable({ ...selectedColumnsAndFilters.order, ...params.myTableConfiguration?.order }, params.columns)
        if (!orderBuilded.length) {
            orderBuilded = [[params.defaultOrder?.defaultOrderField || 'id', params.defaultOrder?.order || 'desc']]
        }
        const headerTableParsedWhere = parseTaskTableHeaderWhereParams(myHeaderTableWhereParams)
        const customFieldIds = getTaskCustomFieldsIds(params.selectedColumnsAndFilters?.selectedColumns || {})
        const singleFieldsSearch = getWhereTaskFromSingleFields(myTableConfiguration.singleFieldsSearch, params.columns)
        let apiTableData = await twinFetchPostJSON(getDataFrom, {
            limit: params.limit,
            customFieldIds,
            offset: myTableConfiguration.offset,
            whereToParse: {
                ...myExtraWhereParams,
                ...headerTableParsedWhere,
                ...singleFieldsSearch,
                ...getTaskWhereFromFilters(selectedColumnsAndFilters.filters, params.columns),
                deleted: false,
            },
            orderToParse: [
                ...orderBuilded
            ],
        })

        if (params.parseData) {
            apiTableData = params.parseData(apiTableData)
        }
        saveTableData(apiTableData, myTableConfiguration.offset, setTableData)

    }, [getDataFrom])


    const updateDataTableBySocket = useCallback(() => {
        const myExtraWhereParams = JSON.parse(myExtraParamsJustForEffect || '{}')
        const myHeaderTableWhereParams = JSON.parse(myHeaderTableWhereParamsJustForEffect)
        const myTableConfiguration = JSON.parse(myTableConfigurationJustForEffect || '{}')
        const myColumns = JSON.parse(myColumnsJustForEffect || '{}')
        const mySelectedColumnsAndFilters = JSON.parse(mySelectedColumnsAndFiltersJustForEffect || '{}')

        const limit = myTableConfiguration.offset + DEFAULT_LIMIT_ROWS
        myTableConfiguration.offset = 0
        updateDataTable({ myExtraWhereParams, myTableConfiguration, myHeaderTableWhereParams, limit, columns: myColumns, defaultOrder, parseData, selectedColumnsAndFilters: mySelectedColumnsAndFilters })

    }, [defaultOrder, myExtraParamsJustForEffect, myHeaderTableWhereParamsJustForEffect, mySelectedColumnsAndFiltersJustForEffect, parseData, updateDataTable, myTableConfigurationJustForEffect, myColumnsJustForEffect])

    const getTableDataFromStart = useCallback(() => {
        setTableConfiguration((oldTableConfiguration) => {
            return JSON.parse(JSON.stringify({
                ...oldTableConfiguration,
                petitionNumber: oldTableConfiguration.petitionNumber + 1,
                offset: 0
            }))
        })
        if (tableRef.current) {
            const tableBody = tableRef.current.querySelector('.ReactVirtualized__Table__Grid');
            if (tableBody) {
                tableBody.scrollTop = 0
            }
        }
    }, [setTableConfiguration, tableRef])

    const getUpdateTableByEffect = useCallback(() => {
        const myExtraWhereParams = JSON.parse(myExtraParamsJustForEffect || '{}')
        const myHeaderTableWhereParams = JSON.parse(myHeaderTableWhereParamsJustForEffect)
        const myTableConfiguration = JSON.parse(myTableConfigurationJustForEffect || '{}')
        const myColumns = JSON.parse(myColumnsJustForEffect || '{}')
        const mySelectedColumnsAndFilters = JSON.parse(mySelectedColumnsAndFiltersJustForEffect || '{}')
        updateDataTable({ myExtraWhereParams, myHeaderTableWhereParams, myTableConfiguration, limit: DEFAULT_LIMIT_ROWS, columns: myColumns, defaultOrder, selectedColumnsAndFilters: mySelectedColumnsAndFilters, parseData })

    }, [myExtraParamsJustForEffect, myHeaderTableWhereParamsJustForEffect, myTableConfigurationJustForEffect, parseData, updateDataTable, defaultOrder, mySelectedColumnsAndFiltersJustForEffect, myColumnsJustForEffect])

    useEffect(() => {
        if (tableRef.current) {
            const tableBody = tableRef.current.querySelector('.ReactVirtualized__Table__Grid');
            if (tableBody && tableConfiguration.offset === 0) {
                tableBody.scrollTop = 0
            }
        }
    }, [tableConfiguration.offset, tableRef])

    useEffect(() => {
        getUpdateTableByEffect()
    }, [getUpdateTableByEffect])

    useEffect(() => {
        setTableInstance?.({
            getTableDataFromStart
        })
    }, [setTableInstance, getTableDataFromStart])

    useEffect(() => {
        listenSocket(listenSocketStr, updateDataTableBySocket, true)
        return () => {
            unlistenSocket(listenSocketStr)
        }
    }, [updateDataTableBySocket, listenSocketStr])

    return { tableData, tableConfiguration, changeOffsetVirtualTable, headerChanges: { changeOrder, changeSingleField }, openModalTask, tableRef }
}

export default TaskTable