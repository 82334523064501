import { TaskCFieldModelType } from '@teinor/erp/types/company/task/taskCField'
import { LittleModalCreateEdit } from '../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../../../../../baseComponents/ModalsLayouts/types'
import { permissionCheck } from '../../../../../../../baseComponents/PermissionChecker/function'
import { FormRender } from '../../../../../../../forms/FormRenderer/types'
import { valueOrDefaultValue } from '../../../../../../../utils/globals/valueOrDefaultValue'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'

interface ModalCETaskCustomFieldProps extends ModalEditComponentProps { }

const ModalCETaskCustomField: React.FC<ModalCETaskCustomFieldProps> = ({ allRowData, userPermissions,  ...rest }) => {
    const { t } = useTwinTranslation()

    const fields: FormRender<TaskCFieldModelType> = [
        {
            cols: 1,
            elements: [
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'id',
                    component: 'InputHidden'
                }
            ]
        }
    ]
    if (allRowData && userPermissions) {
        fields[0].elements.push({
            name: 'active',
            label: t('active', 'Activo'),
            component: 'CheckboxMargin',
        })
        const parsedFields = valueOrDefaultValue(fields, userPermissions, allRowData)
        return (
            <LittleModalCreateEdit fields={parsedFields} url={'/api/app/task/customField/updateTaskCField'} translations={{ title: t('editCustomField', 'Editar campo personalizado'), button: t('save', 'Guardar') }} haveButtonPermissions={permissionCheck(userPermissions, 'update')} {...rest} />
        )
    }
    return (
        <LittleModalCreateEdit fields={fields} url={'/api/app/task/customField/createTaskCField'} translations={{
            title: t('createTaskCustomField', 'Crear campo personalizado'),
            button: t('create', 'Crear')
        }} haveButtonPermissions={true} {...rest} />
    )
}

export default ModalCETaskCustomField