import ModalDeleteFilled from '../../../../../baseComponents/ModalsLayouts/ModalDeleteFilled'
import { ModalComponentProps } from '../../../../../baseComponents/ModalsLayouts/types'
import { useFetchText } from '../../../../../utils/hooks/useFetchText'
import useTwinTranslation from '../../../../../utils/hooks/useTwinTranslation'

interface ModalDeleteCustomerProjectCommentsProps extends ModalComponentProps {
    id?: number
}

const ModalDeleteCustomerProjectComments: React.FC<ModalDeleteCustomerProjectCommentsProps> = ({ setOpened, onSubmit, id }) => {
    const { t } = useTwinTranslation()
    const { handleFetchText } = useFetchText({
        onSubmit,
        url: '/api/app/customer/customerProject/customerProjectComment/deleteCustomerProjectComment',
        data: {
            id
        }
    })
    return (
        <ModalDeleteFilled opened={true} setOpened={setOpened} onCancel={() => setOpened(null)} onAccept={handleFetchText} translations={{
            title: t('sureDeleteCustomerProjectComment', '¿Seguro quieres eliminar este comentario?'),
            subtitle: t('onceDeletedNotRecoverM', 'Una vez eliminado no podrás recuperarlo')
        }} />
    )
}

export default ModalDeleteCustomerProjectComments
