import { faCopy, faKey } from '@fortawesome/pro-light-svg-icons'
import { useCallback, useState } from 'react'
import TwinIcon from '../../../../baseComponents/TwinIcon'
import { CopyToClipboard } from 'react-copy-to-clipboard';


interface TokenDisplayProps {
    token: string
}

const TokenDisplay: React.FC<TokenDisplayProps> = ({ token }) => {
    const { showToken, changeShowToken } = useTokenDisplayLogic()
    return (
        <div className='flex justify-end items-center mr-6 h-16' >
            <TwinIcon icon={faKey} className='text-green-43 mr-12 cursor-pointer h-16 w-16' onClick={changeShowToken} />
            <RenderToken {...{ showToken, token }}/>
        </div>
    )
}

const useTokenDisplayLogic = () => {
    const [showToken, setShowToken] = useState<boolean>(false)
    
    const changeShowToken = useCallback((e: React.MouseEvent) => {
        e.preventDefault()
        setShowToken((oldToken) => !oldToken)
    }, [setShowToken])

    return {showToken, changeShowToken}
}

interface RenderTokenProps {
    showToken: boolean
    token: string
}

const RenderToken: React.FC<RenderTokenProps> = ({showToken, token}) => {
    if (showToken) {
        return (<span>
            {token}
            <CopyToClipboard text={token}>
                <TwinIcon icon={faCopy} className='cursor-pointer ml-4 hover:text-green-43 ' />
            </CopyToClipboard>
        </span>)
    }
    return <span className='h-16'>********* </span>
}

export default TokenDisplay