import { getLangs } from '@teinor/langs'
import { getLang } from '../../../../utils/globals'
import twinFetchPost, { twinFetchPostJSON } from '../../../../utils/globals/data'
import { dictionaryComplexFromSimpleDict, elementsOfDictionaryByValues } from '../../../../utils/globals/dictionary'
import { listenSocket } from '../../../../utils/globals/socket'
import { appStore } from '../../../../utils/reducers'
import { addBottomNavAppElement, changeCompany, changeConfig, changeLastNotification } from '../../../../utils/reducers/reduxDispatch'
import { getUserPermissions } from '../../../../utils/reducers/getters'
import { permissionCheck } from '../../../../baseComponents/PermissionChecker/function'
import { prepareCurrencies } from '../../../../utils/reducers/globals/config'
import { getCurrencies } from '@teinor/currencies'

export const getAndChangeCompany = async () => {
    const company = await twinFetchPostJSON('/api/company/getCompany', {})
    await changeCompany(company)
}

export const getAndChangeConfig = async () => {
    const lang = getLang()
    const [currencies, mylang, config] = await Promise.all([
        getCurrencies(lang),
        getLangs(lang),
        twinFetchPostJSON('/api/app/config/getConfig', {})
    ])
    if (config) {
        await changeConfig({
            langs: {
                all: dictionaryComplexFromSimpleDict(mylang),
                used: dictionaryComplexFromSimpleDict(elementsOfDictionaryByValues(mylang, config.langs))
            },
            currencies: prepareCurrencies(config.currencies, currencies),
            parameters: config.parameters
        })
        listenSocket('config', () => getAndChangeConfig())
    }
}

export const getAndChangeNotifications = async () => {
    const idEmployee = appStore.getState().user?.extraData.Employee?.id
    const notifications = appStore.getState().user?.extraData.Employee?.EmployeeNotifications
    if (notifications) {
        changeLastNotification(notifications[0])
    }
    if (idEmployee) {
        listenSocket('employeeNotification-' + idEmployee, (notification?: string) => {
            if (notification) {
                const notificationParsed = JSON.parse(notification)
                changeLastNotification(notificationParsed)
            }
        })
    }
}

export const checkIfEmployeeIsValid = async () => {
    const response = await twinFetchPost('/api/app/employee/checkEmployeeValid', {})
    if (response.status === 200) {
        const text = await response.text()
        return { status: 200, text }
    } else if (response.status === 202) {
        return { status: 202, text: '' }
    } else if (response.status === 423) {
        return { status: 423, text: '' }
    }  else {
        return { status: 422, text: '' }
    }
}

export const getMyWorkingTask = async() => {
    const userPermission = getUserPermissions('task.main')
    if (permissionCheck(userPermission, 'update')) {
        const result = await twinFetchPostJSON('/api/app/task/workLog/getMyCurrentTaskWorkLog' , {})
        if(result){
            addBottomNavAppElement({ type: 'workingModal', key: 'workingModal', extraData: { id: result.id, name: result.Task.name, TaskId: result.TaskId, startDate: result.startDate } })
        }
    }
}