import { useCallback, useState } from 'react'
import { ModalButtons, ModalMediumCentered, ModalSmallCentered } from '../../Modal'
import { ModalOpenedSetTy, ModalOpenedTy } from '../../Modal/types'
import { ButtonRed, ButtonSecondary } from '../../Button'
import TwinTrans from '../../TwinTrans'
import PermissionChecker from '../../PermissionChecker'
import { getUserPermissions } from '../../../utils/reducers/getters'
import { ErpSingleModule } from '@teinor/erp/modules'
import useIsLoading from '../../../utils/hooks/useIsLoading'
import { twinFetchPostJSON } from '../../../utils/globals/data'
import { PartialRecord } from '../../../utils/globals/types'
import './modaldemo.sass'

interface ModalDemoProps extends ModalDemoLogicProps {}

const ModalDemo: React.FC<ModalDemoProps> = ({...logic}) => {
    const { opened, setOpened, activateModule, loading, setGoDelete, goDelete } = useModalDemoLogic({ ...logic })
    if (!opened) {
        return null
    }
    if (loading) {
        return (
            <ModalSmallCentered opened={opened} setOpened={() => setOpened(null)} onClickOut={false}>
                <h2 className='my-auto'><TwinTrans transKey='goingRealMode'>Pasando a modo real...</TwinTrans></h2>
            </ModalSmallCentered>
        )
    }
    if (goDelete) {
        const myDict: PartialRecord<ErpSingleModule, React.FC<ModalActivateModuleDefaultProps>> = {
            tasks: ModalActivateModuleTasks
        }
        const Component = myDict[logic.moduleInDemo] || ModalActivateModuleDefault
        return <Component activateModule={activateModule} setOpened={setOpened}/>
    }
    return (
        <ModalMediumCentered opened={opened} setOpened={() => setOpened(null)} onClickOut={false}>
            <h2><TwinTrans transKey='thisSectionIsInDemo'>Esta parte de la aplicación está en modo pruebas</TwinTrans></h2>
            <span className='my-20'><TwinTrans transKey='explainDemoMode'>Los datos que verás a continuación están en modo pruebas. Al pasar a modo real, todos los datos se borrarán. Los administradores de tu empresa pueden pasar este módulo a modo real.</TwinTrans></span>
            <ModalButtons>
                <ButtonSecondary onClick={() => setOpened(null)} className='cancel_delete' type='button'>
                     <TwinTrans transKey='continueInDemo'>Continuar en pruebas</TwinTrans>
                </ButtonSecondary>
                <PermissionChecker userPermissions={getUserPermissions('config.activateModule')} permission={'delete'}>
                    <ButtonRed onClick={() => setGoDelete(true)}>
                        <TwinTrans transKey='activateRealMode'>Activar modo real</TwinTrans>
                    </ButtonRed>
                </PermissionChecker>
            </ModalButtons>
        </ModalMediumCentered>
    )
}

interface ModalDemoLogicProps {
    moduleInDemo: ErpSingleModule
}

const useModalDemoLogic = ({ moduleInDemo }: ModalDemoLogicProps) => {
    const [opened, setOpened] = useState<ModalOpenedTy>(true)
    const [goDelete, setGoDelete] = useState(false)
    const {loading, startLoading} = useIsLoading()
    const activateModule = useCallback(async (deleteData: boolean | 'full') => {
        startLoading()
        await twinFetchPostJSON('/api/app/config/activateModule', {
            ModuleId: moduleInDemo,
            deleteData
        })
        setTimeout(() => window.location.reload(), 1000)
    }, [startLoading, moduleInDemo])
    return {opened, setOpened, activateModule, loading, setGoDelete, goDelete}
}


interface ModalActivateModuleDefaultProps {
    activateModule: (deleteData: boolean | 'full') => Promise<void>
    setOpened: ModalOpenedSetTy
}

const ModalActivateModuleDefault: React.FC<ModalActivateModuleDefaultProps> = ({activateModule, setOpened}) => {
    return (
        <ModalMediumCentered opened={true} setOpened={() => setOpened(null) } onClickOut={false}>
            <h2><TwinTrans transKey='chooseHowYouCanGoRealMode'>Escoge como quieres pasar a modo real</TwinTrans></h2>
            <div className='flex flex-col gap-y-15 mt-20'>
                <ButtonRed onClick={() => activateModule(true)}>
                    <TwinTrans transKey='activateRealModeDeletingData'>Activar modo real eliminando datos</TwinTrans>
                </ButtonRed>
                <ButtonRed onClick={() => activateModule(false)}>
                    <TwinTrans transKey='activateRealModeKeepingData'>Activar modo real conservando datos</TwinTrans>
                </ButtonRed>
                <ButtonSecondary onClick={() => setOpened(null)} className='cancel_delete' type='button'>
                    <TwinTrans transKey='continueInDemo'>Continuar en pruebas</TwinTrans>
                </ButtonSecondary>
            </div>
        </ModalMediumCentered>
    )
}

const ModalActivateModuleTasks: React.FC<ModalActivateModuleDefaultProps> = ({activateModule, setOpened}) => {
    return (
        <ModalMediumCentered opened={true} setOpened={() => setOpened(null)} onClickOut={false} className='modal_activate_tasks'>
            <h2><TwinTrans transKey='chooseHowYouCanGoRealMode'>Escoge como quieres pasar a modo real</TwinTrans></h2>
            <div className='flex flex-col gap-y-15 mt-20'>
                <ButtonRed onClick={() => activateModule(true)}>
                    <TwinTrans transKey='activateRealModeDeletingTaskData'>Activar modo real eliminando datos de tareas</TwinTrans>
                </ButtonRed>
                <ButtonRed onClick={() => activateModule('full')}>
                    <TwinTrans transKey='activateRealModeDeletingTaskCustomersData'>Activar modo real eliminando datos de tareas y clientes</TwinTrans>
                </ButtonRed>
                <ButtonRed onClick={() => activateModule(false)}>
                    <TwinTrans transKey='activateRealModeKeepingData'>Activar modo real conservando datos</TwinTrans>
                </ButtonRed>
                <ButtonSecondary onClick={() => setOpened(null)} className='cancel_delete' type='button'>
                    <TwinTrans transKey='continueInDemo'>Continuar en pruebas</TwinTrans>
                </ButtonSecondary>
            </div>
        </ModalMediumCentered>
    )
}

export default ModalDemo