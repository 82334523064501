import { EditorState, ContentState, convertToRaw, RawDraftEntity } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
const customChunkRenderer = (nodeName: string, node: HTMLElement): RawDraftEntity | undefined=> {
    const allowedNodes = [
        "div",
        "table",
        "tbody",
        "tr",
        "th",
        "td",
        "thead",
        "style"
    ];
    if (allowedNodes.includes(nodeName)) {
        return {
            type: nodeName.toString().toUpperCase(),
            mutability: "MUTABLE",
            data: {
                // Pass whatever you want here (like id, or classList, etc.)
                innerText: node.innerText,
                innerHTML: node.innerHTML
            }
        };
    }
    return undefined;
}
const entityMapper = (entity: any) => {
    if (entity.type === "DIV") {
        return `<div>${entity.data.innerHTML}</div>`;
    }
    if (entity.type === "TABLE") {
        return `<table>${entity.data.innerHTML}</table>`;
    }
    if (entity.type === "TBODY") {
        return `<tbody>${entity.data.innerHTML}</tbody>`;
    }
    if (entity.type === "TR") {
        return `<tr>${entity.data.innerHTML}</tr>`;
    }
    if (entity.type === "TH") {
        return `<th>${entity.data.innerHTML}</th>`;
    }
    if (entity.type === "TD") {
        return `<td>${entity.data.innerHTML}</td>`;
    }
    if (entity.type === "STYLE") {
        return `<style>${entity.data.innerHTML}</style>`;
    }
    return "";
}

export const getEditorStateWithContent = (content: string) => {
    const blocksFromHtml = htmlToDraft(content, customChunkRenderer);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);
    return editorState
}

export const getHTMLFromEditorState = (EditorState: EditorState) => {
    return draftToHtml(convertToRaw(EditorState.getCurrentContent()), undefined, undefined, entityMapper)
}