import { faBarcode, faBell, faBoxOpen, faBriefcase, faCalendarDays, faCamera, faCarBuilding, faChartLineUp, faClock, faComments, faEnvelope, faFileLines, faHandshake, faHeadset, faMoneyCheckDollar, faPencil, faPhone, faPlaneDeparture, faPlateUtensils, faPrint, faRotateRight, faTag, faTruckFast, faUser } from '@fortawesome/pro-light-svg-icons';
import { faWhatsapp, faSkype, faGoogle, faCcVisa, faLinkedinIn, faMailchimp } from '@fortawesome/free-brands-svg-icons';
import { TwinDictionary } from '../globals/dictionary';

export const dictIcon: TwinDictionary = {
    'faBoxOpen': faBoxOpen,
    'faCarBuilding': faCarBuilding,
    'faPhone': faPhone,
    'faTruckFast': faTruckFast,
    'faComments': faComments,
    'faMoneyCheckDollar': faMoneyCheckDollar,
    'faHeadset': faHeadset,
    'faChartLineUp': faChartLineUp,
    'faPlaneDeparture': faPlaneDeparture,
    'faTag': faTag,
    'faBarcode': faBarcode,
    'faRotateRight': faRotateRight,
    'faPencil': faPencil,
    'faBell': faBell,
    'faCalendarDays': faCalendarDays,
    'faEnvelope': faEnvelope,
    'faHandshake': faHandshake,
    'faFileLines': faFileLines,
    'faUser': faUser,
    'faClock': faClock,
    'faPlateUtensils': faPlateUtensils,
    'faPrint': faPrint,
    'faBriefcase': faBriefcase,
    'faCamera': faCamera,
    'faWhatsapp': faWhatsapp,
    'faSkype': faSkype,
    'faGoogle': faGoogle,
    'faCcVisa': faCcVisa,
    'faLinkedinIn': faLinkedinIn,
    'faMailchimp': faMailchimp,
}
