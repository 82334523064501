import { SueStatusDateModelType } from '@teinor/erp/types/company/sue/sueStatusDate'
import { VirtualTableListingStateLess } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing'
import { ColumnTableSchema } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import { CustomRenderDate, CustomRenderDateJustHours, CustomRenderString } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { TwinDictionary } from '../../../../../../../../../utils/globals/dictionary'
import TwinTrans from '../../../../../../../../../baseComponents/TwinTrans'

interface SueActivityProps {
    sueStatusDates: SueStatusDateModelType[]
}

const SueActivity: React.FC<SueActivityProps> = ({sueStatusDates}) => {
    const {t} = useTwinTranslation()
    const sueStatusTypes: TwinDictionary = {
        0: t('informed', 'Informado'),
        1: t('processing', 'Procesando'),
        2: t('closed', 'Cerrado'),
    }
    const columns: ColumnTableSchema<SueStatusDateModelType> = [
        {
            id: 'date',
            dataKey: 'createdAt',
            label: t('date', 'Fecha'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />,
            minWidth: 100
        },
        {
            id: 'hour',
            dataKey: 'createdAt',
            label: t('hour', 'Hora'),
            customRender: (parameterValue) => <CustomRenderDateJustHours value={parameterValue} />,
            minWidth: 100
        },
        {
            id: 'status',
            dataKey: 'status',
            label: t('status', 'Estado'),
            customRender: (parameterValue) => <CustomRenderString value={sueStatusTypes[parseInt(String(parameterValue))]} />,
            minWidth: 100
        },
    ]
    
    return (
        <div className='flex-auto mb-20'>
            <div className='flex justify-between items-center mb-20'>
                <h2>
                    <TwinTrans transKey='activity'>Actividad</TwinTrans>
                </h2>
            </div>
            <div style={{height: ((sueStatusDates.length + 1) * 48 ) + 'px' }}>
                <VirtualTableListingStateLess tableData={sueStatusDates} rowHeight={48} headerHeight={48} name='sueActivity' columns={columns} setSelectedColumnsAndFilters={() => { }} />
            </div>
        </div>
    )
}


export default SueActivity