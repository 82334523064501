import { useCallback, useEffect, useState } from 'react'
import { ButtonPrimary } from '../../../../../baseComponents/Button'
import CustomError from '../../../../../baseComponents/CustomError'
import { ModalLittleUnclosable } from '../../../../../baseComponents/Modal'
import TwinForm from '../../../../../forms/TwinForm'
import { OnSubmit } from '../../../../../forms/TwinForm/types'
import FormRenderer from '../../../../../forms/FormRenderer'
import { FormRender } from '../../../../../forms/FormRenderer/types'
import { ApiError, twinFetchPostJSON } from '../../../../../utils/globals/data'
import useTwinTranslation from '../../../../../utils/hooks/useTwinTranslation'
import { ErpLoaderLoaded } from '../types'
import jscookie from 'js-cookie'
import { setUserByLogin, signOutUser } from '../../../../../utils/reducers/reduxDispatch'
import TwinTrans from '../../../../../baseComponents/TwinTrans'
import { appStore } from '../../../../../utils/reducers'
import { Link } from 'react-router-dom'
import { TwinDictionary, dictionaryComplexFromJsonArrCodeName } from '../../../../../utils/globals/dictionary'

interface ErpLoaderCompanyModalProps extends ErpLoaderCompanyModalLogicProps {
    loaded: ErpLoaderLoaded
}
interface ErpLoaderCompanyModalForm {
    token: string
}

const ErpLoaderCompanyModal: React.FC<ErpLoaderCompanyModalProps> = ({ setLoaded, loaded }) => {
    const { onSubmit, errors, myCompanies } = useErpLoaderCompanyModalLogic({ setLoaded })
    const { t } = useTwinTranslation()
    const sections: FormRender<ErpLoaderCompanyModalForm> = [
        {
            cols: 1,
            className: 'mt-20',
            elements: [
                {
                    name: 'token',
                    label: t('company', 'Empresa'),
                    component: 'CustomSelectWithSearchBar',
                    required: true,
                    items: myCompanies || {},
                }
            ]
        }
    ]
    return (
        <ModalLittleUnclosable opened={loaded === 'companyLeft' ? true : null}>
            <TwinForm action='/api/user/setCompanyToUser' onSubmit={onSubmit}>
                <div className='text-center'>
                    <h2><TwinTrans transKey='welcomeBack'>Bienvenido de nuevo</TwinTrans>, {appStore.getState().user?.extraData.name} </h2>
                    <span className='mt-20 block'><TwinTrans transKey='selectCompanyToContinue'>Seleccione una empresa para continuar</TwinTrans></span>
                </div>
                <CustomError textError={errors && errors.msg} className='my-20' />
                <FormRenderer sections={sections} />
                <ButtonPrimary className='w-full mt-30' type='submit'>
                    <TwinTrans transKey='confirm'>Confirmar</TwinTrans>
                </ButtonPrimary>
            </TwinForm>
            <Link to='/' onClick={signOutUser} className='mt-30 block text-center text-green-21'><TwinTrans transKey='signOut'>Cerrar sesión</TwinTrans></Link>
        </ModalLittleUnclosable>
    )
}

interface ErpLoaderCompanyModalLogicProps {
    setLoaded: React.Dispatch<React.SetStateAction<ErpLoaderLoaded>>
}

const useErpLoaderCompanyModalLogic = ({ setLoaded }: ErpLoaderCompanyModalLogicProps) => {
    const [errors, setErrors] = useState<false | ApiError>(false)
    const [myCompanies, setMyCompanies] = useState<TwinDictionary | null>(null)

    const getUserCompanies = useCallback(async () => {
        const companies = await twinFetchPostJSON('/api/company/getMyCompanies', {})
        const dictCompanies = dictionaryComplexFromJsonArrCodeName(companies, 'token', 'name', 'id_number')
        setMyCompanies(dictCompanies)
    }, [])

    const onSubmit: OnSubmit = useCallback(async (res) => {
        if (res.status === 200) {
            const token = await res.text()
            const cookie = JSON.parse(jscookie.get('twin_jwt_user') || '')
            const remember = cookie.remember ?? false
            await setUserByLogin({ token, type: 'erp', remember }, remember)
            setLoaded(true)
        } else {
            const errors = await res.json()
            setErrors(errors.errors[0])
        }
    }, [setLoaded])

    useEffect(() => {
        getUserCompanies()
    }, [getUserCompanies])
    return { onSubmit, errors, myCompanies }
}

export default ErpLoaderCompanyModal