import { useCallback, useMemo } from 'react'
import { BlockPicker, BlockPickerProps  } from 'react-color'
import { TwinColorResult } from './types'
import { Modify } from '@teinor/erp'
import './twinColor.sass' 

type TwinColorProps = Modify<BlockPickerProps, TwinColorLogicProps & {
    color: string
    className?: string
}>

const TwinColor: React.FC<TwinColorProps> = ({ colors, onChange, ...rest }) => {
    const { writeSelectedColor, myColors} = useTwinColorLogic({ colors, onChange})
    return (<BlockPicker triangle='hide' colors={myColors} onChangeComplete={writeSelectedColor} {...rest} />)
}
interface TwinColorLogicProps {
    colors?: string[]
    onChange: (color: string)=> void
}


const useTwinColorLogic = ({ colors, onChange }: TwinColorLogicProps) => {
    const myColors = useMemo(() => colors || ['#6299E3', '#90BDDA', '#F8A7C1', '#AECB6C', '#F88754', '#F5AE56', '#D891EE', '#21B37D', '#43BAA5', '#D62839'], [colors])

    const writeSelectedColor = useCallback((color: TwinColorResult) => {
        onChange(color.hex)
    }, [ onChange])


    return { myColors, writeSelectedColor }
}

export default TwinColor