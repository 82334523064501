import { useCallback } from 'react'
import ButtonModalSortList, { ItemSortFunction } from '../../../../../../../../baseComponents/Sorts/ButtonModalSortList'
import { BaseButtonModalSortListProps } from '../../../../../../../../baseComponents/Sorts/ButtonModalSortList/types'
import { twinFetchPostText } from '../../../../../../../../utils/globals/data'
import { useParams } from 'react-router-dom'

interface CustomerProjectStatusSortButtonProps extends BaseButtonModalSortListProps {
}

const CustomerProjectStatusSortButton: React.FC<CustomerProjectStatusSortButtonProps> = ({ ...rest }) => {
    const { updateOrder } = useCustomerProjectStatusSortButtonLogic()
    const { CustomerProjectTypeId } = useParams()
    const extraWhereParams = { 'CustomerProjectTypeId': CustomerProjectTypeId ? parseInt(CustomerProjectTypeId) : null }
    return (
        <ButtonModalSortList updateData={updateOrder} getDataFrom='/api/app/customer/customerProject/customerProjectStatus/getAllCustomerProjectStatus' extraWhereParams={extraWhereParams} {...rest} />
    )
}

const useCustomerProjectStatusSortButtonLogic = () => {
    const updateOrder: ItemSortFunction = useCallback(async (data) => {
        const resp = await twinFetchPostText('/api/app/customer/customerProject/customerProjectStatus/updateCustomerProjectStatusOrderAndListing', {
            customerProjectStatus: data,
        })
        if (resp) {
            return true
        }
        return false
    }, [])

    return { updateOrder }
}

export default CustomerProjectStatusSortButton