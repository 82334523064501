import { faChevronDown, faChevronUp, faClock } from '@fortawesome/pro-light-svg-icons'
import Input, { InputWithLabelMarginProps } from '..'
import TwinIcon from '../../../baseComponents/TwinIcon'
import { OnChangeInputHourMinSecTy } from './types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { HourMinSecTy, Modify } from '../../../utils/globals/types'
import { checkHourValidOnChange, displayFormatedHourMinSec, getHoursMinSecFromSecs, getSecFromHoursMinsSecs, parseHourStr } from '../../../utils/globals/date'
import { addClassName, removeClassName } from '../../../utils/globals/components'
import { checkInputHourSecValidity } from './functions'
import useOnClickOutside from '../../../utils/hooks/useOnClickOut'
import './inputHourMinSec.sass'

export type InputHourMinSecStateLessProps = InputWithLabelMarginProps & InputHourMinSecStateLessLogicProps & {}

export const InputHourMinSecStateLess: React.FC<InputHourMinSecStateLessProps> = ({ className, value, readOnly, label, required, name, onFinalChange, maxHours = 23, ...rest }) => {
    const { ref, myOnChange, myOnFocusParent, myTmpHour, onBlurInput, myFinalChange, openHourModal, hourMinSec } = useInputHourMinSecStateLessLogic({ value, readOnly, onFinalChange, maxHours })
    return (
        <div className={'input_hour_container input_field relative flex w-full flex-col ' + (className || '')}>
            <label className="input_hour_label text-gray-51 cursor-pointer ">{label}{required ? ' *' : ''}</label>
            <div className={'flex w-full items-center cursor-pointer'} onClick={myOnFocusParent} ref={ref}>
                <Input className='w-70' {...rest} readOnly={readOnly} value={myTmpHour} onChange={myOnChange} onBlur={onBlurInput} />
                <TwinIcon className='ml-auto' icon={faClock} />
                {name ? <input type='hidden' name={name} value={String(value)} /> : null}
                {openHourModal && <ModalInputHourMinSec onFinalChangeModal={myFinalChange} value={hourMinSec}  {...{ maxHours }} />}
            </div>
            <div className='input_border' />
        </div>
    )
}

export interface InputHourMinSecStateLessLogicProps {
    value: string
    onFinalChange?: OnChangeInputHourMinSecTy
    readOnly?: boolean
    maxHours?: number
}


const useInputHourMinSecStateLessLogic = ({ readOnly, value, onFinalChange, maxHours }: InputHourMinSecStateLessLogicProps) => {
    const ref = useRef<any>(null)
    const hourMinSec = useMemo(()=>getHoursMinSecFromSecs(parseInt(value || '0')),[value])
    const [myTmpHour, setMyTmpHour] = useState<string>(displayFormatedHourMinSec(hourMinSec))
    const [openHourModal, setOpenHourModal] = useState<boolean>(false)
    
    const myOnChange = useCallback((value: string) => {
        const validQuantity = checkHourValidOnChange(value)
        if (value === '' || validQuantity) {
            setMyTmpHour(value)
        }
    }, [setMyTmpHour])

    const myOnFocusParent: React.MouseEventHandler<HTMLDivElement> = useCallback((e) => {
        if (!openHourModal && !readOnly) {
            addClassName('input_hour_focus', e.currentTarget.parentElement)
            const inputRef: any = e.currentTarget.firstChild
            inputRef.focus()
            setOpenHourModal(true)
        }
    }, [readOnly, setOpenHourModal, openHourModal])

    const myFinalChange = useCallback((hour: HourMinSecTy) => {
        const secs = getSecFromHoursMinsSecs(hour.hours, hour.mins, hour.secs)
        setMyTmpHour(displayFormatedHourMinSec(hour))
        onFinalChange?.(secs, ref)
    }, [onFinalChange])

    const onBlurInput: React.FocusEventHandler<HTMLInputElement> = useCallback((e) => {
        const currentTarget = e.currentTarget
        const currentTargetParent = currentTarget.parentElement?.parentElement
        removeClassName('input_hour_focus', currentTargetParent)
        const timeParsed = checkInputHourSecValidity(currentTarget.value, maxHours)
        if (timeParsed) {
            removeClassName('input_hour_error', currentTargetParent)
            myFinalChange(timeParsed)
        } else {
            if (currentTargetParent) {
                addClassName('input_hour_error', currentTargetParent)
            }
            setMyTmpHour(displayFormatedHourMinSec(hourMinSec))
        }
    }, [setMyTmpHour, myFinalChange, maxHours, hourMinSec])

    useEffect(() => {
        setMyTmpHour(displayFormatedHourMinSec(hourMinSec))
    }, [hourMinSec])

    useOnClickOutside(ref, () => {
        setOpenHourModal(false)
    })

    return { ref, myTmpHour, openHourModal, myOnChange, myOnFocusParent, onBlurInput, myFinalChange, hourMinSec }
}


export type InputHourMinSecStateFullProps = Modify<InputHourMinSecStateLessProps, {
    value?: string
}>

export const InputHourMinSecStateFull: React.FC<InputHourMinSecStateFullProps> = ({ ...rest }) => {
    const { finalSecs,  myFinalChange } = useInputHourMinSecStateFullLogic({ ...rest})
    return (
        <InputHourMinSecStateLess value={String(finalSecs)} onFinalChange={myFinalChange} {...rest} />
    )
}


export const RectangularInputHourMinSecStateLess: React.FC<InputHourMinSecStateLessProps> = ({ className, value, readOnly, label, required, name, onFinalChange, maxHours = 23, ...rest }) => {
    const { ref, myOnChange, myOnFocusParent, myTmpHour, onBlurInput, myFinalChange, openHourModal, hourMinSec } = useInputHourMinSecStateLessLogic({ value, readOnly, onFinalChange, maxHours })
    return (
        <div className={' relative flex w-75 ' + (className || '')} >
            <div className={'rectangular_input_hour_container ' + (readOnly ? ' rectangular_readonly' : '')} onClick={myOnFocusParent} ref={ref} >
                <Input className='rectangular_input_hour' {...rest} readOnly={readOnly} value={myTmpHour} onChange={myOnChange} onBlur={onBlurInput} />
                <TwinIcon className='cursor-pointer' icon={faClock} />
                {name ? <input type='hidden' name={name} value={String(value)} /> : null}
                {openHourModal && <ModalInputHourMinSec onFinalChangeModal={myFinalChange} value={hourMinSec} {...{ maxHours }} />}
            </div>
        </div>
    )
}
export const RectangularInputHourMinSecStateFull: React.FC<InputHourMinSecStateFullProps> = ({ ...rest }) => {
    const { finalSecs, myFinalChange } = useInputHourMinSecStateFullLogic({ ...rest })
    return (
        <RectangularInputHourMinSecStateLess value={String(finalSecs)} onFinalChange={myFinalChange} {...rest} />
    )
}


interface InputHourMinSecStateFullLogicProps {
    value?: string
    onFinalChange?: OnChangeInputHourMinSecTy
}


export const useInputHourMinSecStateFullLogic = ({ value, onFinalChange }: InputHourMinSecStateFullLogicProps) => {
    const [finalSecs, setFinalSecs] = useState<number>(parseInt(value || '0'))

    const myFinalChange: OnChangeInputHourMinSecTy = useCallback((secs, ref) => {
        setFinalSecs(secs)
        onFinalChange?.(secs, ref)
    }, [setFinalSecs, onFinalChange])

    return { finalSecs, myFinalChange }
}


interface ModalInputHourMinSecProps {
    onFinalChangeModal: (hour: HourMinSecTy) => void
    value: HourMinSecTy
    maxHours: number
}


const ModalInputHourMinSec: React.FC<ModalInputHourMinSecProps> = ({ maxHours, ...rest }) => {
    return (
        <div className='modal_input_hour flex items-center'>
            <HourChanger {...rest} maxHours={maxHours} />
            <MinChanger {...rest} field='mins' />
            <SecChanger {...rest} field='secs' />
        </div>
    )
}

interface HourChangerProps extends HourChangerLogicProps {
}

const HourChanger: React.FC<HourChangerProps> = ({ value, onFinalChangeModal, maxHours }) => {
    const { myValue, setTime, optionsTime } = useHourChangerLogic({ value, onFinalChangeModal, maxHours })
    return (
        <TimeChanger value={myValue} {...{ setTime, optionsTime }} />
    )
}

interface HourChangerLogicProps {
    value: HourMinSecTy
    maxHours: number
    onFinalChangeModal: (hour: HourMinSecTy) => void
}

const useHourChangerLogic = ({ value, onFinalChangeModal, maxHours }: HourChangerLogicProps) => {
    const setTime = useCallback((time: number) => {
        if (maxHours === Infinity) {
            if (time < 0) {
                time = 0
            }
        } else {
            if (time > 23) {
                time = time - 24
            } else if (time < 0) {
                time = time + 24
            }
        }
        onFinalChangeModal({secs: value.secs, mins: value.mins, hours: time })
    }, [onFinalChangeModal, value.mins, value.secs, maxHours])

    const myValue = value.hours

    let optionsTime: number[] = [
        myValue - 2 < 0 ? myValue + 22 : myValue - 2,
        myValue - 1 < 0 ? myValue + 23 : myValue - 1,
        myValue,
        myValue + 1 > 23 ? myValue - 23 : myValue + 1,
        myValue + 2 > 23 ? myValue - 22 : myValue + 2
    ]
    if (maxHours === Infinity) {
        optionsTime = [
            myValue - 2 < 0 ? 0 : myValue - 2,
            myValue - 1 < 0 ? 0 : myValue - 1,
            myValue,
            myValue + 1,
            myValue + 2
        ]
    }
    return { myValue, setTime, optionsTime }
}

interface MinChangerProps extends MinSecChangerLogicProps {
}

const MinChanger: React.FC<MinChangerProps> = ({ value, onFinalChangeModal, field }) => {
    const { myValue, setTime, optionsTime } = useMinSecChangerLogic({ value, onFinalChangeModal, field})
    return (
        <TimeChanger value={myValue} {...{ setTime, optionsTime }} />
    )
}

interface MinSecChangerLogicProps {
    field: 'mins' | 'secs'
    value: HourMinSecTy
    onFinalChangeModal: (hour: HourMinSecTy) => void
}

const useMinSecChangerLogic = ({ value, onFinalChangeModal, field }: MinSecChangerLogicProps) => {
    const setTime = useCallback((time: number) => {
        if (time > 59) {
            time = time - 60
        } else if (time < 0) {
            time = time + 60
        }
        if(field === 'mins'){
            onFinalChangeModal({ hours: value.hours, [field]: time, secs: value.secs })
        } else {
            onFinalChangeModal({ hours: value.hours, mins: value.mins , [field]: time })
        }
    }, [onFinalChangeModal, value.hours, value.secs, value.mins, field])
    const myValue = value[field]
    const optionsTime: number[] = [
        myValue - 2 < 0 ? myValue + 58 : myValue - 2,
        myValue - 1 < 0 ? myValue + 59 : myValue - 1,
        myValue,
        myValue + 1 > 59 ? myValue - 59 : myValue + 1,
        myValue + 2 > 59 ? myValue - 58 : myValue + 2
    ]
    return { myValue, setTime, optionsTime }
}
interface SecChangerProps extends MinSecChangerLogicProps {
}

const SecChanger: React.FC<SecChangerProps> = ({ value, onFinalChangeModal }) => {
    const { myValue, setTime, optionsTime } = useMinSecChangerLogic({ value, onFinalChangeModal, field: 'secs' })
    return (
        <TimeChanger value={myValue} {...{ setTime, optionsTime }} />
    )
}

interface TimeChangerProps extends TimerChangerLogicProps {
    optionsTime: number[]
}

const TimeChanger: React.FC<TimeChangerProps> = ({ value, setTime, optionsTime }) => {
    const { handleMouseDownAdd, handleMouseDownReduce } = useTimerChangerLogic({ setTime, value })
    return (
        <div className='flex flex-col justify-center'>
            <TwinIcon className='icon_change_hour' icon={faChevronUp} onClick={() => setTime(value - 1)} onMouseDown={handleMouseDownReduce} />
            <div className='time_changer_value' onClick={() => setTime(value - 2)}>{parseHourStr(optionsTime[0])}</div>
            <div className='time_changer_value' onClick={() => setTime(value - 1)}>{parseHourStr(optionsTime[1])}</div>
            <div className='time_changer_value selected'>{parseHourStr(optionsTime[2])}</div>
            <div className='time_changer_value' onClick={() => setTime(value + 1)}>{parseHourStr(optionsTime[3])}</div>
            <div className='time_changer_value' onClick={() => setTime(value + 2)}>{parseHourStr(optionsTime[4])}</div>
            <TwinIcon className='icon_change_hour' icon={faChevronDown} onClick={() => setTime(value + 1)} onMouseDown={handleMouseDownAdd} />
        </div>
    )
}
interface TimerChangerLogicProps {
    setTime: (value: number) => void
    value: number
}

const useTimerChangerLogic = ({ value, setTime }: TimerChangerLogicProps) => {
    const [myInterval, setMyInterval] = useState<NodeJS.Timer | null>(null)

    const handleMouseDownAdd = useCallback(() => {
        setMyInterval(() => { let time = value; return setInterval(() => { time++; setTime(time) }, 150) })
    }, [setTime, value])
    const handleMouseDownReduce = useCallback(() => {
        setMyInterval(() => { let time = value; return setInterval(() => { time--; setTime(time) }, 150) })
    }, [setTime, value])

    const handleMouseUp = useCallback(() => {
        if (myInterval) {
            clearInterval(myInterval)
        }
    }, [myInterval])


    useEffect(() => {
        if (myInterval) {
            document.addEventListener('mouseup', handleMouseUp)
        }
        return () => {
            document.removeEventListener('mouseup', handleMouseUp)
        }
    }, [myInterval, handleMouseUp])

    return { handleMouseDownAdd, handleMouseDownReduce }
}