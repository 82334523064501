import { useCallback, useEffect } from 'react'
import { ModalEditComponentProps } from '../../../../../../../../../../baseComponents/ModalsLayouts/types'
import { addBottomNavAppElement } from '../../../../../../../../../../utils/reducers/reduxDispatch'

interface ModalUserNotificationTasksProps extends ModalUserNotificationLogicProps {}

const ModalUserNotificationTasks: React.FC<ModalUserNotificationTasksProps> = ({ ...rest }) => {
    useModalUserNotificationLogic({...rest})
    return (null)
}
interface ModalUserNotificationLogicProps extends ModalEditComponentProps {}

const useModalUserNotificationLogic = ({allRowData, setOpened}: ModalUserNotificationLogicProps) => {
    const openModalTaskFloating = useCallback((id: string) => {
        addBottomNavAppElement({ type: 'taskModal', key: 'taskModal-' + id, extraData: { id, opened: true } })
        setOpened(null)
    }, [setOpened])
    
    useEffect(() => {
        openModalTaskFloating(allRowData?.SubId)
    }, [allRowData?.SubId, openModalTaskFloating])
}

export default ModalUserNotificationTasks