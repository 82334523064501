import { Modify } from '@teinor/erp'
import { useCallback, useMemo, useState } from 'react'
import { CustomSelectBase, CustomSelectBaseProps } from '..'
import { CustomSelectDropDown, CustomSelectDropDownRow } from '../DropDown'
import { CustomSelectDropDownBasePropsMod, CustomSelectDropDownRowProps } from '../DropDown/types'
import { DisplayTextFuncTy } from '../types'
import { filterCustomSelectSectionsItems, sectionsToArrayForDropDown } from './functions'
import { CustomSelectSectionItems, ItemCustomSelectDropDownRowSection } from './types'
import './customselectsections.sass'
import SearchBar from '../../../baseComponents/SearchBar'
import useTwinTranslation from '../../../utils/hooks/useTwinTranslation'

export type CustomSelectSectionsProps = Modify<CustomSelectBaseProps,  {
    items: CustomSelectSectionItems
    DropDownComponent?: never
    displayTextFunc?: never
}>

const CustomSelectSections: React.FC<CustomSelectSectionsProps> = ({items, ...rest}) => {
    const {sectionItems, displayTextFunc} = useCustomSelectSectionsLogic({items})

    return (
        <CustomSelectBase {...rest} items={sectionItems} DropDownComponent={CustomSelectSectionDropDown} displayTextFunc={displayTextFunc}/>
    )
}

export const CustomSelectSectionsSearchbar: React.FC<CustomSelectSectionsProps> = ({items, ...rest}) => {
    const {sectionItems, displayTextFunc} = useCustomSelectSectionsLogic({items})
    return (
        <CustomSelectBase {...rest} items={sectionItems} DropDownComponent={CustomSelectSectionSearchbarDropDown} displayTextFunc={displayTextFunc}/>
    )
}
interface CustomSelectSectionDropDownLogicProps {
    items: CustomSelectSectionItems
}

const useCustomSelectSectionsLogic = ({items}: CustomSelectSectionDropDownLogicProps) => {
    const {sectionItems, index} = useMemo(() => sectionsToArrayForDropDown(items), [items])

    const displayTextFunc: DisplayTextFuncTy = useCallback((_items, value) => {
        return index[value]?.text || index[value as any]
    }, [index])

    return {sectionItems, displayTextFunc}
}

type CustomSelectSectionDropDownProps = Modify<CustomSelectDropDownBasePropsMod, {
    items: ItemCustomSelectDropDownRowSection[]
}>
const CustomSelectSectionDropDown: React.FC<CustomSelectSectionDropDownProps> = ({ items, children, ...rest }) => {
    const {cancelChange} = useCustomSelectSectionDropDownLogic()
    return (<CustomSelectDropDown items={items} {...rest} rowRenderer={({ ...rowRenderProps }) => {
        const {value, text, isSection} = items[rowRenderProps.index]
        return (
            <CustomSelectDropDownRow currentvalue={rest.value} onChange={isSection ? cancelChange : rest.onChange} item={{ value, text }}  {...rowRenderProps} className={isSection ? 'is_section' : ''}/>
        )
    }}>{children}</CustomSelectDropDown>)
}

export const CustomSelectSectionSearchbarDropDown: React.FC<CustomSelectSectionDropDownProps> = ({ items, ...rest}) => {
    const [searchValue, setSearchValue] = useState('')
    const {t} = useTwinTranslation()
    const filteredItems = filterCustomSelectSectionsItems(searchValue, items)
    return (
        <CustomSelectSectionDropDown {...rest} items={filteredItems}>
            <SearchBar onChange={setSearchValue} searchValue={searchValue} placeholder={t('search', 'Buscar')} className='custom_select_search_bar'></SearchBar>
        </CustomSelectSectionDropDown>
    )
}

const useCustomSelectSectionDropDownLogic = () => {
    const cancelChange: CustomSelectDropDownRowProps['onChange'] = useCallback((_value, e) => {
        e.stopPropagation()
        e.preventDefault()
    }, [])

    return {cancelChange}
}


export default CustomSelectSections