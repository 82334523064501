import { ModalEditComponentProps } from '../../../../../../../../../../baseComponents/ModalsLayouts/types'
import PermissionChecker from '../../../../../../../../../../baseComponents/PermissionChecker'
import EmployeeExpenseEditModal from '../../../../../../../../../../specificComponents/Employee/EmployeeExpenses/EmployeeExpenseEditModal'
import { getUserPermissions } from '../../../../../../../../../../utils/reducers/getters'

interface ModalAdminNotificationExpensesProps extends ModalEditComponentProps {
}

const ModalAdminNotificationExpenses: React.FC<ModalAdminNotificationExpensesProps> = ({ setOpened, allRowData, onSubmit }) => {
    const userPermissions = getUserPermissions('expenses.main')
    
    return (
        <PermissionChecker userPermissions={userPermissions} permission={'read'}>
            <EmployeeExpenseEditModal setOpened={setOpened} onSubmit={onSubmit} allRowData={{ id: allRowData?.SubId }} userPermissions={userPermissions || undefined} />
        </PermissionChecker>
    )
}

export default ModalAdminNotificationExpenses
