import { prepareForSearch, stringMatchWithSearch } from '../../utils/globals/search';
import { InsideTwinFolder, TFSConfig, TFSFileParsedObj, TFSFolderParsedObj, TFSOrderBy, TwinFileFolder, TwinFileFolderParsed, TwinFileS3 } from './types';

export const organizeByFolders = (files: TwinFileS3[], basePath: string, folder: TwinFileFolder = { '/': { folders: {}, files: []} }) => {
    for (const file of files) {
        const fileNameSplit = file.Key.split(basePath+'/')
        const name = fileNameSplit[1]
        if (!name) {
            continue
        }
        const paths = name.split('/')
        let node = folder['/']
        let heredatedPath = ''
        for (const key in paths) {
            const path = paths[key]
            if (!path) {
                continue
            }
            heredatedPath += (path !== '/' ? '/' : '') + path
            const isFolder = parseInt(key) < paths.length-1
            if (isFolder) {
                if (!node.folders[path]) {
                    node.folders[path] = {
                        folders: {},
                        files: []
                    }
                }
                node = node.folders[path]
            } else {
                node.files.push({ ...file, name: path, customPath: heredatedPath })
            }
        }
    }
    return folder
}

export const getContentInsideFolder = (folder: TwinFileFolder | null, config: TFSConfig): TwinFileFolderParsed => {
    if (!folder) {
        return {}
    }
    const myFolder = getCurrentFolderByPath(folder, config.path)
    if (myFolder) {
        const parsedFolders = parseFolders(myFolder.folders, config.path, config.searchbar)
        const parsedFiles = parseFiles(myFolder.files, config.searchbar)
        const ordered = orderFiles(parsedFolders, parsedFiles, config.orderBy)
        return ordered
    }
    return {}
}

export const getCurrentFolderByPath = (folder: TwinFileFolder, path: string, previousLevel = 0) => {
    if (path === '/') {
        return folder[path]
    } else {
        const pathSplit = path.split('/')
        for (const key in pathSplit) {
            const myPath = pathSplit[key]
            const isLast = parseInt(key) >= (pathSplit.length - (1 + previousLevel))
            if (!myPath) {
                if (isLast) {
                    return folder['/']
                }
                folder = folder['/'].folders
            } else {
                if (isLast) {
                    return folder[myPath]
                } else {
                    folder = folder[myPath].folders
                }
            }
        }
    }
}

const parseFolders = (folders: TwinFileFolder, path: string, search: string): TFSFolderParsedObj => {
    let folderPased: TFSFolderParsedObj = {}
    for (const name in folders) {
        if (stringMatchWithSearch(search, name)) {
            folderPased[prepareForSearch(name)] = {name, path: (path === '/' ? '' : path) +'/'+name}
        }
    }
    return folderPased
}
const parseFiles = (files: TwinFileS3[], search: string): TFSFileParsedObj => {
    let filesParsed: TFSFileParsedObj = {}
    for (const file of files) {
        if (file.name && stringMatchWithSearch(search, file.name)) {
            filesParsed[prepareForSearch(file.name)] = file
        }
    }
    return filesParsed
}

const orderFiles = (parsedFolders: TFSFolderParsedObj, parsedFiles: TFSFileParsedObj, orderBy: TFSOrderBy): TwinFileFolderParsed => {
    const unordered = { ...parsedFolders, ...parsedFiles }
    if (orderBy === 'byName') {
        const result = createObjectFromOrderByName(unordered)
        return result
    } else if (orderBy === 'byOldNew') {
        const result = createObjectFromOrderByOld(parsedFiles, true)
        return {...result, ...parsedFolders}
    } else if (orderBy === 'byOldOld') {
        const result = createObjectFromOrderByOld(parsedFiles, false)
        return {...result, ...parsedFolders}
    }
    return unordered
}

const createObjectFromOrderByName = (unordered: TwinFileFolderParsed) => {
    return Object.keys(unordered).sort().reduce(
        (obj: any, key: any) => { 
            obj[key] = unordered[key]; 
            return obj;
            }, 
        {}
    );
}

const createObjectFromOrderByOld = (unordered: TFSFileParsedObj, newsFirst: boolean) => {
    const prepared = prepareObjectForOrderByOld(unordered, newsFirst)
    const result : TwinFileFolderParsed = {}
    for (const element of prepared) {
        result[element[1]] = unordered[element[1]]
    }
    return result
}

const prepareObjectForOrderByOld = (unordered: TFSFileParsedObj, newsFirst: boolean) => {
    const preparedObject: [Date, string][] = []
    for (const name in unordered) {
        const item = unordered[name]
        const date = new Date(item.LastModified)
        if (!preparedObject.length) {
            preparedObject.push([date, name])
            continue
        }
        for (let i = 0; i < preparedObject.length; i++){
            const shallNext = newsFirst ? preparedObject[i][0] > date : preparedObject[i][0] < date
            if (shallNext) {
                if (i >= preparedObject.length-1) {
                    preparedObject.push([date, name])
                    break
                }
            } else {
                preparedObject.splice(i, 0, [date, name])
                break
            }
        }
    }
    return preparedObject
}

export const deleteFirstOrMakeItNull = (files: File[] | null) => {
    if (files) {
        files.shift()
        if (files.length) {
            return files
        }
    }
    return null
}

export const clearLastBar = (moveTo: string) => {
    if (moveTo.length > 1 && moveTo.endsWith('/')) {
       return moveTo.substring(0, moveTo.length -1) 
    } else {
        return moveTo
    }
}

export const appendFileToPath = (oldFiles: TwinFileFolder | null, path: string, name: string) => {
    const myfiles = JSON.parse(JSON.stringify(oldFiles)) || []
    const node = getCurrentFolderByPath(myfiles, path)
    if (node) {
        node.files.push({
            Key: path + name,
            LastModified: new Date().toString(),
            name,
            customPath: path + (path !== '/' ? '/' : '') + name
        })
    }
    return myfiles
}

export const appendFolderToPath = (oldFiles: TwinFileFolder | null, path: string, name: string) => {
    const myfiles = JSON.parse(JSON.stringify(oldFiles))
    const node = getCurrentFolderByPath(myfiles, path)
    let isReplace = true
    if (node) {
        if (!node.folders[name]) {
            isReplace = false
            node.folders[name] = {
                folders: {},
                files: []
            }
        }
    }
    return { myfiles, isReplace }
}

export const modifyKeyToFile = (oldFiles: TwinFileFolder | null, path: string, name: string, Key: string) => {
    const myfiles = JSON.parse(JSON.stringify(oldFiles))
    const node = getCurrentFolderByPath(myfiles, path)
    if (node) {
        for (const file of node.files) {
            if (file.name === name) {
                file.Key = Key
                break
            }
        }
    }
    return myfiles
}

export const deleteFileFromFolder = (oldFiles: TwinFileFolder | null, path: string, name: string) => {
    const myfiles = JSON.parse(JSON.stringify(oldFiles))
    const node = getCurrentFolderByPath(myfiles, path)
    let result = -1
    if (node) {
        for (const key in node.files) {
            if (node.files[key].name === name) {
                result = parseInt(key)
                break
            }
        }
        if (result !== -1) {
            node.files.splice(result, 1)
        }
    }
    return myfiles
}

export const deleteFolderInPath = (oldFiles: TwinFileFolder | null, path: string, name: string, previousLevel = 0) => {
    const myfiles = JSON.parse(JSON.stringify(oldFiles))
    const node = getCurrentFolderByPath(myfiles, path, previousLevel)
    let result = ''
    if (node) {
        for (const key in node.folders) {
            if (key === name) {
                result = key
                break
            }
        }
        if (result !== '') {
            delete node.folders[result]
        }
    }
    return myfiles
}

export const renameFolderInPath = (oldFiles: TwinFileFolder | null, path: string, oldFolderName: string, newFolderName: string, oldPath: string, newPath: string) => {
    const myfiles = JSON.parse(JSON.stringify(oldFiles))
    const node = getCurrentFolderByPath(myfiles, path, 1)
    let result = ''
    if (node) {
        for (const key in node.folders) {
            if (key === oldFolderName) {
                result = key
                const current = node.folders[key]
                modifyAllNamesRecursive(current, oldPath, newPath)
                node.folders[newFolderName] = {
                    ...current
                }
                break
            }
        }
        if (result !== '') {
            delete node.folders[result]
        }
    }
    return myfiles
}

export const modifyAllNamesRecursive = (current: InsideTwinFolder, oldFolderPath: string, newFolderPath: string) => {
    if (current.folders) {
        for (const folderName in current.folders) {
            modifyAllNamesRecursive(current.folders[folderName], oldFolderPath, newFolderPath)
        }
    }
    if (current.files) {
        for (const file of current.files) {
            file.Key = file.Key.replace(oldFolderPath, newFolderPath)
        }
    }
}

export const getLastElementOfPath = (path: string) => {
    if (path !== '/'){
        const pathS = path.split('/')
        const pathLast = pathS.pop()
        return { last: pathLast, otherPath: pathS.join('/') }
    }
    return { last: '/', otherPath: '/' }
}