import { RefObject, useEffect } from 'react';

type UseOnClickOutsideFun = (ref: RefObject<any>, handler: () => void) => void

const useOnClickOutside: UseOnClickOutsideFun = (ref, handler) => {
    useEffect(
        () => {
            const listener = (event: any) => {
                if (!ref || !ref?.current || ref.current.contains(event.target)) {
                    return;
                }
                handler();
            };
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            return () => {
                document.removeEventListener("mousedown", listener);
                document.removeEventListener("touchstart", listener);
            };
        },
        [ref, handler]
    );
}

export default useOnClickOutside