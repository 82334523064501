import { EmployeeModelType } from '@teinor/erp/types/company/userInner/employee'
import { useMemo } from 'react'
import ModalCreateEdit from '../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../../../../../baseComponents/ModalsLayouts/types'
import { FormRender } from '../../../../../../../forms/FormRenderer/types'
import { getDictionaryStates } from '../../../../../../../utils/globals/countries'
import { returnClassOfSpanTemplate } from '../../../../../../../utils/globals/tailwind'
import useCountryState from '../../../../../../../utils/hooks/useCountry'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import { useTypeIdNumber } from '../../../../../../../utils/hooks/useTypeIdNumber'
import { getAllWorkingCenters, getDictionaryCountries, getGroups } from '../../../../../../../utils/reducers/getters'

interface ModalCreateEmployeeProps extends ModalEditComponentProps {}

export type  EmployeeModelExtended = EmployeeModelType & {
    'groups': string
}

const ModalCreateEmployee: React.FC<ModalCreateEmployeeProps> = ({ userPermissions, allRowData, ...rest }) => {
    const { t } = useTwinTranslation()
    const { typesIdNumber } = useTypeIdNumber()
    const { country, setCountry } = useCountryState({ defaultCountry: allRowData?.country })
    const memorizedWC = useMemo(() => getAllWorkingCenters() || {}, [])
    const fields: FormRender<EmployeeModelExtended> = [
        {
            cols: 2,
            elements: [
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'surname',
                    label: t('surname', 'Apellido'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'surname2',
                    label: t('surname2', 'Segundo apellido'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'code',
                    label: t('employeeCode', 'Código de Empleado'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'type_id_number',
                    label: t('typeIdNumber', 'Tipo de documento identificación'),
                    component: 'CustomSelect',
                    items: typesIdNumber,
                    value: 0
                },
                {
                    name: 'id_number',
                    label: t('id_number', 'DNI'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'defaultWorkingCenterId',
                    label: t('usualWorkingCenter', 'Centro de trabajo habitual'),
                    component: 'CustomSelectWithSearchBar',
                    items: memorizedWC
                },
                {
                    name: 'groups',
                    component: 'MultipleCheckboxSelector',
                    subtitles: {
                        subtitleOptions: t('group', 'Grupos'),
                        subtitleSelectedOptions: t('selectedGroups', 'Grupos seleccionados')
                    },
                    items: getGroups() || {},
                    className: returnClassOfSpanTemplate(2)
                },
            ]
        },
        {
            cols: 2,
            title: t('address', 'Dirección'),
            elements: [
                {
                    name: 'address',
                    label: t('address', 'Dirección'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'additional_address',
                    label: t('additional_address', 'Dirección adicional'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'postal_code',
                    label: t('postalCode', 'Código Postal'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'city',
                    label: t('city', 'Ciudad'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'country',
                    label: t('country', 'País'),
                    component: 'CustomSelectWithSearchBar',
                    items: getDictionaryCountries(),
                    value: allRowData?.country,
                    onChange: (value: string) => setCountry(value)
                },
                {
                    name: 'state',
                    label: t('state', 'Provincia'),
                    component: 'CustomSelectWithSearchBar',
                    sortItems: true,
                    items: getDictionaryStates(country),
                    value: allRowData?.state
                },
            ]
        },
        {
            cols: 2,
            title: t('contactInfo', 'Información de contacto'),
            elements: [
                {
                    name: 'email',
                    label: t('email', 'Email'),
                    component: 'InputWithLabelMargin',
                    type: 'email'
                },
                {
                    name: 'phone',
                    label: t('phone', 'Teléfono'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'mobile',
                    label: t('mobile', 'Móvil'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
            ]
        }
    ]

    return (<ModalCreateEdit fields={fields} url={'/api/app/employee/createEmployee'} translations={{
        title: t('createEmployee', 'Crear empleado'),
        button: t('create', 'Crear')
    }} haveButtonPermissions={true} {...rest} />)
}


export default ModalCreateEmployee;