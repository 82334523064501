import { useTranslation } from 'react-i18next'
import { TwinDictionary } from '../globals/dictionary'

export type TwinTranslationTF = (transKey: string, defaultValue: string) => string
export type TwinTranslationTFVars = (transKey: string, defaultValue: string, vars: TwinDictionary) => string

const useTwinTranslation = () => {
    const { t } = useTranslation()
    const myt: TwinTranslationTF = (transKey, defaultValue) => t(transKey, {defaultValue})
    const myTVars: TwinTranslationTFVars = (transKey, defaultValue, vars) => t(transKey, {defaultValue, ...vars})
    return { t: myt, tVars: myTVars }
}

export default useTwinTranslation