import React, { Fragment, useCallback } from 'react'
import { ModalSmallMedium } from '../../../../../../../baseComponents/Modal'
import { SetEditDeleteModalTy, UseEditDeleteModalProps } from '../../../../../../../utils/hooks/useEditDeleteModal'
import TwinTrans from '../../../../../../../baseComponents/TwinTrans'
import { ButtonOrLoader } from '../../../../../../../baseComponents/Button'
import { TwinDictionary } from '../../../../../../../utils/globals/dictionary'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import { getHourFromTimetableDate, parseDateToDayWeekDay } from './functions'
import { RectangularInputHour } from '../../../../../../../forms/Input/InputHour'
import TwinIcon from '../../../../../../../baseComponents/TwinIcon'
import { faPartyHorn, faPlus, faTrashAlt } from '@fortawesome/pro-light-svg-icons'
import { displayFormatedHourFromMins, getHoursMinFromMin, getMinFromHoursMin, parseStringDateHoursToMins } from '../../../../../../../utils/globals/date'
import moment from 'moment'
import { changeErrorMessage, changeSuccessMessage } from '../../../../../../../utils/reducers/reduxDispatch'
import { EmployeeJourneyTyWithErrors } from './types'
import twinFetchPost from '../../../../../../../utils/globals/data'
import useIsLoading from '../../../../../../../utils/hooks/useIsLoading'
import { flushSync } from 'react-dom'
import { EmployeeTimetableModelType } from '@teinor/erp/types/company/userInner/employee/employeeTimetable'
import { checkIsBiggerThanSmaller } from '../../../../Staff/WorkingCalendarParent/WorkingCalendars/SingleWorkingCalendar/Tabs/ScheduleWorkingCalendar/ModalEditScheduleWorkingCalendar/functions'
import { nanoid } from 'nanoid'

interface ModalEditUserTimeControlProps extends ModalEditUserTimeControlLogicProps {}

const ModalEditUserTimeControl: React.FC<ModalEditUserTimeControlProps> = ({openModal, setOpenModal, ...rest}) => {
    const {t} = useTwinTranslation()
    const { checkAllOkAndSubmit, loading } = useModalEditUserTimeControlLogic({ openModal, setOpenModal, ...rest })
    const renderThis: JSX.Element[] = []
    if (openModal?.allRowData && Object.keys(openModal?.allRowData).length)
        for (const key in openModal?.allRowData) {
            const now = new Date()
            const dayDate = new Date(key)
            const day = openModal?.allRowData[key]
            renderThis.push(<EditUserTimeControlSingleDay isFuture={now<dayDate} day={key} data={day} {...{key, setOpenModal}} />)
    }
    return (
        <ModalSmallMedium className='flex flex-col' opened={true} setOpened={() => setOpenModal(null) }  onClickOut={false}>
            <div className=' modal_negative_margin flex flex-auto flex-col'>
                <div className='modal_edit_user_time_control_container flex-auto overflow-auto h-1'>
                    <h2>
                        <TwinTrans transKey='timetable'>Horario</TwinTrans>
                    </h2>
                    {renderThis}
                    <div className='flex justify-end my-20 mr-10'>
                        <ButtonOrLoader onClick={checkAllOkAndSubmit} buttonIsDisabled={loading} textButton={t('save', 'Guardar')} />
                    </div>
                </div>
            </div>
        </ModalSmallMedium>
    )
}

interface ModalEditUserTimeControlLogicProps {
    setOpenModal: SetEditDeleteModalTy
    openModal: UseEditDeleteModalProps
    getCalendarData: () => Promise<void>
    setTableLoaded: React.Dispatch<React.SetStateAction<'loadFirstTableThenEmployee' | 'loadEmployee' | 'loadedAll' | 'justLoadTable'>>
}

const useModalEditUserTimeControlLogic = ({ openModal, setOpenModal, getCalendarData, setTableLoaded }: ModalEditUserTimeControlLogicProps) => {
    const {t} = useTwinTranslation()
    const errorHours = t('conflictInHoursSelected', 'Hay un conflicto en las horas seleccionadas. Revisa los campos en rojo.')
    const successMessage = t('successfullyUpdated', 'Actualizado correctamente')
    const { startLoading, endLoading, loading } = useIsLoading()

    const checkAllOkAndSubmit = useCallback(async () => {
        await flushSync(async () => {
            let hasError = false
            let checked = false
            await setOpenModal((old)=>{
                if(!old){
                    return null
                }
                const copyOld = JSON.parse(JSON.stringify(old))
                for (const key in copyOld.allRowData) {
                    const workDay = copyOld.allRowData[key]
                    if(workDay.timetables){
                        workDay.timetables.sort((a: EmployeeTimetableModelType, b: EmployeeTimetableModelType) => {
                            const startDateA = new Date(a.edited_start_time || a.og_start_time)
                            const startDateB = new Date(b.edited_start_time || b.og_start_time)
                            // @ts-ignore
                            return startDateA - startDateB
                        })
                        let lastDate : number = 0
                        for (const pos in workDay.timetables) {
                            const timetable = workDay.timetables[pos]
                            timetable['errors'] = []
                            if(!timetable.deleted && (timetable.og_end_time || timetable.edited_end_time)){
                                let startHour: any[] = getHourFromTimetableDate(timetable.edited_start_time || timetable.og_start_time)
                                let endHour: any[] = getHourFromTimetableDate(timetable.edited_end_time || timetable.og_end_time)
                                const startHourMin = getMinFromHoursMin(startHour?.[0] || 0, startHour?.[1] || 0)
                                const endHourMin = getMinFromHoursMin(endHour?.[0] || 0, endHour[1] || 0)
                                if (!checkIsBiggerThanSmaller(endHourMin, startHourMin) || lastDate > startHourMin) {
                                    hasError= true
                                    timetable.errors.push(pos)
                                }
                                lastDate = endHourMin
                            }
                        }
                    }
                    if(!hasError){
                        checked = true
                    }
                }
                return {...copyOld}
            })
            if (hasError) {
                changeErrorMessage(errorHours)
                return false
            }
            if (checked) {
                startLoading()
                const sendThis: TwinDictionary = {}
                for (const key in openModal?.allRowData) {
                    const day = openModal?.allRowData[key]
                    if(day.timetables){
                        sendThis[key]= day.timetables
                    }
                }
                const result = await twinFetchPost('/api/app/employee/employeeTimetable/updateAllEmployeeTimetables', { dictJourney: sendThis} )
                if (result) {
                    setTableLoaded('loadFirstTableThenEmployee')
                    endLoading()
                    await getCalendarData()
                    setOpenModal(null)
                    changeSuccessMessage(successMessage)
                }
            }
        })
    }, [setOpenModal, errorHours, successMessage, openModal?.allRowData, getCalendarData, startLoading, endLoading, setTableLoaded])
    return { checkAllOkAndSubmit, loading }
}


interface EditUserTimeControlSingleDayProps {
    day: string
    data: EmployeeJourneyTyWithErrors
    setOpenModal: SetEditDeleteModalTy
    isFuture: boolean
}

const EditUserTimeControlSingleDay: React.FC<EditUserTimeControlSingleDayProps> = ({ data, isFuture,  ...rest }) => {
    const {t} = useTwinTranslation()
    const active = (data.type === 'normalDay' || data.type === 'specialDay' || data.type === 'absenceDay') ? true : false
    let isWorking = false
    if (!isFuture && data.timetables) {
        for (const element of data.timetables) {
            if (!element.edited_end_time && !element.og_end_time) {
                isWorking = true
                break
            }
        }
    }
    return (
        <div className='schedule_day'>
            <HeaderDayTimetables {...{ active, data, ...rest }} />
            {active ?
                isWorking ? <span><TwinTrans transKey='youCantEditTodayIfWorking'>No se puede editar el día de hoy mientras estás trabajando</TwinTrans></span> :
                    !isFuture ? <TableHourDays {...{ data, ...rest }} /> : <span><TwinTrans transKey='thisDayIsNotAvaliableYetForEditing'>Este día todavía no está disponible para ser editado</TwinTrans></span>
                :
                <NoWorkingDayMessage text={data?.type === 'holidayDay' ? t('exHolidayDay', '¡Día vacaciones!') : data?.type === 'freeDay' ? t('exFreeDay', '¡Día festivo!') : t('noWorkDay', 'Día libre') } />
            }
        </div>
    )
}

interface HeaderDayTimetablesProps {
    day: string
    data: EmployeeJourneyTyWithErrors
}

const HeaderDayTimetables: React.FC<HeaderDayTimetablesProps> = ({day, data}) => {
    const { t } = useTwinTranslation()
    const days: TwinDictionary = {
        '0': t('sunday', 'Domingo'),
        '1': t('monday', 'Lunes'),
        '2': t('tuesday', 'Martes'),
        '3': t('wednesday', 'Miércoles'),
        '4': t('thursday', 'Jueves'),
        '5': t('friday', 'Viernes'),
        '6': t('saturday', 'Sábado'),
    }
    const parsedDay = parseDateToDayWeekDay(new Date(day), days)
    return (
        <div className='header_schedule_day'>
            <div className='flex items-center justify-between'>
                <div className='flex items-center mb-15 regular24'>
                    <span>{parsedDay} </span>
                    {data.type === 'specialDay'? 
                        <span> - <TwinTrans transKey='specialDay'>Día especial</TwinTrans></span>
                        : null}
                </div>
            </div>
        </div>
    )
}

interface NoWorkingDayMessageProps {
    text: string
}
const NoWorkingDayMessage: React.FC<NoWorkingDayMessageProps> = ({text}) => {
    return (
        <div>
            <div className='rounded-lg border border-green-43 py-8 text-green-43  '>
                <div className='flex justify-center items-center'>
                    <TwinIcon className='h-24 mr-8' icon={faPartyHorn} />
                    <span className='regular18'>
                        {text}
                    </span>
                </div>
            </div>
        </div>
    )
}

interface TableHourDaysProps extends TableHourDaysLogicProps { }

const TableHourDays: React.FC<TableHourDaysProps> = ({ day, data, setOpenModal }) => {
    const renderThis: JSX.Element[] = []
    const { addHourDayRow, ...rest } = useTableHourDaysLogic({ day, data, setOpenModal })
    let totalMins = 0
    const timetables = data?.timetables
    if (timetables?.length) {
        for (const key in timetables) {
            const timetable = timetables[key]
            if (timetable.og_end_time || timetable.edited_end_time){
                let edited: boolean = false
                if (timetable.edited_start_time || timetable.edited_end_time){
                    edited = true
                }
                const hourStart = parseStringDateHoursToMins(timetable.edited_start_time || timetable.og_start_time)
                const hourEnd = parseStringDateHoursToMins( timetable.edited_end_time || timetable.og_end_time|| '0' )
                if(!timetable.deleted){
                    const diffMins = parseInt(hourEnd) - parseInt(hourStart)
                    totalMins += diffMins
                    const error = timetable?.errors?.includes(key) || false
                    renderThis.push(<HourDayRow pos={parseInt(key)} key={timetable.id || timetable.randomId} {...{ error, edited, hourStart, hourEnd}} {...rest} />)
                }
            }
        }
    }
    const displayTotalHoursFormated = displayFormatedHourFromMins(totalMins)
    return (
        <Fragment>
            <div className='w-full '>
                <div className='flex items-center bg-gray-F7 p-10 px-14 medium14 table_schedule_row'>
                    <div><TwinTrans transKey='startHourS'>Hora inicio</TwinTrans></div>
                    <div><TwinTrans transKey='endHourS'>Hora fin</TwinTrans></div>
                    <div><TwinTrans transKey='totalHours'>Total horas</TwinTrans></div>
                    <div></div>
                </div>
                {renderThis}
                <div className='flex items-center px-14 medium14 h-40 justify-between table_schedule_row'>
                    <div className=''>
                        <TwinTrans transKey='totalCap'>TOTAL</TwinTrans>
                    </div>
                    <div></div>
                    <div>
                        {displayTotalHoursFormated}
                    </div>
                    <div></div>
                </div>
            </div>
            <div className='cursor-pointer text-center my-10 bg-gray-EF py-8 w-full text-gray-51 ' onClick={addHourDayRow}>
                <TwinIcon className='mr-10' icon={faPlus} />
                <TwinTrans transKey='addRow'>Añadir fila</TwinTrans>
            </div>
        </Fragment>
    )
}

interface TableHourDaysLogicProps {
    day: string
    data: EmployeeJourneyTyWithErrors
    setOpenModal: SetEditDeleteModalTy
}

const useTableHourDaysLogic = ({ day, setOpenModal }: TableHourDaysLogicProps) => {
    const deleteHourDayRow = useCallback((pos: number) => {
        setOpenModal((old) => {
            if (!old) {
                return null
            }
            const copyOld = JSON.parse(JSON.stringify(old))
            if (copyOld) {
                copyOld.allRowData[day].timetables[pos] = { ...copyOld.allRowData[day].timetables[pos], deleted: true}
            }
            return { ...copyOld  }
        })        
    }, [setOpenModal, day])

    const addHourDayRow = useCallback(() => {
        setOpenModal((old) => {
            if (!old) {
                return null
            }
            const copyOld = JSON.parse(JSON.stringify(old))
            let timetables = copyOld.allRowData[day].timetables
            if (timetables && timetables.length){
                const lastTime = timetables[timetables.length-1]
                const timeToEdit = lastTime.edited_end_time || lastTime.og_end_time 
                if (timeToEdit) {
                    const time = moment(timeToEdit)
                    const nextHour = time.hour() + 1
                    const newTimeStart = moment(timeToEdit).set('hour', nextHour > 23 ? 0 : nextHour )
                    const newTimeEnd = moment(timeToEdit).set('hour', nextHour > 23 ? 1 : nextHour + 1)
                    timetables.push({ randomId: nanoid(), og_start_time: null, og_end_time: null, edited_start_time: newTimeStart.format('YYYY-MM-DD HH:mm:ss'), edited_end_time: newTimeEnd.format('YYYY-MM-DD HH:mm:ss') } )
                } else {
                    const newStart = moment(day)
                    newStart.set('hour', 9)
                    const newEnd = moment(day)
                    newEnd.set('hour', 10)
                    copyOld.allRowData[day].timetables.push({ randomId: nanoid(),og_start_time: null, og_end_time: null, edited_start_time: newStart.format('YYYY-MM-DD HH:mm:ss'), edited_end_time: newEnd.format('YYYY-MM-DD HH:mm:ss') })
                }
            }else {
                copyOld.allRowData[day].timetables= []
                const newStart = moment(day)
                newStart.set('hour', 9)
                const newEnd = moment(day)
                newEnd.set('hour', 10)
                copyOld.allRowData[day].timetables.push({ randomId: nanoid(),og_start_time: null, og_end_time: null, edited_start_time: newStart.format('YYYY-MM-DD HH:mm:ss'), edited_end_time: newEnd.format('YYYY-MM-DD HH:mm:ss') })
            }
            return { ...copyOld }
        })
    }, [setOpenModal, day])

    const onChangeTime = useCallback((myMins: number, pos: number, isEnd: boolean) => {
        setOpenModal((old)=>{
            if(!old){
                return null
            }
            const copyOld = JSON.parse(JSON.stringify(old))
            if(copyOld){
                const { hours, mins } = getHoursMinFromMin(myMins)
                const row = copyOld.allRowData[day].timetables[pos]
                let date = row.og_start_time?.split(' ')[0] || row.edited_start_time.split(' ')[0]
                if(!isEnd){
                    row.edited_start_time = moment(date + ' ' + hours + ':' + mins + ':00').format('YYYY-MM-DD HH:mm:ss')
                }else {
                    row.edited_end_time = moment(date + ' ' + hours + ':' + mins + ':00').format('YYYY-MM-DD HH:mm:ss')
                }
            }
            return { ...copyOld }
        })
    }, [setOpenModal, day])
    
    return { deleteHourDayRow, addHourDayRow, onChangeTime }
}

interface HourDayRowProps {
    pos: number
    hourStart: string
    hourEnd: string
    edited: boolean
    error: boolean
    onChangeTime: (mins: number, pos: number, isEnd: boolean) => void
    deleteHourDayRow: (pos: number) => void
}

const HourDayRow: React.FC<HourDayRowProps> = ({ hourStart, hourEnd, pos, deleteHourDayRow, onChangeTime, edited, error }) => {
    const totalMin = parseInt(hourEnd) -  parseInt(hourStart)
    return (
        <div className='table_schedule_row flex items-center border-b border-b-gray-EE px-14 py-5'>
            <div>
                <RectangularInputHour className={(error ? 'parent_input_hour_error' : '')} label='' value={String(hourStart)} onFinalChange={(value) => onChangeTime(value, pos, false)} />
            </div>
            <div>
                <RectangularInputHour className={(error ? 'parent_input_hour_error' : '')} label='' value={String(hourEnd)} onFinalChange={(value) => onChangeTime(value, pos, true)} />
            </div>
            <div>
                <span>{displayFormatedHourFromMins(totalMin)}</span>
                <span className='ml-10 text-gray-97'>
                    {edited ? <span>(<TwinTrans transKey='edited'>editado</TwinTrans>)</span> : null}
                </span>
            </div>
            
            <div>
                <TwinIcon className='cursor-pointer hover:text-red-BA' icon={faTrashAlt} onClick={() => deleteHourDayRow(pos)} />

            </div>
        </div>
    )
}


export default ModalEditUserTimeControl