
import { WCalSpecialModelType } from '@teinor/erp/types/company/WCalParent/WCal/WCalSpecial'
import { LittleModalCreateEdit } from '../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../../../../../baseComponents/ModalsLayouts/types'
import { permissionCheck } from '../../../../../../../baseComponents/PermissionChecker/function'
import { FormRender } from '../../../../../../../forms/FormRenderer/types'
import { valueOrDefaultValue } from '../../../../../../../utils/globals/valueOrDefaultValue'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'

interface ModalCEWorkingCalendarSpecialProps extends ModalEditComponentProps {}

const ModalCEWorkingCalendarSpecial: React.FC<ModalCEWorkingCalendarSpecialProps> = ({ userPermissions, allRowData, ...rest }) => {
    const { t } = useTwinTranslation()
    const fields: FormRender<WCalSpecialModelType> = [
        {
            cols: 1,
            elements: [
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'id',
                    component: 'InputHidden'
                }
            ]
        },
    ]
    if (allRowData && userPermissions) {
        const parsedFields = valueOrDefaultValue(fields, userPermissions, allRowData)
        return (<LittleModalCreateEdit fields={parsedFields} url={'/api/app/workingCalendar/workingCalendarSpecial/updateWorkingCalendarSpecial'} translations={{ title: t('editWorkingCalendarSpecial', 'Editar calendario de días especiales'), button: t('save', 'Guardar') }} haveButtonPermissions={permissionCheck(userPermissions, 'update')} {...rest} />)
    }
    return (<LittleModalCreateEdit fields={fields} url={'/api/app/workingCalendar/workingCalendarSpecial/createWorkingCalendarSpecial'} translations={{
        title: t('createWorkingCalendarSpecial', 'Crear calendario de días especiales'),
        button: t('create', 'Crear')
    }} haveButtonPermissions={true} {...rest} />)
}

export default ModalCEWorkingCalendarSpecial
