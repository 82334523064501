import Chart from 'react-apexcharts'
import { DSBSueBarStatusDictionary } from './types'
import { SueStatusTypesInversedKeys, SueModelType, sueStatusTypes } from '@teinor/erp/types/company/sue'
import { useState, useCallback, useEffect } from 'react'
import { twinFetchPostJSON } from '../../../../utils/globals/data'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'

interface DSBSueBarProps {
    title: string
}
const DSBSueBar = ({ title }: DSBSueBarProps) => {
    const { t } = useTwinTranslation()
    const labelStatus: Record<(SueStatusTypesInversedKeys | 'pending' | 'expired'), DSBSueBarStatusDictionary> = {
        closed: {
            color: '#21B35C',
            label: t('sueClosedWidget', 'Cerradas')
        },
        informed: {
            label: t('sueWithoutProcessingWidgets', 'Sin procesar'),
            color: '#BCB0B0'
        },
        processing: {
            label: t('sueInProcessWidget', 'En proceso'),
            color: '#549CF8'
        },
        pending: {
            label: t('sueTotalWithoutResolveWidget', 'Total Sin resolver'),
            color: '#F8AA54'
        },
        expired: {
            label: t('sueTotalExpiredWidget', 'Total Atrasadas'),
            color: '#F85754'
        }
    }
    const { statusData, sues } = useDSBSueBarLogic()
    const arrayForChart: DSBSueBarStatusDictionary[] = [{ ...labelStatus['pending'] }, { ...labelStatus['expired'] }, { ...labelStatus['informed'] }, { ...labelStatus['processing'] }, { ...labelStatus['closed'] }]

    const categories = sues?.length === 0 ? [t('noSuesAvailable', 'No hay denuncias')] : [arrayForChart[0].label, arrayForChart[1].label, arrayForChart[2].label, arrayForChart[3].label, arrayForChart[4].label]

    return (
        <div className='flex-auto h-1 flex flex-col'>
            <h3>{title}</h3>
            <div className='flex-auto h-1'>
                {statusData !== null && statusData ? <Chart options={{
                    chart: {
                        id: 'sue-chart',
                        events: {
                            mounted: (chart) => {
                                chart.windowResizeHandler()
                            }
                        },
                        toolbar: {
                            show: false
                        },
                    },
                    xaxis: {
                        categories
                    },
                    plotOptions: {
                        bar: {
                            distributed: true,
                        }
                    },
                    colors: [arrayForChart[0].color, arrayForChart[1].color, arrayForChart[2].color, arrayForChart[3].color, arrayForChart[4].color]
                }}
                    series={[
                        {
                            name: t('sueBarChartSerie', 'Serie'),
                            data: [statusData['pending'], statusData['expired'], statusData['informed'], statusData['processing'], statusData['closed']]
                        }
                    ]}
                    type='bar'
                    height={'100%'}
                /> : null}
            </div>
        </div>
    )
}

interface DSBSueBarData {
    [key: string]: number
    informed: number
    processing: number
    closed: number
    pending: number
    expired: number
}

const useDSBSueBarLogic = () => {
    const [sues, setSues] = useState<SueModelType[] | null>(null)
    const [statusData, setStatusData] = useState<DSBSueBarData | null>(null)

    const getTotalSueStatus = useCallback((data: SueModelType[]) => {
        let sueStatusLabels: DSBSueBarData = {
            informed: 0,
            processing: 0,
            closed: 0,
            pending: 0,
            expired: 0
        }
        const today = new Date()
        const threeMonthsAgo = new Date().setMonth(today.getMonth() - 3)
        for (const element of data) {
            // @ts-ignore
            if (new Date(element.createdAt) <= threeMonthsAgo && element.status !== 2) {
                sueStatusLabels.expired = sueStatusLabels.expired + 1
            }
            sueStatusLabels[sueStatusTypes[element.status]]++
        }
        sueStatusLabels['pending'] = sueStatusLabels['processing'] + sueStatusLabels['informed']
        setStatusData(sueStatusLabels)
    }, [setStatusData])

    const getData = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/sue/getAllSues', {
            attributes: ['status', 'createdAt']
        })
        if (result) {
            setSues(result)
            getTotalSueStatus(result)
        }
    }, [setSues, getTotalSueStatus])


    useEffect(() => {
        getData()
    }, [getData])
    return { statusData, sues }
}

export default DSBSueBar