import { useCallback, useEffect, useState } from 'react'
import LoadingSpinner from '../../../../baseComponents/LoaderDecider/LoadingSpinner'
import CompanyInformation from './CompanyInformation'
import { twinFetchPostJSON } from '../../../../utils/globals/data'
import { changeCompany } from '../../../../utils/reducers/reduxDispatch'
import UsersCompany from './UsersCompany'
import { useParams } from 'react-router'

interface ManageCompanyProps { }

const ManageCompany: React.FC<ManageCompanyProps> = () => {
    const params = useParams<{id: string}>()
    const id = params.id
    const { loaded } = useManageCompanyLogic(id)
    if (!loaded) {
        return (
            <LoadingSpinner />
        )
    }
    return (
        <div className='flex flex-auto h-0'>
            <CompanyInformation />
            <UsersCompany />
        </div>
    )
}

export const useManageCompanyLogic = (id?: string) => {
    const [loaded, setLoaded] = useState(false)

    const getCompanyData = useCallback(async () => {
        const company = await twinFetchPostJSON('/api/company/getCompany', { id, include: true })
        await changeCompany(company)
        setLoaded(true)
    }, [id])
    useEffect(() => {
        getCompanyData()
    }, [id, getCompanyData])
    
    return { loaded }
}

export default ManageCompany