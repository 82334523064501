import { faCalendar, faCheckCircle, faClock, faEyeSlash, faLayerGroup } from '@fortawesome/pro-light-svg-icons'
import { faCheckCircle as faCheckCircleSolid } from '@fortawesome/pro-solid-svg-icons'
import { Fragment, useCallback, useMemo } from 'react'
import TwinIcon from '../../../../baseComponents/TwinIcon'
import { RowData } from '../../../../baseComponents/TwinTable/types'
import { CustomRenderRoundedImage, CustomRenderRoundedImageProps } from '../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import TwinToolTip from '../../../../baseComponents/TwinToolTip'
import { CustomSelectWithSearchBar } from '../../../../forms/CustomSelect'
import CustomSelectColor from '../../../../forms/CustomSelect/CustomSelectColor'
import { twinFetchPostJSON, twinFetchPostText } from '../../../../utils/globals/data'
import { displayDate, displayDateTime } from '../../../../utils/globals/date'
import { TwinDictionary } from '../../../../utils/globals/dictionary'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import './task_subcomponents.sass'
import { TASK_PROJECT_DEFAULT_COLOR } from '../../variables'

interface CustomRenderTaskNameProps {
    id: string
    name?: string
}

export const CustomRenderTaskName: React.FC<CustomRenderTaskNameProps> = ({ id, name }) => {
    const {t} = useTwinTranslation()
    const taskName = name || t('title', 'Título')
    const idTooltip = useMemo(() => 'Tooltip_CustomRenderTaskName' + String(id), [id])
    return (
        <div className='twin_elipsis custom_render_task_name'>
            <TwinToolTip id={idTooltip} place='top' className='custom_render_task_name_tooltip max-w-600'>
                <p className='text-wrap-initial'>{taskName}</p>
            </TwinToolTip>
            <div data-tooltip-id={idTooltip} className={'cursor-pointer ' + (!name ? 'text-gray-97' : '')}>{taskName}</div>
        </div>
    )
}

interface CustomRenderTaskNameAndDateProps {
    allRowData: RowData
}

export const CustomRenderTaskNameAndDate: React.FC<CustomRenderTaskNameAndDateProps> = ({ allRowData }) => {
    const dateTime = displayDateTime(new Date(allRowData.createdAt))
    return (
        <div className='twin_elipsis'>
            <CustomRenderTaskName name={allRowData?.name} id={String(allRowData?.id)} />
            <div className='light12 text-gray-97'>
                <TwinIcon icon={faCalendar} className='mr-10' />
                <span>{dateTime}</span>
                {allRowData?.start_date ?
                    <Fragment>
                        <TwinIcon icon={faClock} className='mr-10 ml-16' />
                        <span>{displayDateTime(new Date(allRowData.start_date))}</span>
                    </Fragment>
                    : null
                }
                {allRowData?.remember_date ?
                    <Fragment>
                        <TwinIcon icon={faEyeSlash} className='mr-10 ml-16' />
                        <span>{displayDate(new Date(allRowData.remember_date))}</span>
                    </Fragment>
                    : null
                }
            </div>
        </div>
    )
}


interface CustomRenderTaskCFieldProps extends CustomRenderTaskCFieldLogicProps {
    dictTaskCFieldValues: TwinDictionary
    customFieldId: string
    id?: string
}

export const CustomRenderTaskCField: React.FC<CustomRenderTaskCFieldProps> = ({ allRowData, dictTaskCFieldValues, id , customFieldId}) => {
    const {t} = useTwinTranslation()
    const { updateTaskCField } = useCustomRenderTaskCFieldLogic({ allRowData })
    const value = allRowData?.dictTaskCFieldVals?.[customFieldId]?.TaskCFieldValueId || ''
    const isOpened = id !== '' && id !== 'main'
    const itemSelected = dictTaskCFieldValues[value]
    const message = itemSelected?.name !== '---'? itemSelected?.name : t('notAssigned', 'Sin seleccionar')
    const idTooltip = useMemo(() => 'Task_' + allRowData?.id + '_CField_' + customFieldId, [allRowData?.id, customFieldId])
    return (
        <div className='w-full' onClick={(e)=>{e.preventDefault(); e.stopPropagation()}}>
            <CustomSelectColor className={'custom_render_taskCField custom_select_label_up w-full bg-gray-F7 rounded-full px-4 ' + (isOpened ? 'custom_render_taskCField_short' : '')} label='' items={dictTaskCFieldValues} onChange={(value) => updateTaskCField(value, dictTaskCFieldValues[value].TaskCFieldId)} {...{ value }} extraHeaderParams={{ displayToolTip: true, idTooltip, message }} fieldName={isOpened ? 'shortVersion': 'name'} />
        </div>
    )
}


interface CustomRenderTaskCFieldLogicProps {
    allRowData: RowData
}

const useCustomRenderTaskCFieldLogic = ({ allRowData }: CustomRenderTaskCFieldLogicProps) => {

    const updateTaskCField = useCallback(async (TaskCFieldValueId: number, TaskCFieldId: number) => {
        await twinFetchPostJSON('/api/app/task/assignCustomFieldValueToTask', { TaskId: allRowData.id, TaskCFieldValueId, TaskCFieldId })
    }, [allRowData])

    return { updateTaskCField }
}


interface CustomRenderTaskCustomerAndProjectProps {
    allRowData: RowData
    id?: string
}

export const CustomRenderTaskCustomerAndProject: React.FC<CustomRenderTaskCustomerAndProjectProps> = ({ allRowData, id }) => {
    const idTooltip = useMemo(() => 'Tooltip_CustomRenderTaskCustomerAndProject' + String(allRowData?.id), [allRowData?.id])
    if (!allRowData.CustomerProject) {
        return <div> -- </div>
    }
    const customerId = allRowData?.CustomerProject?.Customer?.code || allRowData?.CustomerProject?.Customer?.id
    const customerName = allRowData?.CustomerProject?.Customer?.name
    const projectName = allRowData?.CustomerProject?.name
    const color = allRowData?.CustomerProject?.CustomerProjectType?.color
    const displayText = customerId + ' - ' + customerName
    const displayTextTooltip = customerId + ' - ' + customerName + ' > ' + projectName
    return (
        <div className='w-full'>
            <TwinToolTip id={idTooltip} place='top' content={displayTextTooltip} positionStrategy='fixed' />
            <div data-tooltip-id={idTooltip}  className='flex items-center' >
                <span className='mr-10 h-10 w-10 rounded-full block' style={{ backgroundColor: color || TASK_PROJECT_DEFAULT_COLOR }}></span> 
                { id === 'main' || id === '' ? <span className='flex-auto w-1 twin_elipsis'>{displayText}</span>: null}
            </div>
        </div>
    )
}
interface CustomRenderTaskCustomerProjectProps {
    allRowData: RowData
}

export const CustomRenderTaskCustomerProject: React.FC<CustomRenderTaskCustomerProjectProps> = ({ allRowData }) => {
    const idTooltip = useMemo(() => 'Tooltip_CustomRenderTaskCustomerProject' + String(allRowData?.id), [allRowData?.id])

    if (!allRowData.CustomerProject) {
        return <div> -- </div>
    }
    const color = allRowData?.CustomerProject?.CustomerProjectType?.color
    const projectName = allRowData?.CustomerProject?.name
    const customerIdName = allRowData?.CustomerProject.id + ' - ' + projectName
    return (
        <div>
            <TwinToolTip id={idTooltip} place='top' content={customerIdName} positionStrategy='fixed' />
            <div data-tooltip-id={idTooltip}  className='flex items-center' >
                <span className='mr-10 h-10 w-10 rounded-full block' style={{ backgroundColor: color || TASK_PROJECT_DEFAULT_COLOR }}></span> 
                <span>{customerIdName}</span>
            </div>
        </div>
    )
}


interface CustomRenderTasksFinishedProps extends CustomRenderTaskFinishedLogicProps {
    value: boolean
    width?: number
    allRowData: RowData
}

export const CustomRenderTasksFinished: React.FC<CustomRenderTasksFinishedProps> = ({ value, width, allRowData, ...rest }) => {
    const {t} = useTwinTranslation()
    const idTooltip = useMemo(() => 'Tooltip_CustomRenderTasksFinished' + String(allRowData?.id), [allRowData?.id])
    const { handleFinish } = useCustomRenderTaskFinishedLogic({...rest})
    return (
        <div className='flex items-center w-full custom_render_boolean cursor-pointer' onClick={(e) => handleFinish(value, allRowData, e)}>
            <TwinToolTip id={idTooltip} place='top' content={value? t('unfinishTask','Reabrir tarea') : t('finalizeTask', 'Finalizar Tarea')} positionStrategy='fixed' />
            <div data-tooltip-id={idTooltip} className='text-center' style={{ width: width ?? '100%' }}>
                <TwinIcon icon={value ? faCheckCircleSolid  : faCheckCircle} className={value ? 'text-green-43' : 'text-gray-9D'} size='lg' />
            </div>
        </div>
    )
}

type HandleFinishTaskClick = (parameterValue: boolean, allRowData: RowData, e: React.MouseEvent) => void

interface CustomRenderTaskFinishedLogicProps {
    onChange?:(val: boolean)=> void
}
const useCustomRenderTaskFinishedLogic = ({ onChange }: CustomRenderTaskFinishedLogicProps) => {

    const handleFinish = useCallback<HandleFinishTaskClick>(async (parameterValue, allRowData, e) => {
        e.preventDefault()
        e.stopPropagation()
        await twinFetchPostText('/api/app/task/updateTask', { id: allRowData.id, finished: !parameterValue })
        onChange?.(!parameterValue)
    }, [onChange])

    return { handleFinish }
}

interface CustomRenderTaskIconsProps {
    allRowData: RowData
}

export const CustomRenderTaskIcons: React.FC<CustomRenderTaskIconsProps> = ({ allRowData }) => {
    const renderThis: JSX.Element[] = []
    const className = allRowData?.ChildrenTasks?.length && allRowData?.remember_date?.length ? 'mr-8' : ''
    if (allRowData?.ChildrenTasks?.length) {
        renderThis.push(<TwinIcon icon={faLayerGroup} className={className} />)
    }
    if (allRowData?.remember_date?.length) {
        renderThis.push(<TwinIcon icon={faEyeSlash} />)
    }
   
    return (
        <div className='flex items-center'>
            {renderThis}
        </div>)
}

type CustomRenderTaskRoundImageProps = CustomRenderTaskRoundImageLogicProps & CustomRenderRoundedImageProps & {
    items: TwinDictionary
    value?: number
}
const defaultInfo = {
    'EmployeeId':  '/user-png.png',
    'GroupId':  '/group.png'
}

export const CustomRenderTaskRoundImage: React.FC<CustomRenderTaskRoundImageProps> = ({ items, value, id, field, onChange, icon, ...rest }) => {
    const { onSelectValue } = useCustomRenderTaskRoundImageLogic({ id, field, onChange })
    if (value && !rest.img) {
        rest.img = defaultInfo[field]
    }
    const extraHeaderParams = { icon, img: rest.img }
    return (
        <div className='relative custom_render_task_round_image' onClick={(e) => { e.preventDefault(); e.stopPropagation()}}>
            <CustomSelectWithSearchBar label='' CustomSelectHeader={RoundedImage} items={items} onChange={onSelectValue} sortItems={true}  {...{ extraHeaderParams }} {...rest} />
        </div>
    )
}

interface RoundedImageProps { }

const RoundedImage: React.FC<RoundedImageProps> = ({ extraHeaderParams, ...rest }: any) => {
    const img = extraHeaderParams?.img || ''
    return (
        <CustomRenderRoundedImage className='cursor-pointer h-34 w-34' {...rest} icon={extraHeaderParams.icon} img={img || ''} />
    )
}

interface CustomRenderTaskRoundImageLogicProps {
    id: number
    field: 'EmployeeId' | 'GroupId'
    onChange?:(value: number)=>void
}

const useCustomRenderTaskRoundImageLogic = ({id, field, onChange}: CustomRenderTaskRoundImageLogicProps) => {

    const onSelectValue = useCallback(async(value: any) => {
        await twinFetchPostText('/api/app/task/updateTask', {id, [field]: value})
        onChange?.(value)
    }, [id, field, onChange])

    return { onSelectValue }
}

interface CustomRenderWorkingCenterProps {
    allRowData: RowData
    id?: string
}

export const CustomRenderWorkingCenter: React.FC<CustomRenderWorkingCenterProps> = ({ allRowData, id }) => {
    const { t } = useTwinTranslation()
    const idTooltip = useMemo(() => 'Tooltip_CustomRenderWorkingCenter' + String(allRowData?.id), [allRowData?.id])
    let infoWorkingCenter = allRowData?.WorkingCenter?.name || t('notSelected', 'Sin seleccionar')
    let nameWorkingCenter = allRowData?.WorkingCenter?.name ||  '--'
    if (id !== '' && id !== 'main') {
        nameWorkingCenter = nameWorkingCenter.charAt(0).toUpperCase() || ''
    }
    return (
        <div>
            <TwinToolTip id={idTooltip} place='top' content={infoWorkingCenter} positionStrategy='fixed' />
            <div  className='flex items-center' >
                <div data-tooltip-id={idTooltip} className={'twin_elipsis ' + (!allRowData?.WorkingCenterId ? 'text-gray-97' : '')}>{nameWorkingCenter}</div>
            </div>
        </div>
    )
}