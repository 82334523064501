import './auth.sass'
export interface AuthCompositionProps {
    children: React.ReactNode
};

const AuthComposition: React.FC<AuthCompositionProps> = ({children}) => {
    return (
        <div className='h-full w-full flex'>
            <LeftLoginSide>
                {children}
            </LeftLoginSide>
            <RightImage />
        </div>
    );
}

interface LeftLoginSideProps {
    children: React.ReactNode
}

export const LeftLoginSide: React.FC<LeftLoginSideProps> = ({children}) => {
    return (
        <div className='auth_leftbar bg-gray-F7 items-center justify-center flex'>
            <div className='auth_whitebox'>
                {children}
            </div>
        </div>
    )
}

interface RightImageProps { }

export const RightImage: React.FC<RightImageProps> = () => {
    return (
        <div className='auth_image flex-col flex-auto flex items-bottom justify-end bg-gradient-to-br from-green-auth-start to-green-auth-end '>
            <picture className='max-h-full'>
                <source srcSet='/ilustracion_movil.svg' className='max-h-full' media='(max-width: 1000px)' type='image/svg+xml' />
                <img src='/foto_panel.svg' className='max-h-full w-full' alt='auth_image' />
            </picture>
        </div>
    )
}

export default AuthComposition