import { FormElementValues } from '../../../../../forms/TwinForm/types'

export const checkPassword = (vals: FormElementValues) => {
    if (vals.password !== vals.confirmPassword) {
        return false
    }
    return true
}

export const checkPasswordLength = (vals: FormElementValues) => {
    if (vals.password.length < 8) {
        return true
    }
    return false
}