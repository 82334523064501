import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { calculateTopOfElement } from '../../../../../utils/globals/components'
import { CurrentPagePayload } from '../../../../../utils/reducers/globals/currentPage'
import { joinAndRemoveDuplicatedBars } from '../../../../CreateRoutering/functions'
import { RouteChild, Routes } from '../../../../CreateRoutering/types'
import TwinIcon from '../../../../TwinIcon'

interface OpenedMenuDropDownProps extends DropDownChildItemsBaseProps {
    route: RouteChild
    onClick: () => void
}

const OpenedMenuDropDown: React.FC<OpenedMenuDropDownProps> = ({ route, currentPage, ...rest }) => {
    const [open, setOpen] = useState<boolean>(currentPage?.routes[1] === route.link)
    return (
        <div className='mb-20 left_menu_section'>
            <div className='flex items-center justify-between cursor-pointer' onClick={() => setOpen(!open)}>
                <span className='medium14'>{route.text}</span>
                <DropDownIcon {...{ open }} />
            </div>
            <DropDownChildItems {...{ open, currentPage, routes: route.childs, ...rest }} parentActive={currentPage?.routes[1] === route.link} />
        </div>
    )
}

interface DropDownIconProps {
    open: boolean
}

const DropDownIcon: React.FC<DropDownIconProps> = ({ open }) => {
    if (open) {
        return (
            <TwinIcon icon={faChevronUp} className='text-white opacity-50' />
        )
    } else {
        return (
            <TwinIcon icon={faChevronDown} className='text-white opacity-50' />
        )
    }
}
interface DropDownChildItemsBaseProps {
    routes?: Routes
    currentPage: CurrentPagePayload
    parentLink: string
}

interface DropDownChildItemsProps extends DropDownChildItemsBaseProps {
    open: boolean
    parentActive?: boolean
    onClick: () => void
}
const ROW_HEIGHT = 16
const GAP_BTW_ROWS = 6
type ActiveKey = number | false
const DropDownChildItems: React.FC<DropDownChildItemsProps> = ({ parentActive, open, routes, currentPage, parentLink, onClick }) => {
    let active: ActiveKey = false
    if (open) {
        const renderDropDownItems: JSX.Element[] = []
        for (const key in routes) {
            const item = routes[parseInt(key)]
            let itemTo = item.to
            if (itemTo) {
                itemTo = joinAndRemoveDuplicatedBars(parentLink, itemTo)
            }
            const link = joinAndRemoveDuplicatedBars(parentLink, item.link)
            const isActive = currentPage?.routes[2] === item.link && parentActive ? true : false
            if (isActive) {
                active = parseInt(key)
            }
            const Component = DropDownDecider({isActive})
            renderDropDownItems.push(<Component text={item.text} link={itemTo ?? link} key={link} onClick={onClick} />)
        }
        return (
            <div className='flex mt-8 relative'>
                <LeftColorBar active={active}/>
                <div className='ml-12 dropdown_secondary_menu_items'>
                    {renderDropDownItems}
                </div>
            </div>
        )
    }
    return null
}

interface LeftColorBarProps {
    active: ActiveKey
}

const LeftColorBar: React.FC<LeftColorBarProps> = ({active}) => {
    return (
        <div className='h-full secondary_menu_active absolute'>
            <div className={'secondary_menu_bar ' + (active !== false ? 'active' : '')} style={{top: calculateTopOfElement(ROW_HEIGHT, GAP_BTW_ROWS, active)}}></div>
        </div>
    )
}

interface DropDownDeciderProps {
    isActive: boolean
}

const DropDownDecider = ({isActive}: DropDownDeciderProps) => {
    if (isActive) {
        return DropDownItemChildActive
    }
    return DropDownItemChildNotActive
}

interface DropDownItemChildProps{
    text: string
    link: string
    onClick: () => void
}

export const DropDownItemChildActive: React.FC<DropDownItemChildProps> = ({ link, text, onClick }) => {
    return (
        <Link to={link} className='medium12' onClick={onClick}>
            {text}
        </Link>
    )
}

export const DropDownItemChildNotActive: React.FC<DropDownItemChildProps> = ({ text, link, onClick }) => {
    return (
        <Link to={link} className='light12' onClick={onClick}>
            {text}
        </Link>
    )
}

export default OpenedMenuDropDown