import { TwinTranslationTF } from '../hooks/useTwinTranslation'
import { getActiveCurrencies, getAllConversions, getAllCurrencies, getConfigParam, getPreparePricesConfigParam } from '../reducers/getters'
import { SpecificRulesProduct } from '@teinor/erp/prices/types'
import { preparePrices, roundNumberByParams } from '@teinor/erp/prices'
import { TwinParameters } from '@teinor/erp/types/company/config/parameter'
import { displayFormatedNumber } from './numbers'
import { CalculateParamsTy } from '../../baseComponents/Displays/DisplayPrice/types'

export const getCurrencySymbol = (isoCode: string) => {
    return getAllCurrencies()[isoCode]?.symbol
}
export const twinPreparePrice = (prices: SpecificRulesProduct[]) => {
    const roundingPriceType = getConfigParam('roundingPriceType') as TwinParameters['roundingPriceType']
    const formulaCalculation = getConfigParam('formulaCalculation') as TwinParameters['formulaCalculation']
    return preparePrices(prices, getAllConversions(), getActiveCurrencies(), { roundingPriceType, formulaCalculation })
}
export const prepareVatTag = (with_vat: boolean, t: TwinTranslationTF) => {
    if (with_vat) {
        return t('withVat', 'IVA Inc.')
    } else {
        return t('withoutVat', 'IVA Exc.')
    }
}

export const configurePrice = (value: number, CurrencyId: string) => {
    const calculateParams = getPreparePricesConfigParam() as CalculateParamsTy
    const myValue = roundNumberByParams(value, calculateParams.rounding, { roundingPriceType: calculateParams.roundingPriceType })
    const valueParsed = isNaN(myValue) ? 0 : myValue
    const finalValue = valueParsed.toFixed(calculateParams.decimals)
    const priceParsed = displayFormatedNumber(finalValue)
    const CurrencySymbol = getCurrencySymbol(CurrencyId)
    return priceParsed + ' ' + CurrencySymbol
}