import { LittleModalCreateEdit } from '../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { FormRender } from '../../../../../../forms/FormRenderer/types'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import {LangModelType} from '@teinor/erp/types/company/config/lang' 
import { getAllLangs, getActiveLangs } from '../../../../../../utils/reducers/getters'
import { ModalComponentProps } from '../../../../../../baseComponents/ModalsLayouts/types'
import { filterDictionaryNotInItems } from '../../../../../../utils/globals/dictionary'

interface ModalCreateLanguageProps extends ModalComponentProps{}

const ModalCreateLanguage: React.FC<ModalCreateLanguageProps> = ({ ...rest }) => {
    const activeLangs = Object.keys(getActiveLangs())
    const items = filterDictionaryNotInItems(getAllLangs(), activeLangs)
    const { t } = useTwinTranslation()
    const fields: FormRender<LangModelType> = [
        {
            cols: 1,
            elements: [
                {
                    name: 'id',
                    label: t('language', 'Idioma'),
                    component: 'CustomSelectWithSearchBar',
                    items,
                    required: true
                },
            ]
        },
    ]
    
    return (<LittleModalCreateEdit fields={fields} url={'/api/app/config/lang/createLang'} translations={{
        title: t('createLanguage', 'Crear Idioma'),
        button: t('create', 'Crear')
    }} haveButtonPermissions={true} {...rest} />)
}

export default ModalCreateLanguage