import { faPaperclip, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { nanoid } from 'nanoid';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import TwinIcon from '../../../baseComponents/TwinIcon';
import { ImageInputFile } from '../../ImageInput/types';

export interface FilesClipInputProps extends FilesClipInputLogicProps {
    className?: string
    label?: string
    readOnly?: boolean
}

const FilesClipInput: React.FC<FilesClipInputProps> = ({ defaultValue, onChange, name, className }) => {
    const { files, getRootProps, getInputProps, id, deleteFiles } = useFilesClipInputLogic({ defaultValue, onChange })
    return (
        <div className={(className || '') + ' file_input file_clip_input'}>
            {files.length ?
                <div className='flex flex-auto items-center mr-10'>
                    <span className='twin_elipsis'>{files?.[0].name}</span>
                    <TwinIcon className='ml-5 cursor-pointer' icon={faTrashCan} onClick={deleteFiles} />
                </div>
                :
                <div {...getRootProps({ className: 'dropzone' })} className='flex items-center cursor-pointer ' >
                    <TwinIcon className='mr-10 w-16 h-19 text-gray-63' icon={faPaperclip} />
                </div>
            }
            <input id={id} name={name} title='img' {...getInputProps()} />
        </div>
    )
}

interface FilesClipInputLogicProps {
    defaultValue?: string
    name?: string
    onChange?: (file: ImageInputFile[]) => void
    emptyAfterChange?: boolean
}

const useFilesClipInputLogic = ({ defaultValue, onChange, emptyAfterChange = false }: FilesClipInputLogicProps) => {
    const [files, setFiles] = useState<ImageInputFile[]>(defaultValue ? [{ name: defaultValue, preview: defaultValue }] : []);
    const [id] = useState(nanoid())
    const { getRootProps, getInputProps, inputRef } = useDropzone({
        useFsAccessApi: false,
        accept: {
            'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
            'application/zip': ['.zip'],
            'application/pdf': ['.pdf']
        },
        maxSize: 2000000,
        onDrop: acceptedFiles => {
            const myAcceptedFiles = acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }))
            setFiles([...myAcceptedFiles]);
            if (onChange) {
                onChange([...myAcceptedFiles])
                if (emptyAfterChange) {
                    setFiles([])
                }
            }
        }
    })
    const deleteFiles = useCallback(() => {
        if (onChange) {
            onChange([])
        }
        setFiles([])
        const myInputRef = inputRef.current
        if (myInputRef) {
            myInputRef.value = ''
        }
    }, [setFiles, onChange, inputRef])

    useEffect(() => () => {
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    return { files, id, getRootProps, getInputProps, deleteFiles }
}

export default FilesClipInput