import { ModalOpenedSetTy } from '../../../../../../../../../../baseComponents/Modal/types'
import ModalDeleteFilled from '../../../../../../../../../../baseComponents/ModalsLayouts/ModalDeleteFilled'
import { ModalEditComponentProps } from '../../../../../../../../../../baseComponents/ModalsLayouts/types'
import { useFetchText } from '../../../../../../../../../../utils/hooks/useFetchText'
import useTwinTranslation from '../../../../../../../../../../utils/hooks/useTwinTranslation'

interface ModalDeleteEmployeeExpensesProps extends ModalEditComponentProps { 
    setOpenNotAcceptModal: ModalOpenedSetTy
}

const ModalDeleteEmployeeExpenses: React.FC<ModalDeleteEmployeeExpensesProps> = ({ setOpened, onSubmit, allRowData, setOpenNotAcceptModal }) => {
    const { t } = useTwinTranslation()

    const { handleFetchText } = useFetchText({
        onSubmit,
        url: '/api/app/employeeExpense/updateEmployeeExpense',
        data: {
            ...allRowData,
            id: allRowData?.id,
            status: 2,
        }
    })
    return (
        <ModalDeleteFilled opened={true} setOpened={setOpenNotAcceptModal} onAccept={async () => { await handleFetchText(); setOpened(null) }} translations={{
            title: t('sureDenyEmployeeExpense', '¿Seguro que quieres denegar la ficha de gastos de este empleado?'),
            subtitle: t('onceCanceledNotRecoverM', 'Una vez cancelado no podrás recuperarlo'),
            buttonAccept: t('deny', 'Denegar'),
        }} />
    )
}

export default ModalDeleteEmployeeExpenses