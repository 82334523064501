import { faFileAlt, faTrashCan } from '@fortawesome/pro-light-svg-icons'
import { faFolder } from '@fortawesome/pro-solid-svg-icons'
import React, { Fragment, useState } from 'react'
import { numberToFileSize } from '../../../utils/globals'
import { displayDate } from '../../../utils/globals/date'
import useTwinTranslation from '../../../utils/hooks/useTwinTranslation'
import LoadingSpinner from '../../LoaderDecider/LoadingSpinner'
import { ModalOpenedTy } from '../../Modal/types'
import ModalDeleteFilled from '../../ModalsLayouts/ModalDeleteFilled'
import TwinIcon from '../../TwinIcon'
import TwinTrans from '../../TwinTrans'
import { TFSChangePathTy, TFSFolderParsed, TFSItemParsed, TwinFileFolderParsed, TwinFileS3 } from '../types'
import { ComponentWithPermissions } from '../../CreateRoutering/types'
import PermissionChecker from '../../PermissionChecker'

interface TFSInsideFuncs {
    deleteFile: (filePath: string) => void
    downloadFile: (filePath: string, e: React.MouseEvent) => void
}
type TFSInsideProps = TFSInsideFuncs & ComponentWithPermissions & {
    content: TwinFileFolderParsed
    changePath: TFSChangePathTy
    loadingFiles: string[]
}

const TFSInside: React.FC<TFSInsideProps> = ({ content, changePath, loadingFiles, ...rest }) => {
    const renderThis: JSX.Element[] = []
    for (const key in content) {
        const item = content[key]
        const Component = TFSDecider({ item, isLoading: loadingFiles.includes(content[key].name || '') })
        // @ts-ignore
        renderThis.push(<Component item={item} key={key} changePath={changePath} {...rest}></Component>)
    }
    return (
        <div className='pt-40 grid-cols-5 gap-30 grid overflow-auto h-1 flex-auto auto-rows-min tfs_inside'>
            {renderThis}
        </div>
    )
}

interface TFSBoxProps {
    onClick?: (e: React.MouseEvent) => void
    className?: string
    children?: React.ReactNode
}

const TFSBox: React.FC<TFSBoxProps> = ({children, onClick, className}) => {
    return (
        <div className={'tfs_box cursor-pointer ' + (className || '')} onClick={onClick}>
            <div className='tfs_box_inner'>
                {children}
            </div>
        </div>
    )
}

type TFSDeciderProps = {
    item: TFSItemParsed
    isLoading: boolean
}

const TFSDecider = ({ item, isLoading }: TFSDeciderProps) => {
    if ('path' in item) {
        return TFSFolder
    } else if (isLoading) {
        return TFSFileLoading
    }
    return TFSFile
}

interface TFSFolderProps {
    item: TFSFolderParsed
    changePath: TFSChangePathTy
}

const TFSFolder: React.FC<TFSFolderProps> = ({ item, changePath }) => {
    return (
        <TFSBox onClick={() => changePath(item.path)}>
            <div className='p-14'>
                <TwinIcon icon={faFolder} style={{width: '56px', height: '42px'}} />
                <span>{item.name.length > 25 ? (item.name.substring(0, 25) +'...') : item.name}</span>
            </div>
        </TFSBox>
    )
}

type TFSFileProps = TFSInsideFuncs & ComponentWithPermissions & {
    item: TwinFileS3
}

const TFSFile: React.FC<TFSFileProps> = ({ item, deleteFile, downloadFile, userPermissions }) => {
    const {t} = useTwinTranslation()
    const { opened, setOpened } = useTFSFileLogic()
    
    if (!item.name) {
        return null
    }

    const createdAt = displayDate(new Date(item.LastModified))
    const filename = item.name || ''
    const customPath = item.customPath || ''
    return (
        <Fragment>
            <TFSBox className='file' onClick={(e) => downloadFile(customPath, e)}>
                <div className='p-14'>
                    <TwinIcon icon={faFileAlt} style={{width: '33px', height: '45px'}}/>
                    <span className='tfs_name'>{item.name.length > 25 ? (item.name.substring(0, 25) + '...') : item.name}</span>
                    <a className='hidden tfs_link' download={true} href='/' target='_blank' onClick={(e) => e.stopPropagation()}>a</a>
                </div>
                <div className='hidden'>
                    <span className='gray'><TwinTrans transKey={'createdAt'}>Creado el</TwinTrans> {createdAt}</span>
                    <span className='gray'>{numberToFileSize(item.Size || 0)}</span>
                    <PermissionChecker userPermissions={userPermissions} permission='delete'>
                        <div className='tfs_delete_icon' onClick={(e) => { e.stopPropagation(); setOpened(true) }}>
                            <TwinIcon icon={faTrashCan} style={{ height: '19px', width: '19px' }}></TwinIcon>
                            <span className='ml-10 mt-2'><TwinTrans transKey='delete'>Eliminar</TwinTrans></span>
                        </div>
                    </PermissionChecker>
                </div>

            </TFSBox>
            {opened && <ModalDeleteFilled opened={opened} setOpened={setOpened} onAccept={() => deleteFile(customPath)} translations={{
                title: t('youSureOfDeleteFilev2', '¿Estás seguro que quieres eliminar el fichero') + ' ' + filename + '?',
                subtitle: t('thisFileWillDeletePerma', 'Este fichero se eliminará de forma permanente')
            }} />}
        </Fragment>
    )
}

const useTFSFileLogic = () => {
    const [opened, setOpened] = useState<ModalOpenedTy>(null)

    return {opened, setOpened}
}


interface TFSFileLoadingProps {
    item: TwinFileS3
}

const TFSFileLoading: React.FC<TFSFileLoadingProps> = ({ item }) => {
    if (!item.name) {
        return null
    }
    return (
        <TFSBox>
            <div className='p-14'>
                <LoadingSpinner />
                <span>{item.name.length > 25 ? (item.name.substring(0, 25) +'...') : item.name}</span>
            </div>
        </TFSBox>
    )
}




export default TFSInside