import { PdfTemplateModelType } from '@teinor/erp/types/company/config/pdfTemplate'

export interface PdfTemplatesAction {
    type: 'CHANGE_PDFTEMPLATE'
    payload: PdfTemplateModelType[]
}
export type PdfTemplatePayload = { [id: number]: PdfTemplateModelType } | null

export default function pdfTemplates(state: PdfTemplatePayload = null, action: PdfTemplatesAction): PdfTemplatePayload {
    switch (action.type) {
        case 'CHANGE_PDFTEMPLATE':
            return state = action.payload || []
        default:
            return state
    }
}