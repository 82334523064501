import { faFile, faCalendarDays, faListCheck } from '@fortawesome/pro-light-svg-icons'
import { useState, useMemo, useCallback, useEffect } from 'react'
import { haveModuleBuyed } from '../../../utils/globals'
import { twinFetchPostJSON } from '../../../utils/globals/data'
import useTwinTranslation from '../../../utils/hooks/useTwinTranslation'
import { getEmployeeParams, getAllGroupPermissions, getModulesBuyed } from '../../../utils/reducers/getters'
import { changeEmployeeParams } from '../../../utils/reducers/reduxDispatch'
import { getMaxPermissionByGroupPermissions } from '../../CreateRoutering/functions'
import DSBSueBar from '../Components/DSBSueBar'
import DSBSueCircle, { DSBSueCircleExpired } from '../Components/DSBSueCircle'
import HolidayCalendar from '../Components/HolidayCalendar'
import LastEmployeeTimetable from '../Components/LastEmployeeTimetable'
import WeekHolidayTable from '../Components/WeekHolidayTable'
import { DashboardWidgetAndFamiliesExtraComplete, UserWidget, WidgetFamiliesDictionary, WidgetDictionary } from './types'
import DSBAllTasksTable from '../Components/DSBAllTasksTable'
import moment from 'moment'
import DSBMyTasksTable from '../Components/DSBMyTasksTable'

export interface UseDashboardWidgetsProps {
    name: string
    defaultWidgets: string
}
const useDashboardWidgets = ({name, defaultWidgets}: UseDashboardWidgetsProps): DashboardWidgetAndFamiliesExtraComplete => {
    const { t } = useTwinTranslation()
    const [userDashboardWidgets, setUserDashboardWidgets] = useState<UserWidget[]>([])
    const DSBWidgetTitlesTranslatedParsed = JSON.stringify({
        sue_bar_chart: t('dsbSueStatusChart', 'Gráfico de denuncias'),
        sue_status_informed: t('dsbSueWithoutProcessingTitle', 'Denuncias Sin procesar'),
        sue_status_closed: t('dsbSueClosedTitle', 'Denuncias Cerradas'),
        sue_status_processed: t('dsbSueInProcessTitle', 'Denuncias En proceso'),
        sue_status_expired: t('dsbSueExpiredTitle', 'Denuncias Fuera de plazo'),
        holiday_calendar_employees: t('holidayCalendar','Calendario de vacaciones'),
        holiday_table_employees_week: t('employeesWithHolidayThisWeekTable', 'Tabla de empleados con vacaciones esta semana'),
        lastest_employee_timetable: t('latestEmployeeEntriesTable', 'Tabla de las últimas entradas de empleados'),
        task_todays_tasks: t('dsbTaskTodaysTasksTitle', 'Tareas para hoy'),
        task_weeks_tasks: t('dsbTaskWeeksTasksTitle', 'Tareas para esta semana'),
        my_task_todays_tasks: t('dsbTaskTodaysMyTasksTitle', 'Mis tareas para hoy'),
        my_task_weeks_tasks: t('dsbTaskWeeksMyTasksTitle', 'Mis tareas para esta semana'),
    })
    const dashboardFamilies: WidgetFamiliesDictionary = useMemo(() => {
        return {
            'sue': {
                title: t('sues', 'Denuncias'),
                icon: faFile,
            },
            'working_calendar': {
                title: t('timeControl', 'Control horario'),
                icon: faCalendarDays,
            },
            'task': {
                title: t('tasks', 'Tareas'),
                icon: faListCheck,
            },
        }
    }, [t])

    const avaliableDashboardWidgets: WidgetDictionary = useMemo(() => {
        const DSBWidgetTitlesTranslated = JSON.parse(DSBWidgetTitlesTranslatedParsed)
        const utilsDates = {
            todayStart: moment().startOf('day').toDate(),
            todayEnd: moment().endOf('day').toDate(),
            weekStart: moment().startOf('week').toDate(),
            weekEnd: moment().endOf('week').toDate()
        }
        return filterWidgetsByPermissions({
            'sue_bar_chart': {
                component: DSBSueBar,
                cols: 2,
                rows: 3,
                family: 'sue',
                extraProps: {
                    title: DSBWidgetTitlesTranslated.sue_bar_chart
                },
                permission: 'sue',
                modules: ['sue']
            },
            'sue_status_informed': {
                component: DSBSueCircle,
                cols: 1,
                rows: 2,
                family: 'sue',
                extraProps: {
                    status: 'informed',
                    title: DSBWidgetTitlesTranslated.sue_status_informed
                },
                permission: 'sue',
                modules: ['sue']
            },
            'sue_status_closed': {
                component: DSBSueCircle,
                cols: 1,
                rows: 2,
                family: 'sue',
                extraProps: {
                    status: 'closed',
                    title: DSBWidgetTitlesTranslated.sue_status_closed
                },
                permission: 'sue',
                modules: ['sue']
            },
            'sue_status_processed': {
                component: DSBSueCircle,
                cols: 1,
                rows: 2,
                family: 'sue',
                extraProps: {
                    status: 'processing',
                    title: DSBWidgetTitlesTranslated.sue_status_processed
                },
                permission: 'sue',
                modules: ['sue']
            },
            'sue_status_expired': {
                component: DSBSueCircleExpired,
                cols: 1,
                rows: 2,
                family: 'sue',
                extraProps: {
                    title: DSBWidgetTitlesTranslated.sue_status_expired
                },
                permission: 'sue',
                modules: ['sue']
            },
            'holiday_calendar_employees': {
                component: HolidayCalendar,
                cols: 2,
                rows: 4,
                family: 'working_calendar',
                extraProps: {
                    title: DSBWidgetTitlesTranslated.holiday_calendar_employees
                },
                permission: 'WCal.holiday.usedDays',
                modules: ['working_calendar', 'working_calendar_medium', 'working_calendar_full']
            },
            'holiday_table_employees_week': {
                component: WeekHolidayTable,
                cols: 2,
                rows: 2,
                family: 'working_calendar',
                extraProps: {
                    title: DSBWidgetTitlesTranslated.holiday_table_employees_week
                },
                permission: 'WCal.holiday.usedDays',
                modules: ['working_calendar_medium', 'working_calendar_full']
            },
            'lastest_employee_timetable': {
                component: LastEmployeeTimetable,
                cols: 2,
                rows: 2,
                family: 'working_calendar',
                extraProps: {
                    title: DSBWidgetTitlesTranslated.lastest_employee_timetable
                },
                permission: 'WCal.checkEmployeeMonth',
                modules: ['working_calendar', 'working_calendar_medium', 'working_calendar_full']
            },
            'my_task_todays_tasks': {
                component: DSBMyTasksTable,
                cols: 2,
                rows: 2,
                family: 'task',
                extraProps: {
                    title: DSBWidgetTitlesTranslated.my_task_todays_tasks,
                    start_date: utilsDates.todayStart,
                    end_date: utilsDates.todayEnd
                },
                permission: 'task.main',
                modules: ['tasks']
            },
            'my_task_weeks_tasks': {
                component: DSBMyTasksTable,
                cols: 2,
                rows: 2,
                family: 'task',
                extraProps: {
                    title: DSBWidgetTitlesTranslated.my_task_weeks_tasks,
                    start_date: utilsDates.weekStart,
                    end_date: utilsDates.weekEnd
                },
                permission: 'task.main',
                modules: ['tasks']
            },
            'task_todays_tasks': {
                component: DSBAllTasksTable,
                cols: 2,
                rows: 2,
                family: 'task',
                extraProps: {
                    title: DSBWidgetTitlesTranslated.task_todays_tasks,
                    start_date: utilsDates.todayStart,
                    end_date: utilsDates.todayEnd
                },
                permission: 'task.main',
                modules: ['tasks']
            },
            'task_weeks_tasks': {
                component: DSBAllTasksTable,
                cols: 2,
                rows: 2,
                family: 'task',
                extraProps: {
                    title: DSBWidgetTitlesTranslated.task_weeks_tasks,
                    start_date: utilsDates.weekStart,
                    end_date: utilsDates.weekEnd
                },
                permission: 'task.main',
                modules: ['tasks']
            }
        })
    }, [DSBWidgetTitlesTranslatedParsed])

    
    const getUserWidgets = useCallback(async () => {
        const dashboard_distribution = getEmployeeParams()?.[name]
        const params = dashboard_distribution ? JSON.parse(dashboard_distribution) : JSON.parse(defaultWidgets)
        setUserDashboardWidgets(filterUserWidgetsByAvaliableWidgets(avaliableDashboardWidgets, params))
    }, [avaliableDashboardWidgets, setUserDashboardWidgets, defaultWidgets, name])

    const saveUserWidgets = useCallback(async () => {
        const myName = name
        const myValue = JSON.stringify(userDashboardWidgets)
        await twinFetchPostJSON('/api/app/config/employee/updateEmployeeParameter', { parameterKey: myName, value: myValue })
        changeEmployeeParams({ [myName]: myValue })
    }, [userDashboardWidgets, name])

    useEffect(() => {
        getUserWidgets()
    }, [getUserWidgets])
    
    return { dashboardFamilies, avaliableDashboardWidgets, userDashboardWidgets, setUserDashboardWidgets, getUserWidgets, saveUserWidgets }
}

const filterWidgetsByPermissions = (widgets: WidgetDictionary) => {
    const userPermissions = getAllGroupPermissions()
    const modulesBuyed = getModulesBuyed()
    let widgetsFiltered: WidgetDictionary = {}
    if (userPermissions) {
        for (const key in widgets) {
            const widget = widgets[key]
            if (haveModuleBuyed(widget.modules, modulesBuyed)) {
                if (!widget.permission) {
                    widgetsFiltered[key] = { ...widget, userPermission: { n: 4, permission: 'delete' } }
                    continue
                }
                const userPermission = getMaxPermissionByGroupPermissions(widget.permission, userPermissions)
                if (userPermission.permission) {
                    widgetsFiltered[key] = { ...widget, userPermission }
                }
            }
        }
    }
    return widgetsFiltered
}

const filterUserWidgetsByAvaliableWidgets = (widgets: WidgetDictionary, userWidgets: UserWidget[]) => {
    let widgetsFiltered: UserWidget[] = []
    for (const widget of userWidgets) {
        if (widget.name in widgets) {
            widgetsFiltered.push(widget)
        }
    }
    return widgetsFiltered
}


export default useDashboardWidgets