import { combineReducers } from 'redux'
import { categories, CategoriesPayload } from './products/categories'
import company, { CompanyPayload } from './globals/company'
import countries, { CountryJson } from './globals/countries'
import currentPage, { CurrentPagePayload } from './globals/currentPage'
import customerGroups, { CustomerGroupPayload } from './customers/customerGroups'
import features, { FeaturesPayload } from './products/features'
import fingerprint, { FingerprintPayload } from './globals/fingerprint'
import user, { UserPayload } from './globals/user'
import config, { ConfigPayload } from './globals/config'
import priceZoneGroups, { PriceZoneGroupsPayload } from './globals/priceZoneGroups'
import suppliers, { SupplierPayload } from './products/suppliers'
import specificPriceRules, { SpecificPriceRulesPayload } from './products/specificPriceRules'
import warehouses, { WarehousePayload } from './company/warehouses'
import popupMessage, { PopupMessagePayload } from './globals/popupMessage'
import groupVats, { GroupVatJson } from './globals/groupVats'
import antiUnsave, { AntiUnsavePayload } from './globals/antiUnsave'
import workingCenterTypes, { WorkingCenterTypePayload } from './company/workingCenterTypes'
import brands, { BrandsPayload } from './products/brands'
import variants, { VariantsPayload } from './products/variants'
import workingCenters, { WorkingCenterPayload } from './company/workingCenters'
import costBuys, { CostBuyPayload } from './company/costBuys'
import costSells, { CostSellPayload } from './company/costSells'
import serviceTypes, { ServiceTypePayload } from './company/serviceTypes'
import { configureStore } from '@reduxjs/toolkit'
import billingGroups, { BillingGroupPayload } from './company/billingGroups'
import billingRetentions, { BillingRetentionPayload } from './company/billingRetentions'
import configPaymentTypes, { ConfigPaymentTypePayload } from './company/configPaymentTypes'
import configChargingMethods, { ConfigChargingMethodPayload } from './company/configChargingMethods'
import configPaymentMethods, { ConfigPaymentMethodPayload } from './company/configPaymentMethods'
import customers, { CustomerPayload } from './customers/customer'
import shippingCompanies, { ShippingCompanyPayload } from './company/shippingCompanies'
import shippingZoneGroups, { ShippingZoneGroupPayload } from './company/shippingZoneGroups'
import pdfTemplates, { PdfTemplatePayload } from './company/pdfTemplates'
import shippingServices, { ShippingServicePayload } from './company/shippingServices'
import { createContext } from 'react'
import sellShippingStatus, { SellShippingStatusPayload } from './company/sellShippingStatus'
import groups, { GroupPayload } from './groups/groups'
import employees, { EmployeesPayload } from './company/employees'
import lastNotification, { LastNotificationPayload } from './company/lastNotification'
import employeeAbsenceTypes, { EmployeeAbsenceTypePayload } from './company/employeeAbsenceTypes'
import taskCFields, { TaskCFieldPayload } from './company/taskCFields'
import taskTypes, { TaskTypePayload } from './company/tastTypes'
import customerProjectTypes, { CustomerProjectTypePayload } from './customers/customerProjectTypes'
import taskHelpers, { TaskHelpersPayload } from './company/taskHelpers'
import bottomNavApp, { BottomNavAppPayload } from './globals/bottomNavApp'
import restrictTimeControlAccess, { ChangeRestrictTimeControlAccessPayload } from './globals/restrictTimeControlAccess'

export interface AllReduxPayloads {
    billingGroups: BillingGroupPayload
    billingRetentions: BillingRetentionPayload
    brands: BrandsPayload
    categories: CategoriesPayload
    configChargingMethods: ConfigChargingMethodPayload
    configPaymentMethods: ConfigPaymentMethodPayload
    configPaymentTypes: ConfigPaymentTypePayload
    costBuys: CostBuyPayload
    costSells: CostSellPayload
    customers: CustomerPayload
    customerGroups: CustomerGroupPayload
    customerProjectTypes: CustomerProjectTypePayload
    employees: EmployeesPayload
    employeeAbsenceTypes: EmployeeAbsenceTypePayload
    features: FeaturesPayload
    groupVats: GroupVatJson
    groups: GroupPayload
    priceZoneGroups: PriceZoneGroupsPayload
    sellShippingStatus: SellShippingStatusPayload
    serviceTypes: ServiceTypePayload
    shippingCompanies: ShippingCompanyPayload
    shippingServices: ShippingServicePayload
    shippingZoneGroups: ShippingZoneGroupPayload
    specificPriceRules: SpecificPriceRulesPayload
    suppliers: SupplierPayload
    taskCFields: TaskCFieldPayload
    taskHelpers: TaskHelpersPayload
    taskTypes: TaskTypePayload
    variants: VariantsPayload
    warehouses: WarehousePayload
    workingCenters: WorkingCenterPayload
    workingCenterTypes: WorkingCenterTypePayload
    pdfTemplates: PdfTemplatePayload
}

const allReducers = combineReducers({
    billingGroups,
    billingRetentions,
    brands,
    categories,
    configChargingMethods,
    configPaymentMethods,
    configPaymentTypes,
    costBuys,
    costSells,
    customers,
    customerProjectTypes,
    employees,
    employeeAbsenceTypes,
    customerGroups,
    features,
    groupVats,
    groups,
    priceZoneGroups,
    sellShippingStatus,
    serviceTypes,
    shippingCompanies, 
    shippingServices, 
    shippingZoneGroups, 
    specificPriceRules,
    suppliers,
    taskCFields,
    taskHelpers,
    taskTypes,
    variants,
    warehouses,
    workingCenters,
    workingCenterTypes,
    pdfTemplates,
})

const rootReducer = (state: any, action: any) => {
    if (action.type === 'RESET_APP') {
        state = undefined;
    }
    return allReducers(state, action);
}


const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck: false,
    })
})

export interface AllReduxAppPayloads {
    antiUnsave: AntiUnsavePayload
    bottomNavApp: BottomNavAppPayload
    company: CompanyPayload
    config: ConfigPayload
    countries: CountryJson
    currentPage: CurrentPagePayload
    popupMessage: PopupMessagePayload
    restrictTimeControlAccess: ChangeRestrictTimeControlAccessPayload
    fingerprint: FingerprintPayload
    user: UserPayload
    lastNotification: LastNotificationPayload
}

const appReducer = combineReducers({
    antiUnsave,
    bottomNavApp, 
    company,
    config,
    countries,
    currentPage,
    popupMessage,
    restrictTimeControlAccess,
    fingerprint,
    user,
    lastNotification
})

export const appStore = configureStore({ reducer: appReducer })


export const GlobalContext = createContext<any>(null)

export default store