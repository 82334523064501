import { connect, ConnectedProps } from 'react-redux'
import { AdminRoutesPaths } from '../../app/admin'
import { AllReduxAppPayloads, GlobalContext } from '../../utils/reducers'
import { Routes } from '../CreateRoutering/types'
import ModalAntiUnsave from '../ModalsLayouts/ModalAntiUnsave'
import ModalPopupMessage from '../ModalsLayouts/ModalPopupMessage'
import ModalRestrictTimeControlAccess from '../ModalsLayouts/ModalRestrictTimeControlAccess'
import Header, { HeaderAdminConnect } from './Header'
import Breadcrumb from './Header/Breadcrumb'
import RightSideHeader from './Header/RightSideHeader'
import LeftMenu from './LeftMenu'
import IconAdminMenuBar from './LeftMenu/AdminIconMenuBar'
import ModalBottomNavApp from '../ModalsLayouts/ModalBottomNavApp'
import TaskNotificationModal from '../../specificComponents/Task/TaskNotificationModal'

interface AppLayoutProps {
    routes: Routes
    children?: React.ReactNode
}

const AppLayout: React.FC<AppLayoutProps> = ({ children, routes }) => {
    return (
        <AppLayoutBaseConnector>
            <LeftMenu routes={routes} />
            <RightSideBase>
                <Header>
                    <Breadcrumb routes={routes} />
                    <RightSideHeader />
                </Header>
                {children}
            </RightSideBase>
            <ModalRestrictTimeControlAccess />
            <TaskNotificationModal />
        </AppLayoutBaseConnector>

    )
}
interface AppLayoutBaseProps extends ReduxAppLayoutConnector {
    children: React.ReactNode
}

const AppLayoutBase: React.FC<AppLayoutBaseProps> = ({ children, dark_mode }) => {
    return (
        <div className={'h-full w-full flex ' + (dark_mode === 'on' ? 'dark' : '')}>
            {children}
            <ModalPopupMessage />
            <ModalBottomNavApp />
            <ModalAntiUnsave />
        </div>
    )
}

const mapAppLayoutBaseConnector = (state: AllReduxAppPayloads) => ({ dark_mode: state.user?.extraData?.Employee?.EmployeeParameters?.dark_mode })
export const appLayoutConnector = connect(mapAppLayoutBaseConnector, {}, null, { context: GlobalContext })
export type ReduxAppLayoutConnector = ConnectedProps<typeof appLayoutConnector>
const AppLayoutBaseConnector = appLayoutConnector(AppLayoutBase)

interface RightSideBaseProps {
    children: React.ReactNode
}

const RightSideBase: React.FC<RightSideBaseProps> = ({children}) => {
    return (
        <div className='flex flex-col flex-auto bg-gray-F7 app_right_side w-1'>
            {children}
        </div>
    )
}

interface AppLayoutAdminProps {
    children: React.ReactNode
}

export const AppLayoutAdmin: React.FC<AppLayoutAdminProps> = ({ children }) => {
    const routes = AdminRoutesPaths()
    return (
        <AppLayoutBase dark_mode='off'>
            <IconAdminMenuBar routes={routes} />
            <RightSideBase>
                <HeaderAdminConnect routes={routes}/>
                {children}
            </RightSideBase>
        </AppLayoutBase>
    )
}

export default AppLayout