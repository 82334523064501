import { ConnectedProps, connect } from 'react-redux'
import { AllReduxAppPayloads, GlobalContext } from '../../../utils/reducers'
import { ChangeRestrictTimeControlAccessPayload } from '../../../utils/reducers/globals/restrictTimeControlAccess'
import { changeRestrictTimeControlAccess, signOutUser } from '../../../utils/reducers/reduxDispatch'
import { ButtonRed } from '../../Button'
import { ModalSmallCentered } from '../../Modal'
import TwinTrans from '../../TwinTrans'
import { useCallback, useEffect, useState } from 'react'
import useInterval from '../../../utils/hooks/useInterval'
import { twinFetchPostJSON } from '../../../utils/globals/data'


interface ModalRestrictTimeControlAccessProps extends ReduxRestrictTTCAccessConnector { }

const ModalRestrictTimeControlAccess: React.FC<ModalRestrictTimeControlAccessProps> = ({ restrictTimeControlAccess }) => {
    const { handleRestrictTimeControl } = useModalRestrictTimeControlAccessLogic()
    return (
    <div>
        {restrictTimeControlAccess ?
            <ModalSmallCentered opened={true} setOpened={() => { }}>
                <h2><TwinTrans transKey={'youAreOutOfTime'}>Estás fuera de horario</TwinTrans></h2>
                <span><TwinTrans transKey={'comeBackOnSchedule'}>Vuelve cuando estés dentro de tu horario laboral.</TwinTrans></span>
                <ButtonRed onClick={handleRestrictTimeControl}><TwinTrans transKey={'signOut'}>Cerrar sesión</TwinTrans></ButtonRed>
            </ModalSmallCentered>
        : null}
        </div>
    )
}

const useModalRestrictTimeControlAccessLogic = () => {
    const [millisecondsToRestrictAccess, setMillisecondsToRestrictAccess] = useState<number | false>(false)
    const handleRestrictTimeControl = useCallback(() => {
        signOutUser();
        changeRestrictTimeControlAccess(null)
    }, [])

    const getRestrictTimeControlMilliseconds = useCallback(async() => {
        const result = await twinFetchPostJSON('/api/app/utils/getRestrictTimeControlAccessMilliseconds', {})
        if (result) {
            setMillisecondsToRestrictAccess(result)
        } else {
            setMillisecondsToRestrictAccess(false)
        }      
    }, [setMillisecondsToRestrictAccess])

    useInterval(() => {
        getRestrictTimeControlMilliseconds()
    }, (millisecondsToRestrictAccess))

    useEffect(() => {
        getRestrictTimeControlMilliseconds()
    }, [getRestrictTimeControlMilliseconds])

    return { handleRestrictTimeControl }
}


const restrictTimeControlAccessDispatch = {
    setRestrictTimeControlAccess: (payload: ChangeRestrictTimeControlAccessPayload) => ({ type: 'CHANGE_RESTRICT_TC_ACCESS', payload }),
}
const mapRestrictTimeControlAccessConnector = (state: AllReduxAppPayloads) => ({ restrictTimeControlAccess: state.restrictTimeControlAccess })
const restrictTimeControlAccessConnector = connect(mapRestrictTimeControlAccessConnector, restrictTimeControlAccessDispatch, null, { context: GlobalContext })
type ReduxRestrictTTCAccessConnector = ConnectedProps<typeof restrictTimeControlAccessConnector>



export default restrictTimeControlAccessConnector(ModalRestrictTimeControlAccess)