import { ModalMedium } from '../../../../../../../../baseComponents/Modal'
import { ModalOpenedSetTy } from '../../../../../../../../baseComponents/Modal/types'
import useTwinTranslation from '../../../../../../../../utils/hooks/useTwinTranslation'
import { WCalHolidayUsedDayModelType } from '@teinor/erp/types/company/WCalParent/WCal/WCalHolidayBag/WCalHolidayUsedDay'
import { ColumnTableSchema } from '../../../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import { CustomRenderDate } from '../../../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { VirtualTableListingStateLess } from '../../../../../../../../baseComponents/TwinTable/VirtualTableListing'
import TwinTrans from '../../../../../../../../baseComponents/TwinTrans'
import { DisplayStatusWithoutIcon } from '../../../../../../../../baseComponents/Displays/DisplayStatus'
import { WCalHolidayBagModelType } from '@teinor/erp/types/company/WCalParent/WCal/WCalHolidayBag'
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { ButtonPrimary, ButtonSecondary } from '../../../../../../../../baseComponents/Button'
import twinFetchPost, { twinFetchPostJSON } from '../../../../../../../../utils/globals/data'
import { TimeControlHolidayBags } from '../../types'
import { changeErrorMessage, changeSuccessMessage } from '../../../../../../../../utils/reducers/reduxDispatch'
import useStatusRequestTypes from '../../../../../../../../utils/hooks/useStatusRequestTypes'
import './modalholidaybagdetail.sass'
import { ModalEditComponentPropsWithoutSubmit } from '../../../../../../../../baseComponents/ModalsLayouts/types'
import TwinCalendarStartEnd from '../../../../../../../../baseComponents/TwinCalendar/TwinCalendarStartEnd'

interface BasePropsHolidayBagDetail {
    holidayBagInstance: WCalHolidayBagModelType
    setOpened: ModalOpenedSetTy
    getData: () => Promise<void>
}

type ModalHolidayBagDetailProps = ModalHolidayBagDetailLogicProps & ModalEditComponentPropsWithoutSubmit & {
    getData: () => Promise<void>
}

const ModalHolidayBagDetail: React.FC<ModalHolidayBagDetailProps> = ({ getData, holidayBag, setOpened, allRowData }) => {
    const { holidayBagInstance } = useModalHolidayBagDetailLogic({ holidayBag })
    const usedDays = holidayBagInstance?.WCalHolidayUsedDays

    return (
        <ModalMedium className={'h-full modal_medium flex flex-col'} opened={true} setOpened={setOpened}>
            {holidayBagInstance ? <RequestHolidaySection holidayBagInstance={holidayBagInstance} setOpened={setOpened} defaultModalRequestValue={allRowData?.requestingHolidays} getData={getData} /> : null}
            {usedDays ? <HolidayDetailTable usedDays={usedDays} />: null}
        </ModalMedium>
    )
}

interface ModalHolidayBagDetailLogicProps {
    holidayBag: TimeControlHolidayBags
}

const useModalHolidayBagDetailLogic = ({holidayBag}: ModalHolidayBagDetailLogicProps) => {
    const [holidayBagInstance, setHolidayBagInstance] = useState<WCalHolidayBagModelType | null>(null)

    const getHolidayBag = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/employee/employeeHoliday/getEmployeeHolidayBagInstance', {
            id: holidayBag.id
        })
        if (result) {
            setHolidayBagInstance(result)
        }
    }, [setHolidayBagInstance, holidayBag.id])

    useEffect(() => {
        getHolidayBag()
    }, [getHolidayBag])
    return {holidayBagInstance}
}


type RequestHolidaySectionProps = RequestHolidaySectionLogicProps & BasePropsHolidayBagDetail & {}

const RequestHolidaySection: React.FC<RequestHolidaySectionProps> = ({ holidayBagInstance, setOpened, defaultModalRequestValue, getData }) => {
    const { tVars } = useTwinTranslation()

    const { requestHolidayOpened, setRequestHolidayOpened } = useRequestHolidaySectionLogic({ defaultModalRequestValue })
    return (
        <div className='mt-30'>
            <div className='flex mb-10'>
                <h1 className='text-22 text-gray-51'>
                    <TwinTrans transKey='holidays'>Vacaciones</TwinTrans>
                </h1>
                <span className='ml-auto text-16 text-green-21 cursor-pointer' onClick={() => setRequestHolidayOpened((old) => !old)}><TwinTrans transKey='requestHolidayDays'>Solicitar días de vacaciones</TwinTrans></span>
            </div>
            <span className='text-gray-51 light16'>{tVars('youHaveXDaysRemaining', 'Te quedan {{leftDays}} días disponibles ', { leftDays: holidayBagInstance.leftDays })}</span>
            {requestHolidayOpened ? <RangeSelectorCalendars holidayBagInstance={holidayBagInstance} setRequestHolidayOpened={setRequestHolidayOpened} setOpened={setOpened} getData={getData} /> : null}
        </div>
    )
}

interface RequestHolidaySectionLogicProps {
    defaultModalRequestValue: boolean
}

const useRequestHolidaySectionLogic = ({ defaultModalRequestValue }: RequestHolidaySectionLogicProps) => {
    const [requestHolidayOpened, setRequestHolidayOpened] = useState<boolean>(defaultModalRequestValue)
    return { requestHolidayOpened, setRequestHolidayOpened }
}

interface RangeSelectorCalendarsProps extends RangeSelectorCalendarsLogicProps {
    setRequestHolidayOpened: Dispatch<SetStateAction<boolean>>
}
const RangeSelectorCalendars: React.FC<RangeSelectorCalendarsProps> = ({ holidayBagInstance, setRequestHolidayOpened, setOpened, getData }) => {
    const { dateSelected, handleOnChange, handleRequestHolidays } = useRangeSelectorCalendarsLogic({ holidayBagInstance, setOpened, getData })
    return (
        <div className='mt-22 border-solid border-b border-gray-D6'>
            <TwinCalendarStartEnd start={dateSelected.start} end={dateSelected.end} minDate={holidayBagInstance.from ? new Date(holidayBagInstance.from) : undefined} maxDate={holidayBagInstance.to ? new Date(holidayBagInstance.to) : undefined} defaultActiveStartDate={dateSelected.start} onChange={handleOnChange} />
            <div className='flex justify-end mt-47 gap-38 mb-30'>
                <ButtonSecondary className='modal_edit_holidays_request_btn' onClick={() => setRequestHolidayOpened(false)}><TwinTrans transKey='cancel'>Cancelar</TwinTrans></ButtonSecondary>
                <ButtonPrimary className='modal_edit_holidays_request_btn' onClick={handleRequestHolidays}><TwinTrans transKey='request'>Solicitar</TwinTrans></ButtonPrimary>
            </div>
        </div>
    )
}

interface RangeSelectorCalendarsLogicProps extends BasePropsHolidayBagDetail {}

const useRangeSelectorCalendarsLogic = ({ holidayBagInstance, setOpened, getData }: RangeSelectorCalendarsLogicProps) => {
    const { t } = useTwinTranslation()
    const currentDate = new Date()
    const bagDateparsed = new Date(holidayBagInstance.from)
    const [dateSelected, setDateSelected] = useState({
        start: bagDateparsed > currentDate ? bagDateparsed : currentDate,
        end: bagDateparsed > currentDate ? bagDateparsed : currentDate
    })

    const messages = JSON.stringify({
        errorStartDateNotSelected: t('noStartDateSelected', 'Fecha de inicio no seleccionada'),
        'No left days': t('notEnoughHolidayDaysLeft', 'No quedan suficientes días de vacaciones'),
        'The day picked must be a working day': t('mustBeWorkingDay', 'La fecha seleccionada debe ser un día laborable'),
        'The day picked is not available': t('datePickedNotAvailable', 'La fecha seleccionada no está disponible'),
        success: t('successfullyCompleted', 'Realizado correctamente'),
    })
    
    const handleOnChange = useCallback((value: Date, type: 'start' | 'end') => {
        setDateSelected((old) => {
            return {...old, [type]: value}
        })
    }, [setDateSelected])

    const handleRequestHolidays = useCallback(async () => {
        const messagesParsed = JSON.parse(messages)
        let dateParsedEnd
        if (dateSelected.start) {
            const dateParsedStart = dateSelected.start.toDateString()
            if (dateSelected.end) {
                dateParsedEnd = dateSelected.end.toDateString()
            }
            const result = await twinFetchPost('/api/app/employee/employeeHoliday/createEmployeeHolidayUsedDay', {
                WCalHolidayBagId: parseInt(String(holidayBagInstance.id)),
                EmployeeId: holidayBagInstance.EmployeeId,
                from: dateParsedStart,
                to: dateSelected.end ? dateParsedEnd : dateParsedStart
            })
            let response = await result.json()
            if (result.status === 200) {
                setOpened(null)
                getData()
                changeSuccessMessage(messagesParsed.success)
            } else if (result.status === 422) {
                changeErrorMessage(messagesParsed[response.errors[0].msg])
            }
        } else {
            changeErrorMessage(messagesParsed.errorStartDateNotSelected)
        }
    }, [holidayBagInstance.id, dateSelected.start, dateSelected.end, setOpened, getData, messages, holidayBagInstance.EmployeeId])
    return { dateSelected, handleOnChange, handleRequestHolidays }
}

interface HolidayDetailTableProps {
    usedDays: WCalHolidayUsedDayModelType[]
}

const HolidayDetailTable: React.FC<HolidayDetailTableProps> = ({ usedDays }) => {
    const {t} = useTwinTranslation()
    const columns: ColumnTableSchema<WCalHolidayUsedDayModelType> = [
        {
            id: 'from',
            dataKey: 'from',
            label: t('from', 'Desde'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />,
        },
        {
            id: 'to',
            dataKey: 'to',
            label: t('to', 'Hasta'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />,
        },
        {
            id: 'approved',
            dataKey: 'approved',
            label: t('status', 'Estado'),
            customRender: (parameterValue) => <RenderUsedDayStatus value={parameterValue} />,
            minWidth: 150
        },
    ]
    return (
        <div className='mb-20 mt-25 flex flex-col flex-auto'>
            <div className='justify-between items-center mb-20'>
                <h2 className='text-18 text-gray-51'>
                    <TwinTrans transKey='usedDaysRequiredDetail'>Detalle de tus vacaciones solicitadas</TwinTrans>
                </h2>
            </div>
            <div className='flex-auto h-1 overflow-auto'>
                <VirtualTableListingStateLess tableData={usedDays} rowHeight={48} headerHeight={48} name='HolidayRequestDetail' columns={columns} setSelectedColumnsAndFilters={() => { }} />
            </div>
        </div>
    )
}

interface RenderBuyOrderStatusProps {
    value: string
}

const RenderUsedDayStatus: React.FC<RenderBuyOrderStatusProps> = ({ value }) => {
    const status = useStatusRequestTypes()
    const parsedValue = parseInt(value)
    return <DisplayStatusWithoutIcon colorText={status[parsedValue].colorText} text={status[parsedValue].name} />
}

export default ModalHolidayBagDetail