import { TaskBagOfHoursModelType } from '@teinor/erp/types/company/task/taskBagOfHours'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { LittleModalCreateEdit } from '../../../../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../../../../../../../../baseComponents/ModalsLayouts/types'
import { FormRender } from '../../../../../../../../../../forms/FormRenderer/types'
import { twinFetchPostJSON } from '../../../../../../../../../../utils/globals/data'
import { TwinDictionary, dictionaryComplexFromJsonArr } from '../../../../../../../../../../utils/globals/dictionary'
import useTwinTranslation from '../../../../../../../../../../utils/hooks/useTwinTranslation'

interface ModalCreateContactBagOfHoursProps extends ModalEditComponentProps { }

const ModalCreateContactBagOfHours: React.FC<ModalCreateContactBagOfHoursProps> = ({ allRowData, userPermissions, ...rest }) => {
    const { t } = useTwinTranslation()
    const { customerProjects, timeLimitRemaining, changeRemaining, changeTimeLimit } = useModalCreateContactBagOfHoursLogic()
    
    const fields: FormRender<TaskBagOfHoursModelType> = [
        {
            cols: 1,
            elements: [
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'CustomerProjectId',
                    label: t('project', 'Proyecto'),
                    component: 'CustomSelect',
                    items: customerProjects || {}, 
                    required: true,
                    readOnly: allRowData?.id ? true : false
                },
                {
                    name: 'timeLimit',
                    label: t('timeLimit', 'Tiempo límite'),
                    component: 'InputHourMinSecUnlimitedStateLess',
                    required: true,
                    value: String(timeLimitRemaining.limit),
                    onFinalChange: changeTimeLimit
                },
                {
                    name: 'remainingTime',
                    label: t('remainingTime', 'Tiempo restante'),
                    component: 'InputHourMinSecUnlimitedStateLess',
                    required: true,
                    value: String(timeLimitRemaining.remaining),
                    onFinalChange: changeRemaining
                },
                {
                    name: 'expireDate',
                    label: t('expireDate', 'Fecha de vencimiento'),
                    component: 'InputCalendarStateFull',
                    onlyValids: true,
                },
                {
                    name: 'id',
                    component: 'InputHidden'
                }
            ]
        }
    ]
    
    return (
        <LittleModalCreateEdit fields={fields} url={'/api/app/task/bagOfHours/createTaskBagOfHours'} translations={{
            title: t('createCustomerBagOfHours', 'Crear bolsa de horas'),
            button: t('create', 'Crear')
        }} haveButtonPermissions={true} {...rest} />
    )
}

const useModalCreateContactBagOfHoursLogic = () => {
    const [customerProjects, setCustomerProjects] = useState<TwinDictionary | null>(null)
    const params = useParams<{ id: string }>()
    const CustomerId = parseInt(params.id || '0')
    const [timeLimitRemaining, setTimeLimitRemaining] = useState({ limit: 0, remaining: 0 })

    const changeRemaining = useCallback((value: number) => {
        setTimeLimitRemaining((old) => {
            const copyOld = JSON.parse(JSON.stringify(old))
            copyOld.remaining = value
            if (value > copyOld.limit) {
                copyOld.limit = value
            }
            return { ...copyOld }
        })
    }, [setTimeLimitRemaining])

    const changeTimeLimit = useCallback((value: number) => {
        setTimeLimitRemaining((old) => {
            const copyOld = JSON.parse(JSON.stringify(old))
            if (copyOld.remaining === copyOld.limit || value < copyOld.remaining) {
                copyOld.remaining = value
            }
            copyOld.limit = value
            return { ...copyOld }
        })
    }, [setTimeLimitRemaining])
    const getCustomerProjects = useCallback(async () => {
        const myData = await twinFetchPostJSON('/api/app/customer/customerProject/getAllCustomerProjects', { where: { CustomerId } })
        if (myData) {
            setCustomerProjects(dictionaryComplexFromJsonArr(myData))
        }
    }, [setCustomerProjects, CustomerId])

    useEffect(() => {
        getCustomerProjects()
    }, [getCustomerProjects])

    return { customerProjects, timeLimitRemaining, changeRemaining, changeTimeLimit }
}


export default ModalCreateContactBagOfHours