import { faLock } from '@fortawesome/pro-light-svg-icons'
import { UserModelType } from '@teinor/erp/types/environment/user'
import { Fragment, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ButtonOrLoader } from '../../../../../../baseComponents/Button'
import CustomError from '../../../../../../baseComponents/CustomError'
import TwinTrans from '../../../../../../baseComponents/TwinTrans'
import TwinForm from '../../../../../../forms/TwinForm'
import { BeforeSubmitHandler } from '../../../../../../forms/TwinForm/types'
import FormRenderer from '../../../../../../forms/FormRenderer'
import { FormRender } from '../../../../../../forms/FormRenderer/types'
import useIsLoading from '../../../../../../utils/hooks/useIsLoading'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import { MyLink } from '../../../Login/Components'
import { checkPassword, checkPasswordLength } from '../../../Register/RegisterForm/functions'

export interface NewPasswordFormProps {
    handleChangeShowMessage: (active: boolean) => void
 }

interface NewUserModelType extends UserModelType {
    confirmPassword: string
}
const NewPasswordForm: React.FC<NewPasswordFormProps> = ({ handleChangeShowMessage }) => {
    const { t } = useTwinTranslation()

    const { token } = useParams<{ token: string }>()
    const { startLoading, endLoading, loading } = useIsLoading()
    const { beforeSubmitHandler, handleOnSubmit, errors } = useNewPasswordFormLogic({ startLoading, handleChangeShowMessage })
    const fields: FormRender<NewUserModelType> = [
        {
            cols: 1,
            elements: [
                {
                    name: 'password',
                    placeholder: t('password', 'Contraseña'),
                    component: 'InputWithLeftIcon',
                    type: 'password',
                    required: true,
                    icon: faLock
                },
                {
                    name: 'confirmPassword',
                    placeholder: t('confirm_password', 'Confirmar contraseña'),
                    component: 'InputWithLeftIcon',
                    type: 'password',
                    required: true,
                    icon: faLock,
                },
                {
                    name: 'token',
                    component: 'InputHidden',
                    value: token
                }
            ]
        }
    ]
    return (
        <Fragment>
            <div className='my-40 text-center'>
                <h1 className='mb-22'>
                    <TwinTrans transKey='createNewPassword'>
                        Crear nueva contraseña
                    </TwinTrans>
                </h1>
                <span className='regular14'>
                    <TwinTrans transKey='enterNewPassword'>
                        Introduce tu nueva contraseña
                    </TwinTrans>
                </span>
            </div>
            <CustomError textError={errors} className='mb-10' />
            <TwinForm method='POST' action='/api/generateNewPassword' onSubmit={(res) => { handleOnSubmit(res); endLoading() }} beforeSubmitHandler={beforeSubmitHandler} onError={endLoading}>
                <FormRenderer sections={fields} allSectionsInnerClassName='gap-y-30' />
                <ButtonOrLoader buttonIsDisabled={loading} className='recover_pass_button' textButton={t('generateNewPassword', 'Generar nueva contraseña')} />
            </TwinForm>
            <div className='mt-40 text-center'>
                <MyLink text={t('returnToLoginPage', 'Volver a la página de Inicio sesión')} link={'/'} />
            </div>
        </Fragment>     
    )
}

interface NewPasswordFormLogicProps {
    handleChangeShowMessage: (active: boolean) => void
    startLoading: () => boolean
}
const useNewPasswordFormLogic = ({ startLoading, handleChangeShowMessage }: NewPasswordFormLogicProps) => {
    const [errors, setErrors] = useState<false | string>(false)
    const { t } = useTwinTranslation()

    const handleOnSubmit = useCallback(async (res: Response) => {
        if (res.status === 200) {
            const text = await res.text()
            if (text === 'true') {
                return handleChangeShowMessage(true)
            }
        }
        return handleChangeShowMessage(false)
    }, [handleChangeShowMessage])
    const beforeSubmitHandler: BeforeSubmitHandler = useCallback((vals) => {
        if (!checkPassword(vals)) {
            const error = t('notSamePass', 'Las contraseñas no son iguales')
            setErrors(error)
            return false
        } else if (checkPasswordLength(vals)) {
            const error = t('incorrectPasswordLength', 'La contraseña debe de tener al menos 8 carácteres')
            setErrors(error)
            return false
        }
        setErrors(false)
        startLoading()
        return true
    }, [t, startLoading])
    return { beforeSubmitHandler, handleOnSubmit, errors }
}

export default NewPasswordForm