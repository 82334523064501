import { WhatCanYouDoKeys } from '@teinor/erp/permissions'
import { Fragment } from 'react'
import { UserPermissions } from '../CreateRoutering/types'
import { permissionCheck } from './function'
import { haveModuleBuyed } from '../../utils/globals'
import { ErpModules } from '@teinor/erp/modules'
import { getModulesBuyed } from '../../utils/reducers/getters'

interface PermissionCheckerProps {
    userPermissions: UserPermissions | false
    permission: WhatCanYouDoKeys
    children: React.ReactNode
}

const PermissionChecker: React.FC<PermissionCheckerProps> = ({ userPermissions, permission, children }) => {
    if (!permissionCheck(userPermissions, permission)) {
        return null
    }
    return (
        <Fragment>{children}</Fragment>
    )
}

interface ModuleCheckerProps {
    modules: ErpModules
    children: React.ReactNode
}

export const ModuleChecker: React.FC<ModuleCheckerProps> = ({ modules, children }) => {
    if (!haveModuleBuyed(modules, getModulesBuyed())) {
        return null
    }
    return (
        <Fragment>{children}</Fragment>
    )
}

export default PermissionChecker