import { faUser } from '@fortawesome/pro-light-svg-icons'
import { WhiteBox } from '../../../../../../baseComponents/AppLayout/WhiteBox'
import { BigTitleAndRightLayout } from '../../../../../../baseComponents/Layout/TitleAndRightLayout'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import { getBasePath } from '../../../../../../utils/globals/link'
import { useLocation } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { TwinDictionary } from '../../../../../../utils/globals/dictionary'
import { twinFetchPostJSON } from '../../../../../../utils/globals/data'
import { SearchBarTable } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents'
import { getUserInfo } from '../../../../../../utils/reducers/getters'
import TwinTableCard, { RenderTwinTableCards } from '../../../../../../baseComponents/TwinTable/TwinTableCard'
import { stringMatchWithSearch } from '../../../../../../utils/globals/search'

interface TasksTableEmployeesProps {}

type EmployeeRender = {
    myEmployee: JSX.Element[]
    otherEmployees: JSX.Element[]
}
const TasksTableEmployees: React.FC<TasksTableEmployeesProps> = () => {
    const {t} = useTwinTranslation()
    const location = useLocation()
    const { data, generalSearch, setGeneralSearch } = useTasksTableEmployeesLogic()
    const basePath = getBasePath(location.pathname, 'employees')
    const renderThisJSON: EmployeeRender = {
        myEmployee: [],
        otherEmployees: []
    }
    const user = getUserInfo()
    for (const element of data) {
        const includesSearch = stringMatchWithSearch(generalSearch, element?.fullname_short)
        if (includesSearch){
            const elementJSX = <TwinTableCard key={element.id} id={element.id} title={element?.fullname_short || ''} img={element?.profile_image || '/user-png.png'} icon={faUser} lastPath='main' {...{ basePath }} />
            if (element.id === user?.Employee?.id){
                renderThisJSON.myEmployee.push(elementJSX)
            }else{
                renderThisJSON.otherEmployees.push(elementJSX)
            }
        }
    }
    return (
        <BigTitleAndRightLayout title={t('employees', 'Empleados')}>
            <WhiteBox className='flex flex-col flex-auto px-40 py-50'>
                <div className='flex flex-col flex-auto'>
                    <SearchBarTable className='mb-20' changeGeneralSearchbar={setGeneralSearch} placeholder={t('searchDots', 'Buscar...')} />
                    <div className='flex-auto h-1 overflow-auto'>
                        <RenderTwinTableCards title='' twinTableCards={renderThisJSON.myEmployee} />
                        <RenderTwinTableCards title={t('otherEmployees', 'Otros empleados')} twinTableCards={renderThisJSON.otherEmployees} className='mt-20' />
                    </div>
                </div>
            </WhiteBox>
        </BigTitleAndRightLayout>
    )
}


const useTasksTableEmployeesLogic = () => {
    const [data, setData] = useState<TwinDictionary[]>([])
    const [generalSearch, setGeneralSearch] = useState<string>('')

    const getData = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/task/getAllValidEmployeesForTask', {})
        if (result) {
            setData(result)
        }
    }, [])

    useEffect(() => {
        getData()
    }, [getData])
    return { data, generalSearch, setGeneralSearch }
}

export default TasksTableEmployees