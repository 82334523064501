import { faPlus, faTrashAlt } from '@fortawesome/pro-light-svg-icons'
import { Fragment, useCallback, useMemo } from 'react'
import TwinIcon from '../../../../baseComponents/TwinIcon'
import TwinTrans from '../../../../baseComponents/TwinTrans'
import { RectangularInputHour } from '../../../../forms/Input/InputHour'
import { displayFormatedHourFromMins } from '../../../../utils/globals/date'
import { TwinDictionary } from '../../../../utils/globals/dictionary'
import './table_schedule.sass'
import { nanoid } from 'nanoid'

interface TableScheduleProps extends TableScheduleLogicProps { }

const TableSchedule: React.FC<TableScheduleProps> = ({ scheduleTime, ...restLogic }) => {
    const renderThis: JSX.Element[] = []
    const { addHourDayRow, myErrors, memorized, ...rest } = useTableScheduleLogic({ scheduleTime, ...restLogic })
    let totalMins = 0
    if (scheduleTime?.length) {
        for (const key in scheduleTime) {
            const hours = scheduleTime[key]
            const diffMin = hours[1] - hours[0]
            totalMins += diffMin
            renderThis.push(<HourDayRow key={memorized[key] || key} hourStart={hours[0]} hourEnd={hours[1]} pos={parseInt(key)} error={myErrors[parseInt(key)]} {...rest} />)
        }
    }
    const displayTotalHoursFormated = displayFormatedHourFromMins(totalMins)
    return (
        <Fragment>
            <div className='w-full '>
                <div className='flex items-center bg-gray-F7 p-10 px-14 medium14 table_schedule_row'>
                    <div><TwinTrans transKey='startHourS'>Hora inicio</TwinTrans></div>
                    <div><TwinTrans transKey='endHourS'>Hora fin</TwinTrans></div>
                    <div><TwinTrans transKey='totalHours'>Total horas</TwinTrans></div>
                    <div></div>
                </div>
                {renderThis}
                <div className='flex items-center px-14 medium14 h-40 justify-between table_schedule_row'>
                    <div>
                        <TwinTrans transKey='totalCap'>TOTAL</TwinTrans>
                    </div>
                    <div></div>
                    <div>
                        {displayTotalHoursFormated}
                    </div>
                    <div></div>
                </div>
            </div>
            <div className='cursor-pointer text-center my-10 bg-gray-EF py-8 w-full text-gray-51 ' onClick={addHourDayRow}>
                <TwinIcon className='mr-10' icon={faPlus} />
                <TwinTrans transKey='addRow'>Añadir fila</TwinTrans>
            </div>
        </Fragment>
    )
}
export type ScheduleTimeTy = [number, number][]
interface TableScheduleLogicProps  {
    scheduleTime: ScheduleTimeTy
    onChange: (updatedScheduleTime: ScheduleTimeTy) => void 
    errors: string[]
}

const useTableScheduleLogic = ({ scheduleTime, onChange, errors }: TableScheduleLogicProps) => {
    const memorized = useMemo(() => {
        let arrs = []
        for (let k = 0; k < scheduleTime?.length; k++){
            arrs.push(k+nanoid())
        }
        return arrs
    }, [scheduleTime?.length])

    const deleteHourDayRow = useCallback((pos: number) => {
        const copyOld = JSON.parse(JSON.stringify(scheduleTime))
        copyOld.splice(pos, 1)
        onChange(copyOld)
    }, [scheduleTime, onChange])

    const addHourDayRow = useCallback(() => {
        const copyOld = JSON.parse(JSON.stringify(scheduleTime))
        if (scheduleTime.length) {
            const lastTime = scheduleTime[scheduleTime.length - 1][1]
            copyOld.push([lastTime + 1, lastTime + 61])
        }else{
            copyOld.push([540, 600])
        }
        onChange(copyOld)
    }, [scheduleTime, onChange])

    const onChangeTime = useCallback((mins: number, pos: number, isEnd: boolean) => {
        const copyOld = JSON.parse(JSON.stringify(scheduleTime))
        let changePos = isEnd ? 1 : 0
        copyOld[pos][changePos] = mins
        onChange(copyOld)
    }, [scheduleTime, onChange])

    const myErrors: TwinDictionary = {}
    if (errors?.length) {
        for (const myError of errors) {
            const splited = myError.split('-')
            const parsedStart = parseInt(splited[0])
            const parsedEnd = parseInt(splited[1])
            if (!myErrors?.[parsedStart]) {
                myErrors[parsedStart] = {}
            }
            myErrors[parsedStart] = { ...myErrors[parsedStart], [parsedEnd]: true }
        }
    }
    return { deleteHourDayRow, addHourDayRow, onChangeTime, myErrors, memorized }
}


interface HourDayRowProps {
    pos: number
    hourStart: number
    hourEnd: number
    onChangeTime: (mins: number, pos: number, isEnd: boolean) => void
    deleteHourDayRow: (pos: number) => void
    error?: TwinDictionary
}

const HourDayRow: React.FC<HourDayRowProps> = ({ hourStart, hourEnd, pos, deleteHourDayRow, onChangeTime, error }) => {
    const totalMin = hourEnd - hourStart
    return (
        <div className='table_schedule_row flex items-center border-b border-b-gray-EE px-14 py-5'>
            <div>
                <RectangularInputHour className={(error?.[0] ? 'parent_input_hour_error' : '')} label='' value={String(hourStart)} onFinalChange={(value) => onChangeTime(value, pos, false)} />
            </div>
            <div>
                <RectangularInputHour className={(error?.[1] ? 'parent_input_hour_error' : '')} label='' value={String(hourEnd)} onFinalChange={(value) => onChangeTime(value, pos, true)} />
            </div>
            <div>{displayFormatedHourFromMins(totalMin)}</div>
            <div>
                <TwinIcon className='cursor-pointer hover:text-red-BA' icon={faTrashAlt} onClick={() => deleteHourDayRow(pos)} />
            </div>
        </div>
    )
}



export default TableSchedule

