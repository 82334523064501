import './customselect.sass'
import { ConfigurableSelectClassProps, CustomSelectHeaderBaseProps, DisplayTextFuncTy } from './types'
import { CustomSelectDropdownIcon, DropDownDecider, DropDownWithSearchBar } from './DropDown'
import { CustomSelectDropDownBasePropsMod, CustomSelectValue, DropDownDeciderProps } from './DropDown/types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import useOnClickOutside from '../../utils/hooks/useOnClickOut'
import { addOrRemoveClassList } from '../../utils/globals/components'
import { buildCustomSelectClass } from './functions'
import { TwinDictionary, dictionaryComplexFromJsonArr, filterDictionaryNotInItems } from '../../utils/globals/dictionary'
import { ChangeValueFunc, Modify } from '../../utils/globals/types'
import { TwinIconProp } from '../../baseComponents/TwinIcon'

export type CustomSelectBaseProps = CustomSelectLogicProps & CustomSelectHeaderBaseProps & {
    className?: string
    DropDownComponent: React.FC<CustomSelectDropDownBasePropsMod>
    CustomSelectHeader?: React.FC<CustomSelectHeaderDefaultProps>
    extraDropDownParams?: TwinDictionary
    extraHeaderParams?: TwinDictionary
    sortItems?: boolean
    maxItems?: number
}

export const CustomSelectBase: React.FC<CustomSelectBaseProps> = ({ className, DropDownComponent, label, name, required, items, extraDropDownParams, readOnly, maxItems, fieldName, icon, CustomSelectHeader, extraHeaderParams, sortItems, ...logicProps }) => {
    const { ref, opened, changeOpened, changeValue, myValue, classBuilded, displayText } = useCustomSelectLogic({ ...logicProps, readOnly, items })
    const Component = CustomSelectHeaderDecider(CustomSelectHeader)
    return (
        <div className={'custom_select ' + (className || '') + ' ' + classBuilded} ref={ref} onClick={changeOpened}>
            <Component {...{ label, name, required, opened, onFocus: changeOpened, displayText, value: myValue, readOnly, icon, extraHeaderParams, items, fieldName }} />
            {opened && <DropDownComponent items={items} onChange={changeValue} value={myValue} extraParams={extraDropDownParams} maxItems={maxItems} fieldName={fieldName} sortItems={sortItems} />}
        </div>
    )
}

export const CustomSelectHeaderDecider = (CustomSelectHeader?: React.FC<CustomSelectHeaderDefaultProps>) => {
    if (CustomSelectHeader) {
        return CustomSelectHeader
    }
    return CustomSelectHeaderDefault
}

export interface CustomSelectHeaderDefaultProps extends CustomSelectHeaderBaseProps {
    onFocus?: () => void
    value?: string | number
    extraHeaderParams?: TwinDictionary
    displayText: string
    opened: boolean
    readOnly?: boolean
}

export const CustomSelectHeaderDefault: React.FC<CustomSelectHeaderDefaultProps> = ({ opened, displayText, label, required, icon, extraHeaderParams, ...input }) => {
    return (
        <div className={'custom_select_header cursor-pointer '}>
            <input type='text' className='input_hidden' {...input} required={required} onChange={() => undefined} />
            <div className='custom_select_header_icon'>
                <div className='custom_select_header_text'>
                    <label title={label} className={'cursor-pointer twin_elipsis'}>{label}{required ? ' *' : ''}</label>
                    <div className='custom_select_item_text' title={displayText}>{displayText}</div>
                </div>
                <CustomSelectDropdownIcon opened={opened} readOnly={input.readOnly} icon={icon} />
            </div>
            <div className='custom_select_border'></div>
        </div>
    )
}

interface CustomSelectHeaderProps extends CustomSelectHeaderBaseProps {
    onFocus?: () => void
    value?: string | number
    displayText: string
    opened: boolean
    readOnly?: boolean
}

export const CustomSelectHeader: React.FC<CustomSelectHeaderProps> = ({ opened, displayText, label, required, icon, ...input }) => {
    return (
        <div className={'custom_select_header cursor-pointer '}>
            <input type='text' className='input_hidden' {...input} required={required} onChange={() => undefined} />
            <div className='custom_select_header_icon'>
                <div className='custom_select_header_text'>
                    <label className={'cursor-pointer twin_elipsis'}>{label}{required ? ' *' : ''}</label>
                    <div className='custom_select_item_text' title={displayText}>{displayText}</div>
                </div>
                <CustomSelectDropdownIcon opened={opened} readOnly={input.readOnly} icon={icon} />
            </div>
            <div className='custom_select_border'></div>
        </div>
    )
}

type CustomSelectLogicProps = {
    defaultValue?: never
    onChange?: (value: any) => void
    value?: CustomSelectValue
    configurableClassProps?: ConfigurableSelectClassProps
    readOnly?: boolean
    displayTextFunc: DisplayTextFuncTy
    items: TwinDictionary | TwinDictionary[]
    fieldName?: string
}

const useCustomSelectLogic = ({ onChange, configurableClassProps, readOnly, value, displayTextFunc, items, fieldName }: CustomSelectLogicProps) => {
    const [opened, setOpened] = useState<boolean | null>(null)
    const [myValue, setMyValue] = useState(value ?? '')
    const ref = useRef<any>(null)

    useOnClickOutside(ref, () => setOpened(false))

    const displayText = displayTextFunc(items, myValue, fieldName)

    const changeOpened = useCallback(() => {
        if (!readOnly) {
            setOpened((oldOpened) => !oldOpened)
        }
    }, [setOpened, readOnly])

    const changeValue: ChangeValueFunc = useCallback((myvalue) => {
        setMyValue(myvalue)
        onChange?.(myvalue)
    }, [setMyValue, onChange])

    useEffect(() => {
        setMyValue(value ?? '')
    }, [value])

    useEffect(() => {
        if (opened === false) {
            if (ref && ref.current) {
                const isValid = ref?.current?.querySelector('input')?.checkValidity()
                addOrRemoveClassList(ref.current, 'custom_select_error', !isValid)
            }
        } else {
            if (ref && ref.current) {
                const inputParentRef = ref?.current?.querySelector('.input_with_icon')
                if (inputParentRef) {
                    inputParentRef.children[0].focus()
                }
            }
        }
    }, [opened, ref])

    const myopened = !!opened
    const classBuilded = buildCustomSelectClass({
        opened: myopened,
        labelUp: myValue !== '' && displayText !== undefined && displayText !== '',
        ...configurableClassProps
    })
    return { opened: myopened, ref, myValue, changeOpened, changeValue, classBuilded, displayText }
}

export type CustomSelectProps = Modify<CustomSelectBaseProps, DropDownDeciderProps & {
    displayTextFunc?: never
    items: TwinDictionary
    maxItems?: number
    icon?: TwinIconProp
}>

const CustomSelect: React.FC<CustomSelectProps> = ({ DropDownComponent, sortItems, fieldName = 'name', ...rest }) => {
    const Component = DropDownDecider({ DropDownComponent, sortItems })
    const displayTextFunc: DisplayTextFuncTy = useCallback((items, value) => {
        if (!Array.isArray(items)) {
            return items[value]?.[fieldName]
        }
        return ''
    }, [fieldName])
    return (
        <CustomSelectBase DropDownComponent={Component} {...{ fieldName, sortItems }} {...rest} displayTextFunc={displayTextFunc} />
    )
}

interface CustomSelectWithSearchBarProps extends CustomSelectProps { }

export const CustomSelectWithSearchBar: React.FC<CustomSelectWithSearchBarProps> = ({ items, ...all }) => {
    return (<CustomSelect items={items} {...all} DropDownComponent={DropDownWithSearchBar} />)
}

export interface CustomSelectWithNotInFilteredItemsProps extends CustomSelectProps {
    excludeItems: string[]
}

export const CustomSelectWithNotInFilteredItems: React.FC<CustomSelectWithNotInFilteredItemsProps> = ({ items, excludeItems, ...all }) => {
    const filteredItems: TwinDictionary = useMemo(() => filterDictionaryNotInItems(items, excludeItems), [items, excludeItems])
    return <CustomSelect {...all} items={filteredItems}></CustomSelect>
}

export type CustomSelectArrComplexProps = Modify<CustomSelectBaseProps, DropDownDeciderProps & {
    items: TwinDictionary[]
    displayTextFunc?: never
    maxItems?: number
    fieldValue?: string
}>

export const CustomSelectArrComplex: React.FC<CustomSelectArrComplexProps> = ({ DropDownComponent, sortItems, items, fieldValue = 'id', fieldName = 'name', ...rest }) => {
    const Component = DropDownDecider({ DropDownComponent, sortItems })
    const dictItems = dictionaryComplexFromJsonArr(items || [], fieldValue)
    const displayTextFunc: DisplayTextFuncTy = useCallback((items, value) => {
        if (!Array.isArray(items)) {
            return items[value]?.[fieldName]
        }
        return ''
    }, [fieldName])
    return (
        <CustomSelectBase DropDownComponent={Component} items={dictItems} {...{ displayTextFunc, fieldName }} {...rest} />
    )
}


export default CustomSelect