import { useCallback, useMemo, useState} from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useBlocker } from '../../../utils/hooks/useReactRouterHooks'
import useTwinTranslation from '../../../utils/hooks/useTwinTranslation'
import { AllReduxAppPayloads, GlobalContext } from '../../../utils/reducers'
import { getConfigParam } from '../../../utils/reducers/getters'
import { clearAntiUnsaveElement, hideAntiUnsaveModal } from '../../../utils/reducers/reduxDispatch'
import ModalDeleteFilled from '../ModalDeleteFilled'

interface ModalAntiUnsaveProps extends ModalAntiUnsaveLogicProps { }

const ModalAntiUnsave: React.FC<ModalAntiUnsaveProps> = ({ ...rest }) => {
    const { display, onCancel, onAcceptGoOut } = useModalAntiUnsaveLogic({ ...rest })
    const {t} = useTwinTranslation()
    if (!display) {
        return null
    }
    return (
        <ModalDeleteFilled translations={{
            title: t('youHaveUnsavedChanges', 'Tienes cambios sin guardar'),
            subtitle: t('acceptForGoOutUnsaved', 'Si aceptas perderás los cambios, si cancelas puedes continuar modificando y a continuación pulsa sobre el botón de guardar'),
            buttonAccept: t('discardChanges', 'Descartar cambios'),
            buttonCancel: t('keepHere', 'Permancer')
        }} opened={display} setOpened={onCancel} onCancel={onCancel} onAccept={onAcceptGoOut} onClickOut={false} />
    )
}

interface ModalAntiUnsaveLogicProps extends ReduxAntiUnsaveInformationConnector { }

const useModalAntiUnsaveLogic = ({ antiUnsave }: ModalAntiUnsaveLogicProps) => {
    const [showModal, setShowModal] = useState<any>(false)
    const navigate = useNavigate();
    const parameter = useMemo(() => getConfigParam('showUnsaveDataModal'), [])
    const onCancel = useCallback(() => {
        setShowModal(false)
        hideAntiUnsaveModal()
    }, [])
    const haveAntiUnsave = Object.keys(antiUnsave).length ? true : false
    useBlocker((...props) => { setShowModal(props); return false },haveAntiUnsave && parameter === 'on')
    const onAcceptGoOut = useCallback(async () => {
        await antiUnsave.__show?.()
        await clearAntiUnsaveElement()
        setShowModal((oldModal: any) => {
            if (oldModal && oldModal[0]) {
                const to = oldModal[0].pathname
                navigate(to)
            }
            return false
        })
    }, [antiUnsave, navigate])
    return { display: showModal || antiUnsave.__show, onCancel, onAcceptGoOut }
}

const mapAntiUnsaveInformationConnector = (state: AllReduxAppPayloads) => ({ antiUnsave: state.antiUnsave })
const antiunsaveInformationConnector = connect(mapAntiUnsaveInformationConnector, null, null, { context: GlobalContext })
type ReduxAntiUnsaveInformationConnector = ConnectedProps<typeof antiunsaveInformationConnector>

export default antiunsaveInformationConnector(ModalAntiUnsave)