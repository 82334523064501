import { GroupModelType } from '@teinor/erp/types/company/userInner/group'
import { useCallback, useEffect, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { MediumModalCreateEdit } from '../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../../../../../baseComponents/ModalsLayouts/types'
import { permissionCheck } from '../../../../../../../baseComponents/PermissionChecker/function'
import { FormElement, FormRender } from '../../../../../../../forms/FormRenderer/types'
import { twinFetchPostJSON } from '../../../../../../../utils/globals/data'
import { TwinDictionary } from '../../../../../../../utils/globals/dictionary'
import { valueOrDefaultValue } from '../../../../../../../utils/globals/valueOrDefaultValue'
import withLoading from '../../../../../../../utils/hoc/withLoading'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import { AllReduxPayloads } from '../../../../../../../utils/reducers'
import { EmployeesPayload } from '../../../../../../../utils/reducers/company/employees'
import { arrayFromArrayDictionaryAttribute } from '../../../../../../../utils/globals/array'

type ModalCEGroupEmployeeProps = ModalEditComponentProps & ReduxEmployees & {}

export type GroupModelTypeExtended = GroupModelType & {
    'employees': string
}

const ModalCEGroupEmployee: React.FC<ModalCEGroupEmployeeProps> = ({ userPermissions, allRowData, employees, ...rest }) => {
    const { t } = useTwinTranslation()
    const { group } = useGroupEmployeeLogic({ id: allRowData?.id })
    const selectedDataParsed = arrayFromArrayDictionaryAttribute(group?.Employees || [])
    const imageInputArr: FormElement<keyof GroupModelType>[] =[]
    if (allRowData && userPermissions) {
        imageInputArr.push(
            {
                label: t('photo', 'Foto'),
                name: 'photo',
                component: 'ImageInput',
        }
        )
    }
    const fields: FormRender<GroupModelTypeExtended> = [
        {
            cols: 1,
            elements: [
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                ...imageInputArr,
                {
                    name: 'listing',
                    label: t('listing', 'Listar'),
                    component: 'CheckboxMargin',
                    defaultChecked: allRowData?.listing ?? true,
                },
                {
                    name: 'employees',
                    component: 'MultipleCheckboxSelector',
                    subtitles: { subtitleOptions: t('Employees', 'Empleados'),
                    subtitleSelectedOptions: t('EmployeesSelected', 'Empleados seleccionados') },
                    items: employees || {},
                    keyName: 'fullname_short',
                    defaultValue: selectedDataParsed || [],
                },
                {
                    name: 'id',
                    component: 'InputHidden'
                }
            ],
        },
    ]
    if (allRowData && userPermissions) {
        if (!group) {
            return null
        }
        const parsedFields = valueOrDefaultValue(fields, userPermissions, group)
        return (<MediumModalCreateEdit imagePath={['groupId', group.id]} fields={parsedFields} url={'/api/app/group/updateGroup'} translations={{ title: t('editGroup', 'Editar Grupo'), button: t('save', 'Guardar') }} haveButtonPermissions={permissionCheck(userPermissions, 'update')} {...rest} />)
    }
    return (<MediumModalCreateEdit fields={fields} url={'/api/app/group/createGroup'} translations={{
        title: t('createGroup', 'Crear grupo'),
        button: t('create', 'Crear')
    }} haveButtonPermissions={true} {...rest} />)
}

interface GroupEmployeeLogicProps {
    id: number
}

const useGroupEmployeeLogic = ({id}: GroupEmployeeLogicProps ) => {
    const [group, setGroup] = useState<TwinDictionary>()
    const getData = useCallback(async () => {
        if (id) {
            const groupInstance = await twinFetchPostJSON('/api/app/group/getGroupInstance', { id })
            if (groupInstance) {
                setGroup(groupInstance)
            } 
        }
    }, [id])
    useEffect(() => {
        getData()
    }, [getData])
    return { group}
}


const employeesDispatch = {
    setEmployees: (payload: EmployeesPayload) => ({ type: 'CHANGE_EMPLOYEE', payload }),
}

export type ReduxEmployees = ConnectedProps<typeof employeesConnect>
const mapEmployeesConnector = (state: AllReduxPayloads) => ({ employees: state.employees })
const employeesConnect = connect(mapEmployeesConnector, employeesDispatch)

const employeesConnectLoading = withLoading(ModalCEGroupEmployee, [{ fetchUrl: '/api/app/employee/getAllEmployees', propName: 'employees', setFunctionName: 'setEmployees' }])

const ModalCEGroupEmployeeConnect = employeesConnect(employeesConnectLoading)
export default ModalCEGroupEmployeeConnect;