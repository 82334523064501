import { EmployeeExpenseModelType } from '@teinor/erp/types/company/userInner/employee/employeeExpense'
import { EmployeeExpenseRowTypeModelAdd } from '@teinor/erp/types/company/userInner/employee/employeeExpense/employeeExpenseRow/employeeExpenseRowType'
import { useCallback, useEffect, useState } from 'react'
import { MediumModalCreateEdit } from '../../../../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../../../../../../../../baseComponents/ModalsLayouts/types'
import { RowData } from '../../../../../../../../../../baseComponents/TwinTable/types'
import { FormRender } from '../../../../../../../../../../forms/FormRenderer/types'
import EmployeeExpenseNotEditableTableExpenses from '../../../../../../../../../../specificComponents/Employee/EmployeeExpenses/EmployeeExpenseEditModal/EmployeeExpenseNotEditableModal/EmployeeExpenseNotEditableTableExpenses'
import EmployeeExpenseNotEditableTotals from '../../../../../../../../../../specificComponents/Employee/EmployeeExpenses/EmployeeExpenseEditModal/EmployeeExpenseNotEditableModal/EmployeeExpenseNotEditableTotals'
import { twinFetchPostJSON } from '../../../../../../../../../../utils/globals/data'
import { displayDateByString } from '../../../../../../../../../../utils/globals/date'
import { returnClassOfSpanTemplate } from '../../../../../../../../../../utils/globals/tailwind'
import useEmployeeExpensesType from '../../../../../../../../../../utils/hooks/employeeExpenses/useEmployeeExpensesType'
import useTwinTranslation from '../../../../../../../../../../utils/hooks/useTwinTranslation'

interface ModalUserNotificationExpensesProps extends ModalEditComponentProps {}

const ModalUserNotificationExpenses: React.FC<ModalUserNotificationExpensesProps> = ({ allRowData, setOpened }) => {
    const { t } = useTwinTranslation()
    const { expensesType, expenses } = useModalUserNotificationExpensesLogic({allRowData})
    const { expenseStatus } = useEmployeeExpensesType()

    const fields: FormRender<EmployeeExpenseModelType> = [
        {
            cols: 2,
            elements: [
                {
                    name: 'EmployeeId',
                    component: 'InputHidden',
                    value: expenses?.EmployeeId
                },
                {
                    name: 'dateStart',
                    label: t('dateStart', 'Desde'),
                    component: 'TextField',
                    value: displayDateByString(expenses?.dateStart || ''),
                },
                {
                    name: 'dateEnd',
                    label: t('dateEnd', 'Hasta'),
                    component: 'TextField',
                    value: displayDateByString(expenses?.dateEnd || ''),
                },
                {
                    name: 'CurrencyId',
                    label: t('currency', 'Moneda'),
                    component: 'TextField',
                    value: expenses?.CurrencyId
                },
                {
                    name: 'location',
                    label: t('location', 'Ubicación'),
                    component: 'TextField',
                    value: expenses?.location,
                },
                {
                    name: 'km',
                    label: t('km', 'Km'),
                    component: 'TextField',
                    value: String(expenses?.km),
                },
                {
                    name: 'kmPrice',
                    label: t('kmPrice', 'Precio del Km'),
                    component: 'DisplayPriceField',
                    value: expenses?.kmPrice || 0,
                    CurrencyId: expenses?.CurrencyId || '',
                },
                {
                    name: 'abroad',
                    label: t('abroad', 'Extranjero'),
                    component: 'TextField',
                    value: expenses?.abroad ? t('yes', 'Sí') : t('no', 'No'),
                },
                {
                    name: 'overnight_stay',
                    label: t('overnightStay', 'Pernoctación'),
                    component: 'TextField',
                    value: expenses?.overnight_stay ? t('yes', 'Sí') : t('no', 'No'),
                },
                {
                    name: 'status',
                    label: t('status', 'Estado'),
                    component: 'TextField',
                    value: expenses ? expenseStatus[expenses?.status].name : '',
                },
                {
                    name: 'updatedAt',
                    label: t('modificatedAt', 'Última modificación'),
                    component: 'TextField',
                    value: displayDateByString(expenses?.updatedAt || ''),
                },
                {
                    name: 'remarks',
                    label: t('remarks', 'Observaciones'),
                    component: expenses?.remarks ? 'TextField' : 'InputHidden',
                    value: expenses?.remarks,
                    className: returnClassOfSpanTemplate(2)
                },
            ],
        },
    ]

    if (expenses) {
        return (
            <MediumModalCreateEdit fields={fields} translations={{
                title: t('notificationInfo', 'Información de la notificación'),
                button: ''
            }} haveButtonPermissions={false} url={''} setOpened={setOpened} onSubmit={() => {}}>
                <EmployeeExpenseNotEditableTableExpenses expenseInstance={expenses} expensesType={expensesType} />
                <EmployeeExpenseNotEditableTotals expenseInstance={expenses}/>
            </MediumModalCreateEdit>
        )
    }
    return null
}

interface ModalUserNotificationExpensesLogicProps {
    allRowData?: RowData
}

const useModalUserNotificationExpensesLogic = ({ allRowData }: ModalUserNotificationExpensesLogicProps) => {
    const [expensesType, setExpensesType] = useState<EmployeeExpenseRowTypeModelAdd[]>([])
    const [expenses, setExpenses] = useState<EmployeeExpenseModelType | null>(null)

    const getExpensesTypes = useCallback(async () => {
        const allExpensesTypes = await twinFetchPostJSON('/api/app/employeeExpense/getAllEmployeeExpensesRowType', {})
        setExpensesType(allExpensesTypes)
    }, [setExpensesType])

    const getExpensesRow = useCallback(async () => {
        const allExpenses = await twinFetchPostJSON('/api/app/employee/employeeExpense/getEmployeeExpenseInstance', { id: allRowData?.SubId })
        setExpenses(allExpenses)
    }, [setExpenses, allRowData])

    useEffect(() => {
        getExpensesTypes()
        getExpensesRow()
    }, [getExpensesTypes, getExpensesRow])

    return { expensesType, expenses }
}

export default ModalUserNotificationExpenses