import { faPartyHorn, faAlarmExclamation, faCheck, faTriangleExclamation } from '@fortawesome/pro-light-svg-icons'
import { RowData } from '../../../../baseComponents/TwinTable/types'
import TwinIcon from '../../../../baseComponents/TwinIcon'
import TwinTrans from '../../../../baseComponents/TwinTrans'
import { displayFormatedHourFromMins, getMinFromHoursMin } from '../../../../utils/globals/date'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import { parseStartAndEndTime } from '../functions'
import { TwinDictionary } from '../../../../utils/globals/dictionary'

interface RenderDateAndWeekDayProps {
    date: string
}

export const RenderDateAndWeekDay: React.FC<RenderDateAndWeekDayProps> = ({ date }) => {
    if(date === ''){
        return(
        <div className='uppercase'>
            <TwinTrans transKey='total'>Total</TwinTrans>
        </div>
        )
    }
    const parsedDate = new Date(date)
    return (
        <div>
            <span className='w-20 inline-block text-right mr-15'>{parsedDate.getDate()}</span>
            <span className='capitalize'>{parsedDate.toLocaleDateString(undefined, { weekday: 'long' })}</span>
        </div>
    )
}
interface ControlEmployeesRenderProps {
    allRowData: RowData

}
interface RenderWorkingTimeProps extends ControlEmployeesRenderProps {
}

export const RenderWorkingTime: React.FC<RenderWorkingTimeProps> = ({ allRowData }) => {
    if(!allRowData.journey){
        let extraInfo: JSX.Element | null = null
        if(allRowData.type === 'freeDay'){
            extraInfo = (
                <div className='flex items-center justify-between'>
                    <span className='mx-10 h-24 border-l border-gray-97'></span>
                    <TwinIcon className='mr-8' icon={faPartyHorn} /><TwinTrans transKey='exFreeDay'>¡Día festivo!</TwinTrans>
                </div>
            )
        } else if(allRowData.type === 'holidayDay'){
            extraInfo = (
                <div className='flex items-center justify-between'>
                    <span className='mx-10 h-24 border-l border-gray-97'></span>
                    <TwinIcon className='mr-8' icon={faPartyHorn} /><TwinTrans transKey='exHoliday'>¡Vacaciones!</TwinTrans>
                </div>
            )
        } else if(allRowData.type === 'totals'){
            return <div></div>
        }
        return (
            <div className='flex items-center'>
                <span>
                    <TwinTrans transKey='registerNotExpected'>No se espera registro</TwinTrans>
                </span>
                {extraInfo}
            </div>
        )
    }
    const renderThis: JSX.Element[] = []
    if(allRowData.journey.workingTime){
        for (const key in allRowData.journey.workingTime) {
            const hours = allRowData.journey.workingTime[key]
            renderThis.push(
                <div key={key}>
                    <span><TwinTrans transKey='fromShort'>De</TwinTrans> </span>
                    {displayFormatedHourFromMins(hours[0])}
                    <span> <TwinTrans transKey='aTo'>a</TwinTrans> </span>
                    {displayFormatedHourFromMins(hours[1])}
                </div>
            )
        }
    }
    return (
        <div className={'flex flex-col overflow-y-auto flex-auto h-full justify-center ' + (allRowData?.journey?.workingTime?.length >2 ? 'pt-12' : '')}>
            {renderThis}
        </div>
    )
}

interface RenderEntryOutWorkProps extends ControlEmployeesRenderProps {
    entry: boolean
}

export const RenderEntryOutWork: React.FC<RenderEntryOutWorkProps> = ({ allRowData, entry }) => {
    if (!allRowData.journey) {
        return <div></div>
    }
    const renderThis: JSX.Element[] = []
    if (allRowData?.timetables) {
        for (const key in allRowData.timetables) {
            const timetable = allRowData.timetables[key]
            const { startMin, endMin } = parseStartAndEndTime(timetable)
            const value = entry? startMin : endMin
            if (value === 0 && !entry) {
                renderThis.push(<div key={key}>-</div>)
            } else {
                renderThis.push(<div key={key}>{displayFormatedHourFromMins(value)}</div>)
            }
        }
    }
    return (
        <div className={'flex flex-col overflow-y-auto flex-auto h-full justify-center'+ (allRowData?.timetables?.length > 2 ? 'pt - 12' : '')}>
            {renderThis}
        </div>
    )
}



interface RenderWorkedHoursProps extends ControlEmployeesRenderProps {}


export const RenderWorkedHours: React.FC<RenderWorkedHoursProps> = ({ allRowData }) => {
    if (!allRowData?.journey && allRowData.type !== 'totals') {
        return <div></div>
    }
    let workedHours = 0
    if (allRowData?.totalDayTime) {
       workedHours = allRowData?.totalDayTime
    }
    if (allRowData.type === 'totals') {
        workedHours = allRowData?.totalMinsWorked || 0
    }
    return (
        <div>
            {displayFormatedHourFromMins(workedHours)}
        </div>
    )
}

interface RenderHoursToWorkProps extends ControlEmployeesRenderProps {
}


export const RenderHoursToWork: React.FC<RenderHoursToWorkProps> = ({ allRowData }) => {
    if (!allRowData?.journey && allRowData.type !== 'totals') {
        return <div></div>
    }
    if (allRowData?.journey && allRowData?.journey.flexible){
        return <span><TwinTrans transKey='flexibleTimetable'>Horario flexible</TwinTrans></span >
    }
    
    let hoursToDo = allRowData.totalMinsToDo
    
    if(allRowData.type === 'totals'){
        hoursToDo = allRowData?.totalMinsToDo || 0
    }
    return (
        <div>
            {displayFormatedHourFromMins(hoursToDo)}
        </div>
    )
}
interface RenderBalanceHoursProps extends ControlEmployeesRenderProps {
}


export const RenderBalanceHours: React.FC<RenderBalanceHoursProps> = ({ allRowData }) => {
    const now = new Date()
    const dayDate = new Date(allRowData.date)
    if ((!allRowData?.journey && allRowData.type !== 'totals') || now < dayDate) {
        return <div></div>
    }
    let balance = allRowData?.balance
    let balanceClassName = 'rounded-xl px-15 py-8 ' + ( balance === 0 ? 'balance_correct' : balance > 0? 'balance_more_hours' : 'balance_less_hours')
    if(allRowData.type === 'totals'){
        balance = allRowData?.balance || 0
        balanceClassName ='balance_total ' + ( balance > 0 ? 'balance_total_more_hours' : balance < 0 ? 'balance_total_less_hours' : '')
    }
    return (
        <div className={balanceClassName}>
            {displayFormatedHourFromMins(balance)}
        </div>
    )
}
interface RenderIconBalanceHoursProps extends ControlEmployeesRenderProps {
}


export const RenderIconBalanceHours: React.FC<RenderIconBalanceHoursProps> = ({ allRowData }) => {
    const {t} = useTwinTranslation()
    if (!allRowData?.journey && allRowData.type !== 'totals') {
        return <div></div>
    }
    let error: undefined | string = undefined
    let iconBalance: JSX.Element | null = null
    const dictWorkingTime: TwinDictionary = {}
    if (allRowData?.journey && allRowData?.journey.workingTime) {
        if(allRowData?.timetables){
            for (const timetable of allRowData?.timetables) {
                const startHour =  timetable.edited_start_time || timetable.og_start_time
                const startHourParsed = startHour.split(' ')[1].split(':')
                const startMin = getMinFromHoursMin(startHourParsed[0], startHourParsed[1])
                const endHour =  timetable.edited_end_time || timetable.og_end_time
                if(endHour){
                    const endHourParsed = endHour?.split(' ')[1].split(':')
                    const endMin = getMinFromHoursMin(endHourParsed[0], endHourParsed[1])
                    for (const hours of allRowData?.journey.workingTime) {
                        if(startMin<hours[1] ){
                            if (!dictWorkingTime[hours[0]]){
                                dictWorkingTime[hours[0]] = []
                            }
                            dictWorkingTime[hours[0]].push({startMin, endMin, timeToStart: hours[0], timeToEnd: hours[1]})
                            break
                        }
                    }
                }
            }
        } else if(new Date(allRowData.date) < new Date()) {
            iconBalance = <IconBalanceNotWorkedError error={t('notWorked', 'No ha trabajado ')} />
        }
    }
    for (const key in dictWorkingTime) {
        const work = dictWorkingTime[key]
        if(work.length){
            for (let i = 0; i < work.length; i++) {
                const element = work[i]
                if (i === 0 && (element.startMin < (element.timeToStart - allRowData.marginStartMin) || element.startMin > (element.timeToStart + allRowData.marginStartMin))) {
                    error = t('issuesInTimetable', 'Incidencia en el horario')
                }
                if (i === (work.length-1) && (element.endMin < (element.timeToEnd - allRowData.marginEndMin) || element.endMin > (element.timeToEnd + allRowData.marginEndMin))){
                    error = t('issuesInTimetable', 'Incidencia en el horario')
                }
            }
        }
    }
    if (error){
        iconBalance = <IconBalanceError error={error} />
    }
    if (allRowData?.type === 'absenceDay' || allRowData?.type === 'absenceJustifiedDay'){
        iconBalance = <IconAbsence type={allRowData?.type} />
    }
    return (
        <div>
            {iconBalance}
        </div>
    )
}

interface IconBalanceErrorProps {
    error: string
}

const IconBalanceError: React.FC <IconBalanceErrorProps> = ({error}) => {
    return (
        <div className='icon_balance_error_container flex items-center cursor-pointer relative'>
            <TwinIcon className='h-20 text-orange-DE ' icon={faAlarmExclamation} />
            <div className='ml-10 icon_balance_error px-10 py-10 bg-white rounded-xl'>{error}</div> 
        </div>
    )
}
interface IconBalanceNotWorkedErrorProps {
    error: string
}

const IconBalanceNotWorkedError: React.FC <IconBalanceNotWorkedErrorProps> = ({error}) => {
    return (
        <div className='icon_balance_error_container flex items-center cursor-pointer relative'>
            <TwinIcon className='h-20 text-red-BA ' icon={faTriangleExclamation} />
            <div className='ml-10 icon_balance_error px-10 py-10 bg-white rounded-xl'>{error}</div> 
        </div>
    )
}

interface IconAbsenceProps {
    type: string
}
const IconAbsence: React.FC<IconAbsenceProps> = ({ type }) => {
    const {t} = useTwinTranslation()
    const abscenceMsg: TwinDictionary= {
        absenceDay: t('nonJustifiedAbsenceApproved', 'Ausencia no justificada validada'),
        absenceJustifiedDay: t('justifiedAbsenceApproved', 'Ausencia justificada validada' )
    }
    return (
        <div className='icon_balance_error_container flex items-center cursor-pointer relative'>
            <TwinIcon className='h-20 text-green-43 ' icon={faCheck} />
            <div className='ml-10 icon_balance_error px-10 py-10 bg-white rounded-xl'>
                {abscenceMsg[type] || ''}
            </div> 
        </div>
    )
}