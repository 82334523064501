import { useCallback, useEffect, useRef, useState } from 'react'
import Input, { InputWithLabelMarginProps } from '..'
import TwinIcon from '../../../baseComponents/TwinIcon'
import { faChevronDown, faChevronUp, faClock } from '@fortawesome/pro-light-svg-icons'
import { checkInputHourValidity } from './functions'
import { OnChangeInputHourTy } from './types'
import { addClassName, removeClassName } from '../../../utils/globals/components'
import useOnClickOutside from '../../../utils/hooks/useOnClickOut'
import { checkHourValidOnChange, displayFormatedHour, getHoursMinFromMin, getMinFromHoursMin, parseHourStr } from '../../../utils/globals/date'
import { HourMinTy } from '../../../utils/globals/types'
import './inputHour.sass'

export type InputHourProps = InputWithLabelMarginProps & InputHourLogicProps & {
}

const InputHour: React.FC<InputHourProps> = ({ className, value, readOnly, label, required, name, onFinalChange, maxHours=23, ...rest }) => {
    const { ref, finalHour, myOnChange, myOnFocusParent, myTmpHour, onBlurInput, myFinalChange, openHourModal } = useInputHourLogic({ value, readOnly, onFinalChange, maxHours })
    return (
        <div className={'input_hour_container input_field relative flex w-full flex-col ' + (className || '')} >
            <label className="input_hour_label text-gray-51 cursor-pointer ">{label}{required ? ' *' : ''}</label>
            <div className={'flex w-full items-center cursor-pointer'} onClick={myOnFocusParent} ref={ref}>
                <Input className='w-50' {...rest} readOnly={readOnly} value={myTmpHour} onChange={myOnChange} onBlur={onBlurInput} />
                <TwinIcon icon={faClock} />
                {name ? <input type='hidden' name={name} value={String(finalHour.mins)} /> : null}
                {openHourModal && <ModalInputHour onFinalChangeModal={myFinalChange} value={finalHour.formated}  {...{ maxHours }} />}
            </div>
            <div className='input_border' />
        </div>
    )
}

export const RectangularInputHour: React.FC<InputHourProps> = ({ className, value, readOnly, label, required, name, onFinalChange, maxHours=23, ...rest }) => {
    const { ref, finalHour, myOnChange, myOnFocusParent, myTmpHour, onBlurInput, myFinalChange, openHourModal } = useInputHourLogic({ value, readOnly, onFinalChange, maxHours })
    return (
        <div className={' relative flex w-75 ' + (className || '')} >
            <div className={'rectangular_input_hour_container ' + (readOnly ? ' rectangular_readonly' : '')} onClick={myOnFocusParent} ref={ref} >
                <Input className='rectangular_input_hour' {...rest} readOnly={readOnly} value={myTmpHour} onChange={myOnChange} onBlur={onBlurInput} />
                <TwinIcon className='cursor-pointer' icon={faClock} />
                {name ? <input type='hidden' name={name} value={String(finalHour.mins)} /> : null}
                {openHourModal && <ModalInputHour onFinalChangeModal={myFinalChange} value={finalHour.formated} {...{maxHours}} />}
            </div> 
        </div>
    )
}


export interface InputHourLogicProps {
    value?: string
    readOnly?: boolean
    onFinalChange?: OnChangeInputHourTy
    maxHours?: number
}


export const useInputHourLogic = ({ readOnly, value, onFinalChange, maxHours }: InputHourLogicProps) => {
    const ref = useRef<any>(null)
    const [finalHour, setFinalHour] = useState<{mins: number, formated: HourMinTy}>({mins: parseInt(value || '0'), formated: getHoursMinFromMin(parseInt(value || '0'))})
    const [myTmpHour, setMyTmpHour] = useState<string>(displayFormatedHour(getHoursMinFromMin(parseInt(value || '0'))))
    const [openHourModal, setOpenHourModal] = useState<boolean>(false)

    const myOnChange = useCallback((value: string) => {
        const validQuantity = checkHourValidOnChange(value)
        if (value === '' || validQuantity) {
            setMyTmpHour(value)
        }
    }, [setMyTmpHour])

    const myOnFocusParent: React.MouseEventHandler<HTMLDivElement> = useCallback((e) => {
        if (!openHourModal) {
            if (!readOnly) {
                addClassName('input_hour_focus', e.currentTarget.parentElement)
                const inputRef: any = e.currentTarget.firstChild
                inputRef.focus()
                setOpenHourModal(true)
            }
        }
    }, [readOnly, setOpenHourModal, openHourModal])

    const myFinalChange = useCallback((hour: HourMinTy) => {
        const mins = getMinFromHoursMin(hour.hours, hour.mins)
        setFinalHour({formated: hour, mins})
        setMyTmpHour(displayFormatedHour(hour))
        onFinalChange?.(mins, ref)
    }, [setFinalHour, onFinalChange])

    const onBlurInput: React.FocusEventHandler<HTMLInputElement> = useCallback((e) => {
        const currentTarget = e.currentTarget
        const currentTargetParent = currentTarget.parentElement?.parentElement
        removeClassName('input_hour_focus', currentTargetParent)
        const timeParsed = checkInputHourValidity(currentTarget.value, maxHours)
        if (timeParsed) {
            removeClassName('input_hour_error', currentTargetParent)
            myFinalChange(timeParsed)
        } else {
            if (currentTargetParent) {
                addClassName('input_hour_error', currentTargetParent)
            }
            setMyTmpHour(displayFormatedHour(finalHour.formated))
        }
    }, [finalHour, setMyTmpHour, myFinalChange, maxHours])


    useOnClickOutside(ref, () => {
        setOpenHourModal(false)
    })

    return { ref, finalHour, myTmpHour, openHourModal, myOnChange, myOnFocusParent, onBlurInput, myFinalChange }
}

interface ModalInputHourProps {
    onFinalChangeModal: (hour: HourMinTy) => void
    value: HourMinTy
    maxHours: number
    className?: string
}


export const ModalInputHour: React.FC<ModalInputHourProps> = ({ maxHours, className, ...rest }) => {
    return (
        <div className={'modal_input_hour flex items-center ' + (className || '')}>
            <HourChanger {...rest} maxHours={maxHours} />
            <MinChanger {...rest} />
        </div>
    )
}

interface HourChangerProps extends HourChangerLogicProps {
}

const HourChanger: React.FC<HourChangerProps> = ({ value, onFinalChangeModal, maxHours }) => {
    const { myValue, setTime, optionsTime } = useHourChangerLogic({ value, onFinalChangeModal, maxHours })
    return (
        <TimeChanger value={myValue} {...{ setTime, optionsTime }} />
    )
}

interface HourChangerLogicProps {
    value: HourMinTy
    maxHours: number
    onFinalChangeModal: (hour: HourMinTy) => void
}

const useHourChangerLogic = ({ value, onFinalChangeModal, maxHours }: HourChangerLogicProps) => {
    const setTime = useCallback((time: number) => {
        if(maxHours===Infinity){
            if (time < 0) {
                time = 0
            }
        }else {
            if (time > 23) {
                time = time - 24
            } else if (time < 0) {
                time = time + 24
            }
        }
        onFinalChangeModal({mins: value.mins, hours: time})
    }, [onFinalChangeModal, value.mins, maxHours])

    const myValue = value.hours

    let optionsTime: number[] = [
        myValue - 2 < 0? myValue + 22 : myValue - 2,
        myValue - 1 < 0? myValue + 23 : myValue - 1,
        myValue,
        myValue + 1 > 23 ? myValue - 23 : myValue + 1,
        myValue + 2 > 23 ? myValue - 22 : myValue + 2
    ]
    if(maxHours === Infinity){
        optionsTime=[
            myValue - 2 < 0 ? 0 : myValue - 2,
            myValue - 1 < 0 ? 0 : myValue - 1,
            myValue,
            myValue + 1,
            myValue + 2
        ]
    }
    return { myValue, setTime, optionsTime }
}

interface MinChangerProps extends MinChangerLogicProps {
}

const MinChanger: React.FC<MinChangerProps> = ({ value, onFinalChangeModal }) => {
    const { myValue, setTime, optionsTime } = useMinChangerLogic({ value, onFinalChangeModal })
    return (
        <TimeChanger value={myValue} {...{ setTime, optionsTime }} />
    )
}

interface MinChangerLogicProps {
    value: HourMinTy
    onFinalChangeModal: (hour: HourMinTy) => void
}

const useMinChangerLogic = ({ value, onFinalChangeModal }: MinChangerLogicProps) => {
    const setTime = useCallback((time: number) => {
        if (time > 59) {
            time = time - 60
        } else if (time < 0) {
            time = time + 60
        }
        onFinalChangeModal({ hours: value.hours, mins: time })
    }, [onFinalChangeModal, value.hours])
    const myValue = value.mins
    const optionsTime: number[] = [
        myValue - 2 < 0 ? myValue + 58 : myValue - 2,
        myValue - 1 < 0 ? myValue + 59 : myValue - 1,
        myValue,
        myValue + 1 > 59 ? myValue - 59 : myValue + 1,
        myValue + 2 > 59 ? myValue - 58 : myValue + 2
    ]
    return { myValue, setTime, optionsTime }
}


interface TimeChangerProps extends TimerChangerLogicProps {
    optionsTime: number[]
}

const TimeChanger: React.FC<TimeChangerProps> = ({ value, setTime, optionsTime }) => {
    const { handleMouseDownAdd, handleMouseDownReduce } = useTimerChangerLogic({ setTime, value })
    return (
        <div className='flex flex-col justify-center'>
            <TwinIcon className='icon_change_hour' icon={faChevronUp} onClick={() => setTime(value - 1)} onMouseDown={handleMouseDownReduce} />
            <div className='time_changer_value' onClick={() => setTime(value - 2)}>{parseHourStr(optionsTime[0])}</div>
            <div className='time_changer_value' onClick={() => setTime(value - 1)}>{parseHourStr(optionsTime[1])}</div>
            <div className='time_changer_value selected'>{parseHourStr(optionsTime[2])}</div>
            <div className='time_changer_value' onClick={() => setTime(value + 1)}>{parseHourStr(optionsTime[3])}</div>
            <div className='time_changer_value' onClick={() => setTime(value + 2)}>{parseHourStr(optionsTime[4])}</div>
            <TwinIcon className='icon_change_hour' icon={faChevronDown} onClick={() => setTime(value + 1)} onMouseDown={handleMouseDownAdd} />
        </div>
    )
}
interface TimerChangerLogicProps {
    setTime: (value: number) => void
    value: number
}

const useTimerChangerLogic = ({ value, setTime }: TimerChangerLogicProps) => {
    const [myInterval, setMyInterval] = useState<NodeJS.Timer | null>(null)

    const handleMouseDownAdd = useCallback(() => {
        setMyInterval(() => { let time = value; return setInterval(() => {time++; setTime(time)}, 150)})
    }, [setTime, value])
    const handleMouseDownReduce = useCallback(() => {
        setMyInterval(() => { let time = value; return setInterval(() => { time--; setTime(time) }, 150) })
    }, [setTime, value])

    const handleMouseUp = useCallback(() => {
        if (myInterval) {
            clearInterval(myInterval)
        }
    }, [myInterval])


    useEffect(() => {
        if (myInterval) {
            document.addEventListener('mouseup', handleMouseUp)
        }
        return () => {
            document.removeEventListener('mouseup', handleMouseUp)
        }
    }, [myInterval, handleMouseUp])

    return { handleMouseDownAdd, handleMouseDownReduce }
}

export default InputHour