import { faArrowRotateBack } from '@fortawesome/pro-light-svg-icons'
import { useCallback } from 'react'
import Checkbox from '../../../../forms/Checkbox'
import { SelectedColumnsAndFilters } from '../../../../utils/hooks/useConfigColumns'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import TwinIcon from '../../../TwinIcon'
import TwinTrans from '../../../TwinTrans'
import { ModalCreateEditStructureLayout } from '../../ModalCreateEdit'
import { ModalConfigColumnsBaseProps } from '../types'
import TwinForm from '../../../../forms/TwinForm'
import DropdownVerticalSortList from '../../../Sorts/DropdownVerticalSortList'
import { DropdownVerticalSortItem } from '../../../Sorts/DropdownVerticalSortList/types'
import ModalConfigColumnsFiltersComponentsRenderer from './ModalConfigColumnsFiltersComponents'
import { TwinDictionary } from '../../../../utils/globals/dictionary'
import HeaderModalConfigColumns from '../HeaderModalConfigColumns'

interface ModalConfigColumnsFiltersProps extends ModalConfigColumnsFiltersLogicProps {
}

const ModalConfigColumnsFilters: React.FC<ModalConfigColumnsFiltersProps> = ({ tmpColumns, columns, onSave, ...logic }) => {
    const { changeChecked, changeOrder, resetSelected, changeSingleField } = useModalConfigColumnsFiltersLogic({ tmpColumns, columns, onSave, ...logic })
    const { t } = useTwinTranslation()

    const firstColumn: JSX.Element[] = []
    const secondColumn: DropdownVerticalSortItem[] = []
    const premiumColumns: JSX.Element[] = []
    for (const key in columns) {
        const item = columns[key]
        if ((item.label || item.simpleLabel) && item.havePermission !== false && item.searchableComponent) {
            if (item.haveModule !== false) {
                const index = Object.keys(tmpColumns.filters).indexOf(item.id)
                const selected = index !== -1
                firstColumn.push(<div className='mt-15' key={key}><Checkbox label={item.simpleLabel || item.label as any} onChange={() => changeChecked(item.id)} checked={selected} /></div>)
                if (selected) {
                    secondColumn.push({
                        name: item.simpleLabel || item.label as any,
                        id: item.id,
                        order: index + 1,
                        component: <ModalConfigColumnsFiltersComponentsRenderer changeSingleField={changeSingleField} {...item} value={tmpColumns.filters[item.id]} component={item.searchableComponent.component} extraComponentData={item.searchableComponent.extraComponentData} />
                    })
                }
            } else {
                premiumColumns.push(<div className='mt-15' key={key}><Checkbox label={item.simpleLabel || item.label as any} onChange={() => changeChecked(item.id)} checked={true} readOnly={true} /></div>)
            }
        }
    }
    secondColumn.sort((a, b) => a.order - b.order)
    if (secondColumn[secondColumn.length - 1]) {
        secondColumn[secondColumn.length - 1].component = (<ModalConfigColumnsFiltersComponentsRenderer {...secondColumn[secondColumn.length - 1].component.props} isLast={true} />)        
    }
    let secondColumnRender: JSX.Element | null = <DropdownVerticalSortList items={secondColumn} setItems={(setItemsFake) => changeOrder(setItemsFake(secondColumn))} />
    if (!secondColumn.length) {
        secondColumnRender = <NoFilters />
    }
    return (
        <TwinForm beforeSubmitHandler={onSave} className='flex flex-col flex-auto'>
            <ModalCreateEditStructureLayout loading={false} translations={{
                title: <HeaderModalConfigColumns {...{ tmpColumns, columns, setTmpColumns: logic.setTmpColumns, name: logic.name }} />,
                button: t('save', 'Guardar')
            }} haveButtonPermissions={true} className='modal_negative_margin'>
                <div className='overflow-auto h-full w-full'>
                    <div className='flex flex-wrap w-full h-fit '>
                        <div className='w-fit max-w-300'>
                            <div className='flex items-center mb-20'>
                                <h4 className='mr-18 '><TwinTrans transKey='filtersToShow'>Mostrar filtros</TwinTrans></h4>
                                <div className='flex items-center text-green-21 cursor-pointer' onClick={resetSelected}>
                                    <TwinIcon icon={faArrowRotateBack} fontSize={14} className='mr-8' />
                                    <span className='mt-2 lh-14'><TwinTrans transKey='uncheckAll'>Desmarcar todo</TwinTrans></span>
                                </div>
                            </div>
                            {firstColumn}
                            {premiumColumns.length ? <div className='mt-20'>
                                <h2><TwinTrans transKey='columnsCouldBuy'>Columnas que podrías comprar</TwinTrans></h2>
                                {premiumColumns}
                            </div> : null}
                        </div>
                        <div className='border-r border-gray-D6 w-1 mx-35' ></div>
                        <div className='flex flex-col min-h-full flex-auto w-1'>
                            <h4 className='mb-20'><TwinTrans transKey='orderAndConfigureSelectedFilters'>Ordena y configura los filtros seleccionados</TwinTrans></h4>
                            {secondColumnRender}
                        </div>
                    </div>
                </div>
            </ModalCreateEditStructureLayout>
        </TwinForm>
    )
}

interface ModalConfigColumnsFiltersLogicProps extends ModalConfigColumnsBaseProps { }

const useModalConfigColumnsFiltersLogic = ({ setTmpColumns }: ModalConfigColumnsFiltersLogicProps) => {
    const changeChecked = useCallback((itemKey: string) => {
        setTmpColumns((oldTmp) => {
            let tmpCopy: SelectedColumnsAndFilters = JSON.parse(JSON.stringify(oldTmp))
            const item = tmpCopy.filters[itemKey]
            if (item) {
                delete tmpCopy.filters[itemKey]
            } else {
                tmpCopy.filters = { ...tmpCopy.filters, [itemKey]: {} }
            }
            return { ...tmpCopy }
        })
    }, [setTmpColumns])

    const changeOrder = useCallback((items: DropdownVerticalSortItem[]) => {
        setTmpColumns((oldTmp) => {
            const copy: SelectedColumnsAndFilters = JSON.parse(JSON.stringify(oldTmp))
            let filters: SelectedColumnsAndFilters['filters'] = {}
            for (const item of items) {
                filters[item.id] = copy.filters[item.id] || {}
            }
            return { ...copy, filters }
        })
    }, [setTmpColumns])

    const resetSelected = useCallback(() => {
        setTmpColumns((oldTmp) => {
            const copy: SelectedColumnsAndFilters = JSON.parse(JSON.stringify(oldTmp))
            const myColumns = { ...copy, filters: {} }
            return myColumns
        })
    }, [setTmpColumns])

    const changeSingleField = useCallback((id: string, value: TwinDictionary) => {
        setTmpColumns((oldTmp) => {
            const copy: SelectedColumnsAndFilters = JSON.parse(JSON.stringify(oldTmp))
            const myColumns = { ...copy, filters: { ...copy.filters, [id]: value } }
            return myColumns
        })
    }, [setTmpColumns])

    return { setTmpColumns, changeChecked, changeOrder, resetSelected, changeSingleField }
}

interface NoFiltersProps {}

const NoFilters: React.FC<NoFiltersProps> = () => {
    return (
        <div className='no_filters flex flex-col items-center'>
            <img src='/ilustracion-filtros.svg' alt='' />
            <span className='light20'><TwinTrans transKey='alreadyYouDontAddFilters'>Todavía no has añadido ningún filtro</TwinTrans></span>
        </div>
    )
}


export default ModalConfigColumnsFilters