import { CustomerProjectTypeModelType } from '@teinor/erp/types/company/customer/customerProjectType'
import { LittleModalCreateEdit } from '../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../../../../../baseComponents/ModalsLayouts/types'
import { permissionCheck } from '../../../../../../../baseComponents/PermissionChecker/function'
import { FormRender } from '../../../../../../../forms/FormRenderer/types'
import { valueOrDefaultValue } from '../../../../../../../utils/globals/valueOrDefaultValue'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import { getAllTaskTypes } from '../../../../../../../utils/reducers/getters'
import useCustomerTypesRandomColor from '../../../../../../../utils/hooks/customer/useCustomerTypesRandomColor'


interface ModalCECustomerProjectTypeProps  extends ModalEditComponentProps{}

const ModalCECustomerProjectType: React.FC<ModalCECustomerProjectTypeProps> = ({ userPermissions, allRowData, ...rest }) => {
    const { t } = useTwinTranslation()
    const { getDefaultRandomColorCustomerTypes} = useCustomerTypesRandomColor()
    const fields: FormRender<CustomerProjectTypeModelType> = [
        {
            cols: 1,
            elements: [
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'id',
                    component: 'InputHidden'
                },
                {
                    name: 'TaskTypeId',
                    component: 'CustomSelect',
                    label: t('taskType', 'Tipo de tarea'),
                    items: getAllTaskTypes() || {},
                },
                {
                    name: 'color',
                    label: t('color', 'Color'),
                    component: 'InputColor',
                    defaultValue: allRowData?.color || getDefaultRandomColorCustomerTypes()
                },
            ]
        },
    ]
    if (allRowData && userPermissions) {
        const parsedFields = valueOrDefaultValue(fields, userPermissions, allRowData)
        return (<LittleModalCreateEdit fields={parsedFields} url={'/api/app/customer/customerProject/customerProjectTypes/updateCustomerProjectType'} translations={{ title: t('editCustomerProjectType', 'Editar tipo de proyecto'), button: t('save', 'Guardar') }} haveButtonPermissions={permissionCheck(userPermissions, 'update')} {...rest}></LittleModalCreateEdit>)
    }
    return (<LittleModalCreateEdit fields={fields} url={'/api/app/customer/customerProject/customerProjectTypes/createCustomerProjectType'} translations={{
        title: t('createCustomerProjectType', 'Crear tipo de proyecto'),
        button: t('create', 'Crear')
    }} haveButtonPermissions={true} {...rest} />)
}

export default ModalCECustomerProjectType