import { WhiteBox } from '../../AppLayout/WhiteBox'
import { BigTitleAndRightLayout } from '../TitleAndRightLayout'

interface TitleAndWhiteBoxLayoutProps {
    title: string
    children: React.ReactNode
    RightHeader?: JSX.Element
}

const TitleAndWhiteBoxLayout: React.FC<TitleAndWhiteBoxLayoutProps> = ({children, ...rest}) => {
    return (
        <BigTitleAndRightLayout {...rest}>
            <WhiteBox className='flex flex-col flex-auto px-40 py-50'>
                {children}
            </WhiteBox>
        </BigTitleAndRightLayout>
    )
}

export default TitleAndWhiteBoxLayout