import { useCallback, useEffect, useState } from 'react'
import { twinFetchPostJSON } from '../../../../utils/globals/data'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import { VirtualTableListingStateLess } from '../../../TwinTable/VirtualTableListing'
import { CustomRenderDate } from '../../../TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../TwinTable/VirtualTableListing/Subcomponents/types'
import { TaskModelType } from '@teinor/erp/types/company/task'


interface DSBAllTasksTableProps extends TableAllTasksProps {
    title: string
}

const DSBAllTasksTable: React.FC<DSBAllTasksTableProps> = ({title, start_date, end_date}) => {
    return (
        <div className='flex flex-auto flex-col tce_employee_holidays_calendar'>
            <h3 className='pb-15'>{title}</h3>
            <TableAllTasks start_date={start_date} end_date={end_date}/>
        </div>
    )
}

interface TableAllTasksProps extends TableAllTasksLogicProps {

}

const TableAllTasks: React.FC<TableAllTasksProps> = ({start_date, end_date}) => {
    const { t } = useTwinTranslation()
    const { data } = useTableAllTasksLogic({start_date, end_date})
    const columns: ColumnTableSchema<TaskModelType> = [
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
        },
        {
            id: 'description',
            dataKey: 'description',
            label: t('description', 'Descripción'),
        },
        {
            id: 'start_date',
            dataKey: 'end_date',
            label: t('from', 'Desde'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />,
        },
        {
            id: 'end_date',
            dataKey: 'end_date',
            label: t('to', 'Hasta'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />,
        },
    ]

    return (
        <div className='flex-auto'>
            <VirtualTableListingStateLess tableData={data} rowHeight={48} headerHeight={48} name='DSBAllTasksTableDashboard' columns={columns} setSelectedColumnsAndFilters={() => { }} updateEmployeeParameter={false} />
        </div>
    )
}

interface TableAllTasksLogicProps {
    start_date: Date
    end_date: Date
}

const useTableAllTasksLogic = ({start_date, end_date}: TableAllTasksLogicProps) => {
    const [data, setData] = useState<TaskModelType[] | null>(null)
    const getData = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/task/getAllTaskForCalendar', { start_date, end_date })
        if (result) {
            setData(result)
        }
    }, [setData, start_date, end_date])

    useEffect(() => {
        getData()
    }, [getData])

    return { data }
}

export default DSBAllTasksTable