import { EmployeeModelType } from '@teinor/erp/types/company/userInner/employee'
import SingleFormLayout from '../../../../../../../baseComponents/Layout/SingleFormLayout'
import { FormRender } from '../../../../../../../forms/FormRenderer/types'
import { getDictionaryStates } from '../../../../../../../utils/globals/countries'
import { valueOrDefaultValue } from '../../../../../../../utils/globals/valueOrDefaultValue'
import useCountryState from '../../../../../../../utils/hooks/useCountry'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import { useTypeIdNumber } from '../../../../../../../utils/hooks/useTypeIdNumber'
import { getDictionaryCountries } from '../../../../../../../utils/reducers/getters'
import { ComponentWithPermissions } from '../../../../../../../baseComponents/CreateRoutering/types'


type MainEmployeeProfileProps = ComponentWithPermissions & {
    basicData: EmployeeModelType | null
    getData: () => Promise<void>
}

const MainEmployeeProfile: React.FC<MainEmployeeProfileProps> = ({ getData, basicData }) => {
    const { typesIdNumber } = useTypeIdNumber()
    const { country, setCountry } = useCountryState({ defaultCountry: basicData?.country })
    const { t } = useTwinTranslation()
    const fields: FormRender<EmployeeModelType> = [
        {
            cols: 2,
            title: t('employeeInfo', 'Información del empleado'),
            elements: [
                {
                    name: 'id',
                    component: 'InputHidden'
                },
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'surname',
                    label: t('surname', 'Apellido'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'surname2',
                    label: t('surname2', 'Segundo apellido'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'code',
                    label: t('employeeCode', 'Código de Empleado'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    readOnly: true
                },
                {
                    name: 'type_id_number',
                    label: t('typeIdNumber', 'Tipo de documento identificación'),
                    component: 'CustomSelect',
                    items: typesIdNumber,
                    value: 0
                },
                {
                    name: 'id_number',
                    label: t('id_number', 'DNI'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'profile_image',
                    label: t('userImage', 'Imagen de usuario'),
                    component: 'ImageInput',
                }
            ]
        },
        {
            cols: 2,
            title: t('address', 'Dirección'),
            elements: [
                {
                    name: 'address',
                    label: t('address', 'Dirección'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'additional_address',
                    label: t('additional_address', 'Dirección adicional'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'postal_code',
                    label: t('postalCode', 'Código Postal'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'city',
                    label: t('city', 'Ciudad'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'country',
                    label: t('country', 'País'),
                    component: 'CustomSelectWithSearchBar',
                    items: getDictionaryCountries(),
                    value: basicData?.country,
                    onChange: (value: string) => setCountry(value)
                },
                {
                    name: 'state',
                    label: t('state', 'Provincia'),
                    component: 'CustomSelectWithSearchBar',
                    sortItems: true,
                    items: getDictionaryStates(country),
                    value: basicData?.state
                },
            ]
        },
        {
            cols: 2,
            title: t('contactInfo', 'Información de contacto'),
            elements: [
                {
                    name: 'email',
                    label: t('email', 'Email'),
                    component: 'InputWithLabelMargin',
                    type: 'email'
                },
                {
                    name: 'phone',
                    label: t('phone', 'Teléfono'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'mobile',
                    label: t('mobile', 'Móvil'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
            ]
        }
    ]
    const parsedFields = valueOrDefaultValue(fields, { n: 4, permission: 'delete' }, basicData)
    return (
        <SingleFormLayout action='/api/app/employee/updateMyEmployee' onSubmit={getData} sections={parsedFields} userPermissions={{ n: 4, permission: 'delete' }} imagepath={['employee', String(basicData?.id)]} />
    )
}


export default MainEmployeeProfile