import { SueModelType } from '@teinor/erp/types/company/sue'
import SueComments from './SueComments'
import useIsLoading from '../../../../../../../../utils/hooks/useIsLoading'
import { ComponentWithPermissions } from '../../../../../../../../baseComponents/CreateRoutering/types'
import SueActivity from './SueActivity'

interface SueActivityTabProps extends ComponentWithPermissions {
    sueData: SueModelType
    getSueData: () => Promise<void>
}

const SueActivityTab: React.FC<SueActivityTabProps> = ({sueData, getSueData, userPermissions}) => {
    const { endLoading } = useIsLoading()

    return (
        <div className='overflow-auto flex-auto'>
            <SueActivity sueStatusDates={sueData.SueStatusDates} />
            <SueComments {...{endLoading, getSueData, sueData, userPermissions}} />
        </div>
    )
}

export default SueActivityTab