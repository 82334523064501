import { getAllPossiblePermissions, PermissionThreeKeys, WhatCanYouDoInversedKeys } from '@teinor/erp/permissions';
import { defaultRowRenderer, TableRowProps } from 'react-virtualized/dist/es/Table';
import { DictPermissions } from '.';
import { IdSpecialPermissions, PermissionTabRow } from './types';
import { TwinDictionary } from '../../../../../../../../utils/globals/dictionary';

export const checkPermissionByParent = (data: DictPermissions, isRoleAdmin: boolean) => {
    for (const idPermission in data) {
        if (isRoleAdmin) {
            data[idPermission] = 4
            continue
        }
        if (idPermission.includes('.')) {
            let major = 0 as WhatCanYouDoInversedKeys
            const allPossibleRoutePermissions = getAllPossiblePermissions(idPermission as PermissionThreeKeys)
            for (const permission of allPossibleRoutePermissions) {
                const myPermissionN = data[permission] as WhatCanYouDoInversedKeys
                if (myPermissionN > major) {
                    major = myPermissionN
                }
            }
            data[idPermission] = major
        }
    }
    return data
}

interface DictionaryTableData {
    [key: string]: number;
}

export const createTableDataDictionaryPermissions = (tableData: PermissionTabRow[] | null) => {
    const dictionary: DictionaryTableData = {}
    if (tableData) { 
        for (const data of tableData) {
            if (data.type === 'row' && data.value) {
                dictionary[data.idPermission] = data.value
            }
        }
    }
    return dictionary
}



export const parseDataPermissionTable = (permissionsTranslate: TwinDictionary, resultDict: DictPermissions, GroupId: string) => {
    const { resultParsed, dictCounter } = addParentSectionsPermissions(permissionsTranslate, resultDict, GroupId)
    const data = checkIsAdminForPermissionTable(resultParsed, GroupId)
    const { tableData, sectionCounter } = countTablePermissionRows(data)
    for (const index in sectionCounter) {
        const counterIndex = tableData[parseInt(index)].sectionKey
        updateTotalValuesPermissions(tableData, dictCounter[counterIndex], parseInt(index))
    }
    return tableData
}

const addParentSectionsPermissions = (permissionsTranslate: TwinDictionary, resultDict: DictPermissions, GroupId: string) => {
    const resultParsed: PermissionTabRow[] = []
    const dictCounter: {[key: string]: CounterValues} = {}
    for (const sectionKey in permissionsTranslate) {
        const section = permissionsTranslate[sectionKey]
        dictCounter[sectionKey] = {
            1: 0,
            2: 0,
            3: 0,
            4: 0
        }
        let titleIdPermission: IdSpecialPermissions = 'generic'
        if(sectionKey === 'special'){
            titleIdPermission = 'special'
        }
        resultParsed.push({ 'GroupId': GroupId, idPermission: titleIdPermission, type: 'section', title: section.title, value: undefined, sectionKey })
        resultParsed.push({ 'GroupId': GroupId, idPermission: titleIdPermission, type: 'config', title: '', value: undefined, sectionKey })
        for (const permissionKey in section.permissions) {
            const translatedTitle = section.permissions[permissionKey]
            resultParsed.push({ 'GroupId': GroupId, idPermission: permissionKey as PermissionThreeKeys, type: 'row', title: translatedTitle, value: resultDict[permissionKey], sectionKey  })
            const permission = resultDict[permissionKey]
            if (permission) {
                dictCounter[sectionKey][permission]++
            }
        }
    }
    return { resultParsed, dictCounter }
}

const checkIsAdminForPermissionTable = (tableData: PermissionTabRow[], GroupId: string) => {
    tableData.forEach((data: PermissionTabRow) => {
        if (parseInt(String(GroupId)) === 1) {
            data.value = 4
        }
    });
    return tableData
}

const countTablePermissionRows = (tableData: PermissionTabRow[]) => {
    let sectionCounter: TwinDictionary = { 1 : 0}
    let currentParent = 1
    for (let i = 0; i < tableData.length; i++) {
        const data = tableData[i]
        const prevData = tableData?.[i-1] || tableData[0]
        if (prevData.sectionKey !== data.sectionKey){
            currentParent = i+1
            sectionCounter[currentParent] = 0
        }
        if (data.title !== "" && data.type === "row" && prevData.sectionKey === data.sectionKey) {
            sectionCounter[currentParent]++
            data.sectionIndexCounter = currentParent
        }
    }
    for (const pos in sectionCounter) {
        const counter = sectionCounter[parseInt(String(pos))]
        tableData[parseInt(String(pos))].maxRows = counter
        tableData[parseInt(String(pos))].totalValues = {
            1: 0,
            2: 0,
            3: 0,
            4: 0
        }
    }
    return { tableData, sectionCounter }
}

const updateTotalValuesPermissions = (dataPermissions: PermissionTabRow[], counter: CounterValues, index: number) => {
    let sum = 0
    if (dataPermissions[index].totalValues) {
        for (let j = 4; j >= 1; j--) {
            sum += counter[j]
            // @ts-ignore
            dataPermissions[index].totalValues[j] += sum
        }
    }
}


interface ExtraParamsProcessCheckedRow {
    counter: CounterValues
    index: number
    newPermission: WhatCanYouDoInversedKeys
}

const processCheckedRow = (dataPermissions: PermissionTabRow[], extraParams: ExtraParamsProcessCheckedRow) => {
    const { counter, index, newPermission } = extraParams
    let prevValue = dataPermissions[index].value
    const isUnCheckingCurrentPermission = prevValue && prevValue === newPermission
    const isChekingNextPermission = prevValue && prevValue < newPermission
    const isUncheckingPreviousPermission = prevValue && prevValue > newPermission
    if (isUnCheckingCurrentPermission) {
        dataPermissions[index].value = undefined
        if (prevValue) {
            counter[prevValue]--
        }
    } else {
        dataPermissions[index].value = newPermission
        if (prevValue && isChekingNextPermission) {
            counter[prevValue]--
            counter[newPermission]++
        } else if (prevValue && isUncheckingPreviousPermission) {
            counter[newPermission]++
            counter[prevValue]--
        } else if (!prevValue) {
            counter[newPermission]++
        }
    }
}
interface ExtraParamsParseChild {
    counter: CounterValues
    newPermission: WhatCanYouDoInversedKeys
    parentIsGoingBack: boolean
    index: number
    prevValueChild: number | undefined
    permission: number | undefined
}

const parseChild = (dataPermissions: PermissionTabRow[], extraParams: ExtraParamsParseChild) => {
    const { counter, newPermission, parentIsGoingBack, index, prevValueChild, permission } = extraParams
    const isChekingNextPermission = prevValueChild && prevValueChild < newPermission
    const isUncheckingPreviousPermission = prevValueChild && prevValueChild > newPermission
    if (parentIsGoingBack || (isChekingNextPermission)) {
        dataPermissions[index].value = permission
        counter[newPermission]++
        if (prevValueChild) {
            counter[prevValueChild]--
        }
    } else if (!prevValueChild) {
        dataPermissions[index].value = permission
        counter[newPermission]++
    } else if (!permission && prevValueChild >= newPermission) {
        dataPermissions[index].value = undefined
    }

    if (!permission) {
        counter[newPermission]--
        if (isUncheckingPreviousPermission) {
            if (prevValueChild) {
                counter[prevValueChild]--
            }
            counter[newPermission]++
        }
    }
}

interface ExtraParamsParseAllParentChildren {
    counter: CounterValues
    index: number
    newPermission: WhatCanYouDoInversedKeys
    prevValue: number | undefined
}

const parseAllParentChildren = (dataPermissions: PermissionTabRow[], extraParams: ExtraParamsParseAllParentChildren) => {
    const { counter, index, newPermission, prevValue } = extraParams
    const permission = dataPermissions[index].value
    const idPermission = dataPermissions[index].idPermission
    const parentIsGoingBack = (prevValue && permission) && prevValue > permission ? true : false
    for (let i = index + 1; i < dataPermissions.length; i++) {
        let index = i
        const prevValueChild = dataPermissions[index].value
        if (dataPermissions[index].idPermission.includes(idPermission + '.')) {
            parseChild(dataPermissions, { counter, newPermission, parentIsGoingBack, index, prevValueChild, permission })
        } else {
            break
        }
    }
}

interface CounterValues {
    [key: number]: number
}

interface ExtraParamsAddOrDeletePermissionTable {
    newPermission: WhatCanYouDoInversedKeys
    index: number
}
export const addOrDeletePermissionTable = (oldTableData: PermissionTabRow[] | null, extraParams
    : ExtraParamsAddOrDeletePermissionTable) => {
    const {newPermission, index} = extraParams 
    const dataPermissions: PermissionTabRow[] = JSON.parse(JSON.stringify(oldTableData))
    let counter: CounterValues = {
        1: 0,
        2: 0,
        3: 0,
        4: 0
    }
    const prevValue = dataPermissions[index].value
    processCheckedRow(dataPermissions, { counter, index, newPermission })
    parseAllParentChildren(dataPermissions, { counter, index, newPermission, prevValue })
    updateTotalValuesPermissions(dataPermissions, counter, dataPermissions[index].sectionIndexCounter || 1)    
    
    return dataPermissions
}
interface ExtraParamsAllPermissions {
    haveAllChecked: boolean
    haveAllCheckedNext: boolean
    index: number
    newPermission: WhatCanYouDoInversedKeys
}
const checkAllPermissions = (dataPermissions: PermissionTabRow[], extraParams: ExtraParamsAllPermissions) => {
    const { haveAllChecked, haveAllCheckedNext, index, newPermission } = extraParams
    const prevValue = dataPermissions[index].value
    if (haveAllCheckedNext) {
        dataPermissions[index].value = newPermission
    } else if (!haveAllChecked && !haveAllCheckedNext) {
        if (!prevValue || prevValue < newPermission) {
            dataPermissions[index].value = newPermission
        }
    } else if (haveAllChecked || newPermission === 1) {
        dataPermissions[index].value = undefined
    }
}
interface UpdateAllTotalValuesExtraProps {
    index: number
    haveAllChecked: boolean
    haveAllCheckedNext: boolean
    newPermission: WhatCanYouDoInversedKeys
}

const updateAllTotalValues = (dataPermissions: PermissionTabRow[], extraParams: UpdateAllTotalValuesExtraProps) => {
    const { haveAllChecked, haveAllCheckedNext, newPermission, index } = extraParams
    const maxRows = dataPermissions[index].maxRows || 0
    const rowTotalValues = dataPermissions[index].totalValues
    if (rowTotalValues) {
        if (haveAllCheckedNext) {
            for (let k = newPermission + 1; k < Object.keys(rowTotalValues).length + 1; k++) {
                rowTotalValues[k] = 0
            }
        } else if(haveAllChecked) {
            for (let k = 4; k > 0; k--) {
                rowTotalValues[k] = 0
            }
        } else {
            for (let k = newPermission; k > 0; k--) {
                rowTotalValues[k] = maxRows
            }
        }
    }
}

interface ExtraParamsAddOrDeleteAllPermissionsTable {
    newPermission: WhatCanYouDoInversedKeys
    index: number
}

export const addOrDeleteAllPermissionsTable = (oldTableData: PermissionTabRow[] | null, extraParams: ExtraParamsAddOrDeleteAllPermissionsTable) => {
    const {newPermission, index} = extraParams
    const dataPermissions: PermissionTabRow[] = JSON.parse(JSON.stringify(oldTableData))
    const maxRows = dataPermissions[index].maxRows || 0
    const totalValues = dataPermissions[index].totalValues
    const haveAllChecked = totalValues && totalValues[newPermission] === maxRows ? true : false
    const haveAllCheckedNext = totalValues && totalValues[newPermission + 1] === maxRows ? true : false
    for (let i = 0; i < maxRows +1; i++) {
        const myIndex = index +i
        checkAllPermissions(dataPermissions, { haveAllChecked, haveAllCheckedNext, newPermission, index: myIndex })
    }
    updateAllTotalValues(dataPermissions, { haveAllChecked, haveAllCheckedNext, newPermission, index })
    return dataPermissions
}

export const titleRowRender = (props: TableRowProps) => {
    let className = props.className
    if (props?.rowData?.type === 'section') {
        className += ' bg-gray-F7 regular16'
    }
    return defaultRowRenderer({ ...props, className })
}