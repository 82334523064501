import { faFileDownload } from '@fortawesome/pro-light-svg-icons'
import { SueFileModelType } from '@teinor/erp/types/company/sue/sueFile'
import TwinIcon from '../../../../../../../../../baseComponents/TwinIcon'
import './sue_file.sass'

interface SueFilesProps {
    sueFiles: SueFileModelType[]
}

const SueFiles: React.FC<SueFilesProps> = ({sueFiles}) => {
    const renderThis: JSX.Element[] = []
    for (const sueFile of sueFiles) {
        renderThis.push(<SueFile sueFile={sueFile} />)
    }
    return (
        <div className='flex sue_attached_files'>
            {renderThis}
        </div>
    )
}

interface SueFileProps {
    sueFile: SueFileModelType
}

const SueFile: React.FC<SueFileProps> = ({sueFile}) => {
    return (
        <a href={sueFile.file_url} target='_blank' rel="noreferrer">
            <div className='sue_file' title={sueFile.file_name}>
                <TwinIcon className='text-green-43 h-48 mb-20' icon={faFileDownload}  />
                <span className='twin_elipsis'>{sueFile.file_name}</span>
            </div>
        </a>

    )
}


export default SueFiles