import { Currencies, Currency } from '@teinor/currencies'
import { CurrencyModelType } from '@teinor/erp/types/company/config/currency'
import { TwinParameters } from '@teinor/erp/types/company/config/parameter'
import { calculateAllPosibleConversions, preciseDecimalSum } from '@teinor/erp/prices'
import { TwinDictionary } from '../../globals/dictionary'
import { CurrencyConversionExtended } from '@teinor/erp/prices/types'
import { Langs } from '@teinor/langs'

type CurrencyExtended = Currency & CurrencyConversionExtended & {}

type CurrenciesExtended = { [isoCode: string]: CurrencyExtended }

interface ConfigLang {
    used: Langs
    all: TwinDictionary
}

interface ConfigCurrencies {
    used: CurrenciesExtended
    all: Currencies
    usedDictionary: TwinDictionary
    main: string
    conversions: {[isoCode: string]: number}
}

export interface Config {
    langs: ConfigLang
    currencies: ConfigCurrencies
    parameters: TwinParameters
}

export interface ConfigAction {
    type: 'CHANGE_CONFIG'
    payload: Config
}

export type ConfigPayload = Config | null

export const prepareCurrencies = (currenciesDb: CurrencyModelType[], allCurrencies: Currencies): ConfigCurrencies => {
    const result = calculateBaseDictionary(currenciesDb, allCurrencies)
    result.conversions = calculateAllPosibleConversions(result.used)
    return result
}

const calculateBaseDictionary = (currenciesDb: CurrencyModelType[], allCurrencies: Currencies): ConfigCurrencies => {
    const result: ConfigCurrencies = {
        used: {},
        all: allCurrencies,
        usedDictionary: {},
        main: '',
        conversions: {}
    }
    for (const currency of currenciesDb) {
        const isoCode = currency.id
        const conversion = parseFloat(String(currency.conversion || 1))
        const secure_exchange = parseFloat(String(currency.secure_exchange || 1))
        const mycurrency: CurrencyExtended = {
            ...allCurrencies[isoCode],
            conversion,
            secure_exchange,
            total_conversion: preciseDecimalSum(conversion, secure_exchange)
        }
        result.used[isoCode] = mycurrency
        result.usedDictionary[isoCode] = { name: mycurrency.name }
        if (currency.is_main) {
            result.main = isoCode
        }
    }
    return result
}

export default function config(state: ConfigPayload = null, action: ConfigAction): Config | null {
    switch (action.type) {
        case 'CHANGE_CONFIG':
            return state = action.payload
        default:
            return state
    }
}