import './whitebox.sass'
interface WhiteBoxProps {
    className?: string
    children?: React.ReactNode
    subRef?: any
    onClick?: () => void
}
export const WhiteBox: React.FC<WhiteBoxProps> = ({ className, children, subRef, onClick }) => {
    return (
        <div className={'white_box ' + (className || '')} ref={subRef} onClick={onClick}>
            {children}
        </div>
    )
}

interface SmallWhiteBoxProps extends WhiteBoxProps {}

export const SmallWhiteBox: React.FC<SmallWhiteBoxProps> = ({ className, children, ...rest}) => {
    return (
        <WhiteBox className={'absolute w-200 ' + (className || '')} {...rest}>
            {children}
        </WhiteBox>
    )
}

