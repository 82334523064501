import { useCallback, useEffect, useState } from 'react'
import { twinFetchPostJSON } from '../globals/data'
import { TwinDictionary } from '../globals/dictionary'
import { setVariablesToCurrentPage } from '../reducers/reduxDispatch'

interface UseBasicDataParams {
    params: TwinDictionary
    url: string
    variableToCurrentPage?: string
    doPetition?: boolean
}

export const useBasicData = ({ params, url, variableToCurrentPage, doPetition = true }: UseBasicDataParams) => {
    const [basicData, setBasicData] = useState<TwinDictionary | null>(null)

    const paramsString = JSON.stringify(params)
    const getData = useCallback(async () => {
        const paramsParsed = JSON.parse(paramsString)
        const myData = await twinFetchPostJSON(url, {
            ...paramsParsed
        })
        if (myData) {
            setBasicData(myData)
            if (variableToCurrentPage && myData) {
                setVariablesToCurrentPage([myData[variableToCurrentPage]])
            }
        }
    }, [paramsString, variableToCurrentPage, url])

    useEffect(() => {
        if (doPetition) {
            getData()
        }
    }, [getData, doPetition])

    return { basicData, getData }
}