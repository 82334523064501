import { useMemo } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import CustomSelect from '../../../../../forms/CustomSelect'
import { dictionaryComplexFromJsonArr } from '../../../../../utils/globals/dictionary'
import { AllReduxAppPayloads, GlobalContext } from '../../../../../utils/reducers'
import { changeCurrentWorkingCenter } from '../../../../../utils/reducers/reduxDispatch'
import './chooseworking.sass'

interface ChooseWorkingCenterProps extends ChooseWorkingCenterLogicProps {}

const ChooseWorkingCenter: React.FC<ChooseWorkingCenterProps> = ({WorkingCenterId, ...rest}) => {
    const { workingCentersMemorized } = useChooseWorkingCenterLogic({ WorkingCenterId, ...rest })
    if (!Object.keys(workingCentersMemorized).length) {
        return null
    }
    return (
        <CustomSelect items={workingCentersMemorized} label={''} value={WorkingCenterId} className={'for_header'} onChange={changeCurrentWorkingCenter}/>
    )
}

interface ChooseWorkingCenterLogicProps extends ReduxChooseWorkingCenterConnector {}

const useChooseWorkingCenterLogic = ({workingCenters}: ChooseWorkingCenterLogicProps) => {
    const workingCentersMemorized = useMemo(() => dictionaryComplexFromJsonArr(workingCenters || {}), [workingCenters])
    return {workingCentersMemorized}
}

const mapChooseWorkingCenterConnector = (state: AllReduxAppPayloads) => ({ workingCenters: state.user?.extraData.WorkingCenters, WorkingCenterId: state.user?.extraData.WorkingCenterId })
export const chooseWorkingCenterConnector = connect(mapChooseWorkingCenterConnector, null, null, { context: GlobalContext })
export type ReduxChooseWorkingCenterConnector = ConnectedProps<typeof chooseWorkingCenterConnector>


const ChooseWorkingCenterConnector = chooseWorkingCenterConnector(ChooseWorkingCenter)

export default ChooseWorkingCenterConnector