import { TwinIconProp } from '../../TwinIcon'
import { CustomRenderRoundedImage } from '../VirtualTableListing/CustomRenders'
import { Link } from 'react-router-dom'
import './twin_table_cards.sass'


interface TwinTableCardProps {
    basePath: string
    id: string
    title: string
    icon: TwinIconProp
    lastPath?: string
    img?: string
}

const TwinTableCard: React.FC<TwinTableCardProps> = ({ basePath, id, title, lastPath, ...rest}) => {
    return (
        <Link to={basePath + '/' + id + (lastPath ? '/' + lastPath : '')}>
            <div className='twin_table_card flex justify-start rounded-lg bg-white p-20 w-300 my-10 mr-15 ml-5 items-center'>
                <CustomRenderRoundedImage className='mr-15 h-50 w-50' name={title} {...rest} />
                <div className='flex flex-col items-center'>
                    <span>{title}</span>
                </div>
            </div>
        </Link>
    )
}

interface RenderTwinTableCardsProps {
    title: string
    twinTableCards: JSX.Element[]
    className?: string
}

export const RenderTwinTableCards: React.FC<RenderTwinTableCardsProps> = ({ title, className, twinTableCards}) => {
    return (
    <div className={className}>
        <div className='regular20'>{title}</div>
        <div className='overflow-y-auto flex flex-wrap'>
            {twinTableCards}
        </div>
    </div>)
}

export default TwinTableCard