import { useCallback } from 'react'
import { useNavigate } from 'react-router'
import { ButtonPrimary, ButtonSecondary } from '../../../../../../../../../baseComponents/Button'
import LoadingSpinner from '../../../../../../../../../baseComponents/LoaderDecider/LoadingSpinner'
import { ModalSmallCentered } from '../../../../../../../../../baseComponents/Modal'
import TwinTrans from '../../../../../../../../../baseComponents/TwinTrans'
import { twinFetchPostJSON } from '../../../../../../../../../utils/globals/data'
import { SetEditDeleteModalTy, UseEditDeleteModalProps } from '../../../../../../../../../utils/hooks/useEditDeleteModal'
import useIsLoading from '../../../../../../../../../utils/hooks/useIsLoading'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import { changeSuccessMessage, clearAntiUnsaveElement } from '../../../../../../../../../utils/reducers/reduxDispatch'


interface ModalCreateNewVersionOrUpdateProps extends ModalCreateNewVersionOrUpdateLogicProps {
}

const ModalCreateNewVersionOrUpdate: React.FC<ModalCreateNewVersionOrUpdateProps> = ({ openModal, setOpenModal, getData, urlTabString, onSubmit}) => {
    const { onSubmitUpdate, onSubmitCreateVersion, loading } = useModalCreateNewVersionOrUpdateLogic({ openModal, setOpenModal, getData, urlTabString, onSubmit })
    return (
        <ModalSmallCentered opened={true} setOpened={() => setOpenModal(null)} onClickOut={false} className='small_padding'>
            <h2><TwinTrans transKey='newVersionOrUpdate'>Nueva versión o actualizar</TwinTrans></h2>
            <div className='mb-10'>
                <TwinTrans transKey='versionWCalExplanation'>
                    Actualizar el calendario es útil para cuando ha habido algún error, o no se ha trabajado todavía sobre él, ya que modificará también el pasado. Si el cambio es a partir de ahora, crea una nueva versión para que se conserve también el horario del pasado.
                </TwinTrans>
            </div>
            {loading? 
                <LoadingSpinner className='small_spinner' />
                :
                <div className='flex items-center justify-center'>
                    <ButtonSecondary className='mr-30' onClick={() => onSubmitUpdate()}>
                        <TwinTrans transKey='replace'>Sustituir</TwinTrans>
                    </ButtonSecondary>
                    <ButtonPrimary onClick={() => onSubmitCreateVersion()}>
                        <TwinTrans transKey='createNewVersion'>Crear nueva versión</TwinTrans>
                    </ButtonPrimary>
                </div>
            }
            
        </ModalSmallCentered>
    )
}

interface ModalCreateNewVersionOrUpdateLogicProps {
    setOpenModal: SetEditDeleteModalTy
    openModal: UseEditDeleteModalProps
    urlTabString:'main'| 'schedule'
    getData: ()=> void
    onSubmit?: ()=> void
}

const useModalCreateNewVersionOrUpdateLogic = ({ setOpenModal, getData, openModal, onSubmit, urlTabString }: ModalCreateNewVersionOrUpdateLogicProps) => {
    const navigate = useNavigate()
    const { startLoading, endLoading, loading } = useIsLoading()
    const {t} = useTwinTranslation()
    const successMessageUpdate = t('successfullyUpdated', 'Actualizado correctamente')
    const successMessageNewVersion = t('newVersionCreatedSuccessfully', 'Nueva versión creada correctamente')
    const postSubmit = useCallback(() => {
        setOpenModal(null)
        endLoading()
        onSubmit?.()
    }, [setOpenModal, endLoading, onSubmit])

    const onSubmitCreateVersion = useCallback(async () => {
        startLoading()
        await clearAntiUnsaveElement()
        const result = await twinFetchPostJSON('/api/app/workingCalendar/createNewVersionWorkingCalendar', openModal?.allRowData)
        if (result) {
            navigate(`/staff/timeControl/workingCalendars/${result.id}/${urlTabString}`)
        }
        changeSuccessMessage(successMessageNewVersion)
        postSubmit()
    }, [openModal?.allRowData, navigate, postSubmit, urlTabString, startLoading, successMessageNewVersion])
    const onSubmitUpdate = useCallback(async () => {
        startLoading()
        await clearAntiUnsaveElement()
        await twinFetchPostJSON('/api/app/workingCalendar/replaceWorkingCalendarVersion', openModal?.allRowData)
        changeSuccessMessage(successMessageUpdate)
        postSubmit()
        getData()
    }, [openModal?.allRowData, postSubmit, getData, startLoading, successMessageUpdate])



    return { onSubmitCreateVersion, onSubmitUpdate, loading }
}


export default ModalCreateNewVersionOrUpdate