import { CustomerModelType } from '@teinor/erp/types/company/customer'
import { useCallback, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import { ComponentWithPermissions } from '../../../../../../../baseComponents/CreateRoutering/types'
import EditCardTabs from '../../../../../../../baseComponents/EditCardTabs'
import { twinFetchPostJSON } from '../../../../../../../utils/globals/data'
import { getBasePath } from '../../../../../../../utils/globals/link'
import { ComponentTabObject } from '../../../../../../../utils/hooks/useTabLogic'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import { setVariablesToCurrentPage } from '../../../../../../../utils/reducers/reduxDispatch'
import CustomerB2BAddresses from './Tabs/CustomerB2BAddresses'
import CustomerB2BBanks from './Tabs/CustomerB2BBanks'
import CustomerB2BContactPersons from './Tabs/CustomerB2BContactsPersons'
import CustomerB2BFiles from './Tabs/CustomerB2BFiles'
import CustomerB2BNotes from './Tabs/CustomerB2BNotes'
import CustomerB2BOtherInformation from './Tabs/CustomerB2BOtherInformation'
import CustomerB2BProjects from './Tabs/CustomerB2BProjects'
import CustomerB2BShops from './Tabs/CustomerB2BShops'
import MainCustomerB2BProfile from './Tabs/MainCustomerB2BProfile'
import CustomerB2BBagOfHours from './Tabs/CustomerB2BBagOfHours'
import CustomerB2BGdpr from './Tabs/CustomerB2BGdpr'
import CustomerB2BTasks from './Tabs/CustomerB2BTasks'



type SingleCustomerB2BProps = SingleCustomerB2BLogicProps & ComponentWithPermissions & {

}

const SingleCustomerB2B: React.FC<SingleCustomerB2BProps> = ({ userPermissions }) => {
    const location = useLocation()
    const params = useParams<{ id: string, tab?: string }>()
    const { t } = useTwinTranslation()
    const { basicData, getData } = useSingleCustomerB2BLogic({ params })
    if (!params.id) {
        return null
    }
    const tabs: ComponentTabObject = {
        'main': { component: MainCustomerB2BProfile, text: t('general', 'General'), permission: 'customer.profile.main' },
        'other-information': { component: CustomerB2BOtherInformation, text: t('otherInformation', 'Otra información'), permission: 'customer.profile.main' },
        'address': { component: CustomerB2BAddresses, text: t('addresses', 'Direcciones'), permission: 'customer.profile.address' },
        'bank': { component: CustomerB2BBanks, text: t('banks', 'Bancos'), permission: 'customer.profile.bank' },
        'contacts': { component: CustomerB2BContactPersons, text: t('contacts', 'Contactos'), permission: 'customer.profile.contactPerson' },
        'workingCenters': { component: CustomerB2BShops, text: t('workingCenters', 'Centros de trabajo'), permission: 'customer.profile.shops' },
        'projects': { component: CustomerB2BProjects, text: t('projects', 'Proyectos'), permission: 'customer.profile.projects' },
        'bagsOfHours': { component: CustomerB2BBagOfHours, text: t('bagsOfHours', 'Bolsas de horas'), permission: 'customer.profile.bagOfHours', modules: ['tasks'] },
        'tasks': { component: CustomerB2BTasks, text: t('tasks', 'Tareas'), permission: 'customer.profile.tasks', modules: ['tasks'] },
        'notes': { component: CustomerB2BNotes, text: t('notes', 'Notas'), permission: 'customer.profile.main' },
        'files': { component: CustomerB2BFiles, text: t('files', 'Ficheros'), permission: 'customer.profile.files' },
        'gdpr': { component: CustomerB2BGdpr, text: t('gdpr', 'RGPD'), permission: 'customer.profile.gdpr' },
    }

    return (<EditCardTabs basePath={getBasePath(location.pathname, params.id)} basicData={basicData} tabs={tabs} tab={params.tab} userPermissions={userPermissions} getData={getData} />)
}

interface SingleCustomerB2BLogicProps {
    params: { id?: string, tab?: string }
}

const useSingleCustomerB2BLogic = ({ params }: SingleCustomerB2BLogicProps) => {
    const [basicData, setBasicData] = useState<CustomerModelType | null>(null)

    const id = params.id

    const getData = useCallback(async () => {
        const myData: CustomerModelType = await twinFetchPostJSON('/api/app/customer/getCustomerInstance', {
            id
        })
        if (myData) {
            setBasicData(myData)
            setVariablesToCurrentPage([myData.name])
        }
    }, [setBasicData, id])

    useEffect(() => {
        getData()
    }, [id, getData])

    return { basicData, getData }
}

export default SingleCustomerB2B