import { useCallback } from 'react'
import { InputNumberWithLabelMargin } from '..'
import { checkFloatValid } from '../../../utils/globals/numbers'
import useTwinTranslation from '../../../utils/hooks/useTwinTranslation'
import { getActiveCurrenciesDictionary } from '../../../utils/reducers/getters'
import CustomSelect from '../../CustomSelect'
import { InputCurrencyValue } from './types'

export interface InputCurrencyProps extends InputCurrencyLogicProps {
    label: string
    className?: string
    nameExtended?: InputCurrencyValue
    required?: boolean
}

const InputCurrency: React.FC<InputCurrencyProps> = ({ nameExtended, label, value, onChange, className, required }) => {
    const {t} = useTwinTranslation()
    const { changeValue, changeCurrency } = useInputCurrencyLogic({value, onChange})
    const activeCurrencies = getActiveCurrenciesDictionary()
    return (
        <div className={'input_currency flex items-center ' +  (className || '')}>
            <InputNumberWithLabelMargin label={label} onChange={changeValue} value={value?.price || ''} className='mr-8 flex-auto w-1' name={nameExtended?.price} required={required}/>
            <CustomSelect items={activeCurrencies} label={t('currency', 'Moneda')} value={value?.CurrencyId} onChange={changeCurrency} required={required} className='w-90' name={nameExtended?.CurrencyId} />
        </div>
    )
}

interface InputCurrencyLogicProps {
    onChange?: (value: InputCurrencyValue) => void
    value?: InputCurrencyValue
}


const useInputCurrencyLogic = ({ value, onChange}: InputCurrencyLogicProps) => {
    const changeValue = useCallback((myValue: string) => {
        const validPrice = checkFloatValid(myValue)
        if (validPrice || validPrice === '') {
            onChange?.({ price: validPrice, CurrencyId: value?.CurrencyId })
        }
    }, [onChange, value])

    const changeCurrency = useCallback((CurrencyId: string) => {
        onChange?.({ price: value?.price, CurrencyId})
    }, [onChange, value])

    return { changeValue, changeCurrency }
}

export default InputCurrency