import { TwinDictionary } from '../../../utils/globals/dictionary';
import { BasicCalculateColumnsParams } from './types';

export const calculateColumnWidth = (width: number, columns: BasicCalculateColumnsParams[], defaultMinWidth: number = 100) => {
    let calculatedWidth = 15
    let noWidthColumns = 0
    let minColumns: { width: number, items: number[]} = { width: 0, items: [] }
    for (const column of columns) {
        if (column.haveModule !== false && column.havePermission !== false && !column.justForFilter) {
            if (column.width) {
               calculatedWidth += column.width
            } else if (column.userWidth && column.userWidth > (column.minWidth || defaultMinWidth)) {
                calculatedWidth += column.userWidth
            } else if (column.minWidth) {
                const minW = column.minWidth
                calculatedWidth += minW
                minColumns = {
                    width: minColumns.width + minW ,
                    items: [...minColumns.items, minW]
                }
            } else if (column.width === undefined && column.minWidth === undefined) {
                calculatedWidth += defaultMinWidth
                noWidthColumns++
           }
        }
    }
    let finalCalculatedWidth = (width - calculatedWidth) / noWidthColumns 
    const haveBlankSpace = calculatedWidth < width
    if (haveBlankSpace) {
        let itemsLength = minColumns.items.length
        let leftSpaceWithoutCalculatedW = calculatedWidth - (defaultMinWidth * noWidthColumns) - minColumns.width
        finalCalculatedWidth = (width - leftSpaceWithoutCalculatedW) / (noWidthColumns + itemsLength)
        for (const key in minColumns.items) {
            const element = minColumns.items[key]
            if (element > finalCalculatedWidth) {
                itemsLength--
                minColumns.width = minColumns.width - element
                leftSpaceWithoutCalculatedW = calculatedWidth - (defaultMinWidth * noWidthColumns) - minColumns.width
                finalCalculatedWidth = (width - leftSpaceWithoutCalculatedW) / (noWidthColumns + itemsLength)
            }
        }
    }
    const mustScroll = width < calculatedWidth
    return { columnWidth: finalCalculatedWidth > 0 ? finalCalculatedWidth : defaultMinWidth, newWidth: mustScroll ? calculatedWidth : width }
}

export const cleanVTColumnsForMemo = (object: TwinDictionary) => {
    return {props: object.props}
}

export const getVirtualizedListingColumnWidth = (params: BasicCalculateColumnsParams, columnWidth: number, scrollable: boolean, defaultMinWidth: number) => {
    const myMinWidth = params.minWidth || defaultMinWidth
    if (params.userWidth && params.userWidth < myMinWidth) {
        return myMinWidth
    }
    return params.width ?? params.userWidth ?? (!scrollable && myMinWidth && myMinWidth < columnWidth ? columnWidth : myMinWidth) ?? columnWidth 
}