import TwinIcon, { TwinIconProp } from '../../TwinIcon'

interface DisplayStatusProps {
    icon: TwinIconProp
    text: string
    colorText: string
}

export const DisplayStatus: React.FC<DisplayStatusProps> = ({ icon, text, colorText }) => {
    return (
        <div className={'flex items-center justify-center border rounded-5 px-10 py-4 text-center ' + colorText}>
            <TwinIcon className='mr-5' icon={icon} size='lg' />
            {text}
        </div>
    )
}

interface DisplayStatusWithoutIconProps {
    text: string
    colorText: string
}

export const DisplayStatusWithoutIcon: React.FC<DisplayStatusWithoutIconProps> = ({ text, colorText }) => {
    return (
        <div className={'flex items-center justify-center border rounded-5 px-10 py-4 text-center ' + colorText}>
            {text}
        </div>
    )
}
