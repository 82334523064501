import { CustomSelectSectionItems, ItemCustomSelectDropDownRowSection } from '../../../forms/CustomSelect/CustomSelectSections/types';
import { TwinDictionary } from '../../../utils/globals/dictionary';
import { CustomerPayload, ReduxCustomerTypes } from '../../../utils/reducers/customers/customer';

export const parseSearchCustomerProjectToSections = (customers: CustomerPayload, typeCustomers: Record<ReduxCustomerTypes, string>) => {
    const sections: CustomSelectSectionItems = []
    const customersSectionItems: ItemCustomSelectDropDownRowSection[] = []
    const indexCustomers: TwinDictionary = {}
    if(customers){
        for (const type in typeCustomers) {
            const parsedType = type as ReduxCustomerTypes
            const nameSection = typeCustomers[parsedType]
            if (Object.keys(customers[parsedType]).length){
                const myChilds = customers[parsedType]
                customersSectionItems.push({ value: '', text: nameSection, isSection: true })
                for (const value in myChilds) {
                    const customer = myChilds[value]
                    const text = (customer.code ? customer.code + ' -' : '') + ' ' + customer.name + ' ' + (customer.surname || '')
                    customersSectionItems.push({ ...customer, value, text  })
                    indexCustomers[value] = {...customer, value, text}
                }
                sections.push({ name: nameSection, childs: myChilds })
            }
        }
    }
    return { customersSectionItems, indexCustomers}
}