import { useCallback, useState } from 'react'
import TwinCalendar from '..'
import './twincalendarstartend.sass'
import { flushSync } from 'react-dom'
import { StartEndDateTy } from '../../../utils/globals/types'
import TwinTrans from '../../TwinTrans'

interface TwinCalendarStartEndProps extends TwinCalendarStartEndLogicProps{
    minDate?: Date
    maxDate?: Date
    defaultActiveStartDate?: Date
}

const TwinCalendarStartEnd: React.FC<TwinCalendarStartEndProps> = ({ maxDate, minDate, defaultActiveStartDate, ...rest }) => {
    const { handleOnChange, activeStartDate, setActiveStartDate } = useTwinCalendarStartEndLogic({ ...rest })
    return (
        <div className='flex gap-33 date_range_selector_start_end'>
            <div>
                <span className='regular14'><TwinTrans transKey='dateStart'>Fecha inicio</TwinTrans></span>
                <TwinCalendar value={rest.start} minDate={minDate} maxDate={maxDate} onChange={(val) => handleOnChange(val, 'start')} />
            </div>
            <div>
                <span className='regular14'><TwinTrans transKey='dateEnd'>Fecha fin</TwinTrans></span>
                <TwinCalendar value={rest.end} minDate={rest.start} maxDate={maxDate} onChange={(val) => handleOnChange(val, 'end')} activeStartDate={activeStartDate} onActiveStartDateChange={(val) => setActiveStartDate(val.activeStartDate) } />
            </div>
        </div>
    )
}

interface TwinCalendarStartEndLogicProps extends StartEndDateTy {
    onChange: (value: Date, type: 'start' | 'end') => void
}

const useTwinCalendarStartEndLogic = ({ start, end, onChange }: TwinCalendarStartEndLogicProps) => {
    const [activeStartDate, setActiveStartDate] = useState(start)

    const handleOnChange = useCallback((value: Date, type: 'start' | 'end') => {
        flushSync(async () => {
            if (type === 'start') {
                setActiveStartDate(value)
                await onChange(value, 'end')
            }
            await onChange(value, type)
        })
    }, [onChange])

    return { handleOnChange, activeStartDate, setActiveStartDate }
}


export default TwinCalendarStartEnd