import MainEmployee from './Tabs/MainEmployee'
import EmployeeFiles from './Tabs/EmployeeFiles'
import EmployeeHolidays from './Tabs/EmployeeHolidays'
import EmployeeAbsences from './Tabs/EmployeeAbsences'
import EmployeePayslips from './Tabs/EmployeePayslips'
import { useLocation, useParams } from 'react-router'
import { ComponentWithPermissions } from '../../../../../../../baseComponents/CreateRoutering/types'
import EditCardTabs from '../../../../../../../baseComponents/EditCardTabs'
import { getBasePath } from '../../../../../../../utils/globals/link'
import { useBasicData } from '../../../../../../../utils/hooks/useBasicData'
import { ComponentTabObject } from '../../../../../../../utils/hooks/useTabLogic'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import EmployeeExpenses from './Tabs/EmployeeExpenses'

type SingleEmployeeProps =ComponentWithPermissions & {

}

const SingleEmployee: React.FC<SingleEmployeeProps> = ({ userPermissions }) => {
    const location = useLocation()
    const params = useParams<{ id: string, tab?: string }>()
    const {t} = useTwinTranslation()
    const { basicData, getData } = useBasicData({ params: { id: params.id, includeRelations: true }, url: '/api/app/employee/getEmployeeInstance', variableToCurrentPage: 'name' })
    if (!params.id) {
        return null
    }
    const tabs: ComponentTabObject = {
        'main': { component: MainEmployee, text: t('general', 'General'), permission: 'employee.main'},
        'holidays': { component: EmployeeHolidays, text: t('holidaysBags', 'Bolsas de vacaciones'), permission: 'employee.holidays', modules: ['working_calendar_medium', 'working_calendar_full']},
        'absences': { component: EmployeeAbsences, text: t('absences', 'Ausencias'), permission: 'employee.absences', modules: ['working_calendar_full']},
        'payslips': { component: EmployeePayslips, text: t('payslips', 'Nóminas'), permission: 'employee.payslip', modules: ['working_calendar_full'] },
        'expenses': { component: EmployeeExpenses, text: t('expenses', 'Gastos'), permission: 'employee.expenses', modules: ['employee_expenses'] },
        'files': { component: EmployeeFiles, text: t('files', 'Ficheros'), permission: 'employee.files' },
    }

    return (<EditCardTabs basePath={getBasePath(location.pathname, params.id)} basicData={basicData} tabs={tabs} tab={params.tab} userPermissions={userPermissions} getData={getData} />)
}

export default SingleEmployee