import { dictionaryComplexFromJsonArr, dictionaryFromJsonArrCodeName } from '../../globals/dictionary'
import { BillingGroupModelType } from '@teinor/erp/types/company/config/billingGroup'
import { BillingTypeBuyModelType } from '@teinor/erp/types/company/config/billingGroup/billingTypeBuy'
import { BillingTypeBuyOptionModelType } from '@teinor/erp/types/company/config/billingGroup/billingTypeBuy/billingTypeBuyOption'
import { BillingTypeSellModelType } from '@teinor/erp/types/company/config/billingGroup/billingTypeSell'
import { BillingTypeSellOptionModelType } from '@teinor/erp/types/company/config/billingGroup/billingTypeSell/billingTypeSellOption'

export interface BillingGroupAction {
    type: 'CHANGE_BILLINGGROUP'
    payload: BillingGroupModelType[]
}

interface BillingTypeBuyExt extends BillingTypeBuyModelType{
    BillingTypeBuyOptionsDict: {[id: number]: BillingTypeBuyOptionModelType}
    BillingTypeBuyOptionsParsed: {[id: number]: string}
}
interface BillingTypeSellExt extends BillingTypeSellModelType{
    BillingTypeSellOptionsDict: {[id: number]: BillingTypeSellOptionModelType}
    BillingTypeSellOptionsParsed: {[id: number]: string}
}
interface BillingGroupModelTypeExt extends BillingGroupModelType{
    BillingTypesBuyDict: { [id: number]: BillingTypeBuyExt }
    BillingTypesSellDict: { [id: number]: BillingTypeSellExt }
}
export type DictionaryBillingGroup = { [id: number]: BillingGroupModelTypeExt }
export type BillingGroupPayload = null | DictionaryBillingGroup

export default function billingGroups(state: BillingGroupPayload = null, action: BillingGroupAction): DictionaryBillingGroup | null {
    switch (action.type) {
        case 'CHANGE_BILLINGGROUP':
            const dictionaryBillingGroup = dictionaryComplexFromJsonArr(action.payload || [])
            for (const key in dictionaryBillingGroup) {
                const billingGroup = dictionaryBillingGroup[key]
                if(billingGroup.billingTypesBuy && billingGroup.billingTypesBuy.length){
                    billingGroup.BillingTypesBuyDict = dictionaryComplexFromJsonArr(billingGroup.billingTypesBuy)
                    for (const idBillingType in billingGroup.BillingTypesBuyDict) {
                        const billingType = billingGroup.BillingTypesBuyDict[idBillingType]
                        if(billingType.BillingTypeBuyOptions){
                            billingType.BillingTypeBuyOptionsDict = dictionaryComplexFromJsonArr(billingType.BillingTypeBuyOptions)
                            billingType.BillingTypeBuyOptionsParsed = dictionaryFromJsonArrCodeName(billingType.BillingTypeBuyOptions)
                        }
                    }
                }
                if(billingGroup.billingTypesSell && billingGroup.billingTypesSell.length){
                    billingGroup.BillingTypesSellDict = dictionaryComplexFromJsonArr(billingGroup.billingTypesSell)
                    for (const idBillingType in billingGroup.BillingTypesSellDict) {
                        const billingType = billingGroup.BillingTypesSellDict[idBillingType]
                        if(billingType.BillingTypeSellOptions){
                            billingType.BillingTypeSellOptionsDict = dictionaryComplexFromJsonArr(billingType.BillingTypeSellOptions)
                            billingType.BillingTypeSellOptionsParsed = dictionaryFromJsonArrCodeName(billingType.BillingTypeSellOptions)
                        }
                    }
                }
            }
            return state = dictionaryBillingGroup
        default:
            return state
    }
}