import { useParams } from 'react-router-dom'
import { TaskCFieldValueModelType } from '@teinor/erp/types/company/task/taskCFieldValue'
import { useCallback, useEffect, useState } from 'react'
import { ModalEditComponentProps } from '../../../../../../../../baseComponents/ModalsLayouts/types'
import { permissionCheck } from '../../../../../../../../baseComponents/PermissionChecker/function'
import { RowData } from '../../../../../../../../baseComponents/TwinTable/types'
import { FormRender } from '../../../../../../../../forms/FormRenderer/types'
import { twinFetchPostJSON } from '../../../../../../../../utils/globals/data'
import { valueOrDefaultValue } from '../../../../../../../../utils/globals/valueOrDefaultValue'
import useTwinTranslation from '../../../../../../../../utils/hooks/useTwinTranslation'
import { ModalCreateEditLittleLoading, LittleModalCreateEdit } from '../../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { customerProjectTypeColors } from '../../../../../../../../utils/globals/colors'

interface ModalCETaskCustomFieldValueProps extends ModalEditComponentProps { }

const ModalCETaskCustomFieldValue: React.FC<ModalCETaskCustomFieldValueProps> = ({ userPermissions, allRowData, ...rest }) => {
    const { t } = useTwinTranslation()
    const params = useParams<{ TaskCFieldId?: string }>()
    const { colorRandom, colors} = useModalCETaskCustomFieldValueLogic({ allRowData})

    const fields: FormRender<TaskCFieldValueModelType> = [
        {
            cols: 1,
            elements: [
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true,
                },
                {
                    name: 'color',
                    label: t('color', 'Color'),
                    component: 'InputColor',
                    colors: colors,
                   defaultValue: allRowData?.color || colorRandom,
                },
                {
                    name: 'id',
                    component: 'InputHidden',
                    value: allRowData?.id
                },
                {
                    name: 'TaskCFieldId',
                    component: 'InputHidden',
                    value: params?.TaskCFieldId
                },
            ]
        }
    ]
    if (allRowData && userPermissions) {
        const parsedFields = valueOrDefaultValue(fields, userPermissions, allRowData)
        return (
            <ModalCreateEditLittleLoading loading={false} fields={parsedFields} url={'/api/app/task/customField/value/updateTaskCFieldValue'} translations={{ title: t('editCustomFieldValue', 'Editar valor de campo personalizado'), button: t('save', 'Guardar') }} haveButtonPermissions={permissionCheck(userPermissions, 'update')} {...rest} />
        )
    }
    return (
        <LittleModalCreateEdit fields={colorRandom? fields: []} url={'/api/app/task/customField/value/createTaskCFieldValue'} translations={{
            title: t('createCustomFieldValue', 'Crear valor de campo personalizado'),
            button: t('create', 'Crear')
        }} haveButtonPermissions={true} {...rest} />
    )
}
interface ModalCETaskCustomFieldValueLogicProps {
    allRowData?: RowData
}
const useModalCETaskCustomFieldValueLogic = ({ allRowData }: ModalCETaskCustomFieldValueLogicProps) => {
    const [colorRandom, setColorRandom] = useState<null | string>(null)
    const params = useParams<{ TaskCFieldId?: string }>()
    const colors = customerProjectTypeColors

    const getRandomColor = useCallback(async() => {
        let copyColors = JSON.parse(JSON.stringify(colors))
        const result = await twinFetchPostJSON('/api/app/task/customField/value/getAllTaskCFieldValues', { TaskCFieldId: params.TaskCFieldId })
        if(result?.length){
            for (const element of result) {
                const parsedColor = element.color.toUpperCase()
                const alreadySelectedColor = copyColors.indexOf(parsedColor)
                if (alreadySelectedColor !==  -1){
                    copyColors.splice(alreadySelectedColor, 1)
                }
            }
        }
        if(!copyColors.length){
            copyColors = colors
        }
        const index = Math.floor(Math.random() * copyColors.length)
        setColorRandom(copyColors[index])
    }, [params.TaskCFieldId, colors])

    useEffect(() => {
        if (!allRowData && !colorRandom){
            getRandomColor()
        }
    }, [allRowData, colorRandom, getRandomColor])

    return { colorRandom, colors }
}

export default ModalCETaskCustomFieldValue