import { WCalParentModelType } from '@teinor/erp/types/company/WCalParent'
import { WCalModelType } from '@teinor/erp/types/company/WCalParent/WCal'
import { LittleModalCreateEdit } from '../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { FormRender } from '../../../../../../../forms/FormRenderer/types'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import { ModalEditComponentProps } from '../../../../../../../baseComponents/ModalsLayouts/types'
import { changeErrorMessage } from '../../../../../../../utils/reducers/reduxDispatch'

interface ModalCreateWorkingCalendarProps extends ModalEditComponentProps {}

const ModalCreateWorkingCalendar: React.FC<ModalCreateWorkingCalendarProps> = ({ userPermissions, allRowData, ...rest }) => {
    const { t } = useTwinTranslation()
    const fields: FormRender<WCalModelType & WCalParentModelType> = [
        {
            cols: 1,
            elements: [
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'dayMins',
                    label: t('averageHoursDay', 'Media de horas al día'),
                    component: 'InputHour',
                },
                {
                    name: 'marginStartMin',
                    label: t('marginStartMin', 'Margen mínimo para empezar'),
                    component: 'InputHour',
                },
                {
                    name: 'marginEndMin',
                    label: t('marginEndMin', 'Margen mínimo para acabar'),
                    component: 'InputHour',
                },
                {
                    name: 'id',
                    component: 'InputHidden'
                }
            ]
        },
    ]
    return (<LittleModalCreateEdit fields={fields} url={'/api/app/workingCalendar/createWorkingCalendarParent'} onChangeError={async (e) => {
        const res = await e.json()
        if (res.errors?.[0]?.msg === 'Must upgrade module') {
            changeErrorMessage(t('withYourModuleBuyedOnlyOneAllowedCalendar', 'Con el módulo contratado solo puedes tener un calendario'))
        }
    }} translations={{
        title: t('createWorkingCalendar', 'Crear Calendario laboral'),
        button: t('create', 'Crear')
    }} haveButtonPermissions={true} {...rest} />)
}


export default ModalCreateWorkingCalendar;