import { Fragment, useCallback, useEffect, useState } from 'react';
import { ImageInputFile } from '../ImageInput/types';
import { useDropzone } from 'react-dropzone';
import { nanoid } from 'nanoid';
import TwinIcon, { TwinIconProp } from '../../baseComponents/TwinIcon';
import { faCloudDownload, faCloudUpload, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import useTwinTranslation from '../../utils/hooks/useTwinTranslation';
import TwinTrans from '../../baseComponents/TwinTrans';
import { twinFetchPostJSON, uploadFileToBack } from '../../utils/globals/data';
import { TwinDictionary } from '../../utils/globals/dictionary';
import useIsLoading from '../../utils/hooks/useIsLoading';
import LoadingSpinner from '../../baseComponents/LoaderDecider/LoadingSpinner';

export interface FilesInputProps extends FilesInputLogicProps {
    className?: string
    label?: string
    readOnly?: boolean
}

const FilesInput: React.FC<FilesInputProps> = ({defaultValue, onChange, name, className, label, readOnly}) => {
    const {t} = useTwinTranslation()
    const { files, getRootProps, getInputProps, id, deleteFiles } = useFilesInputLogic({ defaultValue, onChange })
    if(readOnly){
        return(
            <div>
                {label ? <span className='light12 text-gray-51 mb-4 twin_elipsis'>{label}</span>: null}
                {
                    defaultValue
                    ? 
                        <a href={defaultValue} className=' block text-green-21 mt-10 cursor-pointer' target='_blank' rel="noreferrer">
                            <TwinTrans transKey='downloadFile'>Descargar fichero</TwinTrans>
                            <TwinIcon className='ml-10 ' icon={faCloudDownload} />
                        </a>
                    :
                        <div>
                            <TwinTrans transKey='noFile'>No hay fichero</TwinTrans>
                        </div>
                }
            </div>
        )
    }
    const parsedLabel = label || t('uploadFile', 'Subir archivo')
    return (
        <div className={(className || '')  + ' file_input'}>
            {files.length ? 
                <div> 
                    <span className='twin_elipsis max-w-full inline-block'>{files?.[0].name}</span>
                    <TwinIcon className='ml-5 cursor-pointer' icon={faTrashCan} onClick={deleteFiles} />
                </div>
                : 
                <div {...getRootProps({ className: 'dropzone' })} className='flex items-center cursor-pointer ' >
                    <TwinIcon className='iconsMini' icon={faCloudUpload} />
                    <span className='text-sm ml-3 underline'>
                        {parsedLabel}
                    </span>
                </div>
            }
            <input id={id} name={name} title='img' {...getInputProps()}  />
        </div>
    )
}

interface FilesInputLogicProps {
    defaultValue?: string
    name?: string
    onChange?: (file: ImageInputFile[]) => void
    emptyAfterChange?: boolean
}

const useFilesInputLogic = ({ defaultValue, onChange, emptyAfterChange = false}: FilesInputLogicProps) => {
    const [files, setFiles] = useState<ImageInputFile[]>(defaultValue ? [{ name: defaultValue, preview: defaultValue }] : []);
    const [id] = useState(nanoid())
    const { getRootProps, getInputProps, inputRef } = useDropzone({
        useFsAccessApi: false,
        accept: {
            'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
            'application/zip': ['.zip'],
            'application/pdf': ['.pdf']
        },
        maxSize: 2000000,
        onDrop: acceptedFiles => {
            const myAcceptedFiles = acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }))
            setFiles([...myAcceptedFiles]);
            if (onChange) {
                onChange([...myAcceptedFiles])
                if(emptyAfterChange){
                    setFiles([])
                }
            }
        }
    })
    const deleteFiles = useCallback(() => {
        if (onChange) {
            onChange([])
        }
        setFiles([])
        const myInputRef = inputRef.current
        if (myInputRef) {
            myInputRef.value = ''
        }
    }, [setFiles, onChange, inputRef])
    
    useEffect(() => () => {
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    return { files, id, getRootProps, getInputProps, deleteFiles }
}

export interface FilesInputSendOnUploadProps extends FilesInputSendOnUploadLogicProps {
    icon: TwinIconProp
    helperText?: string
}

export const FilesInputSendOnUpload: React.FC<FilesInputSendOnUploadProps> = ({ icon, helperText, ...logic }) => {
    const { getRootProps, getInputProps, id, loading } = useFilesInputSendOnUploadLogic({ ...logic })
    return (
        <div className={'file_input_icon'} title={helperText}>
            {loading ?
                <LoadingSpinner className='h-20'/>
            : 
            <Fragment>
                <div {...getRootProps({ className: 'dropzone' })} className='flex items-center cursor-pointer ' >
                    <TwinIcon className='iconsMini' icon={icon} />
                </div>
                <input id={id} name={logic.name} title='img' {...getInputProps()} />
            </Fragment>
            }
        </div>
    )
}

interface FilesInputSendOnUploadLogicProps {
    name: string
    onChange?: (file: ImageInputFile[]) => void
    url: string
    path: string[]
    extraUpdateFields: TwinDictionary
}

const useFilesInputSendOnUploadLogic = ({ onChange, name, url, path, extraUpdateFields }: FilesInputSendOnUploadLogicProps) => {
    const {endLoading, loading, startLoading} = useIsLoading()
    const [id] = useState(nanoid())
    const { getRootProps, getInputProps } = useDropzone({
        useFsAccessApi: false,
        accept: {
            'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
            'application/zip': ['.zip'],
            'application/pdf': ['.pdf']
        },
        maxSize: 2000000,
        onDrop: acceptedFiles => {
            const myAcceptedFiles = acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }))
            uploadFileOnDrop([...myAcceptedFiles]);
        }
    })

    const uploadFileOnDrop = useCallback(async (myAcceptedFiles: any[]) => {
        startLoading()
        const pushedFiles: any[] = []
        for (const file of myAcceptedFiles) {
            const fileName = file.name
            const file_url = await uploadFileToBack(file, fileName, path)
            const result = await twinFetchPostJSON(url, { ...extraUpdateFields, [name]: file_url })
            if (result) {
                pushedFiles.push(result)
            }
        }
        await onChange?.(myAcceptedFiles)
        endLoading()
    }, [extraUpdateFields, url, name, onChange, path, endLoading, startLoading])


    return { getRootProps, getInputProps, id, loading }
}

export default FilesInput