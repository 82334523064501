import { ConfigChargingMethodModelType } from '@teinor/erp/types/company/accounting/config/configChargingMethod'
import { dictionaryComplexFromJsonArr } from '../../globals/dictionary'

export interface ConfigChargingMethodsAction {
    type: 'CHANGE_CONFIGCHARGINGMETHOD'
    payload: ConfigChargingMethodModelType[]
}
export type ConfigChargingMethodPayload = { [id: number]: ConfigChargingMethodModelType } | null

export default function configChargingMethods(state: ConfigChargingMethodPayload = null, action: ConfigChargingMethodsAction): ConfigChargingMethodPayload {
    switch (action.type) {
        case 'CHANGE_CONFIGCHARGINGMETHOD':
            const configchargingmethods = dictionaryComplexFromJsonArr(action.payload || [])
            return state = { ...configchargingmethods }
        default:
            return state
    }
}