import { ConfigPaymentMethodModelType } from '@teinor/erp/types/company/accounting/config/configPaymentMethod'
import { dictionaryComplexFromJsonArr } from '../../globals/dictionary'

export interface ConfigPaymentMethodsAction {
    type: 'CHANGE_CONFIGPAYMENTMETHOD'
    payload: ConfigPaymentMethodModelType[]
}
export type ConfigPaymentMethodPayload = { [id: number]: ConfigPaymentMethodModelType } | null

export default function configPaymentMethods(state: ConfigPaymentMethodPayload = null, action: ConfigPaymentMethodsAction): ConfigPaymentMethodPayload {
    switch (action.type) {
        case 'CHANGE_CONFIGPAYMENTMETHOD':
            const configpaymentmethods = dictionaryComplexFromJsonArr(action.payload || [])
            return state = {...configpaymentmethods}
        default:
            return state
    }
}