import { Link } from 'react-router-dom'
import { calculateTopOfElement } from '../../../../utils/globals/components'
import { Routes } from '../../../CreateRoutering/types'
import TwinIcon, { TwinIconProp } from '../../../TwinIcon'
import { checkIfCurrentIcon } from '../IconMenuBar/functions'
import { CurrentPagePayload } from '../../../../utils/reducers/globals/currentPage'
import { AllReduxAppPayloads, GlobalContext } from '../../../../utils/reducers'
import { ConnectedProps, connect } from 'react-redux'

interface LeftMenuPrintItemsProps extends PrintMenuLogicProps {
}

const LeftMenuPrintItems: React.FC<LeftMenuPrintItemsProps> = (props) => {
    const { renderMenuItems, selectedIndex } = usePrintMenuLogic(props)
    return (
        <div className='flex mt-40 w-full'>
            {renderMenuItems.length > 0 && <MenuIconsColorBar selectedIndex={selectedIndex} />}
            <div className='menu_icon_wrapper grid gap-y-19'>
                {renderMenuItems}
            </div>
        </div>
    )
}
interface PrintMenuLogicProps extends ReduxLeftMenuConnector {
    routes: Routes
}

const usePrintMenuLogic = ({ routes, currentPage }: PrintMenuLogicProps) => {
    const renderMenuItems: JSX.Element[] = []
    let selectedIndex = 0
    for (const key in routes) {
        const route = routes[key]
        const isCurrentIconProps = checkIfCurrentIcon(currentPage?.routes[0] || '', route.link, key)
        selectedIndex = isCurrentIconProps.selectedIndex ?? selectedIndex
        if (route.icon) {
            renderMenuItems.push(<MenuIconItem key={key} link={route.link} icon={route.icon} {...isCurrentIconProps}></MenuIconItem>)
        } else if (isCurrentIconProps.selectedIndex){
            selectedIndex = -1
        }
    }
    return {
        renderMenuItems,
        selectedIndex
    }
}

interface MenuIconsColorBarProps {
    selectedIndex: number
}

const ICON_HEIGHT = 43
const GAP_BTW_ICONS = 19

const MenuIconsColorBar: React.FC<MenuIconsColorBarProps> = ({ selectedIndex }) => {
    return (
        <div className='h-full menu_icon_colorbar'>
            <div className='icon_colorbar_bar' style={{ opacity: selectedIndex === -1 ? 0 : 1, transition: selectedIndex === -1 ? 'none' : '0.3s', top: calculateTopOfElement(ICON_HEIGHT, GAP_BTW_ICONS, selectedIndex) }}></div>
        </div>
    )
}

interface MenuIconItemProps {
    icon: TwinIconProp
    link: string
    className?: string
}

export const MenuIconItem: React.FC<MenuIconItemProps> = ({ link, icon, className }) => {
    return (
        <Link className={'flex items-center menu_icon_item ' + (className || '')} to={link}>
            <TwinIcon icon={icon} size='2x' className='text-white' />
        </Link>
    )
}

const leftMenuDispatch = {
    setChangePage: (payload: CurrentPagePayload) => ({ type: 'CHANGE_CURRENTPAGE', payload }),
}
const mapLeftMenuConnector = (state: AllReduxAppPayloads) => ({ currentPage: state.currentPage })
export const leftMenuConnector = connect(mapLeftMenuConnector, leftMenuDispatch, null, { context: GlobalContext })
export type ReduxLeftMenuConnector = ConnectedProps<typeof leftMenuConnector>

export const LeftMenuPrintItemsConnect = leftMenuConnector(LeftMenuPrintItems)
