import { faCloudArrowDown } from '@fortawesome/pro-light-svg-icons'
import { EmployeePayslipModelType } from '@teinor/erp/types/company/userInner/employee/employeePayslip'
import { useCallback } from 'react'
import { ComponentWithPermissions } from '../../../../../../../baseComponents/CreateRoutering/types'
import TwinIcon from '../../../../../../../baseComponents/TwinIcon'
import TwinTable from '../../../../../../../baseComponents/TwinTable'
import { CustomRenderDate, CustomRenderDisplayPriceWOParams } from '../../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import { twinFetchPostText } from '../../../../../../../utils/globals/data'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'


type EmployeePayslipProps = ComponentWithPermissions & {}

const EmployeePayslip: React.FC<EmployeePayslipProps> = () => {
    const { t } = useTwinTranslation()

    const columns: ColumnTableSchema<EmployeePayslipModelType> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('id', 'ID'),
            sortable: true,
            width: 100
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            }
        },
        {
            id: 'dateStart',
            dataKey: 'dateStart',
            label: t('dateStart', 'Desde'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />,
            sortable: true
        },
        {
            id: 'dateEnd',
            dataKey: 'dateEnd',
            label: t('dateEnd', 'Hasta'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue}/>
        },
        {
            id: 'amount',
            dataKey: 'amount',
            label: t('amount', 'Importe'),
            customRender: (parameterValue, allRowData) => <CustomRenderDisplayPriceWOParams value={parameterValue} CurrencyId={allRowData?.CurrencyId} />
        },
        {
            id: 'downloadFile',
            dataKey: 'id',
            label: '',
            width: 50,
            customRender: (parameterValue) => <DownloadFile id={parseInt(parameterValue)} />
        }
    ]

    return (
        <TwinTable columns={columns} getDataFrom='/api/app/employee/employeePayslip/getMyEmployeePayslips' name='employeePayslip' defaultOrder={{ order: 'desc', defaultOrderField: 'dateStart' }} />
    )
}

interface DownloadFileProps {
    id: number
}

const DownloadFile: React.FC<DownloadFileProps> = ({ id }) => {
    const { handleDownloadFile } = useDownloadFileLogic()
    return (
        <div onClick={(e) =>handleDownloadFile(id, e)}>
            <TwinIcon icon={faCloudArrowDown} className='cursor-pointer hover:text-green-43' size='lg' />
            <a className='hidden downloadfile' download={true} href='/' target='_blank' onClick={(e) => e.stopPropagation()}>a</a>
        </div>
    )
}

const useDownloadFileLogic = () => {
    const handleDownloadFile = useCallback(async (id: number, e: React.MouseEvent) => {
        const current = e.currentTarget
        const link = await twinFetchPostText('/api/app/employee/employeePayslip/getEmployeePayslipInstanceFile', { id })
        if (link && link !== 'false') {
            const a = current.querySelector('a')
            if (a) {
                a.href = link
                a.click()
            }
        }
    }, [])
    return { handleDownloadFile }
}


export default EmployeePayslip