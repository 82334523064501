import { faSquareSliders } from '@fortawesome/pro-light-svg-icons'
import { useCallback, useState } from 'react'
import { ButtonsLoadFiltersStateFull } from '../../../../../../../baseComponents/Button/ButtonLoadFilter'
import { ModalOpenedTy } from '../../../../../../../baseComponents/Modal/types'
import TwinIcon from '../../../../../../../baseComponents/TwinIcon'
import TwinTrans from '../../../../../../../baseComponents/TwinTrans'
import { TwinDictionary } from '../../../../../../../utils/globals/dictionary'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import { getEmployees, getGroups } from '../../../../../../../utils/reducers/getters'
import { parseTaskAdminCalendarFilterData, taskAdminCalendarGetDefaultSelectedData, taskAdminCalendarGetOnlyDefaultData } from '../functions'
import { TaskAdminCalendarSelectedDataProps, TaskAdminCalendarSelectedDataSetProps } from '../types'
import ModalTabsTaskCalendarAdmin from './ModalTabsTaskCalendarAdmin'

type TaskCalendarHeaderProps = TaskCalendarAdminHeaderLogicProps & TaskAdminCalendarSelectedDataProps & {
}

const TaskCalendarAdminHeader: React.FC<TaskCalendarHeaderProps> = ({ setSelectedData, selectedData }) => {
    const groups = getGroups()
    const employees = getEmployees()
    const defaultValues = taskAdminCalendarGetDefaultSelectedData({ name: 'task_calendar_admin_filter', groups: groups || null, employees: employees || null })
    const { openModal, setOpenModal, onChangeFilter } = useTaskCalendarAdminHeaderLogic({ setSelectedData })
    const { t } = useTwinTranslation()
    let filtersLength: number = 0
    for (const key in selectedData?.filters) {
        const item = selectedData.filters[key]
        if (item.length) {
            filtersLength++
        }
    }
    const isCustomFilter = selectedData.id && String(selectedData.id) !== '0'
    const name = isCustomFilter && selectedData.name ? selectedData.name : t('byDefault', 'Por defecto')
    return (
        <div className='flex items-center'>
            {filtersLength > 0 ? <span className='medium16 mr-15'><TwinTrans transKey='configuration'>Configuración</TwinTrans>: {name} ({filtersLength})</span> : null}
            <ButtonsLoadFiltersStateFull allowCreate={false} selectedFilters={selectedData} onChangeFilter={onChangeFilter} tableName={'task_calendar_admin_filter'} defaultValues={defaultValues} />
            <div className='text-green-21 flex items-center cursor-pointer' onClick={() => setOpenModal(true)} key={'setUpButtton'}>
                <TwinIcon icon={faSquareSliders} className='h-19 mr-10' />
                <span><TwinTrans transKey='setUp'>Configurar</TwinTrans></span>
            </div>
            {openModal ?
                <ModalTabsTaskCalendarAdmin
                    setOpened={setOpenModal}
                    onSubmit={() => { } }
                    setSelectedData={setSelectedData}
                    selectedData={selectedData}
                />
                : null}
        </div>
    )
}

interface TaskCalendarAdminHeaderLogicProps extends TaskAdminCalendarSelectedDataSetProps {}

const useTaskCalendarAdminHeaderLogic = ({setSelectedData}: TaskCalendarAdminHeaderLogicProps) => {
    const [openModal, setOpenModal] = useState<ModalOpenedTy>(null)
    
    const onChangeFilter = useCallback((json: TwinDictionary) => {
        const groups = getGroups()
        const employees = getEmployees()
        setSelectedData((old) => {
            if (!old) {
                return old
            }
            const copy = JSON.parse(JSON.stringify(json?.value || {}))
            parseTaskAdminCalendarFilterData(copy, groups)
            return {
                filters: copy?.filters || {},
                colors: copy?.colors || taskAdminCalendarGetOnlyDefaultData(groups, employees).colors,
                id: json.id,
                name: json.name
            }
        })
    }, [setSelectedData])

    return { openModal, setOpenModal, onChangeFilter }
}

export default TaskCalendarAdminHeader