import { EmployeeModelType } from '@teinor/erp/types/company/userInner/employee'
import { dictionaryComplexFromJsonArr } from '../../globals/dictionary'

export interface EmployeesAction {
    type: 'CHANGE_EMPLOYEE'
    payload: EmployeeModelType[]
}

export type DictionaryEmployee = { [id: number]: EmployeeModelType }

export type EmployeesPayload = DictionaryEmployee | null

export default function employees(state: EmployeesPayload = null, action: EmployeesAction): DictionaryEmployee | null {
    switch (action.type) {
        case 'CHANGE_EMPLOYEE':
            const dictionaryEmployee: DictionaryEmployee = dictionaryComplexFromJsonArr(action.payload || [])
            return state = { ...dictionaryEmployee }
        default:
            return state
    }
}