import { SetStateAction, useCallback } from 'react'
import { ModalDeleteFilledOutside } from '../../../../../../baseComponents/ModalsLayouts/ModalDeleteFilled'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import { UpdateTaskTy } from '../../../types'

interface ModalTaskDeleteProps extends ModalTaskDeleteLogicProps {
    opened: true | null
    setOpened: React.Dispatch<SetStateAction<true | null>>
}

const ModalTaskDelete: React.FC<ModalTaskDeleteProps> = ({ ...rest }) => {
    const { t } = useTwinTranslation()
    const { handleDelete } = useModalTaskDeleteLogic({ ...rest })
    return (
        <ModalDeleteFilledOutside translations={{
            title: t('deleteTask', 'Eliminar tarea'),
            subtitle:t('areYouSureToDeleteThisTaskExp', '¿Estás seguro de eliminar esta tarea y sus subtareas de forma permanente?'),
            buttonAccept: t('delete', 'Eliminar')
        }} onAccept={handleDelete} {...rest} />
    )
}

interface ModalTaskDeleteLogicProps {
    updateTask: UpdateTaskTy
    onDeletedTask: () => void
}

const useModalTaskDeleteLogic = ({ updateTask, onDeletedTask }: ModalTaskDeleteLogicProps) => {
    const handleDelete = useCallback(async() => {
        await updateTask('deleted', true)
        onDeletedTask()
    }, [updateTask, onDeletedTask])

    return { handleDelete }
}


export default ModalTaskDelete