import { faTimesSquare } from '@fortawesome/pro-light-svg-icons'
import React, { useCallback, useEffect, useState } from 'react'
import { ButtonOrLoader, ButtonPrimary } from '../../../../../../baseComponents/Button'
import { ModalMedium } from '../../../../../../baseComponents/Modal'
import { ModalOpenedSetTy, ModalOpenedTy } from '../../../../../../baseComponents/Modal/types'
import TwinIcon from '../../../../../../baseComponents/TwinIcon'
import TwinTrans from '../../../../../../baseComponents/TwinTrans'
import { CheckboxMargin } from '../../../../../../forms/Checkbox'
import CustomSelect from '../../../../../../forms/CustomSelect'
import TwinForm from '../../../../../../forms/TwinForm'
import { InputWithLabelMargin } from '../../../../../../forms/Input'
import twinFetchPost, { twinFetchPostJSON } from '../../../../../../utils/globals/data'
import { TwinDictionary, dictionaryComplexFromJsonArr } from '../../../../../../utils/globals/dictionary'
import useIsLoading from '../../../../../../utils/hooks/useIsLoading'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import { Company } from '../../../../../../utils/reducers/globals/company'
import { changeCompany, changeErrorMessage } from '../../../../../../utils/reducers/reduxDispatch'
import { UserInvite, UserInvites } from './types'

interface UsersModalProps extends UsersModalLogicProps {
    setOpened: ModalOpenedSetTy
    company: Company
}

const UsersModal: React.FC<UsersModalProps> = ({ opened, setOpened, company }) => {
    const { user, changeUserInviteProp, invitedUsers, handleInvitedUser, setInvitedUsers } = useUsersModalLogic({opened})
    const {t} = useTwinTranslation()
    return (
        <ModalMedium {...{opened, setOpened}}>
            <span className='text-22 admin_add_user'>
                <TwinTrans transKey={'addNewUser'}>Añade nuevo usuario</TwinTrans>
            </span>
            <form className='flex items-center justify-between add_user' onSubmit={handleInvitedUser}>
                <InputWithLabelMargin type='email' label={t('writeEmailUser', 'Escribe el email del usuario')} value={user.email} onChange={(value) => changeUserInviteProp('email', value)} className='admin_add_user_email' name='admin_add_user_email'/>
                <CustomSelect className='w-150 admin_user_select_type' items={dictionaryComplexFromJsonArr(company.Groups)} name='GroupId' label={t('typeUser', 'Tipo de usuario ')} value={user.GroupId} onChange={(value) => changeUserInviteProp('GroupId', value)} required/>
                <CheckboxMargin name='multiUser' label={t('multiUser', 'Usuario múltiple')} checked={user.multiUser} onChange={(value) => changeUserInviteProp('multiUser', value)}/>
                <ButtonPrimary className='prepare_add_user mt-auto' disabled={user.email === ''}>
                    <TwinTrans transKey={'add'}>Añadir</TwinTrans>
                </ButtonPrimary>
            </form>
            <ListUserInvite id={company.id} {...{ invitedUsers, setInvitedUsers, setOpened}}/>
        </ModalMedium>
    )
}


const userReset: UserInvite = {
    email: '',
    GroupId: '',
    multiUser: false
}

interface UsersModalLogicProps {
    opened: ModalOpenedTy
}

const useUsersModalLogic = ({ opened }: UsersModalLogicProps) => {
    const [user, setUser] = useState(userReset)
    const [invitedUsers, setInvitedUsers] = useState<UserInvites>([])

    const addToInvitedUsers = useCallback((user: UserInvite) => {
        setInvitedUsers((oldInvitedUsers) => {
            return [...oldInvitedUsers, user]
        })
    }, [setInvitedUsers])

    const handleInvitedUser = useCallback((e: React.FormEvent) => {
        e.preventDefault()
        addToInvitedUsers(user)
        setUser(userReset)
    }, [user, addToInvitedUsers])

    const changeUserInviteProp = useCallback(<K extends keyof UserInvite>(prop: K, value: UserInvite[K]) => {
        setUser((oldUser) => {
            return {...oldUser, [prop]: value}
        })
    }, [setUser])

    useEffect(() => {
        if (!opened) {
            setInvitedUsers([])
            setUser(userReset)
        }
    }, [opened])

    return { user, changeUserInviteProp, invitedUsers, handleInvitedUser, setInvitedUsers}
}

type ListUserInviteProps = ListUserInviteLogicProps & RenderInvitedUsersProps & {}

const ListUserInvite: React.FC<ListUserInviteProps> = ({ id, invitedUsers, setOpened, ...rest }) => {
    const {t} = useTwinTranslation()
    const { startLoading, endLoading, loading } = useIsLoading()
    const { addInvitedUsersToForm} = useListUserInviteLogic({id, invitedUsers, setOpened, startLoading, endLoading})
    if (!invitedUsers.length) {
        return null
    }
    return (
        <TwinForm action='' beforeSubmitHandler={(vals) => { addInvitedUsersToForm(vals); return false }} onError={endLoading}>
            <input type='hidden' name='id' defaultValue={id} />
            <input type='hidden' name='absoluteAdmin' value='false' />
            <input name='users' type='hidden' readOnly={true} />
            <RenderInvitedUsers {...{ invitedUsers, ...rest }} />
            <div className='flex justify-end mt-32'>
                <ButtonOrLoader buttonIsDisabled={loading} textButton={t('finalize', 'Finalizar') }/>
            </div>
        </TwinForm>
    )
}

interface ListUserInviteLogicProps {
    id: number
    invitedUsers: UserInvites
    setOpened: ModalOpenedSetTy
    startLoading?: () => boolean
    endLoading?: () => void
}

const useListUserInviteLogic = ({ id, invitedUsers, setOpened, startLoading, endLoading}: ListUserInviteLogicProps) => {
    const { t } = useTwinTranslation()

    const exceededLimitUsersError = t('exceedLimitUsersContactTeinor', 'Ha excedido el límite de usuarios. Contacte con Teinor')

    const getCompanyData = useCallback(async () => {
        const company = await twinFetchPostJSON('/api/company/getCompany', { id, include: true })
        await changeCompany(company)
    }, [id])

    const handleSubmit = useCallback(async (vals: TwinDictionary) => {
        const response = await twinFetchPost('/api/company/inviteUser', vals)
        if (response.status === 200) {
            getCompanyData()
            setOpened(null)
        } else {
            const responseJSON = await response.json()
            if (responseJSON.errors[0].msg === 'Exceeded limit of users') {
                changeErrorMessage(exceededLimitUsersError)
            }
            endLoading?.()
        }
    }, [exceededLimitUsersError, getCompanyData, setOpened, endLoading])

    const addInvitedUsersToForm = useCallback(async (vals: TwinDictionary) => {
        vals['users'] = invitedUsers
        startLoading?.()
        await handleSubmit(vals)
    }, [invitedUsers, startLoading, handleSubmit])
    
    
    
    return { addInvitedUsersToForm}
}


interface RenderInvitedUsersProps extends RenderInvitedUsersLogicProps{
    invitedUsers: UserInvites
}

const RenderInvitedUsers: React.FC<RenderInvitedUsersProps> = ({ invitedUsers, ...rest }) => {
    const {deleteUser} = useRenderInvitedUsersLogic(rest)
    const renderInviteUsers: JSX.Element[] = []
    for (const k in invitedUsers) {
        const user = invitedUsers[k]
        renderInviteUsers.push(<InviteUser key={k} email={user.email} deleteUser={() => deleteUser(parseInt(k)) } />)
    }
    return (
        <div className='mt-40 border border-gray-97 flex flex-wrap content-start admin_pending_users_container'>
            {renderInviteUsers}
        </div>
    )
}

interface RenderInvitedUsersLogicProps {
    setInvitedUsers: React.Dispatch<React.SetStateAction<UserInvites>>
}

const useRenderInvitedUsersLogic = ({ setInvitedUsers }: RenderInvitedUsersLogicProps) => {
    const deleteUser = useCallback((key: number) => {
        setInvitedUsers((oldUsers) => {
            oldUsers.splice(key, 1)
            return [...oldUsers]
        })
    }, [setInvitedUsers])

    return { deleteUser }
}


interface InviteUserProps {
    email: string
    deleteUser: () =>void
}

const InviteUser: React.FC<InviteUserProps> = ({email, deleteUser}) => {
    return (
        <div className='admin_pending_user flex justify-around items-center bg-gray-F7 mt-15 ml-15'>
        <span>{email}</span>
        <TwinIcon icon={faTimesSquare} className=' ml-20 cursor-pointer hover:text-red-BA delete_add_user' onClick={deleteUser} />
        </div>
    )
}

export default UsersModal