import { getLang } from '.'
import moment from 'moment'
import 'moment/locale/es'  
import { HourMinSecTy, HourMinTy } from './types'

export const isValidDate = (dateString: string) => {
    const date = stringToDate(dateString)
    if(date){
        return true
    }
    return false
}

export const getDateFormat = () => {
    const format = moment.localeData(getLang()).longDateFormat('l')
    const prepared = prepareFormat(format)
    return [...prepared]
}

export const getTimeFormat = () => {
    const format = moment.localeData(getLang()).longDateFormat('lt')
    const prepared = prepareFormat(format)
    return [...prepared]
}

const prepareFormat = (format: string) => {
    return addYYOption([format.replaceAll('-', '/'), format.replaceAll('/', '-')])
}

const addYYOption = (formats: string[]) => {
    const newOptions = []
    for (const format of formats) {
        newOptions.push(format.replace('YYYY', 'YY'))
    }
    return [...formats, ...newOptions]
}
export const stringToDate = (dateString: string) => {
    const momentDate = moment(dateString, getDateFormat(), true)
    if(momentDate?.isValid()){
        return momentDate?.toDate()
    }else if(dateString?.includes('T')){
        return moment?.utc(dateString)?.toDate()
    }
    return false        
}
export const displayDateTime = (date: Date | false): string => {
    if(!date){
        return ''
    }
    return date.toLocaleString(getLang())
}
export const displayDate = (date: Date | false): string => {
    if(!date){
        return ''
    }
    return date.toLocaleDateString(getLang())
}
export const displayDateByString = (date: string | false): string => {
    if(!date){
        return ''
    }
    return displayDate(new Date(date))
}
export const displayDateHours = (date: Date | false): string => {
    if(!date){
        return ''
    }
    return date.toLocaleTimeString(getLang())
}

export const getHoursMinFromMin = (mins: number) => {
    const hours = mins / 60
    const myHours = Math.trunc(hours)
    const myMins = mins - (myHours * 60)
    return { hours: myHours, mins: myMins }
}
export const getMinFromHoursMin = (hours: number, min: number) => {
    return (hours * 60) + parseInt(String(min))
}
export const checkHourValidOnChange = (value: string) => {
    if (!value) {
        return value
    }
    const re = new RegExp("^[0-9]*[:]{0,1}[0-9]*[:]{0,1}[0-9]*$")
    if (re.test(value)) {
        return value
    }
    return false
}
export const parseStringDateHoursToMins = (dateStr: string) => {
    const hours = dateStr?.split(' ')[1]
    const splited = hours?.split(':')
    const mins = getMinFromHoursMin(parseInt(splited[0]), parseInt(splited[1]))
    return String(mins)
}
export const displayFormatedHourFromMins = (valMins: number) => {
    const { hours, mins } = getHoursMinFromMin(valMins)
    let parsedMins = Math.abs(mins)
    let parsedHours = Math.abs(hours)
    let negative = false
    if (hours < 0 || mins < 0) {
        negative = true
    }
    return (negative ? '-' : '') + parseHourStr(parsedHours) + ':' + parseHourStr(parsedMins)
}
export const parseHourStr = (value: number) => {
    const valueStr = String(value)
    return valueStr.length === 1 ? '0' + valueStr : (value < 0 && valueStr.length === 2) ? '-0' + Math.abs(parseFloat(valueStr)) : valueStr
}

export const displayFormatedHour = (vals: HourMinTy) => {
    return parseHourStr(vals.hours) + ':' + parseHourStr(vals.mins)
}
export const getHoursMinsFromFormattedHour = (vals: string) => {
    const timeSplitted = vals.split(':')
    return { min: parseInt(timeSplitted[0]), hour: parseInt(timeSplitted[1]) }
}

export const displayDateWithFormattedHours = (date: Date) => {
    return moment(date).format('DD/MM/YYYY HH:mm')
}
export const displayJustHoursFormatted = (date: Date) => {
    return moment(date).format('HH:mm')
}

export const parseDateToFormattedHourMins = (mins: string) => {
    const hoursAndMins = getHoursMinFromMin(parseInt(mins || '0'))
    return { mins: mins, formatted: hoursAndMins }
}

export const getHoursMinSecFromSecs = (secs: number) => {
    const hours = Math.trunc(Math.abs(secs / 3600))
    const myHours = (secs < 0) ? -hours : hours
    const mins = Math.trunc(Math.abs((secs / 60) % 60))
    const mySecs = Math.abs(secs % 60)
    return { hours: myHours, mins, secs: mySecs }
}
export const getSecFromHoursMinsSecs = (hours: number, mins: number, secs: number) => {
    return (hours * 3600) + parseInt(String(mins)) * 60 + secs
}
export const displayFormatedHourMinSec = (vals: HourMinSecTy) => {
    return parseHourStr(vals.hours) + ':' + parseHourStr(vals.mins) + ':' + parseHourStr(vals.secs)
}
export const displayFormatedHourMinSecFromSecs = (secs: number) => {
    const vals = getHoursMinSecFromSecs(secs)
    return parseHourStr(vals.hours) + ':' + parseHourStr(vals.mins) + ':' + parseHourStr(vals.secs)
}
export const getFirstAndLastDayOfThisWeek = () => {
    const current = new Date()
    const first = current.getDate() - current.getDay() + 1
    const last = first + 6
    const start = new Date(current.setDate(first))
    const end = new Date()
    end.setDate(last)
    start.setHours(0, 0, 0)
    end.setHours(23, 59, 59)

    return { start, end }
}
export const displayFormatedHourFromSecs = (valSecs: number) => {
    const { hours, mins, secs } = getHoursMinSecFromSecs(valSecs)
    let parsedSecs = Math.abs(secs)
    let parsedMins = Math.abs(mins)
    let parsedHours = Math.abs(hours)
    let negative = false
    if (hours < 0 || mins < 0 || secs < 0) {
        negative = true
    }
    return (negative ? '-' : '') + parseHourStr(parsedHours) + ':' + parseHourStr(parsedMins) + ':' + parseHourStr(parsedSecs)
}

export const stringToDateString = (dateStr: string) => {
    if (dateStr !== '') {
        const myDate = stringToDate(dateStr)
        if (myDate) {
            return myDate?.toDateString() || ''
        }
    }
    return false
}