import { useCallback, useState } from 'react'
import { useTabObjectLogic } from '../../../utils/hooks/useTabLogic'
import { getAntiUnsave } from '../../../utils/reducers/getters'
import { showAntiUnsaveModal } from '../../../utils/reducers/reduxDispatch'
import { EditCardTabsLeftBarDiv } from '../../EditCardTabs'
import { TabsInfo } from '../../EditCardTabs/types'
import Modal, { CloseLateralModal } from '../../Modal'
import { ModalComponentProps } from '../types'

type ModalTabsProps = TabsInfo & ModalComponentProps & {
    className?: string
    extraComponentData?: {[key: string]: any}
}

const ModalTabs: React.FC<ModalTabsProps> = ({ className, tabs, tab, extraComponentData, ...rest }) => {
    const {changeTab, tabActive } = useModalTabsLogic({tab})
    const { active, ComponentFromTab } = useTabObjectLogic(tabs, tabActive)
    return (
        <Modal className={'h-full modal_tabs flex ' + (className || '')} opened={true} setOpened={rest.setOpened} onClickOut={false}>
            <EditCardTabsLeftBarDiv tabs={tabs} tab={active} onClick={changeTab}/>
            <div className='flex flex-col flex-auto modal_tab_inner w-1'>
                <CloseLateralModal {...{ setOpened: rest.setOpened }} />
                <ComponentFromTab {...rest} {...extraComponentData} />
            </div>
        </Modal>
    )
}

interface ModalTabsLogicProps {
    tab?: string | number
}

const useModalTabsLogic = ({tab}: ModalTabsLogicProps) => {
    const [tabActive, setTabActive] = useState(tab)
    const changeTab = useCallback((tabId: any) => {
        const errors = getAntiUnsave()
        if(Object.keys(errors).length){
            showAntiUnsaveModal(() => setTabActive(tabId))
        } else {
            setTabActive(tabId)
        }
    }, [])
    return {changeTab, tabActive}
}

export default ModalTabs