import Calendar, { CalendarProps } from 'react-calendar';
import { twinCalendarMonthFormat, twinCalendarShortWeekDayFormat } from '../functions';
import './twincalendaryearview.sass'

interface TwinCalendarYearViewProps extends CalendarProps {}

const TwinCalendarYearView: React.FC<TwinCalendarYearViewProps> = ({ activeStartDate, onClickDay, tileClassName, locale, tileContent }) => {
    return (
        <Calendar
            formatMonthYear={(locale: string, date: Date) => twinCalendarMonthFormat(locale, date)}
            formatShortWeekday={(locale: string, date: Date) => twinCalendarShortWeekDayFormat(locale, date).slice(0, -1)}
            {...{ onClickDay, tileClassName, activeStartDate, locale, tileContent }}
        />
    )
}

export default TwinCalendarYearView