import { CustomerModelType } from '@teinor/erp/types/company/customer'
import { ComponentWithPermissions } from '../../../../../../../../../baseComponents/CreateRoutering/types'
import SingleFormLayout from '../../../../../../../../../baseComponents/Layout/SingleFormLayout'
import { FormRender } from '../../../../../../../../../forms/FormRenderer/types'
import { valueOrDefaultValue } from '../../../../../../../../../utils/globals/valueOrDefaultValue'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import { displayDateTime } from '../../../../../../../../../utils/globals/date'
import TwinTrans from '../../../../../../../../../baseComponents/TwinTrans'
import TwinIcon from '../../../../../../../../../baseComponents/TwinIcon'
import { faCloudDownload, faTrashCan } from '@fortawesome/pro-light-svg-icons'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { twinFetchPostJSON } from '../../../../../../../../../utils/globals/data'
import ModalDeleteCustomerB2BGdprFile from './ModalDeleteCustomerB2BGdprFile'
import useEditDeleteModal from '../../../../../../../../../utils/hooks/useEditDeleteModal'
import LoadingSpinner from '../../../../../../../../../baseComponents/LoaderDecider/LoadingSpinner'

interface CustomerB2BGdprProps extends ComponentWithPermissions {
    basicData: CustomerModelType | null
    getData: () => Promise<void>
}

const CustomerB2BGdpr: React.FC<CustomerB2BGdprProps> = ({ basicData, userPermissions }) => {
    const { t } = useTwinTranslation()
    let renderThis: JSX.Element | null = null
    const { data, getOtherData, openModal, setOpenModal } = useCustomerB2BGdprLogic({ basicData })
    if (!data) {
        return <LoadingSpinner />
    }
    const fields: FormRender<CustomerModelType> = [
        {
            cols: 2,
            title: t('CustomerB2BGdpr', 'RGPD'),
            elements: [
                {
                    name: 'gdpr_accepted',
                    label: t('dataProtectionAct', 'Ley de protección de datos'),
                    component: 'CheckboxMargin',
                },
                {
                    name: 'id',
                    component: 'InputHidden',
                    value: basicData?.id
                }
            ]
        }
    ]
    if (data && data?.gdpr_file) {
        fields[0].elements.push({
            name: 'gdpr_file',
            component: 'Custom',
            render: () => <CustomerRGPDFile deleteFile={() => setOpenModal({ type: 'delete', allRowData: data })} data={data} />
        })
    } else {
        fields[0].elements.push({
            name: 'gdpr_file',
            label: t('file', 'Archivo'),
            component: 'FilesInput',
        })
    }

    const parsedFields = valueOrDefaultValue(fields, userPermissions, data)
    return (
        <Fragment>
            <SingleFormLayout action='/api/app/customer/updateCustomer' imagepath={['customer', String(basicData?.id)]} onSubmit={getOtherData} sections={parsedFields} userPermissions={userPermissions} />
            <div className='flex flex-auto flex-col '>
                {renderThis}
            </div>
            {openModal?.type === 'delete' && <ModalDeleteCustomerB2BGdprFile {...{ setOpened: () => setOpenModal(null) }} allRowData={openModal.allRowData} onSubmit={getOtherData} />}
        </Fragment>
    )
}

interface CustomerB2BGdprLogicProps {
    basicData: CustomerModelType | null
}

const useCustomerB2BGdprLogic = ({ basicData }: CustomerB2BGdprLogicProps) => {
    const [data, setData] = useState<CustomerModelType | null>(null)
    const { openModal, setOpenModal } = useEditDeleteModal()

    const getOtherData = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/customer/getGDPRCustomerInstance', { "id": basicData?.id })
        setData(result)
    }, [basicData])

    useEffect(() => {
        getOtherData()
    }, [getOtherData])

    return { data, getOtherData, openModal, setOpenModal }
}

interface CustomerRGPDFileProps {
    data: CustomerModelType
    deleteFile: () => void
}

const CustomerRGPDFile: React.FC<CustomerRGPDFileProps> = ({ data, deleteFile }) => {
    return (
        <div className='flex items-center gap-20'>
            <div>
                <div className='mb-6'><span className='medium12'><TwinTrans transKey={'responsible'}>Responsable</TwinTrans></span>  <span className='light12'>| {displayDateTime(new Date())}</span></div>
                <a href={data.gdpr_file} className=' block text-green-21 mt-10 cursor-pointer' rel="noreferrer">
                    <TwinTrans transKey='downloadFile'>Descargar fichero</TwinTrans>
                    <TwinIcon className='ml-10 ' icon={faCloudDownload} />
                </a>
            </div>
            <TwinIcon className='ml-5 cursor-pointer' icon={faTrashCan} onClick={deleteFile} />
        </div>
    )
}

export default CustomerB2BGdpr