import { useCallback, useEffect, useState } from 'react'
import { ModalLittle } from '../../../../../../../../../../baseComponents/Modal'
import useTwinTranslation from '../../../../../../../../../../utils/hooks/useTwinTranslation'
import FormRenderer from '../../../../../../../../../../forms/FormRenderer'
import TwinTrans from '../../../../../../../../../../baseComponents/TwinTrans'
import { FormRender } from '../../../../../../../../../../forms/FormRenderer/types'
import { ModalEditComponentProps } from '../../../../../../../../../../baseComponents/ModalsLayouts/types'
import { valueOrDefaultValue } from '../../../../../../../../../../utils/globals/valueOrDefaultValue'
import { displayDate } from '../../../../../../../../../../utils/globals/date'
import { ButtonOrLoader } from '../../../../../../../../../../baseComponents/Button'
import { twinFetchPostJSON } from '../../../../../../../../../../utils/globals/data'
import { changeErrorMessage, changeSuccessMessage } from '../../../../../../../../../../utils/reducers/reduxDispatch'
import { TwinDictionary } from '../../../../../../../../../../utils/globals/dictionary'
import PermissionChecker from '../../../../../../../../../../baseComponents/PermissionChecker'
import useStatusRequestTypes from '../../../../../../../../../../utils/hooks/useStatusRequestTypes'
import useIsLoading from '../../../../../../../../../../utils/hooks/useIsLoading'
import { getUserPermissions } from '../../../../../../../../../../utils/reducers/getters'
import { EmployeeAbsenceModelType, EmployeeAbsenceTypeKeysInversed } from '@teinor/erp/types/company/userInner/employee/employeeAbsence'
import CustomRenderSchedules from '../../../../../../../../../../specificComponents/WCal/schedule/CustomRenderSchedules'
import LoadingSpinner from '../../../../../../../../../../baseComponents/LoaderDecider/LoadingSpinner'

interface ModalAdminNotificationsAbsencesProps extends ModalAdminNotificationsAbsencesLogicProps {
}

const ModalAdminNotificationsAbsences: React.FC<ModalAdminNotificationsAbsencesProps> = ({ setOpened, allRowData, onSubmit }) => {
    const { t } = useTwinTranslation()
    const status = useStatusRequestTypes()
    const { data, manageRequestedAbsences, loading } = useModalAdminNotificationsAbsencesLogic({ allRowData, setOpened, onSubmit })
    const statusButtons: TwinDictionary = {
        'approved': <ButtonOrLoader onClick={() => manageRequestedAbsences('approved')} textButton={t('approve', 'Aprobar')} buttonIsDisabled={loading} />,
        'denied': <ButtonOrLoader onClick={() => manageRequestedAbsences('denied')} textButton={t('deny', 'Denegar')} buttonIsDisabled={loading} className='button_red' />,
    }
    const renderThis: JSX.Element[] = []
    if (data?.approved === 0) {
        renderThis.push(statusButtons['approved'])
        renderThis.push(statusButtons['denied'])
    } else {
        renderThis.push(<div><TwinTrans transKey={'requestManaged'}>Esta solicitud ya ha sido gestionada</TwinTrans></div>)
    }
    if (!data) {
        return (
            <ModalLittle opened={true} setOpened={setOpened} onClickOut={false} className={'flex flex-col'}>
                <LoadingSpinner />
            </ModalLittle>
        )
    }
    const fields: FormRender<EmployeeAbsenceModelType> = [
        {
            cols: 1,
            elements: [
                {
                    name: 'EmployeeId',
                    component: 'TextField',
                    value: data?.Employee?.fullname_short,
                    label: t('employee', 'Empleado')
                },
                {
                    name: 'dateStart',
                    component: 'TextField',
                    label: t('dateStart', 'Desde'),
                    value: displayDate(new Date(data?.dateStart || ''))
                },
                {
                    name: 'dateEnd',
                    component: 'TextField',
                    label: t('dateEnd', 'Hasta'),
                    value: displayDate(new Date(data?.dateEnd || '')),
                },
                {
                    name: 'description',
                    component: 'TextField',
                    label: t('description', 'Descripción'),
                    value: data?.description
                },
                {
                    name: 'EmployeeAbsenceTypeId',
                    component: 'TextField',
                    label: t('type', 'Tipo'),
                    value: data?.EmployeeAbsenceType?.name
                },
                {
                    name: 'schedule',
                    component: 'Custom',
                    render: () => <CustomRenderSchedules schedule={data?.schedule} />,
                },
                {
                    name: 'proof_file_url',
                    component: 'FilesInput',
                    label: t('absenceProof', 'Justificante ausencia'),
                    defaultValue: data?.proof_file_url,
                    readOnly: true
                },
                {
                    name: 'approved',
                    component: 'TextField',
                    label: t('status', 'Estado'),
                    value: status[parseInt(String(data?.approved))]?.name
                },
                {
                    name: 'id',
                    component: 'InputHidden',
                    value: data?.id
                },
            ]
        },
    ]
    const userPermission = getUserPermissions('WCal.absences.control')
    if (userPermission) {
        const parsedFields = valueOrDefaultValue(fields, userPermission, allRowData)
        return (
            <ModalLittle opened={true} setOpened={setOpened} onClickOut={false} className={'flex flex-col'}>
                <div className='mb-25'>
                    <h2><TwinTrans transKey='notificationInfo'>Información de la notificación</TwinTrans></h2>
                </div>
                <div className={'flex flex-auto h-1'}>
                    <div className='overflow-auto flex flex-auto flex-col'>
                        {fields.length ?
                            <FormRenderer sections={parsedFields} className='grid_mincontent'>
                            </FormRenderer>
                            : ''}
                    </div>
                </div>
                <div className='mt-30 ml-auto flex gap-15 edit_holiday_requests_footer'>
                    <PermissionChecker userPermissions={userPermission} permission={'update'}>
                        {renderThis}
                    </PermissionChecker>
                </div>
            </ModalLittle>
        )
    }
    return null
}

interface ModalAdminNotificationsAbsencesLogicProps extends ModalEditComponentProps {
}

const useModalAdminNotificationsAbsencesLogic = ({ allRowData, setOpened, onSubmit }: ModalAdminNotificationsAbsencesLogicProps) => {
    const { endLoading, loading, startLoading } = useIsLoading()
    const { t } = useTwinTranslation()
    const [data, setData] = useState<EmployeeAbsenceModelType | null>(null)
    const message = t('successfullyCompleted', 'Realizado correctamente')
    const manageRequestedAbsences = useCallback(async (status: EmployeeAbsenceTypeKeysInversed) => {
        startLoading()
        const result = await twinFetchPostJSON('/api/app/workingCalendar/employeeAbsence/manageAbsenceRequest', {
            id: data?.id,
            approved: status === 'approved' ? 1 : 2
        })
        if (result) {
            onSubmit(result, {})
            changeSuccessMessage(message)
            setOpened(null)
        } else {
            changeErrorMessage(true)
        }
        endLoading()
    }, [data?.id, setOpened, onSubmit, message, startLoading, endLoading])

    const getAbsenceData = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/workingCalendar/employeeAbsence/getEmployeeAbsenceInstance', { id: allRowData?.SubId })
        if (result) {
            setData(result)
        }
    }, [setData, allRowData?.SubId])

    useEffect(() => {
        getAbsenceData()
    }, [getAbsenceData])
    return { manageRequestedAbsences, data, loading }
}

export default ModalAdminNotificationsAbsences