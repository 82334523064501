import { EmployeeExpenseModelType, EmployeeExpenseStatusKeys } from '@teinor/erp/types/company/userInner/employee/employeeExpense'
import { ConnectedProps, connect } from 'react-redux'
import { ComponentWithPermissions } from '../../../../../../baseComponents/CreateRoutering/types'
import { DisplayStatusWithoutIcon } from '../../../../../../baseComponents/Displays/DisplayStatus'
import { permissionCheck } from '../../../../../../baseComponents/PermissionChecker/function'
import TwinTable from '../../../../../../baseComponents/TwinTable'
import BaseScreenTable from '../../../../../../baseComponents/TwinTable/BaseScreenTable'
import { TableComponentProps } from '../../../../../../baseComponents/TwinTable/BaseScreenTable/types'
import { CustomRenderBoolean, CustomRenderDate, CustomRenderDisplayPriceWOParams, CustomRenderString, EditRow } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import { TwinDictionary } from '../../../../../../utils/globals/dictionary'
import withLoading from '../../../../../../utils/hoc/withLoading'
import useEmployeeExpensesType from '../../../../../../utils/hooks/employeeExpenses/useEmployeeExpensesType'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import { AllReduxPayloads } from '../../../../../../utils/reducers'
import { EmployeesPayload } from '../../../../../../utils/reducers/company/employees'
import ModalCreateExpensesRequest from './ModalCreateExpensesRequest'
import ModalEditExpensesRequest from './ModalEditExpensesRequest'

interface ExpensesRequestProps extends ComponentWithPermissions { }

const ExpensesRequest: React.FC<ExpensesRequestProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()
    const pageData = {
        title: t('expensesListing', 'Listado de gastos'),
        newText: t('newExpense', 'Nuevo gasto'),
    }
    return (
        <BaseScreenTable TableComponent={TableExpensesRequestConnect} pageData={pageData} userPermissions={userPermissions} EditModalComponent={ModalEditExpensesRequest} CreateModalComponent={ModalCreateExpensesRequest} />
    )
}

type TableExpensesRequestProps = TableComponentProps & ReduxEmployees &{}


const TableExpensesRequest: React.FC<TableExpensesRequestProps> = ({ userPermissions, setTableInstance, setOpened, employees }) => {
    const { t } = useTwinTranslation()
    const { expenseStatus } = useEmployeeExpensesType()

    const columns: ColumnTableSchema<EmployeeExpenseModelType> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('id', 'ID'),
            width: 50
        },
        {
            id: 'EmployeeId',
            dataKey: 'EmployeeId',
            label: t('employee', 'Employee'),
            sortable: true,
            searchKey: 'fullname_short',
            searchSingleKey: 'EmployeeId',
            searchableComponent: {
                component: 'SearchableComponentsCustomSelectSearchBar',
                extraComponentData: { items: employees as TwinDictionary, label: t('employee', 'Empleado'), fieldName: 'fullname_short'},
            },
            customRender: (parameterValue, allRowData) => <CustomRenderString value={allRowData?.Employee?.fullname_short} />,
        },
        {
            id: 'dateStart',
            dataKey: 'dateStart',
            label: t('dateStart', 'Desde'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />,
            sortable: true
        },
        {
            id: 'dateEnd',
            dataKey: 'dateEnd',
            label: t('dateEnd', 'Hasta'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />
        },
        {
            id: 'km',
            dataKey: 'km',
            label: t('km', 'Km'),
            width: 100
        },
        {
            id: 'location',
            dataKey: 'location',
            label: t('location', 'Ubicación'),
        },
        {
            id: 'abroad',
            dataKey: 'abroad',
            label: t('abroad', 'Extranjero'),
            width: 90,
            customRender: (parameterValue) => <CustomRenderBoolean value={String(parameterValue) === 'true'} width={67}/>
        },
        {
            id: 'overnight_stay',
            dataKey: 'overnight_stay',
            label: t('overnightStay', 'Pernoctación'),
            width: 100,
            customRender: (parameterValue) => <CustomRenderBoolean value={String(parameterValue) === 'true'} width={100}/>,
        },
        {
            id: 'total_amount',
            dataKey: 'total_amount',
            label: t('totalAmount', 'Importe total'),
            customRender: (parameterValue, allRowData) => <CustomRenderDisplayPriceWOParams value={parameterValue} CurrencyId={allRowData?.CurrencyId} />
        },
        {
            id: 'status',
            dataKey: 'status',
            label: t('status', 'Estado'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentsCustomSelect',
                extraComponentData: { items: expenseStatus as TwinDictionary, label: t('status', 'Estado')},
            },
            customRender: (parameterValue) => <DisplayStatusWithoutIcon text={expenseStatus[parseInt(parameterValue) as EmployeeExpenseStatusKeys].name} colorText={expenseStatus[parseInt(parameterValue) as EmployeeExpenseStatusKeys].colorText} />
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            customRender: (parameterValue, allRowData) => <EditRow key={'edit' + parameterValue} {...{ parameterValue, allRowData }} />,
            havePermission: permissionCheck(userPermissions, 'update')
        }
    ]
    return (
        <TwinTable name='ExpensesRequest' columns={columns} getDataFrom='/api/app/employeeExpense/getAllEmployeeExpenses' onRowClick={(_id, rowData) => setOpened({
            type: 'edit',
            allRowData: rowData
        })} setTableInstance={setTableInstance} />
    )
}


const employeesDispatch = {
    setEmployees: (payload: EmployeesPayload) => ({ type: 'CHANGE_EMPLOYEE', payload }),
}

export type ReduxEmployees = ConnectedProps<typeof employeesConnect>
const mapEmployeesConnector = (state: AllReduxPayloads) => ({ employees: state.employees })
const employeesConnect = connect(mapEmployeesConnector, employeesDispatch)

const employeesConnectLoading = withLoading(TableExpensesRequest, [{ fetchUrl: '/api/app/employee/getAllEmployees', propName: 'employees', setFunctionName: 'setEmployees' }])

const TableExpensesRequestConnect = employeesConnect(employeesConnectLoading)

export default ExpensesRequest