import { useLocation, useNavigate } from 'react-router'
import ModalDeleteFilled from '../../../../../../../baseComponents/ModalsLayouts/ModalDeleteFilled'
import { ModalEditComponentProps } from '../../../../../../../baseComponents/ModalsLayouts/types'
import { getBasePath } from '../../../../../../../utils/globals/link'
import { useFetchText } from '../../../../../../../utils/hooks/useFetchText'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import { useCallback } from 'react'

interface ModalDeleteTaskCustomFieldProps extends ModalEditComponentProps { }

const ModalDeleteTaskCustomField: React.FC<ModalDeleteTaskCustomFieldProps> = ({ setOpened, onSubmit, allRowData }) => {
    const { t } = useTwinTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    
    const { handleFetchText } = useFetchText({
        onSubmit,
        url: '/api/app/task/customField/deleteTaskCField',
        data: {
            id: allRowData?.id,
        }
    })

    const deleteAndNavigate = useCallback(async () => {
        await handleFetchText()
        navigate(getBasePath(location.pathname, 'customFields') + '/main'); 
    }, [handleFetchText, navigate, location.pathname])
    return (
        <ModalDeleteFilled opened={true} setOpened={setOpened} onCancel={() => setOpened(null)} onAccept={deleteAndNavigate} translations={{
            title: t('sureDeleteTaskCustomField', '¿Seguro quieres eliminar este campo personalizado y sus valores?'),
            subtitle: t('onceDeletedNotRecoverM', 'Una vez eliminado no podrás recuperarlo')
        }} />
    )
}

export default ModalDeleteTaskCustomField