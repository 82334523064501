import { RangeViewDate } from './types'

export const rangeViewGenerateDays = (startDate: Date, endDate: Date) => {
    const dates: RangeViewDate[] = []
    const currentDate = new Date(startDate)
    while (endDate >= currentDate) {
        const key = currentDate.toDateString()
        dates.push({
            key,
            text: currentDate.toLocaleDateString(undefined, {
                weekday: 'short',
                month: 'long',
                day: 'numeric',
            })
        })
        currentDate.setDate(currentDate.getDate() + 1)
    }
    return dates
}