import { useCallback, useState } from 'react'
import { InputNumberWithLabelMargin, RectangularInputFormatNumberSmallProps } from '..'
import { getCurrencySymbol } from '../../../utils/globals/price'
import { Modify } from '@teinor/erp'


export type InputPriceStateFullProps = Modify<InputPriceProps, InputPriceStateFullLogicProps & {
    value?: never
}>

export const InputPriceStateFull: React.FC<InputPriceStateFullProps> = ({ onChange, defaultValue, ...rest }) => {
    const { myOnChange, myValue } = useInputPriceStateFullLogic({ onChange, defaultValue, ...rest })
    return (
        <InputPrice  {...rest} value={myValue} onChange={myOnChange} />
    )
}

interface InputPriceStateFullLogicProps {
    defaultValue?: string | number
    onChange: (value: string) => void
}

const useInputPriceStateFullLogic = ({ onChange, defaultValue }: InputPriceStateFullLogicProps) => {
    const [myValue, setMyValue] = useState<string>(String(defaultValue || ''))

    const myOnChange = useCallback((value: string) => {
        setMyValue(value)
        if (onChange) {
            onChange(String(value))
        }
    }, [onChange])
    
    return { myOnChange, myValue }
}


export interface InputPriceProps extends RectangularInputFormatNumberSmallProps {
    CurrencyId: string
    label: string
}

const InputPrice: React.FC<InputPriceProps> = ({ CurrencyId, className, ...rest }) => {
    const CurrencySymbol = getCurrencySymbol(CurrencyId)
    return (
        <div className={'flex items-end input_price ' + (className || '') }>
            <InputNumberWithLabelMargin {...rest} className='flex-auto w-1' /> <span className='ml-10'>{CurrencySymbol}</span>
        </div>
    )
}

export default InputPrice