import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons'
import TwinIcon from '../../TwinIcon'
import './loading_spinner.sass'

interface LoadingSpinnerProps {
    className?: string
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({className}) => {
    return (
        <div className={'loading_spinner flex justify-center items-center flex-auto ' + (className || '')}>
            <TwinIcon icon={faSpinnerThird} spin={true} size='3x' className='text-green-43 max-h-full' />
        </div>
    )
}

export default LoadingSpinner