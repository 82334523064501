import { faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faSearch } from '@fortawesome/pro-light-svg-icons'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { WhiteBox } from '../../../../../../baseComponents/AppLayout/WhiteBox'
import { ButtonPrimary, ButtonSecondary } from '../../../../../../baseComponents/Button'
import { ComponentWithPermissions } from '../../../../../../baseComponents/CreateRoutering/types'
import { BigTitleAndRightLayout } from '../../../../../../baseComponents/Layout/TitleAndRightLayout'
import LoadingSpinner from '../../../../../../baseComponents/LoaderDecider/LoadingSpinner'
import TwinCalendar from '../../../../../../baseComponents/TwinCalendar'
import TwinIcon from '../../../../../../baseComponents/TwinIcon'
import { useBaseScreenTableLogic } from '../../../../../../baseComponents/TwinTable/BaseScreenTable'
import TwinTrans from '../../../../../../baseComponents/TwinTrans'
import { CustomSelectWithSearchBar } from '../../../../../../forms/CustomSelect'
import TableControlMonthWork from '../../../../../../specificComponents/WCal/TableControlMonthWork'
import TableControlYearWork from '../../../../../../specificComponents/WCal/TableControlMonthWork/TableControlYearWork'
import { getTotalMonthData } from '../../../../../../specificComponents/WCal/TableControlMonthWork/functions'
import { TimeControlEmployeeJourneyData } from '../../../../../../specificComponents/WCal/TableControlMonthWork/types'
import twinFetchPost, { twinFetchPostJSON } from '../../../../../../utils/globals/data'
import { getBasePath } from '../../../../../../utils/globals/link'
import withLoading from '../../../../../../utils/hoc/withLoading'
import useDictMonths from '../../../../../../utils/hooks/useDictMonths'
import useEditDeleteModal from '../../../../../../utils/hooks/useEditDeleteModal'
import useIsLoading from '../../../../../../utils/hooks/useIsLoading'
import useOnClickOutside from '../../../../../../utils/hooks/useOnClickOut'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import useUpdateTableInstance from '../../../../../../utils/hooks/useUpdateTableInstance'
import { AllReduxPayloads } from '../../../../../../utils/reducers'
import { DictionaryEmployee, EmployeesPayload } from '../../../../../../utils/reducers/company/employees'
import ModalTCEmployeePdfMultiple from './ModalTCEmployeePdfMultiple'
import { EmployeeJourneyTyExtended } from './types'
import { timeControlEmployeePDFParseDates } from './functions'
import './timeControlEmployeeMonth.sass'
import { getEmployees } from '../../../../../../utils/reducers/getters'

type TimeControlEmployeeMonthProps = ComponentWithPermissions & ReduxTimeControlEmployeeMonthDispatch  & { }

const TimeControlEmployeeMonth: React.FC<TimeControlEmployeeMonthProps> = ({ userPermissions, employees }) => {
    const {t} = useTwinTranslation()
    const { baseParamsParsed, changeShowYear, showYears, data } = useTimeControlEmployeeMonthLogic()
    const { setTableInstance, tableInstance, setOpenModal, } = useBaseScreenTableLogic()
    const { updateTableInstance } = useUpdateTableInstance({ tableInstance })
    const commonProps = {
        setOpened: setOpenModal,
        userPermissions,
        updateTableInstance,
        setTableInstance,
        tableInstance
    }
    
    return (
        <BigTitleAndRightLayout title={t('timeControl', 'Control horario')} RightHeader={<RightHeader changeShowYear={changeShowYear} showYears={showYears} baseParamsParsed={baseParamsParsed}/>}>
            <WhiteBox className='flex-auto p-24 flex flex-col control_employee_working_month'>
                <HeaderTimeControlEmployee changeShowYear={changeShowYear} showYears={showYears} employees={employees || {}} {...baseParamsParsed} />
                {showYears && data?.type === 'year' ?
                    <TableControlYearWork {...{...commonProps, data: data.data, year: baseParamsParsed.year }} /> :
                    data?.type === 'month' ? 
                        <TableControlMonthWork {...{ ...commonProps, data: data?.data}} /> : null}
            </WhiteBox>
        </BigTitleAndRightLayout >
    )
}
interface BaseParamsParsed {
    year: number
    month: number
    EmployeeId: number
    basePath: string
}
const useTimeControlEmployeeMonthLogic = () => {
    const [data, setData] = useState<TimeControlEmployeeJourneyData>(null)
    const [showYears, setShowYears] = useState<boolean>(false)
    const location = useLocation()
    const params = useParams<{ EmployeeId: string, month: string, year: string }>()

    const baseParamsParsed: BaseParamsParsed = useMemo(() => {
        return {
            basePath: getBasePath(location.pathname, 'controlEmployeesWorkingMonth'),
            EmployeeId: parseInt(params.EmployeeId || '0'),
            month: parseInt(params?.month || '1'),
            year: parseInt(params?.year || '2024')
        }
    }, [params.month, params.year, params.EmployeeId, location.pathname])

    const getData = useCallback(async() => {
        const year = baseParamsParsed.year
        const month = baseParamsParsed.month
        const EmployeeId = baseParamsParsed.EmployeeId
        const date = new Date(year + '-' + month + '-1')
        if (showYears) {
            const result = await twinFetchPostJSON('/api/app/employee/employeeTimetable/getEmployeeJourneyByYear', { EmployeeId, year })
            if (result) {
                setData({
                    type: 'year',
                    data: result
                })
            }
        } else {
            const dateStart = new Date(date.getFullYear(), date.getMonth(), 1).toDateString()
            const dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0).toDateString()
            const result = await twinFetchPostJSON('/api/app/employee/employeeTimetable/getEmployeeJourneyByDates', { EmployeeId, dateStart, dateEnd })
            if (result) {
                const arrResult: EmployeeJourneyTyExtended[] = []
                for (const key in result) {
                    const journey = result[key]
                    arrResult.push({ ...journey, date: key })
                }
                const { totalMinsWorked, totalMinsToDo, balanceMinsToDo } = getTotalMonthData(arrResult || [])
                arrResult?.push({ type: 'totals', date: '', totalMinsWorked, totalMinsToDo, balance: balanceMinsToDo })
                setData({
                    type: 'month',
                    data: arrResult
                })
            }
        }
    }, [baseParamsParsed.month, baseParamsParsed.EmployeeId, baseParamsParsed.year, showYears, setData])

    const changeShowYear = useCallback(() => {
        setShowYears((old) => !old)
    }, [setShowYears])

    useEffect(() => {
        getData()
    }, [getData])

    return { baseParamsParsed, changeShowYear, showYears, setData, data }
}

interface RightHeaderProps extends RightHeaderLogicProps {
    changeShowYear: () => void
}

const RightHeader: React.FC<RightHeaderProps> = ({ showYears, changeShowYear, baseParamsParsed }) => {
    const { loading, handleDownloadPdf, openModal, setOpenModal, displayPDFMultiple } = useRightHeaderLogic({ showYears, baseParamsParsed })
    const textButton = showYears ? <TwinTrans transKey='displayByMonth'>Mostrar por mes</TwinTrans> : <TwinTrans transKey='displayByYear'>Mostrar por año</TwinTrans>
    return (
        <div>
            <div className='flex items-center'>
                <ButtonSecondary onClick={changeShowYear} className='mr-5' >{textButton}</ButtonSecondary>
                <ButtonPrimary className='mr-5 w-160' onClick={handleDownloadPdf}>
                    {loading ?
                        <LoadingSpinner className='h-24' /> :
                        <TwinTrans transKey='downloadPdf'>Descargar PDF</TwinTrans>
                    }
                    <a className='hidden downloadfile' download={true} href='/' target='_blank' onClick={(e) => e.stopPropagation()}>a</a>
                </ButtonPrimary>
                <ButtonPrimary onClick={displayPDFMultiple}>Descargar PDF múltiple</ButtonPrimary>
            </div>
            {openModal ? <ModalTCEmployeePdfMultiple setOpened={() => setOpenModal(null)} onSubmit={() => { }} allRowData={openModal.allRowData} /> : null}
        </div>
    )
}

interface RightHeaderLogicProps {
    showYears: boolean
    baseParamsParsed: BaseParamsParsed
}

const useRightHeaderLogic = ({ showYears, baseParamsParsed }: RightHeaderLogicProps) => {
    const { startLoading, endLoading, loading } = useIsLoading()
    const {openModal, setOpenModal} = useEditDeleteModal()
    const { dictMonths } = useDictMonths()
    const employees = getEmployees()

    const handleDownloadPdf = useCallback(async (e: React.MouseEvent) => {
        startLoading?.()
        const EmployeeId = baseParamsParsed.EmployeeId
        const { dateStart, dateEnd } = timeControlEmployeePDFParseDates(baseParamsParsed.month, baseParamsParsed.year, showYears)
        const current = e.currentTarget
       
        const result = await twinFetchPost('/api/app/employee/employeeTimetable/getEmployeeTimeControlPdf', { EmployeeIds: [String(EmployeeId)], dateStart, dateEnd })
        if (result) {
            const pdf = await result.blob()
            const fileURL = URL.createObjectURL(pdf)
            const a = current.querySelector('a')
            const EmployeeName = employees?.[EmployeeId].fullname_short.trim().split(' ').join('-')
            const fileName = (showYears ? baseParamsParsed.year : dictMonths[baseParamsParsed.month] + '-' + baseParamsParsed.year) + '-' + EmployeeName
            if (a) {
                a.href = fileURL
                a.download = fileName + '.pdf'
                a.click()
            }
            endLoading?.()
        }
        return result
    }, [startLoading, endLoading, baseParamsParsed, showYears, dictMonths, employees])

    const displayPDFMultiple = useCallback(() => {
        const { dateStart, dateEnd } = timeControlEmployeePDFParseDates(baseParamsParsed.month, baseParamsParsed.year, showYears)
        setOpenModal({ type: 'edit', allRowData: {dateStart, dateEnd} })
    }, [baseParamsParsed, setOpenModal, showYears])

    return { loading, handleDownloadPdf, openModal, setOpenModal, displayPDFMultiple }
}


type HeaderTimeControlEmployeeProps = TimeControlMonthSelectorProps & TimeControlEmployeeSelectorProps & {
    changeShowYear: () => void
    showYears: boolean
}

const HeaderTimeControlEmployee: React.FC<HeaderTimeControlEmployeeProps> = ({ employees, changeShowYear, showYears, ...rest }) => {
    return (
        <div className='flex items-center justify-between calendar_toolbar'>
            {showYears ?
                <TimeControlYearSelector {...rest} /> :
                <TimeControlMonthSelector {...rest} />}
            <TimeControlEmployeeSelector {...{ employees }} {...rest} />
        </div>
    )
}

interface TimeControlYearSelectorProps extends TimeControlYearSelectorLogicProps {
}

const TimeControlYearSelector: React.FC<TimeControlYearSelectorProps> = ({ year, ...rest }) => {
    const { linkNextYear, linkToPrevYear } = useTimeControlYearSelectorLogic({ year, ...rest })
    return (
        <div className='flex items-center relative'>
            <Link className='cursor-pointer mr-24 hover:text-green-21' to={linkToPrevYear}><TwinIcon className='h-24' icon={faChevronLeft} /></Link>
            <Link className='cursor-pointer mr-24 hover:text-green-21' to={linkNextYear}><TwinIcon className='h-24' icon={faChevronRight} /></Link>
            <div className='toolbar-label  text-left text-gray-51 capitalize'>{year}</div>
        </div>
    )
}

interface TimeControlYearSelectorLogicProps extends BaseParamsParsed {
}
const useTimeControlYearSelectorLogic = ({ EmployeeId, basePath, month, year }: TimeControlYearSelectorLogicProps) => {
    const nextYear = useCallback(() => {
        let parsedYear = year + 1
        return `${basePath}/${EmployeeId}/${month}/${parsedYear}`
    }, [EmployeeId, month, year, basePath])

    const prevYear = useCallback(() => {
        let parsedYear = year - 1
        return `${basePath}/${EmployeeId}/${month}/${parsedYear}`
    }, [EmployeeId, month, year, basePath])

    return { linkToPrevYear: prevYear(), linkNextYear: nextYear() }
}

interface TimeControlMonthSelectorProps extends TimeControlMonthSelectorLogicProps  {}

const TimeControlMonthSelector: React.FC<TimeControlMonthSelectorProps> = ({...rest}) => {
    const { ref, showModalCalendar, handleShowModalCalendar, month, year, onSelectMonth, linkNextMonth, linkPrevMonth } = useTimeControlMonthSelectorLogic({ ...rest })
    const { dictMonths } = useDictMonths()

    return (
        <div className='flex items-center relative'>
            <Link className='cursor-pointer mr-24 hover:text-green-21' to={linkPrevMonth}><TwinIcon className='h-24' icon={faChevronLeft} /></Link>
            <Link className='cursor-pointer mr-24 hover:text-green-21' to={linkNextMonth}><TwinIcon className='h-24' icon={faChevronRight} /></Link>
            <div className='flex items-center' ref={ref}>
                <div className='toolbar-label  text-left text-gray-51 capitalize cursor-pointer' onClick={handleShowModalCalendar}>
                    {dictMonths[month]} - {year}
                </div>
                <div className='cursor-pointer ml-24 hover:text-green-21' >
                    <TwinIcon className='h-24 mt-5' icon={showModalCalendar ? faChevronUp : faChevronDown} onClick={handleShowModalCalendar} />
                </div>
                {showModalCalendar ? <TwinCalendar defaultActiveStartDate={new Date(year+'-'+month+'-1')} defaultView='year' className='absolute time_selector_month_calendar' onChange={() => null} onClickMonth={onSelectMonth} /> : null}
            </div>
        </div>
    )
}

interface TimeControlMonthSelectorLogicProps extends BaseParamsParsed {
    
}
const useTimeControlMonthSelectorLogic = ({year, EmployeeId, month, basePath}: TimeControlMonthSelectorLogicProps) => {
    const [showModalCalendar, setShowModalCalendar] = useState<boolean>(false)
    const navigate = useNavigate()

    const ref = useRef<any>(null)

    useOnClickOutside(ref, () => setShowModalCalendar(false))


    const handleShowModalCalendar = useCallback(() => {
        setShowModalCalendar((old)=>!old)
    }, [setShowModalCalendar])

    const onSelectMonth = useCallback((date: Date) => {
        const newMonth = date.getMonth() +1
        const newYear = date.getFullYear()
        navigate(`${basePath}/${EmployeeId}/${newMonth}/${newYear}`)
        handleShowModalCalendar()
    }, [navigate, handleShowModalCalendar, EmployeeId, basePath])


    const nextMonth = useCallback(() => {
        let parsedMonth = month
        let parsedYear = year
        if (parsedMonth === 12) {
            parsedMonth = 1
            parsedYear++
        } else {
            parsedMonth++
        }
        return `${basePath}/${EmployeeId}/${parsedMonth}/${parsedYear}`
    }, [EmployeeId, month, year, basePath])

    const prevMonth = useCallback(() => {
        let parsedMonth = month
        let parsedYear = year

        if (parsedMonth === 1) {
            parsedMonth = 12
            parsedYear--
        } else {
            parsedMonth--
        }
        return `${basePath}/${EmployeeId}/${parsedMonth}/${parsedYear}`
    }, [EmployeeId, month, year, basePath])

    return { ref,  showModalCalendar, handleShowModalCalendar, month, year, onSelectMonth, linkNextMonth: nextMonth(), linkPrevMonth: prevMonth() }
}

interface TimeControlEmployeeSelectorProps extends TimeControlEmployeeSelectorLogicProps {}

const TimeControlEmployeeSelector: React.FC<TimeControlEmployeeSelectorProps> = ({ employees, EmployeeId, ...rest}) => {
    const { linkNextEmployee, linkPrevEmployee, onSelectEmployee } = useTimeControlEmployeeSelectorLogic({ employees, EmployeeId, ...rest })
    return (
        <div className='flex items-center'>
            <CustomSelectWithSearchBar className='time_control_employee_selector mr-24' label='' items={employees} fieldName={'fullname_short'} onChange={onSelectEmployee} icon={faSearch} />
            <div className='toolbar-label  text-left text-gray-51 capitalize'>
                {employees?.[EmployeeId]?.fullname_short}
            </div>
            <Link className='cursor-pointer ml-24 hover:text-green-21' to={linkPrevEmployee}><TwinIcon className='h-24 p-3 ' icon={faChevronLeft} /></Link>
            <Link className='cursor-pointer ml-24 hover:text-green-21' to={linkNextEmployee}><TwinIcon className='h-24 p-3' icon={faChevronRight} /></Link>
        </div>
    )
}
interface TimeControlEmployeeSelectorLogicProps extends BaseParamsParsed {
    employees: DictionaryEmployee
}

const useTimeControlEmployeeSelectorLogic = ({employees, EmployeeId, basePath, month, year}: TimeControlEmployeeSelectorLogicProps) => {
    const navigate = useNavigate()
    const lengthEmployees = Object.keys(employees).length
    const arrIds = Object.keys(employees)
    const posId = arrIds.indexOf(String(EmployeeId))
    const nextEmployee = useCallback(() => {
        let parsed = EmployeeId
        if (posId === lengthEmployees - 1) {
            parsed = parseInt(arrIds[0])
        } else {
            parsed = parseInt(arrIds[posId + 1])
        }
        return `${basePath}/${parsed}/${month}/${year}`
    }, [arrIds, posId, lengthEmployees, EmployeeId, month, year, basePath])

    const prevEmployee = useCallback(() => {
        let parsed = EmployeeId
        if (posId === 0) {
            parsed = parseInt(arrIds[lengthEmployees - 1])
        } else {
            parsed = parseInt(arrIds[posId - 1])
        }
        return `${basePath}/${parsed}/${month}/${year}`
    }, [arrIds, posId, lengthEmployees, EmployeeId, month, year, basePath])

    const onSelectEmployee = useCallback((id: number) => {
        navigate(`${basePath}/${id}/${month}/${year}`)
    }, [month, year, navigate, basePath])

    return { EmployeeId, linkNextEmployee: nextEmployee(), linkPrevEmployee: prevEmployee(), onSelectEmployee }
}


const timeControlEmployeeMonthDispatch = {
    setEmployees: (payload: EmployeesPayload) =>
        ({ type: 'CHANGE_EMPLOYEE', payload })
}

export type ReduxTimeControlEmployeeMonthDispatch = ConnectedProps<typeof timeControlEmployeeMonthConnect>
const mapTimeControlEmployeeMonthConnector = (state: AllReduxPayloads) => ({ employees: state.employees })
const timeControlEmployeeMonthConnect = connect(mapTimeControlEmployeeMonthConnector, timeControlEmployeeMonthDispatch)
const timeControlEmployeeMonthConnectLoading = withLoading(TimeControlEmployeeMonth, [{ fetchUrl: '/api/app/employee/getAllEmployees', propName: 'employees', setFunctionName: 'setEmployees' }])

const TimeControlEmployeeMonthConnect = timeControlEmployeeMonthConnect(timeControlEmployeeMonthConnectLoading)


export default TimeControlEmployeeMonthConnect