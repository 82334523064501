import { useEffect, useState } from 'react'
import { ButtonOrLoader } from '../../../../../../baseComponents/Button'
import { ModalBig } from '../../../../../../baseComponents/Modal'
import TwinForm from '../../../../../../forms/TwinForm'
import FormRenderer from '../../../../../../forms/FormRenderer'
import { getDictionaryStates } from '../../../../../../utils/globals/countries'
import { twinFetchPostJSON } from '../../../../../../utils/globals/data'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import { CompanyPayload } from '../../../../../../utils/reducers/globals/company'
import { FormRender } from '../../../../../../forms/FormRenderer/types'
import {CompanyModelType} from '@teinor/erp/types/environment/company'
import { ModalOpenedSetTy, ModalOpenedTy } from '../../../../../../baseComponents/Modal/types'
import useIsLoading from '../../../../../../utils/hooks/useIsLoading'
import { returnClassOfSpanTemplate } from '../../../../../../utils/globals/tailwind'
import { getDictionaryCountries } from '../../../../../../utils/reducers/getters'


interface EditCompanyModalProps extends EditCompanyModalLogicProps {
}

const EditCompanyModal: React.FC<EditCompanyModalProps> = ({ id, openEditModal, setOpenEditModal, company, ...rest }) => {
    const { handleSaveEditModal, country, setCountry } = useEditCompanyModalLogic({ id, setOpenEditModal, openEditModal, company, ...rest })
    const { t } = useTwinTranslation()
    const { startLoading, endLoading, loading } = useIsLoading()

    const fields: FormRender<CompanyModelType> = [
        {
            cols: 2,
            elements: [
                {
                    name: 'image',
                    component: 'ImageInput',
                    defaultValue: company?.image,
                    className: returnClassOfSpanTemplate(2)
                },
                {
                    name: 'id_number',
                    label: t('id_number', 'Nº Identificación'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    defaultValue: company?.id_number
                },
                {
                    name: 'name',
                    label: t('name', 'name'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    defaultValue: company?.name,
                    required: true
                },
                {
                    name: 'fiscal_name',
                    label: t('fiscalName', 'fiscal_name'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    defaultValue: company?.fiscal_name,
                    required: true
                },
            ]
        },
        {
            cols: 2,
            title: t('address', 'Dirección'),
            elements: [
                {
                    name: 'address',
                    label: t('address', 'Dirección'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    defaultValue: company?.address,
                    required: true
                },
                {
                    name: 'additional_address',
                    label: t('additional_address', 'Dirección adicional'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    defaultValue: company?.additional_address
                },
                {
                    name: 'postal_code',
                    label: t('postal_code', 'Código Postal'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    defaultValue: company?.postal_code,
                    required: true
                },
                {
                    name: 'city',
                    label: t('city', 'Ciudad'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    defaultValue: company?.city,
                    required: true
                },
                {
                    name: 'country',
                    label: t('country', 'País'),
                    component: 'CustomSelectWithSearchBar',
                    items: getDictionaryCountries(),
                    required: true,
                    value: country,
                    onChange: (value: string) => setCountry(value)
                },
                {
                    name: 'state',
                    label: t('state', 'Provincia'),
                    component: 'CustomSelectWithSearchBar',
                    sortItems: true,
                    items: getDictionaryStates(country),
                    value: company?.state,
                    required: true
                }
            ]
        },
        {
            cols: 2,
            title: t('contactInformation', 'Datos de contacto'),
            elements: [
                {
                    name: 'phone',
                    label: t('phone', 'Teléfono'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    defaultValue: company?.phone
                },
                {
                    name: 'mobile',
                    label: t('mobile', 'Móvil'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    defaultValue: company?.mobile
                },
                {
                    name: 'email',
                    label: t('email', 'Email'),
                    component: 'InputWithLabelMargin',
                    type: 'email',
                    defaultValue: company?.email,
                    required: true
                },
                {
                    name: 'billing_email',
                    label: t('billingEmail', 'Email de facturación'),
                    component: 'InputWithLabelMargin',
                    type: 'email',
                    defaultValue: company?.billing_email
                },
                {
                    name: 'id',
                    component: 'InputHidden',
                    value: company?.id
                }
            ]
        }
    ]
    return (
        <ModalBig setOpened={setOpenEditModal} opened={openEditModal} className='overflow-y-auto'>
            <TwinForm action='/api/company/updateCompany' onSubmit={() => { endLoading(); handleSaveEditModal() }} beforeSubmitHandler={startLoading} imagepath={['info']} companyId={id}>
                <FormRenderer sections={fields} />
                <ButtonOrLoader buttonIsDisabled={loading} className='mt-68 ml-auto' textButton={t('save', 'Guardar')} />
            </TwinForm>
        </ModalBig>
    )
}

interface EditCompanyModalLogicProps {
    id: string
    openEditModal: ModalOpenedTy
    setOpenEditModal: ModalOpenedSetTy
    company: CompanyPayload
    setCompany: (payload: CompanyPayload) => {
        type: string
        payload: CompanyPayload
    }
}
const useEditCompanyModalLogic = ({ setOpenEditModal, id,  setCompany, company, openEditModal }: EditCompanyModalLogicProps) => {
    const [country, setCountry] = useState(company?.country || '')

    const handleSaveEditModal = async () => {
            const mycompany = await twinFetchPostJSON('/api/company/getCompany', {id, include: true })
            await setCompany(mycompany)
            setOpenEditModal(null)
    }
    
    useEffect(() => {
        if (openEditModal) {
            setCountry(company?.country || '')
        }
    }, [openEditModal, company?.country])
    
    return { handleSaveEditModal, country, setCountry }
}

export default EditCompanyModal
