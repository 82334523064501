import { useCallback, useState } from 'react'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import { MyLink } from '../Login/Components'
import { RegisterCheckWithText, RegisterHeader } from '../Register'
import ForgotPasswordForm from './ForgotPasswordForm'

interface ForgotPasswordProps {}

const ForgotPassword: React.FC<ForgotPasswordProps> = () => {
    const { showMessage, handleChangeShowMessage } = useForgotPasswordLogic()
    const Component = ForgotPassDecider({ showMessage })
    return (
        <div>
            <RegisterHeader />
            < Component handleChangeShowMessage={handleChangeShowMessage} />                      
        </div>
    )
}

const useForgotPasswordLogic = () => {
    const [showMessage, setShowMessage] = useState<true | null>(null)
    const handleChangeShowMessage = useCallback((active: true | null) => {
        setShowMessage(active)
    }, [setShowMessage])

    return { showMessage, handleChangeShowMessage }
}


type ForgotPasswordDeciderProps = {
    showMessage: boolean | null
}

const ForgotPassDecider = ({ showMessage }: ForgotPasswordDeciderProps) => {
    if (showMessage) {
        return MessageStartRecoverPassword
    }
    return ForgotPasswordForm
}

interface MessageStartRecoverPasswordProps { }

const MessageStartRecoverPassword: React.FC<MessageStartRecoverPasswordProps> = () => {
    const { t } = useTwinTranslation()
    return (
        <div className='flex flex-col items-center justify-between'>
            <RegisterCheckWithText title={t('emailToChangePassword', 'Si el mail es correcto, hemos enviado un mail para modificar tu contraseña')} subtitle={t('preparedToChangePass', 'Revisa tu correo electrónico, haz click en el link para generar tu nueva contraseña.')} />
            <MyLink className='my-10 text-center' text={t('returnToLogin', 'Ir a la página de Inicio sesión')} link={'/'} />
        </div>
    )
}

export default ForgotPassword