import ModalTaskAddTime from './ModalTaskAddTime'
import ModalTaskListInvertedTime from './ModalTaskListInvertedTime'
import { ModalSmallMediumOutside } from '../../../../baseComponents/Modal'
import { ModalTaskInvertedTimeBaseProps } from './types'
import { UseEditDeleteModalProps } from '../../../../utils/hooks/useEditDeleteModal'

interface ModalTaskInvertedTimeProps extends ModalTaskInvertedTimeBaseProps   {
    openModalInvertedTime: UseEditDeleteModalProps
}

const ModalTaskInvertedTime: React.FC<ModalTaskInvertedTimeProps> = ({openModalInvertedTime, setOpenModalInvertedTime, data}) => {
    return (
        <ModalSmallMediumOutside opened={openModalInvertedTime? true: null} className='flex flex-col' setOpened={()=>setOpenModalInvertedTime(null)}>
            {openModalInvertedTime?.type ==='create' ? 
                <ModalTaskAddTime  {...{ openModalInvertedTime, setOpenModalInvertedTime, data}} /> 
                : null
            }
            {openModalInvertedTime?.type === 'edit' ?        
                <ModalTaskListInvertedTime {...{ openModalInvertedTime, setOpenModalInvertedTime, data }} />
                : null
            }
        </ModalSmallMediumOutside>
    )
}


export default ModalTaskInvertedTime