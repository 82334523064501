import { WCalParentModelType } from '@teinor/erp/types/company/WCalParent'
import { useLocation } from 'react-router'
import { ComponentWithPermissions } from '../../../../../../baseComponents/CreateRoutering/types'
import { permissionCheck } from '../../../../../../baseComponents/PermissionChecker/function'
import { TwinTableLink } from '../../../../../../baseComponents/TwinTable'
import BaseScreenTable from '../../../../../../baseComponents/TwinTable/BaseScreenTable'
import { TableComponentProps } from '../../../../../../baseComponents/TwinTable/BaseScreenTable/types'
import { CustomRenderDisplayHoursByMins, EditRow } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import ModalCreateWorkingCalendar from './ModalCreateWorkingCalendar'

interface WorkingCalendarsProps extends ComponentWithPermissions { }

const WorkingCalendars: React.FC<WorkingCalendarsProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()

    const pageData = {
        title: t('WorkingCalendars', 'Calendarios laborales'),
        newText: t('newWorkingCalendar', 'Nuevo Calendario laboral'),
    }

    return (
        <BaseScreenTable TableComponent={TableWorkingCalendars} CreateModalComponent={ModalCreateWorkingCalendar} pageData={pageData} userPermissions={userPermissions} />
    )
}

type TableWorkingCalendarsProps = TableComponentProps & {}


interface WCalParentModelTypeExt extends WCalParentModelType { 
    dayMins: number
    marginStartMin: number
    marginEndMin: number
 }
const TableWorkingCalendars: React.FC<TableWorkingCalendarsProps> = ({ setTableInstance, setOpened, userPermissions}) => {
    const { t } = useTwinTranslation()
    const location = useLocation().pathname
    const columns: ColumnTableSchema<WCalParentModelTypeExt> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('id', 'ID'),
            sortable: true,
            width: 70
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            sortable: true,
        },
        {
            id: 'dayMins',
            dataKey: 'dayMins',
            label: t('averageHoursDay', 'Media de horas al día'),
            customRender: (_parameterValue, allRowData) => <CustomRenderDisplayHoursByMins value={allRowData?.WCals?.[0].dayMins} />,
            sortable: true,
        },
        {
            id: 'marginStartMin',
            dataKey: 'marginStartMin',
            customRender: (_parameterValue, allRowData) => <CustomRenderDisplayHoursByMins value={allRowData?.WCals?.[0].marginStartMin} />,
            label: t('marginStartMin', 'Margen mínimo para empezar'),
        },
        {
            id: 'marginEndMin',
            dataKey: 'marginEndMin',
            customRender: (_parameterValue, allRowData) => <CustomRenderDisplayHoursByMins value={allRowData?.WCals?.[0].marginEndMin} />,
            label: t('marginEndMin', 'Margen mínimo para acabar'),
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            customRender: (parameterValue, allRowData) => <EditRow key={'edit' + parameterValue} {...{ parameterValue, allRowData }} />,
            havePermission: permissionCheck(userPermissions, 'update')
        }
    ]
    return (
        <TwinTableLink createRowDataLink={[location, ['$WCalId', 'main']]} name='WorkingCalendars' columns={columns} getDataFrom='/api/app/workingCalendar/getAllWorkingCalendarParents' onRowClick={(_id, rowData) => setOpened({
            type: 'edit',
            allRowData: rowData
        })} setTableInstance={setTableInstance} />
    )
}


export default WorkingCalendars