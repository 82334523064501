import { joinAndRemoveDuplicatedBars } from '../../baseComponents/CreateRoutering/functions'
export type CreateLinkProps = [baseLink: string, propsLink: string[]]
export type CreateLinkType = (props: CreateLinkProps, rowData: any) => string
export type CreateLinkFunctionType = (rowData: any) => string
export const createLink: CreateLinkType = ( [baseLink, propsLink], rowData) => {
    let parsedPropsLink =''
    if (propsLink.length) {
        for (const elem of propsLink) {
            let itemLink = elem
            if (elem.startsWith('$')) {
                itemLink = rowData[elem.substring(1, elem.length)]
            }
            parsedPropsLink += ('/' + itemLink)
        }
    }
    const link = baseLink + parsedPropsLink
    return (link)
}

export const getBasePath = (pathname: string, lastMatch: string) => {
    const pathname_splited = pathname.split('/')
    let last_found = 0
    for (let i = pathname_splited.length-1; i > -1; i--){
        if (pathname_splited[i] === lastMatch) {
            last_found = i+1 // for "" of start
            break
        }
    }
    let pathname_joined = '/'
    for (let i = 0; i < last_found; i++){
        pathname_joined = joinAndRemoveDuplicatedBars(pathname_joined, '/'+pathname_splited[i])
    }
    return pathname_joined
}

export const getPathWithoutLastSlash = (path: string) => {
    const pathname_splited = path.split('/')
    pathname_splited.pop()
    let pathname_joined = '/'
    if (pathname_splited.length) {
        for (let i = 0; i < pathname_splited?.length; i++) {
            pathname_joined = joinAndRemoveDuplicatedBars(pathname_joined, '/' + pathname_splited[i])
        }
        return pathname_joined
    }
    return false
}