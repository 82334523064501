import twinFetchPost, { TwinFetchPostExtraProps, uploadFileToBack } from '../../utils/globals/data'
import { borderRedErrorClass, inputErrorClass } from '../../utils/globals/tailwind'
import { changeErrorMessage } from '../../utils/reducers/reduxDispatch'
import { FormElementValues, FormInput, OnSubmit, ProcessFileProps, ProcessFormElementProps, ProcessInputResult } from './types'

export const getValuesAndErrorsFromForm = async (formElements: HTMLFormControlsCollection, processFormElementProps: ProcessFormElementProps) => {
    const values: FormElementValues = {}
    let haveErrorsInProcess = false

    // We have to do like this because formElements have functions and with "for in" we got them, and like this we just get the elements.
    for (let formElementKey = 0; formElementKey < formElements.length; formElementKey++) {
        const formInput = formElements[formElementKey] as FormInput
        const result = await processSingleFormInput(formInput, processFormElementProps)
        saveElementValueIfPossible(values, result, formInput.name)
        if (result?.errors) {
            haveErrorsInProcess = true
        }
    }

    return { values, errors: haveErrorsInProcess }
}

export const processSingleFormInput = async (formInput: FormInput, processFormElementProps: ProcessFormElementProps): Promise<ProcessInputResult> => {
    if (formInput.name) {
        if (formInput.type === 'file') {
            return await processFileInput(formInput, processFormElementProps)
        } else if (formInput.type === 'checkbox') {
            return await processCheckboxInput(formInput)
        } else {
            return await processDefaultInput(formInput)
        }
    }
}

const processFileInput = async (formInput: FormInput, processFileProps: ProcessFileProps) => {
    if (formInput?.files?.[0] && processFileProps.imagepath) {
        const file = formInput.files[0]
        const moveTo = processFileProps.imagepath
        const pathInBack = await uploadFileToBack(file, file.name, moveTo, processFileProps.companyId)
        if (pathInBack) {
            return { value: pathInBack }
        }
    }
}

const processCheckboxInput = (formInput: FormInput) => {
    const errors = !formInput.checkValidity()
    borderRedByValidity(errors, formInput)
    return { value: formInput.checked, errors }
}

const borderRedByValidity = (haveErrors: boolean, formInput: FormInput) => {
    const classListElement = formInput.classList
    if (haveErrors) {
        classListElement.add(borderRedErrorClass)
    } else {
        classListElement.remove(borderRedErrorClass)
    }
}

const processDefaultInput = (formInput: FormInput) => {
    const errors = !formInput.checkValidity()
    inputErrorClassByValidity(errors, formInput)
    return { value: formInput.value, errors }
}

const inputErrorClassByValidity = (haveErrors: boolean, formInput: FormInput) => {
    const classListElement = formInput.classList
    if (haveErrors) {
        classListElement.add(inputErrorClass)
    } else {
        classListElement.remove(inputErrorClass)
    }
}

const saveElementValueIfPossible = (values: FormElementValues, processElement?: ProcessInputResult, name?: string) => {
    if (processElement) {
        const { value } = processElement
        const canBeSaved = value !== undefined && name
        if (canBeSaved) {
            values[name] = value
        }
    }
}

export const sendFormByFetch = async (values: FormElementValues, action: string, onSubmit?: OnSubmit, onError?: OnSubmit, extraProps?: TwinFetchPostExtraProps) => {
    const response = await twinFetchPost(action, values, extraProps)
    if (response.status !== 200) {
        changeErrorMessage(true)
        onError?.(response, values)
    } else {
        onSubmit?.(response, values)
    }
}

export const sendFormByFetchWithoutErrors = async (values: FormElementValues, action: string, onSubmit?: OnSubmit, extraProps?: TwinFetchPostExtraProps) => {
    const response = await twinFetchPost(action, values, extraProps)
    onSubmit?.(response, values)
}