import { UserPermissions } from '../../baseComponents/CreateRoutering/types';
import { FormRendererSingleElements } from '../../forms/FormRenderer';
import { FormElement, FormRender} from '../../forms/FormRenderer/types';

export const valueOrDefaultValue = (fields: FormRender<any>, userPermissions: UserPermissions, values: any) => {
    for (const formSection of fields) {
        for (const element of formSection.elements) {
            const key = getKeyValueForElement(element.component, element as any)
            //@ts-ignore
            element[key] = element[key] ?? values[element.name]
            if (userPermissions.n <= 2) {
                element.readOnly = true
            }
            
        }        
    }
    return fields
}
export const valueOrDefaultValueReadOnly = (fields: FormRender<any>, userPermissions: UserPermissions, values: any) => {
    for (const formSection of fields) {
        for (const element of formSection.elements) {
            const key = getKeyValueForElement(element.component, element as any)
            //@ts-ignore
            const value = element[key] ?? values[element.name]
            //@ts-ignore
            element[key] = value
            if ((value || userPermissions.n <= 2) && element.readOnly === undefined) {
                element.readOnly = true
            }
            
        }        
    }
    return fields
}

export const getKeyValueForElement = <T extends keyof FormRendererSingleElements>(component: T, params: FormElement<T>) => {
    if (component === 'Checkbox' || component === 'CheckboxMargin') {
        return 'defaultChecked'
    } else if (String(component).includes('CustomSelect') || String(component).includes('InputCalendar') || String(component).includes('TextField') || String(component).includes('DisplayPriceField') || 'value' in params) {
        return 'value'
    } else {
        return 'defaultValue'
    }
}

export const getValueForElement = <T extends keyof FormRendererSingleElements>(component: T, params: FormElement<T>): any => {
    const key = getKeyValueForElement(component, params) as keyof FormElement<T>
    return params[key]
}