import { useLocation, useParams } from 'react-router'
import { ComponentWithPermissions } from '../../../../../../../baseComponents/CreateRoutering/types'
import EditCardTabs from '../../../../../../../baseComponents/EditCardTabs'
import { getBasePath } from '../../../../../../../utils/globals/link'
import { useBasicData } from '../../../../../../../utils/hooks/useBasicData'
import { ComponentTabObject } from '../../../../../../../utils/hooks/useTabLogic'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import MainWorkingCenterConnect from './Tabs/MainWorkingCenter'
import WorkingCenterEmployees from './Tabs/WorkingCenterEmployees'
import WorkingCenterTasks from './Tabs/WorkingCenterTasks'



type SingleWorkingCenterProps = ComponentWithPermissions & {}

const SingleWorkingCenter: React.FC<SingleWorkingCenterProps> = ({ userPermissions }) => {
    const location = useLocation()
    const params = useParams<{ id: string, tab?: string }>()
    const { t } = useTwinTranslation()
    const { basicData, getData } = useBasicData({ params: {id: params.id, includeRelations: true}, url: '/api/app/workingCenter/getWorkingCenterInstance', variableToCurrentPage: 'name' })
    if (!params.id) {
        return null
    }
    const tabs: ComponentTabObject = {
        'main': { component: MainWorkingCenterConnect, text: t('general', 'General'), permission: 'workingCenter.main',  },
        'employees': { component: WorkingCenterEmployees, text: t('employees', 'Empleados'), permission: 'workingCenter.employee', },
        'tasks': { component: WorkingCenterTasks, text: t('tasks', 'Tareas'), permission: 'workingCenter.employee', modules: ['tasks']},
    }

    return (<EditCardTabs basePath={getBasePath(location.pathname, params.id)} basicData={basicData} tabs={tabs} tab={params.tab} userPermissions={userPermissions} getData={getData} />)
}

export default SingleWorkingCenter