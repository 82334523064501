export const twinCalendarMonthYearFormat = (locale: string, date: Date) => {
    const month = new Intl.DateTimeFormat(locale, { month: 'long' }).format(date)
    const year  = new Intl.DateTimeFormat(locale, { year: 'numeric' }).format(date)
    return month + ' ' + year
}
export const twinCalendarMonthFormat = (locale: string, date: Date) => {
    return  new Intl.DateTimeFormat(locale, { month: 'long' }).format(date)
}
export const twinCalendarDayAndWeekDayFormat = (locale: string, date: Date) => {
    const day = new Intl.DateTimeFormat(locale, {day: 'numeric'}).format(date)
    const weekDay = new Intl.DateTimeFormat(locale, {weekday: 'long'} ).format(date)
    return {day, weekDay}
}
export const twinCalendarShortWeekDayFormat = (locale: string, date: Date) => {
    return new Intl.DateTimeFormat(locale, {weekday: 'short'} ).format(date)
}