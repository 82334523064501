import { EmployeeAbsenceModelType } from '@teinor/erp/types/company/userInner/employee/employeeAbsence'
import { useCallback, useState } from 'react'
import { flushSync } from 'react-dom'
import { ModalOpenedSetTy } from '../../../../../../../baseComponents/Modal/types'
import { ModalCreateEditMedium } from '../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../../../../../baseComponents/ModalsLayouts/types'
import TwinCalendarStartEnd from '../../../../../../../baseComponents/TwinCalendar/TwinCalendarStartEnd'
import { FormRender } from '../../../../../../../forms/FormRenderer/types'
import { OnSubmit } from '../../../../../../../forms/TwinForm/types'
import ScheduleAbsence from '../../../../../../../specificComponents/WCal/schedule/ScheduleAbsence'
import { AbsenceScheduleErrorsTy } from '../../../../../../../specificComponents/WCal/schedule/ScheduleAbsence/types'
import twinFetchPost from '../../../../../../../utils/globals/data'
import { TwinDictionary } from '../../../../../../../utils/globals/dictionary'
import { checkSchedulesAllOk } from '../../../../../../../utils/globals/schedule'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import { getEmployeeAbsenceTypes, getEmployees } from '../../../../../../../utils/reducers/getters'
import { changeErrorMessage } from '../../../../../../../utils/reducers/reduxDispatch'
import { returnClassOfSpanTemplate } from '../../../../../../../utils/globals/tailwind'


type ModalCreateEmployeeAbsenceProps = ModalEditComponentProps & ModalCreateEmployeeAbsenceLogicProps & {}

const ModalCreateEmployeeAbsence: React.FC<ModalCreateEmployeeAbsenceProps> = ({  setOpened, onSubmit }) => {
    const { t } = useTwinTranslation()
    const employeeAbsenceTypes = getEmployeeAbsenceTypes()
    const employees = getEmployees()

    const { handleOnChange, dateSelected, checkAllOk, schedule, setSchedule } = useModalCreateEmployeeAbsenceLogic({ setOpened, onSubmit })
    const fields: FormRender<EmployeeAbsenceModelType> = [
        {
            cols: 2,
            elements: [
                {
                    name: 'EmployeeId',
                    component: 'CustomSelect',
                    label: t('employee', 'Empleado'),
                    items: employees || {},
                    required: true,
                    fieldName: 'fullname_short'
                },
                {
                    name: 'EmployeeAbsenceTypeId',
                    component: 'CustomSelect',
                    label: t('absenceType', 'Tipo de ausencia'),
                    items: employeeAbsenceTypes || {},
                    required: true
                },
                {
                    name: 'description',
                    component: 'TextArea',
                    label: t('description', 'Descripción'),
                    className: returnClassOfSpanTemplate(2)
                },
                {
                    name: 'proof_file_url',
                    component: 'FilesInput',
                    label: t('uploadProof', 'Subir justificante'),
                },
            ]
        }
    ]
    return (
        <ModalCreateEditMedium url='' fields={fields} setOpened={setOpened} onSubmit={() => { }} beforeSubmit={(vals) => { checkAllOk(vals); return false }} translations={{
            title: t('createEmployeeAbsence', 'Crear ausencia'),
            button: t('create', 'Crear')
        }} haveButtonPermissions={true} imagePath={['tmp']} className='notFlexAutoFormRender'>
            <TwinCalendarStartEnd start={dateSelected.start} end={dateSelected.end} onChange={handleOnChange} />
            <ScheduleAbsence {...{ schedule, setSchedule }} />
        </ModalCreateEditMedium>
    )
}


interface ModalCreateEmployeeAbsenceLogicProps {
    setOpened: ModalOpenedSetTy
    onSubmit: OnSubmit
}
const useModalCreateEmployeeAbsenceLogic = ({ setOpened, onSubmit }: ModalCreateEmployeeAbsenceLogicProps) => {
    const currentDate = new Date()
    const [schedule, setSchedule] = useState<AbsenceScheduleErrorsTy>({ workingTime: [], errors: [] })
    const [dateSelected, setDateSelected] = useState({ start: currentDate, end: currentDate })
    const { t } = useTwinTranslation()

    const errorHours = t('conflictInHoursSelectedCheckRedFields', 'Hay un conflicto en las horas seleccionadas. Revisa los campos en rojo.')
    const messages = JSON.stringify({
        'The day picked must be a working day': t('mustBeWorkingDay', 'La fecha seleccionada debe ser un día laborable'),
        'The day picked is not available': t('datePickedNotAvailable', 'La fecha seleccionada no está disponible'),
    })

    const handleOnChange = useCallback((value: Date, type: 'start' | 'end') => {
        setDateSelected((old) => {
            return { ...old, [type]: value }
        })
    }, [setDateSelected])


    const checkAllOk = useCallback(async (values: TwinDictionary) => {
        await flushSync(async () => {
            let hasError = false
            values['dateStart'] = dateSelected.start.toDateString()
            values['dateEnd'] = dateSelected.end.toDateString()
            let checkedSchedule: AbsenceScheduleErrorsTy | false = false
            await setSchedule((old) => {
                const copyOld = JSON.parse(JSON.stringify(old))
                const myDaySchedule = copyOld
                myDaySchedule.errors = []
                const response = checkSchedulesAllOk(myDaySchedule)
                if (response.hasError) {
                    hasError = true
                }
                if (hasError) {
                    changeErrorMessage(errorHours)
                }
                if (!hasError) {
                    checkedSchedule = { ...copyOld }
                }
                return { ...copyOld }
            })
            if (hasError) {
                changeErrorMessage(errorHours)
                return false
            }
            if (checkedSchedule) {
                if (schedule.workingTime && schedule.workingTime.length) {
                    values['schedule'] = JSON.stringify(schedule?.workingTime)
                }
                const response = await twinFetchPost('/api/app/workingCalendar/employeeAbsence/createEmployeeAbsence', values)
                if (response.status === 200) {
                    setOpened(null)
                    onSubmit?.(response, values)
                } else {
                    const messagesParsed = JSON.parse(messages)
                    const responseJSON = await response.json()
                    if (responseJSON.errors[0].msg && messagesParsed[responseJSON.errors[0].msg]) {
                        changeErrorMessage(messagesParsed[responseJSON.errors[0].msg])
                    }
                }
            }
        })
        return false
    }, [errorHours, setSchedule, setOpened, dateSelected.start, dateSelected.end, onSubmit, schedule.workingTime, messages])

    return { handleOnChange, dateSelected, checkAllOk, schedule, setSchedule }
}


export default ModalCreateEmployeeAbsence