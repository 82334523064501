import { useCallback } from 'react'
import TableSchedule, { ScheduleTimeTy } from '../TableSchedule'
import { AbsenceScheduleErrorsTy } from './types'
import TwinTrans from '../../../../baseComponents/TwinTrans'

export interface ScheduleAbsenceProps extends ScheduleAbsenceLogicProps {
    schedule: AbsenceScheduleErrorsTy
}

const ScheduleAbsence: React.FC<ScheduleAbsenceProps> = ({ schedule, setSchedule }) => {
    const { onChange } = useScheduleAbsenceLogic({ setSchedule })
    return (
        <div>
            <div className='regular20 my-20'>
                <TwinTrans transKey='scheduleAbsence'>Horario ausencia</TwinTrans>
            </div>
            <TableSchedule scheduleTime={schedule.workingTime} errors={schedule.errors} onChange={onChange} />
        </div>
    )
}

interface ScheduleAbsenceLogicProps {
    setSchedule: React.Dispatch<React.SetStateAction<AbsenceScheduleErrorsTy>>

}

const useScheduleAbsenceLogic = ({ setSchedule }: ScheduleAbsenceLogicProps) => {
    const onChange = useCallback((scheduleTime: ScheduleTimeTy) => {
        setSchedule((old) => {
            const copy = JSON.parse(JSON.stringify(old))
            return { ...copy, workingTime: scheduleTime }
        })
    }, [setSchedule])
    return { onChange }
}

export default ScheduleAbsence