import ModalCEEmployeeAbsenceType from './MocalCEEmployeeAbsenceType'
import { ComponentWithPermissions } from '../../../../../../baseComponents/CreateRoutering/types'
import BaseScreenTable from '../../../../../../baseComponents/TwinTable/BaseScreenTable'
import { TableComponentProps } from '../../../../../../baseComponents/TwinTable/BaseScreenTable/types'
import { CustomRenderBoolean, EditRowNotLink } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import TwinTable from '../../../../../../baseComponents/TwinTable'
import { EmployeeAbsenceTypeModelType } from '@teinor/erp/types/company/userInner/employee/employeeAbsence/employeeAbsenceType'

interface EmployeeAbsenceTypesProps extends ComponentWithPermissions { }

const EmployeeAbsenceTypes: React.FC<EmployeeAbsenceTypesProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()

    const pageData = {
        title: t('employeeAbsenceTypes', 'Tipos de ausencias'),
        newText: t('newEmployeeAbsenceType', 'Nuevo tipo de ausencia'),
    }

    return (
        <BaseScreenTable TableComponent={TableEmployeeAbsenceTypes} CreateModalComponent={ModalCEEmployeeAbsenceType} EditModalComponent={ModalCEEmployeeAbsenceType} pageData={pageData} userPermissions={userPermissions} />
    )
}

type TableEmployeeAbsenceTypesProps = TableComponentProps & {}

const TableEmployeeAbsenceTypes: React.FC<TableEmployeeAbsenceTypesProps> = ({ setTableInstance, setOpened }) => {
    const { t } = useTwinTranslation()
    const columns: ColumnTableSchema<EmployeeAbsenceTypeModelType> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('id', 'ID'),
            sortable: true,
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            }
        },
        {
            id: 'justified',
            dataKey: 'justified',
            label: t('justifiedAbsence', 'Ausencia justificada'),
            customRender: (parameterValue) => <CustomRenderBoolean value={String(parameterValue) === 'true'} width={45} />,
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            customRender: (parameterValue, allRowData) => <EditRowNotLink key={'edit' + parameterValue} {...{ parameterValue, allRowData }} onClick={(_idk, allRowData) => setOpened({ type: 'edit', allRowData })} />
        },
    ]
    return (
        <TwinTable name='EmployeeAbsenceTypes' columns={columns} getDataFrom='/api/app/workingCalendar/employeeAbsence/getAllEmployeeAbsenceTypes' onRowClick={(_id, rowData) => setOpened({
            type: 'edit',
            allRowData: rowData
        })} setTableInstance={setTableInstance} />
    )
}

export default EmployeeAbsenceTypes