import { CustomerProjectTypeModelType } from '@teinor/erp/types/company/customer/customerProjectType'
import { CustomerProjectStatusModelType } from '@teinor/erp/types/company/customer/customerProjectStatus'

export interface CustomerProjectTypesAction {
    type: 'CHANGE_CUSTOMER_PROJECT_TYPES'
    payload: CustomerProjectTypeModelType[]
}

interface CustomerProjectTypeModelTypeExtended extends CustomerProjectTypeModelType {
    customerProjectStatusDict: DictionaryCustomerProjectStatus
}

type DictionaryCustomerProjectStatus = { [id: number]: CustomerProjectStatusModelType }
export type DictionaryCustomerProject = { [id: number]: CustomerProjectTypeModelTypeExtended }
export type CustomerProjectTypePayload = null | DictionaryCustomerProject

export default function customerProjectTypes(state: CustomerProjectTypePayload = null, action: CustomerProjectTypesAction): DictionaryCustomerProject | null {
    switch (action.type) {
        case 'CHANGE_CUSTOMER_PROJECT_TYPES':
            let dictionaryCustomerProjectType: DictionaryCustomerProject = {}
            for (const key in action.payload) {
                let dictionaryCustomerProjectStatus: DictionaryCustomerProjectStatus = {}
                const element = action.payload[key]
                if (element.CustomerProjectStatuses) {
                    for (const item of element.CustomerProjectStatuses) {
                        dictionaryCustomerProjectStatus[item.id] = item
                    }    
                }
                dictionaryCustomerProjectType[element.id] = {
                    ...element,
                    customerProjectStatusDict: dictionaryCustomerProjectStatus
                }
            }
            return state = dictionaryCustomerProjectType
        default:
            return state
    }
}