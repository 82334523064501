export const checkInputHourValidity = (value: string, maxHours: number = 23) => {
    const time = value.split(':')
    const hours = parseInt(time[0])
    const isCorrectHours = 0 <= hours && hours <= maxHours
    if (time.length === 1) {
        if (isCorrectHours) {
            return {
                hours,
                mins: 0
            }
        }
        return false
    } else if (time.length === 2) {
        const mins = parseInt(time[1])
        const isCorrectMins = 0 <= mins && mins <= 59
        if (isCorrectHours && isCorrectMins) {
            return {
                hours,
                mins
            }
        }
    }
    return false
}