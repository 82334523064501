import { useCallback, useState } from 'react'
import InputCurrency, { InputCurrencyProps } from '..'
import { InputCurrencyValue } from '../types'
import { Modify } from '@teinor/erp'

export type InputCurrencyStateFullProps = Modify<InputCurrencyProps, InputCurrencyStateFullLogicProps & {
    value?: never
}>

const InputCurrencyStateFull: React.FC<InputCurrencyStateFullProps> = ({defaultValue, onChange, ...rest}) => {
    const { myOnChange, myValue } = InputCurrencyStateFullLogic({ onChange, defaultValue, ...rest })
    return (<InputCurrency {...rest} value={myValue} onChange={myOnChange}/>)
}

interface InputCurrencyStateFullLogicProps {
    onChange?: InputCurrencyProps['onChange']
    defaultValue?: InputCurrencyValue
}

const InputCurrencyStateFullLogic = ({ onChange, defaultValue}: InputCurrencyStateFullLogicProps) => {
    const [myValue, setMyValue] = useState<InputCurrencyValue>({ price: defaultValue?.price || '', CurrencyId: defaultValue?.CurrencyId || '' })
    const myOnChange = useCallback((value: InputCurrencyValue) => {
        setMyValue(value)
        if (onChange) {
            onChange(value)
        }
    }, [onChange])

    return { myOnChange, myValue }
}

export default InputCurrencyStateFull