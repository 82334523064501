import { preciseDecimalSum } from '@teinor/erp/prices'
import { CurrencyModelType } from '@teinor/erp/types/company/config/currency'
import { useCallback, useMemo, useState } from 'react'
import { ButtonSecondary } from '../../../../../baseComponents/Button'
import { ComponentWithPermissions, UserPermissions } from '../../../../../baseComponents/CreateRoutering/types'
import { ModalOpenedTy } from '../../../../../baseComponents/Modal/types'
import ModalSmallFilled from '../../../../../baseComponents/ModalsLayouts/ModalSmallFilled'
import PermissionChecker from '../../../../../baseComponents/PermissionChecker'
import { permissionCheck } from '../../../../../baseComponents/PermissionChecker/function'
import TwinTable from '../../../../../baseComponents/TwinTable'
import BaseScreenTable from '../../../../../baseComponents/TwinTable/BaseScreenTable'
import { TableComponentProps } from '../../../../../baseComponents/TwinTable/BaseScreenTable/types'
import { CustomRenderBoolean, CustomRenderCurrencyName, CustomRenderDate, DeleteRow, EditRow } from '../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import { RowData, TableInstanceType } from '../../../../../baseComponents/TwinTable/types'
import TwinTrans from '../../../../../baseComponents/TwinTrans'
import { twinFetchPostText } from '../../../../../utils/globals/data'
import useTwinTranslation from '../../../../../utils/hooks/useTwinTranslation'
import ModalCreateCurrency from './ModalCreateCurrency'
import ModalEditCurrency from './ModalEditCurrency'

interface ConfigCurrenciesProps extends ComponentWithPermissions {}

const ConfigCurrencies: React.FC<ConfigCurrenciesProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()

    const pageData = {
        title: t('currencies', 'Monedas'),
        newText: t('newCurrency', 'Nueva moneda'),
    }
    return (
        <BaseScreenTable TableComponent={TableCurrencies} CreateModalComponent={ModalCreateCurrency} EditModalComponent={ModalEditCurrency} pageData={pageData} userPermissions={userPermissions} />
    )
}

interface TableCurrenciesProps extends TableComponentProps {}

const TableCurrencies: React.FC<TableCurrenciesProps> = ({ userPermissions, setTableInstance, setOpened, tableInstance }) => {
    const { t } = useTwinTranslation()

    const columns: ColumnTableSchema<CurrencyModelType> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('isoCode', 'Código ISO'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('isoCode', 'Código ISO') }
            }
        },
        {
            id: 'currency',
            dataKey: 'id',
            label: t('currency', 'Moneda'),
            customRender: (parameterValue) => <CustomRenderCurrencyName currencyId={parameterValue} />,
            generalSearchable: false
        },
        {
            id: 'is_main',
            dataKey: 'is_main',
            label: t('main', 'Principal'),
            customRender: (parameterValue) => <CustomRenderBoolean value={String(parameterValue) === 'true'} width={56} />,
            generalSearchable: false
        },
        {
            id: 'conversion',
            dataKey: 'conversion',
            label: t('conversion', 'Conversión'),
            sortable: true
        },
        {
            id: 'secure_exchange',
            dataKey: 'secure_exchange',
            label: t('secureExchange', 'Seguro de cambio'),
            sortable: true
        },
        {
            id: 'total',
            dataKey: 'conversion',
            label: t('total', 'Total'),
            customRender: (_parameterValue, allRowData) => <TotalRender allRowData={allRowData}></TotalRender>
        },
        {
            id: 'updatedAt',
            dataKey: 'updatedAt',
            label: t('updatedAt', 'Actualizado en'),
            sortable: true,
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            customRender: (parameterValue, allRowData) => <EditRow key={'edit' + parameterValue} {...{ parameterValue, allRowData }} onClick={(_idk, allRowData) => setOpened({ type: 'edit', allRowData })} />,
            havePermission: permissionCheck(userPermissions, 'update')
        },
        {
            id: 'delete',
            dataKey: 'id',
            label: '',
            width: 50,
            customRender: (parameterValue, allRowData) => <DeleteRow key={'delete' + parameterValue} {...{ parameterValue, allRowData }} onClick={(_idk, allRowData) => setOpened({ type: 'delete', allRowData })} />,
            havePermission: permissionCheck(userPermissions, 'delete')
        }
    ]
    const children = useMemo(() => <UpdateCurrenciesButton tableInstance={tableInstance} key='updateCurrenciesButt' userPermissions={userPermissions}></UpdateCurrenciesButton>, [tableInstance, userPermissions])
    return (
        <TwinTable  name='currencies' columns={columns} getDataFrom='/api/app/config/currency/getAllCurrencies' setTableInstance={setTableInstance} defaultOrder={{ defaultOrderField: 'is_main' }} onRowClick={(_id, rowData) => setOpened({type: 'edit', allRowData: rowData})}>{children}</TwinTable>
    )
}

interface TotalRenderProps {
    allRowData: RowData
}

const TotalRender: React.FC<TotalRenderProps> = ({ allRowData }) => {
    const data = {
        conversion: allRowData.conversion ? parseFloat(allRowData.conversion) : 1,
        secure_exchange: allRowData.secure_exchange ? parseFloat(allRowData.secure_exchange) : 0
    }
    return (
        <div>{preciseDecimalSum(data.conversion, data.secure_exchange)}</div>
    )
}

interface UpdateCurrenciesButtonProps extends UpdateCurrenciesButtonLogicProps {
    userPermissions: UserPermissions
}

const UpdateCurrenciesButton: React.FC<UpdateCurrenciesButtonProps> = ({tableInstance, userPermissions}) => {
    const { opened, setOpened, updateCurrencies } = useUpdateCurrenciesButtonLogic({ tableInstance })
    const {t} = useTwinTranslation()
    return (
        <PermissionChecker permission='update' userPermissions={userPermissions}>
            <ButtonSecondary onClick={() => setOpened(true)}>
                <TwinTrans transKey={'updateConversions'}>Actualizar conversiones</TwinTrans>
            </ButtonSecondary>
            {opened && <ModalSmallFilled opened={true} setOpened={setOpened} onAccept={updateCurrencies} translations={{
                title: t('updateCurrencies', 'Actualizar monedas'),
                subtitle: t('youWillUpdateCurrenciesExchange', 'Actualizarás los cambios de moneda y perderás los cambios actuales'),
                buttonAccept: t('accept', 'Aceptar')
            }} >
            </ModalSmallFilled>}
        </PermissionChecker>
    )
}

interface UpdateCurrenciesButtonLogicProps {
    tableInstance: TableInstanceType
}

const useUpdateCurrenciesButtonLogic = ({tableInstance}: UpdateCurrenciesButtonLogicProps) => {
    const [opened, setOpened] = useState<ModalOpenedTy>(null)

    const updateCurrencies = useCallback(async () => {
        await twinFetchPostText('/api/app/config/currency/updateCurrenciesConvert', {})
        tableInstance?.getTableDataFromStart()
    }, [tableInstance])
    return { opened, setOpened, updateCurrencies}
}



export default ConfigCurrencies