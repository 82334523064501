import { useMemo } from 'react'
import { rangeViewGenerateDays } from './function'
import { RangeViewCalendarEvents, RangeViewDate } from './types'
import { TwinAutoSizer } from '../../TwinTable/Adapters'
import './rangeView.sass'

interface RangeViewProps extends RangeViewLogicProps {
    leftColumnData: { [id: number]: string }
    events: RangeViewCalendarEvents
    headerWidth?: number
    skipRowsWithoutData?: boolean
}

const RangeView: React.FC<RangeViewProps> = ({...rest}) => {
    return (
        <div className='range_view flex-auto'>
            <TwinAutoSizer>
                {({ width, height }) => (
                    <RangeViewAutoSized {...{ width, height, ...rest }} />
                )}
            </TwinAutoSizer>
        </div>
    )
}

interface RangeViewAutoSizedProps extends RangeViewProps {
    width: number
    height: number
}
const RangeViewAutoSized: React.FC<RangeViewAutoSizedProps> = ({ width, height, headerWidth = 170, events, leftColumnData, skipRowsWithoutData, ...logic}) => {
    const { dates } = RangeViewAutoSizedLogic({ ...logic })
    const colWidth = (width - headerWidth) / (dates.length || 0)
    const myColWidth = colWidth < 16 ? 16 : colWidth
    return (
        <div className='range_view_inner flex flex-col overflow-x-auto overflow-y-hidden' style={{height, width}}>
            <RangeViewHeader dates={dates} colWidth={myColWidth} headerWidth={headerWidth - 3} />
            <RangeViewRows {...{ colWidth: myColWidth, headerWidth: headerWidth - 3, dates, events, leftColumnData, rowWidth: width, skipRowsWithoutData }} />
        </div>
    )
}

interface RangeViewLogicProps {
    dateStart: Date
    dateEnd: Date
}

const RangeViewAutoSizedLogic = ({dateStart, dateEnd}: RangeViewLogicProps) => {
    const dates = useMemo(() => rangeViewGenerateDays(dateStart, dateEnd), [dateStart, dateEnd])
    return {dates}
}

interface RangeViewHeaderProps {
    dates: RangeViewDate[]
    headerWidth: number
    colWidth: number
}

const RangeViewHeader: React.FC<RangeViewHeaderProps> = ({ dates, headerWidth, colWidth }) => {
    const renderThis: JSX.Element[] = []
    for (const date of dates) {
        renderThis.push(<div style={{ minWidth: colWidth + 'px' }} key={date.key} className='capitalize regular12 vertical_text'>{date.text}</div>)
    }
    return (
        <div className='flex items-end range_view_header'>
            <div style={{ minWidth: headerWidth + 'px' }}></div>
            {renderThis}
        </div>
    )
}

interface RangeViewRowsProps {
    dates: RangeViewDate[]
    events: RangeViewCalendarEvents
    headerWidth: number
    colWidth: number
    leftColumnData: { [id: number]: string }
    rowWidth: number
    skipRowsWithoutData?: boolean
}

const RangeViewRows: React.FC<RangeViewRowsProps> = ({ dates, headerWidth, colWidth, leftColumnData, events, skipRowsWithoutData }) => {
    const renderThis: JSX.Element[] = []
    for (const id in leftColumnData) {
        const row: JSX.Element[] = []
        const rowEvents = events?.[id]
        if (!skipRowsWithoutData || (skipRowsWithoutData && rowEvents && Object.keys(rowEvents).length)) {
            row.push(<div style={{ minWidth: headerWidth + 'px' }} key={'header'+id}>{leftColumnData[id]}</div>)
            for (const date of dates) {
                const event = rowEvents?.[date.key]
                row.push(<div style={{ minWidth: colWidth + 'px'}} key={id + date.key} className='h-full range_view_cell'>
                    <div style={{ background: event ? event.color : '#C3D3DE' }} className='range_view_cell_inner h-full w-full'></div>
                </div>)
            }
            renderThis.push(<div className='flex items-center range_view_row w-fit'>{row}</div>)
        }
    }
    return (
        <div className='range_view_scroll flex-auto h-1'>
            <div className='w-fit h-full overflow-x-auto'>
                {renderThis}
            </div>
        </div>
    )
}


export default RangeView