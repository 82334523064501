import { faExclamationCircle, faGlobe, faUserLarge } from '@fortawesome/pro-light-svg-icons'
import TwinIcon from '../../../../baseComponents/TwinIcon'
import TwinTrans from '../../../../baseComponents/TwinTrans'

interface TypeUserCompanyProps {
    type: number
    className?: string
    onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const TypeUserCompany: React.FC<TypeUserCompanyProps> = ({ type, className, onClick }) => {
    if (type === 3) {
        return (
            <TypeUserCompanyGuest {...{className, onClick}} />
        )
    } else  if (type === 100) {
        return (
            <TypeUserCompanyPending {...{className, onClick}} />
        )
    }
    return (
        <TypeUserCompanyManager {...{className, onClick}} />
    )
}

interface TypeUserCompanyGuestProps extends TypeUserCompanySingleProps{}

const TypeUserCompanyGuest: React.FC<TypeUserCompanyGuestProps> = ({className, onClick}) => {
    return (
        <div className={className} onClick={onClick}>
            <TwinIcon icon={faUserLarge} className='text-green-43 mr-6' />
            <TwinTrans transKey={'guest'}>Invitado</TwinTrans>
        </div>
    )
}

interface TypeUserCompanyPendingProps extends TypeUserCompanySingleProps{}

const TypeUserCompanyPending: React.FC<TypeUserCompanyPendingProps> = ({ className, onClick}) => {
    return (
        <div className={'text-red-BA ' + (className || '')} onClick={onClick}>
            <TwinIcon icon={faExclamationCircle} className='mr-6' />
            <TwinTrans transKey={'invitationPendent'}>Invitación pendiente</TwinTrans>
        </div>
    )
}

interface TypeUserCompanySingleProps {
    className?: string
    onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

interface TypeUserCompanyManagerProps extends TypeUserCompanySingleProps{}

const TypeUserCompanyManager: React.FC<TypeUserCompanyManagerProps> = ({className, onClick}) => {
    return (
        <div className={className} onClick={onClick}>
            <TwinIcon icon={faGlobe} className=' mr-6' />
            <TwinTrans transKey={'manager'}>Responsable</TwinTrans>
        </div>
    )
}

export default TypeUserCompany