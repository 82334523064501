import DisplayPrice from '../../baseComponents/Displays/DisplayPrice'

export interface DisplayPriceFieldProps {
    className?: string
    label: string
    value?: number
    CurrencyId: string
}

export const DisplayPriceField: React.FC<DisplayPriceFieldProps> = ({ className, label, value, CurrencyId }) => {
    return (
        <div className={'flex flex-col ' + (className || '')}>
            <span className='light12 text-gray-51 mb-4'>{label}</span>
            <DisplayPrice value={value || 0} CurrencyId={CurrencyId} with_vat={false} calculateParams={{
                decimals: 2,
                rounding: '0.01',
                roundingPriceType: 'medium'
            }}/>
        </div>
    )
}