import { CategoryModelType } from '@teinor/erp/types/company/category'

export interface Category extends CategoryModelType {
    'CategoryLangs.name': string
    childs?: Category[]
}
export interface OrganizedCategoriesTy {
    ogCategory: Category[]
    treeCategory: Category[]
}
type CategoriesOrganized = { [key: number]: OrganizedCategoriesTy }
export interface CategoriesPayload{
    organized: CategoriesOrganized
    dictionary: {[id: number]: Category}
}
export interface CategoriesAction {
    type: 'CHANGE_CATEGORIES'
    payload: Category[]
}


export function categories(state: CategoriesPayload | null = null, action: CategoriesAction): CategoriesPayload | null {
    switch (action.type) {
        case 'CHANGE_CATEGORIES':
            return state = organizeByMaterialType(action.payload)
        default:
            return state
    }
}


const organizeByMaterialType = (categories: Category[]) => {
    const result: CategoriesOrganized = {}
    const dictionary: CategoriesPayload['dictionary'] = {}
    if (categories && categories.length) {
        const types = [0,1]
        for (const category of categories) {
            dictionary[category.id] = category
            if (category.id === 1) {
                const categoryMain = category
                for (const type of types) {
                    if (!result[type]) {
                        result[type] = {
                            ogCategory: [JSON.parse(JSON.stringify(categoryMain))],
                            treeCategory: []
                        }
                    }    
                }
            } else {
                result[category.categoryMaterialType].ogCategory.push(category)
            }
        }
        for (const type in result) {
            result[type].treeCategory = makeTree(result[type].ogCategory)
        }
    }
    return { organized: result, dictionary: dictionary }
}
const makeTree = (categories: Category[]) => {
    const result: any = []
    const index: any = {}
    for (const item of categories) {
        if (!item['CategoryLangs.name']) {
            if (item.id === 1) {
                item['CategoryLangs.name'] = 'Base'
            } else {
                item['CategoryLangs.name'] = '-'
            }
        }
        if (!item.CategoryId) {
            result.push(item)
            continue
        }
        if (index[item.CategoryId] === undefined) {
            index[item.CategoryId] = []
        }
        index[item.CategoryId].push(item)
    }
    for (const item of result) {
        recursiveTree(item, index)
    }
    return result
}
const recursiveTree = (item: Category, map: any = {}) => {
    item.childs = []
    if (map[item.id]) {
        for (const sub of map[item.id]) {
            recursiveTree(sub, map)
            item.childs.push(sub)
        }
    }
}