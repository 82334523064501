import { useCallback, useContext, useEffect } from 'react';
import { NavigateOptions, To, UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

type BlockerFunction = (to: To, state?: any, opts?: NavigateOptions | undefined) => boolean

function useConfirmExit(confirmExit: BlockerFunction, when = true) {
    const { navigator } = useContext(NavigationContext);

    useEffect(() => {
        if (!when) {
            return;
        }

        const push = navigator.push;

        navigator.push = (...args: Parameters<typeof push>) => {
            const result = confirmExit(...args);
            if (result !== false) {
                push(...args);
            }
        };

        return () => {
            navigator.push = push;
        };
    }, [navigator, confirmExit, when]);
}

export function useBlocker(message: string | BlockerFunction, when = true) {
    useEffect(() => {
        if (when) {
            window.onbeforeunload = function () {
                return message;
            };
        }

        return () => {
            window.onbeforeunload = null;
        };
    }, [message, when]);

    const confirmExit: BlockerFunction = useCallback((...params) => {
        let confirm = false
        if (typeof message === 'function') {
            confirm = message(...params) || false
        } else {
            confirm = window.confirm(message);
        }
        return confirm;
    }, [message]);
    useConfirmExit(confirmExit, when);
}