import { useCallback, useEffect, useState } from 'react'
import { ModalOpenedSetTy } from '../../../../../../../../baseComponents/Modal/types'
import ModalTabs from '../../../../../../../../baseComponents/ModalsLayouts/ModalTabs'
import { ModalEditComponentProps } from '../../../../../../../../baseComponents/ModalsLayouts/types'
import { twinFetchPostJSON } from '../../../../../../../../utils/globals/data'
import { elementsOfDictionaryByValues } from '../../../../../../../../utils/globals/dictionary'
import useIsLoading from '../../../../../../../../utils/hooks/useIsLoading'
import { ComponentTabObject } from '../../../../../../../../utils/hooks/useTabLogic'
import useTwinTranslation from '../../../../../../../../utils/hooks/useTwinTranslation'
import { DictionaryEmployee } from '../../../../../../../../utils/reducers/company/employees'
import { getEmployees, getGroups } from '../../../../../../../../utils/reducers/getters'
import { DictionaryGroup } from '../../../../../../../../utils/reducers/groups/groups'
import { changeEmployeeParams } from '../../../../../../../../utils/reducers/reduxDispatch'
import { TaskAdminCalendarSelectedData, TaskAdminCalendarSelectedFullDataProps } from '../../types'
import ModalTaskCalendarAdmin from './tabs/ModalTaskCalendarAdmin'
import ModalTaskCalendarAdminColorPicker from './tabs/ModalTaskCalendarAdminColorPicker'

type ModalTabsTaskCalendarAdminProps = ModalEditComponentProps & ModalTabsTaskCalendarAdminLogicProps & {
}

const ModalTabsTaskCalendarAdmin: React.FC<ModalTabsTaskCalendarAdminProps> = ({ ...rest }) => {
    const { t } = useTwinTranslation()
    const logic = useModalTabsTaskCalendarAdminLogic({ ...rest })
    const tabs: ComponentTabObject = {
        'main': { component: ModalTaskCalendarAdmin, text: t('config', 'Configuración') },
        'colors': { component: ModalTaskCalendarAdminColorPicker, text: t('assignColors', 'Definir colores') }
    }
    return (
        <ModalTabs {...rest} tabs={tabs} tab={'main'} className='notFlexAutoFormRender' extraComponentData={logic}></ModalTabs>
    )
}

interface ModalTabsTaskCalendarAdminLogicProps extends TaskAdminCalendarSelectedFullDataProps {
    setOpened: ModalOpenedSetTy
}

const useModalTabsTaskCalendarAdminLogic = ({ selectedData, setSelectedData, setOpened }: ModalTabsTaskCalendarAdminLogicProps) => {
    const [validFilters, setValidFilters] = useState<{ validEmployees: DictionaryEmployee, validGroups: DictionaryGroup } | null>(null)
    const [tmpSelectedData, setTmpSelectedData] = useState<TaskAdminCalendarSelectedData>(selectedData)
    const { endLoading, loading, startLoading } = useIsLoading()

    const updateFilterOnCalendar = useCallback(async (myfilters: TaskAdminCalendarSelectedData, id: number) => {
        delete myfilters.id
        delete myfilters.name
        const myValue = JSON.stringify(myfilters)
        startLoading()
        await twinFetchPostJSON('/api/app/config/filter/updateFilter', { id, value: myValue })
        endLoading()
    }, [startLoading, endLoading])

    const updateEmployeeParams = useCallback(async (myfilters: TaskAdminCalendarSelectedData) => {
        delete myfilters.id
        delete myfilters.name
        const myValue = JSON.stringify(myfilters)
        startLoading()
        await twinFetchPostJSON('/api/app/config/employee/updateEmployeeParameter', { parameterKey: 'task_calendar_admin_filter', value: myValue })
        endLoading()
        changeEmployeeParams({ 'task_calendar_admin_filter': myValue })
    }, [startLoading, endLoading])

    const updateParamsDecider = useCallback(async (filters: TaskAdminCalendarSelectedData) => {
        if (String(tmpSelectedData.id) !== '0' && tmpSelectedData.id) {
            await updateFilterOnCalendar(filters, tmpSelectedData.id)
        } else {
            await updateEmployeeParams(filters)
        }
    }, [tmpSelectedData.id, updateFilterOnCalendar, updateEmployeeParams])
    const onSave = useCallback(() => {
        setSelectedData({ ...tmpSelectedData })
        updateParamsDecider({ ...tmpSelectedData })
        setOpened(null)
        return false
    }, [tmpSelectedData, setOpened, updateParamsDecider, setSelectedData])

    const getAllValidEmployeeAndGroups = useCallback(async () => {
        const employees = getEmployees()
        const groups = getGroups()
        const result = await twinFetchPostJSON('/api/app/task/getAllValidGroupsAndEmployeesForTask', {})
        if (result) {
            const validGroups: DictionaryGroup = elementsOfDictionaryByValues(groups || {}, result.validGroups)
            const validEmployees: DictionaryEmployee = elementsOfDictionaryByValues(employees || {}, result.validEmployees)
            setValidFilters({
                validEmployees,
                validGroups
            })
        }
    }, [setValidFilters])

    useEffect(() => {
        getAllValidEmployeeAndGroups()
    }, [getAllValidEmployeeAndGroups])

    return { selectedData: tmpSelectedData, setSelectedData: setTmpSelectedData, onSave, loading, validFilters }
}

export default ModalTabsTaskCalendarAdmin