import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons'
import TwinIcon from '../TwinIcon'

interface CustomErrorProps {
    textError?: string | false
    className?: string
}

const CustomError: React.FC<CustomErrorProps> = ({ textError, className }) => {
    if (!textError) {
        return null
    }
    return (
        <div className={'flex bg-red-FE text-red-BA px-12 py-14 items-center custom_error ' + (className || '')}>
            <TwinIcon icon={faExclamationTriangle} style={{width: '28px', height: '25px'}} className='ml-8 mr-12' />
            <span>{textError}</span>
        </div>
    )
}

export default CustomError