import "tailwindcss/tailwind.css"
import './utils/globals/variables.sass'
import './app.sass'
import './dark.sass'
import { Suspense, useCallback, useEffect, useState } from 'react';
import { AllReduxAppPayloads, GlobalContext } from './utils/reducers/';
import { connect, ConnectedProps } from 'react-redux';
import LoginRoutes from './app/login'
import { changeCountries, changeFingerPrint, setUserIfCookieValid } from './utils/reducers/reduxDispatch';
import AdminRoutes from './app/admin';
import ErpRoutes from './app/erp';
import { BrowserRouter as Router } from 'react-router-dom';

function App() {
  const [loaded, setLoaded] = useState(false)
  const firstLoading = useCallback(async () => {
    await Promise.all([
      changeFingerPrint(),
      changeCountries(),
    ])
    await setUserIfCookieValid()
    setLoaded(true)
  }, [])

  useEffect(() => {
    firstLoading()
  }, [firstLoading])
  
  return (
    <Suspense fallback={<LoadingDiv />}>
      {loaded && 
        <AppTranslated />
      }
    </Suspense>
  );
}

const LoadingDiv: React.FC = () => {
  return <div className="App bg-gradient-to-r from-bg-start via-bg-mid to-bg-end h-screen"> <div>Loading</div> </div>
}

const AppTranslated: React.FC = () => {
  return (
    <div className='h-screen w-screen'>
      <Router>
        <AppLogicRoutesConnect />
      </Router>
    </div>
  );
}

interface AppLogicRoutesProps extends ReduxAppRoutesLogicConnector { }

const AppLogicRoutes: React.FC<AppLogicRoutesProps> = ({ user }) => {
  if (!user) {
    return <LoginRoutes />
  } else if (user.type === 'admin') {
    return <AdminRoutes />
  } else if (user.type === 'erp') {
    return <ErpRoutes />
  }
  return null
}

const mapLogicRoutesConnector = (state: AllReduxAppPayloads) => ({ user: state.user })
const appLogicRouterConnector = connect(mapLogicRoutesConnector, null, null, {context: GlobalContext})
type ReduxAppRoutesLogicConnector = ConnectedProps<typeof appLogicRouterConnector>
const AppLogicRoutesConnect = appLogicRouterConnector(AppLogicRoutes)

export default App;
