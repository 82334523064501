import { faUserGroup } from '@fortawesome/pro-light-svg-icons'
import { WhiteBox } from '../../../../../../baseComponents/AppLayout/WhiteBox'
import { BigTitleAndRightLayout } from '../../../../../../baseComponents/Layout/TitleAndRightLayout'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import { getBasePath } from '../../../../../../utils/globals/link'
import { useLocation } from 'react-router-dom'
import { useState, useCallback, useEffect } from 'react'
import { SearchBarTable } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents'
import { twinFetchPostJSON } from '../../../../../../utils/globals/data'
import { TwinDictionary, dictionaryComplexFromJsonArr } from '../../../../../../utils/globals/dictionary'
import { getUserInfo } from '../../../../../../utils/reducers/getters'
import TwinTableCard, { RenderTwinTableCards } from '../../../../../../baseComponents/TwinTable/TwinTableCard'
import { stringMatchWithSearch } from '../../../../../../utils/globals/search'

interface TasksTableGroupsProps { }
type GroupRender = {
    myGroups: JSX.Element[]
    otherGroups: JSX.Element[]
}
const TasksTableGroups: React.FC<TasksTableGroupsProps> = () => {
    const { t } = useTwinTranslation()
    const location = useLocation()
    const basePath = getBasePath(location.pathname, 'groups')
    const { data, generalSearch, handleGeneralSearch, } = useTasksTableGroupsLogic()
    const user = getUserInfo()
    const dictGroups = dictionaryComplexFromJsonArr(user?.Employee?.Groups || [])
    const renderThisJSON: GroupRender = {
        myGroups: [],
        otherGroups: []
    }

    for (const element of data) {
        const includesSearch = stringMatchWithSearch(generalSearch, element?.name)
        if (includesSearch) {
            const elementJSX = <TwinTableCard key={element.id} id={element.id} title={element?.name || ''} img={element?.photo || '/group.png'} icon={faUserGroup} lastPath='main' {...{ basePath }} />
            if (dictGroups[element.id]) {
                renderThisJSON.myGroups.push(elementJSX)
            } else {
                renderThisJSON.otherGroups.push(elementJSX)
            }
        }
    }
    return (
        <BigTitleAndRightLayout title={t('teams', 'Equipos')}>
            <WhiteBox className='flex flex-col flex-auto px-40 py-50'>
                <div className='flex flex-col flex-auto'>
                    <SearchBarTable className='mb-20' changeGeneralSearchbar={handleGeneralSearch} placeholder={t('searchDots', 'Buscar...')} />
                    <div className='flex-auto h-1 overflow-auto'>
                        <RenderTwinTableCards title={t('myTeams', 'Mis equipos')} twinTableCards={renderThisJSON.myGroups} />
                        <RenderTwinTableCards title={t('otherTeams', 'Otros equipos')} twinTableCards={renderThisJSON.otherGroups} className='mt-20' />
                    </div>
                </div>
            </WhiteBox>
        </BigTitleAndRightLayout>

    )
}


const useTasksTableGroupsLogic = () => {
    const [data, setData] = useState<TwinDictionary[]>([])
    const [generalSearch, setGeneralSearch] = useState<string>('')

    const handleGeneralSearch = useCallback((val: string) => {
        setGeneralSearch(val)
    }, [setGeneralSearch])

    const getData = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/task/getAllValidGroupsForTask', {})
        if (result) {
            setData(result)
        }
    }, [])

    useEffect(() => {
        getData()
    }, [getData])
    return { data, generalSearch, handleGeneralSearch }
}

export default TasksTableGroups