import { useState } from 'react'

export interface CountryStateProps {
    defaultCountry?: string
}

const useCountryState = ({ defaultCountry }: CountryStateProps = {}) => {
    const [country, setCountry] = useState<string>(defaultCountry || '')

    return { country, setCountry }

}


export default useCountryState