import { useCallback, useLayoutEffect, useState } from 'react'
import { taskDoubleTableAnimationKeys, TaskDoubleTableAnimationStatus } from './types'
import { TaskTableBaseCompletedProps } from '../TasksTableDefault/types'
import './taskDoubleTable.sass'

type TaskDoubleTableProps = TaskTableBaseCompletedProps & TaskDoubleTableLogicProps & {
    FirstChild: React.FC<any>
    SecondChild: React.FC<any>
}

const TaskDoubleTable: React.FC<TaskDoubleTableProps> = ({ FirstChild, SecondChild, displaySecond, userPermissions, basePath, ...rest }) => {
    const { animationReady } = useTaskDoubleTableLogic({ displaySecond })
    return (
        <div className={'px-40 py-45 grid flex-auto taskDoubleTable overflow-auto ' + (animationReady > 0 ? 'active' : '')}>
            <FirstChild {...{ userPermissions, ...rest }} />
            {animationReady > 0 && displaySecond ? <SecondChild {...{ userPermissions, animationReady, basePath, ...rest }} /> : null}
        </div>
    )
}
interface TaskTableTabProps extends TaskTableBaseCompletedProps {
    FirstChild: React.FC<any>
}

export const TaskTableTab: React.FC<TaskTableTabProps> = ({ FirstChild, ...rest }) => {
    return (
        <div className={'grid flex-auto taskDoubleTable taskTableTab' }>
            <FirstChild {...rest } />
        </div>
    )
}

interface TaskDoubleTableLogicProps {
    displaySecond: boolean
}

const useTaskDoubleTableLogic = ({ displaySecond }: TaskDoubleTableLogicProps) => {
    const [animationReady, setAnimationReady] = useState<TaskDoubleTableAnimationStatus>(taskDoubleTableAnimationKeys['none'])
    const processAnimation = useCallback(() => {
        setTimeout(() => {
            setAnimationReady(taskDoubleTableAnimationKeys['loaded'])
        }, 450)
    }, [])

    useLayoutEffect(() => {
        setAnimationReady((oldAnimationReady) => {
            if (displaySecond && oldAnimationReady === taskDoubleTableAnimationKeys['loading']) {
                processAnimation()
            }
            if (displaySecond && oldAnimationReady === taskDoubleTableAnimationKeys['none']) {
                return 1
            } else if (!displaySecond && oldAnimationReady === taskDoubleTableAnimationKeys['loaded']) {
                return 0
            }
            return oldAnimationReady
        })
    }, [animationReady, displaySecond, processAnimation])

    return { animationReady }
}

export default TaskDoubleTable