import { CustomerModelType } from '@teinor/erp/types/company/customer'
import { ComponentWithPermissions } from '../../../../../../../../../baseComponents/CreateRoutering/types'
import SingleFormLayout from '../../../../../../../../../baseComponents/Layout/SingleFormLayout'
import { FormRender } from '../../../../../../../../../forms/FormRenderer/types'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'

interface ContactNotesProps extends ComponentWithPermissions {
    basicData: CustomerModelType | null
    getData: () => Promise<void>
}

const ContactNotes: React.FC<ContactNotesProps> = ({ basicData, userPermissions, getData }) => {
    const { t } = useTwinTranslation()

    const fields: FormRender<CustomerModelType> = [
        {
            cols: 1,
            title: t('notesContact', 'Notas del Contacto'),
            elements: [
                {
                    name: 'notes',
                    component: 'TwinEditor',
                    defaultValue: basicData?.notes || '',
                    className: 'h-200'
                },
                {
                    name: 'id',
                    component: 'InputHidden',
                    value: basicData?.id
                }
            ]
        }
    ]
    return (
        <SingleFormLayout action='/api/app/customer/updateCustomer' onSubmit={getData} sections={fields} userPermissions={userPermissions} />
    )
}

export default ContactNotes