import { useEffect, useMemo, useState } from 'react'
import { ConfigColumnsColumn } from '../../baseComponents/ModalsLayouts/ModalConfigColumnsTabs/types'
import { indexKeyDictionaryFromJsonArray } from '../globals/dictionary'
import { getEmployeeParams } from '../reducers/getters'
import { Order } from '../../baseComponents/TwinTable/types'

interface ConfigColumnsProps {
    columns: ConfigColumnsColumn<any>[]
    name: string
}
export type SelectedColumnsConfigTy = {
    userWidth?: number
}
export type SelectedColumnsTy = { [name: string]: SelectedColumnsConfigTy }
export type SingleSelectedFilterTy = { value?: any, excluded?: boolean }
export type SelectedFiltersTy = { [name: string]: SingleSelectedFilterTy }
export type SelectedOrderTy = { [name: string]: Order }
export interface SelectedColumnsAndFilters {
    selectedColumns: SelectedColumnsTy
    filters: SelectedFiltersTy
    id?: number
    name?: string
    order: SelectedOrderTy
}

const useConfigColumns = ({ columns, name }: ConfigColumnsProps) => {
    const [selectedColumnsAndFilters, setSelectedColumnsAndFilters] = useState<SelectedColumnsAndFilters>(getTableSelectedColumns(name, columns))
    const memorizedColumns = useMemo(() => getOnlySelectedColumns(columns, selectedColumnsAndFilters.selectedColumns), [columns, selectedColumnsAndFilters.selectedColumns]) as any
    const cols = useMemo(() => JSON.stringify(columns.map((item) => { return { id: item.id, hideColumnByDefault: item.hideColumnByDefault } })), [columns])
    
    useEffect(() => {
        const colsParsed = JSON.parse(cols)
        setSelectedColumnsAndFilters(getTableSelectedColumns(name, colsParsed))
    }, [cols, name])

    return { selectedColumnsAndFilters, setSelectedColumnsAndFilters, memorizedColumns, filterColumns: selectedColumnsAndFilters.filters}
}

const getOnlySelectedColumns = (columns: ConfigColumnsColumn[], selectedColumns: SelectedColumnsTy) => {
    if (!Object.keys(selectedColumns).length) {
        return columns.filter((item) => !item.hideColumnByDefault )
    }
    const result : ConfigColumnsColumn[] = []
    const dict = indexKeyDictionaryFromJsonArray(columns)
    for (const key in selectedColumns) {
        result.push({...columns[dict[key]], ...selectedColumns[key]})
    }
    return result
}

export const getTableSelectedColumns = (name: string, columns: ConfigColumnsColumn[]): SelectedColumnsAndFilters => {
    return getEmployeeParams()?.['table_' + name] ? JSON.parse(getEmployeeParams()?.['table_' + name]) : getOnlyDefaultColumns(columns)
}

export const getOnlyDefaultColumns = (columns: ConfigColumnsColumn[]) => {
    const result: SelectedColumnsAndFilters = {
        selectedColumns: {},
        filters: {},
        order: {}
    }
    for (const key in columns) {
        const item = columns[key]
        if (!item.hideColumnByDefault) {
            result.selectedColumns[item.id] = {}
        }
    }
    return result
}

export default useConfigColumns
