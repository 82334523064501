import { CustomerModelType } from '@teinor/erp/types/company/customer'
import { ComponentWithPermissions } from '../../../../../../../../../baseComponents/CreateRoutering/types'
import { permissionCheck } from '../../../../../../../../../baseComponents/PermissionChecker/function'
import TwinTable from '../../../../../../../../../baseComponents/TwinTable'
import BaseScreenTableInner from '../../../../../../../../../baseComponents/TwinTable/BaseScreenTableInner'
import { TableComponentBaseScreenInnerProps } from '../../../../../../../../../baseComponents/TwinTable/BaseScreenTableInner/types'
import { EditRow, DeleteRow, CustomRenderBoolean } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import ModalCECustomerShop from '../CustomerShops/ModalCECustomerShop'
import { CustomerShopModelType } from '@teinor/erp/types/company/customer/customerShop'


interface CustomerShopsProps extends ComponentWithPermissions {
    basicData: CustomerModelType | null
}

const CustomerShops: React.FC<CustomerShopsProps> = ({ basicData, userPermissions }) => {
    const { t } = useTwinTranslation()

    const pageData = {
        title: t('workingCenters', 'Centros de trabajo'),
        newText: t('newWorkingCenter', 'Nuevo centro de trabajo'),
    }

    return (
        <BaseScreenTableInner TableComponent={TableCustomerShops} CreateModalComponent={ModalCECustomerShop} EditModalComponent={ModalCECustomerShop} pageData={pageData} userPermissions={userPermissions} extraWhereParams={{ CustomerId: basicData?.id }} />
    )
}
interface TableCustomerShopsProps extends TableComponentBaseScreenInnerProps { }

const TableCustomerShops: React.FC<TableCustomerShopsProps> = ({ setOpened, setTableInstance, userPermissions, extraWhereParams }) => {
    const { t } = useTwinTranslation()

    const columns: ColumnTableSchema<CustomerShopModelType> = [
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            }
        },
        {
            id: 'address',
            dataKey: 'address',
            label: t('address', 'Dirección'),
            sortable: true,
        },
        {
            id: 'city',
            dataKey: 'city',
            label: t('city', 'Ciudad'),
            sortable: true,
        },
        {
            id: 'phone',
            dataKey: 'phone',
            label: t('phone', 'Teléfono'),
        },
        {
            id: 'email',
            dataKey: 'email',
            label: t('email', 'Email'),
        },
        {
            id: 'isMain',
            dataKey: 'isMain',
            label: t('isMainWC', 'Centro principal'),
            width: 150,
            customRender: (parameterValue) => <CustomRenderBoolean value={String(parameterValue) === 'true'} width={75} />
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            customRender: (parameterValue, allRowData) => <EditRow key={'edit' + parameterValue} {...{ parameterValue, allRowData }} />
        },
        {
            id: 'delete',
            dataKey: 'id',
            label: '',
            width: 50,
            customRender: (parameterValue, allRowData) => <DeleteRow key={'delete' + parameterValue} {...{ parameterValue, allRowData }} onClick={(_idk, allRowData) => setOpened({ type: 'delete', allRowData })} />,
            havePermission: permissionCheck(userPermissions, 'delete')
        }
    ]
    return (
        <TwinTable columns={columns} name='customersShops' getDataFrom='/api/app/customer/customerShop/getAllCustomerShops'
            onRowClick={(_id, rowData) => setOpened({
                type: 'edit',
                allRowData: rowData,
            })} setTableInstance={setTableInstance} extraWhereParams={extraWhereParams}
        />
    )
}

export default CustomerShops