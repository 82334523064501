import { dictionaryComplexFromJsonArr } from '../../globals/dictionary'
import { EmployeeAbsenceTypeModelType } from '@teinor/erp/types/company/userInner/employee/employeeAbsence/employeeAbsenceType'

export interface EmployeeAbsenceTypesAction {
    type: 'CHANGE_EMPLOYEEABSENCETYPE'
    payload: EmployeeAbsenceTypeModelType[]
}
export type DictionaryEmployeeAbsence = { [id: number]: EmployeeAbsenceTypeModelType }
export type EmployeeAbsenceTypePayload = null | DictionaryEmployeeAbsence

export default function employeeAbsenceType(state: EmployeeAbsenceTypePayload = null, action: EmployeeAbsenceTypesAction): DictionaryEmployeeAbsence | null {
    switch (action.type) {
        case 'CHANGE_EMPLOYEEABSENCETYPE':
            const dictionaryEmployeeAbsenceType = dictionaryComplexFromJsonArr(action.payload || [])
            return state = dictionaryEmployeeAbsenceType
        default:
            return state
    }
}