import { TwinDictionary } from '../globals/dictionary'
import useTwinTranslation from './useTwinTranslation'

const useDictMonths = () => {
    const {t} = useTwinTranslation()
    const dictMonths: TwinDictionary = {
        1: t('january', 'Enero'),
        2: t('february', 'Febrero'),
        3: t('march', 'Marzo'),
        4: t('april', 'Abril'),
        5: t('may', 'Mayo'),
        6: t('june', 'Junio'),
        7: t('july', 'Julio'),
        8: t('august', 'Agosto'),
        9: t('september', 'Septiembre'),
        10: t('october', 'Octubre'),
        11: t('november', 'Noviembre'),
        12: t('december', 'Diciembre')
    }
    return { dictMonths }
}

export default useDictMonths