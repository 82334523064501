import ReactDOM from 'react-dom'
import { ModalDelete, ModalDeleteProps } from '../../Modal'

interface ModalDeleteFilledProps extends ModalDeleteProps {
    translations: {
        title: string
        subtitle: string
        buttonCancel?: string
        buttonAccept?: string
    }
}

const ModalDeleteFilled: React.FC<ModalDeleteFilledProps> = ({translations, ...rest}) => {
    return (
        <ModalDelete {...rest} translations={translations}>
            <h2>{translations.title}</h2>
            <span>{translations.subtitle}</span>
        </ModalDelete>
    )
}

interface ModalDeleteFilledOutsideProps extends ModalDeleteFilledProps { }

export const ModalDeleteFilledOutside: React.FC<ModalDeleteFilledOutsideProps> = ({ ...rest }) => {
    return (
        ReactDOM.createPortal(<ModalDeleteFilled  {...rest} />, document.body)
    )
}

export default ModalDeleteFilled