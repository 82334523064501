import { ComponentWithPermissions } from '../../../../../baseComponents/CreateRoutering/types'
import { permissionCheck } from '../../../../../baseComponents/PermissionChecker/function'
import { BaseScreenTableSimpleStateless, useBaseScreenTableLogic } from '../../../../../baseComponents/TwinTable/BaseScreenTable'
import { TableComponentProps } from '../../../../../baseComponents/TwinTable/BaseScreenTable/types'
import { CustomRenderDate, CustomRenderString, EditRow } from '../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../utils/hooks/useTwinTranslation'
import { SueModelType } from '@teinor/erp/types/company/sue/'
import useUpdateTableInstance from '../../../../../utils/hooks/useUpdateTableInstance'
import TwinTable from '../../../../../baseComponents/TwinTable'
import ModalEditSue from './ModalEditSue'
import { TwinDictionary } from '../../../../../utils/globals/dictionary'
import './sues.sass'
import { DisplayStatusWithoutIcon } from '../../../../../baseComponents/Displays/DisplayStatus'

interface SuesProps extends ComponentWithPermissions { }

const Sues: React.FC<SuesProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()
    const pageData = {
        title: t('sues', 'Denuncias'),
        newText: t('newSue', 'Nueva denuncia'),
    }
    const { openModal, setOpenModal, tableInstance, setTableInstance } = useBaseScreenTableLogic()
    const { updateTableInstance } = useUpdateTableInstance({ tableInstance })
    return (
        <BaseScreenTableSimpleStateless logicProps={{ openModal, setOpenModal, setTableInstance, tableInstance }} EditModalComponent={ModalEditSue} TableComponent={TableSues} {...{ pageData, userPermissions, updateTableInstance }} />
    )
}

interface TableSuesProps extends TableComponentProps { }

const TableSues: React.FC<TableSuesProps> = ({ userPermissions, setTableInstance, setOpened }) => {
    const { t } = useTwinTranslation()

    const sueRelationTypesDict: TwinDictionary = {
        0: { name: t('employee', 'Empleado')},
        1: { name: t('customer', 'Cliente')},
        2: { name: t('supplier','Proveedor')},
        3: { name: t('other', 'Otros')}
    }
    const sueCategoryTypesDict: TwinDictionary = {
        0: { name: t('fraud','Fraude')},
        1: { name: t('robbery','Robo')},
        2: { name: t('misuseOfInformation','Mal uso de la información')},
        3: { name: t('workplaceHarassment','Acoso laboral')},
        4: { name: t('sexualHarassment','Acoso sexual')},
        5: { name: t('immoralBehavior','Conducta inmoral')},
        6: { name: t('other', 'Otros')},
    }

    const sueStatusTypesDict: TwinDictionary = {
        0: { name: t('sueTableWithoutProcessing', 'Sin procesar') },
        1: { name: t('sueTableInProcess', 'En proceso') },
        2: { name: t('closed', 'Cerrado') },
    }

    const columns: ColumnTableSchema<SueModelType> = [
        {
            id: 'sueExpiredRow',
            dataKey: 'id',
            label: '',
            width: 0,
            requiredColumn: true,
            simpleLabel: '',
            customRender: (parameterValue, allRowData) => {
                const sueMonths = 2
                const initialDate = new Date(allRowData.createdAt)
                const currentDate = new Date()
                let expired = false
                const monthsDifference = (currentDate.getFullYear() - initialDate.getFullYear()) * 12 + (currentDate.getMonth() - initialDate.getMonth())
                if (monthsDifference >= sueMonths && allRowData.status !== 2) {
                    expired = true
                }
                if (expired) {
                    return <div className='sue_expired_row'></div>
                }
                return <div></div>
            }
        },
        {
            id: 'id',
            dataKey: 'id',
            label: t('id', 'ID'),
            headerClassName: 'sues_extramargin_header',
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            },
            minWidth: 300,
            customRender: (parameterValue, allRowData) => <div className={'flex items-center ' + (allRowData?.allReaded ? ' ml-20' : '')}> {allRowData?.allReaded ? null : <div className={'w-3 h-3 rounded-xl mr-10 bg-green-43'}></div>}{parameterValue}</div>,
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            customRender: (parameterValue) => <CustomRenderString value={parameterValue || t('anonimous', 'Anónimo')} />,
        },
        {
            id: 'email',
            dataKey: 'email',
            label: t('email', 'Email'),
        },
        {
            id: 'mobile',
            dataKey: 'mobile',
            label: t('mobile', 'Móvil'),
        },
        {
            id: 'status',
            dataKey: 'status',
            label: t('status', 'Estado'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentsCustomSelectSearchBar',
                extraComponentData: { items: sueStatusTypesDict, label: t('status', 'Estado') }
            },
            customRender: (parameterValue) => {
                return <RenderBuyOrderStatus value={parameterValue}  />
            },
        },
        {
            id: 'category',
            dataKey: 'category',
            label: t('category', 'Categoría'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentsCustomSelectSearchBar',
                extraComponentData: { items: sueCategoryTypesDict, label: t('category', 'Categoría') }
            },
            customRender: (parameterValue) => <CustomRenderString value={sueCategoryTypesDict[parseInt(parameterValue)].name} />,
        },
        {
            id: 'relation',
            dataKey: 'relation',
            label: t('relation', 'Relación'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentsCustomSelectSearchBar',
                extraComponentData: { items: sueRelationTypesDict, label: t('category', 'Categoría') }
            },
            customRender: (parameterValue) => <CustomRenderString value={sueRelationTypesDict[parseInt(parameterValue)].name} />,
        },
        {
            id: 'createdAt',
            dataKey: 'createdAt',
            label: t('createdAt', 'Creado el'),
            sortable: true,
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />,
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            customRender: (parameterValue, allRowData) => <EditRow key={'edit' + parameterValue} {...{ parameterValue, allRowData }} onClick={(_idk, allRowData) => setOpened({ type: 'edit', allRowData })} />,
            havePermission: permissionCheck(userPermissions, 'update')
        },

    ]
    return (
        <TwinTable  name='sues' columns={columns} getDataFrom='/api/app/sue/getAllSues' onRowClick={(_id, rowData) => setOpened({
            type: 'edit',
            allRowData: rowData
        })} setTableInstance={setTableInstance} defaultOrder={{ order: 'desc', defaultOrderField: 'createdAt' }} />
    )
}

interface RenderBuyOrderStatusProps {
    value: string
}

const RenderBuyOrderStatus: React.FC<RenderBuyOrderStatusProps> = ({ value }) => {
    const {t} = useTwinTranslation()
    const sueStatusTypesDict: TwinDictionary = {
        0: { name: t('sueTableWithoutProcessing', 'Sin procesar'), colorText: 'text-gray-2E' },
        1: { name: t('sueTableInProcess', 'En proceso'), colorText: 'text-orange-DE' },
        2: { name: t('closed', 'Cerrado'), colorText: 'text-green-43' },
    }
    const parsedValue = parseInt(value)
    return <DisplayStatusWithoutIcon colorText={sueStatusTypesDict[parsedValue].colorText} text={sueStatusTypesDict[parsedValue].name} />

}

export default Sues