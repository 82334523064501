import { TaskModelType } from '@teinor/erp/types/company/task'
import { useCallback, useEffect, useState } from 'react'
import { SlotInfo } from 'react-big-calendar'
import { ConnectedProps, connect } from 'react-redux'
import { BigTitleAndRightLayout } from '../../../../../../baseComponents/Layout/TitleAndRightLayout'
import { TwinBigCalendarEvents } from '../../../../../../baseComponents/TwinBigCalendar/types'
import TwinToolTip from '../../../../../../baseComponents/TwinToolTip'
import ModalCreateTaskWithHelper from '../../../../../../specificComponents/Task/ModalCreateTaskWithHelper'
import TaskCalendar from '../../../../../../specificComponents/Task/TaskCalendar'
import { twinFetchPostJSON } from '../../../../../../utils/globals/data'
import { getFirstAndLastDayOfThisWeek } from '../../../../../../utils/globals/date'
import { listenSocket, unlistenSocket } from '../../../../../../utils/globals/socket'
import { StartEndDateTy } from '../../../../../../utils/globals/types'
import withLoading from '../../../../../../utils/hoc/withLoading'
import useEditDeleteModal from '../../../../../../utils/hooks/useEditDeleteModal'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import { AllReduxPayloads } from '../../../../../../utils/reducers'
import { DictionaryEmployee, EmployeesPayload } from '../../../../../../utils/reducers/company/employees'
import { TaskTypePayload } from '../../../../../../utils/reducers/company/tastTypes'
import { DictionaryGroup, GroupPayload } from '../../../../../../utils/reducers/groups/groups'
import { addBottomNavAppElement } from '../../../../../../utils/reducers/reduxDispatch'
import { getStartOfWeek } from '../../../BottomIcons/UserTimeControl/PunchInUser/functions'
import TaskCalendarAdminHeader from './TaskCalendarAdminHeader'
import { taskAdminCalendarGetDefaultSelectedData } from './functions'
import { TaskAdminCalendarSelectedData } from './types'
import { hexToRgbA } from '../../../../../../utils/globals/colors'

interface TaskCalendarAdminProps extends ReduxEmployeeParams {}

const TaskCalendarAdmin: React.FC<TaskCalendarAdminProps> = ({ employees, groups  }) => {
    const { t } = useTwinTranslation()
    const { events, onRangeChange, setSelectedData, selectedData, onSelectSlot, openModal, setOpenModal, getTaskCalendarData, onSelectEvent, startWeekDate } = useTaskCalendarAdminLogic({ groups, employees })
    return (
        <BigTitleAndRightLayout title={t('tasks', 'Tasks')} RightHeader={
            <TaskCalendarAdminHeader
                setSelectedData={setSelectedData}
                selectedData={selectedData}
            />
        }>
            <TaskCalendar min={startWeekDate} events={events || undefined} onRangeChange={onRangeChange} onSelectSlot={onSelectSlot} onSelectEvent={onSelectEvent} selectable />
            {openModal?.type === 'edit' ? <ModalCreateTaskWithHelper setOpened={() => setOpenModal(null)} onSubmit={getTaskCalendarData} allRowData={openModal?.allRowData}  /> : null}
        </BigTitleAndRightLayout>
    )
}

interface TaskCalendarAdminLogic {
    groups: DictionaryGroup | null
    employees: DictionaryEmployee | null
}

const useTaskCalendarAdminLogic = ({ groups, employees }: TaskCalendarAdminLogic) => {
    const [events, setEvents] = useState<TwinBigCalendarEvents | null>(null)
    const [dates, setDates] = useState<StartEndDateTy>(getFirstAndLastDayOfThisWeek())
    const [minHours, setMinHours] = useState(8)
    const [selectedData, setSelectedData] = useState<TaskAdminCalendarSelectedData>(
        taskAdminCalendarGetDefaultSelectedData({ name: 'task_calendar_admin_filter', groups, employees })
    )
    const { openModal, setOpenModal } = useEditDeleteModal()
    const { t } = useTwinTranslation()
    const taskWithNoName = t('taskWithNoName', 'Tarea sin nombre')

    const onSelectSlot = useCallback((slotInfo: SlotInfo) => {
        setOpenModal({
            type: 'edit',
            allRowData: slotInfo
        })
    }, [setOpenModal])

    const parseDataToEvents = useCallback((data: TaskModelType[]) => {
        const eventsParsed: TwinBigCalendarEvents = []
        let minHour = 8
        for (const task of data) {
            const startDate = task.start_date ? new Date(task.start_date) : undefined
            const endDate = task.end_date ? new Date(task.end_date) : undefined
            let taskTitle = '#' + task.id + ' - ' + (task?.name || taskWithNoName)
            if (task?.Employee?.name) {
                taskTitle += ' - ' + task?.Employee?.fullname_short
            }
            if (task?.Group?.name) {
                taskTitle += ' - ' + task.Group?.name
            }

            const title = (<div className='h-full'>
                <TwinToolTip place='top' anchorSelect={'#task' + task?.id} className='max-w-600 z-1'>
                    <div>{taskTitle}</div>
                </TwinToolTip>
                <div id={'task' + task?.id} className='h-full'>{taskTitle}</div>
            </div>)
            const mainColor = (selectedData?.colors?.employees?.[task.EmployeeId] || selectedData?.colors?.groups?.[task.GroupId] || '')
            eventsParsed.push(
                {
                    id: task.id,
                    title,
                    subtitle: task.description,
                    start: startDate || endDate,
                    end: endDate || startDate,
                    color: !task.finished ? mainColor : hexToRgbA(mainColor, '0.2'),
                    borderColor: task.finished ? mainColor : '',
                    textColor: !task.finished ? '#fff' : '#515966'
                }
            )
            if (startDate && startDate.getHours() < minHour) {
                minHour = startDate.getHours()
            }
        }
        setMinHours(minHour)
        setEvents(eventsParsed)
    }, [setEvents, selectedData, taskWithNoName])

    const onSelectEvent = useCallback(async (event: any) => {
        addBottomNavAppElement({ type: 'taskModal', key: 'taskModal-' + event.id, extraData: { id: event.id, opened: true } })
    }, [])

    const onRangeChange = useCallback((range: any) => {
        if (range.length === 12) {
            const newDate = new Date(range[11])
            newDate.setDate(newDate.getDate() + 30)
            setDates({
                start: range[0],
                end: newDate
            })
        } else if (range.length === 7) {
            setDates({
                start: range[0],
                end: range[6]
            })
        } else {
            setDates({
                start: range.start,
                end: range.end
            })
        }
    }, [setDates])

    const getTaskCalendarData = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/task/getAllTaskForCalendar', {
            start_date: dates.start,
            end_date: dates.end,
            employees: selectedData.filters.employees,
            groups: selectedData.filters.groups,
            orderToParse: [['start_date', 'ASC']]
        })
        if (result) {
            parseDataToEvents(result)
        }
    }, [parseDataToEvents, dates, selectedData])

    useEffect(() => {
        getTaskCalendarData()
    }, [getTaskCalendarData])

    useEffect(() => {
        listenSocket('getAllTasks', getTaskCalendarData, true)
        return () => {
            unlistenSocket('getAllTasks')
        }
    }, [getTaskCalendarData])

    const startWeekDate = getStartOfWeek(dates.start?.toDateString())
    startWeekDate.setHours(minHours)
    
    return { events, onRangeChange, setSelectedData, selectedData, onSelectSlot, openModal, setOpenModal, getTaskCalendarData, onSelectEvent, dates, startWeekDate }
}


const employeeParamsDispatch = {
    setEmployees: (payload: EmployeesPayload) => ({ type: 'CHANGE_EMPLOYEE', payload }),
    setGroups: (payload: GroupPayload) => ({ type: 'CHANGE_GROUP', payload }),
    setTaskTypes: (payload: TaskTypePayload) => ({ type: 'CHANGE_TASKTYPE', payload }),
}

export type ReduxEmployeeParams = ConnectedProps<typeof employeeParamsConnect>
const mapEmployeeParamsConnector = (state: AllReduxPayloads) => ({ employees: state.employees, groups: state.groups, taskTypes: state.taskTypes })
const employeeParamsConnect = connect(mapEmployeeParamsConnector, employeeParamsDispatch)

const employeeParamsConnectLoading = withLoading(TaskCalendarAdmin, [{ fetchUrl: '/api/app/employee/getAllEmployees', propName: 'employees', setFunctionName: 'setEmployees' }, { fetchUrl: '/api/app/group/getAllGroupsListing', propName: 'groups', setFunctionName: 'setGroups' }, { fetchUrl: '/api/app/task/taskType/getAllTaskTypes', propName: 'taskTypes', setFunctionName: 'setTaskTypes' }])

const TaskCalendarConnect = employeeParamsConnect(employeeParamsConnectLoading)

export default TaskCalendarConnect