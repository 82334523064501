import { TwinDictionary } from '../globals/dictionary'
import useTwinTranslation from './useTwinTranslation'

const useMonthDictionary = () => {
    const { t } = useTwinTranslation()
    const months: TwinDictionary = {
        0: t('genuary', 'Enero'),
        1: t('february', 'Febrero'),
        2: t('march', 'Marzo'),
        3: t('april', 'Abril'),
        4: t('may', 'Mayo'),
        5: t('june', 'Junio'),
        6: t('july', 'Julio'),
        7: t('august', 'Agosto'),
        8: t('september', 'Septiembre'),
        9: t('october', 'Octubre'),
        10: t('november', 'Noviembre'),
        11: t('december', 'Diciembre'),
    }
    return months
}

export default useMonthDictionary
