import { EmployeeExpenseModelType } from '@teinor/erp/types/company/userInner/employee/employeeExpense'
import FormRenderer from '../../../../../../forms/FormRenderer'
import { FormRender } from '../../../../../../forms/FormRenderer/types'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import { valueOrDefaultValue } from '../../../../../../utils/globals/valueOrDefaultValue'

interface EmployeeExpenseNotEditableTotalsProps {
    expenseInstance: EmployeeExpenseModelType | null
}

const EmployeeExpenseNotEditableTotals: React.FC<EmployeeExpenseNotEditableTotalsProps> = ({ expenseInstance }) => {
    const { t } = useTwinTranslation()

    if (!expenseInstance) {
        return null
    }
    
    const fieldsTotalExpenses: FormRender<EmployeeExpenseModelType> = [
        {
            cols: 2,
            title: t('totalExpenses', 'Gastos totales'),
            elements: [
                {
                    name: 'km_amount',
                    label: t('totalKmAmount', 'Importe de Km totales'),
                    component: 'DisplayPriceField',
                    CurrencyId: expenseInstance?.CurrencyId || '',
                },
                {
                    name: 'expenses_amount',
                    label: t('addicionalTotalExpenses', 'Gastos adicionales totales'),
                    component: 'DisplayPriceField',
                    CurrencyId: expenseInstance?.CurrencyId || '',
                },
                {
                    name: 'total_amount',
                    label: t('total', 'Total'),
                    component: 'DisplayPriceField',
                    CurrencyId: expenseInstance?.CurrencyId || '',
                },
                {
                    name: 'total_amount_excluded_vat',
                    label: t('totalExcludedIRPFExpenses', 'Gastos totales excentos de IRPF'),
                    component: 'DisplayPriceField',
                    CurrencyId: expenseInstance?.CurrencyId || '',
                },
                {
                    name: 'total_amount_declarable',
                    label: t('totalDeclarableExpenses', 'Gastos totales declarables'),
                    component: 'DisplayPriceField',
                    CurrencyId: expenseInstance?.CurrencyId || '',
                },
            ]
        },
    ]
    const parsedFields = valueOrDefaultValue(fieldsTotalExpenses, {n: 1, permission: 'read'}, expenseInstance)

    return (<FormRenderer sections={parsedFields} className='mt-50' />)
}

export default EmployeeExpenseNotEditableTotals
