import { TaskCFieldModelType } from '@teinor/erp/types/company/task/taskCField'
import { TwinDictionary } from '../../globals/dictionary'

type TaskCFieldModelTypeWithDict = TaskCFieldModelType & {
    dictTaskCFieldValues : TwinDictionary
}
export interface TaskCFieldsAction {
    type: 'CHANGE_TASKCFIELD'
    payload: TaskCFieldModelType[]
}
type DictTaskCFieldTy = { [key: string]: TaskCFieldModelTypeWithDict }
export type TaskCFieldPayload = DictTaskCFieldTy | null

export default function taskCField(state: TaskCFieldPayload = null, action: TaskCFieldsAction): TaskCFieldPayload {
    switch (action.type) {
        case 'CHANGE_TASKCFIELD':
            const result: DictTaskCFieldTy = {}
            for (const taskCField of action.payload) {
                const myDictValues: TwinDictionary = {}
                if (taskCField.TaskCFieldValues){
                    for (const element of taskCField.TaskCFieldValues) {
                        myDictValues[element.id] = { ...element, shortVersion: element.name.charAt(0).toUpperCase() || '' }
                    }
                }
                result[taskCField.id] = { ...taskCField, 'dictTaskCFieldValues': { 
                    ...myDictValues, 
                    '': { value: '', name: '---', color: '#B9B8C1', TaskCFieldId: taskCField.id, order: 0, shortVersion: '-' }} 
                }
            }
            return state = result
        default:
            return state
    }
}