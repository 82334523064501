import { faBars } from '@fortawesome/pro-solid-svg-icons'
import { CurrentPagePayload } from '../../../../utils/reducers/globals/currentPage'
import { Routes } from '../../../CreateRoutering/types'
import TwinIcon from '../../../TwinIcon'
import { getChildrenMenuItems } from './functions'
import '../left_menu.sass'
import { AllReduxAppPayloads, GlobalContext } from '../../../../utils/reducers'
import { connect, ConnectedProps } from 'react-redux'
import { joinAndRemoveDuplicatedBars } from '../../../CreateRoutering/functions'
import OpenedMenuDropDown from './OpenedMenuDropDown'
import { Fragment } from 'react'
import { ModalOpenedSetTy, ModalOpenedTy } from '../../../Modal/types'

type OpenedMenuProps = HeaderOpenedMenuProps & ReduxOpenedMenuConnector & {
    routes: Routes
    opened: ModalOpenedTy
    onClick: () => void
}

const OpenedMenu: React.FC<OpenedMenuProps> = ({ currentPage, opened, routes, setOpened, onClick }) => {
    const position = getChildrenMenuItems(routes, currentPage)
    if (!opened) {
        return null
    }
    return (
        <div className='flex flex-col bg-gray-3E text-white second_left_menu'>
            {routes[position] ?
            <Fragment>
                <HeaderOpenedMenu text={routes[position].text} setOpened={setOpened} />
                    <OpenedMenuItems onClick={onClick} routes={routes[position].childs} currentPage={currentPage} parentLink={routes[position].link} />
                </Fragment>
                : null
            }
        </div>
    )
}

interface HeaderOpenedMenuProps {
    text?: string
    setOpened: ModalOpenedSetTy
}

const HeaderOpenedMenu: React.FC<HeaderOpenedMenuProps> = ({ text, setOpened}) => {
    return (
        <div className='flex justify-between items-center '>
            <h3>{text}</h3>
            <TwinIcon icon={faBars} size='lg' className='cursor-pointer text-white opacity-50' onClick={() => setOpened(null)}/>
        </div>
    )
}

interface OpenedMenuItemsProps {
    routes?: Routes
    currentPage: CurrentPagePayload
    parentLink: string
    onClick: () => void
}

const OpenedMenuItems: React.FC<OpenedMenuItemsProps> = ({ routes, currentPage, parentLink, onClick }) => {
    const renderOpenedMenuItems: JSX.Element[] = []
    if (!currentPage) {
        return null
    }
    for (const key in routes) {
        const item = routes[parseInt(key)]
        renderOpenedMenuItems.push(<OpenedMenuDropDown onClick={onClick} key={parentLink + item.link} route={item} currentPage={currentPage} parentLink={joinAndRemoveDuplicatedBars(parentLink, item.link)}/>)
    }
    return (
        <div className='mt-48 left_menu_parent overflow-auto'>
            {renderOpenedMenuItems}
        </div>
    )
}

const openedMenuDispatch = {
    setChangePage: (payload: CurrentPagePayload) => ({ type: 'CHANGE_CURRENTPAGE', payload }),
}
const mapOpenedMenuConnector = (state: AllReduxAppPayloads) => ({ currentPage: state.currentPage })
export const openedMenuConnector = connect(mapOpenedMenuConnector, openedMenuDispatch, null, { context: GlobalContext })
export type ReduxOpenedMenuConnector = ConnectedProps<typeof openedMenuConnector>

export default openedMenuConnector(OpenedMenu)