import { CustomerModelType } from '@teinor/erp/types/company/customer'
import { ComponentWithPermissions } from '../../../../../../../../../baseComponents/CreateRoutering/types'
import TwinFileSystem from '../../../../../../../../../baseComponents/TwinFileSystem'

interface CustomerFilesProps extends ComponentWithPermissions {
    basicData: CustomerModelType | null
}

const CustomerFiles: React.FC<CustomerFilesProps> = ({ userPermissions, basicData }) =>  {
    if (basicData?.id) {
        return (<TwinFileSystem subFolder={'/' + basicData.id + '/customerFolder'} type='customer' userPermissions={userPermissions}></TwinFileSystem>)
    }
    return null
}

export default CustomerFiles