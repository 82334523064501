import { faCalendarXmark } from '@fortawesome/pro-light-svg-icons'
import { HeaderElementTask } from '..'
import { ModalTaskNotShowUntil } from './ModalTaskNotShowUntil'
import { useState } from 'react'
import { ModalOpenedTy } from '../../../../../baseComponents/Modal/types'
import useTwinTranslation from '../../../../../utils/hooks/useTwinTranslation'
import { UpdateTaskTy } from '../../types'

interface HeaderTaskNotShowUntilProps {
    id: string
    updateTask: UpdateTaskTy  
    remember_date?: string
}

const HeaderTaskNotShowUntil: React.FC<HeaderTaskNotShowUntilProps> = ({ id, updateTask, remember_date }) => {
    const {t} = useTwinTranslation()
    const { opened, setOpened} = useHeaderTaskNotShowUntilLogic()
    return (
        <HeaderElementTask id={'notShow_'+ id} message={t('notShowUntil', 'No mostrar hasta...')} icon={faCalendarXmark} onClick={()=>setOpened((old)=>old? null : true)}>
            {opened ? <ModalTaskNotShowUntil {...{ setOpened, updateTask, remember_date }} /> : null}
        </HeaderElementTask>
    )
}

const useHeaderTaskNotShowUntilLogic = () => {
    const [opened, setOpened] = useState<ModalOpenedTy>(null)

    return { opened, setOpened }
}

export default HeaderTaskNotShowUntil