import { SpecificPriceRuleModelType } from '@teinor/erp/types/company/product/specificPriceRule/'
import { dictionaryComplexFromJsonArr } from '../../globals/dictionary'

export type SpecificPriceRulesDict = { [SpecificPriceRuleId: string]: SpecificPriceRuleModelType }

type SpecificPriceRules = {
    dictionary: SpecificPriceRulesDict
    arrSorted: SpecificPriceRuleModelType[]
}

export interface SpecificPriceRulesAction {
    type: 'CHANGE_SPECIFICPRICERULES'
    payload: SpecificPriceRuleModelType[]
}

export type SpecificPriceRulesPayload = SpecificPriceRules | null

export default function specificPriceRules(state: SpecificPriceRulesPayload = null, action: SpecificPriceRulesAction): SpecificPriceRules | null {
    switch (action.type) {
        case 'CHANGE_SPECIFICPRICERULES':
            return state = { dictionary: dictionaryComplexFromJsonArr(action.payload), arrSorted: action.payload }
        default:
            return state
    }
}