import { EmployeeExpenseRowModelAdd } from '@teinor/erp/types/company/userInner/employee/employeeExpense/employeeExpenseRow'
import { EmployeeExpenseRowTypeModelAdd } from '@teinor/erp/types/company/userInner/employee/employeeExpense/employeeExpenseRow/employeeExpenseRowType'
import { SetStateAction, useCallback, useState } from 'react'
import { ButtonPrimary } from '../../../../../baseComponents/Button'
import TableWithoutVirtualListing from '../../../../../baseComponents/TwinTable/TableWithoutVirtualListing'
import { CustomRenderDisplayPriceWOParams, CustomRenderDownloadFile, CustomRenderString, DeleteRow } from '../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import TwinTrans from '../../../../../baseComponents/TwinTrans'
import FormRenderer from '../../../../../forms/FormRenderer'
import { FormRender } from '../../../../../forms/FormRenderer/types'
import { dictionaryComplexFromJsonArr } from '../../../../../utils/globals/dictionary'
import useTwinTranslation from '../../../../../utils/hooks/useTwinTranslation'
import { EmployeeExpenseModelAddExtended, EmployeeExpenseModelTypeExtended, EmployeeExpenseNewRowInfoProps, EmployeeExpenseRowModelTypeExtended } from '../types'
import { calculateEmployeeExpensesTotals } from '@teinor/erp/prices/expense'


interface EmployeeExpenseTableExpensesProps extends EmployeeExpenseTableExpensesLogicProps {
    expenseInstance: EmployeeExpenseModelAddExtended | EmployeeExpenseModelTypeExtended
    expensesType: EmployeeExpenseRowTypeModelAdd[]
}

const EmployeeExpenseTableExpenses: React.FC<EmployeeExpenseTableExpensesProps> = ({ expenseInstance, setExpenseInstance, expensesType }) => {
    const { t } = useTwinTranslation()
    const { changeFieldExpenseInfo, deleteExpense, addExpense, selectedInfo } = useEmployeeExpenseTableExpensesLogic({ setExpenseInstance })
    const allExpenseTypes = dictionaryComplexFromJsonArr(expensesType)

    if (!expenseInstance) {
        return null
    }

    const columns: ColumnTableSchema<EmployeeExpenseRowModelTypeExtended> = [
        {
            id: 'EmployeeExpenseRowTypeId',
            dataKey: 'EmployeeExpenseRowTypeId',
            label: t('expenseType', 'Tipo de gasto'),
            customRender: (parameterValue) => <CustomRenderString value={allExpenseTypes?.[parameterValue]?.name} />
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
        },
        {
            id: 'amount',
            dataKey: 'amount',
            label: t('amount', 'Importe'),
            customRender: (parameterValue) => <CustomRenderDisplayPriceWOParams value={parameterValue} CurrencyId={expenseInstance?.CurrencyId || ''} />
        },
        {
            id: 'file_amount_expense_parse',
            dataKey: 'file_amount_expense_parse',
            label: t('file', 'Fichero'),
            width: 100,
            customRender: (parameterValue) => <CustomRenderDownloadFile url={parameterValue} />
        },
        {
            id: 'delete',
            dataKey: 'id',
            label: '',
            width: 50,
            customRender: (parameterValue, allRowData) => <DeleteRow key={'delete' + parameterValue} {...{ parameterValue, allRowData }} onClick={() => deleteExpense(allRowData.rowIndex)} />,
        }
    ]

    const fieldsAdditionalExpenses: FormRender<any> = [
        {
            cols: 2,
            title: t('additionalExpenses', 'Gastos adicionales'),
            elements: [
                {
                    name: 'EmployeeExpenseRowTypeId',
                    label: t('expenseType', 'Tipo de gasto'),
                    component: 'CustomSelect',
                    items: allExpenseTypes,
                    value: selectedInfo.expenseType,
                    onChange: (value) => changeFieldExpenseInfo(value, 'expenseType'),
                },
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    value: selectedInfo.name,
                    onChange: (value) => changeFieldExpenseInfo(value, 'name'),
                    defaultValue: '',
                },
                {
                    name: 'amount',
                    label: t('amount', 'Importe'),
                    component: 'InputPrice',
                    value: selectedInfo.amount,
                    CurrencyId: expenseInstance?.CurrencyId || '',
                    onChange: (value) => changeFieldExpenseInfo(value, 'amount'),
                    defaultValue: undefined
                },
            ]
        },
    ]

    if (expenseInstance?.EmployeeExpenseRows) {
        for (let i = 0; i < expenseInstance.EmployeeExpenseRows.length + 1; i++) {
            fieldsAdditionalExpenses[0].elements.push(
                {
                    name: 'file_amount_expense_' + String(i),
                    label: t('uploadfile', 'Subir fichero'),
                    component: 'FilesInput',
                    className: expenseInstance.EmployeeExpenseRows.length === i ? '' : 'hidden'
                }
            )
        }
    }

    return (
        <FormRenderer sections={fieldsAdditionalExpenses} className='mt-20 flex flex-col'>
            <ButtonPrimary className='ml-auto' onClick={addExpense} type='button'><TwinTrans transKey='add'>Añadir</TwinTrans></ButtonPrimary>
            <TableWithoutVirtualListing data={expenseInstance?.EmployeeExpenseRows || []} columns={columns} disableScroll={true} />
        </FormRenderer>)
}


interface EmployeeExpenseTableExpensesLogicProps { 
    setExpenseInstance: React.Dispatch<SetStateAction<EmployeeExpenseModelAddExtended>> | React.Dispatch<SetStateAction<EmployeeExpenseModelTypeExtended | null>>
}

const useEmployeeExpenseTableExpensesLogic = ({ setExpenseInstance }: EmployeeExpenseTableExpensesLogicProps) => {
    const [selectedInfo, setSelectedInfo] = useState<EmployeeExpenseNewRowInfoProps>({
        name: '',
        amount: 0,
        expenseType: undefined
    })

    const addExpense = useCallback(() => {
        if (selectedInfo.expenseType && selectedInfo.amount) {
            let newExpenseAdded: EmployeeExpenseRowModelAdd = { EmployeeExpenseRowTypeId: selectedInfo.expenseType, name: selectedInfo.name, amount: selectedInfo.amount }
            setExpenseInstance((old: any) => {
                const expenseInstanceCopy = JSON.parse(JSON.stringify(old))
                expenseInstanceCopy.EmployeeExpenseRows.push(newExpenseAdded)
                calculateEmployeeExpensesTotals(expenseInstanceCopy)
                return expenseInstanceCopy
            })
            setSelectedInfo({ name: '', amount: 0, expenseType: undefined })
        }
    }, [setSelectedInfo, selectedInfo, setExpenseInstance])

    const deleteExpense = useCallback((rowIndex: number) => {
        setExpenseInstance((old: any) => {
            const expenseInstanceCopy = JSON.parse(JSON.stringify(old))
            expenseInstanceCopy.EmployeeExpenseRows.splice(rowIndex, 1)
            calculateEmployeeExpensesTotals(expenseInstanceCopy)
            return expenseInstanceCopy
        })
    }, [setExpenseInstance])

    const changeFieldExpenseInfo = useCallback((value: string, field: keyof EmployeeExpenseNewRowInfoProps) => {
        setSelectedInfo((old: any) => {
            if (!old) {
                return old
            }
            return { ...old, [field]: value }
        })
    }, [setSelectedInfo])
    
    return { changeFieldExpenseInfo, deleteExpense, addExpense, selectedInfo }
}

export default EmployeeExpenseTableExpenses