import { dictionaryComplexFromJsonArr } from '../../utils/globals/dictionary'
import { SelectedFiltersTy } from '../../utils/hooks/useConfigColumns'
import { AllSearchBars, BuildedOrder, GeneralSearchBar, Order, OrderFields, SingleFieldsSearch, Where } from './types'
import { AllSearchableComponentsComponent, ColumnConfiguration, TypeSearchTy } from './VirtualTableListing/Subcomponents/types'

export const getNextOrder = (fieldName: string, orderFields: OrderFields, defaultOrder: Order) => {
    const isNotOrderedYet = !orderFields[fieldName]
    const hasOnlyBeenOrderedOnce = orderFields[fieldName] === defaultOrder
    if (isNotOrderedYet) {
        orderFields[fieldName] = defaultOrder
    } else if (hasOnlyBeenOrderedOnce) {
        const opositeOfDefaultOrder = defaultOrder === 'asc' ? 'desc' : 'asc'
        orderFields[fieldName] = opositeOfDefaultOrder
    } else {
        delete orderFields[fieldName]
    }
    return orderFields
}

export const buildWhereBySearchbars = (searchBars: AllSearchBars, columns: ColumnConfiguration[]) => {
    const whereOfGeneralSearchBar = getWhereFromGeneralSearchBar(searchBars.generalSearchbar, columns)
    const whereOfSingleFields = getWhereFromSingleFields(searchBars.singleFieldsSearch, columns)
    const whereOfFilters = getWhereFromFilters(searchBars.filtersFieldsSearch || {}, columns)
    return {
        ...whereOfGeneralSearchBar,
        ...whereOfSingleFields,
        ...whereOfFilters
    }
}

const getWhereFromGeneralSearchBar = (generalSearchbar: GeneralSearchBar, columns: ColumnConfiguration[]) => {
    let where: Where = {}
    if (generalSearchbar !== '') {
        for (const column of columns) {
            const { dataKey, searchKey} = column
            let searchField = searchKey || dataKey
            if (column.searchableComponent && column.generalSearchable !== false) {
                if (!where['$or']) {
                    where['$or'] = {}
                }
                where['$or'][searchField] = { $iLike: '%' + generalSearchbar + '%' }
            }
        }
    }
    return where
}

const getWhereFromSingleFields = (singleFieldsSearch: SingleFieldsSearch, columns: ColumnConfiguration[]) => {
    let where: Where = {}
    const dictColumns: { [id: string]: ColumnConfiguration } = dictionaryComplexFromJsonArr(columns, 'dataKey')
    for (const searchFieldKey in singleFieldsSearch) {
        const column = dictColumns[searchFieldKey]
        const typeSearch = getSelectorTypeByColumn(column)
        const searchFieldValue = singleFieldsSearch[searchFieldKey]
        const parsedSearchFieldKey = column.searchSingleKey || column.searchKey || searchFieldKey
        if (searchFieldValue) {
            if (typeSearch === 'selector'){
                where[parsedSearchFieldKey] = searchFieldValue 
            } else {
                where[parsedSearchFieldKey] = { $iLike: '%' + searchFieldValue + '%' }
            }
        }
    }
    return where
}

const getWhereFromFilters = (selectedFilters: SelectedFiltersTy, columns: ColumnConfiguration[]) => {
    let where: Where = {}
    const dictColumns: { [id: string]: ColumnConfiguration } = dictionaryComplexFromJsonArr(columns)
    for (const searchFieldKey in selectedFilters) {
        const singleFilter = selectedFilters[searchFieldKey]
        const column = dictColumns[searchFieldKey]
        const typeSearch = getSelectorTypeByColumn(column)
        const searchFieldValue = singleFilter.value
        const parsedSearchFieldKey = column.searchKey || column.dataKey
        const excluded = singleFilter.excluded || false
        if (searchFieldValue) {
            if (typeSearch === 'selector') {
                if (excluded) {
                    where[parsedSearchFieldKey] = { $notIn: searchFieldValue }
                } else {
                    where[parsedSearchFieldKey] = { $in: searchFieldValue }
                }
            } else {
                if (Array.isArray(searchFieldValue)) {
                    let myOrAnd = []
                    for (const element of searchFieldValue) {
                        myOrAnd.push(getValueOfSingleFilterForWriteFilter(element, excluded))
                    }
                    if (excluded) {
                        where[parsedSearchFieldKey] = { $and: myOrAnd }
                    } else {
                        where[parsedSearchFieldKey] = { $or: myOrAnd }
                    }
                } else {
                    where[parsedSearchFieldKey] = getValueOfSingleFilterForWriteFilter(searchFieldValue, excluded)
                }
            }
        }
    }
    return where
}

const getValueOfSingleFilterForWriteFilter = (value: string, excluded: boolean) => {
    let operator = '%'
    if (value.includes('*')) {
        operator = ''
        value = value.replaceAll('*', '%')
    }
    if (excluded) {
        return { $notILike: operator + value + operator }
    }
    return { $iLike: operator + value + operator }
}

export const getSelectorTypeByColumn = (column: ColumnConfiguration): TypeSearchTy => {
    const columnSearchableComponent = column.searchableComponent?.component
    const typeColumn: Record<AllSearchableComponentsComponent, TypeSearchTy> = {
        SearchableComponentBasic: 'like',
        SearchableComponentsCustomSelect: 'selector',
        SearchableComponentsCustomSelectSearchBar: 'selector',
        SearchableComponentsCustomSelectSingle: 'singleSelector',
    }
    if (columnSearchableComponent) {
        return typeColumn[columnSearchableComponent]
    }
    return 'like'
}

export const buildOrder = (orderFields: OrderFields, columns: ColumnConfiguration[]) => {
    const buildedOrder: BuildedOrder = []
    const dictColumns: { [id: string]: ColumnConfiguration } = dictionaryComplexFromJsonArr(columns)
    for (const orderFieldKey in orderFields) {
        const myOrderFieldKey = dictColumns?.[orderFieldKey]?.sortKey || orderFieldKey
        const orderFieldValue = orderFields[orderFieldKey]
        buildedOrder.push([myOrderFieldKey, orderFieldValue])
    }
    return buildedOrder
}

export const saveTableData = (tableData: any, offset: number, setTableData: React.Dispatch<React.SetStateAction<{ [fieldName: string]: any }[] | null>>) => {
    if (tableData && Object.keys(tableData).length) {
        if (offset > 0) {
            setTableData((oldTableData) => { if (oldTableData) { return [...oldTableData, ...tableData] } else { return [...tableData]} })
        } else {
            setTableData([...tableData])
        }
    } else if (offset === 0) {
        setTableData([])
    }
}