import { dictionaryComplexFromJsonArr } from '../../globals/dictionary'
import { BillingRetentionModelType } from '@teinor/erp/types/company/config/billingGroup/billingRetention'

export interface BillingRetentionsAction {
    type: 'CHANGE_BILLINGRETENTION'
    payload: BillingRetentionModelType[]
}
export type DictionaryBillingRetention = { [id: number]: BillingRetentionModelType }
export type BillingRetentionPayload = DictionaryBillingRetention | null

export default function billingRetentions(state: BillingRetentionPayload = null, action: BillingRetentionsAction): DictionaryBillingRetention | null {
    switch (action.type) {
        case 'CHANGE_BILLINGRETENTION':
            const dictionaryBillingRetention: DictionaryBillingRetention = dictionaryComplexFromJsonArr(action.payload || [])
            return state = dictionaryBillingRetention
        default:
            return state
    }
}