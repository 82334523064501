import { faTimesSquare } from '@fortawesome/pro-light-svg-icons'
import TwinIcon from '../../baseComponents/TwinIcon'
import './listAdder.sass'
import { InputHidden, InputWithLabel } from '../Input'
import { ButtonPrimary } from '../../baseComponents/Button'
import TwinTrans from '../../baseComponents/TwinTrans'
import { Fragment, useCallback, useEffect, useState } from 'react'
export interface ListAdderStateLessProps extends ListAdderStateLessLogicProps {
    items: string[]
    label: string
    className?: string
}

export const ListAdderStateLess: React.FC<ListAdderStateLessProps> = ({ label, items, className, ...logic }) => {
    const { handleItem, addItems, deleteItem, inputValue } = useListAdderStateLessLogic({ items, ...logic})
    const renderThis: JSX.Element[] = []
    for (const k in items) {
        const item = items[k]
        if (item !== '') {
            renderThis.push(<ListAdderItem key={k} item={item} onDelete={() => deleteItem(parseInt(k))} />)
        }
    }
    return (
        <div className={'list_adder_parent ' + (className || '')}>
            <div className='flex my-20'>
                <InputWithLabel label={label} value={inputValue} onChange={handleItem} />
                <ButtonPrimary className='ml-20' onClick={() => addItems(inputValue)} type='button'>
                    <TwinTrans transKey={'add'}>Añadir</TwinTrans>
                </ButtonPrimary>
            </div>
            {items.length !== 0?
                <div className={'border flex flex-wrap content-start list_adder'}>
                    {renderThis}
                </div>
                : null
            }
        </div>
    )
}


interface ListAdderStateLessLogicProps {
    items: string[]
    onChange: (value: string[]) => void
    checkValid?: (value: string) => boolean
}

const useListAdderStateLessLogic = ({ items, onChange, checkValid }: ListAdderStateLessLogicProps) => {
    const [inputValue, setInputValue] = useState<string >('')

    const addItems = useCallback((word: string) => {
        if (word) {
            setInputValue('')
            if(!items.includes(word)){
                onChange([...items, word])
            }
        }
    }, [onChange, setInputValue, items])

    const handleItem = useCallback((value: string) => {
        if(checkValid){
            if(checkValid(value)){
                setInputValue(value)
            }
        }else{
            setInputValue(value)
        }
    }, [setInputValue, checkValid])
    const deleteItem = useCallback((key: number) => {
        const copy = JSON.parse(JSON.stringify(items))
        copy.splice(key, 1)
        onChange([...copy])
    }, [onChange, items])

    return { addItems, handleItem, inputValue, deleteItem }
}


interface ListAdderItemsProps {
    item: string
    onDelete: () => void
}

const ListAdderItem: React.FC<ListAdderItemsProps> = ({item, onDelete}) => {
    return (
        <div className='list_adder_item flex justify-around items-center bg-gray-F7 mt-15 ml-15'>
            <span>{item}</span>
            <TwinIcon icon={faTimesSquare} className=' ml-20 cursor-pointer hover:text-red-BA' onClick={onDelete} />
        </div>
    )
}

export interface ListAdderStateFullProps extends ListAdderStateLessProps {
    name?: string
    parseValue?: (items: string[]) => string
}

export const ListAdderStateFull: React.FC<ListAdderStateFullProps> = ({name, parseValue, ...rest}) => {
    const { myItems, myOnChange, myParseValue } = useListAdderStateFullLogic({...rest})
    return (
        <Fragment>
            <ListAdderStateLess {...rest} items={myItems} onChange={myOnChange}/>
            {name && <InputHidden name={name} value={parseValue ? parseValue(myItems) : myParseValue()} />}
        </Fragment>
    )
}

interface ListAdderStateFullLogicProps extends ListAdderStateLessLogicProps {}

const useListAdderStateFullLogic = ({items, onChange}: ListAdderStateFullLogicProps) => {
    const [myItems, setMyItems] = useState<string[]>(items)
    const myOnChange = useCallback((receivedItems: string[]) => {
        setMyItems(receivedItems)
        onChange(receivedItems)
    }, [onChange])

    const myParseValue = useCallback(() => {
        return myItems.join(';')
    }, [myItems])

    useEffect(() => {
        setMyItems(items)
    }, [items])
    return { myItems, setMyItems, myOnChange, myParseValue }
}

