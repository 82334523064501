import { SetStateAction, useCallback, useEffect, useState } from 'react'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import { ButtonDisabledOrLoader } from '../../../../../Button'
import { ModalLittle } from '../../../../../Modal'
import { ModalOpenedTy } from '../../../../../Modal/types'
import TwinTrans from '../../../../../TwinTrans'
import { twinFetchPostJSON, twinFetchPostText } from '../../../../../../utils/globals/data'
import { TwinDictionary, dictionaryComplexFromJsonArrCodeName } from '../../../../../../utils/globals/dictionary'
import { setUserCookie } from '../../../../../../utils/reducers/reduxDispatch'
import jscookie from 'js-cookie'
import { CustomSelectWithSearchBar } from '../../../../../../forms/CustomSelect'
import { AllReduxAppPayloads, GlobalContext } from '../../../../../../utils/reducers'
import { ConnectedProps, connect } from 'react-redux'
import { CompanyPayload } from '../../../../../../utils/reducers/globals/company'
import useIsLoading from '../../../../../../utils/hooks/useIsLoading'

type ModalSelectorCompanyManagerProps = ModalSelectorCompanyManagerLogicProps & ReduxModalSelectorCompanyManagerConnector & {
    opened: ModalOpenedTy
    setOpened: React.Dispatch<SetStateAction<ModalOpenedTy>>
}

const ModalSelectorCompanyManager: React.FC<ModalSelectorCompanyManagerProps> = ({ opened, setOpened, company }) => {
    const { t } = useTwinTranslation()
    const { myCompanies, handleChangeCompany, changeSelectedCompany, selectedCompany, loading } = useModalSelectorCompanyManagerLogic({ company })
    return (
        <ModalLittle {...{ setOpened, opened }} className='text-gray-51'>
            <div className='text-center'>
                <h2><TwinTrans transKey='wishChangeCompany'>¿Desea cambiar de empresa?</TwinTrans></h2>
                <span className='mt-20 mb-40 block'><TwinTrans transKey='selectCompanyToContinue'>Seleccione una empresa para continuar</TwinTrans></span>
                <CustomSelectWithSearchBar label={t('company', 'Empresa')} onChange={changeSelectedCompany} items={myCompanies || {}} value={selectedCompany} />
                <ButtonDisabledOrLoader className='w-full mt-40' onClick={handleChangeCompany} textButton={t('confirm', 'Confirmar')} {...{ loading }} />
            </div>
        </ModalLittle>

    )
}

interface ModalSelectorCompanyManagerLogicProps {
    company: CompanyPayload
}

const useModalSelectorCompanyManagerLogic = ({ company }: ModalSelectorCompanyManagerLogicProps) => {
    const [myCompanies, setMyCompanies] = useState<TwinDictionary | null>(null)
    const [selectedCompany, setSelectedCompany] = useState<string>(company?.token || '')
    const { startLoading, loading } = useIsLoading()

    const changeSelectedCompany = useCallback((value: string) => {
        setSelectedCompany(value)
    }, [setSelectedCompany])

    const getUserCompanies = useCallback(async () => {
        const companies = await twinFetchPostJSON('/api/company/getMyCompanies', {})
        const dictCompanies = dictionaryComplexFromJsonArrCodeName(companies, 'token', 'name', 'id_number')
        setMyCompanies(dictCompanies)
    }, [])
    const handleChangeCompany = useCallback(async () => {
        startLoading()
        const token = await twinFetchPostText('/api/user/setCompanyToUser', { token: selectedCompany })
        const cookie = JSON.parse(jscookie.get('twin_jwt_user') || '')
        const remember = cookie.remember ?? false
        await setUserCookie({ token, type: 'erp', remember }, remember)
        window.location.reload()

    }, [selectedCompany, startLoading])

    useEffect(() => {
        getUserCompanies()
    }, [getUserCompanies])
    return { myCompanies, handleChangeCompany, changeSelectedCompany, selectedCompany, loading }
}

const modalSelectorCompanyManagerDispatch = {
    setCompany: (payload: CompanyPayload) => ({ type: 'CHANGE_COMPANY', payload }),
}
const mapModalSelectorCompanyManagerConnector = (state: AllReduxAppPayloads) => ({ company: state.company })
const modalSelectorCompanyManagerConnector = connect(mapModalSelectorCompanyManagerConnector, modalSelectorCompanyManagerDispatch, null, { context: GlobalContext })
type ReduxModalSelectorCompanyManagerConnector = ConnectedProps<typeof modalSelectorCompanyManagerConnector>
const ModalSelectorCompanyManagerConnected = modalSelectorCompanyManagerConnector(ModalSelectorCompanyManager)


export default ModalSelectorCompanyManagerConnected