import { TwinDictionary } from '../../globals/dictionary'

interface Variant {
    name: string
    values: VariantValues
}
export type Variants = { [VariantId: string]: Variant }
export type VariantValues =  { [VariantValueId: string] : string}

export interface VariantsAction {
    type: 'CHANGE_VARIANTS'
    payload: Variants | null
}

interface VariantsParsed {
    variants: Variants,
    values: VariantValues
    orderedValuesJson: TwinDictionary
    orderedGroupsJson: TwinDictionary
}

export type VariantsPayload = VariantsParsed | null

export default function variants(state: VariantsPayload = null, action: VariantsAction): VariantsParsed | null {
    switch (action.type) {
        case 'CHANGE_VARIANTS':
            const orderedVals: any = action.payload?.orderValues
            const orderedGroups: any = action.payload?.orderGroups
            delete action.payload?.orderValues
            delete action.payload?.orderGroups
            let values = {}
            if (action.payload) {
                for (const key in action.payload) {
                    values = { ...values, ...action.payload[key].values }
                }
            }
            return state = { variants: action.payload || {}, values, orderedValuesJson: orderedVals, orderedGroupsJson: orderedGroups  }
        default:
            return state
    }
}