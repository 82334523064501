import { ChangeEvent, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import './textarea.sass'

export type TextAreaProps = TextAreaLogicProps & {
    value?: string
    className?: string
    name?: string
    label?: string
    placeholder?: string
    onBlur?: React.FocusEventHandler<HTMLTextAreaElement>
    readOnly?: boolean
    subRef?: any
    maxRows?: number
    required?: boolean
}

const TextArea: React.FC<TextAreaProps> = ({ label, value, className, onChange, placeholder, onBlur, readOnly = false, skipTimeout, subRef, maxRows = 6, ...rest }) => {
    const { myOnChange, isLoaded } = useTextAreaLogic({ onChange, skipTimeout })
    return (
        <div className={'flex flex-col justify-end items-start custom_textarea_parent ' + (className || '')}>
            <span>{label} {rest.required ? '*' : ''}</span>
            {isLoaded ? <TextareaAutosize ref={subRef} onBlur={onBlur} onChange={myOnChange} placeholder={placeholder} maxRows={maxRows} className={'custom_textarea'} value={value} {...rest} /> : null}
        </div>
    )
}

export interface RectangularTextAreaProps extends TextAreaProps {
    subRef?: any
}

export const RectangularTextArea: React.FC<RectangularTextAreaProps> = ({ label, value, onChange, placeholder, onBlur, readOnly = false,  className, skipTimeout, subRef, maxRows = 6, ...rest }) => {
    const { myOnChange, isLoaded } = useTextAreaLogic({ onChange, skipTimeout })
    return (
        <div className={'rectangular_textarea_parent flex flex-col justify-end items-start border border-gray-D6 px-6 py-2 max-w-full ' + (className || '')}>
            <span>{label}</span>
            {isLoaded !== 1 ? <TextareaAutosize ref={subRef} onBlur={onBlur} onChange={myOnChange} placeholder={placeholder} maxRows={maxRows} className={'rectangular_textarea resize-none border-0 w-full h-0'} value={value} {...rest} /> : null}
        </div>
    )
}

interface TextAreaLogicProps {
    onChange?: (value: string) => void
    skipTimeout?: boolean
}

const useTextAreaLogic = ({ onChange, skipTimeout = false }: TextAreaLogicProps) => {
    const [isLoaded, setIsLoaded] = useState(skipTimeout ? 2 : 0)

    const myOnChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
        onChange?.(e.currentTarget.value)
    }, [onChange])

    useLayoutEffect(() => {
        setIsLoaded(1)
        setTimeout(() => {
            setIsLoaded(2)
        }, 325)
    }, [])
    
    return { myOnChange, isLoaded}
}

type TextAreaDebounceProps = TextAreaProps & TextAreaDebounceLogicProps & {}

export const TextAreaDebounce: React.FC<TextAreaDebounceProps> = ({ value, onChange, ...rest }) => {
    const { changeMyValue, myValue, ref } = useTextAreaDebounceLogic({ value, onChange })
    return (
        <TextArea {...rest} value={myValue} onChange={changeMyValue} subRef={ref} />
    )
}
interface TextAreaDebounceLogicProps {
    value: string
    onChange: (value: string) => void
}

const useTextAreaDebounceLogic = ({ value, onChange }: TextAreaDebounceLogicProps) => {
    const [myValue, setMyValue] = useState(value)
    const [haveToTrigger, setHaveToTrigger] = useState<boolean>(false)
    const ref = useRef<any>(null)
    const changeMyValue = useCallback((changedValue: string) => {
        setHaveToTrigger(true)
        setMyValue(changedValue)
    }, [setMyValue, setHaveToTrigger])

    useEffect(() => {
        if (haveToTrigger !== false) {
            let id = setInterval(() => { 
                if(ref.current){
                    if(ref.current.value === myValue){
                        onChange(ref.current.value); 
                        setHaveToTrigger(false)
                    }
                }else{
                    onChange(ref.current.value);
                    setHaveToTrigger(false)
                }
            }, 500)
            return () =>  clearInterval(id)
        }
    }, [haveToTrigger, onChange, myValue])


    useEffect(() => {
        setMyValue(value)
    }, [value])

    return { myValue, changeMyValue, ref }
}

type RectangularTextAreaDebounceProps = RectangularTextAreaProps & TextAreaDebounceLogicProps & {}

export const RectangularTextAreaDebounce: React.FC<RectangularTextAreaDebounceProps> = ({ value, onChange, ...rest }) => {
    const { changeMyValue, myValue, ref } = useTextAreaDebounceLogic({ value, onChange })
    return (
        <RectangularTextArea {...rest} value={myValue} onChange={changeMyValue} subRef={ref} />
    )
}


export default TextArea