import Dashboard from '../../../../../baseComponents/Dashboard'


const CompanyDashboard: React.FC = () => {
    const props = {
        name: 'dashboard_distribution',
        defaultWidgets: JSON.stringify([{ "cols": 1, "rows": 2, "name": "sue_status_expired" }, { "cols": 1, "rows": 2, "name": "sue_status_informed" }, { "cols": 2, "rows": 4, "name": "sue_bar_chart" }, { "cols": 1, "rows": 2, "name": "sue_status_processed" }, { "cols": 1, "rows": 2, "name": "sue_status_closed" }])
    }
    return (<Dashboard {...props} />)
}

export default CompanyDashboard