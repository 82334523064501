import ModalDeleteFilled from '../../../../../../../../baseComponents/ModalsLayouts/ModalDeleteFilled'
import { ModalComponentProps } from '../../../../../../../../baseComponents/ModalsLayouts/types'
import { useFetchText } from '../../../../../../../../utils/hooks/useFetchText'
import useTwinTranslation from '../../../../../../../../utils/hooks/useTwinTranslation'

interface ModalDeleteWCSpecialDaysProps extends ModalComponentProps {
    id?: number
}

const ModalDeleteWCSpecialDays: React.FC<ModalDeleteWCSpecialDaysProps> = ({ setOpened, onSubmit, id }) => {
    const { t } = useTwinTranslation()
    const { handleFetchText } = useFetchText({
        onSubmit,
        url: '/api/app/workingCalendar/workingCalendarSpecial/deleteWorkingCalendarSpecialDay',
        data: {
            id
        }
    })
    return (
        <ModalDeleteFilled opened={true} setOpened={setOpened} onCancel={() => setOpened(null)} onAccept={handleFetchText} translations={{
            title: t('sureDeleteWorkingCalendarSpecialDays', '¿Seguro quieres eliminar este día especial?'),
            subtitle: t('onceDeletedNotRecoverM', 'Una vez eliminado no podrás recuperarlo')
        }} />
    )
}

export default ModalDeleteWCSpecialDays
