import { faChevronLeft, faChevronRight, faRedo } from '@fortawesome/pro-light-svg-icons'
import { useCallback, useState } from 'react'
import TwinIcon from '../../baseComponents/TwinIcon'
import { parseStringArray } from '../../utils/globals/array'
import { TwinDictionary } from '../../utils/globals/dictionary'
import { getNotChangingValuesOnPreSelected, separateOptionsBySelected } from './functions'
import { MiddleIconsButton, MiddleIconsDoubleIcon, SingleColumnSelector } from './Subcomponents'
import { MultipleCbxSelectorValues, PreselectedMultipleCbxSelector, SubtitleMultipleCbxSelector } from './types'
import './multiple-selector.sass'
import { Modify } from '../../utils/globals/types'


export type MultipleCheckboxSelectorProps = Modify<MultipleCheckboxSelectorStateLessProps, MultipleCheckboxSelectorLogicProps & {
    selectedValues?: never
}>

const MultipleCheckboxSelector: React.FC<MultipleCheckboxSelectorProps> = ({ defaultValue, onChange, ...rest }) => {
    const { mySelectedValues, myOnChange } = useMultipleCheckboxSelectorLogic({ defaultValue, onChange })
    return (
        <MultipleCheckboxSelectorStateLess {...rest} selectedValues={mySelectedValues} onChange={myOnChange} />
    )
}
    
interface MultipleCheckboxSelectorLogicProps {
    defaultValue?: MultipleCbxSelectorValues
    onChange?: (values: MultipleCbxSelectorValues) => void
}

const useMultipleCheckboxSelectorLogic = ({ defaultValue, onChange }: MultipleCheckboxSelectorLogicProps) => {
    const [mySelectedValues, setMySelectedValues] = useState(parseStringArray(defaultValue || []))
    const myOnChange = useCallback((values: MultipleCbxSelectorValues) => {
        setMySelectedValues(values)
        onChange?.(values)
    }, [onChange])
    return {myOnChange, mySelectedValues}
}

export interface MultipleCheckboxSelectorStateLessProps extends MultipleCheckboxSelectorStateLessLogicProps{
    subtitles?: SubtitleMultipleCbxSelector
    name?: string
    className?: string
    keyName?: string
}

export const MultipleCheckboxSelectorStateLess: React.FC<MultipleCheckboxSelectorStateLessProps> = ({ subtitles, name, className, keyName, ...rest }) => {
    const { options, selectedValues, swapPreselectedValue, preSelected, swapToSelectedValues, handleResetPreSelected, handleAllPreselected} = useMultipleCheckboxSelectorStateLessLogic(rest)
    return (
        <div className={'flex flex-col ' + (className || '')}>
            {name && <input type='hidden' name={name} value={selectedValues.join(';')} readOnly twin-type='multiple-checkbox-selector' />}
            <div className='multiple_selector' id={'multiple_selector-'+name}>
                <SingleColumnSelector subtitle={subtitles?.subtitleOptions} options={options.unSelected} onChangeAll={(value) => handleAllPreselected(value, options.unSelected, 'unSelected')} onChange={(value) => swapPreselectedValue(value, 'unSelected')} values={preSelected.unSelected} keyName={keyName} />
                <div className='middle_icons_button_container'>
                    <MiddleIconsDoubleIcon icon={faChevronRight} onClick={() => swapToSelectedValues('unSelected')} />
                    <MiddleIconsDoubleIcon icon={faChevronLeft} onClick={() => swapToSelectedValues('selected')} />
                    <MiddleIconsButton onClick={() => handleResetPreSelected()}><TwinIcon icon={faRedo} /></MiddleIconsButton>
                </div>
                <SingleColumnSelector subtitle={subtitles?.subtitleSelectedOptions} options={options.selected} onChangeAll={(value) => handleAllPreselected(value, options.selected, 'selected')} onChange={(value) => swapPreselectedValue(value, 'selected')} values={preSelected.selected} keyName={keyName} />
            </div>
        </div>
    )
}
    
const resetPreSelected: PreselectedMultipleCbxSelector = {
    unSelected: [],
    selected: []
}
interface MultipleCheckboxSelectorStateLessLogicProps {
    selectedValues: MultipleCbxSelectorValues
    items: TwinDictionary
    onChange?: (values: MultipleCbxSelectorValues) => void
}
const useMultipleCheckboxSelectorStateLessLogic = ({ items, selectedValues, onChange }: MultipleCheckboxSelectorStateLessLogicProps) => {
    const [preSelected, setPreselected] = useState(resetPreSelected)

    const handleAllPreselected = useCallback((value: boolean, items: TwinDictionary, type: 'selected' | 'unSelected') => {
        setPreselected((old)=> {
            const result = value ? Object.keys(items) : []
            return { ...old, [type]: result }
        })
    }, [setPreselected])

    const swapPreselectedValue = useCallback((value: string, type: keyof PreselectedMultipleCbxSelector) => {
        setPreselected((oldPreselected) => {
            const myOldPreselected = JSON.parse(JSON.stringify(oldPreselected))
            const newType = myOldPreselected[type]
            const position = newType.indexOf(value)
            if (position !== -1) {
                newType.splice(position, 1)
            } else {
                newType.push(String(value))
            }
            return {
                ...myOldPreselected,
                [type]: newType
            }
        })
    }, [setPreselected])

    const swapToSelectedValues = useCallback(async (type: keyof PreselectedMultipleCbxSelector ) => {
        if (type === 'unSelected') {
            const onChangeResult: MultipleCbxSelectorValues = [...selectedValues, ...preSelected.unSelected]
            onChange?.(onChangeResult)
        } else {
            const onChangeResult: MultipleCbxSelectorValues = getNotChangingValuesOnPreSelected(selectedValues, preSelected)
            onChange?.(onChangeResult)
        }
        setPreselected((oldPreselected) => {
            return {
                ...oldPreselected,
                [type]: resetPreSelected[type]
            }
        })
    }, [preSelected, selectedValues, onChange])

    const handleResetPreSelected = useCallback(() => {
        setPreselected(resetPreSelected)
    }, [setPreselected])

    const options = separateOptionsBySelected(items, selectedValues)

    return { options, preSelected, swapPreselectedValue, swapToSelectedValues, handleResetPreSelected, handleAllPreselected, selectedValues }
}


export default MultipleCheckboxSelector