import { SueModelType } from '@teinor/erp/types/company/sue'
import { useCallback } from 'react'
import { ModalEditComponentProps } from '../../../../../../../../baseComponents/ModalsLayouts/types'
import TwinTrans from '../../../../../../../../baseComponents/TwinTrans'
import FormRenderer from '../../../../../../../../forms/FormRenderer'
import { FormElement, FormRender } from '../../../../../../../../forms/FormRenderer/types'
import { OnSubmit } from '../../../../../../../../forms/TwinForm/types'
import { twinFetchPostJSON } from '../../../../../../../../utils/globals/data'
import { displayDate } from '../../../../../../../../utils/globals/date'
import { TwinDictionary } from '../../../../../../../../utils/globals/dictionary'
import { returnClassOfSpanTemplate } from '../../../../../../../../utils/globals/tailwind'
import { valueOrDefaultValue } from '../../../../../../../../utils/globals/valueOrDefaultValue'
import useTwinTranslation from '../../../../../../../../utils/hooks/useTwinTranslation'
import SueFiles from './SueFiles'
import { SueTypeExtendedModalEdit } from './types'

type MainEditSueProps = ModalEditComponentProps & MainEditSueLogicProps & {
    sueData: SueTypeExtendedModalEdit
}

const MainEditSue: React.FC<MainEditSueProps> = ({ userPermissions, sueData, getSueData, allRowData, onSubmit}) => {
    const { t } = useTwinTranslation()
    const { onChangeStatus } = useMainEditSueLogic({ id: sueData?.id || '', getSueData, onSubmit })

    const sueStatusTypes = {
        0: { name: t('sueTableWithoutProcessing', 'Sin procesar') },
        1: { name: t('sueTableInProcess', 'En proceso') },
        2: { name: t('closed', 'Cerrado') },
    }

    const sueRelationTypes: TwinDictionary = {
        0: {name: t('employee', 'Empleado')},
        1: {name: t('customer', 'Cliente')},
        2: {name: t('supplier', 'Proveedor')},
        3: {name: t('other', 'Otro')},
    }
    const sueCategoryTypes: TwinDictionary = {
        0: {name: t('fraud', 'Fraude')},
        1: {name: t('robbery', 'Robo')},
        2: {name: t('misuseOfInformation', 'Mal uso de la información')},
        3: {name: t('workplaceHarassment', 'Acoso laboral')},
        4: {name: t('sexualHarassment',  'Acoso sexual')},
        5: {name: t('immoralBehavior', 'Comportamiento inmoral')},
        6: {name: t('other', 'Otro')}
    }
    const whoSued: FormElement<keyof SueModelType>[] = []
    if(sueData?.anonimous){
        whoSued.push({
            name: 'anonimous',
            component: 'TextField',
            label: t('createdBy', 'Creado por'),
            value: t('anonimous', 'Anónimo')
        })
    } else {
        whoSued.push(
            {
                name: 'name',
                component: 'TextField',
                label: t('name', 'Nombre'),
            },
            {
                name: 'email',
                component: 'TextField',
                label: t('email', 'Email'),
            },
            {
                name: 'mobile',
                component: 'TextField',
                label: t('mobile', 'Móvil'),
            },
        )
    }
    const fields: FormRender<SueModelType> = [
        {
            cols: 3,
            elements: [
                {
                    name: 'status',
                    label: t('status', 'Estado'),
                    component: 'CustomSelect',
                    items: sueStatusTypes,
                    onChange: onChangeStatus,
                    required: true,
                },
                ...whoSued,
                {
                    name: 'createdAt',
                    component: 'TextField',
                    label: t('date', 'Fecha'),
                    value: displayDate(new Date(allRowData?.createdAt))
                },
                {
                    name: 'category',
                    component: 'TextField',
                    label: t('category/Type', 'Categoría / Tipo'),
                    value: sueCategoryTypes[parseInt(String(allRowData?.category))].name
                },
                {
                    name: 'relation',
                    component: 'TextField',
                    label: t('relationCompany', 'Relación empresa'),
                    value: sueRelationTypes[parseInt(String(allRowData?.relation))].name
                },
                {
                    name: 'location',
                    component: 'TextField',
                    label: t('placeOfTheScene', 'Lugar de los hechos'),
                    className: returnClassOfSpanTemplate(3)
                },
                {
                    name: 'sue',
                    component: 'TextField',
                    label: t('sue', 'Denuncia'),
                    className: returnClassOfSpanTemplate(3)
                },
                {
                    name: 'measureApplied',
                    component: 'TextField',
                    label: t('measureApplied', 'Medida aplicada'),
                    className: returnClassOfSpanTemplate(3)
                },
                {
                    name: 'id',
                    component: 'InputHidden',
                },
            ]
        }
    ]
    if (sueData && userPermissions){
        const parsedFields = valueOrDefaultValue(fields, userPermissions, sueData)
        return (
            <div className='flex flex-col flex-auto'>
                <div className='overflow-auto flex flex-auto flex-col h-1'>
                    <div className='mb-25'>
                        <h2 className={sueData.expired ? 'text-red-BA' : ''}>
                            {sueData.expired ? <TwinTrans transKey='mainInformationSueExpired'>Información principal - Denuncia fuera de plazo</TwinTrans> : <TwinTrans transKey='mainInformation'>Información principal</TwinTrans> }
                        </h2>
                    </div>
                    <FormRenderer sections={parsedFields} className='grid_mincontent' />
                    <div className='my-25'>
                        <h2>
                            <TwinTrans transKey='attachedFiles'>Archivos adjuntos</TwinTrans>
                        </h2>
                    </div>
                    <SueFiles sueFiles={sueData.SueFiles} />
                </div>
            </div >
        )
    }
    return null 
}

interface MainEditSueLogicProps {
    id: string
    getSueData: () => Promise<void>
    onSubmit: OnSubmit
}

const useMainEditSueLogic = ({ id, getSueData, onSubmit }: MainEditSueLogicProps) => {
    const onChangeStatus = useCallback(async(status: number) => {
        const res = await twinFetchPostJSON('/api/app/sue/updateSue', {id, status })
        await getSueData()
        await onSubmit(res, {})
    }, [id, getSueData, onSubmit])
    return { onChangeStatus }
}

export default MainEditSue