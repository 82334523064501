import { CustomerModelType } from '@teinor/erp/types/company/customer'
import { useLocation } from 'react-router'
import { ComponentWithPermissions } from '../../../../../../baseComponents/CreateRoutering/types'
import { permissionCheck } from '../../../../../../baseComponents/PermissionChecker/function'
import { TwinTableLink } from '../../../../../../baseComponents/TwinTable'
import BaseScreenTable from '../../../../../../baseComponents/TwinTable/BaseScreenTable'
import { TableComponentProps } from '../../../../../../baseComponents/TwinTable/BaseScreenTable/types'
import { EditRow, DeleteRow } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import ModalCreateCustomerB2B from './ModalCreateCustomerB2B'


interface CustomersB2BProps extends ComponentWithPermissions { }

const CustomersB2B: React.FC<CustomersB2BProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()

    const pageData = {
        title: t('customersB2B', 'Clientes B2B'),
        newText: t('newCustomerB2B', 'Nuevo cliente B2B'),
    }

    return (
        <BaseScreenTable TableComponent={TableCustomers} CreateModalComponent={ModalCreateCustomerB2B} pageData={pageData} userPermissions={userPermissions} />
    )
}

interface TableCustomersProps extends TableComponentProps { }

const TableCustomers: React.FC<TableCustomersProps> = ({ userPermissions, setTableInstance, setOpened }) => {
    const { t } = useTwinTranslation()
    const location = useLocation().pathname
    const columns: ColumnTableSchema<CustomerModelType> = [
        {
            id: 'code',
            dataKey: 'code',
            label: t('code', 'Código'),
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('customerCode', 'Código de cliente') }
            }
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            }
        },
        {
            id: 'fiscal_name',
            dataKey: 'fiscal_name',
            label: t('fiscalName', 'Nombre Fiscal'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('fiscalName', 'Nombre Fiscal') }
            }
        },
        {
            id: 'email',
            dataKey: 'email',
            label: t('email', 'Email'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            }
        },
        {
            id: 'phone',
            dataKey: 'phone',
            label: t('phone', 'Teléfono'),
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('phone', 'Teléfono') }
            }
        },
        {
            id: 'responsable_name',
            dataKey: 'responsable_name',
            label: t('responsableName', 'Nombre del responsable'),
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            customRender: (parameterValue, allRowData) => <EditRow key={'edit' + parameterValue} {...{ parameterValue, allRowData }} />
        },
        {
            id: 'delete',
            dataKey: 'id',
            label: '',
            width: 50,
            customRender: (parameterValue, allRowData) => <DeleteRow key={'delete' + parameterValue} {...{ parameterValue, allRowData }} onClick={(_idk, allRowData) => setOpened({ type: 'delete', allRowData })} />,
            havePermission: permissionCheck(userPermissions, 'delete')
        }
    ]
    return (
        <TwinTableLink  createRowDataLink={[location, ['$id', 'main']]} name='customersB2B' columns={columns} getDataFrom='/api/app/customer/getAllCustomers' extraWhereParams={{'customer_type' : 1}}  setTableInstance={setTableInstance} />
    )
}

export default CustomersB2B