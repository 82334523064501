import { TaskModelType } from '@teinor/erp/types/company/task';

export const sortChildrenTasks = (childrenTasks: TaskModelType[]) => {
    const result: TaskModelType[] = []
    const finisheds: TaskModelType[] = []
   for (const task of childrenTasks) {
        if(task.finished){
            finisheds.push(task)
        }else {
            result.push(task)
        }
   }
    finisheds.sort((a, b) => a.id - b.id)
    result.sort((a, b) => a.id - b.id)

    return [...result, ...finisheds]
}