import { EmployeeExpenseModelType } from '@teinor/erp/types/company/userInner/employee/employeeExpense'
import { EmployeeExpenseRowTypeModelAdd } from '@teinor/erp/types/company/userInner/employee/employeeExpense/employeeExpenseRow/employeeExpenseRowType'
import { useCallback, useEffect, useState } from 'react'
import { MediumModalCreateEdit } from '../../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../../../../../../baseComponents/ModalsLayouts/types'
import { RowData } from '../../../../../../../../baseComponents/TwinTable/types'
import { FormRender } from '../../../../../../../../forms/FormRenderer/types'
import EmployeeExpenseNotEditableTableExpenses from '../../../../../../../../specificComponents/Employee/EmployeeExpenses/EmployeeExpenseEditModal/EmployeeExpenseNotEditableModal/EmployeeExpenseNotEditableTableExpenses'
import EmployeeExpenseNotEditableTotals from '../../../../../../../../specificComponents/Employee/EmployeeExpenses/EmployeeExpenseEditModal/EmployeeExpenseNotEditableModal/EmployeeExpenseNotEditableTotals'
import { twinFetchPostJSON } from '../../../../../../../../utils/globals/data'
import { displayDateByString } from '../../../../../../../../utils/globals/date'
import { returnClassOfSpanTemplate } from '../../../../../../../../utils/globals/tailwind'
import { valueOrDefaultValue } from '../../../../../../../../utils/globals/valueOrDefaultValue'
import useEmployeeExpensesType from '../../../../../../../../utils/hooks/employeeExpenses/useEmployeeExpensesType'
import useTwinTranslation from '../../../../../../../../utils/hooks/useTwinTranslation'


type ModalEditEmployeeExpensesProps = ModalEditComponentProps & {}


const ModalEditEmployeeExpenses: React.FC<ModalEditEmployeeExpensesProps> = ({ userPermissions, allRowData, ...rest }) => {
    const { t } = useTwinTranslation()
    const { expensesType, expenseInstance } = useModalEditEmployeeExpensesLogic({allRowData})
    const { expenseStatus } = useEmployeeExpensesType()
    
    if (!expenseInstance) {
        return null
    }

    const fields: FormRender<EmployeeExpenseModelType> = [
        {
            cols: 2,
            elements: [
                {
                    name: 'EmployeeId',
                    component: 'InputHidden',
                    value: expenseInstance?.EmployeeId
                },
                {
                    name: 'dateStart',
                    label: t('dateStart', 'Desde'),
                    component: 'TextField',
                    value: displayDateByString(expenseInstance?.dateStart || ''),
                    readOnly: true
                },
                {
                    name: 'dateEnd',
                    label: t('dateEnd', 'Hasta'),
                    component: 'TextField',
                    value: displayDateByString(expenseInstance?.dateEnd || ''),
                    readOnly: true
                },
                {
                    name: 'CurrencyId',
                    label: t('currency', 'Moneda'),
                    component: 'TextField',
                },
                {
                    name: 'location',
                    label: t('location', 'Ubicación'),
                    component: 'TextField',
                    readOnly: true
                },
                {
                    name: 'km',
                    label: t('km', 'Km'),
                    component: 'TextField',
                    readOnly: true 
                },
                {
                    name: 'kmPrice',
                    label: t('kmPrice', 'Precio del Km'),
                    component: 'DisplayPriceField',
                    CurrencyId: expenseInstance?.CurrencyId || '',
                    value: expenseInstance?.kmPrice,
                    readOnly: true 
                },
                {
                    name: 'abroad',
                    label: t('abroad', 'Extranjero'),
                    component: 'TextField',
                    value: expenseInstance?.abroad ? t('yes', 'Sí') : t('no', 'No'),
                    readOnly:  true
                },
                {
                    name: 'overnight_stay',
                    label: t('overnightStay', 'Pernoctación'),
                    component: 'TextField',
                    value: expenseInstance?.overnight_stay ? t('yes', 'Sí') : t('no', 'No'),
                    readOnly: true
                },
                {
                    name: 'status',
                    label: t('status', 'Estado'),
                    component: 'TextField',
                    value: expenseInstance ? expenseStatus[expenseInstance?.status].name : '',
                    readOnly: true
                },
                {
                    name: 'updatedAt',
                    label: t('modificatedAt', 'Última modificación'),
                    component: 'TextField',
                    value: displayDateByString(expenseInstance?.updatedAt || ''),
                    readOnly: true
                },
                {
                    name: 'remarks',
                    label: t('remarks', 'Observaciones'),
                    component: expenseInstance?.remarks ? 'TextField': 'InputHidden',
                    value: expenseInstance?.remarks,
                    className: returnClassOfSpanTemplate(2)
                },
            ],
        },
    ]

  
    if (allRowData && userPermissions) {
        const parsedFields = valueOrDefaultValue(fields, userPermissions, expenseInstance)
        return (
            <MediumModalCreateEdit fields={parsedFields} translations={{
                title: t('expensesSheet', 'Ficha de gastos'),
                button: ''
            }} haveButtonPermissions={false} url={''} {...rest} >
                <EmployeeExpenseNotEditableTableExpenses expenseInstance={expenseInstance} expensesType={expensesType} />
                <EmployeeExpenseNotEditableTotals expenseInstance={expenseInstance}/>
            </MediumModalCreateEdit>
        )
    }
    return null
}

interface ModalEditEmployeeExpensesLogicProps {
    allRowData?: RowData
}

const useModalEditEmployeeExpensesLogic = ({ allRowData }: ModalEditEmployeeExpensesLogicProps) => {
    const [expensesType, setExpensesType] = useState<EmployeeExpenseRowTypeModelAdd[]>([])
    const [expenseInstance, setExpenseInstance] = useState<EmployeeExpenseModelType | null>(null)

    const getExpensesTypes = useCallback(async () => {
        const allExpensesTypes = await twinFetchPostJSON('/api/app/employeeExpense/getAllEmployeeExpensesRowType', {})
        setExpensesType(allExpensesTypes)
    }, [setExpensesType])

    const getExpensesRow = useCallback(async () => {
        const allExpenses = await twinFetchPostJSON('/api/app/employee/employeeExpense/getEmployeeExpenseInstance', {id: allRowData?.id})
        setExpenseInstance(allExpenses)
    }, [setExpenseInstance, allRowData])

    useEffect(() => {
        getExpensesTypes()
    }, [getExpensesTypes])

    useEffect(() => {
        getExpensesRow()
    }, [getExpensesRow])

    return { expensesType, expenseInstance }
}

export default ModalEditEmployeeExpenses;