import { TwinDictionary } from './dictionary'

export const reverseArray = <T>(array: T[]) => {
    let reversedArray: T[]= []
    for (let i = array.length - 1; i > -1; i--) {
        reversedArray.push(array[i])
    }
    return reversedArray
}

export const includesByArrayType = (values: any[], value: string) => {
    if (!values.length) {
        return false
    }
    if (typeof values[0] === 'string') {
        return values.includes(String(value))
    } else if (typeof values[0] === 'number') {
        return values.includes(parseInt(value))
    }
}

export const parseIntArray = (arr: string[]) => {
    const result: number[] = []
    for (const id of arr) {
        result.push(parseInt(id))
    }
    return result
}

export const parseStringArray = (arr: any[]) => {
    const result: string[] = []
    for (const id of arr) {
        result.push(String(id))
    }
    return result
}
export const arrayFromDictionaryIdInteger = (dict: TwinDictionary, value: string) => {
    const result: any[] = []
    for (const k in dict) {
        result.push({[value]: parseInt(k)})
    }
    return result
}

export const arrayFromArrayDictionaryAttribute = (arr: TwinDictionary[], value: string = 'id') => {
    const result: any[] = []
    for (const element of arr) {
        result.push(element[value])
    }
    return result
}