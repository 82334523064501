export const timeControlEmployeePDFParseDates = (month: number, year: number, showYears: boolean) => {
    let dateStart = ''
    let dateEnd = ''
    if (showYears) {
        dateStart = new Date(year, 0, 1).toDateString()
        dateEnd = new Date(year, 12, 0).toDateString()
    } else {
        dateStart = new Date(year, month - 1, 1).toDateString()
        dateEnd = new Date(year, month, 0).toDateString()
    }

    return {dateStart, dateEnd}
}