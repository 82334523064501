import { MediumModalCreateEdit } from '../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { FormRender } from '../../../../../../forms/FormRenderer/types'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import { CurrencyModelType } from '@teinor/erp/types/company/config/currency'
import { ModalEditComponentProps } from '../../../../../../baseComponents/ModalsLayouts/types'

interface ModalEditCurrencyProps extends ModalEditComponentProps { }

const ModalEditCurrency: React.FC<ModalEditCurrencyProps> = ({ allRowData, ...rest }) => {
    const { t } = useTwinTranslation()
    if (!allRowData) {
        return null
    }
    const fields: FormRender<CurrencyModelType> = [
        {
            cols: 2,
            elements: [
                {
                    name: 'id',
                    label: t('currency', 'Moneda'),
                    component: 'InputWithLabelMargin',
                    readOnly: true,
                    value: allRowData.id
                },
                {
                    name: 'conversion',
                    label: t('conversion', 'Conversión'),
                    component: 'InputWithLabelMargin',
                    defaultValue: allRowData.conversion
                },
                {
                    name: 'secure_exchange',
                    label: t('secureExchange', 'Seguro de cambio'),
                    component: 'InputWithLabelMargin',
                    defaultValue: allRowData.secure_exchange
                },
                {
                    name: 'is_main',
                    label: t('main', 'Principal'),
                    component: 'CheckboxMargin',
                    defaultChecked: allRowData.is_main
                }
            ]
        },
    ]
    return (<MediumModalCreateEdit fields={fields} url={'/api/app/config/currency/updateCurrency'} translations={{
        title: t('editCurrency', 'Editar moneda'),
        button: t('edit', 'Editar')
    }} haveButtonPermissions={true} {...rest} />)
    
}

export default ModalEditCurrency