import { useCallback } from 'react'
import { Modify } from '../../utils/globals/types'
import { changeSuccessMessage, clearAntiUnsaveElement } from '../../utils/reducers/reduxDispatch'
import { getValuesAndErrorsFromForm, sendFormByFetch, sendFormByFetchWithoutErrors } from './functions'
import { FormLogicProps, OnSubmit, ReactForm } from './types'

export type FormProps = Modify<ReactForm, FormLogicProps & {
}>

const TwinForm: React.FC<FormProps> = ({ onSubmit, onError, beforeSubmitHandler, children, ...formProps }) => {
    const {customFormSubmit} = useTwinFormLogic({onSubmit, onError, beforeSubmitHandler, ...formProps})
    return (
        <form {...formProps} onSubmit={customFormSubmit}>
            {children}
        </form>
    )
}
const useTwinFormLogic = ({ onSubmit, onError, beforeSubmitHandler, triggerErrors = true, extraProps, ...processFormElementProps }: FormLogicProps) => {
    const ownOnSubmit: OnSubmit = useCallback(async (res, values) => {
        onSubmit?.(res, values)
        await clearAntiUnsaveElement()
        changeSuccessMessage('Realizado correctamente')
    }, [onSubmit])
    const customFormSubmit = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const currentTarget = e.currentTarget
        const formElements = currentTarget.elements
        const { values, errors } = await getValuesAndErrorsFromForm(formElements, processFormElementProps)
        if (!errors) {
            if (!beforeSubmitHandler || beforeSubmitHandler(values, formElements)) {
                if (triggerErrors) {
                    await sendFormByFetch(values, currentTarget.action, ownOnSubmit, onError, extraProps)
                } else {
                    await sendFormByFetchWithoutErrors(values, currentTarget.action, ownOnSubmit, extraProps)
                }
            }
        }
    }, [ownOnSubmit, processFormElementProps, beforeSubmitHandler, triggerErrors, onError, extraProps])
    return { customFormSubmit }
}


export default TwinForm