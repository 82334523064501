import { TaskTypeModelType } from '@teinor/erp/types/company/task/taskType'
import { dictionaryComplexFromJsonArr } from '../../globals/dictionary'

export interface TaskTypesAction {
    type: 'CHANGE_TASKTYPE'
    payload: TaskTypeModelType[]
}

export type DictionaryTask = { [id: number]: TaskTypeModelType }
export type TaskTypePayload = null | DictionaryTask

export default function taskTypes(state: TaskTypePayload = null, action: TaskTypesAction): DictionaryTask | null {
    switch (action.type) {
        case 'CHANGE_TASKTYPE':
            const dictionaryTaskType = dictionaryComplexFromJsonArr(action.payload || [])
            return state = dictionaryTaskType
        default:
            return state
    }
}