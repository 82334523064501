import { CustomerModelType } from '@teinor/erp/types/company/customer'
import { useCallback, useEffect, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { ComponentWithPermissions } from '../../../../../../../../../baseComponents/CreateRoutering/types'
import SingleFormLayout from '../../../../../../../../../baseComponents/Layout/SingleFormLayout'
import LoaderDecider from '../../../../../../../../../baseComponents/LoaderDecider'
import { FormRender } from '../../../../../../../../../forms/FormRenderer/types'
import { parseCustomerGroups } from '../../../../../../../../../specificComponents/Customers/CustomerGroups'
import { getDictionaryStates } from '../../../../../../../../../utils/globals/countries'
import { twinFetchPostJSON } from '../../../../../../../../../utils/globals/data'
import { TwinDictionary, dictionaryComplexFromJsonArr } from '../../../../../../../../../utils/globals/dictionary'
import withLoading from '../../../../../../../../../utils/hoc/withLoading'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import { AllReduxPayloads } from '../../../../../../../../../utils/reducers'
import { CustomerGroupPayload } from '../../../../../../../../../utils/reducers/customers/customerGroups'
import { getDictionaryCountries } from '../../../../../../../../../utils/reducers/getters'


interface ContactOtherInformationProps extends ComponentWithPermissions {
    basicData: CustomerModelType | null
    getData: () => Promise<void>
}
interface ModifiedCustomerModelType extends CustomerModelType {
    customersForParse: string[]
}

const ContactOtherInformation: React.FC<ContactOtherInformationProps & ReduxCustomerGroups> = ({ basicData, userPermissions, customerGroups }) => {
    const { t } = useTwinTranslation()
    const { myCustomerGroups, getOtherData, otherData, taxRegimeCountry, setTaxRegimeCountry } = useContactOtherInformationLogic(basicData)
    const fields: FormRender<ModifiedCustomerModelType> = [
        {
            cols: 3,
            elements: [
                {
                    label: t('logo', 'Logotipo'),
                    name: 'image',
                    component: 'ImageInput',
                    defaultValue: otherData?.image
                },
                {
                    label: t('frontPhoto', 'Foto frontal'),
                    name: 'id_image_front',
                    component: 'ImageInput',
                    defaultValue: otherData?.id_image_front
                },
                {
                    label: t('backlPhoto', 'Foto trasera'),
                    name: 'id_image_back',
                    component: 'ImageInput',
                    defaultValue: otherData?.id_image_back
                },
            ]
        },
        {
            cols: 3,
            title: t('billingInformation', 'Información de  facturación'),
            elements: [
                {
                    name: 'balance',
                    label: t('balance', 'Balance'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    defaultValue: otherData?.balance || ''
                },
                {
                    name: 'tax_regime_country',
                    label: t('taxRegimeCountry', 'Régimen Fiscal País'),
                    component: 'CustomSelectWithSearchBar',
                    items: getDictionaryCountries(),
                    value: taxRegimeCountry,
                    onChange: (value: string) => setTaxRegimeCountry(value)
                },
                {
                    name: 'tax_regime_state',
                    label: t('taxRegimeState', 'Régimen Fiscal Provincia'),
                    component: 'CustomSelectWithSearchBar',
                    sortItems: true,
                    items: getDictionaryStates(taxRegimeCountry),
                    value: otherData?.tax_regime_state,
                }
            ]
        },
        {
            cols: 1,
            title: t('contactTypes', 'Tipos de Contactos'),
            elements: [
                {
                    name: 'customersForParse',
                    component: 'MultipleCheckboxSelector',
                    subtitles: { subtitleOptions: t('notSelected', 'Sin seleccionar'), subtitleSelectedOptions: t('selected', 'Seleccionados') },
                    items: dictionaryComplexFromJsonArr(customerGroups?.ContactGroups || []),
                    defaultValue: myCustomerGroups
                },
                {
                    name: 'id',
                    component: 'InputHidden',
                    value: basicData?.id
                }
            ]
        }
    ]
    return (
        <LoaderDecider loading={otherData ? false : true}>
            <SingleFormLayout action='/api/app/customer/updateCustomer' imagepath={['customer', String(basicData?.id)]} onSubmit={getOtherData} sections={fields} userPermissions={userPermissions} />
        </LoaderDecider>
    )
}

export interface OtherCustomerInformation {
    balance: number
    tax_regime_country: string
    tax_regime_state: string
    CustomerGroups: number[]
    image: string
    id_image_front: string
    id_image_back: string
}
const useContactOtherInformationLogic = (basicData: CustomerModelType | null) => {
    const [selectedOptions, setSelectedOptions] = useState<TwinDictionary>({})
    const [otherData, setOtherData] = useState<CustomerModelType | null>(null)
    const [taxRegimeCountry, setTaxRegimeCountry] = useState('')

    const getOtherData = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/customer/getOtherCustomerInformation', { "id": basicData?.id })
        if (result) {
            result.CustomerGroups = parseCustomerGroups(result.CustomerGroups)
        }
        setOtherData(result)
        if (result?.tax_regime_country) {
            setTaxRegimeCountry(result?.tax_regime_country)
        }
    }, [basicData])

    useEffect(() => {
        getOtherData()
    }, [getOtherData])

    return { otherData, getOtherData, taxRegimeCountry, setTaxRegimeCountry, selectedOptions, setSelectedOptions, myCustomerGroups: otherData?.CustomerGroups ?? [] }
}


const customerGroupsDispatch = {
    setCustomerGroups: (payload: CustomerGroupPayload) => ({ type: 'CHANGE_CUSTOMER_GROUP', payload }),
}

export type ReduxCustomerGroups = ConnectedProps<typeof customerGroupsConnect>
const mapCustomerGroupsConnector = (state: AllReduxPayloads) => ({ customerGroups: state.customerGroups })
const customerGroupsConnect = connect(mapCustomerGroupsConnector, customerGroupsDispatch)

const customerGroupsConnectLoading = withLoading(ContactOtherInformation, [{ fetchUrl: '/api/app/customer/customerGroup/getAllCustomersGroup', propName: 'customerGroups', setFunctionName: 'setCustomerGroups' }])

export default customerGroupsConnect(customerGroupsConnectLoading);