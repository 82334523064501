import Dashboard from '../../../../../baseComponents/Dashboard'

const StaffDashboard: React.FC = () => {
    const props = {
        name: 'dashboard_staff_distribution',
        defaultWidgets: JSON.stringify([{ "cols": 2, "rows": 4, "name": "holiday_calendar_employees" }, { "cols": 2, "rows": 2, "name": "holiday_table_employees_week" }, { "cols": 2, "rows": 2, "name": "lastest_employee_timetable" }])
    }
    return (<Dashboard {...props} />)
}

export default StaffDashboard