import { Modify } from '@teinor/erp'
import './../Input/input.sass'

export type RadioSelectorProps = Modify<React.HTMLProps<HTMLInputElement>, {
    myref?: React.MutableRefObject<any>
}>

const RadioSelector: React.FC<RadioSelectorProps> = ({ ...rest }) => {
    return (
        <input className='input_radio' type='radio' {...rest}/>
    )
}

interface RadioWithLabelProps extends RadioSelectorProps {
    label: string
    id: string
}

export const RadioWithLabel: React.FC<RadioWithLabelProps> = ({ id, label, className, ...rest }) => {
    return (
        <div className={className}><RadioSelector id={id} {...rest} /><label htmlFor={id} className='ml-8 cursor-pointer'>{label}</label></div>
    )
}

export default RadioSelector