import { Fragment } from 'react'
import LoadingSpinner from './LoadingSpinner'

interface LoaderDeciderProps {
    loading: boolean
    children: React.ReactNode
}


const LoaderDecider: React.FC<LoaderDeciderProps> = ({ loading, children }) => {
    if (loading) {
        return (
            <div className=' flex h-full'>
                <LoadingSpinner />
            </div>
        )
    }
    return (
        <Fragment>
            {children}
        </Fragment>
    )
}


export default LoaderDecider