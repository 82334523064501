import { LittleModalCreateEdit } from '../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalComponentProps } from '../../../../../../baseComponents/ModalsLayouts/types'
import { FormRender } from '../../../../../../forms/FormRenderer/types'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import { CurrencyModelType } from '@teinor/erp/types/company/config/currency'
import { getActiveCurrencies, getAllCurrencies } from '../../../../../../utils/reducers/getters'
import { filterDictionaryNotInItems } from '../../../../../../utils/globals/dictionary'
import { useMemo } from 'react'

interface ModalCreateCurrencyProps extends ModalComponentProps{}

const ModalCreateCurrency: React.FC<ModalCreateCurrencyProps> = ({ ...rest }) => {
    const activeCurrencies = useMemo(() => Object.keys(getActiveCurrencies()), [])
    const items = useMemo(() => filterDictionaryNotInItems(getAllCurrencies(), activeCurrencies), [activeCurrencies])
    const { t } = useTwinTranslation()
    
    const fields: FormRender<CurrencyModelType> = [
        {
            cols: 1,
            elements: [
                {
                    name: 'id',
                    label: t('currency', 'Moneda'),
                    component: 'CustomSelectWithSearchBar',
                    items,
                    required: true
                },
            ]
        },
    ]
    
    return (<LittleModalCreateEdit fields={fields} url={'/api/app/config/currency/createCurrency'} translations={{
        title: t('createCurrency', 'Crear Moneda'),
        button: t('create', 'Crear')
    }} haveButtonPermissions={true} {...rest} />)
}

export default ModalCreateCurrency